const Track = require('./Track');
const Utils = require('../../utils/Utils');

const disabledLanguage = 'off';

class TextTrack extends Track {
    constructor(engine) {
        super('text', engine);
        this.disableable = true;
        this.lastActiveTrack = null;
        this.addOffTextTrack = false;
        this.hasAddedOffTextTrack = false;

        this.accessibilityIndicates = {
            language: '-cc',
            name: '[CC]',
            role: 'caption'
        }
    }

    getTracks() {
        if (this.addOffTextTrack && !this.hasAddedOffTextTrack) {
            this.hasAddedOffTextTrack = this.tryAddOffTextTrack();
        }
        return super.getTracks();
    }

    getActiveTrack() {
        try {
            let activeIds = this.getActiveIds();
            if (activeIds.length > 0) {
                return this.gTracksManager.getTrackById(activeIds[0])
            } else {
                return null;
            }
        } catch (e) {
            return null
        }
    }

    tryAddOffTextTrack() {
        const textTracks = (this.gTracksManager) ? this.gTracksManager.getTracks() : [];
        if (textTracks.length && !textTracks.some(t => t.language === disabledLanguage) && this.gTracksManager.createTrack) {
            const offTextTrack = Utils.extend(this.gTracksManager.createTrack(), {
                trackContentType: 'text/vtt',
                type: 'TEXT',
                language: 'off',
                name: 'Off',
                subtype: 'SUBTITLE',
                roles: []
            });
            this.gTracksManager.addTracks([offTextTrack]);

            return true;
        }

        return false;
    }

    enabledStateChanged(enableState) {
        if (enableState === false) {
            this.lastActiveTrack = this.getActiveTrack();
            this.disableHandler();
            this.setActiveTrack(disabledLanguage);
        } else {
            if (this.lastActiveTrack && this.lastActiveTrack.language) {
                this.setActiveTrack(this.lastActiveTrack.language);
            }

        }
    }

    disableHandler() {
        if (this.engine.textContainer) {
            this.engine.textContainer.innerHTML = '';
        }
    }

    reset() {
        super.reset();

        this.hasAddedOffTextTrack = false;
    }
}

module.exports = TextTrack;