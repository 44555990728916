import type { Metadata, ReceiverInformation, Stream } from '@tv4/unified-receiver';

import {
  Content,
  Credentials,
  HandledError,
  IServiceLayer,
  Load,
  LoadAdvertisement,
  LoadTracking,
  ReceiverError,
  ReceiverInterface,
  Shutdown,
} from '@tv4/unified-receiver';

import { FetchRequestFactory } from '@tv4/one-playback-sdk';

import { PlaybackApiService } from './components/playbackApiService/PlaybackApiService';
import { GetSegmentData } from './components/playbackApiService/SegmentBSUtil';
import { PlaybackAsset, PlaybackMedia, Videoplaza } from './components/playbackApiService/Types';
import { Tv4YospacePlaybackFeature } from './components/playbackFeatures/yospace/Tv4YospacePlaybackFeature';
import { PlaybackApiMapper } from './mappers/PlaybackApiMapper';
import {
  AvodAuxiliaryData,
  ClientServices,
  CredentialsWithRefreshToken,
  LoadWithCredentials,
  PlayerConfiguration,
  ServiceLayerConfiguration,
  ServiceLayerInformation,
} from './Types';
import { credentialsConfig, getTokenType, getUserInfoFromJwtToken, generateS4 } from './utils/TokenUtil';

import { NextContent } from './components/nextContent/NextContent';
import { PauseAds } from './components/pauseAds/PauseAds';
import { BonnierPlayerSdkTrackingManager } from './components/trackingManager/BonnierPlayerSdkTrackingManager';
import { BonnierSdkSimpleUser } from './components/trackingManager/BonnierSdkSimpleUser';
import { GoogleTagManager } from './components/trackingManager/trackersComponents/GoogleTagManager';
import { DEFAULT_SERVICE_LANGUAGE } from './Constants';
import { Translations } from './i18n';
import { ServiceLayerErrorFactory } from './utils/ServiceLayerErrorFactory';
import { isLive, isYospace } from './utils/StreamUtil';
import { StandardError } from '@tv4/one-playback-sdk-shared';

export class AvodServiceLayer extends IServiceLayer {
  public config: ServiceLayerConfiguration;
  public receiver: ReceiverInterface;
  private requestFactory: FetchRequestFactory = new FetchRequestFactory();
  private credentialsRefreshInterval: NodeJS.Timer | undefined;

  private currentContent?: Content;
  private playbackSessionId: string = '';
  private advertisement?: LoadAdvertisement;
  private isInitalPlayback: Boolean = true;
  private playbackApiService!: PlaybackApiService;
  private segmentTags: string[] = [];

  private deviceCapabilities: string[] = ['live-drm-adstitch-2', 'yospace3'];
  private token: string = '';
  private profileId: string = '';
  private tracking?: LoadTracking;
  private trackingManager!: BonnierPlayerSdkTrackingManager;
  private user!: BonnierSdkSimpleUser;
  private yospacePlaybackFeature?: Tv4YospacePlaybackFeature;
  private deviceId: string = '';

  private playerConfiguration: PlayerConfiguration = {
    shaka: {
      default: {
        manifest: {
          defaultPresentationDelay: 20,
          retryParameters: {
            timeout: 15000,
          },
        },
        streaming: {
          rebufferingGoal: 10,
          retryParameters: {
            timeout: 15000,
          },
        },
      },
      live: {
        manifest: {
          retryParameters: {
            timeout: 15000,
          },
        },
        streaming: {
          rebufferingGoal: 5,
          retryParameters: {
            timeout: 15000,
          },
        },
      },
    },
    wacka: {},
    dush: {},
  };
  private pauseAds!: PauseAds;
  private serviceLayerInfo!: ServiceLayerInformation;

  constructor(config: ServiceLayerConfiguration, receiver: ReceiverInterface) {
    super(config, receiver);
    this.config = config;
    this.receiver = receiver;

    this.initServiceLayerInfo(config);
    this.initReceiver(config);
    this.initUser();
    this.initPlaybackApiService();
    this.initTrackingManager();
    this.initYospacePlaybackFeature(config);
    this.initPauseAds();
    this.generateDeviceId();
  }

  public async initialize(): Promise<void> {
    if (this.config.tracking.gtmId) {
      await GoogleTagManager.loadGoogleTagManager(this.config.tracking.gtmId);
    }
  }

  public async handleError(error: ReceiverError): Promise<HandledError> {
    return new HandledError(error);
  }

  public async handleLoad(load: LoadWithCredentials, receiverInformation: ReceiverInformation): Promise<Load> {
    if (this.isGen1Device(receiverInformation)) {
      throw ServiceLayerErrorFactory.createTerminatingError('Device', 'Gen1NotSupported');
    }

    this.playbackSessionId = receiverInformation.playbackSession.playbackSessionId;

    await this.resetTrackingManager();
    this.validateReceiverInformation(receiverInformation);
    this.initializeServiceLayerInfo(receiverInformation, load);

    this.updateProfileId(load.credentials?.token);
    if (load.credentials?.refreshToken) {
      const accessToken = await this.getAccessTokenByRefreshToken(load.credentials.refreshToken, this.profileId);
      await this.updateCredentials(accessToken);
    } else {
      await this.updateCredentials(load.credentials?.token || this.token);
    }
    this.startPeriodicTokenRefresh(load.credentials);

    this.handleInitialPlaybackIfNeeded();
    const runtimeConfig = this.getRuntimeConfig(load.content);

    this.setAdvertisementAndTracking(load);
    this.initializeTrackingManager(load.content.contentId);

    const { asset, media } = await this.getAssetAndMedia(load.content.contentId);
    this.validateAssetAndMedia(asset, media);

    this.initializePauseAds(asset);
    this.updateTrackingManager(asset, media);

    this.mapAndSetMetadata(load, asset, runtimeConfig);
    const streams = PlaybackApiMapper.MapStreams(media, asset, runtimeConfig.startOver || false);
    if (!streams || streams.length === 0) {
      this.handleNoStreamsFound();
    }

    load.content.streams = streams;
    load.currentTime = typeof load.currentTime === 'number' ? load.currentTime : 0;
    load.content.contentType = asset.metadata.type;
    this.currentContent = load.content;

    //@ts-ignore
    if (asset.metadata.isLive) {
      //@ts-ignore
      load.currentTime = undefined;
    }

    if (asset.metadata.type === 'channel') {
      load.currentTime = 7200;
    }

    try {
      this.trackingManager.updateTracker(load.content, this.user, this.serviceLayerInfo, this.config, this.deviceId);
    } catch (e) {
      this.handleTrackingManagerUpdateError(e);
    }

    const stream = load.content.streams[0];
    const isStreamLive = isLive(stream) || asset.metadata.type === 'channel';

    this.setPlayerConfigurationProfile(isStreamLive);

    await this.initializeYospacePlayback(stream, isStreamLive, asset, load);

    return load;
  }

  private updateProfileId(token?: string) {
    if (!token) return;
    const userData = getUserInfoFromJwtToken(token);

    if (userData?.profileId) {
      this.profileId = userData.profileId;
      console.log('Updated profileId with ', this.profileId);
    }
  }

  private isGen1Device(receiverInformation: ReceiverInformation) {
    const { device } = receiverInformation;
    return device.deviceModel === 'Generation.1';
  }

  public async handleLoadNextContent(): Promise<void> {
    if (!this.currentContent) return;

    let nextContent;
    switch (this.serviceLayerInfo.serviceId) {
      case ClientServices.TV4PLAY:
      case ClientServices.MTV:
        const isClip =
          typeof this.currentContent.auxiliaryData?.isClip === 'boolean'
            ? this.currentContent.auxiliaryData?.isClip
            : false;

        nextContent = await NextContent.GetTv4Play(
          this.currentContent.contentId,
          isClip,
          this.config.backend.graphqlEndpoint,
          this.token
        );
        break;
      case ClientServices.FK:
        nextContent = await NextContent.GetFK(
          this.currentContent.contentId,
          this.config.backend.graphqlEndpoint,
          this.token
        );
        break;
    }

    if (nextContent) {
      this.receiver.addToQueue(nextContent);
    }
  }

  public async handleReset(): Promise<void> {
    this.pauseAds.reset();
    clearInterval(this.credentialsRefreshInterval);
  }

  public async handleShutdown(shutdown: Shutdown): Promise<void> {
    await this.handleReset();
    let pId = await this.trackingManager.destroy(this.playbackSessionId);
    if (pId !== this.playbackSessionId) {
      return;
    }
  }

  private initServiceLayerInfo(config: ServiceLayerConfiguration) {
    const { serviceBrand, serviceCountry, serviceId, serviceName } = config.service;
    this.serviceLayerInfo = {
      applicationInfo: {
        applicationName: `${serviceBrand} OA Chromecast`,
        //@ts-ignore
        applicationVersion: __VERSION__,
      },
      serviceBrand,
      serviceCountry,
      serviceId,
      serviceName,
    };
  }

  private generateDeviceId() {
    this.deviceId =
      generateS4() +
      generateS4() +
      '-' +
      generateS4() +
      '-' +
      generateS4() +
      '-' +
      generateS4() +
      '-' +
      generateS4() +
      generateS4() +
      generateS4();
  }

  private initReceiver(config: ServiceLayerConfiguration) {
    this.receiver.registerTranslation(Translations[config.service.serviceBrand]);
    this.receiver.registerPlayerConfiguration(this.playerConfiguration);
    this.receiver.setLanguage(DEFAULT_SERVICE_LANGUAGE);
    this.receiver.updateEventBusSetting({
      debugMode: false,
      emitOriginal: false,
    });
    this.receiver.setApplicationInfo(this.serviceLayerInfo.applicationInfo);
  }

  private initUser() {
    this.user = new BonnierSdkSimpleUser('', {
      userId: '-1',
      userIsLoggedIn: false,
    });
  }

  private initPlaybackApiService() {
    this.playbackApiService = new PlaybackApiService(this.requestFactory);
  }

  private initTrackingManager() {
    this.trackingManager = new BonnierPlayerSdkTrackingManager(this.receiver);
  }

  private initYospacePlaybackFeature(config: ServiceLayerConfiguration) {
    const yospaceEnabled = config.features.yospaceLive || config.features.yospaceVod;
    if (yospaceEnabled) {
      this.yospacePlaybackFeature = new Tv4YospacePlaybackFeature(config.features, false);
      this.receiver.registerPlaybackFeature('yospace', this.yospacePlaybackFeature);
    }
  }

  private initPauseAds() {
    this.pauseAds = new PauseAds(this.receiver, this.serviceLayerInfo);
  }

  private async resetTrackingManager(): Promise<void> {
    console.log(`## [AvodServiceLayer] | Should await reset promise`);
    let pId = await this.trackingManager.reset(this.playbackSessionId);
    console.log(`## [AvodServiceLayer] | Done`);

    if (pId !== this.playbackSessionId) {
      console.warn(
        `### Warning class:playbackSessionId[handleLoad] `,
        pId,
        this.playbackSessionId,
        pId !== this.playbackSessionId
      );
      return undefined;
    }
  }

  private validateReceiverInformation(receiverInformation: ReceiverInformation) {
    if (!receiverInformation) {
      throw ServiceLayerErrorFactory.createTerminatingError('ReceiverInformation', 'MissingReceiverInformation');
    }
    if (!receiverInformation.player) {
      throw ServiceLayerErrorFactory.createTerminatingError('ReceiverInformation', 'MissingPlayerData');
    }
    if (!receiverInformation.device) {
      throw ServiceLayerErrorFactory.createTerminatingError('ReceiverInformation', 'MissingDeviceData');
    }
  }

  private initializeServiceLayerInfo(receiverInformation: ReceiverInformation, load: Load) {
    this.serviceLayerInfo.receiverInfo = receiverInformation;
    if (load.sender?.type) {
      this.serviceLayerInfo.senderType = load.sender.type.substr(12);
    }

    if (!this.serviceLayerInfo.receiverInfo.playbackSession?.playbackSessionId) {
      throw ServiceLayerErrorFactory.createTerminatingError('serviceLayerInfo', 'MissingPlaybackSessionId');
    }
    if (!this.serviceLayerInfo.receiverInfo.device?.deviceId) {
      throw ServiceLayerErrorFactory.createTerminatingError('serviceLayerInfo', 'MissingDeviceId');
    }
  }

  private handleInitialPlaybackIfNeeded() {
    if (this.isInitalPlayback) {
      this.handleInitialPlayback();
    }
  }

  private getRuntimeConfig = (content: Content): AvodAuxiliaryData => {
    const runtimeConfig = content.auxiliaryData as AvodAuxiliaryData;
    return runtimeConfig;
  };

  private setAdvertisementAndTracking = (load: Load): void => {
    this.advertisement = load.advertisement || this.advertisement;
    this.tracking = load.tracking || this.tracking;
    load.advertisement = this.advertisement;
    load.tracking = this.tracking;
    if (this.serviceLayerInfo.user && load.tracking?.consentString) {
      this.serviceLayerInfo.user.consentString = load.tracking.consentString;
    }
  };

  private initializeTrackingManager(contentId: string): void {
    this.trackingManager.initialize(contentId, this.serviceLayerInfo, this.config);
  }

  private async getAssetAndMedia(contentId: string): Promise<{ asset: PlaybackAsset; media: PlaybackMedia }> {
    try {
      const assetMedia = await this.playbackApiService.getAssetAndMedia(
        contentId,
        this.deviceCapabilities,
        this.serviceLayerInfo.receiverInfo?.device
      );
      return assetMedia;
    } catch (e) {
      if (e instanceof StandardError) {
        let error = ServiceLayerErrorFactory.createFromStandardError(e);
        throw error;
      } else {
        //@ts-ignore
        const message = e.message || '';
        throw ServiceLayerErrorFactory.createTerminatingError('HandleLoad', 'UnknownLoadError', message);
      }
    }
  }

  private validateAssetAndMedia(asset: PlaybackAsset | undefined, media: PlaybackMedia | undefined): void {
    if (!asset) {
      throw ServiceLayerErrorFactory.createTerminatingError('HandleLoad', 'MissingAsset');
    }
    if (!media) {
      throw ServiceLayerErrorFactory.createTerminatingError('HandleLoad', 'MissingMedia');
    }
  }

  private initializePauseAds(asset: PlaybackAsset) {
    if (asset.metadata.hideAds) return;

    if (asset.trackingData.videoplaza) {
      this.pauseAds.initialize(
        asset.trackingData.videoplaza,
        this.segmentTags,
        this.serviceLayerInfo,
        this.config.features.freewheelEnabled,
        this.config.features.freewheelTestEnv
      );
    }
    this.pauseAds.fetchPauseAd();
  }

  private updateTrackingManager(asset: PlaybackAsset, media: PlaybackMedia) {
    let mediaWithPossibleNewManifestUrl = media;
    if (media.accessUrl) {
      mediaWithPossibleNewManifestUrl.manifestUrl = media.accessUrl;
    }
    this.trackingManager.updateMedia(asset, mediaWithPossibleNewManifestUrl);
  }

  private mapAndSetMetadata = (load: Load, asset: PlaybackAsset, runtimeConfig: AvodAuxiliaryData): void => {
    (runtimeConfig as AvodAuxiliaryData).isClip = asset.metadata.isClip;
    const metadata = PlaybackApiMapper.MapMetadata(asset, this.config);
    if (metadata) {
      load.content.metadata = metadata;
    }
  };

  private handleNoStreamsFound = (): never => {
    throw ServiceLayerErrorFactory.createTerminatingError(
      'HandleLoad',
      'NoStreamsFound',
      'No Streams returned from PlaybackApiMapper'
    );
  };

  private handleTrackingManagerUpdateError = (e: any): void => {
    const error = ServiceLayerErrorFactory.createTerminatingError(
      'HandleLoad',
      'TrackingManagerUpdateError',
      'trackingManager failed to update!'
    );
    error.fatal = false;
    error.normalizedError.details = e;
    this.receiver.dispatchError(error);
  };

  private setPlayerConfigurationProfile = (isStreamLive: boolean): void => {
    if (isStreamLive) {
      this.receiver.setPlayerConfigurationProfile('live');
    }
  };

  private initializeYospacePlayback = async (
    stream: Stream,
    isStreamLive: boolean,
    asset: PlaybackAsset,
    load: Load
  ): Promise<void> => {
    if (isYospace(stream) && this.yospacePlaybackFeature) {
      try {
        const videoPlaza: Videoplaza = asset.trackingData.videoplaza;
        const configuration = {
          skipBumpers: false,
          skipPrerolls: false,
          isLive: isStreamLive,
        };
        const serviceLayerInfo = this.serviceLayerInfo;
        const segmentTags = this.segmentTags;
        load.content.streams = await this.yospacePlaybackFeature.initialize({
          configuration,
          load,
          serviceLayerInfo,
          videoPlaza,
          viewedAdvertisementBreaks: [],
          segmentTags,
        });
      } catch (e) {}
    }
  };

  private handleInitialPlayback() {
    if (this.serviceLayerInfo.serviceCountry) {
      this.receiver.setLanguage(this.serviceLayerInfo.serviceCountry);
    }
    /**
     * Set up profiles for CC gen 1
     * **this.applicationData.device**
     * PlaybackManager: low entry point
     * Engine: Limit top bitrate & Diminished confidence!
     */

    this.playbackApiService.initialize({
      playbackApiUrl: this.config.backend.playbackApiEndpoint,
      protocol: ['hls', 'dash'],
      drm: 'widevine',
      service: this.config.service.serviceId,
    });

    this.isInitalPlayback = false;
  }

  private getAccessTokenByRefreshToken = async (refreshToken?: string, profileId?: string): Promise<string> => {
    if (!refreshToken)
      throw ServiceLayerErrorFactory.createTerminatingError('Credentials', 'MissingOrInvalidRefreshToken');

    const isMTV = this.config.service.serviceId === 'mtv';
    const url = new URL(`${isMTV ? credentialsConfig.mtvAuthEndpoint : credentialsConfig.tv4AuthEndpoint}/refresh`);
    const body = JSON.stringify({
      refresh_token: refreshToken,
      ...(profileId ? { profile_id: profileId } : {}),
    });

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: body,
    };
    return fetch(url.toString(), options)
      .then((response) => response.json())
      .then((result) => {
        return result.access_token;
      });
  };

  private startPeriodicTokenRefresh(credentials: CredentialsWithRefreshToken): void {
    if (!credentials?.refreshToken || this.credentialsRefreshInterval) return;
    this.credentialsRefreshInterval = setInterval(async () => {
      try {
        const accessToken = await this.getAccessTokenByRefreshToken(credentials.refreshToken, this.profileId);
        this.updateCredentials(accessToken);
      } catch (error) {
        console.log('Failed to fetch refresh token', { error });
      }
    }, credentialsConfig.tokenRefreshInterval);
  }

  private async updateCredentials(jwtToken?: string) {
    // This is indication required on the given service
    // Does the law request include credentials, If not, Check if we have a token already
    // if the loadRequest is nextContent it will not include credentials
    let token;
    const tokenType = getTokenType(jwtToken);
    if (tokenType === 'access') {
      this.updateProfileId(jwtToken);
    }

    if (tokenType === 'refresh') {
      token = await this.getAccessTokenByRefreshToken(jwtToken, this.profileId).catch((error) => {
        console.log('Failed to get accessToken from refreshToken, will throw error', error);
        throw ServiceLayerErrorFactory.createTerminatingError('Credentials', 'MissingOrInvalidRefreshToken');
      });
    } else {
      token = jwtToken;
    }

    // Check incoming token against stored token, if they don't match we update the user
    // This check is important as it's possible for a sender to change user while a session is ongoing
    if (token && token !== this.token) {
      this.token = token.replace('Bearer ', '');
      this.playbackApiService.updateJwtToken(this.token);
      console.log('Updated accesstoken');

      const userInfo = getUserInfoFromJwtToken(this.token);
      if (userInfo) {
        this.user = new BonnierSdkSimpleUser(this.token, userInfo);
      } else {
        throw ServiceLayerErrorFactory.createTerminatingError(
          'HandleLoad',
          'TokenParserError',
          'Failed to extract user information from token'
        );
      }
    }

    // We update the user information regardless of login status
    // if login is not required we use the default dummy user, created in the constructor
    // this.applicationData.setUserInfo(this.user.getUser());
    this.serviceLayerInfo.user = this.user.getUser();
  }
}
