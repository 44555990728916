export const getTopLocation = (): string => {
  const location = window.location;
  if (window.top && window.top.location !== location) {
    return document.referrer;
  }
  return location.href;
};

export const getHostName = (url?: string): string => {
  const href = url || window.location.href;
  const host = new URL(href).host;
  return host.replace(/^www\./, '');
};

export const getTopLocationHost = (): string => {
  return getHostName(getTopLocation());
};

export const addOrAppendQueryParameter = (searchParams: URLSearchParams, key: string, value: string) => {
  const existingT = searchParams.has(key) && searchParams.get(key);

  searchParams.set(key, existingT ? `${existingT},${value}` : value);
};
