const Utils = require('../utils/Utils');

/**
 * @typedef {object} TLoadTrackingArgs
 * @property {string} [consentString]
 */
/**
 * @type {TLoadTrackingArgs}
 */
class Props {
    constructor() {
        this.consentString = undefined;
    }
}

class LoadTracking extends Props {
    /**
     * @param {TLoadTrackingArgs} args
     */
    constructor(args = {}) {
        super();

        Utils.extend(this, {}, args);
    }
}

module.exports = LoadTracking;