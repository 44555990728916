import {
  AdvertisementBreakTypes,
  ErrorCategories,
  StandardError,
  StreamCueTypes,
  AdvertisementInfo, AdvertisementBreakInfo
} from '@tv4/one-playback-sdk-shared';

import {
  YSPlayerEvents,
  YSSessionManager,
  YSTimelineAdvertElement,
  YSTimelineElement
} from './lib/yospace-1.8.12.min';

import { getPersistentAdvertisementId } from '../../../../utils';
import { Advertisement } from './dtos/Advertisement';

import { AdvertisementBreak } from './dtos/AdvertisementBreak';

import { TYospaceCallbacks, TYospaceSeekPositionData } from './Types';
import { Id3Cue, StreamCue } from '../../../Types';
import { parseYospaceTimedDataObjectFromId3Cues } from './utils/TimedDataParsers';
import { ID3Parser } from '../../../utils';
import { addOrAppendQueryParameter } from '../../../../utils';

type TVastAdExtensionData = {
  campaignId: string;
  customId: string;
  goalId: string;
  sponsor: boolean;
};

interface AdInfoElement extends Element {
  attributes: Element["attributes"] & {
    cid?: { value: string }
    customaid?: { value: string }
    gid?: { value: string }
    variant?: { value: string }
  }
}

const getDataFromAdInfo = (adInfo?: AdInfoElement) => ({
  campaignId: adInfo?.attributes?.cid?.value || "",
  customId: adInfo?.attributes?.customaid?.value || "",
  goalId: adInfo?.attributes?.gid?.value || "",
  sponsor: adInfo?.attributes?.variant?.value === "BUMPER",
})

const getVastAdExtensionData = (adExtensions: Array<Element>): TVastAdExtensionData => {
  // Look explicitly for a videoplaza extension with AdInfo
  const videoplazaExtension: Element | undefined = adExtensions
    ?.find((extension => extension?.getAttribute('name') === 'Videoplaza'));

  // Extract videoplaza AdInfo
  const videoplazaAdInfo: AdInfoElement | undefined = videoplazaExtension?.getElementsByTagName('AdInfo')[0];

  if (videoplazaAdInfo) {
    return getDataFromAdInfo(videoplazaAdInfo);
  }

  // Look for any extension with AdInfo
  const extensionWithAdInfo: Element | undefined = adExtensions?.find(extension => extension.getElementsByTagName('AdInfo')[0]);
  // Extract AdInfo
  const adInfo: AdInfoElement | undefined = extensionWithAdInfo?.getElementsByTagName('AdInfo')[0];

  return getDataFromAdInfo(adInfo);
};

const getStitchedUrl = (masterPlaylist: string): string | undefined => {
  return masterPlaylist && masterPlaylist.indexOf('https://null/') === 0
    ? undefined
    : masterPlaylist;
};

const YospaceSuccessfulResult = 'ready';
const AdvertisementViewedMarginSeconds = 2;

export type TYospaceConfiguration = {
  advertisingId?: string;
  applicationVersion: string;
  callbacks: TYospaceCallbacks;
  contentId: string;
  contentUrl: string;
  debug?: boolean;
  deviceAdvertisementId: string;
  extraParameters?: Record<string, string>;
  extraTags?: Array<string>;
  gdprConsentString?: string;
  live: boolean;
  serviceCountry: string;
  serviceId: string;
  skipBumpers: boolean;
  skipPrerolls: boolean;
  startOver: boolean;
  startTime?: number;
  userId?: string;
  viewedAdvertisementBreaks?: Array<number>;
};

export class YospacePlaybackFeature {
  private readonly configuration: TYospaceConfiguration;

  private currentTime: number;
  private hasPlayedOnce: boolean;
  private isBuffering: boolean;
  private sessionManager?: YSSessionManager;
  private stitchedUrl?: string;
  private yospaceTimedDataObjects: Array<any>;

  public advertisementBreaks: Array<AdvertisementBreak>;
  public contentDuration: number;
  public inAdBreak: boolean;

  constructor(configuration: TYospaceConfiguration) {
    this.configuration = configuration;

    this.advertisementBreaks = [];
    this.contentDuration = 0;
    this.currentTime = 0;
    this.hasPlayedOnce = false;
    this.isBuffering = false;
    this.yospaceTimedDataObjects = [];
    this.inAdBreak = false;

    if (this.configuration.debug) {
      YSSessionManager.DEFAULTS.DEBUGGING = true; // enable yospace debugging
    }
  }

  public async initialize(): Promise<string> {
    const yospaceUrl = this.prepareYospaceUrl(this.configuration.contentUrl, this.configuration.extraParameters);
    const result = await this.setupYospace(yospaceUrl);

    if (result !== YospaceSuccessfulResult || !this.sessionManager) {
      throw new StandardError({
        category: ErrorCategories.API,
        code: 'AD_LOAD_FAILED',
        fatal: true,
        details: {
          origin: 'Yospace',
          domain: 'initialize',
          configuration: this.configuration,
          result
        }
      });
    }

    this.stitchedUrl = getStitchedUrl(this.sessionManager.masterPlaylist());
    if (!this.stitchedUrl) {
      throw new StandardError({
        category: ErrorCategories.API,
        code: 'AD_STITCHING_FAILED',
        fatal: true,
        details: {
          origin: 'Yospace',
          domain: 'initialize',
          configuration: this.configuration
        }
      });
    }

    this.setupCallbacks(this.configuration.callbacks);
    this.setupAdvertisementBreaks();

    return this.stitchedUrl;
  }

  private getAdvertisementStartData(): AdvertisementInfo | undefined {
    if (
      !this.sessionManager ||
      !this.sessionManager.session ||
      !this.sessionManager.session.currentAdvert ||
      !this.sessionManager.session.currentAdvert.advert
    ) {
      return;
    }

    const advert = this.sessionManager.session.currentAdvert.advert;
    const advertWrapper = this.sessionManager.session.currentAdvert;
    const durationInSeconds: number = this.sessionManager.session.currentAdvert.duration;

    let positionInAdBreak;
    let totalAdsInAdBreak;

    const adBreak = this.sessionManager.session.getCurrentBreak();
    if (adBreak?.adverts?.length) {
      const index = adBreak.adverts.findIndex((wrapper) => wrapper.startPosition === advertWrapper.startPosition);
      if (index !== -1) {
        positionInAdBreak = index + 1; 
        totalAdsInAdBreak = adBreak.adverts.length;
      } 
    }

    const id: string = advert.id;
    const name: string = advert.AdTitle;
    const breakType = this.getAdvertisementBreakType();
    const { campaignId, customId, goalId, sponsor } = getVastAdExtensionData(
      advert.Extensions
    );

    return {
      id,
      name,
      breakType,
      durationInSeconds,
      campaignId,
      customId,
      goalId,
      sponsor,
      positionInAdBreak,
      totalAdsInAdBreak 
    };
  }

  private handleAdvertisementBreakEnd(): AdvertisementBreakInfo | undefined {
    this.inAdBreak = false;

    let lastActiveBreak: AdvertisementBreak | undefined;
    this.advertisementBreaks.forEach(advertisementBreak => {
      if (advertisementBreak.active) {
        advertisementBreak.active = false;
        advertisementBreak.watched = true;
        lastActiveBreak = advertisementBreak;
      }
    });

    return lastActiveBreak ? {
      ...lastActiveBreak,
      advertisements: lastActiveBreak
        .advertisements
        .map(ad => ({ ...ad }))
    } : undefined;
  }

  private handleAdvertisementBreakStart(): AdvertisementBreakInfo | undefined {
    if (!this.sessionManager || !this.sessionManager.session) return;

    this.inAdBreak = true;

    const currentBreak = this.sessionManager.session.getCurrentBreak();

    let lastActiveBreak: AdvertisementBreak | undefined;

    this.advertisementBreaks.forEach(advertisementBreak => {
      if (advertisementBreak.position === currentBreak.startPosition) {
        advertisementBreak.active = true;
        lastActiveBreak = advertisementBreak;
      }
    });

    return lastActiveBreak ? {
      ...lastActiveBreak,
      advertisements: lastActiveBreak
        .advertisements
        .map(ad => ({ ...ad }))
    } : undefined;
  }

  private prepareYospaceUrl(contentUrl: string, extraParameters?: Record<string, string>): string {
    let tags = `${this.configuration.serviceId.toLowerCase()}.${this.configuration.serviceCountry.toLowerCase()},ns_st_mv-${this.configuration.applicationVersion}`;

    if (this.configuration.startOver) {
      tags += ',startover';
    }

    if (this.configuration.skipBumpers) {
      tags += ',NOBUMPERS';
    }

    if (this.configuration.extraTags) {
      tags += `,${this.configuration.extraTags.join(',')}`;
    }

    const yospaceUrl = new URL(contentUrl);
    yospaceUrl.searchParams.set('dcid', this.configuration.deviceAdvertisementId);
    yospaceUrl.searchParams.set('pid', getPersistentAdvertisementId(this.configuration.userId));
    addOrAppendQueryParameter(yospaceUrl.searchParams, 't', tags);

    if (this.configuration.advertisingId) {
      yospaceUrl.searchParams.set('cp.ifa', this.configuration.advertisingId);
    }
    if (this.configuration.gdprConsentString) {
      yospaceUrl.searchParams.set('gdpr_consent', this.configuration.gdprConsentString);
      yospaceUrl.searchParams.set('gdpr', '1');
    }
    if (this.configuration.skipPrerolls) {
      yospaceUrl.searchParams.set('f', 'noprerolls');
    }
    if (this.configuration.startOver && this.configuration.startTime) {
      yospaceUrl.searchParams.set('yo.so', this.configuration.startTime.toString());
    }

    if (extraParameters) {
      Object.keys(extraParameters).forEach((key) => {
        yospaceUrl.searchParams.set(key, extraParameters[key]);
      });
    }

    return yospaceUrl.href;
  }

  private setupAdvertisementBreaks() {
    if (!this.sessionManager) return;

    const timeline = this.sessionManager.getTimeline();
    if (!timeline || !timeline.elements) return;

    let nextAdvertisementId = -1;
    let nextAdvertisementBreakId = -1;
    timeline.elements.forEach((element: YSTimelineAdvertElement | YSTimelineElement) => {
      if (element instanceof YSTimelineAdvertElement) {
        const adStart = this.getPositionWithoutAds(element.offset);
        const viewedAdvertisementBreaks = this.configuration.viewedAdvertisementBreaks ?? [];
        const watched =
          viewedAdvertisementBreaks.some(
            (viewedStart) => Math.abs(viewedStart - adStart) < AdvertisementViewedMarginSeconds
          ) ||
          (this.configuration.startTime != null && adStart < this.configuration.startTime);

        const breakType = element.offset === 0 ? AdvertisementBreakTypes.PREROLL : AdvertisementBreakTypes.MIDROLL;

        const advertisements = element.adBreak.adverts.map((advertWrapper: any, index) => new Advertisement({
          id: `Advertisement-${this.configuration.contentId}-${++nextAdvertisementId}`,
          name: advertWrapper.advert?.AdTitle,
          durationInSeconds: advertWrapper.duration,
          breakType,
          adNumberInBreak: index + 1,
          sponsor: getVastAdExtensionData(advertWrapper.advert.Extensions).sponsor,
        }));

        this.advertisementBreaks.push(new AdvertisementBreak({
          id: `AdvertisementBreak-${this.configuration.contentId}-${++nextAdvertisementBreakId}`,
          name: '',
          active: false,
          advertisements,
          breakType,
          durationInSeconds: element.duration,
          embedded: true,
          position: element.offset,
          watched
        }));
      }
      else {
        this.contentDuration += element.duration;
      }
    });
  }

  private setupCallbacks(callbacks: TYospaceCallbacks) {
    if (!this.sessionManager) return;

    this.sessionManager.registerPlayer({
      AdBreakStart: () => callbacks.advertisementBreakStart(this.handleAdvertisementBreakStart()),
      AdBreakEnd: () => callbacks.advertisementBreakEnd(this.handleAdvertisementBreakEnd()),
      AdvertStart: () => callbacks.advertisementStart(this.getAdvertisementStartData()),
      AdvertEnd: () => callbacks.advertisementEnd(),
    });
  }

  private setupYospace(yospaceUrl: string): Promise<any> {
    return new Promise((resolve) => {
      const yospaceFunction = this.configuration.live
        ? YSSessionManager.createForLive
        : YSSessionManager.createForVoD;
      const yospaceParameters = this.configuration.live
        ? { IS_REDIRECT: false }
        : null;

      this.sessionManager = yospaceFunction(
        yospaceUrl,
        yospaceParameters,
        resolve
      );
    });
  }

  private getPastAdvertisementBreak(positionWithAds: number): AdvertisementBreak | undefined {
    let pastAdvertisementBreak: AdvertisementBreak | undefined;

    this.advertisementBreaks.forEach(advertisementBreak => {
      if (advertisementBreak.isPositionPastAdvertisementBreak(positionWithAds)) {
        pastAdvertisementBreak = advertisementBreak;
      }
    });

    return pastAdvertisementBreak;
  }

  private getPositionWithAdsBeforeOrAfterBreak(positionWithoutAds: number): number {
    let newPosition: number = positionWithoutAds;

    this.advertisementBreaks.forEach((advertisementBreak) => {
      if (
        advertisementBreak.isPositionPastAdvertisementBreak(newPosition) ||
        advertisementBreak.isPositionInAdvertisementBreak(newPosition)
      ) {
        newPosition += advertisementBreak.durationInSeconds;
      }
    });

    return newPosition;
  }

  public getAdvertisementBreaks(): Array<AdvertisementBreakInfo> {
    return this.advertisementBreaks.map(({
      id,
      name,
      breakType,
      durationInSeconds,
      advertisements,
      embedded,
      position
    }) => new AdvertisementBreakInfo({
      id,
      name,
      breakType,
      durationInSeconds,
      advertisements: advertisements.map((a) => new AdvertisementInfo(a)),
      embedded,
      position
    }));
  }

  public getAdvertisementBreakForPosition(currentTime: number): AdvertisementBreak | undefined {
    return this.advertisementBreaks.find(advertisementBreak => advertisementBreak.isPositionInAdvertisementBreak(currentTime));
  }

  public getAdvertisementBreakType(): AdvertisementBreakTypes {
    const lastActiveBreak = this.advertisementBreaks
      .filter(advertisementBreak => advertisementBreak.active)
      .pop();
    if (
      !this.configuration.live &&
      lastActiveBreak &&
      lastActiveBreak.position === 0
    ) {
      return AdvertisementBreakTypes.PREROLL;
    }
    return AdvertisementBreakTypes.MIDROLL;
  }

  public getCurrentAdvertisementClickThroughUrl(): string | undefined {
    if (
      !this.sessionManager ||
      !this.sessionManager.session ||
      !this.sessionManager.session.currentAdvert ||
      !this.sessionManager.session.currentAdvert.advert ||
      !this.sessionManager.session.currentAdvert.advert.linear ||
      !this.sessionManager.session.currentAdvert.advert.linear.clickThrough
    ) return;

    return this.sessionManager.session.currentAdvert.advert.linear.clickThrough;
  }

  public getCurrentAdvertisementDuration(): number {
    if (!this.sessionManager || !this.sessionManager.session || !this.sessionManager.session.currentAdvert) return 0;
    return this.sessionManager.session.currentAdvert.duration;
  }

  public getCurrentAdvertisementTime(): number {
    if (!this.sessionManager || !this.sessionManager.session || !this.sessionManager.session.currentAdvert) return 0;
    return this.sessionManager.session.currentAdvert.timeElapsed();
  }

  public getPositionWithAds(positionWithoutAds: number): number {
    const pos = this.getPositionWithAdsBeforeOrAfterBreak(positionWithoutAds);

    const pastBreak = this.getPastAdvertisementBreak(pos);

    if (pastBreak && !pastBreak.watched) {
      return pastBreak.position;
    }

    return pos;
  }

  public getPositionWithoutAds(positionWithAds: number): number {
    let adTime = 0;

    this.advertisementBreaks.forEach(advertisementBreak => {
      if (advertisementBreak.isPositionPastAdvertisementBreak(positionWithAds)) {
        adTime += advertisementBreak.durationInSeconds;
      }
      else if (advertisementBreak.isPositionInAdvertisementBreak(positionWithAds)) {
        adTime += positionWithAds - advertisementBreak.position;
      }
    });

    return positionWithAds - adTime;
  }

  public getSeekPosition(positionWithoutAds: number): TYospaceSeekPositionData {
    const seekPosition = this.getPositionWithAds(positionWithoutAds);
    const seekPositionAfterBreakEnd = this.getPositionWithAdsBeforeOrAfterBreak(positionWithoutAds);

    return {
      seekPosition,
      seekPositionAfterBreakEnd: seekPositionAfterBreakEnd === seekPosition ? undefined : seekPositionAfterBreakEnd,
    };
  }

  // Matching ITracker

  public loaded(): void {
    if (!this.sessionManager) return;

    // Loaded indicates we are ready to play, run START
    this.sessionManager.reportPlayerEvent(YSPlayerEvents.START);

    // Force pre-roll ad break to trigger before playing event
    if (!this.configuration.live) {
      this.sessionManager.reportPlayerEvent(YSPlayerEvents.POSITION, this.configuration.startTime || 0);
    }

    // We're not playing until playing event arrives
    this.sessionManager.reportPlayerEvent(YSPlayerEvents.PAUSE);
  }

  public advertisementClicked(): void {
    if (!this.sessionManager) return;
    this.sessionManager.reportPlayerEvent(YSPlayerEvents.CLICK);
  }

  public buffered(): void {
    if (!this.sessionManager || !this.isBuffering) return;

    this.isBuffering = false;
    this.sessionManager.reportPlayerEvent(YSPlayerEvents.CONTINUE);
  }

  public buffering(): void {
    if (!this.sessionManager || this.currentTime <= 3) return;

    this.isBuffering = true;
    this.sessionManager.reportPlayerEvent(YSPlayerEvents.STALL);
  }

  public paused(): void {
    if (!this.sessionManager) return;
    this.sessionManager.reportPlayerEvent(YSPlayerEvents.PAUSE);
  }

  public playing(): void {
    if (!this.sessionManager) return;

    this.sessionManager.reportPlayerEvent(YSPlayerEvents.RESUME);
  }

  public id3Cue(id3Cue: Id3Cue) {
    const yospaceTimedDataObject = parseYospaceTimedDataObjectFromId3Cues(id3Cue);
    this.yospaceTimedDataObjects.push(yospaceTimedDataObject);
  }

  public streamCue(streamCue: StreamCue) { // ToDo: Remove ?
    if (streamCue.type === StreamCueTypes.ID3) {
      if (!streamCue.startTime) return;

      const id3Cue: Id3Cue = streamCue.parsed || ID3Parser.Parse(streamCue.raw, streamCue.startTime);
      this.id3Cue(id3Cue);
    }
    else if (streamCue.type === StreamCueTypes.ID3_IN_EMSG) {
      // ToDo: Parse Id3 in emsg messageData
      // this.id3Cue(streamCue.parsed, streamCue.startTime);
    }
    else if (streamCue.type === StreamCueTypes.EMSG) {
      // this.emsgCue(streamCue.parsed, streamCue.startTime);
    }
  }

  public stopped(): void {
    if (!this.sessionManager) return;
    this.sessionManager.reportPlayerEvent(YSPlayerEvents.END);
  }

  public timeUpdate(positionWithAds: number): void {
    if (!this.sessionManager || !this.sessionManager.session) return;

    this.currentTime = positionWithAds;

    if (!isNaN(this.currentTime)) {
      const currentAdBreak = this.advertisementBreaks.find(advertisementBreak => advertisementBreak.isPositionInAdvertisementBreak(this.currentTime));
      if (!currentAdBreak || !currentAdBreak.watched) { // ToDo: Should it really not report progress for watched breaks? Granted, they will be seeked over but still seems odd...
        this.sessionManager.reportPlayerEvent(YSPlayerEvents.POSITION, this.currentTime);

        this.yospaceTimedDataObjects.forEach((id3, index) => {
          if (!this.sessionManager) return;

          if (id3.time <= this.currentTime) {
            this.sessionManager.reportPlayerEvent(YSPlayerEvents.METADATA, id3.tag);
            this.yospaceTimedDataObjects.splice(index, 1);
          }
        });
      }
    }
  }

  async reset(): Promise<void> {
    if (this.sessionManager) {
      this.sessionManager.shutdown();
    }
    this.sessionManager = undefined;

    this.advertisementBreaks = [];
    this.isBuffering = false;
    this.contentDuration = 0;
    this.currentTime = 0;
    this.hasPlayedOnce = false;
    this.yospaceTimedDataObjects = [];
    this.inAdBreak = false;
    this.stitchedUrl = undefined;
  }

  async destroy(): Promise<void> {
    await this.reset();
  }
}
