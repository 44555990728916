module.exports = class ShakaDashStaticTransitionHandler {
    constructor({ durationInSeconds } = {}) {
        this.canHandle = true;
        this.durationInSeconds = durationInSeconds;
        this.textDecoder = null;
        this.textEncoder = null;

        try {
            this.textDecoder = new TextDecoder();
            this.textEncoder = new TextEncoder();
        } catch (e) {
            this.canHandle = false;
        }
    }

    responseFilter() {
        return (type, response) => {
            // type: 0 === shaka.net.NetworkingEngine.html.RequestType.Manifest
            if (!this.canHandle || type !== 0) return response;

            try {
                const manifestStr = this.textDecoder.decode(response.data);
                const parser = new DOMParser();
                const manifestDoc = parser.parseFromString(manifestStr, 'application/xml');
                const mpd = manifestDoc.querySelector('MPD');
                let didAddPresentationTimeOffset = false;
                const segmentTemplates = manifestDoc.querySelectorAll('SegmentTemplate');
                if (segmentTemplates.length) {
                    for (let i in segmentTemplates) {
                        if (!segmentTemplates.hasOwnProperty(i)) continue;

                        const segmentTemplate = segmentTemplates[i];
                        const firstSegment = segmentTemplate.querySelector('S');
                        if (segmentTemplate && firstSegment) {
                            const unscaledTime = firstSegment.getAttribute('t');
                            if (unscaledTime) {
                                segmentTemplate.setAttribute('presentationTimeOffset', unscaledTime);
                                didAddPresentationTimeOffset = true;
                            }
                        }
                    }
                }

                if (didAddPresentationTimeOffset) {
                    if (this.durationInSeconds) {
                        mpd.setAttribute('mediaPresentationDuration', `PT${this.durationInSeconds}S`);
                        mpd.removeAttribute('timeShiftBufferDepth');
                    }

                    response.data = this.textEncoder.encode(manifestDoc.documentElement.outerHTML);
                }
            } catch (e) {
            } // Ignore errors and return unmodified manifest if modifications fail

            return response;
        };
    }
};