export class ServiceInfo {
    public serviceBrand: string;
    public serviceCountry: string;
    public serviceId: string;
    public serviceName: string;

    constructor(args?: ServiceInfo) {
        if (args) {
            this.serviceBrand = args.serviceBrand;
            this.serviceCountry = args.serviceCountry;
            this.serviceId = args.serviceId;
            this.serviceName = args.serviceName;
        }
    }
};