//
//  EpgUpdateTimer.ts
//
//  Created by Lars Rothaus on 19/03/2021.
//  Copyright © 19/03/2021 Lars Rothaus. All rights reserved.
//

import { ChannelMetadata } from '../Types';
import { Logger } from '@tv4/one-playback-sdk-shared';

type EpgUpdateTimerOptions = {
    thresholdInMinutes: number;
    fuzzFactorSec: number;
};

export class EpgUpdateTimer {
    private timeToUpdate: number = -1;
    private updateTimer: any;
    private scheduledUpdate?: Date;

    constructor(channelMetadata: ChannelMetadata, callback: () => void, options: EpgUpdateTimerOptions = { thresholdInMinutes: 5, fuzzFactorSec: 60 }) {
        if (channelMetadata.programs.length > 0) {
            let lastProgram = channelMetadata.programs[channelMetadata.programs.length - 1];
            if (lastProgram.endTime) {
                this.timeToUpdate = (lastProgram.endTime - Date.now()) / 1000;
                const threshold = (options.thresholdInMinutes * 60) - (Math.random() * options.fuzzFactorSec);
                if (threshold > 0 && threshold < this.timeToUpdate) {
                    this.timeToUpdate = this.timeToUpdate - threshold;
                }

                this.scheduledUpdate = new Date(lastProgram.endTime - (threshold * 1000));

                Logger.log(`EpgUpdateTimer[constructor] : epg update scheduled for ${this.scheduledUpdate.toLocaleTimeString()}!`);

                this.updateTimer = setTimeout(callback, this.timeToUpdate * 1000);
            }
            else{
                Logger.error(`EpgUpdateTimer[constructor] : No endTime programItems!`);
            }
        }
        else{
            Logger.error(`EpgUpdateTimer[constructor] : No programs in ChannelMetadata!`);
        }
    }

    public getScheduledUpdate(): Date | undefined {
        return this.scheduledUpdate;
    }

    public kill() {
        if (this.updateTimer) {
            Logger.warn(`EpgUpdateTimer[kill] : EpgUpdateTimer was destroyed!`);
            clearTimeout(this.updateTimer);
            this.updateTimer = null;
        }
    }
};