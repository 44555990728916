//
//  EventPayloads.js
//
//  Created by Lars Rothaus on 02/02/2022.
//  Copyright © 02/02/2022 Lars Rothaus. All rights reserved.
//
const { Drm } = require('../dtos');
const { StoppedReasons, StreamCueTypes } = require('../Constants');
/**
 * @typedef AdvertisementInfo
 * @property {string } id
 * @property {string } name
 * @property {number } durationInSeconds
 * @property { "MIDROLL" | "POSTROLL" | "PREROLL"} breakType
 * @property { string } [campaignId]
 * @property { string } [customId]
 * @property { string } [goalId]
 * @property {boolean } sponsor
 * @property { number } [positionInAdBreak]
 * @property { number } [totalAdsInAdBreak]
 */

/**
 * @typedef AdvertisementBreakInfo
 * @property {string} id
 * @property {string} name
 * @property {"MIDROLL" | "POSTROLL" | "PREROLL"} breakType
 * @property {number} durationInSeconds
 * @property {Array<AdvertisementInfo>} advertisements
 * @property {boolean} embedded
 * @property {number} position
 */


/**
 * @typedef AdvertisementEndedEvent
 */
const AdvertisementEndedEvent = {};
/**
 * @typedef AdvertisementStartedEvent
 */
const AdvertisementStartedEvent = {};
/**
 * @typedef AdvertisementBreakEndedEvent
 */
const AdvertisementBreakEndedEvent = {};
/**
 * @typedef AdvertisementBreakStartedEvent
 */
const AdvertisementBreakStartedEvent = {};
/**
 * @typedef AdvertisementTimeUpdateEvent
 */
const AdvertisementTimeUpdateEvent = {};
/**
 * @typedef BitrateChangedEvent
 */
const BitrateChangedEvent = {};
/**
 * @typedef BufferedEvent
 */
const BufferedEvent = {};
/**
 * @typedef BufferingEvent
 */
const BufferingEvent = {};
/**
 * @typedef CdnChangedEvent
 * @property { string } cdn
 */
const CdnChangedEvent = {};
/**
 * @typedef DrmChangedEvent
 * @property { Drm } drm
 */
const DrmChangedEvent = {};
/**
 * @typedef DroppedFramesEvent
 * @property { number } frames
 * @property { number } total
 */
const DroppedFramesEvent = {};
/**
 * @typedef LoadStartEvent
 */
const LoadStartEvent = {};
/**
 * @typedef LoadedDataEvent
 */
const LoadedDataEvent = {};
/**
 * @typedef LoadedMetadataEvent
 * @type {{contentUrl: string}}
 */
const LoadedMetadataEvent = {};
/**
 * @typedef PauseEvent
 */
const PauseEvent = {};
/**
 * @typedef PlayEvent
 */
const PlayEvent = {};

/**
 * @typedef SeekableRange
 * @property { number } [start]
 * @property { number } [end]
 * @property { boolean } [isMovingWindow]
 * @property { boolean } [isLiveDone]
 */

/**
 * @typedef SeekableRangeUpdatedEvent
 * @property { duration } duration
 * @property { SeekableRange } seekableRange
 */
const SeekableRangeUpdatedEvent = {};
/**
 * @typedef SeekedEvent
 */
const SeekedEvent = {};
/**
 * @typedef SeekingEvent
 */
const SeekingEvent = {};
/**
 * @typedef StreamChangedEvent
 */
const StreamChangedEvent = {};

/**
 * @typedef StreamCueEvent
 * @property {StreamCueTypes} type
 * @property { * } parsed
 * @property { Uint8Array }[raw]
 * @property { number }[endTime]
 * @property { number }[eventDuration]
 * @property { number }[id]
 * @property { number }[presentationTimeDelta]
 * @property { string }[schemeIdUri]
 * @property { number }[startTime]
 * @property { number }[timescale]
 * @property { any } [value]
 */
const StreamCueEvent = {};

/**
 * @typedef StreamFinishedEvent
 * @property { number } [currentTime]
 * @property { number } [duration]
 * @property {StoppedReasons} reason
 */
const StreamFinishedEvent = {};
/**
 * @typedef SystemVolumeChangedEvent
 */
const SystemVolumeChangedEvent = {};
/**
 * @typedef TimeUpdateEvent
 */
const TimeUpdateEvent = {};

export {
    AdvertisementEndedEvent,
    AdvertisementStartedEvent,
    AdvertisementBreakEndedEvent,
    AdvertisementBreakStartedEvent,
    AdvertisementTimeUpdateEvent,
    BitrateChangedEvent,
    BufferedEvent,
    BufferingEvent,
    CdnChangedEvent,
    DrmChangedEvent,
    DroppedFramesEvent,
    LoadStartEvent,
    LoadedDataEvent,
    LoadedMetadataEvent,
    PauseEvent,
    PlayEvent,
    SeekableRangeUpdatedEvent,
    SeekedEvent,
    SeekingEvent,
    StreamChangedEvent,
    StreamCueEvent,
    StreamFinishedEvent,
    SystemVolumeChangedEvent,
    TimeUpdateEvent
}