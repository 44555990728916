const Utils = require('../utils/Utils');

/**
 * @typedef {object} TAndroidDeviceInfo
 * @property {string} build
 * @property {string} version
 */

/**
 * @typedef {object} TDeviceInfoArgs
 * @property {TAndroidDeviceInfo} [android]
 * @property {string} cafVersion
 * @property {string} deviceId
 * @property {string} firmwareVersion
 * @property {string} generation
 * @property {string} language
 * @property {string} [shakaVersion=null]
 *
 */
/**
 * @type {TDeviceInfoArgs}
 */
class Props {
    constructor() {
        this.android = this.cafVersion = this.deviceId = this.firmwareVersion = this.generation = this.language = undefined;
    }
}

class DeviceInfo extends Props {
    /**
     * @param {TDeviceInfoArgs} args
     */
    constructor(args = {}) {
        super();

        Utils.extend(this, {}, args);
    }
}

module.exports = DeviceInfo;