import Bowser from 'bowser';

export class OperatingSystemInfo {
    public operatingSystemName: string;
    public operatingSystemVersion: string;
    public operatingSystemFirmwareVersion?: string;

    constructor(args?: OperatingSystemInfo) {
        if (!args) {
            args = {
                operatingSystemName: undefined,
                operatingSystemVersion: undefined,
                operatingSystemFirmwareVersion: undefined
            };
        }

        const os = Bowser.getParser(window.navigator.userAgent).getOS();
        this.operatingSystemName = args.operatingSystemName || os.name;
        this.operatingSystemVersion = args.operatingSystemVersion || os.version;
        this.operatingSystemFirmwareVersion = args.operatingSystemFirmwareVersion || os.versionName;
    }
};