const EventEmitter = require('eventemitter3');
const {
    CommunicationManagerEvents
} = require('../../Events');

const Utils = require('../../utils/Utils');

module.exports = class CommunicationManager extends EventEmitter {
    constructor({ config }) {
        super();
        this._ready = false;
        this.context = cast.framework.CastReceiverContext
          .getInstance();

        this.messageNamespace = config.messageNamespace;
        this.unifiedMessageNamespace = config.unifiedMessageNamespace;

        this.pendingMessages = [];

        this.registerCommunicationChannels();
    }

    get ready() {
        return this._ready;
    }

    set ready(value) {
        this._ready = value;
        Utils.defer(() => {
            if (this.pendingMessages.length > 0) {
                this.pendingMessages.forEach((value) => {
                    this.context.sendCustomMessage(value.messageNamespace, value.senderId, value.message);
                });
                this.pendingMessages = [];
            }
        }, 100)

    }

    /** @private */
    registerCommunicationChannels() {
        this.context.addCustomMessageListener(this.unifiedMessageNamespace, message => {
            if (message && message.data) {
                try {
                    this.emit(CommunicationManagerEvents.UnifiedMessage, message.data);
                } catch (e) {
                }
            }
        });

        if (this.messageNamespace) {
            this.context.addCustomMessageListener(this.messageNamespace, message => {
                if (message && message.data) {
                    try {
                        const unifiedMessages = [
                            'Command.Eval',
                            'Command.Reload',
                            'Debug.Console',
                            'Debug.Overlay'
                        ];

                        // Until all senders have been updated to support multiple channels
                        if (unifiedMessages.includes(message.data.type)) {
                            return this.emit(CommunicationManagerEvents.UnifiedMessage, message.data);
                        }

                        this.emit(CommunicationManagerEvents.ApplicationMessage, message.data);
                    } catch (e) {
                    }
                }
            });
        }
    }

    /** @private */
    getCommunicationChannels() {
        const communicationChannels = [this.unifiedMessageNamespace];

        if (this.messageNamespace) {
            communicationChannels.push(this.messageNamespace);
        }

        return communicationChannels;
    }

    /**
     * @param {object} message
     * @param {string} [senderId]
     */
    sendMessage(message, senderId) {
        if (this.ready) {
            this.context.sendCustomMessage(this.messageNamespace, senderId, message);
        } else {
            this.pendingMessages.push({
                messageNamespace: this.messageNamespace,
                senderId,
                message
            });
        }
    }

    /**
     * @private
     * @param {object} message
     * @param {string} [senderId]
     */
    sendUnifiedMessage(message, senderId) {
        if (this.ready) {
            this.context.sendCustomMessage(this.unifiedMessageNamespace, senderId, message);
        } else {
            this.pendingMessages.push({
                messageNamespace: this.unifiedMessageNamespace,
                senderId,
                message
            });
        }
    }

    reset() {
        this.pendingMessages = [];
    }

    destroy() {
        this.reset();
        this.removeAllListeners();
    }
};