import { UserInfo } from '../dtos/UserInfo';

export const getUserInfoFromJwtToken = (jwtToken?: string): UserInfo | null => {
  if (!jwtToken) return null;

  const tokenParts = jwtToken.split('.');
  if (tokenParts && tokenParts[1]) {
    try {
      const decoded = atob(tokenParts[1]);
      const parsed = JSON.parse(decoded);
      if (parsed && parsed.uid) {
        return {
          userId: parsed.uid,
          userIsLoggedIn: true,
          userType: 'unset',
          profileId: parsed.profile_id,
        };
      }
    } catch (e) {}
  }
  return null;
};

export type TokenType = 'access' | 'refresh';

export const getTokenType = (jwtToken?: string): TokenType | null => {
  if (!jwtToken) return null;

  const tokenParts = jwtToken.split('.');
  if (tokenParts && tokenParts[1]) {
    try {
      const decoded = atob(tokenParts[1]);
      const { type } = JSON.parse(decoded);
      return type;
    } catch (e) {}
  }
  return null;
};

export const sessionTokenIsExpired = (token: string): boolean => {
  const [_header, body, _sig] = token.split('.');
  const expiryTime = JSON.parse(atob(body))['exp'] || 0;
  return expiryTime - Math.floor(Date.now() / 1000) < 100;
};

export const credentialsConfig = {
  mtvAuthEndpoint: 'https://fin-alb.auth.a2d.tv/oauth',
  tv4AuthEndpoint: 'https://avod-auth-alb.a2d.tv/oauth',
  tokenRefreshInterval: (1 * 60 + 25) * 60 * 1000, // 1 hour and 25 minutes. AccessToken should last for 3hours according to docs when writing this code.
};

export const generateS4 = (): string => {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
};
