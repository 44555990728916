const IShakaPlayer = require('./IShakaPlayer');
const Utils = require('../utils/Utils');

module.exports = class IPlayer extends IShakaPlayer {
    constructor({ getNewLicense, license, mediaElement, textContainer }) {
        super(mediaElement);

        this.destroyed = true;

        this.getNewLicense = getNewLicense;
        this.license = license;
        this.textContainer = textContainer;

        this.shakaConfigurationRootProperties = [
            'abr',
            'abrFactory',
            'drm',
            'manifest',
            'offline',
            'playRangeEnd',
            'playRangeStart',
            'preferForcedSubs',
            'preferredAudioChannelCount',
            'preferredAudioLanguage',
            'preferredTextLanguage',
            'preferredTextRole',
            'preferredVariantRole',
            'restrictions',
            'streaming',
            'textDisplayFactory',
            'useMediaCapabilities'
        ];
    }

    static isSupported() {
    }

    isShakaConfiguration(configuration) {
        return Object.getOwnPropertyNames(configuration).every(c => this.shakaConfigurationRootProperties.includes(c));
    }

    setActiveVariantTrack() {
    }

    reset() {
    }

    destroy() {
        this.destroyed = true;
    }
};