//
//  ContentManager.js
//
//  Created by Lars Rothaus on 28/01/2021.
//  Copyright © 28/01/2021 Lars Rothaus. All rights reserved.
//

const {
    ContentManagerEvents,
    EngineEvents
} = require('../../Events');

const { Metadata, Content } = require('../../dtos');
const Engine = require('../../player/Engine');
const ListenableListener = require('../../event/ListenableListener');
const ContentMapper = require('../../mappers/ContentMapper');
const MetadataMapper = require('../../mappers/MetadataMapper');

//|todo ContentManager.js [BUG]: when error google moves to next content;

module.exports = class ContentManager extends ListenableListener {
    /**
     * @typedef {Object} ContentManagerArgs
     * @param {Object} config
     * @param {Engine} engine
     * @param {QueueManager} queueManager
     */
    /**
     * @param {ContentManagerArgs} args
     */
    constructor(args) {
        super();

        const { config, engine, queueManager } = args;

        this.config = config;
        this.engine = engine;
        this.queueManager = queueManager;

        this.castPlayerManagerEventListeners = {};
        this.engineEventListeners = {};

        this.castPlayerManager = this.engine.playerManager;
        this.castQueueManager = this.castPlayerManager.getQueueManager();

        this.hasUsedContainerMetadata = false;
        this.hasPendingMetadata = false;
        this.pendingMetadata = null;

        this.currentMetadata = null;
        this.metadataItems = [];

        //To ensure backwards compatibility
        this.queueManager.contentManager = this;

        this.registerEventListeners();
    }

    /** @private */
    registerEventListeners() {
        this.listen(this.castPlayerManager, this.castPlayerManagerEventListeners, cast.framework.events.EventType.MEDIA_INFORMATION_CHANGED,
          () => {
              this.emit(ContentManagerEvents.ContentMetadataUpdated, this.getCurrentMetadata());
          });
        this.listen(this.engine, this.engineEventListeners, EngineEvents.LoadedData,
          this.validateMetadata.bind(this));
    }

    /** @private */
    unregisterEventListeners() {
        Object.keys(this.castPlayerManagerEventListeners)
          .forEach(e => this.unlisten(this.castPlayerManager, this.castPlayerManagerEventListeners, e));
        this.castPlayerManagerEventListeners = {};

        Object.keys(this.engineEventListeners)
          .forEach(e => this.unlisten(this.engine, this.engineEventListeners, e));
        this.engineEventListeners = {};
    }

    /** @private */
    validateMetadata() {
        if (this.pendingMetadata) {
            /**
             * Diffing using google dtos since the unified ones can contain more data than what one can get from Caf's playerManager
             */
            const mappedPendingMetadata = MetadataMapper.mapToGoogleMetadata(this.pendingMetadata, this.config.logoUrl);
            const mappedCurrentData = this.getCurrentMetadata(true); // ToDo: Needs to get the metadata asGoogleDto !

            /**
             * For live streams this property changes depending on when the stream was started, so for the purposes of
             * comparing the metadata just copy the value that Caf has calculated over to the pending metadata
             */
            mappedPendingMetadata.sectionStartTimeInMedia = mappedCurrentData.sectionStartTimeInMedia;

            if (JSON.stringify(mappedPendingMetadata).length !== JSON.stringify(mappedCurrentData).length) {
                console.warn('ContentManager::validateMetadata: Metadata failed to update correctly! Overriding metadata manually!');
                this.updateCurrentMetadata(this.pendingMetadata);
            }
        }
    }

    /**
     * @param {Content} content
     * @param {Boolean} playDirect
     */
    addToQueue(content, playDirect = false) {
        content.nextContent = true;
        let item = new cast.framework.messages.QueueItem();
        item.autoplay = true;
        item.preloadTime = 0;
        item.media = new cast.framework.messages.MediaInformation();
        item = ContentMapper.mapToLoadRequest(item, content, this.config.logoUrl);

        this.queueManager.addToQueue(item);

        if (playDirect) {
            this.queueManager.playNextContent();
        }
    }

    clearNextContent() {
        this.queueManager.clearNextContent();
    }

    /**
     * @returns {ContainerMetadata}
     */
    getContainerMetadata() {
        return this.castQueueManager.getContainerMetadata();
    }

    getCurrentContainerMetadata() {
        const now = Date.now() / 1000;
        let currentSection = new Metadata({ title: 'unknown' })

        //todo detect if there is any gaps between programs and inject a dummy channelItem
        this.metadataItems.forEach(metadata => {
            if (!metadata.sectionInfo) return;
            const { duration, startAbsoluteTime } = metadata.sectionInfo;
            if (startAbsoluteTime <= now && (startAbsoluteTime + duration) >= now) {
                currentSection = metadata;
            }
        });

        return currentSection;

    }

    /**
     * getCurrentMetadata will return the metadata for the currently playing content
     * Note: Although it's possible to acquire metadata in its native format via Google it is not recommended
     * as our internal metadata format follows a different structure which ensures compatibility of our sender libraries
     * @returns {Metadata|null}
     */
    getCurrentMetadata() {
        if (this.castQueueManager.getContainerMetadata()) {
            console.log(`--| ChannelMetadata:`);
            console.log(this.getCurrentContainerMetadata());
            return this.getCurrentContainerMetadata();
        } else {
            try {
                const mediaInfo = this.castPlayerManager.getMediaInformation();
                let metadata = MetadataMapper.mapFromGoogleMetadata(mediaInfo.metadata);
                console.log(`--| MediaMetadata:`);
                console.log(metadata);
                return metadata;
            } catch (e) {
                console.error(`Error class:ContentManager[getCurrentMetadata] : ${e}!`);
                return null;
            }
        }
    }

    getNextContent() {
        const item = this.queueManager.getNextContent();
        if (item) {
            return ContentMapper.mapFromLoadRequest(item.customData, item);
        }
    }

    getPendingMetadata() {
        return this.pendingMetadata;
    }


    /**
     * @returns {boolean}
     */
    hasNextContentInQueue() {
        return this.queueManager.hasNextContentInQueue();
    }

    playNextContent() {
        this.queueManager.playNextContent();
    }

    removeNextContent() {
        return this.queueManager.removeNextContent();
    }

    resetContainerMetadata() {
        if (this.hasUsedContainerMetadata) {
            const containerMetadata = new cast.framework.messages.ContainerMetadata();
            this.castQueueManager.setContainerMetadata(containerMetadata);
        }
    }

    /**
     * @param {Metadata} metadata
     */
    updateCurrentMetadata(metadata) {
        this.hasPendingMetadata = true;
        this.pendingMetadata = metadata;
        this.currentMetadata = metadata;
        let mediaInfo;
        try {
            mediaInfo = this.castPlayerManager.getMediaInformation();
        } catch (e) {
            console.warn(`ContentManager::updateCurrentMetadata: No MediaInfo available!`);
        }

        if (mediaInfo) {
            mediaInfo.metadata = MetadataMapper.mapToGoogleMetadata(this.pendingMetadata, this.config.logoUrl);
            this.castPlayerManager.setMediaInformation(mediaInfo);
            this.emit(ContentManagerEvents.ContentMetadataUpdated, metadata);
        }
    }

    /**
     * @param {string} title=''
     * @param {Array<Metadata>} metadataItems
     */
    updateContainerMetadata(title, metadataItems) {
        this.metadataItems = metadataItems;
        const containerMetadata = this.getContainerMetadata() || new cast.framework.messages.ContainerMetadata();
        containerMetadata.title = title || '';

        if (containerMetadata.sections && containerMetadata.sections.length > 0) {
            containerMetadata.sections = [];
        }

        const currentSection = this.getCurrentContainerMetadata();
        console.log(`currentSection:`, currentSection);

        if (currentSection) {
            this.hasPendingMetadata = true;
            this.pendingMetadata = currentSection;
        }

        containerMetadata.sections = this.metadataItems.map(value => MetadataMapper.mapToGoogleMetadata(value, this.config.logoUrl));

        this.hasUsedContainerMetadata = true;
        this.castQueueManager.setContainerMetadata(containerMetadata);
    }

    updateCurrentItem() {
    }

    reset() {
        this.hasPendingMetadata = false;
        this.pendingMetadata = null;

        this.resetContainerMetadata();
    }

    destroy() {
        this.reset();

        this.hasUsedContainerMetadata = false;

        this.unregisterEventListeners();
    }
};