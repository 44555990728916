import { ChannelsMetadata } from "../Types";

export const ChannelsMetadataMapper = (response: {
  data: { channels: any };
}): Array<ChannelsMetadata> => {
  if (!(response && response.data && response.data.channels)) {
    throw new Error(
      "MetadataService::ChannelMetadataMapper: Missing data or channel!"
    );
  }

  const {
    data: {
      channels: { channelItems },
    },
  } = response;

  const channelsMetadata: Array<ChannelsMetadata> = [];

  channelItems.map((channel: any) => {
    const mapped = {
      id: channel.id,
      name: channel.name,
      playback: channel.playback,
      icons: {
        dark: channel.icons.dark.sourceNonEncoded,
        light: channel.icons.light.sourceNonEncoded,
      },
      programs: channel.programs.programItems,
    };
    channelsMetadata.push(mapped);
  });

  return channelsMetadata;
};
