const Utils = require('../utils/Utils');
const Image = require('./Image');
const { MetadataTypes } = require('../Constants');

/**
 * @typedef {object} ChannelInfo
 * @property {string} name
 * @property {Image} [logo]
 */

/**
 * @typedef {object} NextContent
 * @property playbackSpec: PlaybackSpec;
 * @property metadata?: MediaMetadata;
 * @property nextContent?: PlaybackSpec;
 * @property seriesTitle?: string;
 * @property episode: number;
 * @property season: number;
 */

/**
 * @typedef {object} SectionInfo
 * @property {number} duration
 * @property {number} startAbsoluteTime
 * @property {number} [startTime] - from graph
 * @property {number} [endTime] - from graph
 */
/**
 * @typedef {object} SeriesInfo
 * @property {number} [episode=null]
 * @property {number} [season=null]
 * @property {string} title
 */

/**
 * @typedef {object} TMetadataArgs
 * @property {ChannelInfo} channelInfo
 * @property {string} [description]
 * @property {Array<Image>} [images]
 * @property {Image} [posterImage]
 * @property {string} [releaseDate]
 * @property {SectionInfo} sectionInfo
 * @property {SeriesInfo} series
 * @property {string} title
 * @property {string} [type='MetadataTypes.Movie']
 */
/**
 * @type {TMetadataArgs}
 */
class Props {
    constructor() {
        this.channelInfo = this.description = this.images = this.posterImage = this.releaseDate =
          this.sectionInfo = this.series = this.title = this.type = undefined;
    }
}

class Metadata extends Props {
    /**
     * @param {TMetadataArgs} args
     */
    constructor(args = {}) {
        super();

        Utils.extend(this, {
            type: MetadataTypes.Movie,
            images: []
        }, args);

        if (this.series && this.series.title) {
            this.type = MetadataTypes.TvShow;
        }
    }
}

module.exports = Metadata;