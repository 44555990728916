import { AdvertisementBreakTypes } from "../Constants";
import { AdvertisementInfo } from "./AdvertisementInfo";

export class AdvertisementBreakInfo {
  readonly id: string;
  readonly name: string;
  readonly breakType: AdvertisementBreakTypes;
  readonly durationInSeconds: number;
  readonly advertisements: Array<AdvertisementInfo>;
  readonly embedded: boolean;
  readonly position: number;

  constructor(args: AdvertisementBreakInfo) {
    Object.entries(args).forEach((arg) => {
      this[arg[0]] = arg[1];
    })
  }
}
