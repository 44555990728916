const Utils = require('../utils/Utils');

/**
 * @typedef {object} TImageArgs
 * @property {string} url
 * @property {number} [width]
 * @property {number} [height]
 */
/**
 * @type {TImageArgs}
 */
class Props {
    constructor() {
        this.url = this.width = this.height = undefined;
    }
}

class Image extends Props {
    /**
     * @param {TImageArgs} args
     */
    constructor(args = {}) {
        super();

        Utils.extend(this, { height: 0, width: 0 }, args);
    }
}

module.exports = Image;