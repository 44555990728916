import { BaseService } from '../BaseService'
import {
    FetchRequestBase,
    FetchRequestOptions,
    FetchRequestResponse
} from '../../network/FetchRequestFactory';
import { ServiceError } from '../errors/';
import { MessagingServiceConfiguration, ServiceConfig } from '../Types';
import {
    ErrorCategories,
    Logger as LoggerSingleton, NetworkError,
    MessagingError,
} from "@tv4/one-playback-sdk-shared";
import { ServiceErrorCodes } from "../errors/ServiceError";
import { PubSubSetupResponse, Payload } from './Types';

export interface PubSubMessage {
    type: string;
    version: string;
    deviceId: string;
    payload: Payload;
};

const Logger = LoggerSingleton.createLoggerContext('MessagingService');

const randomInteger = (min: number, max: number) => {
    min = Math.ceil(min || 0);
    max = Math.floor(max || 1000000000);

    return Math.floor(Math.random() * (max - min + 1)) + min;
};

const RefreshMinMs = 50 * 60 * 1000;
const RefreshMaxMs = 70 * 60 * 1000;

export class MessagingService extends BaseService {
    protected configuration?: MessagingServiceConfiguration | ServiceConfig;
    protected headers: any = {};
    private pupSubToken?: String;
    private pubSubInfo?: PubSubSetupResponse;
    public isActive: boolean = false;
    private activePubSubRequest?: FetchRequestResponse | NetworkError;
    private activePubSubFetchRequest: FetchRequestBase | null = null;
    private activeTimer?: any = null;

    constructor() {
        super('MessagingService');
    }

    public async connect(): Promise<void> {
        if (!this.configuration) {
            throw new ServiceError({
                code: ServiceErrorCodes.MissingConfiguration,
                category: ErrorCategories.DEFAULT,
                fatal: true,
                details: {
                    origin: this.name,
                    domain: 'requestPubSubInfo',
                }
            });
        }

        if (this.pubSubInfo) {
            this.pubSubInfo = undefined;
        }
        let pubSubInfo = await this.requestPubSubInfo()
        if (pubSubInfo.type !== 'NCHAN') {
            throw new Error(`Error class:MessagingService[connect] : Wrong Message type!`);
        }
        this.pubSubInfo = pubSubInfo;
        this.isActive = true;

        this.refresh(this.etagNow());
    }

    public initialize(configuration: MessagingServiceConfiguration | ServiceConfig) {
        this.configuration = configuration;
        this.headers = {
            'X-Country': this.configuration.serviceCountry.toLowerCase()
        };
    }

    public onMessage(message: PubSubMessage) {
    }

    public resume() {
        //todo check is token is valid else connect!
        this.isActive = true;
        this.refresh(this.etagNow());
    }

    public stop() {
        this.isActive = false;
        if (this.activePubSubFetchRequest) {
            this.activePubSubFetchRequest.abort();
        }
    }

    private etagNow(): string {
        return `${Math.floor(Date.now() / 1000)}:0`;
    }

    private async awaitPubSubMessage(etag: string) {
        if (this.pubSubInfo == null) {
            throw new Error(`Error class:MessagingService[requestPubSubMessage] : Missing pubSubInfo!`);
        }
        if (!this.pubSubInfo.config.baseUrl || !this.pubSubInfo.channels.engagement) {
            throw new Error(`Error class:MessagingService[awaitPubSubMessage] : Missing requered parts of the pubsub infomation!`);

        }
        //setting activePubSubRequest so it can be aborted from the outside
        const pubSubUrl = `${this.pubSubInfo.config.baseUrl}/sub?id=${this.pubSubInfo.channels.engagement}`
        this.activePubSubFetchRequest = await this.requestFactory.generateRequest(pubSubUrl, this.createPubSubLongPollRequestOptions(etag));
        let response = await this.activePubSubFetchRequest.send();

        if (response instanceof NetworkError) {
            return response;
        }
        else if (response instanceof FetchRequestResponse) {
            if (!(response.responseBody as PubSubSetupResponse)) {
                throw new ServiceError({
                    code: ServiceErrorCodes.BadResponseError,
                    category: ErrorCategories.TV_CLIENT_GATEWAY,
                    fatal: true,
                    details: {
                        origin: this.name,
                        domain: 'awaitPubSubMessage',
                    }
                });
            }

            return response;
        }
        else {
            throw new ServiceError({
                code: ServiceErrorCodes.InternalError,
                category: ErrorCategories.TV_CLIENT_GATEWAY,
                fatal: true,
                details: {
                    origin: this.name,
                    domain: 'awaitPubSubMessage',
                }
            });
        }
    }

    private createPubSubRequestOptions(): FetchRequestOptions {
        return {
            method: 'GET',
            headers: this.headers,
            useAuthentication: true,
            ignoreGlobalHeaders: false,
            redirect: 'follow'
        }
    }

    private createPubSubLongPollRequestOptions(etag: string): FetchRequestOptions {
        if (this.pubSubInfo) {
            return {
                method: 'GET',
                headers: {
                    'if-none-match': etag,
                    'Authorization': `Bearer ${this.pubSubInfo?.config.subscriberToken}`,
                },
                useAuthentication: false,
                ignoreGlobalHeaders: false,
                redirect: 'follow',
                mode: 'cors',
                //timeoutDelayInSec: 10 // randomInteger(RefreshMinMs, RefreshMaxMs)
            };
        }
        else {
            throw new Error(`Error class:MessagingService[createPubSubLongPollRequestOptions] : Missing pub!`);
        }
    }

    private async requestPubSubInfo(): Promise<PubSubSetupResponse> {

        let response: FetchRequestResponse | NetworkError | null = null;

        const url = `${this.configuration!.tvClientGatewayUrl}/tvclientgateway/rest/secure/v1/pubsub`;

        response = await this.requestFactory.fetch(url, this.createPubSubRequestOptions());

        if (response instanceof NetworkError) {

            if (response.responseBody?.errorCode) {
                throw new MessagingError({
                    originalError: response,
                    fatal: true,
                    details: {
                        origin: this.name,
                        domain: "requestPubSubInfo",
                    }
                });
            }

            throw response;

        } else if (response instanceof FetchRequestResponse) {
            if (!(response.responseBody as PubSubSetupResponse)) {
                throw new ServiceError({
                    code: ServiceErrorCodes.BadResponseError,
                    category: ErrorCategories.TV_CLIENT_GATEWAY,
                    details: {
                        response,
                        origin: this.name,
                        domain: 'requestPubSubInfo',
                    },
                    fatal: true,
                });
            }
            return response.responseBody;

        } else {
            throw new ServiceError({
                code: ServiceErrorCodes.InternalError,
                category: ErrorCategories.TV_CLIENT_GATEWAY,
                details: {
                    response,
                    origin: this.name,
                    domain: 'requestPubSubInfo',
                },
                fatal: true,
            });
        }
    }

    private async refresh(etag: string) {
        if (this.activePubSubFetchRequest) {
            this.activePubSubFetchRequest.abort();
            this.activePubSubFetchRequest = null;
        }

        let pubSubResponse: FetchRequestResponse | NetworkError | null = null;

        pubSubResponse = await this.awaitPubSubMessage(etag);

        if (pubSubResponse instanceof NetworkError) {

            // IMPORTANT - IMPORTANT - IMPORTANT
            // It is paramount that the abortion was done manually i.e. pubSubResponse.manuallyAborted === true!!
            // Otherwise we create an infinite loop

            if (pubSubResponse.aborted && pubSubResponse.manuallyAborted === false && this.isActive === true) {
                Logger.log(`--| awaitPubSubMessage timedOut refreshing`);
                this.refresh(this.etagNow());
                return;
            }
        }
        else if (pubSubResponse instanceof FetchRequestResponse) {
            if (!(pubSubResponse.responseBody as PubSubMessage)) {
                throw new ServiceError({
                    code: ServiceErrorCodes.BadResponseError,
                    category: ErrorCategories.TV_CLIENT_GATEWAY,
                    details: {
                        response: pubSubResponse,
                        origin: this.name,
                        domain: 'refresh',
                    },
                    fatal: true,
                });
            }

            this.onMessage(pubSubResponse.responseBody as PubSubMessage);

            if (this.isActive) {
                const etag = pubSubResponse.responseHeaders.get('etag');
                if (!pubSubResponse.responseHeaders || !etag) {
                    throw new Error(`Error class:MessagingService[refresh] : Missing etag!`);
                }
                this.refresh(etag);
            } else {
                Logger.warn(`[LOG] class:MessagingService[refresh] : Setting MessagingService inactive....!`);
            }
        }
        else {
            throw new ServiceError({
                code: ServiceErrorCodes.InternalError,
                category: ErrorCategories.DEFAULT,
                details: {
                    origin: this.name,
                    domain: 'refresh',
                },
                fatal: true,
            });
        }
    }

    async reset(): Promise<void> {
        return Promise.resolve();
    }

    async destroy(): Promise<void> {
        return Promise.resolve();
    }
}

