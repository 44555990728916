import {
    AdvertisementTypes,
    Content,
    EngineEvents,
    ReceiverError,
    ReceiverEvents,
    ReceiverInterface,
    TrackManagerEvents,
    Utils
} from '@tv4/unified-receiver';
import { BonnierPlayerSdk } from '../../lib/BonnierPlayerSDK'
import { PlaybackAsset, PlaybackMedia } from '../playbackApiService/Types';
import { ServiceLayerConfiguration, ServiceLayerInformation } from "../../Types";
import { BonnierSdkSimpleUser } from "./BonnierSdkSimpleUser";
import { AdvertisementBreakTypes } from "../playbackFeatures/yospace/Types";
import { ServiceLayerErrorFactory } from '../../utils/ServiceLayerErrorFactory';
import { ErrorCategoriesMapper } from '../../mappers';

const {
    EventEmitter,
    TrackingManager,
    Constants: {
        PlayerEvents,
        VideoType
    },
} = BonnierPlayerSdk;
const BonnierPlayerEvents = PlayerEvents

import {
    AdvertisementEndedEvent as UnifiedAdvertisementEndedEvent,
    AdvertisementStartedEvent as UnifiedAdvertisementStartedEvent,
    AdvertisementBreakEndedEvent as UnifiedAdvertisementBreakEndedEvent,
    AdvertisementBreakStartedEvent as UnifiedAdvertisementBreakStartedEvent,
    AdvertisementTimeUpdateEvent as UnifiedAdvertisementTimeUpdateEvent,
    BitrateChangedEvent as UnifiedBitrateChangedEvent,
    BufferedEvent as UnifiedBufferedEvent,
    BufferingEvent as UnifiedBufferingEvent,
    CdnChangedEvent as UnifiedCdnChangedEvent,
    DroppedFramesEvent as UnifiedDroppedFramesEvent,
    LoadStartEvent as UnifiedLoadStartEvent,
    LoadedDataEvent as UnifiedLoadedDataEvent,
    LoadedMetadataEvent as UnifiedLoadedMetadataEvent,
    PauseEvent as UnifiedPauseEvent,
    PlayEvent as UnifiedPlayEvent,
    SeekedEvent as UnifiedSeekedEvent,
    SeekingEvent as UnifiedSeekingEvent,
    StreamCueEvent as UnifiedStreamCueEvent,
    StreamFinishedEvent as UnifiedStreamFinishedEvent,
    SystemVolumeChangedEvent as UnifiedSystemVolumeChangedEvent,
    TimeUpdateEvent as UnifiedTimeUpdateEvent
} from '@tv4/unified-receiver';


const MapBreakType = (adbreak?: UnifiedAdvertisementStartedEvent): 'pre-roll' | 'mid-roll' => {
    if (adbreak && adbreak.breakType) {
        return adbreak.breakType === AdvertisementBreakTypes.PREROLL ? 'pre-roll' : 'mid-roll';
    } else {
        return 'mid-roll'
    }
}

export class BonnierPlayerSdkTrackingManager {

    private serviceLayerInfo?: ServiceLayerInformation
    private asset?: PlaybackAsset
    private currentTime: number = 0;
    private duration: number = 0;
    private adsCurrentTime: number = 0;
    private adsDuration: number = 0;
    private content?: Content;
    private contentStarted: boolean = false;
    private contentLoadingFired: boolean = false;
    private endCreditsEmitted: boolean = false;
    private eventEmitter?: typeof EventEmitter;
    private inAdBreak: boolean = false;
    private isPlaying: boolean = false;
    private isSeeking: boolean = false;
    private media?: PlaybackMedia
    private receiver: ReceiverInterface
    private receiverEvents: Record<string, (e: any) => void>;
    private trackingManager: any
    private resetPromise?: Promise<string>
    private serviceLayerConfig?: ServiceLayerConfiguration;

    /** Deferred Actions **/
    private adBreakEndAction?: () => void;
    private seekedAction?: () => void;

    constructor(receiver: ReceiverInterface) {
        this.receiver = receiver;
        this.receiverEvents = {};
        this.registerListeners();
    }

    public async initialize(assetId: string, serviceLayerInfo: ServiceLayerInformation, serviceLayerConfig: ServiceLayerConfiguration) {
        this.serviceLayerInfo = serviceLayerInfo;
        this.serviceLayerConfig = serviceLayerConfig;
        if (!this.serviceLayerInfo.applicationInfo?.applicationVersion) {
            console.warn(`Warning class:BonnierPlayerSdkTrackingManager[initialize] : Missing applicationVersion!`);
            return;
        }
        if (!serviceLayerConfig.tracking.youboraAccount) {
            throw ServiceLayerErrorFactory.createFatalError('BonnierPlayerSdkTrackingManager', 'InitialisationError', `Missing youboraAccount!`);
        }
        if (!this.serviceLayerInfo.receiverInfo) {
            throw ServiceLayerErrorFactory.createFatalError('BonnierPlayerSdkTrackingManager', 'InitialisationError', `Missing receiverInfo!`);
        }

        this.eventEmitter = new EventEmitter();

        this.trackingManager = new TrackingManager({
            assetId
        }, this.eventEmitter);

        this.trackingManager.setupYoubora({
            youboraAccount: serviceLayerConfig.tracking.youboraAccount,
            options: {
                service: this.serviceLayerInfo,
                assetId: assetId
            },
            appName: `ChromeCast[${this.serviceLayerInfo.serviceId}]`,
            appVersion: this.serviceLayerInfo.applicationInfo?.applicationVersion,
            youboraOptions: {
                'app.name': this.serviceLayerInfo.applicationInfo.applicationName,
                'app.releaseVersion': this.serviceLayerInfo.applicationInfo.applicationVersion,
                'device.model': this.serviceLayerInfo.receiverInfo.device.deviceModel,
                'device.osName': this.serviceLayerInfo.receiverInfo.operatingSystem.operatingSystemName,
                'device.osVersion': this.serviceLayerInfo.receiverInfo.operatingSystem.operatingSystemVersion,
                'content.customDimension.1': this.serviceLayerInfo.applicationInfo.applicationName,
                'content.customDimension.3': this.serviceLayerInfo.serviceName,
                'content.customDimension.5': this.serviceLayerInfo.senderType
            }
        });
    }

    public updateMedia(asset: PlaybackAsset, media: PlaybackMedia) {
        this.media = media;
        this.asset = asset;
        /**
         * Marking functions needed by BonnierTrackingManager
         * Remove as soon as possible!!!
         */
        //@ts-ignore
        this.asset.getId = () => {
            return this.asset?.metadata.videoId || this.asset?.metadata.id || ''
        }
        //@ts-ignore
        this.asset.getType = () => {
            //@ts-ignore
            return (this.asset && this.asset.type) ? this.asset.type : undefined;
        }

        if (!this.trackingManager) {
            return ServiceLayerErrorFactory.createNoneFatalError('BonnierPlayerSdkTrackingManager', 'UpdateMediaError', `No TrackingManager initialized!`);
        }
        let isStartOver = this.asset.metadata.startOver || false;
        this.trackingManager.enrichYouboraData({
            asset: this.asset,
            media: this.media,
            isStartOver: isStartOver,
        })
    }

    public updateTracker(content: Content, user: BonnierSdkSimpleUser, serviceLayerInfo: ServiceLayerInformation, serviceLayerConfig: ServiceLayerConfiguration, deviceId: string) {
        this.content = content;
        this.serviceLayerInfo = serviceLayerInfo;
        this.serviceLayerConfig = serviceLayerConfig;
        this.trackingManager.setup({
            asset: this.asset,
            media: this.media,
            config: {
                WIRE_ENDPOINT: user.getId() === "-1" ? serviceLayerConfig.tracking.wireAnonymousUrl : serviceLayerConfig.tracking.wireUrl
            },
            autoplay: true,
            user: user,
            service: this.serviceLayerInfo.serviceId,
            //@ts-ignore
            playbackMode: this.asset?.metadata.isLive ? "livestitch" : "vodstitch",
            isPNC: content.nextContent,
	    deviceId: deviceId
        })
    }


    private registerListeners() {
        const fireContentStarted = () => {
            if (this.contentStarted === true) {
                return;
            }

            if (this.contentLoadingFired === false) {
                this.emit(BonnierPlayerEvents.Content.LOADED, {});
                this.contentLoadingFired = true
            }

            let playerEvent = BonnierPlayerEvents.Content.START
            let tracksManager = this.receiver.components.tracksManager;
            let payload = {
                currentTime: this.currentTime,
                activeAudio: tracksManager.activeTrackLanguages().audio,
                activeSubtitle: tracksManager.activeTrackLanguages().text,
                audioTracks: tracksManager.audioTrackLanguages(),
                subtitles: tracksManager.textTrackLanguages()
            }
            this.contentStarted = true;
            this.emit(playerEvent, payload);
            this.isPlaying = true;
        }

        this.receiver.addListener(EngineEvents.AdvertisementEnded, this.receiverEvents[EngineEvents.AdvertisementEnded] = (e: UnifiedAdvertisementEndedEvent) => {
            this.emit(BonnierPlayerEvents.Ad.END, {
                currentTime: this.adsDuration,
                duration: this.adsDuration
            });
        });
        this.receiver.addListener(EngineEvents.AdvertisementStarted, this.receiverEvents[EngineEvents.AdvertisementStarted] = (e: UnifiedAdvertisementStartedEvent) => {
            let payload = {
                position: e.positionInAdBreak,
                total: e.totalAdsInAdBreak,
                adDetails: {
                    id: e.id,
                    title: e.name,
                    format: MapBreakType(e),
                    duration: e.durationInSeconds,
                    campaignId: e.campaignId,
                    customId: e.customId,
                    goalId: e.goalId
                }
            }

            this.adsCurrentTime = 0;
            this.adsDuration = e.durationInSeconds;

            this.emit(BonnierPlayerEvents.Ad.LOADING, payload);
            this.emit(BonnierPlayerEvents.Ad.START, payload);
        });
        this.receiver.addListener(EngineEvents.AdvertisementBreakEnded, this.receiverEvents[EngineEvents.AdvertisementBreakEnded] = (e: UnifiedAdvertisementBreakEndedEvent) => {
            this.inAdBreak = false;
            this.adsCurrentTime = 0;
            this.adsDuration = 0;
            this.adBreakEndAction = () => {
                this.adBreakEndAction = undefined;
                this.emit(BonnierPlayerEvents.Ad.BREAK_END, {
                    adBreakPosition: MapBreakType(e),
                });
                this.emit(BonnierPlayerEvents.Content.RESUME, {});
                fireContentStarted();
            };

        });
        this.receiver.addListener(EngineEvents.AdvertisementBreakStarted, this.receiverEvents[EngineEvents.AdvertisementBreakStarted] = (e: UnifiedAdvertisementBreakStartedEvent) => {
            this.inAdBreak = true;
            this.emit(BonnierPlayerEvents.Ad.BREAK_START, {
                adBreakPosition: MapBreakType(e),
                adInsertionType: "ssai",
                numberOfAds: e.advertisements.length,
            });
        });
        this.receiver.addListener(EngineEvents.AdvertisementTimeUpdate, this.receiverEvents[EngineEvents.AdvertisementTimeUpdate] = (e: UnifiedAdvertisementTimeUpdateEvent) => {
            if(!this.inAdBreak){
                return;
            }
            this.adsCurrentTime = e.advertisementCurrentTime;

            /**
             * We only override the adsDuration of it has not yet been set by the vast info
             */
            if (this.adsDuration === 0) {
                console.warn(`Warning class:BonnierPlayerSdkTrackingManager[AdvertisementTimeUpdate] :Missing advertisement duration from vast using detected duration! (${e.advertisementDuration})`);
                this.adsDuration = e.advertisementDuration;
            }
            if (this.adsDuration !== e.advertisementDuration) {
                console.warn(`Warning class:BonnierPlayerSdkTrackingManager[AdvertisementTimeUpdate] : Reported advertisement duration and detected duration do not match! (${this.adsDuration} - ${e.advertisementDuration})`);
            }

            this.emit(BonnierPlayerEvents.Ad.TIME_UPDATE, {
                currentTime: this.adsCurrentTime,
                duration:this.adsDuration
            });
        });
        this.receiver.addListener(EngineEvents.BitrateChanged, this.receiverEvents[EngineEvents.BitrateChanged] = (e: UnifiedBitrateChangedEvent) => {
            this.emit(BonnierPlayerEvents.BITRATE_CHANGED, e);
        });
        this.receiver.addListener(EngineEvents.Buffered, this.receiverEvents[EngineEvents.Buffered] = (e: UnifiedBufferedEvent) => {
            let playerEvent = this.inAdBreak ? BonnierPlayerEvents.Ad.BUFFERED : BonnierPlayerEvents.Content.BUFFERED;
            this.emit(playerEvent, e);
            this.isPlaying = true;
        });
        this.receiver.addListener(EngineEvents.Buffering, this.receiverEvents[EngineEvents.Buffering] = (e: UnifiedBufferingEvent) => {
            this.isPlaying = false;
            let playerEvent = this.inAdBreak ? BonnierPlayerEvents.Ad.BUFFERING : BonnierPlayerEvents.Content.BUFFERING;
            this.emit(playerEvent, e);
        });
        this.receiver.addListener(EngineEvents.CdnChanged, this.receiverEvents[EngineEvents.CdnChanged] = (e: UnifiedCdnChangedEvent) => {
            if(this.inAdBreak){
                return;
            }
            this.emit(BonnierPlayerEvents.CDN_CHANGED, e);

        });
        this.receiver.addListener(EngineEvents.DroppedFrames, this.receiverEvents[EngineEvents.DroppedFrames] = (e: UnifiedDroppedFramesEvent) => {
            if(this.inAdBreak){
                return;
            }
            this.emit(BonnierPlayerEvents.DROPPED_FRAMES, {
                droppedFrames: frames,
            });
        });
        // this.receiver.addListener(EngineEvents.LoadStart, this.receiverEvents[EngineEvents.LoadStart] = (e: UnifiedLoadStartEvent) => {
        //
        // });
        this.receiver.addListener(EngineEvents.LoadedData, this.receiverEvents[EngineEvents.LoadedData] = (e: UnifiedLoadedDataEvent) => {
            if (this.inAdBreak) {
                return;
            }
            fireContentStarted();
        });
        this.receiver.addListener(EngineEvents.LoadedMetadata, this.receiverEvents[EngineEvents.LoadedMetadata] = (e: UnifiedLoadedMetadataEvent) => {
            let stream = this.content?.streams[0];
            if (stream && stream.advertisementType === AdvertisementTypes.Yospace) {
                return;
            }
            this.emit(BonnierPlayerEvents.Content.LOADED, {});
            this.contentLoadingFired = true
        });

        this.receiver.addListener(EngineEvents.Pause, this.receiverEvents[EngineEvents.Pause] = (e: UnifiedPauseEvent) => {
            this.isPlaying = false;
            let playerEvent = this.inAdBreak ? BonnierPlayerEvents.Ad.PAUSE : BonnierPlayerEvents.Content.PAUSE;
            this.emit(playerEvent, {});
        });
        this.receiver.addListener(EngineEvents.Play, this.receiverEvents[EngineEvents.Play] = (e: UnifiedPlayEvent) => {
            let playerEvent = this.inAdBreak ? BonnierPlayerEvents.Ad.RESUME : BonnierPlayerEvents.Content.RESUME
            this.emit(playerEvent, {});
            this.isPlaying = true;
        });
        this.receiver.addListener(EngineEvents.Seeked, this.receiverEvents[EngineEvents.Seeked] = (e: UnifiedSeekedEvent) => {
            let playerEvent = BonnierPlayerEvents.Content.SEEKED;
            this.isPlaying = true;
            /**
             * This is probably one of the worst ideas ever!!
             * This code should be scraped as soon as possible
             */
            this.seekedAction = () => {
                this.seekedAction = undefined;
                this.emit(playerEvent, {});
            }
        });
        this.receiver.addListener(EngineEvents.Seeking, this.receiverEvents[EngineEvents.Seeking] = (e: UnifiedSeekingEvent) => {

            let playerEvent = BonnierPlayerEvents.Content.SEEKING;
            this.emit(playerEvent, {});
            this.isPlaying = true;
        });
        this.receiver.addListener(EngineEvents.StreamFinished, this.receiverEvents[EngineEvents.StreamFinished] = (e: UnifiedStreamFinishedEvent) => {
            if (this.inAdBreak) {
                return;
            }
            this.isPlaying = false;
            //@ts-ignore
            let isLive = this.media ? !!this.media.isLive : undefined;
            this.emit(BonnierPlayerEvents.Content.END, {
                isLive: isLive
            });
        });
        this.receiver.addListener(EngineEvents.StreamCue, this.receiverEvents[EngineEvents.StreamCue] = (e: UnifiedStreamCueEvent) => {
            if (this.inAdBreak) {
                return;
            }
            this.emit(BonnierPlayerEvents.Ad.ID3, e);
        });
        this.receiver.addListener(EngineEvents.SystemVolumeChanged, this.receiverEvents[EngineEvents.SystemVolumeChanged] = (e: UnifiedSystemVolumeChangedEvent) => {
            if (this.inAdBreak) {
                return;
            }
            this.emit(BonnierPlayerEvents.VOLUME_CHANGE, e);

        });
        this.receiver.addListener(EngineEvents.TimeUpdate, this.receiverEvents[EngineEvents.TimeUpdate] = (e: UnifiedTimeUpdateEvent) => {
            if (this.inAdBreak) return;
            /*
            Note: this isPlaying flag was added to try to fix a comscore compliant
            Comscore remark:
            When seeking there is a play event with a playhead position and an end position with the value ns_st_po = 663600.
            Please make sure to update the playhead position after seeking, only when the playback resumes:
             */

            if (this.isPlaying === false) return;

            const currentTime = e.currentTime || 0;
            const duration = e.duration || 0;

            this.currentTime = currentTime;
            this.duration = duration;

            /**
             * To ensure the correct event currentTime, we defer the ad-break ended and seeked actions to the first timeUpdate after the event
             */
            if (this.adBreakEndAction) {
                this.adBreakEndAction()
            }

            if (this.seekedAction) {
                this.seekedAction();
            }

            //@ts-ignore
            const normalTriggerCase = this.media.endCreditsTimestamp && this.media.endCreditsTimestamp < duration && currentTime >= this.media.endCreditsTimestamp;

            //@ts-ignore
            const noTriggerCase = !this.media.type === VideoType.CLIP && !this.media.endCreditsTimestamp &&
                currentTime > 20 &&
                currentTime >= duration - 10;
            //@ts-ignore
            const clipTrigger = this.media.type === VideoType.CLIP && !this.media.endCreditsTimestamp &&
                currentTime > 10 &&
                currentTime >= duration - 1;

            //@ts-ignore
            const falsyTriggerCase = this.media.endCreditsTimestamp > duration &&
                currentTime > 20 &&
                currentTime >= duration - 10;
            //@ts-ignore
            if (!this.media.isLive && !this.endCreditsEmitted && (normalTriggerCase || noTriggerCase || clipTrigger || falsyTriggerCase)
            ) {
                this.endCreditsEmitted = true;
                this.emit(BonnierPlayerEvents.Content.CREDITS_START, {});
            }
            //@ts-ignore
            if (this.endCreditsEmitted && ((this.media.endCreditsTimestamp && currentTime < this.media.endCreditsTimestamp) || (!this.media.endCreditsTimestamp && currentTime < duration - 10))) {
                this.endCreditsEmitted = false;
                this.emit(BonnierPlayerEvents.Content.CREDITS_BACKED_OUT, {});

            }
            this.emit(BonnierPlayerEvents.Content.TIME_UPDATE, e);
        });
        this.receiver.addListener(ReceiverEvents.Error, this.receiverEvents[ReceiverEvents.Error] = (e: ReceiverError) => {
            if (e.fatal === true) {
                this.emit(BonnierPlayerEvents.ERROR, {
                    error: Utils.extend({}, e.normalizedError, {
                        category: ErrorCategoriesMapper(e.normalizedError?.category)
                    })
                });
            }
        });
        this.receiver.addListener(TrackManagerEvents.AudioTrackChanged, this.receiverEvents[TrackManagerEvents.AudioTrackChanged] = (e: {
            language: string
        }) => {
            if (this.inAdBreak) {
                return;
            }
            this.emit(BonnierPlayerEvents.AUDIO_CHANGED, e);
        });
        this.receiver.addListener(TrackManagerEvents.TextTrackChanged, this.receiverEvents[TrackManagerEvents.TextTrackChanged] = (e: {
            language: string
        }) => {
            if (this.inAdBreak) {
                return;
            }
            this.emit(BonnierPlayerEvents.SUBTITLE_CHANGED, e);
        });
    }

    private unregisterListeners() {
        for (let event in this.receiverEvents) {
            this.receiver.removeListener(event, this.receiverEvents[event])
        }
    }

    private emit(event: string, data = {}) {
        if (this.eventEmitter) {
            if (!this.inAdBreak) {
                //@ts-ignore
                data.currentTime = this.currentTime;
                //@ts-ignore
                data.duration = this.duration;
            }

            this.eventEmitter.emit(event, data);
        }
    }

    public async reset(playbackSessionId: string): Promise<string> {
        if (this.resetPromise) {
            console.log(`### --| Still awaiting previous reset`);
            return this.resetPromise;
        }

        /**
         * this reset promise aws put in place to fix deallocation lifecycle issue
         * It's a shit solution, and should we fixed in the library instead
         */
        this.resetPromise = new Promise((resolve, reject) => {
            this.serviceLayerInfo = undefined
            this.asset = undefined
            this.currentTime = 0
            this.duration = 0
            this.content = undefined
            this.contentStarted = false
            this.contentLoadingFired = false
            this.endCreditsEmitted = false
            this.eventEmitter = undefined
            this.inAdBreak = false
            this.isPlaying = false
            this.isSeeking = false
            this.media = undefined


            if (this.eventEmitter) {
                this.eventEmitter = null;
                this.eventEmitter
            }
            if (this.trackingManager) {
                let checkCounter = 0;
                const checkTrackingManagerDestroy = () => {
                    console.log(`### --| Checking tracking manager activity`);
                    let stillActive = false;
                    if (this.trackingManager.trackers && Object.keys(this.trackingManager.trackers).length > 0) {
                        for (let i in this.trackingManager.trackers) {
                            if (this.trackingManager.trackers[i] != null) {
                                console.log(`${i}-> `, this.trackingManager.trackers[i]);
                                stillActive = true;
                            }
                        }
                    }
                    if (checkCounter >= 10) {
                        console.log(`### --| Tracker is completely broken`);
                        reject(`Error class:BonnierPlayerSdkTrackingManager[checkTrackingManagerDestroy] : Tracker is completely broken!`);
                    }
                    if (stillActive) {
                        console.log(`### --| Tracking manager still active awaiting deallocating`);
                        checkCounter++;
                        setTimeout(checkTrackingManagerDestroy, 500);
                    } else {
                        this.trackingManager = null;
                        console.log(`### --| Tracking manager was successfully destroyed`);
                        resolve(playbackSessionId);
                    }
                }

                checkTrackingManagerDestroy();
                this.trackingManager.destroy();

            } else {
                console.log(`### --| Resolving due to no trackingManager`);
                resolve(playbackSessionId);
            }

        })

        this.resetPromise.finally(() => {
            console.log(`###--| Reset completed!`);
            this.resetPromise = undefined;
        })

        return this.resetPromise;
    }

    public async destroy(playbackSessionId: string): Promise<string> {
        await this.reset(playbackSessionId);
        this.unregisterListeners();
        return playbackSessionId;
    }
}
