//
//  ReceiverEventBus.js
//
//  Created by Lars Rothaus on 08/10/2021.
//  Copyright © 08/10/2021 Lars Rothaus. All rights reserved.
//

const { ErrorOrigins } = require('../Constants');

const ListenableListener = require('./ListenableListener');

const {
    EngineEvents,
    TrackManagerEvents,
    ReceiverEvents
} = require('../Events');

/**
 * Specify what events are allowed to be mapped
 */
const AllowedEvents = [...Object.values(EngineEvents), ...Object.values(TrackManagerEvents), ReceiverEvents.Error];

class EventBus extends ListenableListener {
    constructor({ engine, tracksManager, errorManager }) {
        super();
        this.errorManager = errorManager;
        this.emitOriginal = false;
        /** @private */
        this.engine = engine;
        /** @private */
        this.engineEventListeners = {};
        /** @private */
        this.tracksManager = tracksManager;
        /** @private */
        this.tracksManagerEventListeners = {};
        /** @private */
        this.registerEventListeners();
        /** @private */
        this.eventMapper = {}
        /** @private */
        this.settings = {
            debugMode: false,
            emitOriginal: false,
            wrapPayload: false
        }
    }

    /** @private **/
    //|todo EventBus.js [TASK][Minor]: Make validate them more robust Cycle for available events, Check that output is function;
    validateEventMapper(eventMap) {
        for (let i in eventMap) {
            if (!AllowedEvents.includes(i)) {
                this.errorManager.dispatchError({
                    code: 'EventBus.ValidateEventMapper.Error',
                    message: `Error class:EventBus[validateEventMapper] : Event with type of '${i}' It's not allowed. See the list of mappable events below: \n ${JSON.stringify(AllowedEvents, null, 2)}!`,
                    origin: ErrorOrigins.Receiver.Interface.EventBus,
                    fatal: true
                })
            }
            if (!typeof eventMap[i] === 'function') {
                this.errorManager.dispatchError({
                    code: 'EventBus.ValidateEventMapper.Error',
                    message: `Error class:ReceiverEventBus[validateEventMapper] : mapped value must be a function!`,
                    origin: ErrorOrigins.Receiver.Interface.EventBus,
                    fatal: true
                })
                return false
            }
        }
        return true;
    }

    /**
     * @typedef {object} TPEvent
     * @property {string} type
     * @property {*} payload
     */

    /**
     * @callback requestCallback
     * @param {TPEvent} event
     * @return {TPEvent}
     */

    /**
     * @typedef {object} TSetEventMapperArgs
     * @property {string} type
     * @property {requestCallback} converterMethod
     */

    /**
     *
     * @param {Object.<string, requestCallback} eventMapper
     */
    setEventMapper(eventMapper) {
        if (this.validateEventMapper(eventMapper)) {
            this.eventMapper = eventMapper;
        } else {
            console.error(`Error class:ReceiverEventBus[setEventMapper] : EventMapper failed to validate!`);
        }
    }

    /** @private */
    registerEventListeners() {
        Object.values(EngineEvents).forEach(type => {
            this.listen(this.engine, this.engineEventListeners, type, ((data) => {
                this.dispatchEvent(type, data);
            }))
        });

        Object.values(TrackManagerEvents).forEach(type => {
            this.listen(this.tracksManager, this.tracksManagerEventListeners, type, (data) => {
                this.dispatchEvent(type, data);
            })
        });
    }

    /** @private */
    unregisterEventListeners() {
        Object.keys(this.engineEventListeners)
          .forEach(e => this.unlisten(this.engine, this.engineEventListeners, e));
        this.engineEventListeners = {};

        Object.keys(this.tracksManagerEventListeners)
          .forEach(e => this.unlisten(this.tracksManager, this.tracksManagerEventListeners, e));
        this.tracksManagerEventListeners = {};
    }

    /** @private */
    dispatchEvent(type, data) {
        let event = data;
        // if (this.settings.wrapPayload) {
        //   event = {
        //     type,
        //     payload: data
        //   }
        // }else{
        //   event = data;
        // }

        if (this.settings.emitOriginal) {
            //dispatch original event
            this.emit(type, event);
        }
        //if mapping exist
        if (typeof this.eventMapper[type] === 'function') {
            let modifiedEvent = this.eventMapper[type](event);
            if (modifiedEvent) {
                event = modifiedEvent;
            }
            if (modifiedEvent.type) {
                type = modifiedEvent.type
            }
        }
        this.emit(type, event);
    }

    reset() {
    }

    destroy() {
        this.unregisterEventListeners();
    }
}

module.exports = EventBus;