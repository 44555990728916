const LanguageMapper = require('../../mappers/LanguageMapper');

const disabledLanguage = 'off';

class Track {
    constructor(type, engine) {
        this.engine = engine;
        this.playerManager = this.engine.playerManager;
        this.type = type;
        this.disableable = true;
        this.initialized = false;
        this._enabled = true;
        this.languageLabelMapper = null;
        this.tracks = [];


        this.accessibilitySupported = false;
        this.preferAccessibility = false;
        // this will be overridden by AudioTrack & TextTrack
        this.accessibilityIndicates = {
            language: '',
            name: '',
            role: 'none'
        }

    }

    applyPreferredTracks(preferred) {
        if (!Array.isArray(preferred)) {
            return false;
        }

        let available = this.getTracks();
        if (preferred.length > 0 && available.length > 0) {
            for (let i = 0; i < preferred.length; i++) {
                if (preferred[i] === 'off') {
                    continue;
                }
                let track;
                if (this.preferAccessibility) {
                    track = available.find(track => track.language === preferred[i] + this.accessibilityIndicates.language);
                }
                if (!track) {
                    track = available.find(track => track.language === preferred[i]);
                }
                if (track) {
                    return this.setActiveTrack(track.trackId);
                }
            }
            return false
        } else {
            return false;
        }
    }

    // applyPreferredTracks(preferred) {
    //   if (!Array.isArray(preferred)) {
    //     return false;
    //   }
    //
    //   let available = this.getTrackLanguages();
    //   if (preferred.length > 0 && available.length > 0) {
    //     for (let i = 0; i < preferred.length; i++) {
    //       if (preferred[i] === "off") {
    //         continue;
    //       }
    //       let selectedLanguage = undefined;
    //       if (this.preferAccessibility && available.includes(preferred[i] + this.accessibilityIndicates.language)) {
    //         selectedLanguage = preferred[i] + this.accessibilityIndicates.language;
    //       }
    //       else if (available.includes(preferred[i])) {
    //         selectedLanguage = preferred[i];
    //       }
    //       if (selectedLanguage) {
    //         return this.setActiveTrack(selectedLanguage);
    //       }
    //     }
    //     return false
    //   }
    //   else {
    //     return false;
    //   }
    // }

    enabledStateChanged(enabled) {
        //override
    }

    disableHandler() {
        //override
    }

    checkAvailability() {
        //override
        return !!this.gTracksManager;
    }

    getActiveIds() {
        if (typeof this.gTracksManager.getActiveIds === 'function') {
            return this.gTracksManager.getActiveIds();
        } else {
            return [this.gTracksManager.getActiveId()];
        }
    }

    getTracks() {
        let tracks = [];
        if (this.gTracksManager) {
            try {
                tracks = this.gTracksManager.getTracks();

                if (!this.accessibilitySupported) {
                    // tracks = tracks.filter(t => !(t.roles || []).includes(this.accessibilityIndicates.role);
                }
            } catch (e) {
                return [];
            }
        }
        let requireHardUpdate = false

        // if (this.tracks.length !== tracks.length) {
        //   requireHardUpdate = true;
        // }

        // if (!requireHardUpdate) {
        //   return this.tracks;
        // }
        if (this.tracks.length) {
            return this.tracks;
        }

        /**
         * Hack to fix audio language being reported as undefined if only one track is available
         */
        for (let t in tracks) {
            if (!tracks.hasOwnProperty(t)) continue;
            if (tracks[t].type.toLowerCase() === 'audio') {
                if (tracks[t].language === 'und') {
                    tracks[t].language = 'en';
                }
            }

            const language = tracks[t].language;

            let suffix = '';
            /*
            if (tracks[t].roles && Array.isArray(tracks[t].roles) && tracks[t].roles.includes(this.accessibilityIndicates.role)) {
                if (!this.accessibilitySupported) {
                    continue
                }
                tracks[t].language = `${tracks[t].language}${this.accessibilityIndicates.language}`;
                suffix = this.accessibilityIndicates.name;
            }
            */

            if (this.languageLabelMapper && this.languageLabelMapper(language)) {
                tracks[t].name = this.languageLabelMapper(language) + suffix;
            }

            this.tracks.push(tracks[t]);
        }

        return this.tracks;
    }

    getTrackLanguages() {
        const tracks = this.getTracks();
        if (tracks.length > 0) {
            return tracks.map(a => a.language);
        }

        return [];
    }

    getTrackNames() {
        const tracks = this.getTracks();
        if (tracks.length > 0) {
            return tracks.map(a => a.name);
        }

        return [];
    }

    getActiveTrack() {
        return null;
    }

    getActiveTrackLanguage() {
        let track = this.getActiveTrack();
        if (track && track.language) {
            return track.language;
        } else {
            console.debug(`Track[${this.type}]::getActiveTrackLanguage: Fail to get active track returning '${disabledLanguage}'!`);
            return disabledLanguage;
        }
    }

    getActiveTrackName() {
        let track = this.getActiveTrack();
        if (track && track.name) {
            return track.name;
        } else {
            let disabledName = disabledLanguage;
            if (this.languageLabelMapper && this.languageLabelMapper(disabledLanguage)) {
                disabledName = this.languageLabelMapper(disabledLanguage);
            }
            console.debug(`Track[${this.type}]::getActiveTrackName: Fail to get active track returning '${disabledName}'!`);
            return disabledName;
        }
    }

    handleTracksChange(pending) {
        setTimeout(() => {
            if (pending && pending === this.getActiveTrackLanguage()) {
                let data = {};
                data[this.type] = this.getActiveTrackLanguage();
                this.activeTracksChanged(data);
            } else {
                console.debug(`Track[${this.type}]::announce: activeTracksChanged failed!`);
            }
        }, 300);
    }

    initialize() {
        console.log(`Track[${this.type}]::initialize: Initializing Tracks!`);
        this.readyTimer = setInterval(() => {
            if (this.checkAvailability()) {
                this.readyTimer = clearInterval(this.readyTimer);
                this.setInitialActiveTrack();
                this.initialized = true;
                this.trackReady(this);
            } else {
                console.debug(`Track[${this.type}]::initialize: Waiting for tracksManager!`);
            }
        }, 100);
    }


    setActiveTrack(languageOrId) {
        if (!this.gTracksManager && this.getTrackLanguages().length <= 0) {
            return console.debug(`Track[${this.type}]::setActiveTracks: Tracks is not ready!`);
        }
        let language = languageOrId;

        // setActiveByIds

        if (!language) {
            language = disabledLanguage;
        }
        try {
            if (language === disabledLanguage) {
                if (this.disableable) {
                    this.gTracksManager.setActiveByIds([]);
                    this.disableHandler();
                }
            } else {
                if (typeof (languageOrId) === 'number' && this.getTracks().find(track => track.trackId === languageOrId)) {

                } else {
                    language = LanguageMapper.mapISOCodeFromAlpha2or3to2(language);
                    this.gTracksManager.setActiveByLanguage(language);
                }
            }

            this.handleTracksChange(language);
            return true;
        } catch (e) {
            console.debug(`Track[${this.type}]::setActiveTrack: Could not set track language (${language})!`, e);

            return false;
        }
    }

    setInitialActiveTrack() {
    }

    setLanguageLabelMapper(languageMapper) {
        if (typeof (languageMapper) === 'function') {
            this.languageLabelMapper = languageMapper;
        } else {
            console.debug(`Track[${this.type}]::setLanguageLabelMapper: languageMapper must be a function!`);
        }
    }

    reset() {
        if (this.readyTimer) {
            this.readyTimer = clearInterval(this.readyTimer);
        }
        this.tracks = [];
        this.initialized = false;
    }

    // |--------------------------|
    // | Handlers
    // |--------------------------|

    /**
     * @typedef {object} TTracksChanged
     * @property {string} [audio]
     * @property {string} [text]
     */
    /**
     * @param {TTracksChanged} data
     */
    activeTracksChanged(data) {
        //override
    }

    /**
     * @param {Track} track
     */
    trackReady(track) {
        //override
    }

    // |--------------------------|
    // | GET&SET
    // |--------------------------|

    get enabled() {
        return this._enabled;
    }

    set enabled(value) {
        if (this.disableable === false) {
            return console.debug(`Track[${this.type}]::enabled: Track is not disableable!!`);
        }
        if (typeof value === 'boolean' && this._enabled !== value) {
            this._enabled = value;
            this.enabledStateChanged(this._enabled);
        }
    }

    get gTracksManager() {
        let tManager = null;
        try {
            let t = (this.type === 'text') ? this.playerManager.getTextTracksManager() : this.playerManager.getAudioTracksManager();
            if (t.getTracks()) {
                tManager = t;
            }
        } catch (e) {
            console.debug(`Track[${this.type}]::gTracksManager: No TracksManager!`);
        }
        return tManager;
    }
}

module.exports = Track;