const Utils = require('../utils/Utils');

/**
 * @typedef {object} TDrmArgs
 * @property {Function} [getNewDrmInfo]
 * @property {object} [headers={}]
 * @property {string} [protectionSystem=com.widevine.alpha]
 * @property {string} server
 */
/**
 * @type {TDrmArgs}
 */
class Props {
    constructor() {
        this.getNewDrmInfo = this.headers = this.server = this.protectionSystem = undefined;
    }
}

class Drm extends Props {
    /**
     * @param {TDrmArgs} args
     */
    constructor(args = {}) {
        super();

        Utils.extend(this, {}, args);
    }
}

module.exports = Drm;