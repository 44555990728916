//
//  RestrictionHelper.js
//
//  Created by Lars Rothaus on 01/06/2021.
//  Copyright © 01/06/2021 Lars Rothaus. All rights reserved.
//

class RestrictionHelper {

    constructor() {
        this.player = null;
        this.restriction = null;
        window.restriction = new Restriction();
    }

    applyRestriction(restriction) {
        if (restriction instanceof Restriction) {
            let levels = this.getLevels();
            console.log(`-------= restriction =-------`);
            let topLevel = null;
            let bottomLevel = null;
            for (let i in levels) {
                if (restriction.checkLevel(levels[i])) {
                    if (bottomLevel == null) {
                        bottomLevel = levels[i];
                    }
                    topLevel = levels[i];
                }
            }

            if (topLevel) {
                this._setMaxLevel(topLevel, restriction);
            } else {
                console.error(`Error class:RestrictionHelper[applyRestriction] : No level qualified the restrictions!`);
            }

        } else {
            console.error(`Error class:RestrictionHelper[applyRestriction] : argument is not of type Restriction!`);
        }
    }

    clearRestriction() {
        if (this.restriction === null) {
            console.warn(`Warning class:RestrictionHelper[clearRestriction] : No restriction registered!`);
            return
        }
        this._setMaxLevel(null);
        this._setMinLevel(null);
    }

    getLevels() {
        console.error(`Error class:RestrictionHelper[getLevels] : Missing implementation!`);
    }

    _setMaxLevel(level, restriction) {
        console.error(`Error class:RestrictionHelper[_setMaxLevel] : Missing implementation!`);
    }

    _setMinLevel(level, restriction) {
        console.error(`Error class:RestrictionHelper[_setMaxLevel] : Missing implementation!`);
    }

    setPlayer(player) {
        console.log(`--| Setting Player`);
        this.player = player;
    }

    test() {

        this.applyRestriction(window.restriction);
    }
}

class Restriction {

    constructor() {
        this.index = {
            min: null,
            max: null
        }
        this.width = {
            min: null,
            max: null
        }
        this.height = {
            min: null,
            max: null
        }
        this.bandwidth = {
            min: null,
            max: null
        }
        this.bitrate = {
            min: null,
            max: null
        }
        this.fps = {
            min: null,
            max: null
        }
        this.attributes = [
            'width',
            'height',
            'bandwidth',
            'bitrate',
            'fps'
        ];
    }

    checkLevel(level) {
        let passed = true;
        for (let i in this.attributes) {
            let { max, min } = this[this.attributes[i]];
            if (max || min) {
                let levelValue = level[this.attributes[i]];
                let maxCondition = true;
                let minCondition = true;
                if (max && levelValue > max) {
                    maxCondition = false;
                }
                if (passed == true && min && levelValue < min) {
                    minCondition = false;
                }

                console.log(`maxCondition:${maxCondition} / minCondition:${minCondition}`);
                if (!maxCondition || !minCondition) {
                    passed = false;
                }
                console.log(`checking level:'${level.index}::${this.attributes[i]} against max:'${max}/level[${levelValue}]', min:'${min}/level[${levelValue}]'`);
            }

        }
        return passed;
    }

}

class Level {

    constructor(level) {
        if (
          typeof level.index !== 'number',
          !level.width ||
          !level.height ||
          !level.bandwidth ||
          !level.bitrate
        ) {
            console.error(`Error class:RestrictionHelper[constructor] : Could not create Level!\n`, level)
            return;
        }
        this.index = level.index;
        this.width = level.width;
        this.height = level.height;
        this.bandwidth = level.bandwidth;
        this.bitrate = level.bitrate;
        this.fps = level.fps || null;
    }
}

module.exports = {
    RestrictionHelper,
    Restriction,
    Level
};