import { guid } from "../../../../../utils";
import { TAdvertisement } from "../Types";
import { AdvertisementBreakTypes } from "@tv4/one-playback-sdk-shared";

export type TAdvertisementArgs = {
  id?: string;
  name?: string;
  durationInSeconds: number;
  breakType: AdvertisementBreakTypes;
  adNumberInBreak: number;
  sponsor: boolean;
};

export class Advertisement implements TAdvertisement {
    public readonly type = 'Advertisement';

    public id: string;
    public name: string;
    public durationInSeconds: number;
    public breakType: AdvertisementBreakTypes;
    public adNumberInBreak: number;
    public sponsor: boolean;

    constructor(advertisement?: TAdvertisementArgs) {
        this.id = advertisement?.id ?? guid();
        this.name = advertisement?.name ?? 'Unknown';
        this.durationInSeconds = advertisement?.durationInSeconds ?? 0;
        this.breakType = advertisement?.breakType ?? AdvertisementBreakTypes.MIDROLL;
        this.adNumberInBreak = advertisement?.adNumberInBreak ?? 1;
        this.sponsor = advertisement?.sponsor ?? false;
    }
}
