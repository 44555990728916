const { Image, Metadata } = require('../dtos/');
const { MetadataTypes } = require('../Constants');
const Utils = require('../utils/Utils');

module.exports = class MetadataMapper {
    constructor() {
    }

    /**
     * @param {MediaMetadata} gMetadata
     */
    static mapFromGoogleMetadata(gMetadata) {
        var metadata = new Metadata();
        metadata.type = MetadataMapper.mapTypeFromGoogleDto(gMetadata);
        metadata.title = gMetadata.title;

        if (gMetadata.sectionDuration && gMetadata.sectionStartAbsoluteTime) {
            metadata.sectionInfo = {};
            metadata.sectionInfo.duration = gMetadata.sectionDuration;
            metadata.sectionInfo.startAbsoluteTime = gMetadata.sectionStartAbsoluteTime;
            metadata.sectionInfo.startTime = gMetadata.sectionStartAbsoluteTime * 1000;
            metadata.sectionInfo.endTime = metadata.sectionInfo.startTime + (gMetadata.sectionDuration * 1000);
        }

        if (gMetadata.images && gMetadata.images.length > 0) {
            metadata.images = metadata.images || [];
            for (let img in gMetadata.images) {
                if (!gMetadata.images.hasOwnProperty(img)) continue;
                metadata.images.push(new Image({ ...gMetadata.images[img] }));
            }
        }

        if (gMetadata.posterImage) {
            metadata.posterImage = new Image({ url: gMetadata.posterImage });
        }

        if ([cast.framework.messages.MetadataType.GENERIC, cast.framework.messages.MetadataType.MOVIE].includes(gMetadata.metadataType)) {
            metadata.releaseDate = gMetadata.releaseDate;
            metadata.description = gMetadata.subtitle;
        } else {
            if (gMetadata.seriesTitle || Utils.isNumber(gMetadata.season) || Utils.isNumber(gMetadata.episode)) {
                metadata.series = {};
                metadata.series.title = gMetadata.seriesTitle || gMetadata.title;
                metadata.series.season = gMetadata.season;
                metadata.series.episode = gMetadata.episode;

            }
            metadata.releaseDate = gMetadata.originalAirdate;
        }

        return metadata;
    }

    /**
     * @param metadataDto
     * @returns {cast.framework.messages.MovieMediaMetadata|cast.framework.messages.TvShowMediaMetadata}
     * @constructor
     */
    static mapToGoogleMetadata(metadataDto, logoUrl) {
        let gMetadata;
        if (metadataDto.series && metadataDto.series.title) {
            gMetadata = new cast.framework.messages.TvShowMediaMetadata();
            //Add series data
            gMetadata.seriesTitle = metadataDto.series.title;
            gMetadata.season = metadataDto.series.season;
            gMetadata.episode = metadataDto.series.episode;
            gMetadata.originalAirdate = metadataDto.releaseDate;
        } else {
            //Add movie data
            gMetadata = new cast.framework.messages.MovieMediaMetadata();
            gMetadata.releaseDate = metadataDto.releaseDate;
            gMetadata.subtitle = metadataDto.description;
        }

        gMetadata.title = metadataDto.title;
        gMetadata.metadataType = MetadataMapper.mapTypeToGoogleDto(metadataDto);

        if (metadataDto.sectionInfo) {
            gMetadata.sectionDuration = metadataDto.sectionInfo.duration;
            gMetadata.sectionStartAbsoluteTime = metadataDto.sectionInfo.startAbsoluteTime;
            // gMetadata.sectionStartTimeInContainer = metadata.sectionInfo.startTimeInContainer;
            // gMetadata.sectionStartTimeInMedia = 0;
        }

        gMetadata.images = gMetadata.images || [];
        if (metadataDto.images && metadataDto.images.length > 0) {
            for (let img in metadataDto.images) {
                if (!metadataDto.images.hasOwnProperty(img)) continue;

                gMetadata.images.push(metadataDto.images[img]);
            }
        }

        if (!gMetadata.images.length && logoUrl) {
            gMetadata.images.push({
                url: Utils.resolveUrl(logoUrl),
                height: 0,
                width: 0
            });
        }

        if (metadataDto.posterImage) {
            gMetadata.posterImage = metadataDto.posterImage.url;
        }

        if (gMetadata.seriesTitle && gMetadata.seriesTitle === gMetadata.title) {
            gMetadata.seriesTitle = '';
        }

        return gMetadata;
    }

    static mapTypeFromGoogleDto(googleMetadata) {
        switch (googleMetadata.metadataType) {
            case cast.framework.messages.MetadataType.AUDIOBOOK_CHAPTER:
                return MetadataTypes.AudioBookChapter;
            case cast.framework.messages.MetadataType.GENERIC:
                return MetadataTypes.Generic;
            case cast.framework.messages.MetadataType.MOVIE:
                return MetadataTypes.Movie;
            case cast.framework.messages.MetadataType.MUSIC_TRACK:
                return MetadataTypes.MusicTrack;
            case cast.framework.messages.MetadataType.PHOTO:
                return MetadataTypes.Photo;
            case cast.framework.messages.MetadataType.TV_SHOW:
                return MetadataTypes.TvShow;
            case 100: // cast.framework.messages.MetadataType.USER - doesn't exist in Google's JS enum
                return MetadataTypes.User;
        }

        return MetadataTypes.Unknown;
    }

    static mapTypeToGoogleDto(metadata) {
        switch (metadata.type) {
            case MetadataTypes.AudioBookChapter:
                return cast.framework.messages.MetadataType.AUDIOBOOK_CHAPTER;
            case MetadataTypes.Generic:
                return cast.framework.messages.MetadataType.GENERIC;
            case MetadataTypes.Movie:
                return cast.framework.messages.MetadataType.MOVIE;
            case MetadataTypes.MusicTrack:
                return cast.framework.messages.MetadataType.MUSIC_TRACK;
            case MetadataTypes.Photo:
                return cast.framework.messages.MetadataType.PHOTO;
            case MetadataTypes.TvShow:
                return cast.framework.messages.MetadataType.TV_SHOW;
            case MetadataTypes.Unknown:
                return cast.framework.messages.MetadataType.GENERIC;
            case MetadataTypes.User:
                return 100; // cast.framework.messages.MetadataType.USER - doesn't exist in Google's JS enum
        }

        return cast.framework.messages.MetadataType.GENERIC;
    }
};