import {
    BrowserInfo,
    DeviceInfo,
    OperatingSystemInfo,
    PlayerInfo,
    ServiceInfo,
    PlaybackSessionInfo,
    UserInfo, TrackingInfoEvent, TYouboraTracking, ApplicationInfo, PlaybackSpec
} from '@tv4/one-playback-sdk-shared';

import {
    TYouboraOptions,
    TYouboraTrackerConfiguration,
} from './Types';

const getUnknown = (): string => {
    return 'Unknown';
};

const getUnset = (): string => {
    return 'Unset';
};

const getBrowserOptions = (browser?: BrowserInfo): TYouboraOptions => {
    if (!browser) return {};

    return {
        'device.browserName': browser.browserName,
        'device.browserVersion': browser.browserVersion
    };
};

const getDeviceOptions = (device?: DeviceInfo): TYouboraOptions => {
    if (!device) return {};

    return {
        'device.model': device.deviceModel
    };
};

const getMetadataOptions = ({ payload: { content, session, tracking: { YOUBORA } = {} } }: TrackingInfoEvent) => {
    return {
        'content.metadata': {
            ...content,
            ...session,
            ...(YOUBORA && YOUBORA),
        }
    };
};

const getOperatingSystemOptions = (operatingSystem?: OperatingSystemInfo): TYouboraOptions => {
    if (!operatingSystem) return {};

    return {
        'device.osName': operatingSystem.operatingSystemName,
        'device.osVersion': operatingSystem.operatingSystemVersion
    };
};

const getPlaybackSessionOptions = (playbackSession?: PlaybackSessionInfo): TYouboraOptions => {
    if (!playbackSession || !playbackSession.playbackSessionId) return {};

    return {
        'content.transactionCode': playbackSession.playbackSessionId
    };
};

const getApplicationOptions = (application?: ApplicationInfo) => {
    if (!application) return;

    const playerOptions: TYouboraOptions = {
        'app.name': application.applicationName,
        'app.releaseVersion': application.applicationVersion
    };

    return playerOptions;
}

const getServiceOptions = (service?: ServiceInfo): TYouboraOptions => {
    if (!service) return {};

    return {
        'content.customDimension.3': service.serviceName,
        'content.customDimension.4': service.serviceCountry
    };
};

const getUserOptions = (user: UserInfo | null | undefined): TYouboraOptions => {
    if (!user || !user.userId) return {};

    return {
        'user.name': user.userId
    };
};

const getPlaybackOptions = (playbackSpec: PlaybackSpec | undefined): TYouboraOptions => {
    if (!playbackSpec) return {};

    return {
        'content.id': playbackSpec.videoId,
        'content.type': playbackSpec.watchMode,
        'content.playbackType': playbackSpec.videoIdType
    };
};

const getCustomTagOptions = (youbora: TYouboraTracking): TYouboraOptions => ({
    'content.id': youbora.videoId,
    'content.isLive': youbora.live,
    'content.title': youbora.title,
    'content.streamingProtocol': youbora.streamingProtocol,
    'content.transactionCode': youbora.transactionCode,
    'content.playbackType': youbora.videoIdType,
    'content.type': youbora.watchMode,
    'content.customDimension.4': youbora.country
});

export const buildYouboraOptionsFromTrackerConfiguration = (configuration: TYouboraTrackerConfiguration): TYouboraOptions => ({
    accountCode: configuration.accountId,
    'app.https': true, // replaces httpSecure
    'background.enabled': false, // Could prevent memory leak in Youbora lib
    'content.cdn': getUnset(),
    'parse.cdnNode': false,
    'parse.cdnNode.list': ['Balancer'],
    'parse.manifest': false,
    'user.type': 'OTT',

    ...getApplicationOptions(configuration.application),
    ...getBrowserOptions(configuration.browser),
    ...getDeviceOptions(configuration.device),
    ...getOperatingSystemOptions(configuration.operatingSystem),
    ...getPlaybackSessionOptions(configuration.playbackSession),
    ...getServiceOptions(configuration.service),
    ...getUserOptions(configuration.user),
});

export const buildYouboraOptionsFromTrackingInfo = (event: TrackingInfoEvent): TYouboraOptions => ({
    ...getMetadataOptions(event),
    ...getUserOptions(event.payload.user),
    ...getPlaybackOptions(event.payload.content?.playbackSpec),
    ...(event.payload.tracking?.YOUBORA ? getCustomTagOptions(event.payload.tracking.YOUBORA) : {})
});
