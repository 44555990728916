import { PlaybackAsset, PlaybackMedia } from '../components/playbackApiService/Types';
import {
  AdvertisementTypes,
  CipherModeTypes,
  CipherTypes,
  ContainerTypes,
  ContentTypes,
  Drm,
  Encryption,
  Metadata,
  MetadataTypes,
  Stream,
  StreamTypes,
} from '@tv4/unified-receiver';
import { createResizedImageUrl } from '../utils/CreateResizedImageUrl';
import { ServiceLayerConfiguration } from '../Types';

export class PlaybackApiMapper {
  static MapMetadata(asset: PlaybackAsset, config: ServiceLayerConfiguration): Metadata {
    const metadata = new Metadata();
    metadata.title = asset.metadata.title;
    metadata.description = asset.metadata.description;

    if (asset.metadata.image) {
      metadata.posterImage = {
        url: createResizedImageUrl(asset.metadata.image, 1280, 720, config.backend.imageResizeUrlPattern),
        height: 720,
        width: 1280,
      };
      metadata.images.push(metadata.posterImage);
    }

    if (asset.metadata.episodeTitle) {
      metadata.type = MetadataTypes.TvShow;
      metadata.series = {
        title: asset.metadata.title,
      };
      metadata.title = asset.metadata.episodeTitle;
      if (asset.metadata.seasonNumber) {
        metadata.series.season = asset.metadata.seasonNumber;
      }
      if (asset.metadata.episodeNumber) {
        metadata.series.episode = asset.metadata.episodeNumber;
      }
    }

    return metadata;
  }

  static MapStreams(media: PlaybackMedia, asset: PlaybackAsset, isStartOver: boolean): Stream[] {
    const streams: Stream[] = [];
    const advertisementType = media.isStitched ? AdvertisementTypes.Yospace : AdvertisementTypes.None;
    
    let url = media.manifestUrl;
    let playbackSessionUrl = url;
    if (media.accessUrl) {
      playbackSessionUrl = media.accessUrl;
    }

    //@ts-ignore
    if (isStartOver && media.startoverManifestUrl) {
      //@ts-ignore
      playbackSessionUrl = media.startoverManifestUrl;
    }

    const contentType = media.type === 'hls' ? ContentTypes.Hls : ContentTypes.Dash;
    const containerType = media.type === 'hls' ? ContainerTypes.Mp2Ts : ContainerTypes.Mp4IsoBmff;
    const streamType = asset.metadata.isLive ? StreamTypes.Live : StreamTypes.OnDemand;
    const streamRestrictions =
      streamType === StreamTypes.Live ? { pause: false, seek: false } : { pause: false, seek: false };

    const drms = [];
    let encryption: Encryption | undefined = undefined;
    if (media.license?.castlabsServer && media.license?.castlabsToken) {
      drms.push(
        new Drm({
          server: media.license?.castlabsServer,
          headers: {
            'x-dt-auth-token': media.license?.castlabsToken,
          },
          protectionSystem: 'com.widevine.alpha',
        })
      );
      encryption = new Encryption({
        cipher: CipherTypes.Aes128,
        cipherMode: CipherModeTypes.Ctr,
      });
    }

    streams.push(
      new Stream({
        advertisementType,
        containerType,
        contentType,
        drms,
        encryption,
        playbackSessionUrl,
        streamType,
        streamRestrictions,
        url,
      })
    );

    return streams;
  }
}
