const LanguageMapper = require('../mappers/LanguageMapper');
const { DeviceInfo } = require('../dtos/');

module.exports = new class Utils {
    constructor() {
        this.localStorageNamespace = 'UnifiedReceiver.Utils';
        this.searchParameters = new URLSearchParams(window.location.search);
        this.sourceBufferLimitBytesPromise = Promise.resolve(30 * 1024 * 1024);
    }

    addClass(node, clazz) {
        if (!this.hasClass(node, clazz)) {
            node.className = (node.className + ' ' + clazz).trim();
        }
    }

    allSettled(promises) {
        return Promise.all(promises.map(p => p
          .then(v => ({
              status: 'fulfilled',
              value: v,
          }))
          .catch(e => ({
              status: 'rejected',
              reason: e,
          }))
        ));
    }

    booleanOrDefault(value, defaultValue) {
        return this.isBoolean(value) ? value : defaultValue;
    }

    byteArrayToString(byteArray) {
        let s = '';
        for (let i = 0; i < byteArray.length; i += 1) {
            s += String.fromCharCode(byteArray[i]);
        }
        return s;
    }

    /**
     * @param {number} probability Number between 0 and 1
     * @returns {boolean}
     */
    chance(probability = 0.5) {
        if (probability <= 0) return false;
        if (probability >= 1) return true;

        const max = 1000000000;
        return Utils.randomInteger(0, max) <= max * probability;
    }

    /**
     * Courtesy of: https://github.com/beaufortfrancois/sandbox/blob/gh-pages/media/source-buffer-limit.html
     * @private
     */
    checkSourceBufferLimit() {
        return this.sourceBufferLimitBytesPromise = new Promise(resolve => {
            const mediaSource = new MediaSource();
            const video = document.createElement('video');

            video.src = URL.createObjectURL(mediaSource);
            video.addEventListener('error', () => {
                resolve(null);
            });

            mediaSource.addEventListener('sourceopen', () => {
                URL.revokeObjectURL(video.src);

                const sourceBuffer = mediaSource.addSourceBuffer('video/mp4; codecs="avc1.4d401f"');
                sourceBuffer.mode = 'sequence';

                let size = 0;
                Promise.resolve()
                  .then(() => fetch('https://storage.googleapis.com/fbeaufort-test/sample-video.mp4', { headers: { range: 'bytes=0-2343' } }))
                  .then(response => response.arrayBuffer())
                  .then(data => new Promise(resolveInitSegment => {
                      sourceBuffer.appendBuffer(data);
                      sourceBuffer.addEventListener('updateend', () => {
                          size += data.byteLength;
                          resolveInitSegment();
                      }, { once: true });
                  }))
                  .then(() => fetch('https://storage.googleapis.com/fbeaufort-test/sample-video.mp4', { headers: { range: 'bytes=2344-939299' } }))
                  .then(response => response.arrayBuffer())
                  .then(data => {
                      (function appendSomeData(percent) {
                          try {
                              let byteLength = (percent === 100) ? data.byteLength : Math.round(data.byteLength * percent / 100);
                              sourceBuffer.appendBuffer(data.slice(0, byteLength));
                              sourceBuffer.addEventListener('updateend', () => {
                                  size += byteLength;
                                  appendSomeData(percent);
                              }, { once: true });
                          } catch (error) {
                              if (error.name !== 'QuotaExceededError' && error.name !== 'InvalidStateError') {
                                  return resolve(null);
                              }
                              if (percent <= 5) {
                                  return resolve(size);
                              }
                              appendSomeData(percent - 5);
                          }
                      })(100);
                  });
            }, { once: true });
        }).catch(() => null);
    }

    clearDeviceId() {
        const localStorageKey = `${this.localStorageNamespace}.Device.Id`;
        localStorage.removeItem(localStorageKey);
    }

    /**
     * -1 === v1 is lower than v2
     * 0 === versions equal
     * 1 === v1 is higher than v2
     */
    compareVersions(v1, v2) {
        let v1parts = v1.split('.');
        let v2parts = v2.split('.');

        let isValid = (x) => /^\d+$/.test(x);
        if (!v1parts.every(isValid) || !v2parts.every(isValid)) {
            return 0;
        }

        v1parts = v1parts.map(Number);
        v2parts = v2parts.map(Number);

        for (let i = 0; i < v1parts.length; ++i) {
            if (v2parts.length == i) {
                // All equal for the length of v2, but v1 has more numbers and is therefore higher
                return 1;
            }

            if (v1parts[i] === v2parts[i]) continue;

            return v1parts[i] > v2parts[i] ? 1 : -1;
        }

        if (v1parts.length !== v2parts.length) {
            // All equal for the length of v1, but v2 has more numbers and is therefore higher
            return -1;
        }

        return 0;
    }

    defer(func, ms) {
        return setTimeout(func, ms || 1);
    }

    domReady(func) {
        if (['interactive', 'complete'].includes(document.readyState)) {
            func();

            return;
        }

        document.addEventListener('DOMContentLoaded', () => {
            func && func();
        });
    }

    enumerate(obj, func) {
        var prop = null,
          result = null;

        if (this.isFunction(func)) {
            for (prop in obj) {
                if (!obj.hasOwnProperty(prop)) continue;

                result = func(prop, obj[prop]);

                if (result) {
                    return result;
                }
            }
        }
    }

    extend(...args) {
        let allowUndefinedValues = false;
        if (this.isBoolean(args[0])) {
            allowUndefinedValues = args.shift();
        }

        let toStr = Object.prototype.toString,
          arrayStr = toStr.call([]),
          current = null,
          newProperty = null,
          i = 0,
          _args = [...args],
          base = args[0];

        for (i = 1; i < _args.length; i += 1) {
            current = _args[i];

            this.enumerate(current, (key, value) => {
                if (this.isObject(value) && [Array, Object].some(c => c === value.constructor) && !this.isDate(value)) {
                    newProperty = (toStr.call(value) === arrayStr) ? [] : {};
                    base[key] = this.extend(allowUndefinedValues, base[key] || newProperty, value);
                } else {
                    base[key] = allowUndefinedValues || 'undefined' !== typeof (value) ? value : base[key];
                }
            });
        }

        return base;
    }

    getAndroidDeviceInfo() {
        const androidDeviceInfoRegex = /(?:Linux; Android )(.*); ([^)]*)/i;
        const androidDeviceInfo = androidDeviceInfoRegex.exec(navigator.userAgent);
        if (androidDeviceInfo && androidDeviceInfo.length === 3) {
            return {
                build: androidDeviceInfo[2],
                version: androidDeviceInfo[1]
            };
        }

        return null;
    }

    getDeviceId(compactGuidFormat = false) {
        const localStorageKey = `${this.localStorageNamespace}.Device.Id`;
        let deviceId = localStorage.getItem(localStorageKey);
        if (!deviceId) {
            deviceId = this.guid(compactGuidFormat);
            localStorage.setItem(localStorageKey, deviceId);
        }

        return deviceId;
    }

    /**
     * @param compactGuidFormat
     * @returns {DeviceInfo}
     */
    getDeviceInfo(compactGuidFormat = false) {
        return {
            android: this.getAndroidDeviceInfo(),
            cafVersion: cast.framework.VERSION,
            deviceId: this.getDeviceId(compactGuidFormat),
            firmwareVersion: this.getFirmwareVersion(),
            generation: this.getGeneration(),
            language: LanguageMapper.mapISOCodeFromAlpha2or3to2(navigator.language.slice(0, 2)),
            shakaVersion: (('undefined' !== typeof (shaka) && shaka.Player && shaka.Player.version) || '').replace('v', '') || null
        };
    }

    getFirmwareVersion() {
        const versionMatch = navigator.userAgent.match(/(?:CrKey\/)(\d*\.\d*\.\d*)/i);
        if (versionMatch && versionMatch.length) {
            return versionMatch[1];
        }

        return null;
    }

    getGeneration() {
        const firmwareVersion = this.getFirmwareVersion() || '';
        const context = cast.framework.CastReceiverContext.getInstance();
        const canDisplayType = context.canDisplayType.bind(context);
        const canPlay = {
            'H.264 high level 4.2': canDisplayType('video/mp4', 'avc1.64002A, mp4a.40.2'),
            'H.265 main level 5.0': canDisplayType('video/mp4', 'hev1.1.6.L150.B0'),
            'H.265 main level 5.1': canDisplayType('video/mp4', 'hev1.1.6.L153.B0'),
            'H.265 main10 level 5.0': canDisplayType('video/mp4', 'hev1.2.6.L150.B0'),
            'H.265 main10 level 5.1': canDisplayType('video/mp4', 'hev1.2.6.L153.B0'),
            'VP9': canDisplayType('video/webm', 'vp9'),
            'AC-3': canDisplayType('audio/mp4', 'ac-3')
        };
        canPlay['H.265'] = canPlay['H.265 main level 5.0'] || canPlay['H.265 main level 5.1'] || canPlay['H.265 main10 level 5.0'] || canPlay['H.265 main10 level 5.1'];

        if (firmwareVersion.indexOf('1.36') === 0) {
            return '1';
        }

        if (!canPlay['H.264 high level 4.2']) {
            return '2';
        }

        if (!canPlay['VP9']) {
            return '3';
        }

        if (canPlay['H.265']) {
            return 'Ultra';
        }

        return null;
    }

    getPropertyByPath(object, path) {
        let current = object;

        path = path.split('.');

        for (let i = 0; i < path.length; ++i) {
            if (!current || !current.hasOwnProperty([path[i]])) {
                return;
            }
            current = current[path[i]];
        }

        return current;
    }

    getQueryStringParameter(parameter, queryString) {
        let searchParameters = queryString ? new URLSearchParams(queryString) : this.searchParameters;
        return searchParameters.get(parameter);
    }

    /**
     * @returns {Promise<number | null>}
     */
    getSourceBufferLimitBytes() {
        return this.sourceBufferLimitBytesPromise;
    }

    /**
     * @param {boolean|'default'|'compact'} [format]
     * @returns {string}
     */
    guid(format) {
        const formats = {
            compact: 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx',
            default: 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
        };

        format = this.isBoolean(format)
          ? (format ? formats.compact : formats.default)
          : (this.isString(format)
            ? format
            : formats.default);

        return (format || formats.default).replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0;

            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }

    has(object, path) {
        let current = object;

        path = path.split('.');

        for (let i = 0; i < path.length; ++i) {
            if (!current || !current.hasOwnProperty([path[i]])) {
                return false;
            }
            current = current[path[i]];
        }

        return true;
    }

    hasClass(node, clazz) {
        const classes = node.className.split(' ');
        let current = classes.shift();
        while (current) {
            if (clazz === current) {
                return true;
            }

            current = classes.shift();
        }

        return false;
    }

    insertAfter(newNode, afterNode) {
        return afterNode.parentNode.insertBefore(newNode, afterNode.nextSibling);
    }

    isBoolean(value) {
        return typeof (value) === 'boolean';
    }

    isDate(value) {
        return value instanceof Date;
    }

    isFunction(value) {
        return typeof (value) === 'function';
    }

    isInMegaDeath() {
        return new Promise(async resolve => {
            let timeout;
            try {
                const mksa = await navigator.requestMediaKeySystemAccess('com.widevine.alpha', [{
                    videoCapabilities: [{
                        contentType: 'video/mp4;codecs="avc1.42E01E"',
                        robustness: 'SW_SECURE_CRYPTO'
                    }]
                }]);
                const timeout = setTimeout(() => resolve(true), 1000);
                const mk = await mksa.createMediaKeys();
            } catch (e) {
            }

            if (timeout) {
                timeout = clearTimeout(timeout);
            }

            resolve(false);
        });
    }

    isNumber(value) {
        return typeof (value) === 'number' && isFinite(value);
    }

    isObject(value) {
        return typeof (value) === 'object' && value !== null;
    }

    isString(value) {
        return typeof (value) === 'string' || value instanceof String;
    }

    loadScript(url, callback) {
        var script = document.createElement('script');
        script.type = 'text/javascript';

        if (callback) {
            script.onerror = () => callback(false);
            script.onload = () => callback(true);
        }

        script.src = url;
        return document.getElementsByTagName('head')[0].appendChild(script);
    }

    numberOrDefault(value, defaultValue) {
        return this.isNumber(value) ? value : defaultValue;
    }

    parseEmsgCue(byteArray) {
        try {
            return new TextDecoder().decode(byteArray);
        } catch (decoderError) {
            try {
                return String.fromCharCode(null, byteArray);
            } catch (stringError) {
                return null;
            }
        }
    }

    parseEmsgBoxesFromMp4(byteArray) {
        if (!('ISOBoxer' in window)) {
            return null;
        }

        return ISOBoxer
          .parseBuffer(byteArray)
          .fetchAll('emsg');
    }

    parseId3Cue(byteArray, startTime) {
        const id3Cue = {
            startTime,
            tags: []
        };
        let offset = 0;
        if (!(byteArray[0] === 0x49 && byteArray[1] === 0x44 && byteArray[2] === 0x33)) {
            // Should be 'ID3'
            throw new Error('Invalid Id3 payload');
        }
        const flags = byteArray[5];
        const usesSynch = (flags & 0x80) !== 0;
        const hasExtendedHdr = (flags & 0x40) !== 0;
        const size =
          (byteArray[9] & 0xff) |
          ((byteArray[8] & 0xff) << 7) |
          ((byteArray[7] & 0xff) << 14);
        offset += 10; // Header size is 10 bytes
        if (usesSynch) {
            throw new Error('Uses Synch which is not supported yet');
        }
        if (hasExtendedHdr) {
            throw new Error('Has extended hdr which is not supported yet');
        }
        const frameHeaderSize = byteArray[3] < 3 ? 6 : 10;
        while (offset < byteArray.length) {
            if (offset > size + 10) {
                break;
            }
            const key = this.byteArrayToString(byteArray.slice(offset, offset + 4));
            const frameSize =
              (byteArray[offset + 7] & 0xff) |
              ((byteArray[offset + 6] & 0xff) << 8) |
              ((byteArray[offset + 5] & 0xff) << 16) |
              (byteArray[offset + 4] << 24);
            if (frameSize > 0) {
                const tag = {
                    key,
                    data: byteArray.slice(
                      offset + 11,
                      offset + frameSize + frameHeaderSize
                    )
                };
                try {
                    const characters = [];
                    for (let byte in tag.data) {
                        if (!tag.data.hasOwnProperty(byte)) continue;

                        characters.push(String.fromCharCode(tag.data[byte]));
                    }
                    tag.value = characters.join('');
                } catch (e) {
                }

                id3Cue.tags.push(tag);
            }
            offset += frameSize + frameHeaderSize;
        }

        return id3Cue;
    }

    parseM3u8Bitrates(manifest) {
        const getParams = (manifestLine) => {
            const param = manifestLine.split(':');
            if (param[0] !== '#EXT-X-STREAM-INF') {
                return;
            }
            if (param[1]) {
                const outParams = {
                    bitrate: null,
                    width: null,
                    height: null
                };
                const subparams = param[1].split(',');
                for (let p = 0; p < subparams.length; p++) {
                    const values = subparams[p].split('=');
                    if (values.length === 2) {
                        switch (values[0]) {
                            case 'AVERAGE-BANDWIDTH': {
                                outParams.bitrate = values[1];
                                break;
                            }
                            case 'BANDWIDTH': {
                                if (!outParams.bitrate) {
                                    outParams.bitrate = values[1];
                                }
                                break;
                            }
                            case 'RESOLUTION': {
                                const wh = values[1].split('x');

                                if (wh.length === 2) {
                                    outParams.width = parseInt(wh[0]) || -1;
                                    outParams.height = parseInt(wh[1]) || -1
                                }
                                break;
                            }
                        }
                    }
                }
                return outParams;
            }
        };
        const lines = manifest.split('\n');

        const out = {};
        for (let i in lines) {
            if (!lines.hasOwnProperty(i)) continue;

            const params = getParams(lines[i]);
            if (params && params.bitrate) {
                out[params.bitrate] = params;
            }
        }

        return Object.keys(out).length > 0 ? out : null;
    }

    pathToObject(path, value, separator = '.') {
        const object = {};

        path = path.split(separator);

        let current = object;
        for (let i = 0; i < path.length - 1; ++i) {
            if (!current[path[i]]) {
                current[path[i]] = {};
            }
            current = current[path[i]];
        }

        current[path[path.length - 1]] = value;

        return object;
    };

    randomInteger(min, max) {
        min = Math.ceil(min || 0);
        max = Math.floor(max || 1000000000);

        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    removeClass(node, clazz) {
        if (this.hasClass(node, clazz)) {
            node.className = node.className.replace(clazz, '')
              .trim()
              .replace(/\s+/g, ' ');
        }
    }

    requiresUpdate(minimumFirmwareVersion) {
        const firmwareVersion = this.getFirmwareVersion();
        if (!firmwareVersion) {
            return false;
        }

        return this.compareVersions(firmwareVersion, minimumFirmwareVersion) < 0;
    }

    resolveUrl() {
        var numUrls = arguments.length;

        if (numUrls === 0) {
            throw new Error('Utils.resolveUrl requires at least one argument!');
        }

        var base = document.createElement('base');
        base.href = 0 === arguments[0].indexOf('//') ? window.location.protocol + arguments[0] : arguments[0];

        if (numUrls === 1) {
            return base.href;
        }

        var head = document.getElementsByTagName('head')[0];
        head.insertBefore(base, head.firstChild);

        var a = document.createElement('a');
        var resolved;

        for (var index = 1; index < numUrls; index++) {
            a.href = arguments[index];
            resolved = a.href;
            base.href = resolved;
        }

        head.removeChild(base);

        return resolved;
    }

    stringOrDefault(value, defaultValue) {
        return (this.isString(value) && value) ? value : defaultValue;
    }
};