import { CoreTrackingEvent } from "./types";

export const getAdEventType = ({ name, sponsor }: { name: string, sponsor: boolean }) => {
  if (sponsor) return CoreTrackingEvent.STREAM_SPONSOR_START;

  // The "bumper" title is controlled on our side. The
  // code that generates the title has a comment in it that
  // we use it for tracking purposes. It should not change
  // unexpectedly.
  if (name.indexOf("bumper") === 0)
    return CoreTrackingEvent.STREAM_JINGLE_START;

  // yospace controls the "filler" title. If it changes
  // on their side, fillers will start being tracked as
  // regular ads.
  if (name.indexOf("filler") === 0)
    return CoreTrackingEvent.STREAM_FILLER_START;

  // Again, this may or may not remain true in the future.
  // Best effort tracking to differentiate from "regular"
  // ads.
  if (name.indexOf("trailer") === 0)
    return CoreTrackingEvent.STREAM_TRAILER_START;

  return CoreTrackingEvent.STREAM_AD_START;
};
