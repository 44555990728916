const AdvertisementBreak = require('./AdvertisementBreak');
const Drm = require('./Drm');
const Encryption = require('./Encryption');
const StreamRestrictions = require('./StreamRestrictions');
const { AdvertisementTypes, ContainerTypes, ContentTypes, StreamTypes } = require('../Constants');
const Utils = require('../utils/Utils');

/**
 * @typedef {object} TStreamArgs
 * @property {Array<AdvertisementBreak>} [advertisementBreaks]
 * @property {string} advertisementType
 * @property {string} containerType
 * @property {string} contentType
 * @property {Encryption} [encryption]
 * @property {Array<Drm>} drms
 * @property {string} [playbackSessionUrl]
 * @property {StreamRestrictions} [streamRestrictions]
 * @property {string} [streamType]
 * @property {string} url
 */
/**
 * @type {TStreamArgs}
 */
class Props {
    constructor() {
        this.advertisementBreaks = this.advertisementType = this.containerType = this.contentType = this.drms =
          this.playbackSessionUrl = this.streamRestrictions = this.streamType = this.url = undefined;
    }
}

class Stream extends Props {
    /**
     * @param {TStreamArgs} args
     */
    constructor(args = {}) {
        super();

        Utils.extend(this, {
            advertisementBreaks: [],
            advertisementType: AdvertisementTypes.None,
            contentType: ContentTypes.Dash,
            drms: [],
            streamType: StreamTypes.OnDemand
        }, args);

        /**
         * Default containerType for Hls is Mp2Ts and Mp4IsoBmff for Dash (default contentType)
         */
        if (!this.containerType) {
            this.containerType = this.contentType === ContentTypes.Hls ? ContainerTypes.Mp2Ts : ContainerTypes.Mp4IsoBmff;
        }
    }
}

module.exports = Stream;