const Utils = require('./Utils');

module.exports = new class Console {
    constructor() {
        this._console = null;

        this.configuration = {
            blockConsole: true,
            debugConsole: {
                debug: false,
                log: false,
                info: false,
                warn: false,
                error: false
            },
            send: () => {
            }
        };

        this.excludeFromDebugConsole = [
            '[cast.receiver.IpcChannel] IPC message sent:'
        ];
    }

    configure(configuration) {
        Object.assign(this.configuration, configuration);

        return this;
    }

    intercept(debugConsole = {}) {
        Object.assign(this.configuration.debugConsole, debugConsole);

        if (!this._console) {
            this._console = {
                debug: console.debug.bind(console),
                log: console.log.bind(console),
                info: console.info.bind(console),
                warn: console.warn.bind(console),
                error: console.error.bind(console)
            };
        }

        const isExcludedFromDebugConsole = (...args) => {
            if (!Utils.isString(args[0])) return false;

            return this.excludeFromDebugConsole.some(message => args[0].includes(message));
        };

        const hijack = (type) => {
            console[type] = (...args) => {
                if (this.configuration.debugConsole[type] && !isExcludedFromDebugConsole(...args)) {
                    try {
                        this.configuration.send({ type: 'Debug.Console', dataType: type, data: args });
                    } catch (e) {
                    }
                }

                if (!this.configuration.blockConsole) {
                    this._console[type](...args);
                }
            };
        };

        [
            'debug',
            'log',
            'info',
            'warn',
            'error',
        ].forEach(type => hijack(type));
    }

    restore() {
        if (this._console) {
            console.debug = this._console.debug;
            console.log = this._console.log;
            console.info = this._console.info;
            console.warn = this._console.warn;
            console.error = this._console.error;

            this._console = null;
        }
    }
};