export type GraphChannel = {
    id: string;
    name: string;
    icons: GraphIcons;
    playbackSpec: GraphPlaybackSpec;
    programs?: GraphPrograms;
};

export type GraphMedia = {
    __typename: GraphMediaTypes;
    id: string;
    title: string;
    descriptionLong: string;
    seasonNumber: GraphSeasonNumber;
    episodeNumber: GraphEpisodeNumber;
    series: GraphSeries;
    images: GraphImages;
    nextEpisode?: {
        __typename: GraphMediaTypes;
        id: string;
        title: string;
        descriptionLong: string;
        seasonNumber: GraphSeasonNumber;
        episodeNumber: GraphEpisodeNumber;
        series: GraphSeries;
        images: GraphImages;
        playback?: {
            play?: {
                subscription?: [{
                    item?: {
                        playbackSpec: GraphPlaybackSpec
                    }
                }],
                rental?: [{
                    item?: {
                        playbackSpec: GraphPlaybackSpec
                    }
                }]
            }
        }
    }
    playback?: {
        play?: {
            linear?: {
                item?: {
                    endTime?: {
                        timestamp: number
                    },
                    startTime?: {
                        timestamp: number
                    }
                    playbackSpec: GraphPlaybackSpec
                    startover?: {
                        playbackSpec: GraphPlaybackSpec
                    }
                    channel?: {
                        playbackSpec: GraphPlaybackSpec
                    }
                }
            }
            npvr?: [{
                npvrInfo: {
                    originalAirDate: {
                        startDate: {
                            timestamp: number
                        }
                        endDate: {
                            timestamp: number
                        }
                    }
                }
            }]
        }
    }
};

export enum GraphMediaTypes {
    Episode = 'Episode',
    Movie = 'Movie',
    Channel = 'Channel'
};

export type GraphIcons = {
    light: GraphImage;
    dark: GraphImage;
};

export type GraphImage = {
    source?: string;
    sourceNonEncoded?: string;
};

export type GraphImages = {
    showcard2x3?: GraphImage;
    showcard16x9?: GraphImage;
    backdrop16x9?: GraphImage;
    backdrop4x3?: GraphImage;
    screenshot16x9?: GraphImage;
};

export type GraphPlaybackSpec = {
    videoId: string;
    videoIdType: string;
    watchMode: string;
    accessControl: string;
};

export type GraphPrograms = {
    programItems: Array<GraphProgramItem>;
};

export type GraphProgramItem = {
    id: string;
    startTime: GraphStartTime;
    endTime: GraphEndTime;
    live: boolean;
    rerun: boolean;
    media: GraphProgramMedia;
};

export type GraphStartTime = {
    timestamp: number;
};

export type GraphEndTime = {
    timestamp: number;
};

export type GraphProgramMedia = {
    __typename: GraphMediaTypes;
    id: string;
    title: string;
    descriptionLong: string;
    seasonNumber: GraphSeasonNumber;
    episodeNumber: GraphEpisodeNumber;
    series: GraphSeries;
    images: GraphImages;
    playback: {
        play?: {
            linear?: {
                item?: {
                    startover?: {
                        playbackSpec: GraphPlaybackSpec
                    }
                }
            }
        }
    }
};

export type GraphSeries = {
    id: string;
    title: string;
    seasons: GraphSeasons;
};

export type GraphSeasons = {
    seasonItems: Array<GraphSeasonItems>;
};

export type GraphSeasonItems = {
    id: string;
    seasonNumber: GraphSeasonNumber;
    episodes: GraphEpisodes;
};

export type GraphEpisodes = {
    episodeItems: Array<GraphEpisodeItems>
};

export type GraphEpisodeItems = {
    id: string;
    episodeNumber: GraphEpisodeNumber;
};

export type GraphSeasonNumber = {
    number: number;
};

export type GraphEpisodeNumber = {
    number: number;
};
