const Utils = require('../utils/Utils');
const Content = require('./Content');
const NormalizedError = require('./NormalizedError');

/**
 * @typedef {object} TReceiverErrorArgs
 * @property {string} [code]
 * @property {Content} [content]
 * @property {string} [displayMessage]
 * @property {boolean} [fatal]
 * @property {boolean} [handled]
 * @property {string} [message]
 * @property {boolean} [notify]
 * @property {boolean} [notificationDuration]
 * @property {NormalizedError} [normalizedError]
 * @property {string} [origin]
 * @property {*} [originalError]
 * @property {boolean} [reload]
 * @property {boolean} [reloadAbortable]
 * @property {number} [reloadDelay]
 * @property {string} [stack]
 * @property {Array<*>} [stackedErrors]
 * @property {boolean} [terminate]
 * @property {number} [terminateDelay]
 */
/**
 * @type {TReceiverErrorArgs}
 */
class Props {
    constructor() {
        this.code = this.content = this.displayMessage = this.fatal = this.handled = this.message = this.notify =
          this.notificationDuration = this.normalizedError = this.origin = this.originalError = this.reload =
            this.reloadAbortable = this.reloadDelay = this.stack = this.stackedErrors = this.terminate =
              this.terminateDelay = undefined;
    }
}

class ReceiverError extends Props {
    /**
     * @param {TReceiverErrorArgs} args
     */
    constructor(args = {}) {
        super();

        Utils.extend(this, {
            stackedErrors: [],
            notify: Utils.booleanOrDefault(args.notify, false),
            notificationDuration: 10000
        }, args);
    }
}

module.exports = ReceiverError;