import {
    BitrateChangedEvent,
    DroppedFramesEvent,
    CustomTrackingEvent,
    PlaybackErrorEvent,
    StoppedEvent,
    StreamInfoEvent,
    TimeUpdateEvent,
    TrackingInfoEvent
} from '@tv4/one-playback-sdk-shared';

import youbora from 'youboralib';
import { TYouboraTrackerConfiguration } from './Types';
import { convertTrackerErrorToYouboraError, ignore, TYouboraTrackerData } from "./YouboraTracker";

const FirstFrameMargin = 0.1;

const isBoolean = (value: any): boolean => typeof (value) === 'boolean';
const isNumber = (value: any): boolean => typeof (value) === 'number' && isFinite(value);
const isString = (value: any): boolean => typeof (value) === 'string' || value instanceof String;

export class PlayerAdapter {
    private bitrate?: number;
    private contentUrl?: string;
    private duration: number;
    private hasPlayedOnce: boolean;
    private height?: number;
    private isBuffering: boolean;
    private isLive: boolean;
    private isPlayDeferred: boolean;
    private isSeeking: boolean;
    private playhead: number;
    private startTime?: number;
    private totalDroppedFrames: number;
    private width?: number;

    public adapter: youbora.Adapter;

    constructor(configuration: TYouboraTrackerConfiguration) {
        const self = this;

        this.bitrate = undefined;
        this.contentUrl = undefined;
        this.duration = 0;
        this.hasPlayedOnce = false;
        this.height = undefined;
        this.isBuffering = false;
        this.isLive = false;
        this.isPlayDeferred = false;
        this.isSeeking = false;
        this.playhead = 0;
        this.startTime = undefined;
        this.totalDroppedFrames = 0;
        this.width = undefined;

        const playerName = configuration.player.playerName;
        const playerVersion = configuration.player.playerVersion;
        const Adapter = youbora.Adapter.extend({
            getVersion(): string | null {
                return `${youbora.VERSION}-${playerName}-${playerVersion}`;
            },

            getPlayerName(): string | null {
                return playerName ?? null;
            },

            getPlayerVersion(): string | null {
                return playerVersion ?? null;
            },

            getBitrate(): number | null {
                return self.bitrate || null;
            },

            getRendition(): any {
                if (self.bitrate) {
                    return youbora.Util.buildRenditionString(
                        self.width,
                        self.height,
                        self.bitrate
                    );
                }
                return null;
            },

            getResource(): string | undefined {
                return self.contentUrl;
            },

            getPlayhead(): number {
                return self.getPlayhead(false);
            },

            getDuration(): number {
                return self.isLive ? 0 : self.duration;
            },

            getDroppedFrames(): number {
                return self.totalDroppedFrames;
            },
        });

        this.adapter = new Adapter();
    }

    private getPlayhead(unmodified: boolean): number {
        if (this.startTime == null) {
            this.setStartTime(this.playhead);
        }

        return this.isLive && !unmodified
            ? this.playhead - (this.startTime || 0)
            : this.playhead;
    }

    private setStartTime(startTime?: number): void {
        if (isNumber(startTime)) {
            this.startTime = startTime;
        }
    }

    public initialize (data: TYouboraTrackerData) {
        this.isLive = data.live;
    }

    public bitrateChanged({ payload }: BitrateChangedEvent): void {
        this.bitrate = payload.bitrate;
        this.height = payload.height;
        this.width = payload.width;
    }

    public buffering(): void {
        this.isBuffering = true;
        this.adapter.fireBufferBegin();
    }

    public buffered(): void {
        this.adapter.fireBufferEnd();
        this.isBuffering = false;
        if (this.isPlayDeferred) {
            this.isPlayDeferred = false;
            this.playing();
        }
    }

    public droppedFrames({ payload }: DroppedFramesEvent) {
        if (!payload || !payload.total) return;

        this.totalDroppedFrames = payload.total;
    }

    public error({ payload }: PlaybackErrorEvent) {
        if (!payload.error || ignore(payload.error)) return;

        // if errors occur before `fireStart` was called we *have* to trigger it here,
        // otherwise `fireStop` won't work
        !this.adapter.flags.isStarted && this.adapter.fireStart();

        this.adapter.fireError(convertTrackerErrorToYouboraError(payload.error));

        this.adapter.fireStop();
    }

    public paused(): void {
        this.adapter.firePause();
    }

    public playing(): void {
        if (!this.hasPlayedOnce) {
            this.hasPlayedOnce = true;
            this.setStartTime(this.playhead);
            this.adapter.fireStart();
        }
        else {
            // If we're still buffering this 'play' event is invalid
            if (!this.isBuffering && !this.isSeeking) {
                this.adapter.fireResume();
            } else {
                this.isPlayDeferred = true;
            }
        }
    }

    public seeking(): void {
        this.isSeeking = true;
        this.adapter.fireSeekBegin();
    }

    public seeked(): void {
        this.adapter.fireSeekEnd();
        this.isSeeking = false;
        if (this.isPlayDeferred) {
            this.isPlayDeferred = false;
            this.playing();
        }
    }

    public starting(): void {
        this.adapter.fireInit();
    }

    public stopped({ payload }: StoppedEvent): void {
        this.adapter.fireStop();
    }

    public streamInfo({ payload }: StreamInfoEvent): void {
        if (!payload || !payload.contentUrl) return;

        this.contentUrl = payload.contentUrl;
    }

    public custom(event: CustomTrackingEvent): void {
        this.adapter.fireEvent(event.payload.name);
    }

    public timeUpdate({ payload }: TimeUpdateEvent): void {
        this.playhead = payload.currentTime;
        this.duration = payload.duration;

        this.adapter.fireStart();

        if (this.startTime != null && this.isLive) {
            this.adapter.fireJoin();
        } else if (
            !this.isLive &&
            this.startTime != null &&
            this.getPlayhead(true) > this.startTime + FirstFrameMargin
        ) {
            // check that playhead is at least FirstFrameMargin bigger than startTime to verify that the first frame is truly there on all browsers
            this.adapter.fireJoin();
        }
    }

    public trackingInfo({ payload }: TrackingInfoEvent): void {
        if (!payload) return;

        if (payload.content && isString(payload.content.contentUrl)) {
            this.contentUrl = payload.content.contentUrl;
        }

        if (payload.tracking && payload.tracking.YOUBORA && isBoolean(payload.tracking.YOUBORA.live)) {
            this.isLive = payload.tracking.YOUBORA.live;
        }
    }

    public reset(): void {
        this.bitrate = undefined;
        this.contentUrl = undefined;
        this.duration = 0;
        this.hasPlayedOnce = false;
        this.height = undefined;
        this.isBuffering = false;
        this.isLive = false;
        this.isPlayDeferred = false;
        this.isSeeking = false;
        this.playhead = 0;
        this.startTime = undefined;
        this.totalDroppedFrames = 0;
        this.width = undefined;
    }

    public destroy(): void {
        this.reset();
    }
};
