import { ErrorCodes, ErrorOrigins, NormalizedError, ServiceLayerError } from '@tv4/unified-receiver';
import { StandardError } from '@tv4/one-playback-sdk-shared';
import { ErrorCategories } from '../Constants';

const getStack = (error?: Error) => {
    let stack = error?.stack;
    if (!stack) {
        try { throw new Error(); }
        catch (e) {
            if (!(e instanceof Error) || !e.stack) return stack;

            try {
                const stackParts = e.stack.split('\n');
                stackParts.splice(1, 2); // Remove getStack && factory function lines
                if (stackParts[1] && stackParts[1].includes('.create (')) { // Remove nested factory function line
                    stackParts.splice(1, 1);
                }
                stack = stackParts.join('\n');
            }
            catch (err) {
                stack = e.stack;
            }
        }
    }

    return stack;
};

const create = (error: ServiceLayerError | StandardError | Error): ServiceLayerError => {
    if (error instanceof ServiceLayerError) return error;
    if (error instanceof StandardError) return createFromStandardError(error);

    return createFromError(error);
};

const createFatalError = (domain: string, errorName: string, message?: string) => {
    const stack = getStack();

    return new ServiceLayerError({
        code: ErrorCodes.Generic, // Code for translation - not necessarily the actual error code
        fatal: true,
        normalizedError: new NormalizedError({
            category: ErrorCategories.DEFAULT,
            code: `ServiceLayer:${domain}:${errorName}`,
            details: { message, stack },
            fatal: true
        }),
        origin: ErrorOrigins.ServiceLayer.Generic,
        reload: true,
        stack,
        terminate: false
    });
};

const createNoneFatalError = (domain: string, errorName: string, message?: string) => {
    const stack = getStack();

    return new ServiceLayerError({
        code: ErrorCodes.Generic, // Code for translation - not necessarily the actual error code
        fatal: false,
        normalizedError: new NormalizedError({
            category: ErrorCategories.DEFAULT,
            code: `ServiceLayer:${domain}:${errorName}`,
            details: { message, stack },
            fatal: true
        }),
        origin: ErrorOrigins.ServiceLayer.Generic,
        reload: true,
        stack,
        terminate: false
    });
};


const createFromError = (error: Error) => {
    const message = error.message;
    const stack = error.stack ?? getStack();

    return new ServiceLayerError({
        code: ErrorCodes.Generic, // Code for translation - not necessarily the actual error code
        fatal: true,
        normalizedError: new NormalizedError({
            category: ErrorCategories.DEFAULT,
            code: 'ServiceLayer:Error:Unknown',
            details: { message, stack },
            fatal: true
        }),
        origin: ErrorOrigins.ServiceLayer.Generic,
        reload: true,
        terminate: false,
        stack
    });
};

const createFromStandardError = (standardError: StandardError) => {
    const stack = standardError.details?.originalError?.stack ?? getStack();

    return new ServiceLayerError({
        code: standardError.code || ErrorCodes.Generic, // Code for translation - not necessarily the actual error code
        fatal: standardError.category !== ErrorCategories.GRAPH,
        normalizedError: new NormalizedError(standardError),
        stack
    });
};

const createTerminatingError = (domain: string, errorName: string, message?: string, terminateDelay: number = 5000) => {
    const code = `ServiceLayer:${domain}:${errorName}`;
    const stack = getStack();

    return new ServiceLayerError({
        code: code, // Code for translation - not necessarily the actual error code
        fatal: true,
        normalizedError: new NormalizedError({
            category: ErrorCategories.DEFAULT,
            code,
            details: { message, stack },
            fatal: true
        }),
        origin: ErrorOrigins.ServiceLayer.Generic,
        reload: false,
        stack,
        terminate: true,
        terminateDelay
    });
};

export const ServiceLayerErrorFactory = {
    create,
    createFatalError,
    createNoneFatalError,
    createFromError,
    createFromStandardError,
    createTerminatingError
};
