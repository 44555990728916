import { UserInfo } from "../Types";
export const parseUserInfoFromAccessToken = (accessToken: string): UserInfo | null => {
    const tokenParts = accessToken.split('.');
    if (tokenParts && tokenParts[1]) {
        try {
            const decoded = atob(tokenParts[1]);
            const parsed = JSON.parse(decoded);
            if (parsed && parsed.sub) {
                return {
                    userId: parsed.sub,
                    userIsLoggedIn: true
                };
            }
        } catch (e) {}
    }

    return null;
};

export const parseUserInfoFromToken = (refreshToken: string): string | null => {
    const tokenParts = refreshToken.split('.');
    let tokenPartsOffset;
    if(typeof tokenParts[0] === 'string' && tokenParts[0].toLowerCase() === 'refresh'){
        tokenPartsOffset = 2;
    }else{
        tokenPartsOffset = 1
    }
    if (tokenParts && tokenParts[tokenPartsOffset]) {
        try {
            const decoded = atob(tokenParts[tokenPartsOffset]);
            const parsed = JSON.parse(decoded);
            if (parsed && parsed.sub) {
                return parsed.sub
            }
        } catch (e) {}
    }

    return null;
};

