import { MediaMetadata, MediaTypes,  } from '../Types'
import { GraphMedia } from './GraphTypes';

export const MediaMetadataMapper = function (response: { data: { media: GraphMedia } }): MediaMetadata | null {
    if (!(response && response.data && response.data.media)) {
        throw new Error('MetadataService::ChannelMetadataMapper: Missing data or media!');
    }

    const graphMedia = response.data.media;
    // see if we have any next content
    const nextContentPlaybackSpec = graphMedia.nextEpisode?.playback?.play?.subscription?.[0]?.item?.playbackSpec
      || graphMedia.nextEpisode?.playback?.play?.rental?.[0]?.item?.playbackSpec

    let nextContent;

    const getNpvrStartTime = (graphMedia: GraphMedia) => {
        return graphMedia.playback?.play?.npvr?.[0].npvrInfo.originalAirDate.startDate.timestamp;
    }

    const getNpvrEndTime = (graphMedia: GraphMedia) => {
        return graphMedia.playback?.play?.npvr?.[0]?.npvrInfo.originalAirDate.endDate.timestamp;
    }

    const flattenImages = (images: any) => {
        let flatImages: any = {};
        Object.keys(images)
            .filter(image => !!images[image])
            .forEach(image => flatImages[image] = images[image].sourceNonEncoded || decodeURIComponent(images[image].source));
        return flatImages;
    }
    if (nextContentPlaybackSpec && graphMedia.nextEpisode) {
        nextContent = {
            // @ts-ignore
            validFrom: graphMedia.nextEpisode?.playback?.play?.subscription?.[0]?.item?.validFrom?.timestamp
              // @ts-ignore
              || graphMedia.nextEpisode?.playback?.play?.rental?.[0]?.item?.validFrom?.timestamp,
            playbackSpec: nextContentPlaybackSpec,
            metadata: {
                type: graphMedia.nextEpisode.__typename as string as MediaTypes,
                id: graphMedia.nextEpisode.id,
                description: graphMedia.nextEpisode.descriptionLong || '',
                title: graphMedia.nextEpisode.title || 'unknown',
                images: graphMedia.nextEpisode.images ? flattenImages(graphMedia.nextEpisode.images) : undefined
            },
            // nextContent: nextContentPlaybackSpec, // not used? relevant?
            seriesTitle: graphMedia.nextEpisode.series.title,
            episode: graphMedia.nextEpisode.episodeNumber.number,
            season: graphMedia.nextEpisode.seasonNumber.number
        };
    }
    const mediaMetadata: MediaMetadata = {
        type: graphMedia.__typename as string as MediaTypes,
        id: graphMedia.id,
        description: graphMedia.descriptionLong || '',
        title: graphMedia.title || 'unknown',
        nextContent: nextContent,
        series: graphMedia.series ? {
            ...graphMedia.series,
            episode: graphMedia.episodeNumber.number,
            season: graphMedia.seasonNumber.number,
        }: undefined,
        startTime: graphMedia.playback?.play?.linear?.item?.startTime?.timestamp || getNpvrStartTime(graphMedia),
        endTime: graphMedia.playback?.play?.linear?.item?.endTime?.timestamp || getNpvrEndTime(graphMedia),
        startoverPlaybackSpec: graphMedia.playback?.play?.linear?.item?.startover?.playbackSpec,
        linearPlaybackSpec: graphMedia.playback?.play?.linear?.item?.playbackSpec,
        channelPlaybackSpec: graphMedia.playback?.play?.linear?.item?.channel?.playbackSpec,
        images: graphMedia.images ? flattenImages(graphMedia.images) : undefined
    };

    return mediaMetadata;
};
