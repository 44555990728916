const isBoolean = (value: any): value is boolean => {
  return typeof (value) === 'boolean';
};

const isString = (value: any): boolean => {
  return typeof (value) === 'string' || value instanceof String;
};

export const guid = (format: string | boolean = false): string => {
  const formats = {
    compact: 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx',
    default: 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
  };

  format = isBoolean(format)
    ? (format ? formats.compact : formats.default)
    : (isString(format)
      ? format
      : formats.default);

  return (format || formats.default).replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0;

    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
};