const { ShakaEmsgFilter } = require('./shaka/ShakaEmsgFilter');

const {
    ContainerTypes,
    ContentTypes,
    ErrorOrigins,
    NetworkFilterTypes,
    ProtectionSystemTypes,
    StreamTypes,
    StreamCueTypes,
    StoppedReasons,
} = require('../Constants');

const {
    DushEvents,
    EngineEvents,
    NetworkEvents,
    ShakaWackaEvents,
    WackaEvents,
    NotificationEvents
} = require('../Events');

const ListenableListener = require('../event/ListenableListener');
const { ErrorManager, UiManager } = require('../managers/');
const NetworkInterceptor = require('../utils/network/NetworkInterceptor');
const Utils = require('../utils/Utils');
const { RestrictionHelper } = require('./helpers/RestrictionHelper');
const Dush = require('../player/Dush');
const ShakaDashStaticTransitionHandler = require('../player/shaka/ShakaDashStaticTransitionHandler');
const ShakaMp4TtmlParser = require('../player/shaka/ShakaMp4TtmlParser');
const ShakaWacka = require('../player/ShakaWacka');
const Wacka = require('../player/Wacka');

const { Drm, Stream, StreamRestrictions } = require('../dtos');

const BufferingMargin = 1; // 1s === safety margin to account for straggling time_update events

const isLiveStream = stream => stream && [StreamTypes.Dvr, StreamTypes.Live, StreamTypes.StartOver].includes(stream.streamType);
const isHlsCmafStream = stream => stream && stream.contentType === ContentTypes.Hls && stream.containerType === ContainerTypes.Mp4Cmaf;

//|todo Engine.js [TASK][Major]: This is far to simple and should take more aspects in to account;
const gapDetector = (mediaElement) => {

    // const bufferEnd = mediaElement.buffered.end(mediaElement.buffered.length - 1);
    // const bufferAhead = bufferEnd - mediaElement.currentTime;

    if (mediaElement.buffered.length > 1 && this.seeking === false) {

        if (mediaElement.currentTime > mediaElement.buffered.start(1)) return;

        const diff = mediaElement.buffered.start(1) - mediaElement.buffered.end(0);

        console.log(`###--| GapDetected: ${diff}ms taken action: ${(diff > 0.1)}`);

        if (diff > 0.1) return;

        mediaElement.currentTime += diff + 0.01;

    }
}

module.exports = class Engine extends ListenableListener {
    /**
     * @typedef {Object} EngineArgs
     * @param {ErrorManager} errorManager
     * @param {Object} features
     * @param {Function} [stoppedHandler]
     * @param {UiManager} uiManager
     */
    /**
     * @param {EngineArgs} args
     */
    constructor(args) {
        super();
        const {
            errorManager, features, stoppedHandler = () => {
            }, uiManager
        } = args;

        this.destroyed = false;
        this.name = 'Engine';
        /** @type {ErrorManager} */
        this.errorManager = errorManager;
        this.config = { features };
        this.stoppedHandler = stoppedHandler;
        this.uiManager = uiManager;
        /** @type {HTMLElement} */
        this.mediaElement = this.uiManager.domElements.mediaElement;
        this.textContainer = this.uiManager.domElements.textContainer;

        this.advertisementBreakCurrentTime = 0;
        this.buffering = false;
        this.bufferingPosition = -1;
        /** @type {string} */
        this.cdn = null;
        this.currentTime = 0;
        this.currentMediaElementTime = 0;
        this.droppedFrames = 0;
        this.duration = -1;
        this.hasEmittedStopped = false;
        this.hasLoadedStream = false;
        this.hasMediaLoaded = false;
        this.lastAdvertisementTimeUpdate = null;
        this.license = null;
        this.manifestBitrates = null;
        this.mediaElementEventListeners = {};
        this.minimumRangeForSeeking = 180;
        this.networkFilters = [];
        this.networkInterceptorEventListeners = {};
        this.playbackFeatures = {};
        this.player = null;
        this.playerConfiguration = null;
        this.playerConfigurationProfile = null;
        this.playerEventListeners = {};
        this.playerManagerEventListeners = {};
        this.playerName = null;
        this.playerType = null;
        this.preferredTracks = null;
        this.restrictionHelper = null;
        this.seeking = false;
        this.seekableRange = null;
        this.seekableRangeUpdateTolerance = 1;
        this.stoppedReason = null;
        this.streamLimitations = null; // Technical limitations
        this.streamRestrictions = null; // Business rules
        this.stream = null;
        this.streams = null;
        this.textTrackEventListeners = {};
        this.useDashStaticTransitionHandler = false;
        this.useDush = false;
        this.useSeekableRangeEndAsDuration = true;
        this.useShakaWacka = false;
        this.useTtmlHandler = false;
        this.useWacka = false;

        this.networkInterceptor = NetworkInterceptor.getInstance();

        this.mediaSourceIsTypeSupported = MediaSource.isTypeSupported;
        this.textTrackAddCue = TextTrack.prototype.addCue;

        this.playerManager = cast.framework.CastReceiverContext
          .getInstance()
          .getPlayerManager();

        this.registerDrmHandler();

        this.defaultPlayerConfiguration = {
            dush: {},
            mpl: {},
            shaka: { // Also applies to shakalaka, shakawacka & shakawackalaka
                abr: {
                    bandwidthDowngradeTarget: 0.95,
                    bandwidthUpgradeTarget: 0.8,
                    switchInterval: 5
                },
                manifest: {
                    dash: {
                        clockSyncUri: 'https://time.akamai.com/?iso'
                    },
                    hls: {
                        ignoreTextStreamFailures: true,
                        useFullSegmentsForStartTime: true
                    },
                    retryParameters: {
                        baseDelay: 600,
                        fuzzFactor: 0.1,
                        maxAttempts: 5,
                        timeout: 30000
                    }
                },
                streaming: {
                    bufferBehind: 10,
                    bufferingGoal: 30,
                    ignoreTextStreamFailures: true,
                    retryParameters: {
                        baseDelay: 600,
                        fuzzFactor: 0.1,
                        maxAttempts: 5,
                        timeout: 30000
                    }
                }
            },
            wacka: {
                debug: false,
                autoStartLoad: true,
                enableWorker: false,
                // highBufferWatchdogPeriod: 2, // Old, but default has changed from 3 to 2
                lowLatencyMode: false, // New - default: true
                progressive: false, // New - default: false
                startLevel: -1,

                abrEwmaFastLive: 3.0,
                abrEwmaSlowLive: 9.0,
                abrEwmaFastVoD: 3.0,
                abrEwmaSlowVoD: 9.0,
                abrMaxWithRealBitrate: true, // New - default: false

                backBufferLength: 10, // New - default: 90
                maxBufferSize: 20 * 1000 * 1000,
                maxBufferLength: 30,
                maxMaxBufferLength: 30,

                manifestLoadingTimeOut: 10e3,
                levelLoadingTimeOut: 10e3,
                fragLoadingTimeOut: 10e3,

                manifestLoadingMaxRetry: 3,
                levelLoadingMaxRetry: 10,
                fragLoadingMaxRetry: 10,

                initialLiveManifestSize: 3,
                liveSyncDuration: 30,
                liveMaxLatencyDuration: 120, // ToDo: Test Infinity !
                liveDurationInfinity: false,
                maxLiveSyncPlaybackRate: 1 // New - default: 1, min: 1, max: 2
            }
        };

        this.liveHlsCmafPlayerConfigurationOverrides = {
            shaka: {
                manifest: {
                    defaultPresentationDelay: 40,
                    availabilityWindowOverride: 90
                }
            }
        };
    }

    /** @private */
    emitEngineEvent(event, data) {
        if (this.playbackFeatures.yospace) {
            const intercepted = this.playbackFeatures.yospace.interceptEngineEvent(event, data);
            if (intercepted) return;
        }

        this.emit(event, data);
    }

    /** @private */
    emitEngineEventFromPlaybackFeature(playbackFeature, event, data) {
        if ('yospace' === playbackFeature) {
            const activeAdvertisementBreak = this.stream.advertisementBreaks.find(a => a.active);
            switch (event) {
                case EngineEvents.AdvertisementEnded:
                    break;
                case EngineEvents.AdvertisementStarted:
                    break;
                case EngineEvents.AdvertisementBreakEnded:
                    if (activeAdvertisementBreak) {
                        activeAdvertisementBreak.active = false;
                        activeAdvertisementBreak.watched = true;
                        activeAdvertisementBreak.completion = 100;
                    }
                    this.advertisementBreakCurrentTime = 0;
                    this.lastAdvertisementTimeUpdate = null;
                    break;
                case EngineEvents.AdvertisementBreakStarted:
                    const advertisementBreak = this.stream.advertisementBreaks.find(a => a.id === (data || {}).id);
                    if (advertisementBreak) {
                        advertisementBreak.active = true;
                        advertisementBreak.completion = 0;
                    }

                    this.advertisementBreakCurrentTime = 0;
                    this.lastAdvertisementTimeUpdate = Date.now();
                    break;
                case EngineEvents.AdvertisementTimeUpdate:
                    const now = Date.now();
                    this.advertisementBreakCurrentTime += (this.lastAdvertisementTimeUpdate ? now - this.lastAdvertisementTimeUpdate : 0) / 1000;

                    if (activeAdvertisementBreak && this.lastAdvertisementTimeUpdate) {
                        const duration = activeAdvertisementBreak.duration || 1;
                        activeAdvertisementBreak.completion = 100 * (this.advertisementBreakCurrentTime / duration);
                    }

                    this.lastAdvertisementTimeUpdate = now;
                    break;
            }
        }

        this.emit(event, data);
    }

    /** @private */
    registerEventListeners() {
        this.listen(this.mediaElement, this.mediaElementEventListeners, 'ended',
          this.onMediaElementEnded.bind(this));
        this.listen(this.mediaElement, this.mediaElementEventListeners, 'loadeddata', () => {
            this.hasLoadedStream = true;
            // ToDo: Should emit stream specs here, eg. initial seekable range, stream 0-time in epoch, etc.
            //  And maybe also the stream object (and loose the contentUrl from LoadedMetadata)
            //  That might be unnecessary though, now that the StreamChanged event exists
            this.emitEngineEvent(EngineEvents.LoadedData);
        });
        this.listen(this.mediaElement, this.mediaElementEventListeners, 'loadedmetadata', () => {
            cast.player.api.setLoggerLevel(cast.player.api.LoggerLevel.ERROR);

            const stream = this.stream || {};
            this.emitEngineEvent(EngineEvents.LoadedMetadata, {
                contentUrl: stream.playbackSessionUrl || stream.url
            });
        });
        this.listen(this.mediaElement, this.mediaElementEventListeners, 'loadstart', () => {
            this.resetPreviousStopInformation();

            /**
             * This needs to be re-applied here because Caf will also configure shaka at some point between creation and
             * stream loading start
             */
            this.applyPlayerConfiguration();

            this.emitEngineEvent(EngineEvents.LoadStart);
        });
        this.listen(this.mediaElement, this.mediaElementEventListeners, 'pause', () => this.emitEngineEvent(EngineEvents.Pause));
        this.listen(this.mediaElement, this.mediaElementEventListeners, 'play', () => {
            if (this.buffering) {
                this.onPlayerManagerBuffered();
            }

            this.emitEngineEvent(EngineEvents.Play);
            this.emitEngineEvent(NotificationEvents.ClearNotification);
        });
        this.listen(this.mediaElement, this.mediaElementEventListeners, 'timeupdate', () => {
            gapDetector(this.mediaElement);
            this.updateCurrentTimeAndDuration();

            const droppedFrames = this.getDroppedFrames();
            if (droppedFrames !== this.droppedFrames) {
                const frames = droppedFrames - this.droppedFrames;
                this.droppedFrames = droppedFrames;

                this.emitEngineEvent(EngineEvents.DroppedFrames, {
                    frames,
                    total: this.droppedFrames
                });
            }

            if (this.buffering) {
                if (Math.abs(this.currentMediaElementTime - this.bufferingPosition) > BufferingMargin) {
                    // currentTime changed enough in either direction
                    this.onPlayerManagerBuffered();
                } else { // Not emitting TimeUpdate while buffering
                    return;
                }
            }

            this.emitEngineEvent(EngineEvents.TimeUpdate, {
                currentTime: this.currentTime,
                duration: this.duration
            });
        });

        const PlayerManagerEvents = cast.framework.events.EventType;
        this.listen(this.playerManager, this.playerManagerEventListeners, PlayerManagerEvents.BITRATE_CHANGED,
          this.onPlayerManagerBitrateChanged.bind(this));
        this.listen(this.playerManager, this.playerManagerEventListeners, PlayerManagerEvents.PLAYING,
          this.onPlayerManagerBuffered.bind(this));
        this.listen(this.playerManager, this.playerManagerEventListeners, PlayerManagerEvents.BUFFERING,
          this.onPlayerManagerBuffering.bind(this));
        this.listen(this.playerManager, this.playerManagerEventListeners, PlayerManagerEvents.ID3,
          this.onPlayerManagerId3Cue.bind(this));
        this.listen(this.playerManager, this.playerManagerEventListeners, PlayerManagerEvents.EMSG,
          this.onPlayerManagerEmsgCue.bind(this));
        this.listen(this.playerManager, this.playerManagerEventListeners, PlayerManagerEvents.ERROR,
          this.onPlayerManagerError.bind(this));
        this.listen(this.playerManager, this.playerManagerEventListeners, PlayerManagerEvents.MEDIA_FINISHED,
          this.onPlayerManagerMediaFinished.bind(this));
        this.listen(this.playerManager, this.playerManagerEventListeners, PlayerManagerEvents.PLAYER_LOAD_COMPLETE,
          this.onPlayerManagerPlayerLoadComplete.bind(this));
        this.listen(this.playerManager, this.playerManagerEventListeners, PlayerManagerEvents.SEEKED,
          this.onPlayerManagerSeeked.bind(this));
        this.listen(this.playerManager, this.playerManagerEventListeners, PlayerManagerEvents.SEEKING,
          this.onPlayerManagerSeeking.bind(this));

        this.listen(this.networkInterceptor, this.networkInterceptorEventListeners, NetworkEvents.CdnDiscovered,
          e => {
              if (e && e.cdn && e.cdn !== this.cdn) {
                  this.cdn = e.cdn;
                  this.emitEngineEvent(EngineEvents.CdnChanged, e);
              }
          });
    };

    /** @private */
    registerAdditionalPlayerEventListeners() {
        if (this.playerName) {
            switch (this.playerName) {
                case 'Dush':
                    this.listen(this.player, this.playerEventListeners, DushEvents.BitratesReady,
                      bitrates => {
                          if (bitrates && bitrates.length) {
                              this.manifestBitrates = bitrates;
                          }
                      });
                    this.listen(this.player, this.playerEventListeners, DushEvents.Error,
                      e => {
                          e = Utils.extend(e || {}, {
                              fatal: true,
                              origin: ErrorOrigins.Receiver.Dush
                          });

                          this.errorManager.dispatchError(e);
                      });
                    this.listen(this.player, this.playerEventListeners, DushEvents.Scte35,
                      segment => this.onPlayerManagerId3Cue(segment));
                    break;
                case 'Mpl':
                    break;
                case 'Shaka':
                    break;
                case 'ShakaLaka':
                    break;
                case 'ShakaWacka':
                case 'ShakaWackaLaka':
                    this.listen(this.player, this.playerEventListeners, ShakaWackaEvents.Error,
                      e => {
                          e = Utils.extend(e || {}, {
                              origin: ErrorOrigins.Receiver.ShakaWacka
                          });
                          this.errorManager.dispatchError(e);
                      });
                    break;
                case 'Wacka':
                    this.listen(this.player, this.playerEventListeners, WackaEvents.BitratesReady,
                      bitrates => {
                          if (bitrates && bitrates.length) {
                              this.manifestBitrates = bitrates;
                          }
                      });
                    this.listen(this.player, this.playerEventListeners, WackaEvents.Error,
                      e => {
                          e = Utils.extend(e || {}, {
                              fatal: true,
                              origin: ErrorOrigins.Receiver.Wacka
                          });

                          if (e.details === 'Wacka.FailedToRecover') {
                              this.stop(StoppedReasons.Error);
                          }

                          this.errorManager.dispatchError(e);
                      });
                    this.listen(this.player, this.playerEventListeners, WackaEvents.Id3,
                      segment => this.onPlayerManagerId3Cue(segment));
                    break;
            }
        }
    }

    /** @private */
    unregisterMediaElementEventListeners() {
        Object.keys(this.mediaElementEventListeners).forEach(e =>
          this.mediaElement.removeEventListener(e,
            this.mediaElementEventListeners[e]
          )
        );
        this.mediaElementEventListeners = {};
    }

    /** @private */
    unregisterEventListeners() {
        this.unregisterMediaElementEventListeners();

        Object.keys(this.textTrackEventListeners).forEach(e =>
          this.textTrackEventListeners[e].track.removeEventListener(e,
            this.textTrackEventListeners[e].listener
          )
        );
        this.textTrackEventListeners = {};

        Object.keys(this.playerManagerEventListeners).forEach(e =>
          this.playerManager.removeEventListener(e,
            this.playerManagerEventListeners[e]
          )
        );
        this.playerManagerEventListeners = {};

        if (this.player && typeof (this.player.removeEventListener) === 'function') {
            Object.keys(this.playerEventListeners).forEach(e =>
              this.player.removeEventListener(e,
                this.playerEventListeners[e]
              )
            );
        }
        this.playerEventListeners = {};

        Object.keys(this.networkInterceptorEventListeners).forEach(e =>
          this.networkInterceptor.off(e,
            this.networkInterceptorEventListeners[e]
          )
        );
        this.networkInterceptorEventListeners = {};
    };

    /** @private */
    applyPlayerConfiguration() {
        if (this.player && this.player.configure) {
            const lowerCasePlayerName = (this.playerName || '').toLowerCase();
            const configurationKey = -1 < lowerCasePlayerName.indexOf('shaka') ? 'shaka' : lowerCasePlayerName; // Use same configuration for all shaka variants
            let playerConfiguration = this.defaultPlayerConfiguration[configurationKey];
            if (
              this.playerConfiguration &&
              this.playerConfiguration[configurationKey] &&
              Object.getOwnPropertyNames(this.playerConfiguration[configurationKey]).length
            ) {
                const playerConfigurations = this.playerConfiguration[configurationKey];

                if (playerConfigurations.default || playerConfigurations[this.playerConfigurationProfile]) {
                    playerConfiguration = Utils.extend(
                      {},
                      playerConfiguration,
                      playerConfigurations.default || {},
                      playerConfigurations[this.playerConfigurationProfile] || {}
                    );
                }
            }

            this.player.configure(playerConfiguration);

            if (
              isHlsCmafStream(this.stream) &&
              isLiveStream(this.stream) &&
              this.liveHlsCmafPlayerConfigurationOverrides[configurationKey] &&
              Object.keys(this.liveHlsCmafPlayerConfigurationOverrides[configurationKey]).length
            ) {
                this.player.configure(this.liveHlsCmafPlayerConfigurationOverrides[configurationKey]);
            }
        }
    }

    /** @private */
    applyStreamCapabilities() {
        if (this.commandPossible('pause')) {
            this.playerManager.addSupportedMediaCommands(cast.framework.messages.Command.PAUSE, true);
        } else {
            this.playerManager.removeSupportedMediaCommands(cast.framework.messages.Command.PAUSE, true);
        }

        if (this.commandPossible('seek')) {
            this.playerManager.addSupportedMediaCommands(cast.framework.messages.Command.SEEK, true);
        } else {
            this.playerManager.removeSupportedMediaCommands(cast.framework.messages.Command.SEEK, true);
        }
    }

    /** @private */
    commandAllowed(command) {
        return !this.streamRestrictions ||
          !Utils.booleanOrDefault(this.streamRestrictions[command], false);
    }

    /** @private */
    commandHandleable(command) {
        return !this.streamLimitations ||
          !Utils.booleanOrDefault(this.streamLimitations[command], false);
    }

    /** @private */
    commandPossible(command) {
        return this.commandAllowed(command) && this.commandHandleable(command);
    }

    /** @private */
    getBitratesFromPlayer() {
        let bitrates = {};
        if (this.player && this.player.getVariantTracks) {
            const variantTracks = this.player.getVariantTracks() || [];
            if (variantTracks && variantTracks.length) {
                variantTracks.forEach(t => {
                    bitrates[t.bandwidth] = {
                        bitrate: t.bandwidth,
                        width: t.width,
                        height: t.height
                    };
                });
            }
        }

        return Object.keys(bitrates).length > 0 ? bitrates : null;
    }

    /** @private */
    handleLoadRequest(request) {
        return new Promise(resolve => {
            if (!this.playerManager) {
                const error = new cast.framework.messages.ErrorData(cast.framework.messages.ErrorType.LOAD_CANCELLED);
                error.reason = cast.framework.messages.ErrorReason.GENERIC_LOAD_ERROR;
                error.customData = {
                    message: 'Engine::handleLoadRequest: Missing playerManager reference'
                };
                return resolve(error);
            }

            this.resetEventListeners();
            this.registerEventListeners();

            this.playerName = 'Mpl';
            this.useDush = false;
            this.useWacka = false;
            this.useShakaWacka = false;
            const containerType = this.stream.containerType;
            const contentType = this.stream.contentType;
            const preferShakaHlsCmaf = this.config.features.engine.preferShakaHlsCmaf;
            const preferShakaHlsTs = this.config.features.engine.preferShakaHlsTs;
            const preferShakaHls = preferShakaHlsCmaf && preferShakaHlsTs;

            let useShaka = false;
            if (contentType === ContentTypes.Dash) {
                useShaka = true;

                if (this.config.features.dush) {
                    this.useDush = true;
                }
            } else if (contentType === ContentTypes.Hls) {
                useShaka = true;

                if (this.config.features.wacka && !preferShakaHls) {
                    if (
                      (containerType === ContainerTypes.Mp4Cmaf && !preferShakaHlsCmaf) ||
                      (containerType === ContainerTypes.Mp2Ts && !preferShakaHlsTs)
                    ) {
                        this.useWacka = true;
                    }
                }
            }

            if (this.config.features.shakaWacka) {
                this.useShakaWacka = true;
            }

            if (this.useWacka || this.useDush || this.useShakaWacka || useShaka) { // To make Caf select shaka (intercepted) instead of Mpl as player
                request.media.contentType = ContentTypes.Dash; // ToDo: Always do this? Never allow MPL?
            }

            this.hasMediaLoaded = true;

            resolve(request);
        });
    }

    /** @private */
    handleSeekRequest(request) {
        if (request && request.relativeTime) {
            request.currentTime = this.currentTime + request.relativeTime;
            delete request.relativeTime;
        }

        return request;
    }

    /** @private */
    imageSubtitleDisplayer(track) {
        if (this.textContainer) {
            this.textContainer.innerHTML = '';
        }

        //@TextTrackRecoveryScript
        this.emitEngineEvent(EngineEvents.TTMLImageSubtitleUpdated)

        if (track.mode !== 'showing' || !this.textContainer) return;

        for (let c = 0; c < track.activeCues.length; c++) {
            const cue = track.activeCues[c];
            if (cue.text.indexOf('data:image') === 0) {
                const image = document.createElement('img');
                image.id = cue.startTime;
                image.src = cue.text;
                image.setAttribute('style', 'height: 100%; position: absolute; width: 100%;');
                this.textContainer.appendChild(image);
            }
        }
    }

    /** @private */
    onPlayerManagerBitrateChanged(e) {
        console.debug('Engine::Event:BitrateChanged', e);

        let bitrates = this.manifestBitrates;
        if (!bitrates || !Object.keys(bitrates).length) {
            bitrates = this.getBitratesFromPlayer();
        }

        if (bitrates && Object.keys(bitrates).length && bitrates[e.totalBitrate]) {
            this.emitEngineEvent(EngineEvents.BitrateChanged, bitrates[e.totalBitrate]);
        } else {
            console.warn('Engine::Event:BitrateChanged: Missing bitrate info, pushing fake!');
            this.emitEngineEvent(EngineEvents.BitrateChanged, {
                bitrate: e.totalBitrate,
                width: 0,
                height: 0
            });
        }
    }

    /** @private */
    onPlayerManagerBuffered(e) {
        if (this.buffering) {
            console.debug('Engine::Event:Buffered', e);
            this.buffering = false;
            this.bufferingPosition = -1;
            this.emitEngineEvent(EngineEvents.Buffered);
        }
    }

    /** @private */
    onPlayerManagerBuffering(e) {
        e = e || {};

        const buffering = Utils.booleanOrDefault(e.isBuffering, false);
        if (!this.hasLoadedStream || this.seeking || (buffering && this.buffering)) return;

        this.updateCurrentTimeAndDuration();

        const paused = this.mediaElement.paused;
        if (!buffering && this.buffering && paused) {
            this.onPlayerManagerBuffered();
        } else if (buffering && !this.buffering && !paused) {
            console.debug('Engine::Event:Buffering', e);
            this.buffering = true;
            this.bufferingPosition = this.currentMediaElementTime;
            this.emitEngineEvent(EngineEvents.Buffering);
        }
    }

    /** @private */
    onPlayerManagerEmsgCue(e) {
        console.debug('Engine::Event:EmsgCue:', e);
        try {
            const isScte35 = (e.schemeIdUri || '').toLowerCase().includes('scte35');
            const type = e.dataType === 'Id3'
              ? StreamCueTypes.Id3InEmsg
              : (isScte35 ? StreamCueTypes.Scte35 : StreamCueTypes.Emsg);

            let parsed = null;
            if (type === StreamCueTypes.Id3InEmsg) {
                try {
                    parsed = Utils.parseId3Cue(e.messageData, e.startTime);
                } catch (err) {
                    console.warn('Engine::Event:EmsgCue: Failed to parse Id3 from Emsg cue', e, err);
                }
            }

            if (!parsed) {
                try {
                    parsed = Utils.parseEmsgCue(e.messageData);
                } catch (err) {
                    console.warn('Engine::Event:EmsgCue: Failed to parse Emsg cue', e, err);
                }
            }

            this.emitEngineEvent(EngineEvents.StreamCue, {
                type,
                parsed,
                raw: e.messageData,
                endTime: e.endTime,
                eventDuration: e.eventDuration,
                id: e.id,
                presentationTimeDelta: e.presentationTimeDelta,
                schemeIdUri: e.schemeIdUri,
                startTime: e.startTime,
                timescale: e.timescale,
                value: e.value
            });
        } catch (err) {
            console.warn('Engine::Event:EmsgCue: StreamCue handler failed', err);
        }
    }

    /** @private */
    onPlayerManagerError(e) {
        e = Utils.extend({}, e, {
            fatal: true,
            origin: ErrorOrigins.Caf.PlayerManager
        });
        e.error = e.error || {};

        if (
          (e.detailedErrorCode === 905 && Object.getOwnPropertyNames(e.error).length === 0) ||
          (e.detailedErrorCode === 906 && e.error.type === 'INVALID_REQUEST') ||
          (e.error.reason === 'INVALID_MEDIA_SESSION_ID' || e.error.type === 'LOAD_CANCELLED')
        ) {
            e.fatal = false;
        }

        if (e.fatal) {
            this.resetPreviousStopInformation();
        }

        this.errorManager.dispatchError(e);
    }

    /** @private */
    onPlayerManagerId3Cue(segment) {
        console.debug('Engine::Event:Id3Cue:', segment);

        try {
            let parsed = null;
            try {
                parsed = Utils.parseId3Cue(segment.segmentData, segment.timestamp);
            } catch (err) {
                console.warn('Engine::Event:Id3Cue: Failed to parse Id3 cue', segment, err);
            }

            this.emitEngineEvent(EngineEvents.StreamCue, {
                type: StreamCueTypes.Id3,
                parsed: parsed,
                raw: segment.segmentData,
                startTime: segment.timestamp
            });
        } catch (err) {
            console.warn('Engine::Event:Id3Cue: StreamCue handler failed', err);
        }
    }

    /** @private */
    onPlayerManagerMediaFinished(e) {
        e = e || {};

        /**
         * Ensure only emitting STOPPED once, might otherwise happen twice due to mediaElement.ended listener or awaitStop::mediaFinishedResolver
         */
        if (this.hasEmittedStopped) return;
        this.hasEmittedStopped = true;

        const mapCafEndedReason = (cafEndedReason = '') => {
            switch (cafEndedReason) {
                case cast.framework.events.EndedReason.BREAK_SWITCH:
                    return StoppedReasons.AdvertisementSwitch;
                case cast.framework.events.EndedReason.END_OF_STREAM:
                    return StoppedReasons.EndOfStream;
                case cast.framework.events.EndedReason.ERROR:
                    return StoppedReasons.Error;
                case cast.framework.events.EndedReason.INTERRUPTED:
                    return StoppedReasons.Interrupted;
                case cast.framework.events.EndedReason.SKIPPED:
                    return StoppedReasons.AdvertisementSkip;
                case cast.framework.events.EndedReason.STOPPED:
                    return StoppedReasons.User;
            }
        };

        const reason = e.reason || this.stoppedReason || mapCafEndedReason(e.endedReason) || StoppedReasons.Unknown;

        delete e.endedReason;

        if (reason) {
            this.emitEngineEvent(EngineEvents.StreamFinished, Utils.extend(e, {
                currentTime: this.currentTime,
                duration: this.duration,
                reason
            }));
        }

    }

    /** @private */
    onPlayerManagerPlayerLoadComplete() {
    }

    /** @private */
    onPlayerManagerSeeked(e) {
        console.debug('Engine::Event:Seeked', e);
        this.seeking = false;
        this.emitEngineEvent(EngineEvents.Seeked);
    }

    /** @private */
    onPlayerManagerSeeking(e) {
        if (this.buffering) {
            this.onPlayerManagerBuffered();
        }

        if (!this.hasLoadedStream || this.seeking) {
            return;
        }

        console.debug('Engine::Event:Seeking', e);
        this.seeking = true;
        this.emitEngineEvent(EngineEvents.Seeking);
    }

    /** @private */
    onMediaElementEnded() {
        /**
         * Unregistering media element listeners on ended, otherwise CAF's internal player destroy() which resets the
         * media element will lead to a timeupdate event after ended has happened which will lead to incorrect trackingManager
         */
        this.unregisterMediaElementEventListeners();

        this.onPlayerManagerMediaFinished({
            reason: StoppedReasons.EndOfStream
        });
    }

    /** @private */
    registerDrmHandler() {
        this.playerManager.setMediaPlaybackInfoHandler((request, playbackConfig) => {
            if (this.license && this.license.server) {
                playbackConfig.licenseUrl = this.license.server;
                playbackConfig.licenseRequestHandler = licenseRequest => {
                    if (Object.keys(this.license.headers).length > 0) {
                        for (let h in this.license.headers) {
                            if (!this.license.headers.hasOwnProperty(h)) continue;
                            licenseRequest.headers[h] = this.license.headers[h];
                        }
                    }
                };
                playbackConfig.protectionSystem = cast.framework.ContentProtection.WIDEVINE;
                if (this.license.protectionSystem === ProtectionSystemTypes.PlayReady) {
                    playbackConfig.protectionSystem = cast.framework.ContentProtection.PLAYREADY;
                }
            }

            return playbackConfig;
        });
    }

    /** @private */
    registerShakaTtmlHandler(shakaRoot) {
        // Using ES 5 function for registration of parser for compatibility with shaka 2.x and 3.x
        const preserveBrBreaksInTtmlTextContent = this.config.features.preserveBrBreaksInTtmlTextContent;
        shakaRoot.text.TextEngine.registerParser(
          'application/mp4; codecs="stpp"',
          function () {
              return new ShakaMp4TtmlParser(preserveBrBreaksInTtmlTextContent);
          }
        );

        if (!this.mediaElement.textTracks.length) {
            const trackAddedHandler = e => {
                const cueChangeListener = this.imageSubtitleDisplayer.bind(this, e.track);
                e.track.addEventListener('cuechange', cueChangeListener);
                this.textTrackEventListeners['cuechange'] = {
                    listener: cueChangeListener,
                    track: e.track
                };
                this.mediaElement.textTracks.removeEventListener('addtrack', trackAddedHandler);
            };
            this.mediaElement.textTracks.addEventListener('addtrack', trackAddedHandler);
        } else {
            const cueChangeListener = this.imageSubtitleDisplayer.bind(this, this.mediaElement.textTracks[0]);
            this.mediaElement.textTracks[0].addEventListener('cuechange', cueChangeListener);
            this.textTrackEventListeners['cuechange'] = {
                listener: cueChangeListener,
                track: this.mediaElement.textTracks[0]
            };
        }
    }

    /**
     * Handles technical stream limitations
     * @private
     */
    setStreamLimitation(command, limited) {
        this.streamLimitations = this.streamLimitations || {};
        this.streamLimitations[command] = limited;

        this.applyStreamCapabilities();
    }

    /** @private */
    updateCurrentTimeAndDuration() {
        this.currentTime = this.currentTime || 0;
        this.currentMediaElementTime = this.mediaElement.currentTime;

        if (this.playerManager) {
            this.currentTime = this.playerManager.getCurrentTimeSec();
            let duration = Utils.numberOrDefault(this.playerManager.getDurationSec(), -1);

            const seekableRange = this.playerManager.getLiveSeekableRange();
            if (seekableRange && Utils.isNumber(seekableRange.end)) {
                if (this.useSeekableRangeEndAsDuration) {
                    duration = seekableRange.end;
                }

                /**
                 * Only checking business rules and not technical limitations, otherwise the limitation could not be
                 * 'unlocked' again if the seekable range expands enough to allow seeking after a while
                 */

                if (this.player) {
                    const playerSeekRange = this.player.seekRange();
                    if (this.commandAllowed('seek') && playerSeekRange && Utils.isNumber(playerSeekRange.end) && Utils.isNumber(playerSeekRange.start)) {
                        const seekingLimited = (playerSeekRange.end - playerSeekRange.start < this.minimumRangeForSeeking);
                        this.setStreamLimitation('seek', seekingLimited);
                        if (!seekingLimited) {
                            if (!this.seekableRange || Math.abs(this.seekableRange.end - seekableRange.end) > this.seekableRangeUpdateTolerance) {
                                this.seekableRange = seekableRange;
                                this.emitEngineEvent(EngineEvents.SeekableRangeUpdated, {
                                    duration: duration,
                                    seekableRange: seekableRange,
                                    currentTime: this.currentTime
                                });
                            }
                        }
                    }
                }

            }

            this.duration = duration;
        }

        if ( // If currentTime passes duration, set it to duration
          Utils.isNumber(this.currentTime) &&
          Utils.isNumber(this.duration) &&
          this.duration > -1 &&
          this.duration < this.currentTime
        ) {
            this.currentTime = this.duration;
        }
    }

    assessAc3Support() {
        let shakaVersion = Utils.getDeviceInfo().shakaVersion || ''
        if (this.mediaSourceIsTypeSupported('audio/mp4; codecs="ac-3"')) {
            if (shakaVersion.includes('3.1.')) {
                this.defaultPlayerConfiguration.shaka.preferredAudioChannelCount = 6;
            } else {
                this.defaultPlayerConfiguration.shaka.preferredAudioCodecs = ['ac-3']
                if (this.mediaSourceIsTypeSupported('audio/mp4; codecs="ec-3"')) {
                    this.defaultPlayerConfiguration.shaka.preferredAudioCodecs.unshift('ec-3');
                }
            }
        }
    }

    /**
     * Called from Mediator
     * @private
     */
    getPlayerFactory(shakaConstructor) {
        this.assessAc3Support();
        return {
            create: (a, b, c) => {
                if (this.mediaSourceIsTypeSupported) { // Restore original MediaSource.isTypeSupported which was overridden by shaka polyfill
                    MediaSource.isTypeSupported = this.mediaSourceIsTypeSupported;
                }

                const initConfig = {};
                if (this.preferredTracks) {
                    Object.assign(initConfig, {
                        preferredAudioLanguage: this.preferredTracks.audioTrackLanguage,
                        preferredTextLanguage: this.preferredTracks.textTrackLanguage
                    });
                }

                let getNewLicense = () => Promise.resolve();
                if (this.license && this.license.getNewDrmInfo) {
                    getNewLicense = () => this.license.getNewDrmInfo()
                      .then(license => this.license && license && Utils.extend(this.license, license));
                }

                if (this.useWacka && Wacka.isSupported()) {
                    this.playerName = 'Wacka';
                    this.playerType = 'wacks'
                    this.player = new Wacka({
                        getNewLicense,
                        license: this.license,
                        mediaElement: this.mediaElement,
                        textContainer: this.textContainer
                    });
                } else if (this.useDush && Dush.isSupported()) {
                    this.playerName = 'Dush';
                    this.playerType = 'dush'
                    this.player = new Dush({
                        getNewLicense,
                        license: this.license,
                        mediaElement: this.mediaElement,
                        textContainer: this.textContainer
                    });
                } else {
                    let playerNamePostFix = '';
                    this.playerType = 'shaka'
                    let shakaRoot = shaka;
                    if ('shakalaka' in window) {
                        playerNamePostFix = 'Laka';
                        shakaConstructor = shakalaka.Player;
                        shakaRoot = shakalaka;
                        shakalaka.log.setLevel(shakalaka.log.Level.DEBUG);
                    }

                    if (this.useShakaWacka && ShakaWacka.isSupported()) {
                        this.playerName = 'ShakaWacka' + playerNamePostFix;
                        this.player = new ShakaWacka({
                            getNewLicense,
                            license: this.license,
                            mediaElement: this.mediaElement,
                            shakaConstructor,
                            shakaRoot,
                            textContainer: this.textContainer
                        });

                        if (this.config.debug === true) {
                            if (shakaRoot && shakaRoot.log) {
                                let shakaLogLevel = 4;//debug
                                try {
                                    let logLevel = parseInt(this.config.features.engine.shakaLogLevel);
                                    if (Utils.isNumber(logLevel) && logLevel >= 0 && logLevel <= 6) {
                                        shakaLogLevel = logLevel;
                                    } else {
                                        console.warn(`Warning class:Engine[create] : ConfigError: value provide from config -> receiver.features.engine.shakaLogLevel, is out of range or not a number!`);
                                    }
                                    console.log('Setting shakaLogLevel:', shakaLogLevel)
                                } catch (e) {
                                    console.error(e);

                                }
                                ;

                                shakaRoot.log.setLevel(shakaLogLevel)
                            }
                        }
                    } else {
                        this.playerName = 'Shaka' + playerNamePostFix;
                        this.player = new shakaConstructor(a, b, c);
                    }

                    if (this.useTtmlHandler) {
                        this.registerShakaTtmlHandler(shakaRoot);
                    }

                    const networkFilters = Array.prototype.slice.call(this.networkFilters);
                    if (this.useDashStaticTransitionHandler) {
                        networkFilters.push({
                            type: NetworkFilterTypes.Response,
                            filter: new ShakaDashStaticTransitionHandler().responseFilter()
                        });
                    }

                    if (isHlsCmafStream(this.stream)) {
                        if (
                          isLiveStream(this.stream) &&
                          this.liveHlsCmafPlayerConfigurationOverrides.shaka &&
                          Object.keys(this.liveHlsCmafPlayerConfigurationOverrides.shaka).length
                        ) {
                            this.player.configure(this.liveHlsCmafPlayerConfigurationOverrides.shaka);
                        }

                        networkFilters.push({
                            type: NetworkFilterTypes.Response,
                            filter: new ShakaEmsgFilter(
                              e => this.onPlayerManagerEmsgCue({
                                  ...e,
                                  dataType: 'Id3',
                                  endTime: e.endTime / e.timescale,
                                  eventDuration: e.eventDuration / e.timescale,
                                  presentationTime: e.presentationTime ? e.presentationTime / e.timescale : undefined,
                                  presentationTimeDelta: e.presentationTimeDelta ? e.presentationTimeDelta / e.timescale : undefined,
                                  startTime: e.startTime / e.timescale,
                                  timescale: 1
                              }),
                              this.mediaElement
                            ).responseFilter
                        });
                    }
                    for (let i in networkFilters) {
                        if (!networkFilters.hasOwnProperty(i)) continue;

                        switch (networkFilters[i].type) {
                            case NetworkFilterTypes.Request:
                                this.player.getNetworkingEngine().registerRequestFilter(networkFilters[i].filter);
                                break;
                            case NetworkFilterTypes.Response:
                                this.player.getNetworkingEngine().registerResponseFilter(networkFilters[i].filter);
                                break;
                        }
                    }

                    const _self = this;
                    TextTrack.prototype.addCue = function (cue) {
                        if (!cue.text) return;

                        // Hack: Fix for duplicated cues (fixed in shaka-player@2.5.11), https://github.com/google/shaka-player/issues/2497
                        var cues = Array.prototype.slice.call(this.cues);
                        if (cues.find(c => c.startTime === cue.startTime && c.endTime === cue.endTime)) return;

                        // Hack: Fix for broken TTML style parsing in shaka-player@2.5.7 causing cues to be start(left)-aligned
                        cue.align = 'center';

                        _self.textTrackAddCue.call(this, cue);
                    };
                }

                this.player.configure(initConfig);

                this.applyPlayerConfiguration();

                this.registerAdditionalPlayerEventListeners();

                if (this.player.restrictionHelper && this.player.restrictionHelper instanceof RestrictionHelper) {
                    this.restrictionHelper = this.player.restrictionHelper;
                }

                return this.player;
            }
        };
    }

    /**
     * Called from Mediator
     * @private
     */
    onSegment(segment) {
    }

    /**
     * Called from Mediator
     * @private
     */
    onManifest(manifest) {
        if (!this.manifestBitrates) {
            this.manifestBitrates = Utils.parseM3u8Bitrates(manifest);
        }
    }

    /**
     * Called from Mediator
     * @private
     */
    onSystemVolumeChanged(level, muted) {
        this.emitEngineEvent(EngineEvents.SystemVolumeChanged, {
            volume: level,
            muted: muted
        });
    }

    /**
     * This function does not actually stop anything, just provides an awaiter for when it is stopped.
     * If you need to actively stop the current media then you should call engine.stop() to perform a stop.
     *
     * @returns {Promise<void>}
     */
    awaitStop(dueToNewContentLoading = false) {
        if (this.playerManager && this.hasMediaLoaded) {
            this.unregisterMediaElementEventListeners();

            return new Promise(resolve => {
                const PlayerManagerEvents = cast.framework.events.EventType;

                let resolverTimeout;
                const mediaFinishedResolver = (timeout) => {
                    if (resolverTimeout) {
                        resolverTimeout = clearTimeout(resolverTimeout);
                    }

                    this.playerManager.removeEventListener(PlayerManagerEvents.MEDIA_FINISHED, mediaFinishedResolver);

                    const stoppedReason = dueToNewContentLoading ? StoppedReasons.Interrupted : (this.stoppedReason || StoppedReasons.User);

                    if (Utils.isBoolean(timeout) && timeout) {
                        this.onPlayerManagerMediaFinished({
                            reason: stoppedReason
                        });
                    }

                    resolve();
                };

                this.playerManager.addEventListener(PlayerManagerEvents.MEDIA_FINISHED, mediaFinishedResolver);
                resolverTimeout = setTimeout(mediaFinishedResolver.bind(this, true), 3000);
            });
        }

        return Promise.resolve();
    }

    can(command) {
        let can = true;
        switch (command) {
            case 'pause':
                can = this.playerManager.getCurrentSupportedMediaCommands() & cast.framework.messages.Command.PAUSE;
                break;
            case 'seek':
                can = this.playerManager.getCurrentSupportedMediaCommands() & cast.framework.messages.Command.SEEK;
                break;
        }

        return !!can;
    }

    enableDashStaticTransitionHandler(enable = true) {
        this.useDashStaticTransitionHandler = Utils.booleanOrDefault(enable, this.useDashStaticTransitionHandler);
    }

    enableSeekableRangeEndAsDuration(enable = true) {
        this.useSeekableRangeEndAsDuration = Utils.booleanOrDefault(enable, this.useSeekableRangeEndAsDuration);
    }

    enableTtmlHandler(enable = true) {
        this.useTtmlHandler = Utils.booleanOrDefault(enable, this.useTtmlHandler);
    }

    getDroppedFrames() {
        let droppedFrames = 0;
        if (this.mediaElement.getVideoPlaybackQuality) {
            const quality = this.mediaElement.getVideoPlaybackQuality();
            droppedFrames = quality.droppedVideoFrames;
        } else if (Utils.isNumber(this.mediaElement.webkitDroppedFrameCount)) {
            droppedFrames = this.mediaElement.webkitDroppedFrameCount;
        }

        return droppedFrames;
    }

    getSeekableRange() {
        if (!this.player || !this.player.seekRange) return null;

        return this.player.seekRange();
    }

    getStreamRestrictions() {
        return new StreamRestrictions({
            pause: !this.can('pause'),
            seek: !this.can('seek')
        });
    }

    getVariantTracks() {
        if (this.player && this.player.getVariantTracks) {
            return this.player.getVariantTracks() || [];
        }

        return [];
    }

    pause() {
        this.playerManager && this.playerManager.pause();
    }

    play() {
        this.playerManager && this.playerManager.play();
    }

    /**
     * @param {string} type
     * @param {function(any, object): void} filter
     */
    registerNetworkFilters(type, filter) {
        if (this.player) {
            return console.warn('Warning registerNetworkFilters : It\'s not possible to set network filters after a player is instantiated');
        }

        if ([NetworkFilterTypes.Request, NetworkFilterTypes.Response].includes(type)) {
            this.networkFilters.push({ type, filter });
        } else {
            console.error(`Error: registerNetworkFilters, first argument '${type}' provided is invalid must be either 'request' or 'response'`);
        }
    }

    registerPlaybackFeature(name, playbackFeature) {
        if (!Utils.isString(name)) {
            return console.warn('Engine::registerPlaybackFeature: Invalid playback feature name:', name);
        }

        const lowerCaseName = name.toLowerCase();
        if (!['yospace'].includes(lowerCaseName)) {
            return console.warn('Engine::registerPlaybackFeature: Unsupported playback feature:', name, playbackFeature);
        }

        playbackFeature.setEngine(this);

        if (Utils.isFunction(playbackFeature.setEngineEventHandler)) {
            playbackFeature.setEngineEventHandler(this.emitEngineEventFromPlaybackFeature.bind(this, lowerCaseName));
        }

        this.playbackFeatures[lowerCaseName] = playbackFeature;
    }

    registerPlayerConfiguration(playerConfiguration) {
        if (playerConfiguration) {
            this.playerConfiguration = playerConfiguration;
        }
    }

    /**
     * @param {number} position
     */
    seek(position) {
        this.playerManager && this.playerManager.seek(position);
    }

    setLicense(license) {
        if (license && license.server) {
            this.license = license;
        }
    }

    /**
     * Handles non-technical restrictions based on business rules
     * @param streamRestrictions
     */
    setStreamRestrictions(streamRestrictions) {
        if (streamRestrictions) {
            this.streamRestrictions = streamRestrictions;

            this.applyStreamCapabilities();
        }
    }

    /**
     * @param {Array<Stream>} streams
     * @returns {Stream} The selected stream
     */
    setStreams(streams = []) {
        if (!streams || !streams.length) return;

        this.streams = streams;
        this.stream = this.streams[0]; // Not handling fallback streams, only using first one

        if (this.stream.streamRestrictions) {
            this.setStreamRestrictions(this.stream.streamRestrictions);
        }

        // This needs to be deferred so that Caf has time to apply the restrictions (which happens asynchronously)
        Utils.defer(() => this.emitEngineEvent(EngineEvents.StreamChanged, {
            stream: this.stream
        }));

        if (this.stream.drms && this.stream.drms.length) {
            const drm = this.stream.drms[0]; // Not handling multiple drms, only using first one
            this.setLicense(drm);

            Utils.defer(() => this.emitEngineEvent(EngineEvents.DrmChanged, { drm }));
        }

        return this.stream;
    }

    setPlayerConfigurationProfile(playerConfigurationProfile) {
        if (playerConfigurationProfile) {
            this.playerConfigurationProfile = playerConfigurationProfile;
        }
    }

    stop(reason = StoppedReasons.Unknown) {
        // Allow internal override of stoppedHandler depending on how/when the media is stopped
        const stoppedHandlerOverride = arguments[1];

        // Not overriding stoppedReason in case stop() is called from multiple places, then we keep the first reason
        if (this.stoppedReason === null) {
            this.stoppedReason = reason;
        }

        return new Promise(resolve => {
            // This requires an active call to playerManager.stop() since Caf is not in the process of stopping the ongoing media
            this
              .awaitStop()
              .then(stoppedHandlerOverride || this.stoppedHandler)
              .then(resolve);

            if (this.playerManager && this.hasMediaLoaded) {
                this.playerManager.stop();
            }
        });
    }

    /**
     * @private
     */
    resetEventListeners() {
        this.unregisterEventListeners();
        this.mediaElementEventListeners = {};
        this.textTrackEventListeners = {};
        this.playerManagerEventListeners = {};
        this.playerEventListeners = {};
        this.networkInterceptorEventListeners = {};
    }

    /**
     * Clearing any previous stop information in loadstart handler & error handler instead of in reset() because Caf
     * might fire the MEDIA_FINISHED event out-of-turn - firing the MEDIA_FINISHED for the previous media well after
     * we have already started loading the new media (especially prevalent in the case of switching to the next/previous
     * item in the queue, mid playback). The MEDIA_FINISHED event should, however, not come after Caf has created a
     * new stream has started loading/failed.
     * @private
     */
    resetPreviousStopInformation() {
        this.hasEmittedStopped = false;
        this.stoppedReason = null;
    }

    reset() {
        this.advertisementBreakCurrentTime = 0;
        this.buffering = false;
        this.bufferingPosition = -1;
        this.cdn = null;
        this.currentTime = 0;
        this.currentMediaElementTime = 0;
        this.droppedFrames = 0;
        this.duration = -1;
        this.hasLoadedStream = false;
        this.hasMediaLoaded = false;
        this.lastAdvertisementTimeUpdate = null;
        this.license = null;
        this.manifestBitrates = null;
        this.seekableRange = null;
        this.streamLimitations = null;
        this.streamRestrictions = null;
        this.preferredTracks = null;
        this.player = null;
        this.playerConfigurationProfile = null;
        this.playerName = null;
        this.playerType = null;
        this.seeking = false;
        this.stream = null;
        this.streams = null;
        this.useDashStaticTransitionHandler = false;
        this.useDush = false;
        this.useSeekableRangeEndAsDuration = true;
        this.useShakaWacka = false;
        this.useTtmlHandler = false;
        this.useWacka = false;

        Object.values(this.playbackFeatures).forEach(p => p.reset());

        if (this.textContainer) {
            this.textContainer.innerHTML = '';
        }

        if (this.networkInterceptor) {
            this.networkInterceptor.reset();
        }

        TextTrack.prototype.addCue = this.textTrackAddCue;

        // Restore capabilities
        this.applyStreamCapabilities();
    }

    destroy() {
        this.destroyed = true;

        this.stop(StoppedReasons.Unknown, () => {
            this.reset();
            this.resetEventListeners();

            Object.values(this.playbackFeatures).forEach(p => p.destroy());

            this.errorManager = null;
            this.playbackFeatures = null;
            this.config = { features: {} };
            this.mediaElement = null;
            this.networkFilters = [];
            this.networkInterceptor = null;
            this.playerManager = null;
            this.textContainer = null;

            this.mediaSourceIsTypeSupported = null;
            this.textTrackAddCue = null;

            return Promise.resolve();
        });
    }
};