const Mediator = require('./cast/Mediator');
const Engine = require('./player/Engine');
const Console = require('./utils/Console');
const ConsoleTracker = require('./tracking/trackers/ConsoleTracker');
const ComscoreEventParser = require('./utils/ComscoreEventParser');
const DebugOverlay = require('./ui/overlays/DebugOverlay');
const I18n = require('./i18n/I18n');
const ImageInterceptor = require('./utils/network/ImageInterceptor');
const LegacyLoadRequestMapper = require('./mappers/LegacyLoadRequestMapper');
const NetworkInterceptor = require('./utils/network/NetworkInterceptor');
const NotificationsOverlay = require('./ui/overlays/NotificationsOverlay');
const RequestMapper = require('./mappers/RequestMapper');
const ReceiverInterface = require('./service/ReceiverInterface');
const ServiceLayer = require('./service/ServiceLayer');
const Utils = require('./utils/Utils');
const ValidationEventDispatcher = require('./utils/ValidationEventDispatcher');

const {
    ErrorCategories,
    ErrorCodes,
    ErrorOrigins,
    PlaybackStates,
    ReceiverStates,
    SenderTypes,
    ShutdownReasons,
    StoppedReasons
} = require('./Constants');

const {
    ReceiverConfig,
    ReceiverInformation,
    Sender,
    StreamRestrictions
} = require('./dtos/');

const {
    CommunicationManagerEvents,
    ContentManagerEvents,
    EngineEvents,
    MediatorEvents,
    NotificationEvents,
    ReceiverEvents,
    TimelineManagerEvents,
    TrackManagerEvents
} = require('./Events');

const {
    CommunicationManager,
    ContentManager,
    ErrorManager,
    SessionManager,
    PlaybackManager,
    QueueManager,
    TimelineManager,
    TrackingManager,
    TracksManager,
    UiManager
} = require('./managers/');

const {
    mapLoadError,
    mapReceiverStartError
} = require('./mappers/ErrorMapper');

module.exports = new class Receiver {
    constructor() {
        this.busy = null;
        this.communicationManager = null;
        this.contentManager = null;
        this.debugOverlay = null;
        this.engine = null;
        this.errorManager = null;
        this.load = null;
        this.loadCounter = 0;
        this.loadingByEntity = false;
        this.mediator = null;
        this.notificationsOverlay = null;
        this.playbackManager = null;
        this.preload = null;
        this.queueManager = null;
        this.receiverInformation = null;
        this.receiverInterface = null;
        this.senders = [];
        this.serviceLayer = null;
        this.sessionManager = null;
        this.timelineManager = null;
        this.trackingManager = null;
        this.tracksManager = null;
        this.uiManager = null;
        this.validationEventDispatcher = null;

        /** @type {ReceiverConfig} */
        this.config = Utils.extend({}, new ReceiverConfig());
        this.utils = Utils;
        this.version = __VERSION__; // Will be injected at build time

        window.receiver = this; // Do not remove, used for loadingByEntity flag
    }

    start(ServiceLayerClass = ServiceLayer) {
        if (!this.preload) {
            this.initialize();
        }

        const applyServiceLayerConfigurationOverrides = (receiverConfigurationOverrides = {}) => {
            this.config.receiver = Utils.extend({}, this.config.receiver, receiverConfigurationOverrides);
        };

        const awaitShakaIntercept = () => this.mediator.awaitShakaIntercept();

        const configureDebugOverlay = () => {
            const applicationInformation = this.receiverInterface.applicationInfo;
            if (
              applicationInformation &&
              applicationInformation.applicationVersion &&
              this.debugOverlay
            ) {
                this.debugOverlay.setApplicationData({
                    application: applicationInformation,
                    receiver: {
                        receiverVersion: this.version
                    }
                });
            }
        };

        const domReady = () => new Promise(resolve => Utils.domReady(resolve));

        const fireReceiverStateChanged = (state) => {
            const fireReceiverApplicationStateChanged = state => { // Deprecated, only kept for backwards compatibility
                this.communicationManager.sendUnifiedMessage({
                    type: `Event.ReceiverApplicationStateChanged`,
                    data: { state: state.replace('ReceiverStates.', 'ReceiverApplicationStates.') }
                });
            };

            this.communicationManager.sendUnifiedMessage({
                type: `Event.ReceiverStateChanged`,
                data: { state }
            });

            fireReceiverApplicationStateChanged(state);
        };

        const getSender = id => {
            const lastConnectedSender = this.senders[this.senders.length - 1];

            if (!id) return lastConnectedSender;

            return this.senders.find(s => s.id === id) || lastConnectedSender;
        };

        const handleReceiverStartError = e => {
            this.errorManager.dispatchError(mapReceiverStartError(e));
        };

        const initializeServiceLayer = () => this.serviceLayer.initialize(); // ServiceLayer.initialize() can optionally return some configuration to override receiver defaults

        const interceptConsole = () => {
            Console
              .configure({
                  blockConsole: !this.config.receiver.debug,
                  debugConsole: this.config.receiver.features.debugConsole,
                  send: this.communicationManager.sendUnifiedMessage.bind(this.communicationManager)
              })
              .intercept();
        };

        const reset = () => {
            const resetPromises = [];

            this.communicationManager.reset();
            this.contentManager.reset();
            this.errorManager.reset();
            this.playbackManager.reset();
            this.queueManager.reset();
            this.sessionManager.reset();
            this.timelineManager.reset();
            this.trackingManager.reset();
            this.tracksManager.reset();
            this.uiManager.reset();
            this.engine.reset();

            resetPromises.push(this.serviceLayer.handleReset());

            this.receiverInformation.playbackSession = {
                playbackSessionId: Utils.guid()
            };

            return Utils.allSettled(resetPromises);
        };

        const setupDebugTracking = () => {
            const trackingReporter = (trackingType, url) => {
                this.communicationManager.sendUnifiedMessage({
                    type: 'Debug.Tracking',
                    dataType: trackingType,
                    data: { url }
                });

                return url;
            };

            ImageInterceptor
              .getInstance()
              .registerInterceptor(/se-tv4\.videoplaza\.tv/, {
                  requestHandler: url => trackingReporter('invidi', url)
              })
              .registerInterceptor(/scorecardresearch\.com/, {
                  requestHandler: url => {
                      console.log(`--| ComscoreEvent:`, ComscoreEventParser.Parse(url));
                      return trackingReporter('comscore', url);
                  }
              });
        };

        const setupIntegration = () => {
            const receiverConfig = this.config.receiver;
            const requiresUpdate = Utils.requiresUpdate(receiverConfig.minimumFirmwareVersion);
            let content = null;
            let hasStartedPlaying = false;

            const createEngineEventHandler = type => {
                return (e = {}) => {
                    this.communicationManager.sendUnifiedMessage({
                        type: `Event.${type}`,
                        data: e
                    });
                };
            };

            const createStreamUpdatingEngineEventHandler = type => {
                const engineEventHandler = createEngineEventHandler(type);

                return (e = {}) => Utils.defer(() => {
                    fireStreamUpdated();

                    engineEventHandler(e);
                });
            };

            const createReceiverStateChangedHandler = state => {
                return () => fireReceiverStateChanged(state);
            };

            const createTrackChangedHandler = type => {
                return e => {
                    if (e && e.language) {
                        this.communicationManager.sendUnifiedMessage({
                            type: `Event.${type}TrackChanged`,
                            data: e.language
                        });
                    }
                };
            };

            const filterMediaStatusCustomData = mediaStatusCustomData => {
                const mapped = Utils.extend({}, mediaStatusCustomData);
                delete mapped.advertisement;
                delete mapped.credentials;
                delete mapped.tracking;
                return mapped;
            };

            const fireContentChanged = () => {
                this.communicationManager.sendUnifiedMessage({
                    type: 'Event.ContentChanged',
                    data: mapContent(content)
                });
            };

            const fireContentStopped = reason => {
                this.communicationManager.sendUnifiedMessage({
                    type: 'Event.ContentStopped',
                    data: reason
                });
            };

            const fireContentUpdated = () => {
                const sender = getSender() || { receiver: {} };
                if (
                  !sender.receiver.receiverVersion ||
                  Utils.compareVersions(sender.receiver.receiverVersion, '3.0.0') >= 0
                ) {
                    /**
                     * @deprecated 3.0.0
                     */
                    this.communicationManager.sendUnifiedMessage({
                        type: 'Event.ContentUpdated',
                        data: mapContent(content) || {}
                    });
                } else {
                    this.communicationManager.sendUnifiedMessage({
                        type: 'Event.ContentUpdated',
                        data: mapContent(content)
                    });
                }
            };

            const fireReceiverStateLoadComplete = createReceiverStateChangedHandler(ReceiverStates.LoadComplete);

            const fireSessionState = () => {
                if (this.engine.playerManager) {
                    this.engine.playerManager.broadcastStatus();
                }

                if (content && this.engine.stream) {
                    this.communicationManager.sendUnifiedMessage({
                        type: 'Event.SessionStateUpdated',
                        data: {
                            content: mapContent(content),
                            playbackSessionState: {
                                currentTime: this.engine.currentTime,
                                duration: this.engine.duration,
                                stream: mapStream(this.engine.stream)
                            }
                        }
                    });
                }

                (() => {
                    /**
                     * Deprecated
                     *  if
                     *   1. Web Sender >= 3.2.0 or Android Sender >= 0.11.0 or iOS Sender >= 0.4.0 then no need to fire these
                     *  or
                     *   2. all connected senders have receiver.receiverVersion >= 2.21.0
                     *
                     *  then no need to fire these
                     *
                     *  alt - fire these specifically for the ones that have versions lower than this instead of broadcasting
                     */
                      //if (this.senders.every(s => isNewEnough(s))) return; // ToDo:

                    const sender = getSender();
                    if (sender && sender.receiver && sender.receiver.receiverVersion) {
                        if (Utils.compareVersions(sender.receiver.receiverVersion, '3.0.0') >= 0) return; // Sender supports SessionStateUpdated - no need to fire the deprecated events
                    }

                    fireContentUpdated();
                    if (this.timelineManager.seekableRangeInfo && this.timelineManager.seekableRangeInfo.seekableRange) {
                        this.communicationManager.sendUnifiedMessage({
                            type: 'Event.SeekableRangeUpdated',
                            data: this.timelineManager.seekableRangeInfo
                        });
                    }
                })();
            };

            const fireStreamChanged = () => {
                this.communicationManager.sendUnifiedMessage({
                    type: 'Event.StreamChanged',
                    data: mapStream(this.engine.stream)
                });
            };

            const fireStreamUpdated = () => {
                this.communicationManager.sendUnifiedMessage({
                    type: 'Event.StreamUpdated',
                    data: mapStream(this.engine.stream)
                });
            };

            const mapContent = content => {
                if (!content) return content;

                // Add default streamRestrictions to content
                const mapped = Utils.extend({
                    streamRestrictions: new StreamRestrictions()
                }, content);

                delete mapped.nextContent;
                delete mapped.streams;

                return mapped;
            };

            const mapStream = stream => {
                const seekableRangeInfo = this.timelineManager.seekableRangeInfo;
                const seekableRange = seekableRangeInfo && seekableRangeInfo.seekableRange;
                const seekableRangeRestricted = !!(seekableRangeInfo && seekableRangeInfo.seekableRangeRestricted);
                const epochInformation = seekableRange && seekableRangeInfo && seekableRangeInfo.epochInformation;

                const mapped = Utils.extend({}, stream, {
                    epochInformation,
                    seekableRange,
                    seekableRangeRestricted,
                    streamRestrictions: this.engine.getStreamRestrictions()
                });

                if (mapped.epochInformation && Object.values(mapped.epochInformation).length == 0) {
                    mapped.epochInformation = null;
                }

                delete mapped.drms;
                delete mapped.playbackSessionUrl;
                delete mapped.url;

                return mapped;
            };

            this.communicationManager.on(CommunicationManagerEvents.UnifiedMessage, message => {
                if (message.type) {
                    let enable = false;
                    switch (message.type) {
                        case 'Command.Eval':
                            let data = null;
                            try {
                                data = eval(message.code);
                            } catch (e) {
                                data = e.message;
                            }

                            if (data) {
                                try {
                                    const sizeTest = JSON.stringify(data);
                                    if (sizeTest.length > 60000) {
                                        data = 'Evaluated value is too large to send!';
                                    }
                                } catch (e) {
                                    data = e.message;
                                }
                            }

                            return this.communicationManager.sendUnifiedMessage({
                                type: 'Response.Eval',
                                commandId: message.commandId,
                                data
                            });
                        case 'Command.Notification':
                            if (message.notification && this.notificationsOverlay) {
                                this.notificationsOverlay.showNotification(message.notification, message.duration);
                            }

                            return;
                        case 'Command.Reload':
                            this.serviceLayer.handleShutdown({ reason: ShutdownReasons.UnifiedCommand });

                            return Utils.defer(() => window.location.reload(true), message.reloadDelay);
                        case 'Command.UpdatePlayerConfig':
                            if (message) {
                                if (this.engine.player) {
                                    if (message[this.engine.playerType]) {
                                        this.engine.player.configure(message[this.engine.playerType])
                                        console.log(`Command.UpdatePlayerConfig: Updating config for '${this.engine.player} \n'`, this.engine.player.getConfiguration())
                                    } else {
                                        console.log(`Command.UpdatePlayerConfig: You are trying to configure '${this.engine.playerType}'`)
                                    }
                                } else {
                                    console.error('Command.UpdatePlayerConfig: no active player, please start a playback session before using Command.UpdatePlayerConfig!')
                                }
                            }

                            return;
                        case 'Debug.Console':
                            let consoleConfiguration = receiverConfig.features.debugConsole;

                            if (typeof (message.debugConsole) === 'object' && message.debugConsole) {
                                consoleConfiguration = Utils.extend(receiverConfig.features.debugConsole, {
                                    debug: Utils.booleanOrDefault(message.debugConsole.debug, receiverConfig.features.debugConsole.debug),
                                    log: Utils.booleanOrDefault(message.debugConsole.log, receiverConfig.features.debugConsole.log),
                                    info: Utils.booleanOrDefault(message.debugConsole.info, receiverConfig.features.debugConsole.info),
                                    warn: Utils.booleanOrDefault(message.debugConsole.warn, receiverConfig.features.debugConsole.warn),
                                    error: Utils.booleanOrDefault(message.debugConsole.error, receiverConfig.features.debugConsole.error)
                                });
                            } else if (Utils.isString(message.level)) {
                                consoleConfiguration = {
                                    debug: false,
                                    log: false,
                                    info: false,
                                    warn: false,
                                    error: false
                                };
                                const enable = level => consoleConfiguration[level] = true;
                                switch (message.level) {
                                    case 'Debug':
                                        ['debug', 'log', 'info', 'warn', 'error'].forEach(enable);
                                        break;
                                    case 'Warning':
                                        ['warn', 'error'].forEach(enable);
                                        break;
                                    case 'Error':
                                        ['error'].forEach(enable);
                                        break;
                                    case 'Off':
                                        break;
                                }
                            }

                            return Console.configure({
                                debugConsole: consoleConfiguration
                            });
                        case 'Debug.Overlay':
                            enable = !!(message && message.enable);
                            return this.debugOverlay && this.debugOverlay[enable ? 'show' : 'hide']();
                        case 'Debug.SimulateError':
                            let errorType = (message && message.errorType && ['http', 'unknown', 'video'].includes(message.errorType)) ? message.errorType : 'unknown';
                            switch (errorType) {
                                case 'http': {
                                    this.engine.player.simulateHttpError()
                                    break;
                                }
                                case 'unknown': {
                                    this.engine.player.simulateUnknownError()
                                    break;
                                }
                                case 'video': {
                                    this.engine.player.simulateVideoError()
                                    break;
                                }
                            }

                            break;
                        case 'Debug.Tracking':
                            enable = !!(message.data && message.data.enable);
                            if (!enable) {
                                ImageInterceptor
                                  .getInstance()
                                  .reset();
                            } else if (!this.config.features.debugTracking) {
                                this.config.features.debugTracking = true;
                                setupDebugTracking();
                            }
                            break;
                        case 'Session.Connect':
                            if (message.sender) {
                                const sender = getSender(message.sender.id);
                                if (sender) {
                                    console.debug(`Receiver::UnifiedMessage::Session.Connect: senderId: ${sender.id}, message:`, message);

                                    if (sender.connectTimeout) {
                                        sender.connectTimeout = clearTimeout(sender.connectTimeout);
                                    }

                                    Utils.extend(sender, message.sender);
                                }
                            }

                            fireSessionState();

                            return;
                        case 'Session.RequestState':
                        case 'Request.SessionState': // Deprecated, use Session.RequestState instead
                            return fireSessionState();
                    }
                }
            });

            this.contentManager.on(ContentManagerEvents.ContentMetadataUpdated, metadata => {
                if (!content) return;

                console.log(`metadata:`, metadata);
                content.metadata = metadata;
                this.timelineManager.updateContent(content);

                fireContentUpdated();
            });

            this.engine.on(NotificationEvents.ClearNotification, () => {
                if (this.uiManager && this.uiManager.ready) {
                    this.uiManager.clearNotification();
                }
            });

            this.engine.on(NotificationEvents.ShowNotification, e => {
                if (this.uiManager && this.uiManager.ready) {
                    this.uiManager.showNotification(e.displayMessage, e.duration || 10000);
                }
            });

            this.engine.on(EngineEvents.AdvertisementEnded, createStreamUpdatingEngineEventHandler('AdvertisementEnded'));

            this.engine.on(EngineEvents.AdvertisementStarted, createStreamUpdatingEngineEventHandler('AdvertisementStarted'));

            this.engine.on(EngineEvents.AdvertisementBreakEnded, createStreamUpdatingEngineEventHandler('AdvertisementBreakEnded'));

            this.engine.on(EngineEvents.AdvertisementBreakStarted, createStreamUpdatingEngineEventHandler('AdvertisementBreakStarted'));

            this.engine.on(EngineEvents.LoadedData, () => {
                if (!this.config.receiver.features.ui.adCounter) {
                    this.uiManager.hideAdCounter();
                }

                fireReceiverStateLoadComplete();
            });

            this.engine.on(EngineEvents.LoadedMetadata, createReceiverStateChangedHandler(ReceiverStates.StreamInitialized));

            /**
             * @deprecated 3.0.0
             */
            this.engine.on(EngineEvents.Seeked, createEngineEventHandler('Seeked'));
            /**
             * @deprecated 3.0.0
             */
            this.engine.on(EngineEvents.Seeking, createEngineEventHandler('Seeking'));
            /**
             * @deprecated 3.0.0
             */
            this.engine.on(EngineEvents.Buffered, createEngineEventHandler('Buffered'));
            /**
             * @deprecated 3.0.0
             */
            this.engine.on(EngineEvents.Buffering, createEngineEventHandler('Buffering'));

            this.engine.on(EngineEvents.StreamChanged, e => {
                content.streamRestrictions = e.stream.streamRestrictions;
                fireStreamChanged();
            });

            this.engine.on(EngineEvents.StreamFinished, e => {
                content = null;

                if (e.reason === StoppedReasons.EndOfStream && this.contentManager.getNextContent()) {
                    e.reason = StoppedReasons.NextContentAutomatic;
                }

                fireContentStopped(e.reason);
            });

            /*** playbackStateChanged ***/
            const playbackStateChanged = (state) => {
                const firePlaybackStateChanged = (playbackState) => {
                    this.communicationManager.sendUnifiedMessage({
                        type: 'Event.PlaybackStateChanged',
                        data: {
                            state: playbackState
                        }
                    });
                };
                const playPausedState = this.engine.mediaElement.paused ? PlaybackStates.Paused : PlaybackStates.Playing;
                switch (state) {
                    case EngineEvents.Buffered:
                        firePlaybackStateChanged(PlaybackStates.Buffered);
                        firePlaybackStateChanged(playPausedState);
                        break;
                    case EngineEvents.Buffering:
                        firePlaybackStateChanged(PlaybackStates.Buffering);
                        break;
                    case EngineEvents.Pause:
                        firePlaybackStateChanged(PlaybackStates.Paused);
                        break;
                    case EngineEvents.Play:
                        firePlaybackStateChanged(PlaybackStates.Playing);
                        break;
                    case EngineEvents.Seeked:
                        firePlaybackStateChanged(PlaybackStates.Seeked);
                        firePlaybackStateChanged(playPausedState);
                        break;
                    case EngineEvents.Seeking:
                        firePlaybackStateChanged(PlaybackStates.Seeking);
                        break;
                }
            };
            this.engine.on(EngineEvents.Buffered, e => playbackStateChanged(EngineEvents.Buffered));
            this.engine.on(EngineEvents.Buffering, e => playbackStateChanged(EngineEvents.Buffering));
            this.engine.on(EngineEvents.Pause, e => playbackStateChanged(EngineEvents.Pause));
            this.engine.on(EngineEvents.Play, e => playbackStateChanged(EngineEvents.Play));
            this.engine.on(EngineEvents.Seeked, e => playbackStateChanged(EngineEvents.Seeked));
            this.engine.on(EngineEvents.Seeking, e => playbackStateChanged(EngineEvents.Seeking));

            this.engine.on(EngineEvents.SeekableRangeUpdated, e => {
                this.communicationManager.sendUnifiedMessage({
                    type: 'Event.SeekableRangeUpdated',
                    data: e
                });
            })

            this.timelineManager.on(TimelineManagerEvents.SeekableRangeUpdated, e => {
                this.communicationManager.sendUnifiedMessage({
                    type: 'Event.SeekableRangeUpdated',
                    data: e
                });
            });

            this.timelineManager.on(TimelineManagerEvents.TimeUpdate, e => {
                if (this.receiverInterface) {
                    this.receiverInterface.dispatchEvent(TimelineManagerEvents.TimeUpdate, e);
                }
            });

            this.tracksManager.on(TrackManagerEvents.AudioTrackChanged, createTrackChangedHandler('Audio'));

            this.tracksManager.on(TrackManagerEvents.TextTrackChanged, createTrackChangedHandler('Text'));

            this.mediator.use(cast.framework.messages.MessageType.MEDIA_STATUS, args => {
                args.request = RequestMapper.mapMediaStatusRequest(args.request, hasStartedPlaying);

                args.request = this.serviceLayer.mapMediaStatusRequest(args.request);
                args.request.customData = filterMediaStatusCustomData(args.request.customData || {})
                //args.request.customData = filterMediaStatusCustomData(this.serviceLayer.buildMediaStatusCustomData());
                if (args.request.media) {
                    delete args.request.media.customData;

                    if (args.request.media.tracks) {
                        args.request.media.tracks = this.tracksManager.getTracks();
                    }
                }

                if (this.timelineManager.seekableRangeInfo && this.timelineManager.seekableRangeInfo.seekableRange) {
                    args.request.liveSeekableRange = this.timelineManager.seekableRangeInfo.seekableRange;
                }

                args.resolve(args.request);
            });

            /**
             * Needed to avoid the LOAD interceptor being called twice
             */
            this.mediator.use(cast.framework.messages.MessageType.PRELOAD, args => {
                args.request = RequestMapper.mapPreloadRequest(args.request);

                args.resolve(args.request);
            });

            this.mediator.use(cast.framework.messages.MessageType.LOAD_BY_ENTITY, args => {
                this.loadingByEntity = true;

                if (!args.request.entity) {
                    return args.pass();
                }

                args.request.entity = this.serviceLayer.mapEntity(args.request.entity);

                args.resolve(args.request);
            });

            this.mediator.use(cast.framework.messages.MessageType.LOAD, args => {
                if (this.busy) {
                    clearTimeout(this.busy);
                    this.busy = setTimeout(() => {
                        this.busy = null
                    }, 1000)
                    return undefined
                } else {
                    this.busy = setTimeout(() => {
                        this.busy = null
                    }, 1000)
                }

                if (args.request.media && args.request.media.metadata) {
                    args.request.media.metadata.images = args.request.media.metadata.images || [];
                    if (!args.request.media.metadata.images.length && this.config.receiver.logoUrl) {
                        args.request.media.metadata.images.push({
                            url: Utils.resolveUrl(this.config.receiver.logoUrl),
                            height: 0,
                            width: 0
                        });
                    }
                }

                this.loadingByEntity = false;
                ++this.loadCounter;
                this.load = {
                    dto: {
                        original: null,
                        final: null
                    },
                    request: {
                        original: Utils.extend({}, args.request),
                        final: null
                    }
                };

                if (this.errorManager.terminatePending || (this.errorManager.reloadPending && !this.errorManager.reloadAbortable)) {
                    return args.reject();
                }

                args.request = RequestMapper.mapLoadRequest(args.request);

                if (args.request.customData && !args.request.customData.content) {
                    args.request = LegacyLoadRequestMapper.mapToLoadDto(args.request);
                }

                this.errorManager.setContent({
                    contentId: args.request.media.contentId
                });

                if (requiresUpdate) {
                    const error = {
                        code: ErrorCodes.UpdateRequired,
                        fatal: true,
                        origin: ErrorOrigins.Receiver.Interceptor.Load,
                        reload: false
                    };
                    return this.errorManager.dispatchError(error);
                }

                const loadDto = RequestMapper.mapToLoadDto(args.request, getSender(args.request.senderId));

                // //cleaning stream if we are not in debug mode
                if (!this.config.receiver.debug && loadDto.content && Array.isArray(loadDto.content.streams) && loadDto.content.streams.length) {
                    loadDto.content.streams = [];
                    console.warn('It is not possible to provide streams in the load-request without debugMode true!!');
                }

                const loadDtoPropertiesFromLegacyDataStructure = this.serviceLayer.mapCustomDataToLoadDto(args.request.customData);
                Object.keys(loadDtoPropertiesFromLegacyDataStructure)
                  .forEach(key => {
                      if (loadDto.hasOwnProperty(key) && !loadDto[key]) { // Only allowed to set properties that aren't yet populated
                          loadDto[key] = loadDtoPropertiesFromLegacyDataStructure[key];
                          delete loadDtoPropertiesFromLegacyDataStructure[key];
                      }
                  });
                delete args.request.customData;

                if (!loadDto.content.contentId) {
                    loadDto.content.contentId = args.request.media.entity;
                }

                this.contentManager.clearNextContent();

                this.errorManager.clearError();
                this.errorManager.setContent(loadDto.content);

                if (!this.serviceLayer.canHandleContent(loadDto.content)) { // Let Caf try to handle the request if the service layer is unable to
                    hasStartedPlaying = true;
                    return args.pass();
                }

                const loadCounter = this.loadCounter;

                // This does not require an active call to engine.stop() since Caf would be in the process of stopping any potentially ongoing media
                this.engine
                  .awaitStop(true)
                  .then(reset)
                  .then(() => {
                      // If callback invoked for old, disposed, load request then abort
                      if (loadCounter < this.loadCounter) return args.resolve(null);

                      content = null;
                      hasStartedPlaying = false;

                      fireReceiverStateChanged(ReceiverStates.LoadStarted);
                      this.trackingManager.playbackSessionStarted(this.receiverInformation);

                      this.load.dto.original = Utils.extend({}, loadDto);

                      this.serviceLayer.handleLoad(loadDto, this.receiverInformation)
                        .then(modifiedLoadDto => {
                            // If callback invoked for old, disposed, load request then abort
                            if (loadCounter < this.loadCounter) return args.resolve(null);

                            if (!modifiedLoadDto || !modifiedLoadDto.content) {
                                this.uiManager.showNotification('Request was canceled!');
                                return undefined;
                            }

                            fireReceiverStateChanged(ReceiverStates.ServiceLayerInitialized);
                            this.trackingManager.playbackSessionInitialized();

                            // If metadata acquisition is faster then the serviceLayer::handleLoad
                            if (this.contentManager.hasPendingMetadata) {
                                modifiedLoadDto.content.metadata = this.contentManager.getPendingMetadata();
                            }

                            this.timelineManager.updateContent(modifiedLoadDto.content);

                            /**
                             * setting the I18n language as fallback if no language is provided
                             */
                            //|todo receiver.js [INVESTIGATE][Major]: Should only happen on init load maybe?;
                            modifiedLoadDto.language = Utils.extend({
                                preferred: {
                                    audio: [I18n.language],
                                    text: [I18n.language]
                                }
                            }, modifiedLoadDto.language);

                            this.tracksManager.setLanguage(modifiedLoadDto.language);

                            content = modifiedLoadDto.content;
                            fireContentChanged();

                            // Configure engine from Content
                            if (content && content.streams && content.streams.length) {
                                this.engine.setStreams(content.streams);
                            }

                            const handleStartOfPlayback = () => {
                                this.engine.off(EngineEvents.AdvertisementTimeUpdate, handleStartOfPlayback);
                                this.engine.off(EngineEvents.TimeUpdate, handleStartOfPlayback);

                                hasStartedPlaying = true;

                                if (receiverConfig.features.nextContent) {
                                    this.serviceLayer.handleLoadNextContent()
                                      .catch(e => {
                                          this.errorManager.dispatchError({
                                              category: ErrorCategories.Default,
                                              code: ErrorCodes.HandleLoadNextContentError,
                                              details: {
                                                  originalError: e
                                              },
                                              fatal: false,
                                              message: e.message || 'An error occurred while retrieving next content'
                                          });
                                      });
                                }
                            };

                            this.engine.once(EngineEvents.AdvertisementTimeUpdate, handleStartOfPlayback);
                            this.engine.once(EngineEvents.TimeUpdate, handleStartOfPlayback);

                            this.load.dto.final = Utils.extend({}, loadDto);
                            args.request = RequestMapper.mapFromLoadDto(args.request, modifiedLoadDto, this.engine.stream, this.config.receiver.logoUrl);

                            return this.engine.handleLoadRequest(args.request) // Returning this promise to let any errors be handled by the following catch
                              .then(finalLoadRequest => args.resolve(
                                this.load.request.final = RequestMapper.mapFinalLoadRequest(finalLoadRequest)
                              ));
                        })
                        .catch(e => {
                            e = mapLoadError(e);
                            // If callback invoked for old, disposed, load request or was cancelled then abort
                            if (loadCounter < this.loadCounter || e.cancelled) return args.resolve(null);

                            this.errorManager.dispatchError(e)
                              .then(processedError => {
                                  // If callback invoked for old, disposed, load request then abort
                                  if (loadCounter < this.loadCounter) return args.resolve(null);
                                  if (processedError.fatal) {
                                      // Communicate error back to any potential sender
                                      return args.reject(processedError);
                                  }

                                  // Non-fatal error, let Caf try to play
                                  args.resolve(args.request);
                              })
                              .catch(e => { // Failed to throw error - reject load request
                                  args.reject(e);
                              });
                        });
                  });
            });

            this.mediator.use(cast.framework.messages.MessageType.EDIT_AUDIO_TRACKS, args => {
                args.request = RequestMapper.mapEditTracksRequest(args.request);

                let setPromise = Promise.resolve(args.request);
                if (args.request.language) {
                    if (['none', 'off'].includes(args.request.language.toLowerCase())) return args.resolve(null);

                    setPromise = this.serviceLayer.handleSetAudioTrack(args.request.language)
                      .then(language => {
                          this.tracksManager.audioTrackLanguage = language;
                          args.request.language = language;

                          return args.request;
                      });
                }

                setPromise
                  .then(args.resolve)
                  .catch(() => args.resolve(null));
            });

            this.mediator.use(cast.framework.messages.MessageType.EDIT_TRACKS_INFO, args => {
                args.request = RequestMapper.mapEditTracksRequest(args.request);

                let setPromise = Promise.resolve(args.request);
                if (args.request.language) {
                    setPromise = this.serviceLayer.handleSetTextTrack(args.request.language)
                      .then(language => {
                          this.tracksManager.textTrackLanguage = language;
                          args.request.language = language;

                          return args.request;
                      });
                } else if (args.request.activeTrackIds) {
                    setPromise = this.serviceLayer.handleSetActiveTracks(args.request)
                      .then(dto => this.tracksManager.handleSetActiveTracksRequest(dto));
                }

                setPromise
                  .then(args.resolve)
                  .catch(() => args.resolve(null));
            });

            this.mediator.use(cast.framework.messages.MessageType.PAUSE, args => {
                if (!this.engine.can('pause')) return args.resolve(null);

                this.receiverInterface.dispatchEvent(ReceiverEvents.PauseRequest);

                this.serviceLayer.handlePause()
                  .then(() => this.uiManager.pause())
                  .then(args.pass)
                  .catch(() => args.resolve(null));
            });

            this.mediator.use(cast.framework.messages.MessageType.PLAY, args => {
                this.receiverInterface.dispatchEvent(ReceiverEvents.PlayRequest);

                this.serviceLayer.handlePlay()
                  .then(() => this.uiManager.play())
                  .then(args.pass)
                  .catch(() => args.resolve(null));
            });

            this.mediator.use(cast.framework.messages.MessageType.SEEK, args => {
                if (!this.engine.can('seek')) return args.resolve(null);

                this.receiverInterface.dispatchEvent(ReceiverEvents.SeekRequest);

                this.serviceLayer.handleSeek(args.request)
                  .then(dto => args.resolve(this.engine.handleSeekRequest(dto)))
                  .catch(() => args.resolve(null));
            });

            this.mediator.use(cast.framework.messages.MessageType.STOP, args => {
                this.receiverInterface.dispatchEvent(ReceiverEvents.StopRequest);

                // This does not require an active call to engine.stop() since Caf is in the process of stopping the ongoing media
                this.engine
                  .awaitStop()
                  .then(reset);

                args.pass();
            });

            this.mediator.use(MediatorEvents.Error, e => this.errorManager.dispatchError(e));

            this.mediator.use(MediatorEvents.SenderConnected, e => {
                if (!this.senders.find(s => s.id === e.senderId)) {
                    const sender = new Sender({
                        id: e.senderId,
                        userAgent: e.userAgent,
                        connectTimeout: setTimeout(
                          () => {
                              sender.sendUnifiedMessage({ type: 'Command.Connect', senderId: e.senderId });

                              sender.connectTimeout = setTimeout(fireSessionState, 1000);
                          },
                          1000
                        ),
                        sendApplicationMessage: message => this.communicationManager.sendApplicationMessage(message, e.senderId),
                        sendUnifiedMessage: message => this.communicationManager.sendUnifiedMessage(message, e.senderId)
                    });

                    /**
                     * Clean up all 'old' senders of type: SenderTypes.Web. For now (2021-09-20), only one Web sender can be
                     * connected at the time but the Js Caf Sender Sdk generates a unique sender id on every page reload which
                     * causes the Caf Receiver Sdk to keep a bunch of old/stale senders in its sender list.
                     */
                    if (sender.type === SenderTypes.Web) {
                        this.senders
                          .filter(s => s.type === SenderTypes.Web)
                          .forEach(s => s.connectTimeout && (s.connectTimeout = clearTimeout(s.connectTimeout)));
                        this.senders = this.senders.filter(s => s.type !== SenderTypes.Web);
                    }

                    this.senders.push(sender);
                }
            });

            this.mediator.use(MediatorEvents.SenderDisconnected, e => {
                this.senders = this.senders.filter(s => s.id !== e.senderId); // Remove sender from list

                if (!this.config.receiver.features.senderDisconnectedDestroy) return;

                if (e.reason === cast.framework.system.DisconnectReason.REQUESTED_BY_SENDER) {
                    this.serviceLayer.handleShutdown({ reason: ShutdownReasons.RequestedBySender });

                    this.engine.stop(StoppedReasons.User);
                    this.uiManager.fadeOut();

                    Utils.defer(() => this.destroy(), this.config.receiver.senderDisconnectedDestroyDelay);
                }
            });

            this.mediator.use(MediatorEvents.Shutdown, () => this.serviceLayer.handleShutdown({ reason: ShutdownReasons.Caf }));
        };

        const setupReceiver = () => {
            if (this.config.receiver.features.checkSourceBufferLimit) {
                Utils.checkSourceBufferLimit();
            }

            this.communicationManager = new CommunicationManager({
                config: this.config.receiver
            });

            this.uiManager = new UiManager({
                config: this.config.receiver
            }).setupDom();

            this.errorManager = new ErrorManager({
                config: this.config,
                handleError: e => {
                    let handleErrorPromise = Promise.resolve();
                    if (this.serviceLayer) {
                        handleErrorPromise = this.serviceLayer.handleError(e);
                    }

                    return handleErrorPromise;
                },
                handleFinalError: error => {
                    if (this.receiverInterface) {
                        this.receiverInterface.dispatchEvent(ReceiverEvents.Error, error);
                        try {
                            this.receiverInterface.logFinalError(error);
                        } catch (e) {
                            //don't do more here!
                        }
                    }

                    this.trackingManager.error(error);

                    let handleFinalErrorPromise = Promise.resolve();
                    if (error.fatal) {
                        if ([ErrorOrigins.Receiver.Interceptor.Load, ErrorOrigins.ServiceLayer.Generic].includes(error.origin)) {
                            fireReceiverStateChanged(ReceiverStates.LoadFailed);
                        } else {
                            fireReceiverStateChanged(ReceiverStates.StreamFailed);
                        }

                        this.communicationManager.sendUnifiedMessage({
                            type: `Event.ReceiverError`,
                            data: { error }
                        });

                        if (this.serviceLayer) {
                            handleFinalErrorPromise = this.serviceLayer.handleShutdown({ reason: ShutdownReasons.Error });
                        }
                    }

                    return handleFinalErrorPromise;
                },
                terminate: () => this.destroy(),
                uiManager: this.uiManager
            });

            this.engine = new Engine({
                errorManager: this.errorManager,
                features: this.config.receiver.features,
                stoppedHandler: reset,
                uiManager: this.uiManager
            });

            this.mediator = new Mediator({
                communicationManager: this.communicationManager,
                config: this.config.receiver,
                engine: this.engine
            });

            this.playbackManager = new PlaybackManager({
                engine: this.engine,
                features: this.config.receiver.features.playbackManager
            });

            this.queueManager = new QueueManager();

            this.contentManager = new ContentManager({
                config: this.config.receiver,
                engine: this.engine,
                queueManager: this.queueManager
            });

            this.sessionManager = new SessionManager({
                engine: this.engine,
                uiManager: this.uiManager
            });

            this.timelineManager = new TimelineManager({
                engine: this.engine,
                config: this.config.receiver
            });

            this.tracksManager = new TracksManager({
                accessibilitySupported: this.config.receiver.features.accessibilitySupported,
                addOffTextTrack: this.config.receiver.features.addOffTextTrack,
                engine: this.engine
            });

            this.trackingManager = new TrackingManager({
                config: {},
                engine: this.engine,
                tracksManager: this.tracksManager
            });

            this.receiverInterface = new ReceiverInterface({
                communicationManager: this.communicationManager,
                contentManager: this.contentManager,
                engine: this.engine,
                errorManager: this.errorManager,
                playbackManager: this.playbackManager,
                tracksManager: this.tracksManager,
                uiManager: this.uiManager
            });

            if (this.config.receiver.features.debugOverlay) {
                this.debugOverlay = this.uiManager.registerOverlay(
                  DebugOverlay.Name,
                  ({ container }) => new DebugOverlay({
                      config: this.config.receiver,
                      container,
                      engine: this.engine
                  })
                );
            }

            if (this.config.receiver.features.debugTracking) {
                setupDebugTracking();
            }

            if (this.config.receiver.features.notifications) {
                this.notificationsOverlay = this.uiManager.registerOverlay(
                  NotificationsOverlay.Name,
                  ({ container }) => new NotificationsOverlay({ container })
                );
            }

            if (this.config.receiver.features.validationEvents) {
                this.validationEventDispatcher = new ValidationEventDispatcher({
                    communicationManager: this.communicationManager,
                    engine: this.engine,
                    trackManager: this.tracksManager
                });
            }

            if (this.config.receiver.features.tracking.trackers.console) {
                this.trackingManager.registerTracker(
                  ConsoleTracker.Name,
                  new ConsoleTracker({
                      config: {}
                  })
                );
            }
        };

        const setupReceiverInformation = () => {
            const deviceInfo = Utils.getDeviceInfo();
            const generation = deviceInfo.generation || 'Unknown';
            const builtIn = !!deviceInfo.android
              ? 'BuiltIn'
              : '';
            const androidDeviceInfo = !!builtIn
              ? `(${generation})`
              : '';

            this.receiverInformation = new ReceiverInformation({
                browser: {
                    browserName: 'Caf',
                    browserVersion: deviceInfo.cafVersion
                },
                device: {
                    deviceId: deviceInfo.deviceId,
                    deviceName: 'Chromecast',
                    deviceModel: `Generation.${generation}`,
                    deviceVendor: 'Google',
                    deviceVersion: generation
                },
                operatingSystem: {
                    operatingSystemFirmwareVersion: deviceInfo.firmwareVersion,
                    operatingSystemName: 'Chromecast',
                    operatingSystemVersion: `${deviceInfo.firmwareVersion} ${androidDeviceInfo}`.trim()
                },
                player: {
                    playerName: 'UnifiedReceiver',
                    playerVersion: `${this.version}`
                },
                screen: {
                    screenHeight: window.screen.availHeight,
                    screenWidth: window.screen.availWidth
                },
                session: {
                    sessionId: Utils.guid()
                }
            })
        };

        const setupServiceLayer = () => {
            this.config.serviceLayer.debug = this.config.receiver.debug;
            // I had to change this to make the DefaultServiceLayerConfiguration extendable
            this.serviceLayer = new ServiceLayerClass(this.config.serviceLayer, this.receiverInterface);
        };

        const start = () => this.mediator.start();

        const updateReceiverInformation = () => {
            const deviceInfo = Utils.getDeviceInfo();

            this.receiverInformation.browser = {
                browserName: 'Caf',
                browserVersion: `${deviceInfo.cafVersion}${deviceInfo.shakaVersion ? `--Shaka--${deviceInfo.shakaVersion}` : ''}`
            }
        };

        return this.preload
          .finally(domReady)
          .then(setupReceiver)
          .then(interceptConsole)
          .then(setupReceiverInformation)
          .then(setupServiceLayer)
          .then(setupIntegration)
          .then(initializeServiceLayer)
          .then(applyServiceLayerConfigurationOverrides)
          .then(configureDebugOverlay)
          .catch(handleReceiverStartError) // Run start regardless of error(s) above
          .then(start)
          .then(awaitShakaIntercept)
          .then(updateReceiverInformation)
          .catch(handleReceiverStartError); // Catch any errors from start
    }

    /**
     * @param {string|object} [config]
     */
    initialize(config = './config.json') {
        let configPromise = Promise.resolve(config);
        if (Utils.isString(config)) {
            configPromise = fetch(config)
              .then(response => response.json())
              .catch(() => {
              });
        }

        const applyConfig = (config = {}) => {
            config = Utils.extend({}, this.config, config);
            config.receiver.debug = Utils.booleanOrDefault(config.receiver.debug, false);
            config.receiver.features = config.receiver.features || {};
            config.receiver.features.accessibilitySupported = Utils.booleanOrDefault(config.receiver.features.accessibilitySupported, false);
            config.receiver.features.addOffTextTrack = Utils.booleanOrDefault(config.receiver.features.addOffTextTrack, false);
            config.receiver.features.checkSourceBufferLimit = Utils.booleanOrDefault(config.receiver.features.checkSourceBufferLimit, false);
            config.receiver.features.debugConsole = config.receiver.features.debugConsole || {};
            config.receiver.features.debugConsole.debug = Utils.booleanOrDefault(config.receiver.features.debugConsole.debug, false);
            config.receiver.features.debugConsole.log = Utils.booleanOrDefault(config.receiver.features.debugConsole.log, false);
            config.receiver.features.debugConsole.info = Utils.booleanOrDefault(config.receiver.features.debugConsole.info, false);
            config.receiver.features.debugConsole.warn = Utils.booleanOrDefault(config.receiver.features.debugConsole.warn, false);
            config.receiver.features.debugConsole.error = Utils.booleanOrDefault(config.receiver.features.debugConsole.error, false);
            config.receiver.features.debugOverlay = Utils.booleanOrDefault(config.receiver.features.debugOverlay, true);
            config.receiver.features.debugTracking = Utils.booleanOrDefault(config.receiver.features.debugTracking, false);
            config.receiver.features.dush = Utils.booleanOrDefault(config.receiver.features.dush, false);
            config.receiver.features.engine = config.receiver.features.engine || {};
            config.receiver.features.engine.preferShakaHlsCmaf = Utils.booleanOrDefault(config.receiver.features.engine.preferShakaHlsCmaf, true);
            config.receiver.features.engine.preferShakaHlsTs = Utils.booleanOrDefault(config.receiver.features.engine.preferShakaHlsTs, false);
            config.receiver.features.engine.shakaLogLevel = Utils.numberOrDefault(config.receiver.features.engine.shakaLogLevel, 0);
            config.receiver.features.intercept = config.receiver.features.intercept || {};
            config.receiver.features.intercept.image = Utils.booleanOrDefault(config.receiver.features.intercept.image, true);
            config.receiver.features.intercept.network = Utils.booleanOrDefault(config.receiver.features.intercept.network, true);
            config.receiver.features.loadCastFramework = Utils.booleanOrDefault(config.receiver.features.loadCastFramework, true);
            config.receiver.features.nextContent = Utils.booleanOrDefault(config.receiver.features.nextContent, true);
            config.receiver.features.notifications = Utils.booleanOrDefault(config.receiver.features.notifications, true);
            config.receiver.features.playbackManager = config.receiver.features.playbackManager || {};
            config.receiver.features.playbackManager.dynamicBufferAdjustment = Utils.booleanOrDefault(config.receiver.features.playbackManager.dynamicBufferAdjustment, false);
            config.receiver.features.preserveBrBreaksInTtmlTextContent = Utils.booleanOrDefault(config.receiver.features.preserveBrBreaksInTtmlTextContent, false);
            config.receiver.features.senderDisconnectedDestroy = Utils.booleanOrDefault(config.receiver.features.senderDisconnectedDestroy, false);
            config.receiver.features.shakaWacka = Utils.booleanOrDefault(config.receiver.features.shakaWacka, false);
            config.receiver.features.timelineManager = config.receiver.features.timelineManager || {};
            config.receiver.features.timelineManager.useEPGRestrictions = Utils.booleanOrDefault(config.receiver.features.timelineManager.useEPGRestrictions, false);
            config.receiver.features.timelineManager.seekableRangeUpdateTolerance = Utils.numberOrDefault(config.receiver.features.timelineManager.seekableRangeUpdateTolerance, 10);
            config.receiver.features.tracking = config.receiver.features.tracking || {};
            config.receiver.features.tracking.trackers = config.receiver.features.tracking.trackers || {};
            config.receiver.features.tracking.trackers.console = Utils.booleanOrDefault(config.receiver.features.tracking.trackers.console, false);
            config.receiver.features.ui = config.receiver.features.ui || {};
            config.receiver.features.ui.adCounter = Utils.booleanOrDefault(config.receiver.features.ui.adCounter, false);
            config.receiver.features.ui.injectDefaultStyling = Utils.booleanOrDefault(config.receiver.features.ui.injectDefaultStyling, true);
            config.receiver.features.validationEvents = Utils.booleanOrDefault(config.receiver.features.validationEvents, false);
            config.receiver.features.wacka = Utils.booleanOrDefault(config.receiver.features.wacka, false);
            config.receiver.logoUrl = Utils.stringOrDefault(config.receiver.logoUrl);
            config.receiver.messageNamespace = Utils.stringOrDefault(config.receiver.messageNamespace, 'urn:x-cast:application.messaging');
            config.receiver.minimumFirmwareVersion = Utils.stringOrDefault(config.receiver.minimumFirmwareVersion, '1.0.0');
            config.receiver.reloadDelay = Utils.numberOrDefault(config.receiver.reloadDelay, 5000);
            config.receiver.senderDisconnectedDestroyDelay = Utils.numberOrDefault(config.receiver.senderDisconnectedDestroyDelay, 2000);
            config.receiver.shakaUrl = Utils.stringOrDefault(config.receiver.shakaUrl);
            config.receiver.terminateDelay = Utils.numberOrDefault(config.receiver.terminateDelay, 5000);
            config.receiver.unifiedMessageNamespace = Utils.stringOrDefault(config.receiver.unifiedMessageNamespace, 'urn:x-cast:unified.messaging');

            return this.config = config;
        };

        const setupCastFrameworkDependency = (config) => {
            if (this.config.receiver.features.loadCastFramework) {
                const language = (navigator.language || 'en')
                  .replace('-', '_')
                  .toLowerCase();
                this.config.dependencies.push(`//www.gstatic.com/intl/${language}/cast/sdk/libs/caf_receiver/v3/cast_receiver_framework.js`);
            }

            return config;
        };

        const setupInterceptors = (config) => {
            if (this.config.receiver.features.intercept) {
                if (this.config.receiver.features.intercept.network) {
                    NetworkInterceptor
                      .getInstance()
                      .intercept();
                }
                if (this.config.receiver.features.intercept.image) {
                    ImageInterceptor
                      .getInstance()
                      .intercept();
                }
            }

            return config;
        };

        const loadDependencies = (config) => {
            const createLoaders = dependencies => dependencies.map(
              url => () => new Promise(resolve => Utils.loadScript(url, resolve))
            );

            let dependencyLoaders = [];
            if (config.dependencies && config.dependencies.length) {
                dependencyLoaders = dependencyLoaders.concat(createLoaders(config.dependencies));
            }

            return dependencyLoaders.reduce((p, fn) => p.then(fn), Promise.resolve());
        };

        return this.preload = configPromise
          .then(applyConfig)
          .then(setupCastFrameworkDependency)
          .then(setupInterceptors)
          .then(loadDependencies)
          .catch(() => Promise.resolve());
    }

    destroy() {
        this.communicationManager.destroy();
        this.contentManager.destroy();
        this.errorManager.destroy();
        this.playbackManager.destroy();
        this.queueManager.destroy();
        this.sessionManager.destroy();
        this.timelineManager.destroy();
        this.trackingManager.destroy();
        this.tracksManager.destroy();
        this.uiManager.destroy();

        this.engine.destroy();

        if (this.serviceLayer) {
            this.serviceLayer.destroy();
        }

        if (this.validationEventDispatcher) {
            this.validationEventDispatcher.destroy();
        }

        ImageInterceptor
          .getInstance()
          .destroy();

        NetworkInterceptor
          .getInstance()
          .destroy();

        this.mediator.destroy();

        this.communicationManager = null;
        this.contentManager = null;
        this.debugOverlay = null;
        this.engine = null;
        this.errorManager = null;
        this.load = null;
        this.loadCounter = 0;
        this.loadingByEntity = false;
        this.mediator = null;
        this.notificationsOverlay = null;
        this.playbackManager = null;
        this.preload = null;
        this.queueManager = null;
        this.senders = [];
        this.receiverInformation = null;
        this.serviceLayer = null;
        this.sessionManager = null;
        this.trackingManager = null;
        this.tracksManager = null;
        this.uiManager = null;
        this.validationEventDispatcher = null;
    }
};