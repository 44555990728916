const {
    EngineEvents
} = require('../../Events');

const Utils = require('../../utils/Utils');
const ShakaManifestInfoHandler = require('../../player/shaka/ShakaManifestInfoHandler');

module.exports = class PlaybackManager {
    constructor({ engine, features }) {
        this.engine = engine;
        this.features = features;

        /**
         * bufferMargin is a fixed safety margin to avoid filling up the buffer entirely.
         * Adjust this value to account for fluctuations between declared bitrates (manifest) and actual bitrates (media files).
         */
        this.bufferMargin = 0.8;
        /**
         * bufferScale is a runtime-changeable value to scale down target buffers
         * ToDo: Decrease in same manner as Shaka when hitting QuotaExceeded (how to detect?)
         * Reduction schedule: 80%, 60%, 40%, 20%, 16%, 12%, 8%, 4%, fail.
         * Taken from: https://developers.google.com/web/updates/2017/10/quotaexceedederror
         */
        this.bufferScale = 1;

        this.activePhase = null;
        this.canHandlePlayer = false;
        this.canHandlePlayers = ['Shaka', 'ShakaLaka', 'ShakaWacka', 'ShakaWackaLaka'];
        this.dynamicPlayerConfiguration = {
            start: {}
        };
        this.engineEventListeners = {};
        this.maxBufferBytes = 30 * 1000000; // 30MB for ChromeCast
        this.maxBufferSeconds = null;
        this.maxSegmentDuration = null;
        this.minTargetBufferAheadSeconds = 25;
        this.mediaElement = null;
        this.phaseTimeouts = {};
        this.player = null;
        this.streamStartHandlers = [];
        this.supportedDynamicConfigurationValues = {
            maxBandwidth: {
                path: 'restrictions.maxBandwidth'
            },
            maxFrameRate: {
                path: 'restrictions.maxFrameRate'
            },
            maxHeight: {
                path: 'restrictions.maxHeight'
            },
            bufferBehind: {
                path: 'streaming.bufferBehind'
            },
            bufferingGoal: {
                path: 'streaming.bufferingGoal'
            },
            rebufferingGoal: {
                path: 'streaming.rebufferingGoal'
            }
        };
        this.targetBufferAhead = null;
        this.targetBufferBehind = null;
        this.targetBufferBehindRatio = null;

        this.registerEventListeners();
    }

    ensureNotDestroyed(func) {
        return (...args) => {
            if (this.destroyed) return;
            return func(...args);
        };
    };

    listen(target, listenerCollection, event, listener) {
        if (!(target && listenerCollection && event && listener)) {
            return;
        }

        const listenerFunction = Utils.isFunction(target.on) ? 'on' : 'addEventListener';

        target[listenerFunction](event, listenerCollection[event] = this.ensureNotDestroyed(listener));
    }

    registerDynamicConfiguration(phase, configuration) {
        if (phase === 'start' && Object.getOwnPropertyNames(configuration).length) {
            for (let key in this.supportedDynamicConfigurationValues) {
                if (!this.supportedDynamicConfigurationValues.hasOwnProperty(key)) continue;

                if (
                  configuration.hasOwnProperty(key) &&
                  configuration[key].hasOwnProperty('value')
                ) {
                    this.dynamicPlayerConfiguration.start[key] = {
                        value: configuration[key].value,
                        path: this.supportedDynamicConfigurationValues[key].path
                    };

                    if (configuration[key].hasOwnProperty('restore')) {
                        this.dynamicPlayerConfiguration.start[key].restore = configuration[key].restore;
                    }
                }
            }
        }
    }

    registerEventListeners() {
        this.listen(this.engine, this.engineEventListeners, EngineEvents.BitrateChanged, e => {
            try {
                if (this.features.dynamicBufferAdjustment && this.canHandlePlayer) {
                    if (!Utils.isNumber(this.maxSegmentDuration)) {
                        this.maxSegmentDuration = this.getMaxSegmentDurationFromPlayer();

                        if (!Utils.isNumber(this.maxSegmentDuration)) return;
                    }

                    this.maxBufferSeconds = this.calculateMaxBufferSeconds(e.bitrate);

                    const {
                        targetBufferAhead,
                        targetBufferBehind
                    } = this.calculateTargetBuffers(this.maxSegmentDuration);

                    this.targetBufferBehind = targetBufferBehind;
                    this.targetBufferAhead = targetBufferAhead;

                    this.player.configure({
                        streaming: {
                            bufferBehind: this.targetBufferBehind,
                            bufferingGoal: this.targetBufferAhead
                        }
                    });
                }
            } catch (e) {
                console.warn('PlaybackManager::EngineEvent:BitrateChanged: failed to adjust buffer targets');
            }
        });
        this.listen(this.engine, this.engineEventListeners, EngineEvents.LoadedMetadata, () => {
            try {
                if (this.canHandlePlayer) {
                    this.maxSegmentDuration = this.getMaxSegmentDurationFromPlayer();
                    if (!Utils.isNumber(this.maxSegmentDuration)) {
                        this.canHandlePlayer = false;
                    }
                }
            } catch (e) {
                this.canHandlePlayer = false;
                console.warn('PlaybackManager::EngineEvent:LoadedMetadata: failed to determine maxSegmentDuration');
            }
        });
        this.listen(this.engine, this.engineEventListeners, EngineEvents.LoadStart, () => {
            this.mediaElement = this.engine.mediaElement;
            this.player = this.engine.player;
            this.canHandlePlayer = !!this.mediaElement &&
              !!this.player &&
              this.canHandlePlayers.includes(this.engine.playerName);

            if (!this.canHandlePlayer) return;

            /**
             * Setting initial values based on configuration.
             * These values will only be used if we don't get a bitrate changed event from the engine.
             * Here we also calculate a targetBufferBehindRatio from the configuration to maintain this ratio for future updates.
             */
            const playerConfiguration = this.player.getConfiguration();
            const { bufferBehind, bufferingGoal } = playerConfiguration.streaming;
            this.targetBufferAhead = bufferingGoal;
            this.targetBufferBehind = bufferBehind;
            if (!Utils.isNumber(this.targetBufferBehindRatio)) { // Don't overwrite if configured from outside
                this.targetBufferBehindRatio = this.targetBufferBehind / (this.targetBufferAhead + this.targetBufferBehind);
            }
            this.maxBufferSeconds = (this.targetBufferAhead + this.targetBufferBehind) / this.bufferMargin / this.bufferScale;

            const playerConfigurationRestoreValues = {};
            for (let key in this.supportedDynamicConfigurationValues) {
                if (!this.supportedDynamicConfigurationValues.hasOwnProperty(key)) continue;

                const value = Utils.getPropertyByPath(playerConfiguration, this.supportedDynamicConfigurationValues[key].path);
                if (typeof (value) !== 'undefined') {
                    playerConfigurationRestoreValues[key] = {
                        path: this.supportedDynamicConfigurationValues[key].path,
                        value
                    };
                }
            }

            this.applyDynamicConfigurationRestoreValues(playerConfigurationRestoreValues);

            const manifestInfoHandlerFilter = new ShakaManifestInfoHandler({
                manifestInfoHandler: manifestInfo => {
                    this.player.getNetworkingEngine().unregisterResponseFilter(manifestInfoHandlerFilter);

                    for (let handler in this.streamStartHandlers) {
                        if (!this.streamStartHandlers.hasOwnProperty(handler)) continue;
                        try {
                            this.streamStartHandlers[handler](manifestInfo);
                        } catch (e) {
                        }
                    }

                    this.enterPhase('start');
                }
            }).responseFilter();

            this.player.getNetworkingEngine().registerResponseFilter(manifestInfoHandlerFilter);
        });
        this.listen(this.engine, this.engineEventListeners, EngineEvents.TimeUpdate, () => {
            try {
                if (this.canHandlePlayer && this.activePhase === 'start') {
                    const bufferedSegments = this.mediaElement.buffered.length;
                    if (!bufferedSegments) return;

                    const bufferedSegmentsRequired = 2;
                    const playTimeRequired = 2;

                    const bufferStart = this.mediaElement.buffered.start(0);
                    const bufferEnd = this.mediaElement.buffered.end(bufferedSegments - 1);
                    const bufferTotal = bufferEnd - bufferStart;
                    const hasReachedStableBuffer = this.maxSegmentDuration
                      ? bufferTotal > (bufferedSegmentsRequired * this.maxSegmentDuration)
                      : false;

                    if (hasReachedStableBuffer && this.mediaElement.currentTime >= playTimeRequired) {
                        this.exitPhase('start');
                    }
                }
            } catch (e) {
                console.warn('PlaybackManager::EngineEvent:TimeUpdate: failed to read buffer values for \'start\' phase');
            }
        });
    }

    unregisterEventListeners() {
        Object.keys(this.engineEventListeners).forEach(e =>
          this.engine.off(e,
            this.engineEventListeners[e]
          )
        );
        this.engineEventListeners = {};
    }

    applyDynamicConfiguration(phase, restore = false) {
        if (Object.getOwnPropertyNames(this.dynamicPlayerConfiguration[phase]).length) {
            for (let key in this.dynamicPlayerConfiguration[phase]) {
                if (!this.dynamicPlayerConfiguration[phase].hasOwnProperty(key)) continue;

                this.player.configure(
                  this.dynamicPlayerConfiguration[phase][key].path,
                  this.dynamicPlayerConfiguration[phase][key][restore ? 'restore' : 'value']
                );
            }
        }
    }

    applyDynamicConfigurationRestoreValues(playerConfigurationRestoreValues) {
        for (let phase in this.dynamicPlayerConfiguration) {
            if (!this.dynamicPlayerConfiguration.hasOwnProperty(phase)) continue;

            for (let key in this.dynamicPlayerConfiguration[phase]) {
                if (
                  !this.dynamicPlayerConfiguration[phase].hasOwnProperty(key) ||
                  this.dynamicPlayerConfiguration[phase].hasOwnProperty('restore')
                ) continue;

                if (playerConfigurationRestoreValues[key].hasOwnProperty('value')) {
                    this.dynamicPlayerConfiguration[phase][key].restore = playerConfigurationRestoreValues[key].value;
                } else {
                    console.warn(
                      'PlaybackManager::applyDynamicConfigurationRestoreValues:',
                      'Dynamic configuration value for: ' + key,
                      'will no be applied to phase:' + phase,
                      'as the restore value is missing!'
                    );
                    delete this.dynamicPlayerConfiguration[phase][key];
                }
            }
        }
    }

    calculateMaxBufferSeconds(bandwidth) {
        const Bps = bandwidth / 8;
        return this.maxBufferBytes / Bps;
    }

    calculateTargetBuffers(maxSegmentDuration) {
        const unscaledTargetBufferBehind = this.maxBufferSeconds * this.targetBufferBehindRatio;
        const scaledBufferMargin = this.bufferMargin * this.bufferScale;

        let targetBufferAhead = Math.floor((this.maxBufferSeconds - unscaledTargetBufferBehind) * scaledBufferMargin);
        let targetBufferBehind = Math.floor(this.maxBufferSeconds * this.targetBufferBehindRatio * scaledBufferMargin);

        console.debug(
          'PlaybackManager::calculateTargetBuffers: calculated new buffer values',
          'maxSegmentDuration: ' + maxSegmentDuration,
          'targetBufferBehind: ' + targetBufferBehind,
          'targetBufferAhead: ' + targetBufferAhead
        );

        if (targetBufferAhead < this.minTargetBufferAheadSeconds || targetBufferBehind < maxSegmentDuration) {
            /**
             * If target buffer ahead is lower than minTargetBufferAhead OR if targetBufferBehind is lower than the
             * minAllowedBufferBehind (1 segment duration) then we set the targetBufferBehind to a fixed value and use
             * the remaining available buffer seconds for the ahead buffer.
             */
            targetBufferBehind = maxSegmentDuration;
            targetBufferAhead = Math.floor((this.maxBufferSeconds - (targetBufferBehind / scaledBufferMargin)) * scaledBufferMargin);

            console.debug(
              'PlaybackManager::calculateTargetBuffers: new buffer values outside bounds, calculated new (adjusted) values',
              'maxSegmentDuration: ' + maxSegmentDuration,
              'targetBufferBehind: ' + targetBufferBehind,
              'targetBufferAhead: ' + targetBufferAhead
            );
        }

        return {
            targetBufferAhead,
            targetBufferBehind
        };
    }

    enterPhase(phase, phaseTimeout = 10000) {
        this.activePhase = phase;
        this.applyDynamicConfiguration(phase);

        if (Utils.isNumber(phaseTimeout)) {
            this.phaseTimeouts[phase] = Utils.defer(() => this.exitPhase(phase), phaseTimeout);
        }
    }

    exitPhase(phase) {
        this.activePhase = null;
        if (Utils.isNumber(this.phaseTimeouts[phase])) {
            this.phaseTimeouts[phase] = clearTimeout(this.phaseTimeouts[phase]);
        }
        this.applyDynamicConfiguration(phase, true);
    }

    getMaxSegmentDurationFromPlayer() {
        let maxSegmentDuration = null;
        const stats = this.player.getStats();
        if (stats && Utils.isNumber(stats.maxSegmentDuration)) {
            maxSegmentDuration = stats.maxSegmentDuration;
        }

        if (!Utils.isNumber(maxSegmentDuration)) {
            const manifest = this.player.getManifest();
            if (
              manifest &&
              manifest.presentationTimeline &&
              manifest.presentationTimeline.getMaxSegmentDuration &&
              Utils.isNumber(manifest.presentationTimeline.getMaxSegmentDuration())
            ) {
                maxSegmentDuration = manifest.presentationTimeline.getMaxSegmentDuration();
            }
        }

        return maxSegmentDuration;
    }

    registerStreamStartHandler(handler) {
        if (!this.streamStartHandlers.includes(handler)) {
            this.streamStartHandlers.push(handler);
        }
    }

    setTargetBufferBehindRatio(targetBufferBehindRatio) {
        if (Utils.isNumber(targetBufferBehindRatio) && 0 <= targetBufferBehindRatio && targetBufferBehindRatio <= 1) {
            this.targetBufferBehindRatio = targetBufferBehindRatio;
        }
    }

    unregisterStreamStartHandler(handler) {
        if (this.streamStartHandlers.includes(handler)) {
            this.streamStartHandlers = this.streamStartHandlers.filter(h => h !== handler);
        }
    }

    reset() {
        for (let p in this.phaseTimeouts) {
            if (!this.phaseTimeouts.hasOwnProperty(p)) continue;

            if (Utils.isNumber(this.phaseTimeouts[p])) {
                this.phaseTimeouts[p] = clearTimeout(this.phaseTimeouts[p]);
            }
        }

        this.activePhase = null;
        this.bufferScale = 1;
        this.canHandlePlayer = false;
        this.dynamicPlayerConfiguration = {
            start: {}
        };
        this.maxBufferSeconds = null;
        this.maxSegmentDuration = null;
        this.mediaElement = null;
        this.phaseTimeouts = {};
        this.player = null;
        this.streamStartHandlers = [];
        this.targetBufferAhead = null;
        this.targetBufferBehind = null;
        this.targetBufferBehindRatio = null;
    }

    destroy() {
        this.reset();

        this.unregisterEventListeners();
    }
};