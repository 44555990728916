const Utils = require('../utils/Utils');

/**
 * @typedef {object} TDebugConsole
 * @property {boolean} [debug=false]
 * @property {boolean} [log=false]
 * @property {boolean} [info=false]
 * @property {boolean} [warn=false]
 * @property {boolean} [error=false]
 */
/**
 * @typedef {object} TEngine
 * @property {boolean} [preferShakaHlsCmaf=true]
 * @property {boolean} [preferShakaHlsTs=false]
 */

/**
 * @typedef {object} TIntercept
 * @property {boolean} [image=true]
 * @property {boolean} [network=true]
 */
/**
 * @typedef {object} TPlaybackManager
 * @property {boolean} [dynamicBufferAdjustment=false]
 */
/**
 * @typedef {object} TTimelineManager
 * @property {boolean} [useEPGRestriction=true]
 * @property {number} [seekableRangeUpdateTolerance=10]
 */
/**
 * @typedef {object} TTrackers
 * @property {boolean} [console=false]
 */
/**
 * @typedef {object} TTracking
 */

/**
 * @typedef {object} TUi
 * @property {boolean} [adCounter=false]
 * @property {boolean} [injectDefaultStyling=true]
 */

/**
 * @typedef {object} TReceiverFeatures
 * @property {boolean} [accessibilitySupported=false]
 * @property {boolean} [addOffTextTrack=false]
 * @property {TDebugConsole} [debugConsole]
 * @property {boolean} [debugOverlay=true]
 * @property {boolean} [debugTracking=false]
 * @property {boolean} [dush=false]
 * @property {TEngine} engine
 * @property {TIntercept} [intercept]
 * @property {boolean} [loadCastFramework=true]
 * @property {boolean} [nextContent=true]
 * @property {TPlaybackManager} [playbackManager]
 * @property {boolean} [preserveBrBreaksInTtmlTextContent=false]
 * @property {boolean} [senderDisconnectedDestroy=false]
 * @property {boolean} [shakaWacka=false]
 * @property {TTimelineManager} [timelineManager]
 * @property {TTracking} [tracking]
 * @property {TUi} [ui]
 * @property {boolean} [validationEvents=false]
 * @property {boolean} [wacka=false]
 */
/**
 * @typedef {object} TReceiverConfig
 * @property {boolean} [debug=false]
 * @property {TReceiverFeatures} [features]
 * @property {string} [messageNamespace]
 * @property {string} [minimumFirmwareVersion]
 * @property {number} [reloadDelay=5000]
 * @property {number} [senderDisconnectedDestroyDelay=2000]
 * @property {string} [shakaUrl=null]
 * @property {string} [unifiedMessageNamespace]
 */

/**
 * @typedef {object} TReceiverConfigArgs
 * @property {Array<string>} [dependencies]
 * @property {TReceiverConfig} [receiver]
 * @property {*} [serviceLayer]
 */
/**
 * @type {TReceiverConfigArgs}
 */
class Props {
    constructor() {
        this.dependencies = this.receiver = this.serviceLayer = undefined;
    }
}

class ReceiverConfig extends Props {
    /**
     * @param {TReceiverConfigArgs} args
     */
    constructor(args = {}) {
        super();

        Utils.extend(this, {
            dependencies: [],
            receiver: {
                debug: false,
                features: {
                    accessibilitySupported: false,
                    addOffTextTrack: false,
                    checkSourceBufferLimit: false,
                    debugConsole: {
                        debug: false,
                        log: false,
                        info: false,
                        warn: false,
                        error: false
                    },
                    debugOverlay: true,
                    debugTracking: false,
                    dush: false,
                    engine: {
                        preferShakaHlsCmaf: true,
                        preferShakaHlsTs: false,
                        shakaLogLevel: 0
                    },
                    intercept: {
                        image: true,
                        network: true
                    },
                    loadCastFramework: true,
                    nextContent: true,
                    notifications: true,
                    playbackManager: {
                        dynamicBufferAdjustment: false
                    },
                    preserveBrBreaksInTtmlTextContent: false,
                    senderDisconnectedDestroy: false,
                    shakaWacka: false,
                    timelineManager: {
                        useEPGRestrictions: false,
                        seekableRangeUpdateTolerance: 10
                    },
                    tracking: {
                        trackers: {
                            console: false
                        }
                    },
                    ui: {
                        adCounter: false,
                        injectDefaultStyling: true
                    },
                    validationEvents: false,
                    wacka: false
                },
                logoUrl: null,
                messageNamespace: 'urn:x-cast:application.messaging',
                minimumFirmwareVersion: '1.0.0',
                reloadDelay: 5000,
                senderDisconnectedDestroyDelay: 2000,
                shakaUrl: null,
                terminateDelay: 5000,
                unifiedMessageNamespace: 'urn:x-cast:unified.messaging'
            },
            serviceLayer: {}
        }, args);
    }
}

module.exports = ReceiverConfig;