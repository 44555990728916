const EventEmitter = require('eventemitter3');
const Utils = require('../utils/Utils');

module.exports = class ListenableListener extends EventEmitter {
    constructor() {
        super();

        this.destroyed = false;
    }

    /** @private */
    ensureNotDestroyed(listener) {
        return (...args) => {
            if (this.destroyed) return;
            return listener(...args);
        };
    }

    /** @protected */
    listen(target, listenerCollection, event, listener) {
        if (!(target && listenerCollection && event && listener)) return;

        const listenerFunction = Utils.isFunction(target.on) ? 'on' : 'addEventListener';

        target[listenerFunction](event, listenerCollection[event] = this.ensureNotDestroyed(listener));
    }

    /** @protected */
    unlisten(target, listenerCollection, event) {
        if (!(target && listenerCollection)) return;

        const unlistenerFunction = Utils.isFunction(target.off) ? 'off' : 'removeEventListener';
        if (event && listenerCollection[event]) {
            target[unlistenerFunction](event, listenerCollection[event]);

            delete listenerCollection[event];
        } else if (!event) {
            Object.keys(listenerCollection).forEach(e =>
              target[unlistenerFunction](e,
                listenerCollection[e]
              )
            );
            listenerCollection = {};
        }
    }
};