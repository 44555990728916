
var exports;
"undefined" != typeof module && module.hasOwnProperty("exports") ? exports = module.exports : void 0 !== exports && Object.defineProperty(exports, "__esModule", { value: !0 });
var ClassHelper = function () {
  var s, r;

  function g() {
  }

  function d() {
  }

  function c(e) {
    var t, i, n, s, r, a = "function" == typeof e ? e.$super : e.callee.$super;
    if (a) if (1 == arguments.length) r = a.call(this); else {
      for (i = arguments.length - 1, t = new Array(i), n = 1, s = 0; s < i;) t[s++] = arguments[n++];
      r = a.apply(this, t)
    }
    return r
  }

  function p(e) {
    var t, i = [], n = 0;
    for (t in e) i[n++] = t;
    return s && void 0 !== e.toString && (i[n++] = "toString"), r && void 0 !== e.valueOf && (i[n++] = "valueOf"), i
  }

  return function () {
    var e;
    for (e in s = r = !0, { toString: !0, valueOf: !0 }) "toString" == e && (s = !1), "valueOf" == e && (r = !1)
  }(), {
    makeClass: function () {
      var e, t, i, n, s, r, a, o, l, h;

      function u() {
        this.initialize.apply(this, arguments)
      }

      for ("function" == typeof arguments[t = 0] && arguments[t]._isConstructor && (e = arguments[t++]), (h = e) && (g.prototype = h.prototype, u.prototype = new g, g.prototype = {}), (u.prototype.constructor = u)._isConstructor = !0, i = u; t < arguments.length;) for ("function" == typeof (n = arguments[t++]) && (n = n()), r = (s = p(n)).length - 1; 0 <= r; --r) o = n[a = s[r]], e && "function" == typeof o && !o._isMixinFunction && "function" == typeof (l = e.prototype[a]) && (o.$super = l), i.prototype[a] = o;
      return "initialize" in i.prototype || (i.prototype.initialize = d), i.prototype.callSuper = c, i
    }, makeMixin: function () {
      for (var e, t, i, n = {}, s = 0; s < arguments.length;) for ("function" == typeof (e = arguments[s++]) && (e = e()), t = p(e), nameIndex = t.length - 1; 0 <= nameIndex; --nameIndex) name = t[nameIndex], "function" == typeof (i = e[name]) && (i._isMixinFunction = !0), n[name] = i;
      return n
    }
  }
}(), YSURL = ClassHelper.makeClass({
  initialize: function (e) {
    this._source = e, this._scheme = "", this._host = "", this._username = "", this._password = "", this._port = -1, this._path = "", this._query = "", this._fragment = "", this._parse()
  }, auth: function () {
    var e = "";
    return "" !== this.userinfo() && (e += this.userinfo() + "@"), e += this.host(), "" !== this.host() && -1 < this.port() && (e += ":" + this.port()), e
  }, fragment: function () {
    return this._fragment
  }, host: function () {
    return this._host
  }, path: function () {
    return this._path
  }, port: function () {
    return this._port
  }, scheme: function () {
    return this._scheme
  }, source: function () {
    return this._source
  }, userinfo: function () {
    if (!this._username) return "";
    var e = "";
    return e += this._username, e += ":" + this._password
  }, query: function () {
    return this._query
  }, queryByName: function (e) {
    if (0 < this._query.length) {
      var t = this._query.split("&");
      if (0 < t.length) for (var i = 0; i < t.length; i++) {
        var n = t[i].split("=");
        if (0 < n.length && n[0] === e) return 1 < n.length ? n[1] : ""
      }
    }
    return null
  }, addQueryParameter: function (e, t, i) {
    if (null === this.queryByName(e)) 0 < this.query().length && (this._query += "&"), this._query += e + "=" + t; else if (i) {
      var n = !1, s = this._query.split("&");
      if (0 < s.length) {
        for (var r = 0; r < s.length; r++) {
          var a = s[r].split("=");
          0 < a.length && a[0] === e && (n = !0, 1 < a.length ? a[1] = t : a.push(t), s[r] = a.join("="))
        }
        n && (this._query = s.join("&"))
      }
    }
  }, toString: function () {
    var e = "";
    return this.scheme() && (e += this.scheme() + ":"), this.auth() && (e += "//" + this.auth()), "" === this.auth() && "file" == this.scheme() && (e += "//"), e += this.path(), "" !== this.query() && (e += "?" + this.query()), "" !== this.fragment() && (e += "#" + this.fragment()), e
  }, _parse: function () {
    var e = this._source;
    if (0 === e.length) throw new Error("Invalid URL supplied to YSURL");
    var t = new RegExp("^(([^:/?#]+):)?(//([^/?#]*))?([^?#]*)([?]([^#]*))?(#(.*))?").exec(e);
    if (t[1] && t[2] && (this._scheme = t[2]), t[3]) {
      var i, n = t[4], s = "";
      if (-1 < n.indexOf("@") ? (i = n.split("@")[0], s = n.split("@")[1], -1 != i.indexOf(":") ? (this._username = i.split(":")[0], this._password = i.split(":")[1]) : this._username = i) : s = n, -1 < s.indexOf(":")) {
        for (var r = s.split(":")[1], a = !0, o = 0; o < r.length; o++) {
          var l = r.charAt(o);
          if (l < "0" || "9" < l) {
            a = !1;
            break
          }
        }
        a && (s = s.split(":")[0], r && 0 < r.length && (this._port = parseInt(r, 10)))
      }
      this._host = s
    }
    t[5] && (this._path = t[5]), t[6] && (this._query = t[7]), t[8] && (this._fragment = t[9])
  }
});
YSURL.Base64Encode = function (e) {
  if (/([^\u0000-\u00ff])/.test(e)) return e;
  var t, i, n, s, r, a = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=", o = [], l = "",
    h = e.length % 3;
  if (0 < h) for (; h++ < 3;) l += "=", e += "\0";
  for (h = 0; h < e.length; h += 3) i = (t = e.charCodeAt(h) << 16 | e.charCodeAt(h + 1) << 8 | e.charCodeAt(h + 2)) >> 18 & 63, n = t >> 12 & 63, s = t >> 6 & 63, r = 63 & t, o[h / 3] = a.charAt(i) + a.charAt(n) + a.charAt(s) + a.charAt(r);
  return e = (e = o.join("")).slice(0, e.length - l.length) + l
}, YSURL._r = /\\/g;
var ProtoAjax = {
  Browser: function () {
    if ("undefined" == typeof navigator || "undefined" == typeof window) return {
      IE: !1,
      Opera: !1,
      WebKit: !1,
      Gecko: !1,
      MobileSafari: !1
    };
    var e = navigator.userAgent, t = "[object Opera]" == Object.prototype.toString.call(window.opera);
    return {
      IE: e && !!window.attachEvent && !t,
      Opera: t,
      WebKit: e && -1 < e.indexOf("AppleWebKit/"),
      Gecko: e && -1 < e.indexOf("Gecko") && -1 === e.indexOf("KHTML"),
      MobileSafari: e && /Apple.*Mobile/.test(e)
    }
  }(), getTransport: function () {
    if ("undefined" != typeof XMLHttpRequest) return new XMLHttpRequest;
    for (var e, t = ["MSXML2.XmlHttp.6.0", "MSXML2.XmlHttp.5.0", "MSXML2.XmlHttp.4.0", "MSXML2.XmlHttp.3.0", "MSXML2.XmlHttp.2.0", "Microsoft.XmlHttp"], i = 0; i < t.length; i++) try {
      e = new ActiveXObject(t[i]);
      break
    } catch (e) {
    }
    return e
  }, activeRequestCount: 0
};
ProtoAjax.Base = ClassHelper.makeClass({
  initialize: function (e) {
    for (var t in this.options = {
      method: "post",
      asynchronous: !0,
      contentType: "application/x-www-form-urlencoded",
      encoding: "UTF-8",
      parameters: ""
    }, e) e.hasOwnProperty(t) && (this.options[t] = e[t]);
    this.options.method = this.options.method.toLowerCase()
  }
}), ProtoAjax.Request = ClassHelper.makeClass(ProtoAjax.Base, {
  _complete: !1, initialize: function (e, t) {
    this.callSuper(this.initialize, t), this.transport = ProtoAjax.getTransport(), this.request(e)
  }, request: function (e) {
    this.url = e, this.method = this.options.method;
    var t = this.options.parameters;
    "get" !== this.method && "post" !== this.method && (t += (t ? "&" : "") + "_method=" + this.method, this.method = "post"), t && "get" === this.method && (this.url += (this.url.include("?") ? "&" : "?") + t), this.parameters = t;
    try {
      var i = new ProtoAjax.Response(this);
      this.options.onCreate && this.options.onCreate(i), this.transport.open(this.method.toUpperCase(), this.url, this.options.asynchronous), this.options.asynchronous && this.defer(this.respondToReadyState.bind(this, 1)), this.transport.onreadystatechange = this.onStateChange.bind(this), this.setRequestHeaders(), this.body = "post" == this.method ? this.options.postBody || t : null, this.transport.send(this.body), !this.options.asynchronous && this.transport.overrideMimeType && this.onStateChange()
    } catch (e) {
    }
  }, update: function (e, t) {
    for (var i = e.length, n = t.length; n--;) e[i + n] = t[n];
    return e
  }, delay: function (e) {
    var t = this, i = Array.prototype.slice.call(arguments, 1);
    return e *= 1e3, setTimeout(function () {
      return t.apply(t, i)
    }, e)
  }, defer: function (e) {
    var t = this.update([.01], arguments);
    return this.delay.apply(e, t)
  }, onStateChange: function () {
    var e = this.transport.readyState;
    1 < e && (4 != e || !this._complete) && this.respondToReadyState(this.transport.readyState)
  }, setRequestHeaders: function () {
    var t = { Accept: "text/javascript, text/html, application/xml, text/xml, */*" };
    if ("post" == this.method && (t["Content-type"] = this.options.contentType + (this.options.encoding ? "; charset=" + this.options.encoding : ""), this.transport.overrideMimeType && (navigator.userAgent.match(/Gecko\/(\d{4})/) || [0, 2005])[1] < 2005 && (t.Connection = "close")), "object" == typeof this.options.requestHeaders) {
      var e = this.options.requestHeaders;
      if (Object.isFunction(e.push)) for (var i = 0, n = e.length; i < n; i += 2) t[e[i]] = e[i + 1]; else $H(e).each(function (e) {
        t[e.key] = e.value
      })
    }
    for (var s in t) null != t[s] && this.transport.setRequestHeader(s, t[s])
  }, success: function () {
    var e = this.getStatus();
    return !e || 200 <= e && e < 300 || 304 == e
  }, getStatus: function () {
    try {
      return 1223 === this.transport.status ? 204 : this.transport.status || 0
    } catch (e) {
      return 0
    }
  }, respondToReadyState: function (e) {
    var t = ProtoAjax.Request.Events[e], i = new ProtoAjax.Response(this);
    if ("Complete" == t) try {
      this._complete = !0, (this.options["on" + i.status] || this.options["on" + (this.success() ? "Success" : "Failure")] || function () {
      })(i)
    } catch (e) {
      Debugger.print("Error handling state: " + e)
    }
    try {
      (this.options["on" + t] || function () {
      })(i)
    } catch (e) {
    }
    "Complete" == t && (this.transport.onreadystatechange = function () {
    })
  }, getHeader: function (e) {
    try {
      return this.transport.getResponseHeader(e) || null
    } catch (e) {
      return null
    }
  }, evalResponse: function () {
    try {
      return eval((this.transport.responseText || "").unfilterJSON())
    } catch (e) {
    }
  }
}), ProtoAjax.Request.Events = ["Uninitialized", "Loading", "Loaded", "Interactive", "Complete"], ProtoAjax.Response = ClassHelper.makeClass({
  initialize: function (e) {
    this.request = e;
    var t, i = this.transport = e.transport, n = this.readyState = i.readyState;
    (2 < n && !ProtoAjax.Browser.IE || 4 == n) && (this.status = this.getStatus(), this.statusText = this.getStatusText(), this.responseText = null === i.responseText ? "" : String(i.responseText)), 4 == n && (t = i.responseXML, this.responseXML = void 0 === t ? null : t)
  }, status: 0, statusText: "", getStatus: ProtoAjax.Request.prototype.getStatus, getStatusText: function () {
    try {
      return this.transport.statusText || ""
    } catch (e) {
      return ""
    }
  }, getHeader: ProtoAjax.Request.prototype.getHeader, getAllHeaders: function () {
    try {
      return this.getAllResponseHeaders()
    } catch (e) {
      return null
    }
  }, getResponseHeader: function (e) {
    return this.transport.getResponseHeader(e)
  }, getAllResponseHeaders: function () {
    return this.transport.getAllResponseHeaders()
  }
}), ProtoAjax.DELEGATE = null;
var VMAPNS = "http://www.iab.net/videosuite/vmap", YOSNS = "http://www.yospace.com/extension",
  TAG_VMAP_AD_BREAK = "AdBreak", ATTR_AD_BREAK_START = "timeOffset", ATTR_AD_BREAK_TYPE = "breakType",
  ATTR_AD_BREAK_ID = "breakId", TAG_AD_SOURCE = "AdSource", TAG_VMAP_TRACKING_EVENTS = "TrackingEvents",
  TAG_VMAP_EXTENSIONS = "Extensions", TAG_VMAP_EXTENSION = "Extension",
  TAG_YOEXT_ADBREAK = "http://www.yospace.com/extension/adbreak",
  TAG_YOEXT_STREAM = "http://www.yospace.com/extension/stream", TAG_VAST_AD_DATA = "VASTAdData",
  TAG_YO_STREAM = "Stream", ATTR_BREAK_POSITION = "position", ATTR_STREAM_DURATION = "duration",
  ATTR_STREAM_PDTSTART = "pdtstart", ATTR_STREAM_PDTEND = "pdtend", ATTR_URL_DOMAIN = "urlDomain",
  ATTR_URL_SUFFIX = "urlSuffix", TAG_AD = "Ad", TAG_AD_EXTENSIONS = "Extensions", TAG_AD_EXTENSION = "Extension",
  TAG_INLINE = "InLine", TAG_INLINE_ADSYSTEM = "AdSystem", TAG_INLINE_VERSION = "version",
  TAG_INLINE_ADTITLE = "AdTitle", TAG_INLINE_DESCRIPTION = "Description", TAG_INLINE_ADVERTISER = "Advertiser",
  TAG_INLINE_SURVEY = "Survey", TAG_CREATIVES = "Creatives", TAG_CREATIVE = "Creative", TAG_IMPRESSION = "Impression",
  TAG_VAST_TRACKING = "Tracking", TAG_VAST_TRACKINGEVENTS = "TrackingEvents", TAG_NONLINEARADS = "NonLinearAds",
  TAG_STATICRESOURCE = "StaticResource", TAG_IFRAMERESOURCE = "IFrameResource",
  TAG_NONLINEARCLICKTHROUGH = "NonLinearClickThrough", TAG_LINEAR = "Linear", TAG_COMPANIONADS = "CompanionAds",
  TAG_CLICKTHROUGH = "ClickThrough", TAG_CLICKTRACKING = "ClickTracking", TAG_HTMLRESOURCE = "HTMLResource",
  TAG_EXTENSIONS = "CreativeExtensions", TAG_EXTENSION = "CreativeExtension",
  ANALYTICS_TOKEN = "#EXT-X-YOSPACE-ANALYTICS-URL", PAUSE_TOKEN = "#EXT-X-YOSPACE-PAUSE",
  X_STREAM_TOKEN = "#EXT-X-STREAM-INF", YSParseUtils = {
    NAMESPACES: !1, NS_SEPARATOR: ":", timecodeToString: function (e) {
      var t = "0" + e % 60;
      return (("0" + parseInt(e / 3600, 10) + ":" + ("0" + parseInt(e % 3600 / 60, 10)) + ":" + t).replace(/(^|:|\.)0(\d{2})/g, "$1$2") + ".000".substr(-1 === t.indexOf(".") ? 0 : 1)).substr(0, 12)
    }, timecodeFromString: function (e) {
      return -1 === e.indexOf(":") ? e : 3600 * parseInt(e.substr(0, 2), 10) + 60 * parseInt(e.substr(3, 2), 10) + parseFloat(e.substr(6), 10)
    }, getDOMElements: function (e, t, i, n) {
      if (!1 !== YSParseUtils.NAMESPACES && void 0 !== e.getElementsByTagNameNS) return e.getElementsByTagNameNS(i, n);
      var s = ("" === t ? "" : t + YSParseUtils.NS_SEPARATOR) + n;
      return e.getElementsByTagName(s)
    }
  }, TrackingEvents = ClassHelper.makeClass({
    initialize: function (e, t) {
      if (this.events = {}, this.suppressedCalls = [], this.isSuppressed = !1, this.ad = t, this.nsPrefix = "", null !== e) {
        var i = e.tagName.indexOf(TAG_VAST_TRACKINGEVENTS);
        if (-1 === i) {
          if (1 !== (e = e.getElementsByTagName(TAG_VAST_TRACKINGEVENTS)).length) return;
          i = (e = e.item(0)).tagName.indexOf(TAG_VAST_TRACKINGEVENTS)
        }
        0 < i && (this.nsPrefix = e.tagName.substr(0, i - 1));
        for (var n = YSParseUtils.getDOMElements(e, this.nsPrefix, "*", TAG_VAST_TRACKING), s = 0; s < n.length; s++) {
          var r, a, o = n.item(s).getAttribute("event");
          o && (r = null, "progress" === o && (o += "-" + (r = n.item(s).getAttribute("offset"))), a = {
            url: n.item(s).textContent.replace(/\s+/g, ""),
            offset: r,
            event: o,
            expired: !1
          }, Debugger.print("Adding tracking for event: " + o), this.events[o] = this.events[o] || [], this.events[o].push(a))
        }
      }
    }, Destroy: function () {
      for (var e in this.suppressedCalls = null, this.events) this.events.hasOwnProperty(e) && delete this.events[e];
      this.events = null
    }, addClickTracking: function (e) {
      this.addTracking("click", e)
    }, addTracking: function (e, t) {
      var i = { url: t, event: e, offset: null, expired: !1 };
      this.events[e] = this.events[e] || [], this.events[e].push(i)
    }, getEventsOfType: function (e) {
      var t = [], i = -1 < e.indexOf("progress");
      for (var n in this.events) this.events.hasOwnProperty(n) && (-1 < e.indexOf(n) || i && 0 === n.indexOf("progress-")) && (t = t.concat(this.events[n]));
      return t
    }, suppressAnalytics: function (e) {
      return e ? this.isSuppressed || (this.suppressedCalls = [], this.isSuppressed = !0) : this.isSuppressed = !1, this.suppressedCalls
    }, fire: function (e, t) {
      for (var i, n, s = TrackingEvents.ACTION_EVENTS.length, r = !1, a = 0; a < s; a++) if (TrackingEvents.ACTION_EVENTS[a] === t.event) {
        r = !0;
        break
      }
      r ? Debugger.print("Is an action-based event") : (Debugger.print("Not an action-based event"), t.expired = !0), this.isSuppressed ? (this.suppressedCalls.push({
        event: t.event,
        url: e
      }), Debugger.print(" ** SUPPRESSING CALL ** Suppressed length: " + this.suppressedCalls.length)) : 0 < e.length && (i = new Image, n = new YSURL(e), !0 === TrackingEvents.FORCE_HTTPS && (n._scheme = "https"), i.src = n.toString())
    }, track: function (e, t) {
      this.ad ? Debugger.print("Tracking " + e + " in " + this.ad.id) : Debugger.print("Tracking " + e + " at global level.");
      var i = [];
      this.events[e] && 0 !== this.events[e].length ? i = [].concat(this.events[e]) : Debugger.print("No specific event to be tracked!");
      var n = {};
      for (var s in t) t.hasOwnProperty(s) && (n["[" + s + "]"] = encodeURIComponent(t[s]));
      if ("creativeView" === e) if (null === this.ad || this.ad.hasSentImpression()) null === this.ad ? Debugger.print(" *** NO ADVERT FOR FIRING IMPRESSION") : Debugger.print(" *** IMPRESSION ALREADY SENT"); else for (this.ad.impressionSent(), Debugger.print(" -=-> Ad Impression"), r = this.ad.impressions.length - 1; 0 <= r; r--) i.unshift({
        url: this.ad.impressions[r],
        expired: !1,
        event: "impression"
      });
      for (var r = 0; r < i.length; r++) {
        for (var a = i[r], o = a.url, l = String(parseInt(99999999 * Math.random(), 10)); 8 !== l.length;) l = "0" + l;
        for (s in n["[CACHEBUSTING]"] = l, n) n.hasOwnProperty(s) && (o = o.replace(s, n[s]));
        !1 === a.expired && (Debugger.print("Firing tracking of: " + o), this.fire(o, a))
      }
    }, progressTrack: function (e, t, i) {
      var n = [];
      for (var s in this.events) {
        var r = this.events[s];
        if (0 < r.length) for (var a, o, l = 0; l < r.length; l++) {
          "progress-" !== (g = r[l]).event.substr(0, 9) || (-1 !== (a = g.event.substr(9)).indexOf("%") ? (o = a.substr(0, a.length - 1), parseFloat(o) * t / 100) : YSParseUtils.timecodeFromString(a)) <= i && n.push(g)
        }
      }
      if (0 < n.length) {
        var h = {};
        for (var u in e) e.hasOwnProperty(u) && (h["[" + u + "]"] = encodeURIComponent(e[u]));
        for (s = 0; s < n.length; s++) {
          for (var g, d = (g = n[s]).url, c = String(parseInt(99999999 * Math.random(), 10)); 8 !== c.length;) c = "0" + c;
          for (u in h["[CACHEBUSTING]"] = c, h) h.hasOwnProperty(u) && (d = d.replace(u, h[u]));
          !1 === g.expired && (Debugger.print("Firing timed tracking of: " + d), this.fire(d, g))
        }
      }
    }
  });
TrackingEvents.FORCE_HTTPS = !1, TrackingEvents.ACTION_EVENTS = ["mute", "unmute", "pause", "resume", "fullscreen", "exitFullscreen", "expand", "collapse", "click"];
var YoExtension = ClassHelper.makeClass({
  initialize: function (e) {
    this.isValid = !1, this.extensionXml = e
  }, Destroy: function () {
    this.extensionXml = null
  }, getRaw: function () {
    return this.extensionXml
  }, getTypeName: function () {
    return "YoExtension"
  }
}), YoStream = ClassHelper.makeClass(YoExtension, {
  initialize: function (e) {
    this.callSuper(this.initialize, e), this.isValid = !0, this.StartPDT = null, this.EndPDT = null, Debugger.print("[1/4] Extracting STREAM extension...");
    var t = YSParseUtils.getDOMElements(e, "yospace", YOSNS, TAG_YO_STREAM);
    if (1 == t.length) {
      var i = t.item(0);
      if (Debugger.print("[2/4] Validating STREAM tagname..."), i.tagName === "yospace" + YSParseUtils.NS_SEPARATOR + TAG_YO_STREAM) {
        if (Debugger.print("[3/4] Checking for STREAM duration attribute..."), !i.hasAttribute(ATTR_STREAM_DURATION)) return Debugger.print("[3/4] STREAM duration attribute not found..."), void (this.isValid = !1);
        this.duration = i.getAttribute(ATTR_STREAM_DURATION), Debugger.print("[3/4] Found STREAM duration... " + this.duration), Debugger.print("[4/4] Extracting misc STREAM attributes..."), i.hasAttribute(ATTR_STREAM_PDTSTART) && (this.StartPDT = i.getAttribute(ATTR_STREAM_PDTSTART)), i.hasAttribute(ATTR_STREAM_PDTEND) && (this.EndPDT = i.getAttribute(ATTR_STREAM_PDTEND)), this.urlDomain = i.getAttribute(ATTR_URL_DOMAIN), this.urlSuffix = i.getAttribute(ATTR_URL_SUFFIX), this.id = i.getAttribute("id")
      }
      else Debugger.print("Invalid tag name for yospace" + YSParseUtils.NS_SEPARATOR + TAG_YO_STREAM), this.isValid = !1
    }
    else Debugger.print("[1/4] STREAM: No firstElementChild was returned..."), this.isValid = !1;
    Debugger.print("[-/-] STREAM extension parsed. Valid? ... " + (this.isValid ? "YES" : "NO"))
  }, Destroy: function () {
    this.callSuper(this.Destroy)
  }, getTypeName: function () {
    return "YoStream"
  }
}), YoBreak = ClassHelper.makeClass(YoExtension, {
  initialize: function (e) {
    var t, i;
    e && (this.callSuper(this.initialize, e), this.isValid = !0, 1 == (t = YSParseUtils.getDOMElements(e, "yospace", YOSNS, TAG_VMAP_AD_BREAK)).length ? (i = t.item(0), this.duration = i.getAttribute(ATTR_STREAM_DURATION), this.placement = i.getAttribute(ATTR_BREAK_POSITION) || "unknown") : (Debugger.print("[1/4] BREAK: No firstElementChild was returned..."), this.isValid = !1))
  }, Destroy: function () {
    this.callSuper(this.Destroy)
  }, getTypeName: function () {
    return "YoBreak"
  }
}), Extensions = ClassHelper.makeClass({
  initialize: function (e) {
    if (this.extensions = [], e) {
      var t = YSParseUtils.getDOMElements(e, "vmap", VMAPNS, TAG_VMAP_EXTENSION);
      if (t.length) for (var i = 0; i < t.length; i++) {
        var n, s = t.item(i), r = s.getAttribute("type");
        r === TAG_YOEXT_ADBREAK ? (n = new YoBreak(s), this.extensions.push(n), Debugger.print("Found BREAK extension in VMAP")) : r === TAG_YOEXT_STREAM ? (n = new YoStream(s), this.extensions.push(n), Debugger.print("Found STREAM extension in VMAP")) : Debugger.print("Unhandled Extension Type: " + r)
      } else Debugger.print("Empty Extensions section")
    }
    else Debugger.print("Adjustment/Extraction failed for VMAP extensions")
  }, Destroy: function () {
    for (; 0 < this.extensions.length;) {
      this.extensions.pop().Destroy()
    }
    this.extensions = null
  }, getFirstOfType: function (e) {
    return this.getNextOfType(e, null)
  }, getNextOfType: function (e, t) {
    var i = null, n = this.getClassForType(e), s = !1;
    if (null !== n) for (var r = 0; r < this.extensions.length; r++) if (this.extensions[r].getTypeName() == n) {
      if (null === t || !0 === s) {
        i = this.extensions[r];
        break
      }
      this.extensions[r] === t && (s = !0)
    }
    return i
  }, getAllOfType: function (e) {
    var t = [], i = this.getClassForType(e);
    if (null !== i) for (var n = 0; n < this.extensions.length; n++) this.extensions[n].getTypeName() == i && t.push(this.extensions[n]);
    return t
  }, getClassForType: function (e) {
    switch (e) {
      case TAG_YOEXT_ADBREAK:
        return "YoBreak";
      case TAG_YOEXT_STREAM:
        return "YoStream"
    }
    return null
  }
}), VASTIcon = ClassHelper.makeClass({
  initialize: function (e, t, i) {
    var n, s;
    for (this.id = i, this.linear = e, this.clickThrough = null, this.resources = {
      html: null,
      iframe: null,
      images: {}
    }, 0 < (s = t.getElementsByTagName(TAG_HTMLRESOURCE)).length && (this.resources.html = s.item(0).textContent.replace(/\s+/g, "")), 0 < (s = t.getElementsByTagName(TAG_IFRAMERESOURCE)).length && (this.resources.iframe = s.item(0).textContent.replace(/\s+/g, "")), s = t.getElementsByTagName(TAG_STATICRESOURCE), n = 0; n < s.length; n++) this.resources.images[s.item(n).getAttribute("creativeType")] = s.item(n).textContent.replace(/\s+/g, "");
    this.tracking = e.tracking;
    var r = t.getElementsByTagName("IconClicks");
    if (r.length) {
      var a = (r = r.item(0)).getElementsByTagName("IconClickThrough");
      if (a.length && (this.clickThrough = a.item(0).textContent.replace(/\s+/g, "")), (a = r.getElementsByTagName("IconClickTracking")).length) for (n = 0; n < a.length; n++) Debugger.print("Adding Icon Click Tracking: " + n), this.tracking.addTracking("IconClick_" + i, a.item(n).textContent.replace(/\s+/g, ""))
    }
    var o = t.getElementsByTagName("IconViewTracking");
    if (o.length) for (n = 0; n < o.length; n++) this.tracking.addTracking("IconView_" + i, o.item(n).textContent.replace(/\s+/g, ""))
  }, Destroy: function () {
    this.resources = null, this.linear = null, this.tracking = null
  }, getAllResources: function () {
    return this.resources
  }
}), VASTInteractive = ClassHelper.makeClass({
  initialize: function (e, t) {
    for (var i in Debugger.print("Constructing VPAID Unit"), this.width = -1, this.height = -1, this.id = "", this.scalable = !1, this.type = "", this.maintainAspectRatio = !1, this.src = "", this.linear = e, this.bitrate = -1, this.parameters = "", t) if (t.hasOwnProperty(i)) {
      var n = t[i].replace(/\s+/g, "");
      switch (i.toLowerCase()) {
        case"height":
        case"width":
        case"bitrate":
          this[i.toLowerCase()] = parseInt(n, 10);
          break;
        case"id":
        case"type":
          this[i.toLowerCase()] = n;
          break;
        case"maintainaspectratio":
          this.maintainAspectRatio = "true" === n.toLowerCase();
          break;
        case"scalable":
          this.scalable = "true" === n.toLowerCase();
          break;
        case"src":
          this.src = n;
          break;
        default:
          Debugger.print("Unknown attribute: " + i + " with value: " + n)
      }
    }
    var s = e.root.getElementsByTagName("AdParameters");
    1 === s.length && (this.parameters = s.item(0).textContent), this.tracking = new TrackingEvents(e.root, e.vastAd)
  }, Destroy: function () {
    this.resources = null, this.linear = null, this.tracking.Destroy(), this.tracking = null
  }, track: function (e, t, i, n) {
    Debugger.print(" VPAID: Invoke Tracking: " + e), this.tracking.track(e, {
      CONTENTPLAYHEAD: YSParseUtils.timecodeToString(t),
      ASSETURI: i,
      "YO:ACTUAL_DURATION": n
    })
  }
}), VASTCreative = ClassHelper.makeClass({
  initialize: function (e, t, i, n) {
    this.vastAd = e, this.root = t, this.clickThrough = null, this.AdID = i, this.CreativeID = n, this.CreativeExtensions = [];
    var s = t.getElementsByTagName(TAG_EXTENSIONS);
    if (0 < s.length) for (var r = 0; r < s.length; r++) {
      var a = s.item(r).getElementsByTagName(TAG_EXTENSION);
      if (0 < a.length) for (var o = 0; o < a.length; o++) this.CreativeExtensions.push(a.item(o))
    }
    "NonLinear" === t.tagName && (t = t.parentNode), this.tracking = new TrackingEvents(t, e)
  }, Destroy: function () {
    this.root = null, this.CreativeExtensions = null, this.tracking && (this.tracking.Destroy(), this.tracking = null)
  }, track: function (e, t, i, n) {
    this.tracking.track(e, {
      CONTENTPLAYHEAD: YSParseUtils.timecodeToString(t),
      ASSETURI: i,
      "YO:ACTUAL_DURATION": n
    }), this.tracking.progressTrack({
      CONTENTPLAYHEAD: YSParseUtils.timecodeToString(t),
      ASSETURI: i,
      "YO:ACTUAL_DURATION": n
    }, this.getDuration(), t)
  }, trackProgress: function (e, t, i) {
    this.tracking.progressTrack({
      CONTENTPLAYHEAD: YSParseUtils.timecodeToString(e),
      ASSETURI: t,
      "YO:ACTUAL_DURATION": i
    }, this.duration, e)
  }, getClickThrough: function () {
    if (this.clickThrough) {
      var e = new YSURL(this.clickThrough);
      return !0 === TrackingEvents.FORCE_HTTPS && (e._scheme = "https"), e.toString()
    }
    return null
  }, attribute: function (e, t) {
    if (!this.root.hasAttribute(e)) return t;
    var i = this.root.getAttribute(e);
    switch (e) {
      case"skipoffset":
      case"duration":
      case"offset":
      case"minSuggestedDuration":
        i = YSParseUtils.timecodeFromString(i)
    }
    return i
  }
}), VASTLinear = ClassHelper.makeClass(VASTCreative, {
  initialize: function (e, t, i, n) {
    this.callSuper(this.initialize, e, t, i, n), this.mediaFiles = [], this.duration = null, this.skipOffset = -1, this.icons = [], this.interactiveUnit = null;
    var s = t.getElementsByTagName("VideoClicks");
    if (s.length) {
      var r = (s = s.item(0)).getElementsByTagName(TAG_CLICKTHROUGH);
      if (r.length && (this.clickThrough = r.item(0).textContent.replace(/\s+/g, "")), (r = s.getElementsByTagName(TAG_CLICKTRACKING)).length) for (p = 0; p < r.length; p++) Debugger.print("Adding Click Tracking: " + p), this.tracking.addClickTracking(r.item(p).textContent.replace(/\s+/g, ""))
    }
    var a, o, l = t.getElementsByTagName("Duration");
    l.length && (this.duration = YSParseUtils.timecodeFromString(l.item(0).textContent.replace(/\s+/g, ""))), t.hasAttribute("skipoffset") && (0 <= (a = t.getAttribute("skipoffset").replace(/\s+/g, "")).indexOf("%") ? (o = this.duration * a.substring(0, a.length - 1) / 100, this.skipOffset = o) : this.skipOffset = YSParseUtils.timecodeFromString(a));
    var h = t.getElementsByTagName("MediaFiles");
    if (h.length) {
      for (h = h.item(0).getElementsByTagName("MediaFile"), p = 0; p < h.length; p++) {
        var u = {};
        if (void 0 !== (c = h.item(p)).attributes) for (var g = 0; g < c.attributes.length; g++) u[c.attributes.item(g).name] = c.attributes.item(g).value; else c.hasAttribute("id") && (u.id = c.getAttribute("id")), c.hasAttribute("bitrate") && (u.bitrate = c.getAttribute("bitrate")), c.hasAttribute("width") && (u.width = c.getAttribute("width")), c.hasAttribute("height") && (u.height = c.getAttribute("height"));
        u.src = h.item(p).textContent.replace(/\s+/g, ""), u.hasOwnProperty("apiFramework") && "VPAID" === u.apiFramework.toUpperCase() && (this.interactiveUnit = new VASTInteractive(this, u)), this.mediaFiles.push(u)
      }
      var d = t.getElementsByTagName("Icons");
      if (d.length && (d = d.item(0).getElementsByTagName("Icon")).length) for (var c, p = 0; p < d.length; p++) {
        "Icon" === (c = d.item(p)).tagName && this.icons.push(new VASTIcon(this, c, p))
      }
    }
  }, Destroy: function () {
    if (this.callSuper(this.Destroy), this.mediaFiles = null, this.interactiveUnit && (this.interactiveUnit.Destroy(), this.interactiveUnit = null), this.icons) {
      for (; 0 < this.icons.length;) {
        this.icons.pop().Destroy()
      }
      this.icons = null
    }
  }, getDuration: function () {
    return this.duration
  }, getSkipOffset: function () {
    return this.skipOffset
  }, getAllMedias: function () {
    return this.mediaFiles
  }, hasInteractiveUnit: function () {
    return null !== this.interactiveUnit
  }
}), VASTCompanion = ClassHelper.makeClass(VASTCreative, {
  initialize: function (e, t, i, n) {
    var s, r;
    for (this.callSuper(this.initialize, e, t, i, n), this.resources = {
      html: null,
      iframe: null,
      static: null
    }, 0 < (r = t.getElementsByTagName(TAG_HTMLRESOURCE)).length && (this.resources.html = r.item(0).textContent.replace(/^\s+|\s+$/gm, "")), 0 < (r = t.getElementsByTagName(TAG_IFRAMERESOURCE)).length && (this.resources.iframe = r.item(0).textContent.replace(/^\s+|\s+$/gm, "")), r = t.getElementsByTagName(TAG_STATICRESOURCE), s = 0; s < r.length; s++) this.resources.static = {
      creativeType: r.item(s).getAttribute("creativeType"),
      data: r.item(s).textContent.replace(/\s+/g, "")
    };
    var a = t.getElementsByTagName("CompanionClickTracking");
    if (a.length) for (s = 0; s < a.length; s++) this.tracking.addClickTracking(a.item(s).textContent.replace(/\s+/g, ""));
    (a = t.getElementsByTagName("CompanionClickThrough")).length && (this.clickThrough = a.item(0).textContent.replace(/\s+/g, "")), this.alttext = "", (a = t.getElementsByTagName("AltText")).length && (this.alttext = a.item(0).textContent.replace(/^\s+|\s+$/gm, "")), this.parameters = null, (a = t.getElementsByTagName("AdParameters")).length && (this.parameters = a.item(0)), this.attributes = {}, t.hasAttribute("width") && (this.attributes.width = t.getAttribute("width")), t.hasAttribute("height") && (this.attributes.height = t.getAttribute("height")), t.hasAttribute("id") && (this.attributes.id = t.getAttribute("id")), t.hasAttribute("expandedWidth") && (this.attributes.expandedWidth = t.getAttribute("expandedWidth")), t.hasAttribute("expandedHeight") && (this.attributes.expandedHeight = t.getAttribute("expandedHeight")), t.hasAttribute("assetWidth") && (this.attributes.assetWidth = t.getAttribute("assetWidth")), t.hasAttribute("assetHeight") && (this.attributes.assetHeight = t.getAttribute("assetHeight")), t.hasAttribute("adSlotID") && (this.attributes.adSlotID = t.getAttribute("adSlotID")), t.hasAttribute("apiFramework") && (this.attributes.apiFramework = t.getAttribute("apiFramework"))
  }, Destroy: function () {
    this.callSuper(this.Destroy), this.resources = null, this.attributes = null, this.parameters = null
  }, getAllResources: function () {
    return this.resources
  }, getClickThroughUrl: function () {
    return this.getClickThrough()
  }, getAltText: function () {
    return this.alttext
  }, getAdParameters: function () {
    return this.parameters
  }
}), VASTNonLinear = ClassHelper.makeClass(VASTCreative, {
  initialize: function (e, t, i, n) {
    var s, r;
    for (this.callSuper(this.initialize, e, t, i, n), this.resources = {
      html: null,
      iframe: null,
      images: {}
    }, 0 < (r = t.getElementsByTagName(TAG_HTMLRESOURCE)).length && (this.resources.html = r.item(0).textContent.replace(/^\s+|\s+$/gm, "")), 0 < (r = t.getElementsByTagName(TAG_IFRAMERESOURCE)).length && (this.resources.iframe = r.item(0).textContent.replace(/^\s+|\s+$/gm, "")), r = t.getElementsByTagName(TAG_STATICRESOURCE), s = 0; s < r.length; s++) this.resources.images[r.item(s).getAttribute("creativeType")] = r.item(s).textContent.replace(/\s+/g, "");
    this.tracking = e.nonLinearsTracking;
    var a = t.getElementsByTagName("NonLinearClickTracking");
    if (a.length) for (s = 0; s < a.length; s++) this.tracking.addClickTracking(a.item(s).textContent.replace(/\s+/g, ""));
    (a = t.getElementsByTagName(TAG_NONLINEARCLICKTHROUGH)).length && (this.clickThrough = a.item(0).textContent.replace(/\s+/g, "")), this.attributes = {}, t.hasAttribute("width") && (this.attributes.width = t.getAttribute("width")), t.hasAttribute("height") && (this.attributes.height = t.getAttribute("height")), t.hasAttribute("id") && (this.attributes.id = t.getAttribute("id")), t.hasAttribute("expandedWidth") && (this.attributes.expandedWidth = t.getAttribute("expandedWidth")), t.hasAttribute("expandedHeight") && (this.attributes.expandedHeight = t.getAttribute("expandedHeight")), t.hasAttribute("scalable") && (this.attributes.scalable = t.getAttribute("scalable")), t.hasAttribute("maintainAspectRatio") && (this.attributes.maintainAspectRatio = t.getAttribute("maintainAspectRatio")), t.hasAttribute("minSuggestedDuration") && (this.attributes.minSuggestedDuration = t.getAttribute("minSuggestedDuration")), t.hasAttribute("apiFramework") && (this.attributes.apiFramework = t.getAttribute("apiFramework"))
  }, Destroy: function () {
    this.callSuper(this.Destroy), this.resources = null, this.attributes = null
  }, getAllResources: function () {
    return this.resources
  }
}), VASTAd = ClassHelper.makeClass({
  initialize: function (e, t) {
    var i;
    this.container = null, this.vast = e, this.vastXML = t, this.id = "", this.AdTitle = "", this.Description = "", this.Advertiser = "", this.Survey = "", this.version = "", this.AdSystem = "", this.sequence = null, this.linear = null, this.nonLinears = [], this.companions = [], this.nonLinearsTracking = null, this.hasContent = !0, this.impressions = [], this.sentImpression = !1, this.Extensions = [], this.AdvertLineage = null, this.nonLinearsTracking = new TrackingEvents(null, this), t.hasAttribute("sequence") && (this.sequence = parseInt(t.getAttribute("sequence"), 10), Debugger.print(" *VASTAd* Extracted sequence: " + this.sequence)), t.hasAttribute("id") && (this.id = t.getAttribute("id"), Debugger.print(" *VASTAd* Extracted id: " + this.id));
    var n = t.getElementsByTagName(TAG_INLINE);
    if (0 === n.length) return Debugger.print(" *VASTAd* Found no inline element"), void (this.hasContent = !1);
    Debugger.print(" *VASTAd* Inline located. Count (should be 1): " + n.length);
    var s, r = (n = n.item(0)).getElementsByTagName(TAG_INLINE_ADSYSTEM);
    0 !== r.length && (Debugger.print(" *VASTAd* Extracted AdSystem. Count: " + r.length), s = r.item(0), this.AdSystem = s.textContent.replace(/\s+/g, ""), s.hasAttribute("version") && (this.version = s.getAttribute("version"), Debugger.print(" *VASTAd* Extracted AdSystem version: " + this.version))), 0 !== (r = n.getElementsByTagName(TAG_INLINE_ADTITLE)).length && (this.AdTitle = r.item(0).textContent.replace(/\s+/g, ""), Debugger.print(" *VASTAd* Extracted AdTitle: " + this.AdTitle)), 0 !== (r = n.getElementsByTagName(TAG_INLINE_DESCRIPTION)).length && (this.Description = r.item(0).textContent.replace(/\s+/g, ""), Debugger.print(" *VASTAd* Extracted Description: " + this.Description)), 0 !== (r = n.getElementsByTagName(TAG_INLINE_SURVEY)).length && (this.Survey = r.item(0).textContent.replace(/\s+/g, ""), Debugger.print(" *VASTAd* Extracted Survey: " + this.Survey)), 0 !== (r = n.getElementsByTagName(TAG_INLINE_ADVERTISER)).length && (this.Advertiser = r.item(0).textContent.replace(/\s+/g, ""), Debugger.print(" *VASTAd* Extracted Advertiser: " + this.Advertiser));
    var a = n.getElementsByTagName(TAG_IMPRESSION);
    for (Debugger.print(" *VASTAd* Extracted impressions. Count: " + a.length), i = 0; i < a.length; i++) this.impressions.push(a.item(i).textContent.replace(/\s+/g, "")), Debugger.print(" *VASTAd* Impression " + String(i + 1) + ". URL: " + a.item(i).textContent.replace(/\s+/g, ""));
    var o = t.getElementsByTagName(TAG_AD_EXTENSIONS);
    if (Debugger.print(" *VASTAd* Extracted Extensions. Count: " + o.length), 0 < o.length) for (i = 0; i < o.length; i++) {
      var l = o.item(i).getElementsByTagName(TAG_AD_EXTENSION);
      if (Debugger.print(" *VASTAd* For Extensions tag " + String(i + 1) + ", Extension count: " + l.length), 0 < l.length) for (m = 0; m < l.length; m++) if (Debugger.print(" *VASTAd* For Extensions tag " + String(i + 1) + ", Extension " + String(m + 1) + " have added item"), l.item(m).hasAttribute("type") && "com.yospace.wrapperhierarchy" === l.item(m).getAttribute("type")) for (var h, u = l.item(m).firstChild, g = null; null !== u;) {
        "AdWrapper" === u.tagName && (Debugger.print("Detected Hierarchy: " + u.getAttribute("id") + " / " + u.getAttribute("creativeId") + " / " + u.getAttribute("AdSystem")), h = new AdvertWrapper(u.getAttribute("id"), u.getAttribute("creativeId"), u.getAttribute("AdSystem")), null === g ? this.AdvertLineage = h : g.child = h, g = h), u = u.firstChild
      } else this.Extensions.push(l.item(m))
    }
    var d = n.getElementsByTagName(TAG_CREATIVES);
    if (Debugger.print(" *VASTAd* Extracted creatives tag. Count: " + d.length), 0 !== d.length) for (d = d.item(0).getElementsByTagName(TAG_CREATIVE), Debugger.print(" *VASTAd* Extracted creatives. Count: " + d.length), i = 0; i < d.length; i++) {
      var c = "", p = "";
      d.item(i).hasAttribute("AdID") && (c = d.item(i).getAttribute("AdID")), d.item(i).hasAttribute("id") && (p = d.item(i).getAttribute("id")), Debugger.print(" *VASTAd* For creatives " + String(i + 1) + ", AdID: " + c + ", CreativeID: " + p);
      for (var A, S = d.item(i).firstChild; null !== S && 3 === S.nodeType;) S = S.nextSibling;
      if (null !== S) switch (S.tagName) {
        case TAG_LINEAR:
          Debugger.print(" *VASTAd* For creatives " + String(i + 1) + ", Extracting Linear"), this.linear = new VASTLinear(this, S, c, p);
          break;
        case TAG_NONLINEARADS:
          Debugger.print(" *VASTAd* For creatives " + String(i + 1) + ", Extracting NonLinear");
          for (var f = S.tagName.replace("Ads", ""), v = S.getElementsByTagName(f), m = 0; m < v.length; m++) null !== (A = new VASTNonLinear(this, v.item(m), c, p)) && this.nonLinears.push(A);
          Debugger.print(" *VASTAd* For creatives " + String(i + 1) + ", NonLinear Count: " + this.nonLinears.length);
          break;
        case TAG_COMPANIONADS:
          Debugger.print(" *VASTAd* For creatives " + String(i + 1) + ", Extracting CompanionAds");
          f = S.tagName.replace("Ads", ""), v = S.getElementsByTagName(f);
          for (m = 0; m < v.length; m++) null !== (A = new VASTCompanion(this, v.item(m), c, p)) && this.companions.push(A);
          Debugger.print(" *VASTAd* For creatives " + String(i + 1) + ", Companion Count: " + this.companions.length)
      }
    }
  }, Destroy: function () {
    if (this.hasContent = !1, this.vast = null, this.vastXML = null, this.impressions = null, this.Extensions = null, this.container = null, this.AdvertLineage = null, this.linear && (this.linear.Destroy(), this.linear = null), this.nonLinearsTracking && (this.nonLinearsTracking.Destroy(), this.nonLinearsTracking = null), this.nonLinears) {
      for (; 0 < this.nonLinears.length;) {
        this.nonLinears.pop().Destroy()
      }
      this.nonLinears = null
    }
    if (this.companions) {
      for (; 0 < this.companions.length;) {
        this.companions.pop().Destroy()
      }
      this.companions = null
    }
  }, hasSentImpression: function () {
    return this.sentImpression
  }, impressionSent: function () {
    this.sentImpression = !0
  }, isNumber: function (e) {
    return this.sequence === e
  }, hasSequence: function () {
    return null !== this.sequence
  }, isEmpty: function () {
    return !this.hasContent
  }, getLinear: function () {
    return this.linear
  }, getNonLinears: function () {
    return this.nonLinears
  }, getCompanions: function () {
    return this.companions
  }
}), AdvertWrapper = ClassHelper.makeClass({
  initialize: function (e, t, i) {
    this.AdId = e, this.AdSystem = i, this.CreativeID = t, this.child = null
  }
}), VASTAds = ClassHelper.makeClass({
  initialize: function (e, t) {
    this.ads = [], this.onAdsAvailable = e, this.onAdsError = t, this.onReceivedErrorCounter = 0
  }, Destroy: function () {
    for (this.onAdsAvailable = null, this.onAdsError = null; 0 < this.ads.length;) {
      this.ads.pop().Destroy()
    }
    this.ads = null
  }, parse: function (e) {
    var t = YSParseUtils.getDOMElements(e, "", "*", TAG_AD), i = this;
    if (0 !== t.length) {
      function n() {
        i.onReceivedErrorCounter++, i.onReceivedErrorCounter === t.length && "function" == typeof this.onAdsError && this.onAdsError.call(this, "All Ads Failed")
      }

      for (var s = 0; s < t.length; s++) {
        var r = new VASTAd(this, t.item(s));
        r.isEmpty() ? (Debugger.print("Parsed an empty ad"), n()) : this.ads.push(r)
      }
      "function" == typeof this.onAdsAvailable && this.onAdsAvailable.call(this, this.ads)
    }
    else "function" == typeof this.onAdsAvailable && this.onAdsAvailable.call(this, [])
  }
}), AdBreak = ClassHelper.makeClass({
  initialize: function (e) {
    this.adSource = null, this.vast = null, this.tracking = null, this.extensions = null, this.isValid = !1, this.position = e.getAttribute(ATTR_AD_BREAK_START), this.type = e.getAttribute(ATTR_AD_BREAK_TYPE), this.id = e.getAttribute(ATTR_AD_BREAK_ID);
    var t, i, n = YSParseUtils.getDOMElements(e, "vmap", VMAPNS, TAG_AD_SOURCE);
    n.length ? (t = YSParseUtils.getDOMElements(n.item(0), "vmap", VMAPNS, TAG_VAST_AD_DATA)).length ? ((i = this).adSource = t.item(0).innerHTML, this.vast = new VASTAds(function (e) {
      Debugger.print("VAST: " + e.length), 0 < e.length && (i.isValid = !0)
    }, function () {
      Debugger.print("Vast Failed")
    }), this.vast.parse(t.item(0))) : Debugger.print("Not a VASTAdData tag") : Debugger.print("No AdSource section in AdBreak");
    var s = YSParseUtils.getDOMElements(e, "vmap", VMAPNS, TAG_VMAP_TRACKING_EVENTS);
    s.length ? (this.tracking = new TrackingEvents(s.item(0), null), this.isValid = !0) : Debugger.print("No TrackingEvents section in AdBreak");
    var r = YSParseUtils.getDOMElements(e, "vmap", VMAPNS, TAG_VMAP_EXTENSIONS);
    r.length ? this.extensions = new Extensions(r.item(0)) : Debugger.print("No Extensions section in AdBreak")
  }, Destroy: function () {
    this.adSource = null, this.vast && (this.vast.Destroy(), this.vast = null), this.tracking && (this.tracking.Destroy(), this.tracking = null), this.extensions && (this.extensions.Destroy(), this.extensions = null)
  }, getPosition: function () {
    if (!this.extensions) return "unknown";
    var e = this.extensions.getFirstOfType(TAG_YOEXT_ADBREAK);
    return e ? e.placement : "unknown"
  }
}), VMAPParser = ClassHelper.makeClass({
  initialize: function (e, t) {
    this.breaks = [], this.extensions = null, this.onSuccess = e, this.onFailure = t
  }, Destroy: function () {
    for (this.extensions && (this.extensions.Destroy(), this.extensions = null), this.onSuccess = null, this.onFailure = null; 0 < this.breaks.length;) {
      this.breaks.pop().Destroy()
    }
    this.breaks = null
  }, parse: function (e) {
    var t, i;
    if (null !== e) {
      if (9 == e.nodeType) {
        Debugger.print("Looks like VMAP document was provided. Stepping into root node");
        var n = YSParseUtils.getDOMElements(e, "vmap", VMAPNS, "VMAP");
        if (1 != n.length) return Debugger.print("VMAP root node count was not 1. This probably wont work: " + n.length), void ("function" == typeof this.onFailure && this.onFailure.call(this, "error"));
        e = n.item(0), Debugger.print("Located root node")
      }
      !0 !== YSSessionManager.DEFAULTS.AD_DEBUG || !0 !== YSSessionManager.DEFAULTS.DEBUGGING || null !== (i = e.parentNode.children.item(0).nextSibling) && i.nodeType == Node.COMMENT_NODE && (Debugger.print(" ************* AD-CALL DIAGNOSTICS ****************"), Debugger.print(i.nodeValue), Debugger.print(" ************* END DIAGNOSTICS ****************"));
      var s = YSParseUtils.getDOMElements(e, "vmap", VMAPNS, TAG_VMAP_AD_BREAK);
      if (s.length) for (t = 0; t < s.length; t++) {
        Debugger.print("Processing break: " + t);
        var r, a = s.item(t);
        void 0 !== a.parentNode && (a.parentNode == e || void 0 !== e.tagName && a.parentNode.tagName === e.tagName) ? (r = new AdBreak(a)).isValid ? this.breaks.push(r) : Debugger.print("Break not valid") : Debugger.print("Ignoring floating AdBreak")
      } else Debugger.print(" ** NO ADBREAK ELEMENTS LOCATED IN VMAP.");
      var o = YSParseUtils.getDOMElements(e, "vmap", VMAPNS, TAG_VMAP_EXTENSIONS);
      if (o.length) for (t = 0; t < o.length; t++) {
        var l = "function" == typeof o.item ? o.item(t) : o[t], h = void 0 !== l ? l.parentNode : void 0;
        if (void 0 !== h && (h == e || void 0 !== e.tagName && h.tagName === e.tagName)) {
          this.extensions = new Extensions(o.item(t));
          break
        }
        Debugger.print("Ignoring custom extension which is not top-level")
      } else Debugger.print(" ** NO EXTENSIONS LOCATED IN VMAP.");
      var u = !0;
      if (null !== this.extensions && null !== this.extensions.extensions) {
        for (t = 0; t < this.extensions.extensions.length; t++) if (!this.extensions.extensions[t].isValid) {
          Debugger.print("Extension " + t + " is not valid"), u = !1;
          break
        }
      }
      else Debugger.print("extensions is " + (null !== this.extensions ? "NOT" : "") + " null"), null !== this.extensions && Debugger.print("extensions.extensions is " + (null !== this.extensions.extensions ? "NOT" : "") + " null");
      u ? "function" == typeof this.onSuccess && this.onSuccess.call(this, this.breaks) : "function" == typeof this.onFailure && this.onFailure.call(this, "error")
    }
    else "function" == typeof this.onFailure && this.onFailure.call(this, "error")
  }
}), PlaylistParser = ClassHelper.makeClass({
  initialize: function (e, t, i) {
    Debugger.print("Loading M3U8 from: " + e), this.server = e, this.content = [], this.handleSuccess = t, this.handleFailure = i, this.isRedirect = !1, this.isXML = !1, null !== ProtoAjax.DELEGATE ? ProtoAjax.DELEGATE(e, {
      onSuccess: this.onLoadSucceeded.bind(this),
      onFailure: this.onLoadFailed.bind(this)
    }) : new ProtoAjax.Request(e, {
      method: "get",
      evalJSON: !1,
      evalJS: !1,
      onSuccess: this.onLoadSucceeded.bind(this),
      onFailure: this.onLoadFailed.bind(this)
    })
  }, Destroy: function () {
    this.handleSuccess = null, this.handleFailure = null, this.content = null
  }, onLoadSucceeded: function (e) {
    Debugger.print("Playlist loaded... parsing"), void 0 !== e.transport && void 0 !== e.transport.responseURL && 0 !== e.transport.status && e.transport.responseURL !== this.server && (this.server = e.transport.responseURL, Debugger.print("Detected a playlist redirect to: " + this.server), this.isRedirect = !0), null === e.responseXML ? this.content = this.textToArray(e.responseText) : (this.isXML = !0, this.content = [e.responseXML]), 200 <= e.transport.status && e.transport.status <= 399 ? "function" == typeof this.handleSuccess && this.handleSuccess.call(this) : "function" == typeof this.handleFailure && this.handleFailure.call(this, e.transport.status)
  }, onLoadFailed: function (e) {
    Debugger.printErr("Failed to load Playlist from '" + this.server + "':" + e.status), "function" == typeof this.handleFailure && this.handleFailure.call(this, e.status)
  }, textToArray: function (e) {
    return "string" == typeof e && (e = String(e).trim()) ? e.split(/\s+/) : []
  }
}), VASTParser = ClassHelper.makeClass({
  initialize: function (e, t, i) {
    this.ads = null, this.server = e, this.onSuccess = t, this.onFailure = i
  }, Destroy: function () {
    this.onSuccess = null, (this.onFailure = null) !== this.ads && (this.ads.Destroy(), this.ads = null)
  }, load: function () {
    null !== this.server && (Debugger.print("Loading VAST from: " + this.server), null !== ProtoAjax.DELEGATE ? ProtoAjax.DELEGATE(this.server, {
      onSuccess: this.onLoadSuccess.bind(this),
      onFailure: this.onLoadFailure.bind(this)
    }) : new ProtoAjax.Request(this.server, {
      method: "get",
      evalJSON: !1,
      evalJS: !1,
      onSuccess: this.onLoadSuccess.bind(this),
      onFailure: this.onLoadFailure.bind(this)
    }))
  }, onLoadSuccess: function (e) {
    e = e.responseXML, this.parse(e)
  }, parse: function (e) {
    var t;
    this.ads = new VASTAds(this.onVastReady.bind(this), this.onVastFailed.bind(this)), this.ads.parse(e), !0 !== YSSessionManager.DEFAULTS.AD_DEBUG || !0 !== YSSessionManager.DEFAULTS.DEBUGGING || null !== (t = e.children.item(0).nextSibling) && t.nodeType == Node.COMMENT_NODE && (Debugger.print(" ************* AD-CALL DIAGNOSTICS ****************"), Debugger.print(t.nodeValue), Debugger.print(" ************* END DIAGNOSTICS ****************"))
  }, onVastReady: function (e) {
    "function" == typeof this.onSuccess && this.onSuccess.call(this, this.ads.ads)
  }, onVastFailed: function (e) {
    Debugger.print("ADS ERROR: " + e), "function" == typeof this.onFailure && this.onFailure.call(this, e)
  }, onLoadFailure: function (e) {
    Debugger.printErr("Failed to load VAST from '" + this.server + "':" + e), "function" == typeof this.onFailure && this.onFailure.call(this, e)
  }
}), YOPoller = ClassHelper.makeClass({
  initialize: function (e, t) {
    this.longperiod = e, this.shortperiod = t, this.running = !1, this.highpriority = !1, this.timer = null, this.callback = null
  }, Destroy: function () {
    this.isRunning() && this.stopPolling(), this.callback = null
  }, isRunning: function () {
    return !0 === this.running && null !== this.timer
  }, startPolling: function (e, t) {
    null !== this.timer && this.stopPolling(), this.callback = t, this.highpriority = e, this.running = !0, this.timer = setInterval(this.timerElapsed.bind(this), e ? this.shortperiod : this.longperiod)
  }, stopPolling: function () {
    this.running = !1, null !== this.timer && clearInterval(this.timer), this.timer = null
  }, timerElapsed: function () {
    this.callback && this.callback.call(this)
  }
}), Debugger = {};
!function () {
  "use strict";
  Debugger.print = function (e) {
    YSSessionManager.DEFAULTS.DEBUGGING && console.log(e)
  }, Debugger.printErr = function (e) {
    console.error(e)
  }
}();
var YSPlayerPolicy = ClassHelper.makeClass({
  initialize: function (e) {
    this.session = e
  }, Destroy: function () {
    this.session = null
  }, canSeekTo: function (e) {
    if (Debugger.print("Checking seek to: " + e), this.session instanceof YSLiveSession) return Debugger.print("Returning live default"), this.session.lastPosition;
    Debugger.print("VOD can seek to: " + e);
    var t = this.session.timeline;
    if (t) {
      if (!this.canSeek()) return Debugger.print("Returning last position as we're in an active advert"), this.session.lastPosition || 0;
      var i = t.getAllElements();
      t.getElementAtTime(this.session.lastPosition);
      if (i && 0 != i.length) {
        for (var n = -1, s = !1, r = i.length - 1; 0 <= r; r--) if (Debugger.print("Checking element from " + i[r].offset + " with duration: " + i[r].duration), i[r].getType() === YSTimelineElement.ADVERT) if (s) for (var a = i[r].getAdverts().adverts, o = 0; o < a.length; o++) a[o].setActive(!1); else e >= i[r].offset && i[r].getAdverts().isActive() && (Debugger.print("Break reports active"), n = i[r].offset, s = !0);
        return s && null !== this.session.player && "function" == typeof this.session.player.UpdateTimeline && (Debugger.print("Reporting timeline to player: " + YSParseUtils.timecodeToString(t.getTotalDuration())), this.session.player.UpdateTimeline(t)), -1 == n ? e : n
      }
      Debugger.print("No elements")
    }
    else Debugger.print("No timeline");
    return e
  }, canStart: function () {
    return !0
  }, canStop: function () {
    return !0
  }, canPause: function () {
    return !(this.session instanceof YSLiveSession)
  }, canSeek: function () {
    return !(this.session instanceof YSLiveSession) && (!this.session.isInAnAdvert() || !this.session.currentAdvert.isActive)
  }, canSkip: function () {
    if (this.session.isInAnAdvert()) {
      if (this.session instanceof YSLiveSession) return -1;
      var e = this.session.currentAdvert.advert;
      if (null !== e) {
        var t = e.getLinear();
        if (null !== t) {
          var i = t.getSkipOffset();
          if (!1 === this.session.currentAdvert.isActive && (i = 0), -1 === i) return -1;
          var n = this.session.currentAdvert.timeElapsed(), s = i <= n ? 0 : i - n;
          if (this.session instanceof YSVoDSession) return s;
          var r = t.getDuration() - n, a = this.session.timeline, o = a.getTotalDuration() + a.startOffset;
          return this.session.lastPosition + r >= o - YSPlayerPolicy.LIVE_TOLERANCE ? -1 : s
        }
      }
    }
    return -1
  }, canMute: function () {
    return !0
  }, canChangeFullScreen: function (e) {
    return !0
  }, canExpandCreative: function () {
    return !1
  }, canClickThrough: function () {
    return !0
  }
});
YSPlayerPolicy.LIVE_TOLERANCE = 30;
var YSSession = ClassHelper.makeClass({
  initialize: function (e, t, i) {
    Debugger.print("Constructing YSSession"), null !== t && 0 < t.length ? this.source = new YSURL(t) : this.source = null, this.manager = e, this.onComplete = i, null !== this.source ? this.hostnode = this.source.host() : this.hostnode = "", this.sessionId = "", this.analyticsUrl = "", this.livePauseUrl = "", this.masterURL = null, this.timeline = new YSTimeline, this.adBreakArray = {}, this.nonLinearBreaks = [], this.currentAdvert = null, this.breakEndTimer = null, this.player = null, this.streamType = "hls", this.lastPosition = void 0, this.analyticsSuppressed = !1, this.isPaused = !1, this.isPlaying = !1, this.policy = new YSPlayerPolicy(this), this._missedBreaks = []
  }, Destroy: function () {
    if (Debugger.print("Shutting down Session"), this.source = null, this.manager = null, this.masterURL = null, this.playlist = null, this.player = null, this.policy = null, this.currentAdvert = null, this.onComplete = null, this.stopBreakEndTimer(), this.timeline && (this.timeline.Destroy(), this.timeline = null), this.adBreakArray) {
      for (var e in this.adBreakArray) if (this.adBreakArray.hasOwnProperty(e)) {
        for (var t = this.adBreakArray[e]; 0 < t.length;) {
          t.pop().Destroy()
        }
        delete this.adBreakArray[e]
      }
      this.adBreakArray = null
    }
    if (0 < this._missedBreaks.length) for (var i = 0; i < this._missedBreaks.length; i++) this._missedBreaks[i].Destroy();
    this._missedBreaks = null
  }, LateInit: function (e, t) {
  }, beginSession: function () {
  }, setPaused: function (e) {
    (this.isPaused = e) && !this.isPlaying && (this.isPlaying = !0)
  }, getNonLinearBreaks: function () {
    return this.nonLinearBreaks
  }, removeNonLinearBreak: function (e) {
    if (0 < this.nonLinearBreaks.length) for (var t = 0; t < this.nonLinearBreaks.length; t++) if (this.nonLinearBreaks[t] === e) return void this.nonLinearBreaks.splice(t, 1)
  }, getCurrentBreak: function () {
    if (this instanceof YSLiveSession) {
      if (this._currentBreak) return this._currentBreak;
      if (this.currentAdvert) return this.currentAdvert.adBreak
    }
    else {
      var e = this.timeline.getElementAtTime(this.lastPosition);
      if (e.getType() === YSTimelineElement.ADVERT) return e.adBreak;
      if (this.currentAdvert) return this.currentAdvert.adBreak
    }
    return null
  }, addEmptyBreak: function (e) {
    if (Debugger.print("Adding Empty Break at: " + e.startPosition), 0 < this._missedBreaks.length) for (var t = 0; t < this._missedBreaks.length; t++) {
      var i = this._missedBreaks[t];
      if (e.startPosition < i.startPosition) return Debugger.print("Inserting empty break"), void this._missedBreaks.splice(t, 0, e);
      if (e.startPosition == i.startPosition) return void Debugger.print("Ignoring addition of duplicate empty break")
    }
    this._missedBreaks.push(e)
  }, getLinearClickthrough: function () {
    var e = void 0;
    return this.currentAdvert && this.currentAdvert.advert && this.currentAdvert.advert.getLinear() && (e = this.currentAdvert.advert.getLinear().getClickThrough()), e
  }, setPlayer: function (e) {
    this.player = e
  }, suppressAnalytics: function (e) {
    return e ? (this.currentAdvert && !this.analyticsSuppressed && this.currentAdvert.isSuppressed(!0), this.analyticsSuppressed = !0, this.stopBreakEndTimer(), null) : (this instanceof YSLiveSession && this.startBreakEndTimer(), this.currentAdvert && this.analyticsSuppressed ? (this.analyticsSuppressed = !1, this.currentAdvert.isSuppressed(!1)) : (this.analyticsSuppressed = !1, null))
  }, pingAnalytics: function (e) {
    0 < this.analyticsUrl.length ? null !== ProtoAjax.DELEGATE ? ProtoAjax.DELEGATE(this.analyticsUrl, {
      onSuccess: e.bind(this, !0),
      onFailure: e.bind(this, !1)
    }) : new ProtoAjax.Request(this.analyticsUrl, {
      method: "get",
      evalJSON: !1,
      evalJS: !1,
      onSuccess: e.bind(this, !0),
      onFailure: e.bind(this, !1)
    }) : Debugger.print("No analytics need to be fetched. Poller will not be initialized")
  }, processAnalytics: function (e) {
  }, handleMetadata: function (e) {
  }, updatePosition: function (e) {
    this.lastPosition = e
  }, isInAnAdvert: function () {
    return null !== this.currentAdvert
  }, masterPlaylistUrl: function () {
    return this.masterURL.toString()
  }, loadPlaylist: function () {
    YSSessionManager.DEFAULTS.LEGACY_URL_SYNTAX && this.masterURL.addQueryParameter("yo.js", "true", !1), this.playlist = new PlaylistParser(this.masterPlaylistUrl(), this.playlistLoaded.bind(this), this.playlistNotLoaded.bind(this))
  }, extractSessionId: function (e) {
    var t = e.queryByName("yo.js");
    if (null !== t) return t;
    var i = e.path().split(";");
    return 1 < i.length ? i[1].split("=")[1] : null
  }, playlistLoaded: function () {
    if (Debugger.print("Playlist was loaded"), this.playlist.isRedirect && (this.masterURL = new YSURL(this.playlist.server)), this.playlist.isXML) {
      Debugger.print("Playlist is XML - assuming DASH");
      var e, t = this.playlist.content[0].getElementsByTagName("MPD");
      0 < t.length ? ((t = t.item(0)).hasAttribute("analytics") && (this.analyticsUrl = t.getAttribute("analytics").replace(/\s+/g, "")), 0 < (e = t.getElementsByTagName("Location")).length ? (e = e.item(0), i = new YSURL(e.textContent), this.masterURL._host = i.host(), this.hostnode = i.host(), null !== (n = this.extractSessionId(i)) ? YSSessionManager.DEFAULTS.LEGACY_URL_SYNTAX && this.masterURL.addQueryParameter("yo.js", n, !0) : (this.sessionId = "", this.analyticsUrl = "")) : (Debugger.print("Could not locate location element"), "function" == typeof this.onComplete && this.onComplete.call(this, YSSessionResult.NO_ANALYTICS, YSSessionStatus.NON_YOSPACE_URL, void 0)), t.hasAttribute("livepause") && (this.livePauseUrl = t.getAttribute("livepause").replace(/\s+/g, ""))) : (Debugger.print("Could not locate MPD element"), "function" == typeof this.onComplete && this.onComplete.call(this, YSSessionResult.NO_ANALYTICS, YSSessionStatus.NON_YOSPACE_URL, void 0)), this.streamType = "dash"
    }
    else {
      var i, n, s = Object.keys(this.playlist.content), r = !1;
      for (var a in s) if (this.playlist.content.hasOwnProperty(a) && 0 === this.playlist.content[a].indexOf(X_STREAM_TOKEN)) r = !0; else {
        if (r) {
          var o = new YSURL(this.playlist.content[a]), l = (l = o.path()).substr(0, l.lastIndexOf("/")) + ".m3u8";
          this.masterURL._scheme = o.scheme(), this.masterURL._host = o.host(), this.masterURL._path = l + this.masterURL.path(), this.masterURL._query = o.query();
          break
        }
        this.playlist.content.hasOwnProperty(a) && 0 === this.playlist.content[a].indexOf(ANALYTICS_TOKEN) && (this.analyticsUrl = this.playlist.content[a].substr(ANALYTICS_TOKEN.length + 1), '"' == this.analyticsUrl.charAt(0) && (this.analyticsUrl = this.analyticsUrl.substr(1, this.analyticsUrl.length - 2)), i = new YSURL(this.analyticsUrl), this.hostnode = i.host(), n = this.extractSessionId(i), Debugger.print("sess_id = " + n), null !== n ? YSSessionManager.DEFAULTS.LEGACY_URL_SYNTAX ? (this.masterURL.addQueryParameter("yo.js", n, !0), this.masterURL._path = "") : this.masterURL._path = ";jsessionid=" + n : (this.sessionId = "", this.analyticsUrl = "")), this.playlist.content.hasOwnProperty(a) && 0 === this.playlist.content[a].indexOf(PAUSE_TOKEN) && (this.livePauseUrl = this.playlist.content[a].substr(PAUSE_TOKEN.length + 1), '"' == this.livePauseUrl.charAt(0) && (this.livePauseUrl = this.livePauseUrl.substr(1, this.livePauseUrl.length - 2)))
      }
    }
    Debugger.print("Modified URL: " + this.masterPlaylistUrl()), Debugger.print("Deduced analytics URL: " + this.analyticsUrl), 0 < this.livePauseUrl.length && Debugger.print("Deduced Live Pause URL: " + this.livePauseUrl), 0 === this.analyticsUrl.length ? "function" == typeof this.onComplete && this.onComplete.call(this, YSSessionResult.NO_ANALYTICS, YSSessionStatus.NON_YOSPACE_URL, void 0) : this instanceof YSLivePauseSession ? 0 === this.livePauseUrl.length ? "function" == typeof this.onComplete && this.onComplete.call(this, YSSessionResult.INITIALISED, 0, YSSessionStatus.NO_LIVEPAUSE) : "function" == typeof this.onComplete && this.onComplete.call(this, YSSessionResult.INITIALISED, 0, 0) : "function" == typeof this.onComplete && this.onComplete.call(this, YSSessionResult.INITIALISED)
  }, playlistNotLoaded: function (e) {
    Debugger.print("Playlist was NOT loaded"), "function" == typeof this.onComplete && this.onComplete.call(this, YSSessionResult.NOT_INITIALISED, e, YSSessionStatus.CONNECTION_ERROR)
  }, startBreakEndTimer: function (e) {
    Debugger.print("YSSession::startBreakEndTimer called with duration: " + e), isNaN(e) && (e = YSSession.BREAK_TOLERANCE), null !== this.breakEndTimer && this.stopBreakEndTimer();
    var t = this._currentBreak;
    t && (Debugger.print("Starting break end timer with break: " + t + " and duration: " + e), this.breakEndTimer = setTimeout(this.handleBreakEnd.bind(this, t), e))
  }, stopBreakEndTimer: function () {
    Debugger.print("YSSession::stopBreakEndTimer"), null !== this.breakEndTimer && (Debugger.print("YSSession::stopBreakEndTimer stopping timer"), clearTimeout(this.breakEndTimer), this.breakEndTimer = null)
  }, handleBreakStart: function (e) {
    Debugger.print(" |||||||| CONTROL FLOW |||||||| HANDLE BREAK START"), e && e.vmapBreak && e.vmapBreak.tracking && e.vmapBreak.tracking.track("breakStart", []), e && (this._currentBreak = e, Debugger.print("Break Position: " + e.getPosition())), null === this.breakEndTimer && (null !== this.player && "function" == typeof this.player.AdBreakStart && this.player.AdBreakStart(e), this instanceof YSLiveSession && this.startBreakEndTimer())
  }, handleBreakEnd: function (e) {
    Debugger.print(" |||||||| CONTROL FLOW |||||||| HANDLE BREAK END"), e && e.vmapBreak && e.vmapBreak.tracking && (e.isActive() ? Debugger.print("Not firing breakEnd slot as break was not completed") : e.vmapBreak.tracking.track("breakEnd", [])), this.isInAnAdvert() && (null !== this.player && "function" == typeof this.player.AdvertEnd && this.player.AdvertEnd(this.currentAdvert.getMediaID()), this.currentAdvert.setActive(!1), this.currentAdvert = null), null !== this.breakEndTimer && this.stopBreakEndTimer(), e && (Debugger.print("Advert break ended - notifying consumer"), null !== this.player && "function" == typeof this.player.AdBreakEnd && this.player.AdBreakEnd(e)), this._currentBreak = null
  }, handleAdvertStart: function (e) {
    var t;
    Debugger.print(" ||| CONTROL FLOW ||| HANDLE ADVERT START"), null !== this.player && "function" == typeof this.player.AdvertStart && (t = null, e && (t = e.getMediaID()), this.player.AdvertStart(t))
  }, handleAdvertEnd: function (e) {
    Debugger.print(" ||| CONTROL FLOW ||| HANDLE ADVERT END"), e.setActive(!1), null !== this.player && "function" == typeof this.player.AdvertEnd && this.player.AdvertEnd(e.getMediaID()), this.currentAdvert = null
  }, reportLinearEvent: function (e) {
    var t;
    !this.isInAnAdvert() || null !== (t = this.currentAdvert) && t.reportLinearEvent(e)
  }, reportCompanionEvent: function (e, t) {
    var i;
    !this.isInAnAdvert() || null !== (i = this.currentAdvert) && i.reportCompanionEvent(e, t)
  }, reportNonLinearEvent: function (e, t) {
    var i;
    !this.isInAnAdvert() || null !== (i = this.currentAdvert) && i.reportNonLinearEvent(e, t)
  }, reportNonLinearBreakEvent: function (e, t) {
    e && e.vmapBreak && e.vmapBreak.tracking && e.vmapBreak.tracking.track(t, [])
  }, reportNonLinearAdvertEvent: function (e, t) {
    e && e.tracking && e.tracking.track(t, {})
  }, getPolicy: function () {
    return this.policy
  }
});
YSSession.idRE = new RegExp(/([^_]*)_YO_([\s\S]*)/i), YSSession.BREAK_TOLERANCE = 6e3, YSSession.READY = "ready", YSSession.INIT_FAILED = "error";
var YSSessionResult = { INITIALISED: "ready", NOT_INITIALISED: "error", NO_ANALYTICS: "no-analytics" },
  YSSessionStatus = {
    CONNECTION_ERROR: -1,
    CONNECTION_TIMEOUT: -2,
    MALFORMED_URL: -3,
    NON_YOSPACE_URL: -10,
    NO_LIVEPAUSE: -11
  }, YSVoDSession = ClassHelper.makeClass(YSSession, {
    initialize: function (e, t, i, n) {
      this.callSuper(this.initialize, e, t, i), Debugger.print("Constructing YSVoDSession"), this.isVLive = n
    }, Destroy: function () {
      Debugger.print("Shutting down VOD Session"), this.callSuper(this.Destroy), this.loader && (this.loader.Destroy(), this.loader = null)
    }, beginSession: function () {
      this.callSuper(this.beginSession);
      var e = this.source.toString(), t = this;
      null !== e && 0 < e.length ? null !== e && (null !== ProtoAjax.DELEGATE ? ProtoAjax.DELEGATE(e, {
        onSuccess: this.onVMAPLoaded.bind(this),
        onFailure: function (e) {
          t.onComplete.call(t, YSSessionResult.NOT_INITIALISED, e.status, YSSessionStatus.CONNECTION_ERROR)
        }
      }) : new ProtoAjax.Request(e, {
        method: "get",
        evalJSON: !1,
        evalJS: !1,
        onSuccess: this.onVMAPLoaded.bind(this),
        onFailure: function (e) {
          t.onComplete.call(t, YSSessionResult.NOT_INITIALISED, e.status, YSSessionStatus.CONNECTION_ERROR)
        }
      })) : Debugger.print("Expecting late initialization")
    }, onVMAPLoaded: function (e) {
      var t = 0 <= e.responseText.indexOf("#EXTM3U");
      null === e.responseXML ? "function" == typeof this.onFailure && (t ? this.onComplete.call(this, YSSessionResult.NO_ANALYTICS, YSSessionStatus.NON_YOSPACE_URL, void 0) : this.onComplete.call(this, YSSessionResult.NOT_INITIALISED, e.status, YSSessionStatus.CONNECTION_ERROR)) : (e = e.responseXML, this.grabVMAP(e))
    }, grabVMAP: function (e) {
      var t = this;
      this.loader = new VMAPParser(this.onVMAPSuccess.bind(this), function (e) {
        Debugger.print("Ad Break Load Failed"), "function" == typeof t.onComplete && ("ism3u8" === e ? t.onComplete.call(t, YSSessionResult.NO_ANALYTICS, YSSessionStatus.NON_YOSPACE_URL, void 0) : t.onComplete.call(t, YSSessionResult.NOT_INITIALISED, e, YSSessionStatus.CONNECTION_ERROR))
      }), this.loader.parse(e)
    }, onVMAPSuccess: function (e) {
      if (this.rebuildTimeline(e), !this.masterURL) return Debugger.printErr("VOD - Cannot start session without playback URL"), void ("function" == typeof this.onComplete && this.onComplete.call(this, YSSessionResult.NOT_INITIALISED, 0, YSSessionStatus.CONNECTION_ERROR));
      this.isVLive ? this.loadPlaylist() : (Debugger.print("Standard VOD - Bypassing session initialisation"), 0 < this.masterURL.toString().indexOf("mpd") && (this.streamType = "dash"), "function" == typeof this.onComplete && this.onComplete.call(this, YSSessionResult.INITIALISED))
    }, rebuildTimeline: function (e) {
      if (Debugger.print("\n<<<<<<<<<<<<<< PARSE COMPLETE >>>>>>>>>>>>>>>>\nBreaks returned. Length: " + e.length), null !== this.loader.extensions) {
        var t, i = this.loader.extensions.getAllOfType(TAG_YOEXT_STREAM);
        0 < i.length && !this.masterURL && (t = this.source.scheme() + "://" + i[0].urlDomain + i[0].urlSuffix, this.hostnode = i[0].urlDomain, this.masterURL = new YSURL(t), Debugger.print("URL: " + this.masterPlaylistUrl()));
        for (var n = 0, s = this.nonLinearBreaks.length, r = 0; r < e.length; r++) {
          var a = e[r], n = YSParseUtils.timecodeFromString(a.position), o = new YSAdBreak(a);
          if (o.adBreakIdentifier = a.id, o.adBreakDescription = a.type, o.startPosition = n, a.vast) {
            for (var l = a.vast.ads, h = 0; h < l.length; h++) {
              var u, g = YSSession.idRE.exec(l[h].id), d = null;
              g ? (u = g[2], (d = new YSAdvert(l[h], this.onAdTimeout.bind(this, u), o)).trackingMonitor = this.onTrackingMonitor.bind(this)) : d = new YSAdvert(l[h], null, o), o.adverts.push(d);
              var c = l[h].getLinear();
              c && (n += c.getDuration())
            }
            "linear" === a.type ? this.replaceOnTimeline(new YSTimelineAdvertElement(o.startPosition, o.getDuration(), o)) : (Debugger.print("Adding NonLinear Break (VOD) to existing " + this.nonLinearBreaks.length + " break(s)"), this.nonLinearBreaks.push(o))
          }
          else "linear" === a.type ? this.addEmptyBreak(o) : (Debugger.print("Adding Empty NonLinear Break (VOD) to existing " + this.nonLinearBreaks.length + " break(s)"), this.nonLinearBreaks.push(o))
        }
        var p = 0 < i.length && i[0].isValid && 0 < i[0].duration.length ? YSParseUtils.timecodeFromString(i[0].duration) : n;
        0 < p ? this.timeline.adjustContent(p) : Debugger.print("No duration info at this time"), null !== this.player && "function" == typeof this.player.UpdateTimeline && (Debugger.print("Reporting timeline to player: " + YSParseUtils.timecodeToString(this.timeline.getTotalDuration())), this.player.UpdateTimeline(this.timeline)), s != this.nonLinearBreaks.length && null !== this.player && "function" == typeof this.player.NonLinearsUpdated && this.player.NonLinearsUpdated()
      }
      else Debugger.printErr("VMAP contained no extensions - this is a potential problem!")
    }, replaceOnTimeline: function (e) {
      var t, i;
      this.timeline && ((t = this.timeline.getElementAtTime(e.offset)) ? t.offset === e.offset && t.duration === e.duration && t.getType() === e.getType() || (i = this.timeline.elements.indexOf(t), this.timeline.elements.splice(i, 1, e)) : this.timeline.appendElement(e))
    }, onTrackingMonitor: function (e, t, i) {
      null !== this.player && "function" == typeof this.player.AnalyticsFired && this.player.AnalyticsFired(e, {
        progress: t,
        asset: i
      })
    }, processAnalytics: function (e, n) {
      Debugger.print("Processing VMAP Analytics Data (VOD)"), this.callSuper(this.processAnalytics, e);
      var s = this;
      this.loader = new VMAPParser(function (e) {
        Debugger.print("New breaks received: " + e.length), s.rebuildTimeline(e), Debugger.print("Timeline rebuilt. Total len: " + s.timeline.getTotalDuration() + " :: " + YSParseUtils.timecodeToString(s.timeline.getTotalDuration()));
        for (var t = 0; t < s.timeline.getAllElements().length; t++) {
          var i = s.timeline.getAllElements()[t];
          Debugger.print("$" + t + ": " + i.getType() + " start: " + YSParseUtils.timecodeToString(i.offset) + " dur: " + YSParseUtils.timecodeToString(i.duration))
        }
        "function" == typeof n && n.call(s, !0, e)
      }, function (e) {
        Debugger.print("!no breaks"), "function" == typeof n && n.call(s, !1, e)
      }), this.loader.parse(e.responseXML)
    }, onAdTimeout: function (e) {
      Debugger.print(" !!! Advert Timeout flagged for item: " + e)
    }, updatePosition: function (e) {
      if (!this.isPaused && this.isPlaying) {
        var t;
        if (this.timeline && (e = 0 < (t = this.timeline.getTotalDuration()) && t < e ? t : e), 0 < this._missedBreaks.length) for (var i = 0; i < this._missedBreaks.length; i++) {
          var n = this._missedBreaks[i];
          if (!(this.lastPosition > n.startPosition) && e > n.startPosition) {
            Debugger.print(" @@ MISSED BREAK @@ Transiting a missed break opportunity");
            var s = n.vmapBreak;
            s.tracking && (s.tracking.track("breakStart", []), s.tracking.track("breakEnd", []));
            break
          }
        }
        if (this.callSuper(this.updatePosition, e), this.isInAnAdvert()) {
          this.currentAdvert.isSuppressed(this.analyticsSuppressed);
          var r = this.currentAdvert.pingWatchdog();
          if (r && 0 < r.length) for (var a, i = 0; i < r.length; i++) {
            null !== this.player && "function" == typeof this.player.AnalyticsFired && (a = r[i].track_id, delete r[i].track_id, this.player.AnalyticsFired(a, r[i]))
          }
        }
        if (null !== this.timeline) {
          var o, l = this.timeline.getElementAtTime(e);
          if (null === l) return void Debugger.print("No timeline element was found");
          if (l.getType() === YSTimelineElement.ADVERT) {
            var h = l.getAdverts().getAdvertForPosition(e);
            if (!h) return void Debugger.print("Could not locate current advert!");
            var u = h.getMediaID(), g = this.currentAdvert ? this.currentAdvert.getMediaID() : "";
            this.currentAdvert === h || (Debugger.print("Different ad found"), this.isInAnAdvert() ? (Debugger.print("Shutting down advert: " + g), null !== this.player && "function" == typeof this.player.AdvertEnd && this.player.AdvertEnd(g), this.currentAdvert.setActive(!1), null !== this.player && "function" == typeof this.player.UpdateTimeline && this.player.UpdateTimeline(this.timeline), this.currentAdvert = null) : h.adBreak === this._currentBreak && null !== this._currentBreak || this.handleBreakStart(this.getCurrentBreak()), Debugger.print("Advert starting with ID: " + u), Debugger.print("Advert Duration: " + h.duration), this.currentAdvert = h, this.currentAdvert.isSuppressed(this.analyticsSuppressed), this.currentAdvert.setActive(!0), this.handleAdvertStart(h))
          }
          else {
            this.isInAnAdvert() ? (g = this.currentAdvert.getMediaID(), Debugger.print("Shutting down advert: " + g), null !== this.player && "function" == typeof this.player.AdvertEnd && this.player.AdvertEnd(g), this.currentAdvert.setActive(!1), null !== this.player && "function" == typeof this.player.UpdateTimeline && this.player.UpdateTimeline(this.timeline), o = this.currentAdvert.adBreak, this.currentAdvert = null, Debugger.print("BREAK ENDS!"), this.handleBreakEnd(o)) : this._currentBreak && this.handleBreakEnd(this._currentBreak)
          }
        }
      }
      else Debugger.print("Ignoring position update while not actively playing")
    }, getContentPositionForPlayhead: function (e) {
      var t = e, i = 0;
      if (this.timeline) {
        for (var n = this.timeline.getAllElements(), s = 0; s < n.length && 0 < t;) {
          var r = n[s];
          r.getType() === YSTimelineElement.ADVERT || (t > r.duration ? i += r.duration : i += t), t -= r.duration, s++
        }
        return i
      }
      return Debugger.print("Conversion from Playhead to Content failed"), e
    }, getPlayheadPositionForContent: function (e) {
      var t = e, i = 0;
      if (this.timeline) {
        for (var n = this.timeline.getAllElements(), s = 0; s < n.length && 0 < t;) {
          var r = n[s];
          r.getType() === YSTimelineElement.ADVERT ? i += r.duration : (t > r.duration ? i += r.duration : i += t, t -= r.duration), s++
        }
        return i
      }
      return Debugger.print("Conversion from Content to Playhead failed"), e
    }
  }), YSLiveSession = ClassHelper.makeClass(YSSession, {
    initialize: function (e, t, i) {
      this.callSuper(this.initialize, e, t, i), Debugger.print("Constructing YSLiveSession"), this.adBreakArray = {}, this._pollCount = 0, this._deferred = !1, this._currentBreaks = [], this._currentBreak = null, this._cachedMetadata = [], this._lastMetadata = null
    }, Destroy: function () {
      if (this.callSuper(this.Destroy), this._currentBreak = null, this._currentBreaks) {
        for (; 0 < this._currentBreaks.length;) {
          this._currentBreaks.pop().Destroy()
        }
        this._currentBreaks = null
      }
      this.loader && (this.loader.Destroy(), this.loader = null)
    }, beginSession: function () {
      this.callSuper(this.beginSession), null !== this.source && 0 < this.source.toString().length ? (this.masterURL = new YSURL(this.source.toString()), this.loadPlaylist()) : Debugger.print("Expecting late initialization")
    }, LateInit: function (e, t) {
      this.masterURL = new YSURL(e);
      var i = new YSURL(t);
      this.masterURL._host = i.host();
      var n = i.path().split(";")[1];
      this.masterURL._path = this.masterURL._path + ";" + n, this.source = this.masterURL.toString(), Debugger.print("=== LATE INIT === " + this.masterURL.toString()), this.loadPlaylist(!0)
    }, makeNextAvailableAdvertWatched: function () {
      if (!this._currentBreak) return Debugger.printErr("Cannot perform this action when not in a break!"), null;
      var e = this._currentBreak.makeNextAvailableAdvertWatched();
      return e ? (Debugger.print("Have marked ad: " + e.getMediaID() + " as viewed"), this.haveMoreAds() || this.handleBreakEnd(this._currentBreak)) : Debugger.print("Failed to mark an ad as viewed"), e
    }, processAnalytics: function (e, d) {
      Debugger.print("Processing VAST Analytics Data"), this._pollCount < 2 && this._pollCount++, this.callSuper(this.processAnalytics, e);
      var c = this, t = !1, i = e.responseText.indexOf("<vmap" + YSParseUtils.NS_SEPARATOR + "VMAP"),
        n = e.responseText.indexOf("<VAST");
      -1 == n && -1 != i && (n = i + 1), -1 != i && i < n && (Debugger.print(" +=+ USING ENHANCED ANALYTICS +=+ "), t = !0), this.loader = t ? new VMAPParser(function (e) {
        if (Debugger.print("New breaks received: " + e.length), 0 < e.length) {
          for (var t = c.nonLinearBreaks.length, i = 0; i < e.length; i++) {
            var n = e[i], s = YSParseUtils.timecodeFromString(n.position), r = new YSAdBreak(n);
            if (r.adBreakIdentifier = n.id, r.adBreakDescription = n.type, r.startPosition = s, n.vast) {
              for (var a = n.vast.ads, o = 0; o < a.length; o++) {
                var l = null, h = YSSession.idRE.exec(a[o].id), u = null;
                h ? (u = h[2], (l = new YSAdvert(a[o], c.onAdTimeout.bind(c, u), r)).trackingMonitor = c.onTrackingMonitor.bind(c)) : l = new YSAdvert(a[o], null, r), r.adverts.push(l);
                var g = a[o].getLinear();
                g && (s += g.getDuration()), u && "linear" === n.type && (c.adBreakArray.hasOwnProperty(u) || (c.adBreakArray[u] = []), c.adBreakArray[u].unshift(l))
              }
              "linear" === n.type ? c._currentBreaks.push(r) : c.nonLinearBreaks.push(r)
            }
            else "linear" === n.type ? (Debugger.print(" @@ MISSED BREAK @@ Transiting a missed break opportunity"), n.tracking && (n.tracking.track("breakStart", []), n.tracking.track("breakEnd", []))) : (Debugger.print(" @@ MISSED BREAK @@ Ignoring empty non-linear break"), c.nonLinearBreaks.push(r))
          }
          t != c.nonLinearBreaks.length && null !== c.player && "function" == typeof c.player.NonLinearsUpdated && c.player.NonLinearsUpdated()
        }
        0 < e.length && c._deferred && c.processCachedMetadata(), "function" == typeof d && d.call(c, !0, e)
      }, function (e) {
        Debugger.print("!no breaks"), "function" == typeof d && d.call(c, !1, e)
      }) : new VASTParser(null, function (e) {
        if (Debugger.print("New breaks received: " + e.length), 0 < e.length) {
          for (var t = new YSAdBreak(null), i = 0; i < e.length; i++) {
            var n = YSSession.idRE.exec(e[i].id), s = n ? n[2] : e[i].id;
            Debugger.print("Adding to bucket, MIID: " + s), c.adBreakArray.hasOwnProperty(s) || (c.adBreakArray[s] = []);
            var r = new YSAdvert(e[i], c.onAdTimeout.bind(c, s), t);
            t.adverts.push(r), r.trackingMonitor = c.onTrackingMonitor.bind(c), c.adBreakArray[s].unshift(r);
            var a = 0;
            for (var o in c.adBreakArray) c.adBreakArray.hasOwnProperty(o) && (a += c.adBreakArray[o].length);
            Debugger.print("New bucket size: " + a)
          }
          c._currentBreaks.push(t), 0 < t.adverts.length && null !== c.player && "function" == typeof c.player.OnBreakReceived && c.player.OnBreakReceived(t)
        }
        0 < e.length && c._deferred && c.processCachedMetadata(), "function" == typeof d && d.call(c, !0, e)
      }, function (e) {
        Debugger.print("VAST Failure?"), "function" == typeof d && d.call(c, !1, e)
      }), this.loader.parse(e.responseXML)
    }, processCachedMetadata: function () {
      for (this._deferred && (Debugger.print("Received deferred VAST response"), this._deferred = !1); 0 < this._cachedMetadata.length;) {
        var e, t, i = this._cachedMetadata.shift(), n = i.metadata;
        this.handleMetadata(n), n.hasOwnProperty("YMID") && (e = n.YTYP, t = n.YSEQ.split(":")[0], "S" === e && "1" === t && (this.currentAdvert ? this.currentAdvert.startPosition = i.timestamp : Debugger.print("Cannot set back-time of current advert (no ad active)")))
      }
    }, onAdTimeout: function (e) {
      Debugger.print(" !!! Advert Timeout flagged for item: " + e)
    }, onTrackingMonitor: function (e, t, i) {
      null !== this.player && "function" == typeof this.player.AnalyticsFired && this.player.AnalyticsFired(e, {
        progress: t,
        asset: i
      })
    }, getAdById: function (e) {
      var t, i = null;
      if (YSSessionManager.DEFAULTS.STRICT_BREAKS) {
        var n = this._currentBreak;
        if (n || this._currentBreaks && 0 < this._currentBreaks.length && (n = this._currentBreaks[0]), n) {
          var s = n.getAdvertById(e, !1);
          return s || (Debugger.print("Unable to locate ad for ID: " + e), null)
        }
      }
      console.log(this.adBreakArray);
      return this.adBreakArray.hasOwnProperty(e) ? 0 < (t = this.adBreakArray[e]).length ? i = t.pop() : null === t ? Debugger.print("No adverts have yet been defined") : Debugger.print("Adverts previously seen for this ID, but none currently available: " + e) : (Debugger.print("No adverts found in array for this ID, and have not yet seen any: " + e), null === this.adBreakArray && Debugger.print("And ad break array is null")), i
    }, handleAdvertEnd: function (e) {
      this.callSuper(this.handleAdvertEnd, e), this._lastMetadata = null
    }, handleMetadata: function (e) {
      var i, t = !1;
      if (this.isPlaying) if (e) {
        for (var n in Debugger.print("Live metadata is non-null"), e) e.hasOwnProperty(n) && Debugger.print("Property '" + n + "' = '" + e[n] + "'");
        if (0 == this._currentBreaks.length && (this._pollCount < 2 || this._deferred)) return Debugger.print("Waiting for initial VAST response... deferring"), this._deferred || (this._deferred = !0, (i = this).pingAnalytics(function (e, t) {
          Debugger.print("OK, have pinged: " + e), i.processAnalytics(t, null)
        })), void this._cachedMetadata.push({ timestamp: (new Date).getTime(), metadata: e });
        if (this._deferred && 0 < this._cachedMetadata.length) this._cachedMetadata.push({
          timestamp: (new Date).getTime(),
          metadata: e
        }); else if (e.hasOwnProperty("YMID")) {
          if (this._lastMetadata && e.YMID && e.YMID === this._lastMetadata.YMID && e.YTYP && e.YTYP === this._lastMetadata.YTYP && e.YSEQ && e.YSEQ === this._lastMetadata.YSEQ && e.YDUR && e.YDUR === this._lastMetadata.YDUR) return void Debugger.print("Duplicate metadata reported - ignoring");
          var s = (this._lastMetadata = e).YMID, r = e.YTYP, a = e.YSEQ.split(":"), o = a[0], l = a[1];
          Debugger.print("Valid ID3 found. MIID: " + s);
          var h = "S" === r && "1" === o, u = this.isInAnAdvert() && h && !this.currentAdvert.isFiller();
          if (this.isInAnAdvert() && this.currentAdvert.getMediaID() === s && !u) {
            Debugger.print("Advert still running for MIID: " + s + " with type: " + r + " Seq " + o + " of " + l), this.stopBreakEndTimer(), this.startBreakEndTimer(), this.currentAdvert.isSuppressed(this.analyticsSuppressed);
            var g = this.currentAdvert.pingWatchdog();
            if (g && 0 < g.length) for (var d, c = 0; c < g.length; c++) {
              null !== this.player && "function" == typeof this.player.AnalyticsFired && (d = g[c].track_id, delete g[c].track_id, this.player.AnalyticsFired(d, g[c]))
            }
          }
          else {
            var p, A = this.isInAnAdvert();
            A ? (Debugger.print("Currently in an advert, but the media ID has changed. Terminating current advert."), this.handleAdvertEnd(this.currentAdvert), this.currentAdvert = null) : Debugger.print("Not yet in an advert"), A || h ? null !== (p = this.getAdById(s)) ? (Debugger.print("Advert starting for MIID: " + s + " with type: " + r + " Seq " + o + " of " + l), Debugger.print("Advert Duration: " + p.duration), this.currentAdvert = p, t = !0, null !== this.breakEndTimer || this._currentBreak ? (this.stopBreakEndTimer(), this.startBreakEndTimer()) : (this._currentBreak = this._currentBreaks[0], this._currentBreak ? this.handleBreakStart(this.getCurrentBreak()) : Debugger.print("Could not find break")), null !== this.currentAdvert && null !== this.player && "function" == typeof this.player.AdvertStart && this.player.AdvertStart(s), this.currentAdvert && (this.currentAdvert.isSuppressed(this.analyticsSuppressed), t && this.currentAdvert.setActive(!0))) : (Debugger.print("Could not locate ad for miid: " + s), null !== this.breakEndTimer && (this.stopBreakEndTimer(), this.startBreakEndTimer())) : (Debugger.print("Ignoring advert with MIID: " + s + " because tag is not a start tag. Type: " + r + " Seq: " + o + " of " + l), null !== this.breakEndTimer && (this.stopBreakEndTimer(), this.startBreakEndTimer()))
          }
          "E" == r && null !== this.currentAdvert && (o == l && !this.currentAdvert.isFiller() || this.currentAdvert.timeElapsed() > this.currentAdvert.duration) && (Debugger.print("Advert ending for MIID: " + s + " with type: " + r + " Seq " + o + " of " + l), null !== this.player && "function" == typeof this.player.AdvertEnd && this.player.AdvertEnd(s), this.currentAdvert.setActive(!1), this.currentAdvert = null)
        }
        else Debugger.print("Ignoring unrecognized ID3 tag")
      }
      else Debugger.print("Live metadata is null"); else Debugger.print("Dropping metadata reported before playback has started")
    }, updatePosition: function (e) {
      if (this.callSuper(this.updatePosition, e), !this.paused && this.isPlaying) if (this.isInAnAdvert() && this.currentAdvert.advert.getLinear() && !this.currentAdvert.advert.getLinear().hasInteractiveUnit()) if (this.currentAdvert.timeElapsed() > this.currentAdvert.duration) {
        Debugger.print("******************* ADVERT HAS EXCEEDED DURATION!!! *************************");
        var t = this.currentAdvert.getMediaID();
        null !== this.player && "function" == typeof this.player.AdvertEnd && this.player.AdvertEnd(t), this.currentAdvert.setActive(!1), this.currentAdvert = null
      }
      else {
        this.currentAdvert.isSuppressed(this.analyticsSuppressed);
        var i = this.currentAdvert.pingWatchdog();
        if (i && 0 < i.length) for (var n, s = 0; s < i.length; s++) {
          null !== this.player && "function" == typeof this.player.AnalyticsFired && (n = i[s].track_id, delete i[s].track_id, this.player.AnalyticsFired(n, i[s]))
        }
      } else null !== this.breakEndTimer && (this.haveMoreAds() ? Debugger.print("--- WAITING FOR NEXT AD!!!") : (Debugger.print("--- COULD STOP BREAK HERE!!!"), this.handleBreakEnd(this._currentBreak))); else Debugger.print("Ignoring position update while not actively playing")
    }, haveMoreAds: function () {
      var e, t = 0;
      if (YSSessionManager.DEFAULTS.STRICT_BREAKS) {
        if (this._currentBreak && this._currentBreak.adverts) for (e = 0; e < this._currentBreak.adverts.length; e++) this._currentBreak.adverts[e].isActive && t++;
        return Debugger.print("Have more ads (strict)? " + t), 0 < t
      }
      for (e in this.adBreakArray) this.adBreakArray.hasOwnProperty(e) && (t += this.adBreakArray[e].length);
      return Debugger.print("Have more ads (classic)? " + t), 0 < t
    }, handleBreakEnd: function (e) {
      if (this.callSuper(this.handleBreakEnd, e), 0 < this._currentBreaks.length && this._currentBreaks.shift(), YSSessionManager.DEFAULTS.STRICT_BREAKS) if (Debugger.print("Checking for current break ads at break end"), e) {
        var t = e.adverts;
        if (t && 0 < t.length) for (var i = 0; i < t.length; i++) {
          var n = t[i].getMediaID();
          Debugger.print("Trying to clean up: " + n), this.adBreakArray.hasOwnProperty(n) && (0 < this.adBreakArray[n].length ? (Debugger.print("Removing ad " + n + " From array with pre-length: " + this.adBreakArray[n].length), this.adBreakArray[n].pop()) : Debugger.print("Could not remove ad " + n + " as global bucket has no such ad!"))
        } else Debugger.print("No ads in break, or break length is zero!")
      }
      else Debugger.print("Break is null, so cannot clean up!")
    }
  }), YSLivePauseSession = ClassHelper.makeClass(YSSession, {
    initialize: function (e, t, i) {
      this.callSuper(this.initialize, e, t, i), Debugger.print("Constructing YSLivePauseSession"), this.streamStart = null, this.streamWindowStart = null, this.streamWindowEnd = null, this.streamWindowSize = 0, this.streamDuration = 0, this.adBreakArray = {}, this._pollCount = 0, this._deferred = !1, this.masterURL = new YSURL(this.source), this.livePauseURL = null
    }, Destroy: function () {
      this.callSuper(this.Destroy), this.streamStart = null, this.streamWindowStart = null, this.streamWindowEnd = null, this._deferred = !1, this.isPaused = !1
    }, beginSession: function () {
      this.callSuper(this.beginSession), this.loadPlaylist()
    }, setPaused: function (e) {
      this.callSuper(this.setPaused, e), this.isPaused = e
    }, rebuildTimeline: function (e) {
      var t, i, n, s = this.loader.extensions.getAllOfType(TAG_YOEXT_STREAM);
      0 < s.length && (this.masterURL || (t = this.source.scheme() + "://" + s[0].urlDomain + s[0].urlSuffix, this.hostnode = s[0].urlDomain, this.masterURL = new YSURL(t), Debugger.print("URL: " + this.masterPlaylistUrl())), i = s[0].StartPDT, n = s[0].EndPDT, i && n && (this._streamStart || (this._streamStart = new Date(i)), this._streamWindowStart = new Date(i), this._streamWindowEnd = new Date(n), this._streamWindowSize = (this._streamWindowEnd - this._streamWindowStart) / 1e3, this._streamDuration = (this._streamWindowEnd - this._streamStart) / 1e3, Debugger.print("Stream start: " + this._streamStart.toISOString()), Debugger.print("Stream window start: " + this._streamWindowStart.toISOString()), Debugger.print("Stream window end: " + this._streamWindowEnd.toISOString()), Debugger.print("Stream Window Length: " + this._streamWindowSize), Debugger.print("Stream Duration: " + this._streamDuration)));
      for (var r = 0, a = this.nonLinearBreaks.length, o = 0; o < e.length; o++) {
        var l = e[o], r = YSParseUtils.timecodeFromString(l.position), h = new YSAdBreak(l);
        if (h.adBreakIdentifier = l.id, h.adBreakDescription = l.type, h.startPosition = r, l.vast) {
          for (var u = l.vast.ads, g = 0; g < u.length; g++) {
            var d, c = null, p = YSSession.idRE.exec(u[g].id);
            p ? (d = p[1], (c = new YSAdvert(u[g], this.onAdTimeout.bind(this, d), h)).trackingMonitor = this.onTrackingMonitor.bind(this)) : c = new YSAdvert(u[g], null, h), h.adverts.push(c);
            var A = u[g].getLinear();
            A && (r += A.getDuration())
          }
          "linear" === l.type ? this.replaceOnTimeline(new YSTimelineAdvertElement(h.startPosition, h.getDuration(), h)) : (Debugger.print("Adding NonLinear Break (LivePause) to existing " + this.nonLinearBreaks.length + " break(s)"), this.nonLinearBreaks.push(h))
        }
        else "linear" === l.type ? this.addEmptyBreak(h) : (Debugger.print("Adding Empty NonLinear Break (LivePause) to existing " + this.nonLinearBreaks.length + " break(s)"), this.nonLinearBreaks.push(h))
      }
      this._streamWindowStart && this._streamStart ? this.timeline.UpdateOffset((this._streamWindowStart - this._streamStart) / 1e3) : this.timeline.startOffset = 0;
      var S = 0 < s.length && s[0].isValid && 0 < s[0].duration.length ? YSParseUtils.timecodeFromString(s[0].duration) : r;
      0 < S ? this.timeline.adjustContent(S) : Debugger.print("No duration info at this time"), null !== this.player && "function" == typeof this.player.UpdateTimeline && (Debugger.print("Reporting timeline to player: " + YSParseUtils.timecodeToString(this.timeline.getTotalDuration())), this.player.UpdateTimeline(this.timeline)), a != this.nonLinearBreaks.length && null !== this.player && "function" == typeof this.player.NonLinearsUpdated && this.player.NonLinearsUpdated()
    }, replaceOnTimeline: function (e) {
      var t, i;
      this.timeline && ((t = this.timeline.getElementAtTime(e.offset)) ? t.offset === e.offset && t.duration === e.duration && t.getType() === e.getType() || (i = this.timeline.elements.indexOf(t), this.timeline.elements.splice(i, 1, e)) : this.timeline.appendElement(e))
    }, onTrackingMonitor: function (e, t, i) {
      null !== this.player && "function" == typeof this.player.AnalyticsFired && this.player.AnalyticsFired(e, {
        progress: t,
        asset: i
      })
    }, processAnalytics: function (e, n) {
      this.callSuper(this.processAnalytics, e);
      var s = this;
      this.loader = new VMAPParser(function (e) {
        Debugger.print("New breaks received: " + e.length), s.rebuildTimeline(e), Debugger.print("Timeline rebuilt. Total len: " + s.timeline.getTotalDuration() + " :: " + YSParseUtils.timecodeToString(s.timeline.getTotalDuration()));
        for (var t = 0; t < s.timeline.getAllElements().length; t++) {
          var i = s.timeline.getAllElements()[t];
          Debugger.print("$" + t + ": " + i.getType() + " start: " + YSParseUtils.timecodeToString(i.offset) + " dur: " + YSParseUtils.timecodeToString(i.duration))
        }
        "function" == typeof n && n.call(s, !0, e)
      }, function (e) {
        Debugger.print("!no breaks"), "function" == typeof n && n.call(s, !1, e)
      }), this.loader.parse(e.responseXML), this.isPaused && (0 < this.livePauseUrl.length ? (Debugger.print("Calling LivePause Handler..."), null !== ProtoAjax.DELEGATE ? ProtoAjax.DELEGATE(this.livePauseUrl, {
        onSuccess: function () {
          Debugger.print("Pause handler returned")
        }, onFailure: function () {
          Debugger.print("Pause handler failed")
        }
      }) : new ProtoAjax.Request(this.livePauseUrl, {
        method: "get", evalJSON: !1, evalJS: !1, onSuccess: function () {
          Debugger.print("Pause handler returned")
        }, onFailure: function () {
          Debugger.print("Pause handler failed")
        }
      })) : Debugger.print("No live pause URL available"))
    }, onAdTimeout: function (e) {
      Debugger.print(" !!! Advert Timeout flagged for item: " + e)
    }, updatePosition: function (e) {
      if (this.callSuper(this.updatePosition, e), !this.paused && this.isPlaying) {
        if (this.isInAnAdvert()) {
          this.currentAdvert.paused || (this.stopBreakEndTimer(), this.startBreakEndTimer()), this.currentAdvert.isSuppressed(this.analyticsSuppressed);
          var t = this.currentAdvert.pingWatchdog();
          if (t && 0 < t.length) for (var i, n = 0; n < t.length; n++) {
            null !== this.player && "function" == typeof this.player.AnalyticsFired && (i = t[n].track_id, delete t[n].track_id, this.player.AnalyticsFired(i, t[n]))
          }
        }
        if (null !== this.timeline) {
          var s, r = this.timeline.getElementAtTime(e);
          if (null === r) return void Debugger.print("No timeline element was found");
          if (r.getType() === YSTimelineElement.ADVERT) {
            var a = r.getAdverts().getAdvertForPosition(e);
            if (!a) return void Debugger.print("Could not locate current advert!");
            var o = a.getMediaID(), l = this.currentAdvert ? this.currentAdvert.getMediaID() : "";
            this.currentAdvert === a || (Debugger.print("Different ad found"), this.isInAnAdvert() && (Debugger.print("Shutting down advert: " + l), null !== this.player && "function" == typeof this.player.AdvertEnd && this.player.AdvertEnd(l), this.currentAdvert.setActive(!1), null !== this.player && "function" == typeof this.player.UpdateTimeline && this.player.UpdateTimeline(this.timeline), this.currentAdvert = null), Debugger.print("Advert starting with ID: " + o), Debugger.print("Advert Duration: " + a.duration), this.currentAdvert = a, this.currentAdvert.isSuppressed(this.analyticsSuppressed), this.currentAdvert.setActive(!0), this.handleBreakStart(this.getCurrentBreak()), null !== this.player && "function" == typeof this.player.AdvertStart && this.player.AdvertStart(o))
          }
          else {
            this.isInAnAdvert() && (l = this.currentAdvert.getMediaID(), Debugger.print("Shutting down advert: " + l), null !== this.player && "function" == typeof this.player.AdvertEnd && this.player.AdvertEnd(l), this.currentAdvert.setActive(!1), null !== this.player && "function" == typeof this.player.UpdateTimeline && this.player.UpdateTimeline(this.timeline), s = this.currentAdvert.adBreak, this.currentAdvert = null, Debugger.print("BREAK ENDS!"), this.handleBreakEnd(s))
          }
        }
      }
      else Debugger.print("Ignoring position update while not actively playing")
    }
  }), YSPlayerEvents = {
    READY: "ready",
    START: "start",
    END: "complete",
    MUTE: "mute",
    FULLSCREEN: "fullscreen",
    POSITION: "position",
    METADATA: "id3",
    PAUSE: "pause",
    RESUME: "resume",
    SEEK_START: "seek_begin",
    SEEK_END: "seek_end",
    CLICK: "click",
    NONLINEAR: "non_linear",
    COMPANION: "companion",
    STALL: "buffer",
    CONTINUE: "continue",
    LINEAR_EVENT: "linear",
    NONLINEAR_EVENT: "nonlinear",
    COMPANION_EVENT: "comp_event",
    NONLINEAR_BREAK_EVENT: "nl_brk_event",
    NONLINEAR_ADVERT_EVENT: "nl_ad_event",
    ERROR: "error"
  }, YSSessionManager = ClassHelper.makeClass({
    initialize: function () {
      this.session = null, this.listener = null, this.poller = null, this.player = null, this.properties = YSSessionManager.DEFAULTS
    }, getVersion: function () {
      return YSSessionManager.VERSION
    }, isYospaceStream: function () {
      if (!this.session) return !1;
      if (0 < this.session.analyticsUrl.length) return !0;
      if (this.session instanceof YSVoDSession) {
        var e = this.getTimeline();
        if (e && 1 < e.getAllElements().length) return !0
      }
      return !1
    }, notifyDelegate: function (e, t) {
      "function" == typeof this.listener && this.listener.call(this, e, t)
    }, mergeProperties: function (e, t) {
      var i = Object.keys(t);
      if (0 < i.length) for (var n = 0; n < i.length; n++) {
        var s = i[n];
        e.hasOwnProperty(s) && (t[s] = e[s])
      }
    }, createNonLinearSession: function (e, t, i) {
      Debugger.print("Creating for nonLinear: " + e), null !== t && this.mergeProperties(t, this.properties), this.listener = i, this.session = new YSVoDSession(this, e, this.sessionConstructed.bind(this), !0), this.session.beginSession()
    }, createLivePauseSession: function (e, t, i) {
      Debugger.print("Creating for nonLinear: " + e), null !== t && this.mergeProperties(t, this.properties), this.listener = i, this.session = new YSLivePauseSession(this, e, this.sessionConstructed.bind(this)), this.session.beginSession()
    }, createVODSession: function (e, t, i) {
      Debugger.print("Creating for VOD: " + e), null !== t && this.mergeProperties(t, this.properties), this.listener = i, this.session = new YSVoDSession(this, e, this.sessionConstructed.bind(this), !1), this.session.beginSession()
    }, createLiveSession: function (e, t, i) {
      Debugger.print("Creating for Live: " + e), null !== t && (Debugger.print("Merging properties"), this.mergeProperties(t, this.properties)), this.listener = i, this.session = new YSLiveSession(this, e, this.sessionConstructed.bind(this)), this.session.beginSession()
    }, sessionConstructed: function (e, t, i) {
      Debugger.print("Session Init Result: " + e), Debugger.print("Session Init Status: " + t), Debugger.print("Session Init Code: " + i), this.session ? (e === YSSessionResult.INITIALISED && (this.poller = new YOPoller(this.properties.LOW_FREQ, this.properties.HIGH_FREQ), this._analyticsCB = this.onAnalytics.bind(this), this._pingCB = this.session.pingAnalytics.bind(this.session, this._analyticsCB), this.session instanceof YSLivePauseSession || this.session.pingAnalytics(this._analyticsCB)), this.notifyDelegate(e, 0 === t ? i : t)) : Debugger.print("Session was constructed - but has now gone away?")
    }, shutdown: function () {
      Debugger.print("Shutting down AdManagement session"), this.session && (this.session.Destroy(), this.session = null), this.poller && (this.poller.Destroy(), this.poller = null), this.player = null, this.listener = null, this._analyticsCB = null, this._pingCB = null
    }, onAnalytics: function (e, t) {
      this.session ? (e ? this.session.processAnalytics(t, function (e, t) {
        e || Debugger.print("Failed to update analytics")
      }) : Debugger.print("ANALYTICS FAIL"), this.poller.startPolling(!1, this._pingCB)) : Debugger.print("Ignoring analytics response as there is no session")
    }, reportPlayerEvent: function (e, t) {
      if (this.session) switch (e != YSPlayerEvents.POSITION && Debugger.print("Event reported: " + e), e) {
        case YSPlayerEvents.FULLSCREEN:
          !0 === Boolean(t) ? (this.invokeTracking("fullscreen"), this.invokeTracking("expand", !1)) : (this.invokeTracking("exitFullscreen"), this.invokeTracking("collapse", !1));
          break;
        case YSPlayerEvents.MUTE:
          !0 === Boolean(t) ? this.invokeTracking("mute") : this.invokeTracking("unmute");
          break;
        case YSPlayerEvents.POSITION:
          this.session.updatePosition(t);
          break;
        case YSPlayerEvents.NONLINEAR:
          this.session.isInAnAdvert() && (i = this.session.currentAdvert.advert.getNonLinears()) && i.length > t && (n = i[t].getClickThrough(), Debugger.print(" <<>> Should open" + n), this.session.reportNonLinearEvent(t, "click"));
          break;
        case YSPlayerEvents.COMPANION:
          var i;
          this.session.isInAnAdvert() && (i = this.session.currentAdvert.advert.getCompanions()) && i.length > t && (n = i[t].getClickThroughUrl(), Debugger.print(" <<>> Should open" + n), this.session.reportCompanionEvent(t, "click"));
          break;
        case YSPlayerEvents.CLICK:
          var n;
          this.session.isInAnAdvert() && (n = this.session.currentAdvert.advert.getLinear().getClickThrough(), Debugger.print(" <<>> Should open" + n), this.invokeTracking("click"));
          break;
        case YSPlayerEvents.PAUSE:
        case YSPlayerEvents.STALL:
          if (e === YSPlayerEvents.PAUSE && (this.invokeTracking("pause"), this.session instanceof YSLivePauseSession && this.session.setPaused(!0)), this.session.isInAnAdvert() && this.session.currentAdvert.adPaused(), this.session.stopBreakEndTimer(), this.session instanceof YSLivePauseSession) break;
        case YSPlayerEvents.END:
          var s;
          e === YSPlayerEvents.END && this.session.isInAnAdvert() && (this.session.reportLinearEvent("closeLinear"), s = this.session.currentAdvert.adBreak, this.session.currentAdvert.paused = !1, this.session.handleBreakEnd(s), this.session.currentAdvert && (s = this.session.currentAdvert.adBreak) && (Debugger.print("Advert break ended - notifying consumer"), null !== this.session.player && "function" == typeof this.session.player.AdBreakEnd && this.session.player.AdBreakEnd(s))), this.session.isPlaying = !1;
          break;
        case YSPlayerEvents.RESUME:
        case YSPlayerEvents.CONTINUE:
          if (e === YSPlayerEvents.RESUME && (this.invokeTracking("resume"), this.session instanceof YSLivePauseSession && this.session.setPaused(!1)), this.session.isInAnAdvert() && this.session.currentAdvert.adResumed(), this.session instanceof YSLiveSession && null !== this.session.getCurrentBreak() && this.session.startBreakEndTimer(), this.session instanceof YSLivePauseSession) break;
        case YSPlayerEvents.START:
          var r;
          this.session.isPlaying = !0, e === YSPlayerEvents.START && (r = this, setTimeout(function () {
            r.session && r.session.pingAnalytics(r._analyticsCB)
          }, 2e3));
          break;
        case YSPlayerEvents.METADATA:
          this.session.handleMetadata(this.sanitize(t));
          break;
        case YSPlayerEvents.LINEAR_EVENT:
          this.session.reportLinearEvent(t);
          break;
        case YSPlayerEvents.NONLINEAR_EVENT:
          if (!t.hasOwnProperty("which")) return;
          if (!t.hasOwnProperty("event")) return;
          this.session.reportNonLinearEvent(t.which, t.event);
          break;
        case YSPlayerEvents.COMPANION_EVENT:
          if (!t.hasOwnProperty("which")) return;
          if (!t.hasOwnProperty("event")) return;
          this.session.reportCompanionEvent(t.which, t.event);
          break;
        case YSPlayerEvents.NONLINEAR_BREAK_EVENT:
          if (!t.hasOwnProperty("brk")) return;
          if (!t.hasOwnProperty("event")) return;
          this.session.reportNonLinearBreakEvent(t.brk, t.event);
          break;
        case YSPlayerEvents.NONLINEAR_ADVERT_EVENT:
          if (!t.hasOwnProperty("creative")) return;
          if (!t.hasOwnProperty("event")) return;
          this.session.reportNonLinearAdvertEvent(t.creative, t.event)
      }
    }, sanitize: function (e) {
      var t, i, n = {};
      for (var s in e) {
        e.hasOwnProperty(s) && (t = this.makeClean(s), i = this.makeClean(e[s]), n[t] = i)
      }
      return n
    }, makeClean: function (e) {
      for (var t = "", i = 0; i < e.length; i++) 32 <= e.charCodeAt(i) && (t += String.fromCharCode(e.charCodeAt(i)));
      return t
    }, invokeTracking: function (e, t, i) {
      this.session.isInAnAdvert() && this.session.currentAdvert.advert && this.session.currentAdvert.invokeTracking(e, t, i)
    }, registerPlayer: function (e) {
      this.player = e, this.session.setPlayer(this.player)
    }, masterPlaylist: function () {
      return this.session.masterPlaylistUrl()
    }, getTimeline: function () {
      return this.session.timeline
    }, RawID3: function (e) {
      var t = YSID3Parser.ParseArray(e);
      t ? this.reportPlayerEvent(YSPlayerEvents.METADATA, t) : Debugger.print("ID3 parse returned null")
    }
  });
YSSessionManager.createForLivePause = function (e, t, i) {
  var n = new YSSessionManager;
  if (!n) throw new Error("Failed to create new SessionManager instance");
  return n.createLivePauseSession(e, t, i), n
}, YSSessionManager.createForLive = function (e, t, i) {
  var n = new YSSessionManager;
  if (!n) throw new Error("Failed to create new SessionManager instance");
  return n.createLiveSession(e, t, i), n
}, YSSessionManager.createForNonLinear = function (e, t, i) {
  var n = new YSSessionManager;
  if (!n) throw new Error("Failed to create new SessionManager instance");
  return n.createNonLinearSession(e, t, i), n
}, YSSessionManager.createForVoD = function (e, t, i) {
  var n = new YSSessionManager;
  if (!n) throw new Error("Failed to create new SessionManager instance");
  return n.createVODSession(e, t, i), n
}, YSSessionManager.VERSION = "1.8.12", YSSessionManager.DEFAULTS = {
  LOW_FREQ: 4e3,
  HIGH_FREQ: 500,
  AD_DEBUG: !1,
  DEBUGGING: !1,
  STRICT_BREAKS: !1,
  LEGACY_URL_SYNTAX: !1
};
var YSID3Parser = ClassHelper.makeClass();
YSID3Parser.ID3SYNC = 1229206272, YSID3Parser.UNSYNC = 128, YSID3Parser.EXTHDR = 64, YSID3Parser.GetU32 = function (e, t) {
  return YSID3Parser.GetU16(e, t) << 16 | YSID3Parser.GetU16(e, t + 2)
}, YSID3Parser.GetU16 = function (e, t) {
  return YSID3Parser.GetU8(e, t) << 8 | YSID3Parser.GetU8(e, t + 1)
}, YSID3Parser.GetU8 = function (e, t) {
  return e[t]
}, YSID3Parser.ParseArray = function (e) {
  var t = new Uint8Array(e);
  return YSID3Parser.ParseUint8Array(t)
}, YSID3Parser.ParseUint8Array = function (e) {
  var t = {}, i = 0, n = YSID3Parser.GetU32(e, i);
  if (i += 3, (4294967040 & n) != YSID3Parser.ID3SYNC) return Debugger.print("Source data is not an ID3 tag"), null;
  var s = YSID3Parser.GetU16(e, i);
  if (i += 2, 1024 < s) return Debugger.print("ID3 tag version too new - not supported"), null;
  var r = YSID3Parser.GetU8(e, i++);
  if (r & YSID3Parser.UNSYNC || r & YSID3Parser.EXTHDR) return null;
  var a = ((127 & YSID3Parser.GetU8(e, i + 0)) << 21) + ((127 & YSID3Parser.GetU8(e, i + 1)) << 14) + ((127 & YSID3Parser.GetU8(e, i + 2)) << 7) + (127 & YSID3Parser.GetU8(e, i + 3));
  for (i += 4; i < a;) {
    var o = String.fromCharCode(YSID3Parser.GetU8(e, i++)) + String.fromCharCode(YSID3Parser.GetU8(e, i++)) + String.fromCharCode(YSID3Parser.GetU8(e, i++)) + String.fromCharCode(YSID3Parser.GetU8(e, i++)),
      l = ((127 & YSID3Parser.GetU8(e, i + 0)) << 21) + ((127 & YSID3Parser.GetU8(e, i + 1)) << 14) + ((127 & YSID3Parser.GetU8(e, i + 2)) << 7) + (127 & YSID3Parser.GetU8(e, i + 3));
    if (i += 4, 0 == l) break;
    i += 2;
    for (var h = "", u = 0; u < l; u++) {
      var g = YSID3Parser.GetU8(e, i++);
      32 <= g && g < 127 && (h += String.fromCharCode(g))
    }
    t[o] = h
  }
  return t
};
var YSAdBreak = ClassHelper.makeClass({
  initialize: function (e) {
    this.vmapBreak = e, this.adBreakIdentifier = "", this.adBreakDescription = "", this.adverts = [], this.startPosition = 0
  }, Destroy: function () {
    for (; 0 < this.adverts.length;) {
      this.adverts.pop().Destroy()
    }
    this.adverts = null, this.vmapBreak = null
  }, isActive: function () {
    if (!this.adverts || 0 === this.adverts.length) return !1;
    for (var e = 0; e < this.adverts.length; e++) if (this.adverts[e].isActive) return !0;
    return !1
  }, getDuration: function () {
    var e = 0;
    if (this.adverts && 0 !== this.adverts.length) for (var t = 0; t < this.adverts.length; t++) e += this.adverts[t].duration;
    return e
  }, makeNextAvailableAdvertWatched: function () {
    if (this.adverts && 0 < this.adverts.length) {
      for (var e = 0; e < this.adverts.length; e++) if (this.adverts[e].isActive) return this.adverts[e].isActive = !1, this.adverts[e]
    }
    else Debugger.print("makeNextAvailableAdvertWatched: No adverts to mark!");
    return null
  }, getAdvertForPosition: function (e) {
    if (this.adverts && 0 < this.adverts.length) for (var t = this.startPosition, i = 0; i < this.adverts.length; i++) {
      if (t <= e && e - t < this.adverts[i].duration) return this.adverts[i];
      t += this.adverts[i].duration
    } else Debugger.print("No adverts!!");
    return null
  }, getAdvertById: function (e, t) {
    if (this.adverts && 0 < this.adverts.length) {
      for (var i = 0; i < this.adverts.length; i++) if (this.adverts[i].getMediaID() === e) {
        if (!t && !this.adverts[i].isActive) {
          Debugger.print("Skipping watched ad");
          continue
        }
        if (YSSessionManager.DEFAULTS.STRICT_BREAKS) for (var n = 0; n < i; n++) this.adverts[n].isActive && (Debugger.print("Marking ad#" + n + " ID: " + this.adverts[n].getMediaID() + " as watched"), this.adverts[n].isActive = !1);
        return this.adverts[i]
      }
    }
    else Debugger.print("YSAdBreak: getAdvertById: No adverts!!");
    return Debugger.print("YSAdBreak: getAdvertById: No match found for: " + e), null
  }, getPosition: function () {
    return this.vmapBreak ? this.vmapBreak.getPosition() : "unknown"
  }
}), YSAdvert = ClassHelper.makeClass({
  initialize: function (e, t, i) {
    this.isActive = !0, this.advert = e, this.duration = e.getLinear() ? e.getLinear().getDuration() : 0, this.watchdogCallback = t, this.watchdog = null, this.trackingMonitor = null, this.startPosition = void 0, this.alreadyElapsed = 0, this.paused = !1, this.trackingPoint = 0, this.adBreak = i, e.container = this
  }, Destroy: function () {
    this.adBreak = null
  }, getBreak: function () {
    return this.adBreak
  }, getAdvertID: function () {
    var e = "";
    return this.advert && (e = YSSession.idRE.exec(this.advert.id)[1]), e
  }, getCreativeID: function () {
    var e, t = "";
    return !this.advert || (e = this.advert.getLinear()) && (t = e.CreativeID), t
  }, getMediaID: function () {
    var e, t = "";
    return this.advert && (t = (e = YSSession.idRE.exec(this.advert.id)) ? e[2] : this.advert.id), t
  }, isFiller: function () {
    return "filler" === this.advert.AdTitle
  }, hasInteractiveUnit: function () {
    return null !== this.advert && null !== this.advert.getLinear() && this.advert.getLinear().hasInteractiveUnit()
  }, getInteractiveUnit: function () {
    return this.hasInteractiveUnit() ? this.advert.getLinear().interactiveUnit : null
  }, pingWatchdog: function () {
    var e, t, i, n, s, r, a = [];
    if (!this.paused) return null !== this.watchdog && this.stopWatchdog(), this.startWatchdog(this.duration), 0 < this.duration && (e = this.timeElapsed(), t = this.duration, i = "dummyasset", n = this.adBreak, s = "", this.advert.getLinear() && ((r = this.advert.getLinear().getAllMedias()) && 0 < r.length && (i = r[0].src), s = YSParseUtils.timecodeToString(n.getDuration()), this.advert.getLinear().trackProgress(e, i, s)), t / 4 < e && this.trackingPoint < 2 && (Debugger.print(" -=-> First Quartile"), a.push({
      track_id: "firstQuartile",
      progress: e,
      asset: i
    }), this.advert.getLinear().track("firstQuartile", e, i, s), this.trackingPoint = 2), t / 2 < e && this.trackingPoint < 3 && (Debugger.print(" -=-> Midpoint"), a.push({
      track_id: "midpoint",
      progress: e,
      asset: i
    }), this.advert.getLinear().track("midpoint", e, i, s), this.trackingPoint = 3), 3 * t / 4 < e && this.trackingPoint < 4 && (Debugger.print(" -=-> Third Quartile"), a.push({
      track_id: "thirdQuartile",
      progress: e,
      asset: i
    }), this.advert.getLinear().track("thirdQuartile", e, i, s), this.trackingPoint = 4)), a
  }, startWatchdog: function (e) {
    var t;
    null === this.watchdog && ((t = this).watchdog = setTimeout(function () {
      Debugger.print("Ad watchdog timer fired!"), "function" == typeof t.watchdogCallback && t.watchdogCallback.call(this)
    }, 1e3 * e))
  }, stopWatchdog: function () {
    null !== this.watchdog && (clearTimeout(this.watchdog), this.watchdog = null)
  }, isSuppressed: function (e) {
    var t, i, n, s = [];
    if (this.advert && this.advert.getLinear() && (i = this.advert.getLinear().tracking.suppressAnalytics(e)), this.advert && this.advert.nonLinearsTracking && (n = this.advert.nonLinearsTracking.suppressAnalytics(e)), e) return this.stopWatchdog(), null;
    if (i) for (t = 0; t < i.length; t++) s.push(i[t]);
    if (n) for (t = 0; t < n.length; t++) s.push(n[t]);
    return this.isActive && this.startWatchdog(this.duration), s
  }, setActive: function (e) {
    this.isActive ? e ? (this.isActive = e, this.startPosition = (new Date).getTime(), this.alreadyElapsed = 0, this.trackingPoint = 0, this.startWatchdog(this.duration), Debugger.print(" -=-> Creative View/Start"), this.hasInteractiveUnit() || (this.invokeTracking("creativeView", !1), this.invokeTracking("start", !1))) : (Debugger.print(" -=-> Complete"), this.stopWatchdog(), this.paused || (4 <= this.trackingPoint && this.invokeTracking("complete", !1, this.duration), this.isActive = e)) : this.stopWatchdog()
  }, invokeTracking: function (e, t, i) {
    var n, s, r = "", a = void 0 === i ? 0 : i, o = this.adBreak, l = "", h = this.advert.getLinear();
    h && (a = void 0 === i ? this.timeElapsed() : i, (n = h.getAllMedias()) && 0 < n.length && (r = n[0].src), l = YSParseUtils.timecodeToString(o.getDuration()), h.track(e, a, r, l), this.trackingMonitor && "function" == typeof this.trackingMonitor && this.trackingMonitor(e, a, r)), void 0 !== t && !1 === Boolean(t) && (Debugger.print("Tracking non-linears"), (s = this.advert.nonLinearsTracking) && s.track(e, a, r, l))
  }, reportLinearEvent: function (e) {
    this.invokeTracking(e, !0, this.duration)
  }, reportNonLinearEvent: function (e, t) {
    var i, n = "", s = 0, r = this.adBreak, a = "", o = this.advert.getLinear();
    o && (s = this.timeElapsed(), (i = o.getAllMedias()) && 0 < i.length && (n = i[0].src), a = YSParseUtils.timecodeToString(r.getDuration()));
    var l = this.advert.nonLinearsTracking;
    l && l.track(t, s, n, a)
  }, reportCompanionEvent: function (e, t) {
    var i, n = "", s = 0, r = this.adBreak, a = "", o = this.advert.getLinear();
    o && (s = this.timeElapsed(), (i = o.getAllMedias()) && 0 < i.length && (n = i[0].src), a = YSParseUtils.timecodeToString(r.getDuration()));
    var l, h = this.advert.companions;
    0 < h.length && 0 <= e && e < h.length ? (l = h[e].tracking) && l.track(t, s, n, a) : Debugger.print("Reported companion event for invalid companion id: " + e)
  }, timeElapsed: function () {
    return this.paused ? this.alreadyElapsed : this.alreadyElapsed + ((new Date).getTime() - this.startPosition) / 1e3
  }, adPaused: function () {
    this.paused || (Debugger.print(" -=-> Paused"), this.stopWatchdog(), this.alreadyElapsed = this.timeElapsed(), this.paused = !0, this.startPosition = 0)
  }, adResumed: function () {
    this.paused && (Debugger.print(" -=-> Resumed"), this.startPosition = (new Date).getTime(), this.paused = !1, this.pingWatchdog())
  }
}), YSTimeline = ClassHelper.makeClass({
  initialize: function () {
    this.elements = [], this.modified = !1, this.startOffset = 0
  }, Destroy: function () {
    for (; 0 < this.elements.length;) {
      this.elements.pop().Destroy()
    }
    this.elements = null
  }, UpdateOffset: function (e) {
    for (this.startOffset = e; 0 < this.elements.length;) {
      var t = this.elements[0];
      if (t.offset >= e) break;
      if (!(t.offset + t.duration <= e)) {
        var i = e - t.offset;
        if (t.duration -= i, t.offset = e, t instanceof YSTimelineAdvertElement) {
          Debugger.print("Validating advert element");
          var n = t.getAdverts();
          if (t.duration < n.getDuration()) {
            Debugger.print("Pruning is required");
            var s = n.adverts, r = 0;
            if (s && 0 < s.length) {
              Debugger.print("Validating " + s.length + " adverts");
              for (var a, o = s.length - 1; 0 <= o;) {
                if (r >= t.duration) for (Debugger.print("Winding up. Removing from index: " + o); 0 <= o;) s.shift(), o--; else s[o].duration <= t.duration - r ? (Debugger.print("Preserving index: " + o), r += s[o].duration) : (a = t.duration - r, Debugger.print("Truncating index: " + o + " to duration: " + a), r += s[o].duration = a), o--
              }
            }
          }
          else Debugger.print("Prune not required")
        }
        Debugger.print("New duration: " + t.duration);
        break
      }
      this.elements.splice(0, 1)
    }
  }, appendElement: function (e) {
    this.elements.push(e), this.modified = !0
  }, clear: function () {
    this.elements = [], this.modified = !0
  }, getElementAtTime: function (e) {
    for (var t = 0; t < this.elements.length; t++) {
      var i = this.elements[t];
      if (e >= i.offset && e < i.offset + i.duration) return i
    }
    return null
  }, getNextElementForTime: function (e) {
    for (var t = 0; t < this.elements.length; t++) {
      var i = this.elements[t];
      if (i.offset > e) return i
    }
    return null
  }, getAllElements: function () {
    return this.elements
  }, isModified: function () {
    var e = this.modified;
    return this.modified = !1, e
  }, adjustContent: function (e) {
    var t, i = this.getAllElements(), n = 0, s = 0, r = 0, a = this.startOffset;
    for (e += this.startOffset; r < i.length;) {
      var o = i[r];
      n += o.duration, o.getType() !== YSTimelineElement.ADVERT ? this.elements.splice(r, 1) : (o.offset > this.startOffset && (s += o.offset - a, s += o.duration, this.elements.splice(r, 0, new YSTimelineVODElement(a, o.offset - a)), r++), a = o.offset + o.duration, r++)
    }
    a < e && 1 < Math.abs(e - a) && (s += e - a, this.appendElement(new YSTimelineVODElement(a, e - a))), s != n && (0 < this.elements.length && (t = this.elements[this.elements.length - 1], Debugger.print("Range: " + this.elements[0].offset + " to " + (t.offset + t.duration) + " with length: " + s)), this.modified = !0)
  }, getTotalDuration: function () {
    for (var e = 0, t = 0; t < this.elements.length; t++) {
      e += this.elements[t].duration
    }
    return e
  }
}), YSTimelineElement = ClassHelper.makeClass({
  initialize: function (e, t) {
    this.type = "", this.offset = e, this.duration = t
  }, Destroy: function () {
  }, getType: function () {
    return this.type
  }
});
YSTimelineElement.VOD = "vod", YSTimelineElement.ADVERT = "advert", YSTimelineElement.LIVE = "live";
var YSTimelineVODElement = ClassHelper.makeClass(YSTimelineElement, {
  initialize: function (e, t) {
    this.callSuper(this.initialize, e, t), this.type = YSTimelineElement.VOD
  }, Destroy: function () {
    this.callSuper(this.Destroy)
  }
}), YSTimelineLiveElement = ClassHelper.makeClass(YSTimelineElement, {
  initialize: function (e, t) {
    this.callSuper(this.initialize, e, t), this.type = YSTimelineElement.LIVE
  }, Destroy: function () {
    this.callSuper(this.Destroy)
  }
}), YSTimelineAdvertElement = ClassHelper.makeClass(YSTimelineElement, {
  initialize: function (e, t, i) {
    this.callSuper(this.initialize, e, t), this.type = YSTimelineElement.ADVERT, this.adBreak = i
  }, Destroy: function () {
    this.callSuper(this.Destroy), this.adBreak = null
  }, getAdverts: function () {
    return this.adBreak
  }
});
void 0 !== exports && (exports.YSSessionManager = YSSessionManager, exports.AdBreak = AdBreak, exports.YoExtension = YoExtension, exports.YoStream = YoStream, exports.YoBreak = YoBreak, exports.Extensions = Extensions, exports.TrackingEvents = TrackingEvents, exports.VASTAd = VASTAd, exports.VASTAds = VASTAds, exports.VASTCreative = VASTCreative, exports.VASTIcon = VASTIcon, exports.VASTInteractive = VASTInteractive, exports.VASTLinear = VASTLinear, exports.VASTNonLinear = VASTNonLinear, exports.YSAdBreak = YSAdBreak, exports.YSAdvert = YSAdvert, exports.YSID3Parser = YSID3Parser, exports.YSLiveSession = YSLiveSession, exports.YSPlayerEvents = YSPlayerEvents, exports.YSSessionStatus = YSSessionStatus, exports.YSSessionResult = YSSessionResult, exports.YSPlayerPolicy = YSPlayerPolicy, exports.YSSession = YSSession, exports.YSTimeline = YSTimeline, exports.YSTimelineElement = YSTimelineElement, exports.YSTimelineVODElement = YSTimelineVODElement, exports.YSTimelineLiveElement = YSTimelineLiveElement, exports.YSTimelineAdvertElement = YSTimelineAdvertElement, exports.YSVoDSession = YSVoDSession, exports.YSParseUtils = YSParseUtils, exports.Debugger = Debugger, exports.ProtoAjax = ProtoAjax);
