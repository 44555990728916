import {FetchRequestBase} from "./FetchRequestBase";
import deepmerge from "deepmerge";
import {IAuthenticationComponent} from "../../services";
import { FetchRequest, TFetchMethod } from "./FetchRequest";
import {Logger as LoggerSingleton, NetworkError, NetworkErrorCodes} from "@tv4/one-playback-sdk-shared";

const Logger = LoggerSingleton.createLoggerContext('FetchRequestFactory');


export class FetchRequestFactory {
    private authenticationComponent?: IAuthenticationComponent;
    private RequestClass?: new (request: RequestInfo, options: FetchRequestOptions, fetchMethod?: TFetchMethod) => FetchRequestBase;
    private fetchMethod: TFetchMethod | undefined = undefined;

    public options: FetchRequestOptions = {
        headers: {
            "content-type": "application/json"
        },
        throwHttpErrors: false,
        useAuthentication: true,
        ignoreGlobalHeaders: false,
        method: 'POST'
    };

    constructor(options?: FetchRequestOptions) {
        if (options) {
            this.setOptions(options);
        }
        this.setRequestClass<FetchRequest>(FetchRequest);
    }

    public async fetch(requestUrl: RequestInfo, options: FetchRequestOptions = {}): Promise<FetchRequestResponse | NetworkError> {
        let request: FetchRequestBase;
        try {
            request = await this.generateRequest(requestUrl, options)
        } catch (e) {
            // @ts-ignore
            return e;
        }

        return await request.send();
    }

    public async generateRequest(requestUrl: RequestInfo, options: FetchRequestOptions = {}): Promise<FetchRequestBase> {
        if (!this.RequestClass) {
            throw new Error(`Error class:FetchRequestFactory[generateRequest] : Missing RequestClass!`);
        }
        // //Ensure that options contains headers
        options.headers = options.headers || {}
        if (options.ignoreGlobalHeaders == null) {
            options.ignoreGlobalHeaders = this.options.ignoreGlobalHeaders;
        }

        // |--------------------------|
        // | Merging Global Header
        // |--------------------------|
        if (options.ignoreGlobalHeaders === false) {
            // merge global and local header and convert to Headers instance
            if (this.options.headers && Object.keys(this.options.headers).length > 0) {
                options.headers = Object.assign({}, this.options.headers, options.headers);
            }
        }

        // merge retry settings
        if (this.options.retryOptions || options.retryOptions) {
            //missing retry...
        }

        if (options.throwHttpErrors == null) {
            options.throwHttpErrors = this.options.throwHttpErrors;
        }

        if (options.useAuthentication == null) {
            options.useAuthentication = this.options.useAuthentication;
        }

        options.method = options.method || this.options.method;
        // |--------------------------|
        // | Authorization
        // |--------------------------|
        if (this.authenticationComponent) {
            if (!this.authenticationComponent.isAuthenticationDelegated) {
                // always refresh for fresh access token right before request is made
                if (options.useAuthentication === true) {
                    try {
                        const accessToken = await this.authenticationComponent.getAccessToken();
                        if (accessToken) {
                            options.headers['Authorization'] = `Bearer ${accessToken}`;
                        }
                    }
                    catch (e) {
                        throw new NetworkError({
                            code: NetworkErrorCodes.MissingAuthentication,
                            message: "Authentication missing.",
                            details: {
                                origin: "FetchRequestFactory",
                                domain: "generateRequest",
                                request: requestUrl,
                                options
                            },
                            originalError: e,
                            fatal: true
                        });
                    }
                }
            }
            else {
                Logger.debug(`class:FetchRequestFactory[generateRequest] : the provided authenticationComponent has delegated the authentication process!`);
            }

            // |--------------------------|
            // | Profile-Header
            // |--------------------------|
            if (typeof this.authenticationComponent.getProfileHeader === 'function' && this.options.useProfileHeader === true) {
                const profileHeader = this.authenticationComponent.getProfileHeader();
                if (profileHeader != null) {
                    options.headers['x-profile'] = profileHeader;
                }
            }
        }

        if (requestUrl.toString()[0] === '/' && this.options.baseUrl) {
            requestUrl = (`${this.options.baseUrl}${requestUrl.toString()}`);
        }

        return new this.RequestClass(requestUrl, options, this.fetchMethod);
    }

    public setRequestClass<T extends FetchRequestBase>(requestClass?: new (request: RequestInfo, options: FetchRequestOptions) => T): FetchRequestFactory {
        this.RequestClass = requestClass
        return this;
    }

    public setFetchMethod(fetchMethod: TFetchMethod | undefined) {
        this.fetchMethod = fetchMethod;
    }

    public setOptions(options: FetchRequestOptions) {
        if (options.authenticationComponent) {
            this.authenticationComponent = options.authenticationComponent;
            delete options.authenticationComponent;

            if (!this.authenticationComponent.isAuthenticationDelegated) {
                this.authenticationComponent.isAuthenticationDelegated = false;
            }
        }

        this.options = deepmerge(this.options, options);
    }

    public async isAuthenticated(): Promise<boolean> {
        if (this.authenticationComponent) {
            return this.authenticationComponent.isAuthenticated();
        }

        return false;
    }
}

export interface FetchRetryOptions {
    afterStatusCodes: Array<number> | undefined;
    backoff: number | undefined;
    enabled: boolean;
    limit: number | undefined;
    statusCodes: Array<number> | undefined;

}

export interface FetchRequestOptions extends RequestInit {
    baseUrl?: string;
    headers?: any;
    retryOptions?: FetchRetryOptions;
    throwHttpErrors?: boolean;
    useAuthentication?: boolean;
    useProfileHeader?: boolean;
    ignoreGlobalHeaders?: boolean;
    authenticationComponent?: IAuthenticationComponent;
    timeoutDelayInSec?: number
}

export class FetchRequestResponse {
    public statusCode: number = 0;
    public statusText: string = '';
    public request: RequestInfo;
    public options: FetchRequestOptions;
    public responseBody: any;
    public responseBodyType?: 'json' | 'text';
    public originalResponse: Response | undefined;
    public responseHeaders: Headers = new Headers();
    public ok: boolean = false;

    constructor(request: RequestInfo, options: FetchRequestOptions) {
        this.request = request;
        this.options = options;
    }

    populate(res: Response) {
        this.originalResponse = res;

        this.responseHeaders = res.headers;

        if (this.originalResponse.ok != null) {
            this.ok = this.originalResponse.ok;
        }

        this.statusCode = this.originalResponse.status || this.statusCode;
        this.statusText = this.originalResponse.statusText || this.statusText;
    }
}
