const Utils = require('../../utils/Utils');

module.exports = class UiManager {
    constructor(args) {
        const { config } = args;

        this.config = config;
        this.domElements = {
            castElement: null,
            errorContainer: null,
            /** @type {HTMLElement} */
            mediaElement: null,
            overlayContainer: null,
            textContainer: null,
            touchControls: null
        };
        this.insertOverlayBefore = null;
        this.overlays = {};

        if (this.config.features.ui.injectDefaultStyling) {
            const cssClasses = `
                        @keyframes fadeIn
                        {
                            0%
                            {
                                opacity: 0;
                            }
                        
                            25%
                            {
                                opacity: 0.25;
                            }
                        
                            50%
                            {
                                opacity: 0.5;
                            }
                        
                            75%
                            {
                                opacity: 0.75;
                            }
                        
                            100%
                            {
                                opacity: 1;
                            }
                        }
                        
                        @keyframes fadeOut
                        {
                            0%
                            {
                                opacity: 1;
                            }
                        
                            25%
                            {
                                opacity: 0.75;
                            }
                        
                            50%
                            {
                                opacity: 0.5;
                            }
                        
                            75%
                            {
                                opacity: 0.25;
                            }
                        
                            100%
                            {
                                opacity: 0;
                            }
                        }
                        
                        body
                        {
                            background-color: #000000;
                            overflow: hidden;
                        }
                        
                        .error-container
                        {
                            display: none;
                        }
                        
                        .error-container:not(:empty)
                        {
                            background-color: black;
                            border-radius: 10px;
                            color: white;
                            display: block;
                            font-size: 2em;
                            left: 50%;
                            padding: 10px;
                            position: absolute;
                            text-align: center;
                            top: 50%;
                            transform: translate(-50%, -50%);
                            width: 80%;
                        }
                        
                        .error-container > .code
                        {
                            font-size: 0.5em;
                        }
                        
                        .overlay-container > .debug-container
                        {
                            background-color: rgba(0, 0, 0, 0.8);
                            border-radius: 20px;
                            color: white;
                            display: none;
                            left: 5%;
                            max-width: 88%;
                            padding: 1%;
                            position: absolute;
                            top: 5%;
                            word-break: break-all;
                            z-index: 99999999;
                        }
                        
                        .overlay-container > .debug-container.visible
                        {
                            display: block;
                        }
                        
                        .overlay-container > .notifications-container::before
                        {
                            background: no-repeat center center;
                            background-size: 40px 40px;
                            border-radius: 5px 0 0 5px;
                            content: '';
                            display: block;
                            height: 100%;
                            left: 0;
                            min-width: 60px;
                            position: absolute;
                            top: 0;
                            width: 10%;
                        }
                        
                        .overlay-container > .notifications-container
                        {
                            background-color: rgba(6, 17, 31, 0.6);
                            border-radius: 5px;
                            color: white;
                            line-height: 120%;
                            max-width: 41%;
                            opacity: 0;
                            padding: 1% 1% 1% 1%;
                            position: absolute;
                            right: 5%;
                            text-align: left;
                            top: 5%;
                            z-index: 99999999;
                        }
                        
                        .overlay-container > .notifications-container.fadeOut
                        {
                            animation-direction: alternate;
                            animation-duration: 1s;
                            animation-iteration-count: 1;
                            animation-name: fadeOut;
                            animation-timing-function: ease-out;
                        }
                        
                        .overlay-container > .notifications-container.fadeIn
                        {
                            animation-direction: alternate;
                            animation-duration: 1s;
                            animation-iteration-count: 1;
                            animation-name: fadeIn;
                            animation-timing-function: ease-in;
                            opacity: 1;
                        }
                        
                        .overlay-container > .sample-container
                        {
                            background-color: rgba(0, 0, 0, 0.8);
                            border-radius: 20px;
                            color: white;
                            display: none;
                            left: 5%;
                            max-width: 88%;
                            padding: 1%;
                            position: absolute;
                            top: 5%;
                            word-break: break-all;
                        }
                        
                        .overlay-container > .sample-container.visible
                        {
                            display: block;
                        }
                        
                        cast-media-player
                        {
                            animation-direction: alternate;
                            animation-duration: 1s;
                            animation-iteration-count: 1;
                            animation-name: fadeIn;
                            animation-timing-function: ease-in;
                            opacity: 1;
                        }
`;
            var style = document.createElement('style');
            style.type = 'text/css';
            style.innerHTML = cssClasses;
            document.getElementsByTagName('head')[0].appendChild(style);
        }
    }

    get ready() {
        return (this.domElements && this.domElements.castElement && this.domElements.errorContainer)
    }

    /** @private */
    setupDom() {
        this.domElements.castElement = document.querySelector('cast-media-player');
        this.domElements.mediaElement = this.domElements.castElement.getMediaElement();

        this.domElements.textContainer = document.createElement('div');
        this.domElements.textContainer.setAttribute('class', 'text-container');

        this.domElements.overlayContainer = document.createElement('div');
        this.domElements.overlayContainer.setAttribute('class', 'overlay-container');

        this.domElements.errorContainer = document.createElement('div');
        this.domElements.errorContainer.setAttribute('class', 'error-container');

        Utils.insertAfter(this.domElements.textContainer, this.domElements.castElement);
        Utils.insertAfter(this.domElements.overlayContainer, this.domElements.textContainer);
        Utils.insertAfter(this.domElements.errorContainer, this.domElements.overlayContainer);

        return this;
    }

    /** @private */
    callOnAllOverlays(func) {
        Object.keys(this.overlays).forEach(key => {
            try {
                this.overlays[key][func]();
            } catch (e) {
            }
        });
    }

    /** @private */
    updateDomElements() {
        this.domElements.touchControls = document.querySelector('touch-controls');
    }

    fadeOut() {
        this.domElements.castElement.style.transition = 'opacity 1s linear';
        this.domElements.castElement.style.opacity = 0;
    }

    hideAdCounter() {
        this.updateDomElements();

        if (this.domElements.castElement.shadowRoot && this.domElements.castElement.shadowRoot.querySelector) {
            try {
                const breakInfo = this.domElements.castElement.shadowRoot.querySelector('#castPlayer > tv-overlay').shadowRoot.querySelector('#castBreakMetadata > div.breakInfo')
                if (breakInfo && breakInfo.style) {
                    breakInfo.style.display = 'none';
                }
            } catch (e) {
                console.warn(`## [UiManager] hideAdCounter | could not find breakInfo`);
            }
        }

        if (this.domElements.touchControls && this.domElements.touchControls.shadowRoot && this.domElements.touchControls.shadowRoot.querySelector) {
            const breakCountdown = this.domElements.touchControls.shadowRoot.querySelector('.break-countdown');
            if (breakCountdown && breakCountdown.style) {
                breakCountdown.style.display = 'none';
            }
        }
    }

    /**
     * Pause is being requested by sender
     */
    pause() {
        this.callOnAllOverlays('pause');

        return Promise.resolve();
    }

    /**
     * Play is being requested by sender
     */
    play() {
        this.callOnAllOverlays('play');

        return Promise.resolve();
    }

    registerOverlay(name, factory) {
        const unifiedOverlays = [
            'Debug',
            'Notifications'
        ];
        if (!this.insertOverlayBefore) {

            unifiedOverlays.forEach(o => {
                if (!this.insertOverlayBefore && this.overlays[o]) {
                    this.insertOverlayBefore = this.overlays[o].container;
                }
            });
        }

        const container = document.createElement('div');
        container.className = `${name.toLowerCase()}-container`;

        if (this.insertOverlayBefore && !unifiedOverlays.includes(name)) {
            this.domElements.overlayContainer.insertBefore(container, this.insertOverlayBefore);
        } else {
            this.domElements.overlayContainer.appendChild(container);
        }

        const overlay = factory({
            container: container
        });
        overlay.initialize();

        return this.overlays[name] = overlay;
    }

    /**
     * @param {string} [notification]
     * @param {number} [duration]
     */
    showNotification(notification, duration) {
        if (!this.ready) return;
        if (this.overlays.Notifications && this.overlays.Notifications.showNotification) {
            this.overlays.Notifications.showNotification(notification, duration);
        }
    }

    clearNotification() {
        if (!this.ready) return;
        if (this.overlays.Notifications && this.overlays.Notifications.clearNotification) {
            this.overlays.Notifications.clearNotification();
        }

    }

    showError(message) {
        if (!this.ready) return;
        this.domElements.errorContainer.innerHTML = message;
        this.domElements.castElement.style.display = 'none';
    }

    clearError() {
        if (!this.ready) return;
        this.domElements.errorContainer.innerHTML = '';
        this.domElements.castElement.style.display = 'block';
    }

    //|todo UiManager.js [IMP][Minor]: Missing a bit of work!! (untestted);
    /*debugShowControls(){
      var castElementRoot = this.domElements.castElement.shadowRoot;
      var controlsTimelineStyle = castElementRoot.querySelector('.controlsTimeline').style;
      controlsTimelineStye.display = 'block';
      controlsTimelineStyle.visibility = 'visible';
      controlsTimelineStyle.opacity = 1;
      var metadataStyle = castElementRoot.querySelector('.metadata').style;
      metadataStyle.visibility = 'visible';
      metadataStyle.opacity = 1;
    }*/


    reset() {
        this.callOnAllOverlays('reset');
    }

    destroy() {
        this.callOnAllOverlays('destroy');

        this.domElements.errorContainer.parentNode.removeChild(this.domElements.errorContainer);
        this.domElements.overlayContainer.parentNode.removeChild(this.domElements.overlayContainer);
        this.domElements.textContainer.parentNode.removeChild(this.domElements.textContainer);

        this.insertOverlayBefore = null;
        this.overlays = {};
    }
};