const Content = require('./Content');
const Credentials = require('./Credentials');
const Language = require('./Language');
const LoadAdvertisement = require('./LoadAdvertisement');
const LoadTracking = require('./LoadTracking');
const Sender = require('./Sender');
const Utils = require('../utils/Utils');

/**
 * @typedef {object} TLoad
 * @property {boolean} [autoplay=true]
 * @property {Content} content
 * @property {Credentials} [credentials]
 * @property {number} [currentTime=undefined]
 * @property {LoadAdvertisement} [advertisement]
 * @property {Language} [language]
 * @property {Sender} [sender]
 * @property {LoadTracking} [tracking]
 */
/**
 * @type {TLoad}
 */
class Props {
    constructor() {
        this.advertisement = this.autoplay = this.content = this.credentials = this.currentTime = this.language =
          this.sender = this.tracking = undefined;
    }
}

class Load extends Props {
    /**
     * @param {TLoad} args
     */
    constructor(args = {}) {
        super();

        args.autoplay = Utils.booleanOrDefault(args.autoplay, true);

        Utils.extend(this, {
            autoplay: true
        }, args);
    }
}

module.exports = Load;