module.exports = class Interceptor {
    constructor({ key, event }) {
        this.callStack = [];
        this.key = key;
        this.event = event;
        this.preRequest = null;
        this.requestTimeoutRef = null;
    }

    /**
     * @param {object} requestEvent
     */
    preRequestHandler(requestEvent) {
        this.preRequest = requestEvent;

        if (this.requestTimeoutRef) {
            clearTimeout(this.requestTimeoutRef);
            this.requestTimeoutRef = null;
        }
        this.requestTimeoutRef = setTimeout(() => {
            this.preRequest = null;
        }, 1000);
    }

    /**
     * @param {function} action
     * @param {boolean} interrupt
     */
    addMiddleware(action, interrupt = true) {
        this.callStack.push({ action: action, interrupt: interrupt });
    }

    interceptHandler(request) {
        let _request = request;

        if (this.preRequest && this.preRequest.requestData && this.preRequest.requestData.type === request.type) {
            _request.senderId = this.preRequest.senderId;
        }
        this.preRequest = null;

        let masterResolve;
        let masterReject;
        const masterPromise = new Promise((resolve, reject) => {
            masterResolve = resolve;
            masterReject = reject;
        });
        masterPromise.catch(() => {
        });

        return new Promise(async (resolve, reject) => {
            if (_request.senderId === 'local' && !window.receiver.loadingByEntity) {
                return resolve(_request);
            }
            let rejected = false;
            if (this.callStack.length > 0) {
                for (let i in this.callStack) {
                    if (!this.callStack[i].interrupt) {
                        this.callStack[i].action({ request });

                        continue;
                    }

                    try {
                        _request = await new Promise((internalResolve, internalReject) => {
                            this.callStack[i].action({
                                masterPromise,
                                pass: () => internalResolve(_request),
                                request: _request,
                                resolve: internalResolve,
                                reject: internalReject
                            });
                        });
                    } catch (e) {
                        rejected = true;
                        masterReject(e);
                        reject(e);
                    }
                }
            }

            if (!rejected) {
                masterResolve(_request);
                resolve(_request);
            }
        });
    }
};