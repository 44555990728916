// |--------------------------|
// | guid
// |--------------------------|
import { Id3Cue, TYospaceTimedDataObject } from "./Types";

const isBoolean = (value: any): value is boolean => {
    return typeof (value) === 'boolean';
};

const isString = (value: any): boolean => {
    return typeof (value) === 'string' || value instanceof String;
};
export const guid = (format: string | boolean = false): string => {
    const formats = {
        compact: 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx',
        default: 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
    };

    format = isBoolean(format)
        ? (format ? formats.compact : formats.default)
        : (isString(format)
            ? format
            : formats.default);

    return (format || formats.default).replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0;

        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
};

// |-----------------------------|
// | PersistentAdvertisementId
// |-----------------------------|


const anonymousUserId = 'anonymous';
const dataKey = 'advertisement';
const defaultLocalStorageNamespace = 'TV4Play';
const namespacedGetDataStorage = (localStorageNamespace: string, storageKey: string): Record<string, any> | null => {
    const localStorageKey = `${localStorageNamespace}.${storageKey}`;
    const localStorageData = localStorage.getItem(localStorageKey);

    if (localStorageData) {
        try {
            return JSON.parse(localStorageData);
        } catch (e) {}
    }

    return null;
};

const namespacedStoreData = (localStorageNamespace: string, storageKey: string, key: string, data: Record<string, any> | null): void => {
    const localStorageKey = `${localStorageNamespace}.${storageKey}`;
    const dataStorage = namespacedGetDataStorage(localStorageNamespace, storageKey) ?? {};
    dataStorage[key] = data;
    try {
        localStorage.setItem(localStorageKey, JSON.stringify(dataStorage));
    } catch (e) {}
};

const namespacedStoreUserData = (localStorageNamespace: string, userId: string, key: string, data: Record<string, any> | null): void => namespacedStoreData(localStorageNamespace, userId, key, data);
const storeUserData = (userId: string, key: string, data: Record<string, any> | null): void => namespacedStoreUserData(defaultLocalStorageNamespace, userId, key, data);
const namespacedGetData = (localStorageNamespace: string, storageKey: string, key: string): Record<string, any> | null => {
    const dataStorage = namespacedGetDataStorage(localStorageNamespace, storageKey);

    return (dataStorage && dataStorage[key]) ?? null;
};

const namespacedGetUserData = (localStorageNamespace: string, userId: string, key: string): Record<string, any> | null => namespacedGetData(localStorageNamespace, userId, key);

export const getUserData = (userId: string, key: string): Record<string, any> | null => namespacedGetUserData(defaultLocalStorageNamespace, userId, key);
export const getPersistentAdvertisementId = (userId?: string): string => {
    const userIdKey = userId || anonymousUserId;
    const userData = getUserData(userIdKey, dataKey) ?? {};
    const persistentAdvertisementId = userData.persistentAdvertisementId;

    // persistent id is already user id
    if (userData.persistentAdvertisementId === userId) return persistentAdvertisementId;

    // persistent id is not current user id, update it
    userData.persistentAdvertisementId = userId || guid();
    storeUserData(userIdKey, dataKey, userData);

    return userData.persistentAdvertisementId;
};

// |--------------------------|
// |YospaceTimedData
// |--------------------------|

export const parseYospaceTimedDataObjectFromId3Cues = (id3Cue: Id3Cue): TYospaceTimedDataObject => ({
    time: id3Cue.startTime,
    tag: id3Cue.tags.reduce((acc, curr) => {
        return {
            ...acc,
            [curr.key]: String.fromCharCode(...Array.from(curr.data))
        }
    }, {})
});

// |--------------------------|
// | YospaceTimedData
// |--------------------------|;

const byteArrayToString = (byteArray: Uint8Array): string => {
    let s = '';
    for (let i = 0; i < byteArray.length; i += 1) {
        s += String.fromCharCode(byteArray[i]);
    }
    return s;
};

export class ID3Parser {
    static Parse(byteArray: Uint8Array, startTime: number): Id3Cue {
        try {
            const id3Cue: Id3Cue = {
                startTime,
                tags: []
            };
            let offset = 0;
            if (!(byteArray[0] === 0x49 && byteArray[1] === 0x44 && byteArray[2] === 0x33)) {
                // Should be 'ID3'
                throw new Error('Invalid Id3 payload');
            }
            const flags = byteArray[5];
            const usesSynch = (flags & 0x80) !== 0;
            const hasExtendedHdr = (flags & 0x40) !== 0;
            const size =
                (byteArray[9] & 0xff) |
                ((byteArray[8] & 0xff) << 7) |
                ((byteArray[7] & 0xff) << 14);
            offset += 10; // Header size is 10 bytes
            if (usesSynch) {
                throw new Error('Uses Synch which is not supported yet');
            }
            if (hasExtendedHdr) {
                throw new Error('Has extended hdr which is not supported yet');
            }
            const frameHeaderSize = byteArray[3] < 3 ? 6 : 10;
            while (offset < byteArray.length) {
                if (offset > size + 10) {
                    break;
                }
                const key = byteArrayToString(byteArray.slice(offset, offset + 4));
                const frameSize =
                    (byteArray[offset + 7] & 0xff) |
                    ((byteArray[offset + 6] & 0xff) << 8) |
                    ((byteArray[offset + 5] & 0xff) << 16) |
                    (byteArray[offset + 4] << 24);
                if (frameSize > 0) {
                    id3Cue.tags.push({
                        key,
                        data: byteArray.slice(
                            offset + 11,
                            offset + frameSize + frameHeaderSize
                        )
                    });
                }
                offset += frameSize + frameHeaderSize;
            }

            return id3Cue;
        }
        catch (e) {
            throw new Error(`Error class:ID3Parser[Parse] : error parsing ID3 cues \n${e}!`);
        }
    }
};

export const addOrAppendQueryParameter = (searchParams: URLSearchParams, key: string, value: string) => {
    const existingT = searchParams.has(key) && searchParams.get(key);

    searchParams.set(key, existingT ? `${existingT},${value}` : value);
};
