import { ErrorCodes } from '@tv4/unified-receiver';
import { ServiceLayerErrorCodes, Translations } from '../Constants';

export const en = {
  [ErrorCodes.Generic]: 'Something went wrong - please try again later',
  [ErrorCodes.UpdateRequired]: 'You need to update your device',
  [ErrorCodes.TryingToRecover]: 'A problem occurred<br /><br />Trying to reconnect',

  [ServiceLayerErrorCodes.Api.MissingAssetOrMedia]: 'Content not found - please try again later',
  [ServiceLayerErrorCodes.Configuration.MissingSdkService]: 'Failed to start - please try again later',
  [ServiceLayerErrorCodes.Device.Gen1NotSupported]:
    'It is no longer possible to use TV4 Play on your Chromecast device. You need a newer Chromecast to continue casting in the future. Learn more at tv4play.se/faq.',
  [ServiceLayerErrorCodes.GeoRestricted.Generic]: 'This content is not available in your area',
  [ServiceLayerErrorCodes.GeoRestricted.Portability]: 'This content is not available in your area',
  [ServiceLayerErrorCodes.Load.Cancelled]: 'Load cancelled - please try again later',
  [ServiceLayerErrorCodes.StreamLimit.Generic]:
    "It seems you're watching this content on more than two devices at the same time",
  [ServiceLayerErrorCodes.Credentials.MissingOrInvalidRefreshToken]: 'Authentication failed',

  [Translations.Overlay.CustomPauseAd.TopBar]: 'Advertisement: Click play to continue watching',
};
