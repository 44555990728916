import {
    AdvertisementEndedEvent,
    AdvertisementStartedEvent,
    AdvertisementTimeUpdateEvent,
    AdvertisementBreakEndedEvent,
    AdvertisementBreakStartedEvent,
    AudioTrackChangedEvent,
    BaseComponent,
    BitrateChangedEvent,
    BufferedEvent,
    BufferingEvent,
    CdnChangedEvent,
    CustomTrackingEvent,
    DroppedFramesEvent,
    LoadedEvent,
    LoadingEvent,
    PausedEvent,
    PlaybackErrorEvent,
    PlayingEvent,
    SeekedEvent,
    SeekingEvent,
    StartingEvent,
    StoppedEvent,
    StreamCueEvent,
    StreamInfoEvent,
    TextTrackChangedEvent,
    TimeUpdateEvent,
    TrackingInfoEvent,
    VolumeChangedEvent
} from '@tv4/one-playback-sdk-shared';

import { FetchRequestFactory } from '../../network';
import { ITracker } from './ITracker';

export class BaseTracker extends BaseComponent implements ITracker {
    protected requestFactory?: FetchRequestFactory;

    constructor(trackerName: string) {
        super(trackerName);
    }

    public attachRequestFactory(factory: FetchRequestFactory): void {
        this.requestFactory = factory;
    }

    // ITracker

    initialize(configuration?: any) { throw new Error('BaseTracker::initialize: initialize is a required method and must be implemented!'); }

    advertisementEnded(event: AdvertisementEndedEvent): void {};
    advertisementStarted(event: AdvertisementStartedEvent): void {};
    advertisementBreakEnded(event: AdvertisementBreakEndedEvent): void {};
    advertisementBreakStarted(event: AdvertisementBreakStartedEvent): void {};
    advertisementTimeUpdate(event: AdvertisementTimeUpdateEvent): void {};
    audioTrackChanged(event: AudioTrackChangedEvent): void {};
    bitrateChanged(event: BitrateChangedEvent): void {};
    buffered(event: BufferedEvent): void {};
    buffering(event: BufferingEvent): void {};
    cdnChanged(event: CdnChangedEvent): void {};
    droppedFrames(event: DroppedFramesEvent): void {};
    error(event: PlaybackErrorEvent): void {};
    loaded(event: LoadedEvent): void {};
    loading(event: LoadingEvent): void {};
    paused(event: PausedEvent): void {};
    playing(event: PlayingEvent): void {};
    seeked(event: SeekedEvent): void {};
    seeking(seeking: SeekingEvent): void {};
    starting(starting: StartingEvent): void {};
    stopped(event: StoppedEvent): void {};
    custom(event: CustomTrackingEvent): void {};
    streamCue(event: StreamCueEvent): void {};
    streamInfo(event: StreamInfoEvent): void {};
    textTrackChanged(event: TextTrackChangedEvent): void {};
    timeUpdate(event: TimeUpdateEvent): void {};
    trackingInfo(trackingInfo: TrackingInfoEvent): void {};
    volumeChanged(event: VolumeChangedEvent): void {};

    async reset(): Promise<void> {
        return Promise.resolve();
    }

    async destroy(): Promise<void> {
        return Promise.resolve();
    }
};