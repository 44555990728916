import { PlaybackEventBase } from './PlaybackEventBaseClass';
import { PlaybackEventTypes } from "../Constants";
import { INormalizedError } from '../errors';

type TErrorEventPayload = {
    error: INormalizedError;
}

export class PlaybackErrorEvent extends PlaybackEventBase<PlaybackEventTypes.ERROR, TErrorEventPayload> {
    constructor(payload: TErrorEventPayload) {
        super(PlaybackEventTypes.ERROR, payload);
    }
}
