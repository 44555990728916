export class UserInfo {
    public userId: string;
    public userIsLoggedIn: boolean;
    public userType?: string;

    constructor(args?: UserInfo) {
        if (args) {
            this.userId = args.userId;
            this.userType = args.userType;
            this.userIsLoggedIn = args.userIsLoggedIn
        }
    }
};