const Utils = require('../../utils/Utils');
const ITracker = require('../ITracker');

module.exports = class ConsoleTracker extends ITracker {
    constructor({ config }) {
        super();

        this.config = config;

        this.consoleFunc = 'info';
    }

    static get Name() {
        return 'Console';
    }

    initialize() {
        console[this.consoleFunc]('ConsoleTracker::initialize');
    }

    advertisementEnded() {
        console[this.consoleFunc]('ConsoleTracker::advertisementEnded');
    }

    advertisementStarted(data) {
        console[this.consoleFunc]('ConsoleTracker::advertisementStarted:', data);
    }

    advertisementBreakEnded(data) {
        console[this.consoleFunc]('ConsoleTracker::advertisementBreakEnded:', data);
    }

    advertisementBreakStarted(data) {
        console[this.consoleFunc]('ConsoleTracker::advertisementBreakStarted:', data);
    }

    advertisementTimeUpdate(data) {
        console[this.consoleFunc]('ConsoleTracker::advertisementTimeUpdate:', data);
    }

    audioTrackChanged(data) {
        console[this.consoleFunc]('ConsoleTracker::audioTrackChanged:', data);
    }

    bitrateChanged(data) {
        console[this.consoleFunc]('ConsoleTracker::bitrateChanged:', data);
    }

    buffered() {
        console[this.consoleFunc]('ConsoleTracker::buffered');
    }

    buffering() {
        console[this.consoleFunc]('ConsoleTracker::buffering');
    }

    cdnChanged(data) {
        console[this.consoleFunc]('ConsoleTracker::cdnChanged:', data);
    }

    drmChanged(data) {
        console[this.consoleFunc]('ConsoleTracker::drmChanged:', data);
    }

    droppedFrames(data) {
        console[this.consoleFunc]('ConsoleTracker::droppedFrames:', data);
    }

    error(data) {
        console[this.consoleFunc]('ConsoleTracker::error:', data);
    }

    pause() {
        console[this.consoleFunc]('ConsoleTracker::pause');
    }

    play() {
        console[this.consoleFunc]('ConsoleTracker::play');
    }

    playbackSessionStarted(data) {
        console[this.consoleFunc]('ConsoleTracker::playbackSessionStarted:', data);
    }

    playbackSessionInitialized() {
        console[this.consoleFunc]('ConsoleTracker::playbackSessionInitialized');
    }

    seekableRangeUpdated(data) {
        console[this.consoleFunc]('ConsoleTracker::seekableRangeUpdated:', data);
    }

    seeked() {
        console[this.consoleFunc]('ConsoleTracker::seeked');
    }

    seeking() {
        console[this.consoleFunc]('ConsoleTracker::seeking');
    }

    streamChanged(data) {
        console[this.consoleFunc]('ConsoleTracker::streamChanged:', data);
    }

    streamCue(data) {
        console[this.consoleFunc]('ConsoleTracker::streamCue:', data);
    }

    streamFinished(data) {
        console[this.consoleFunc]('ConsoleTracker::streamFinished:', data);
    }

    streamLoadStart() {
        console[this.consoleFunc]('ConsoleTracker::streamLoadStart');
    }

    streamLoadedData() {
        console[this.consoleFunc]('ConsoleTracker::streamLoadedData');
    }

    streamLoadedMetadata() {
        console[this.consoleFunc]('ConsoleTracker::streamLoadedMetadata');
    }

    textTrackChanged(data) {
        console[this.consoleFunc]('ConsoleTracker::textTrackChanged:', data);
    }

    timeUpdate(data) {
        //console[this.consoleFunc]('ConsoleTracker::timeUpdate:', data);
    }

    volumeChanged(data) {
        console[this.consoleFunc]('ConsoleTracker::volumeChanged:', data);
    }

    reset() {
        console[this.consoleFunc]('ConsoleTracker::reset');
    }

    destroy() {
        console[this.consoleFunc]('ConsoleTracker::destroy');
    }
};