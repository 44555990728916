const Utils = require('../utils/Utils');

/**
 * @typedef {object} TCredentialsArgs
 * @property {boolean} [autoLogin]
 * @property {string} [password]
 * @property {string} [profileHeader]
 * @property {string} [username]
 * @property {string} [token]
 * @property {string} type
 */
/**
 * @type {TCredentialsArgs}
 */
class Props {
    constructor() {
        this.autoLogin = this.password = this.profileHeader = this.username = this.token = this.type = undefined;
    }
}

class Credentials extends Props {
    /**
     * @param {TCredentialsArgs} args
     */
    constructor(args = {}) {
        super();

        args.autoLogin = Utils.booleanOrDefault(args.autoLogin, true);

        Utils.extend(this, {}, args);
    }
}

module.exports = Credentials;