const { TrackTypes } = require('../Constants');
const { Track } = require('../dtos/');

module.exports = class TrackMapper {
    constructor() {
    }

    static mapTrackType(type) {
        switch (type) {
            case cast.framework.messages.TrackType.AUDIO:
                return TrackTypes.Audio;
            case cast.framework.messages.TrackType.TEXT:
                return TrackTypes.Text;
            case cast.framework.messages.TrackType.VIDEO:
                return TrackTypes.Video;
            default:
                return TrackTypes.Unknown;
        }
    }

    static map(track) {
        if (!track) return null;

        return new Track({
            id: track.trackId,
            type: TrackMapper.mapTrackType(track.type),
            language: track.language,
            name: track.name
        });
    }
};