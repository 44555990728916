//
//  ValidationEventDispatcher.js
//
//  Created by Lars Rothaus on 15/04/2021.
//  Copyright © 15/04/2021 Lars Rothaus. All rights reserved.
//

const {
    EngineEvents,
    TrackManagerEvents
} = require('../Events');

const Listener = require('../event/Listener');

const engineEvents = [
    EngineEvents.AdvertisementEnded,
    EngineEvents.AdvertisementStarted,
    EngineEvents.AdvertisementBreakEnded,
    EngineEvents.AdvertisementBreakStarted,
    EngineEvents.AdvertisementTimeUpdate,
    EngineEvents.BitrateChanged,
    EngineEvents.Buffered,
    EngineEvents.Buffering,
    EngineEvents.CdnChanged,
    EngineEvents.DrmChanged,
    EngineEvents.DroppedFrames,
    EngineEvents.LoadedData,
    EngineEvents.LoadedMetadata,
    EngineEvents.LoadStart,
    EngineEvents.Pause,
    EngineEvents.Play,
    EngineEvents.SeekableRangeUpdated,
    EngineEvents.Seeked,
    EngineEvents.Seeking,
    EngineEvents.StreamChanged,
    EngineEvents.StreamCue,
    EngineEvents.StreamFinished,
    EngineEvents.SystemVolumeChanged,
    //EngineEvents.TimeUpdate
];

const trackManagerEvents = [
    TrackManagerEvents.AudioTrackChanged,
    TrackManagerEvents.TextTrackChanged
];

module.exports = class ValidationEventDispatcher extends Listener {
    constructor({ communicationManager, engine, trackManager }) {
        super();

        this.communicationManager = communicationManager;
        this.engine = engine;
        this.trackManager = trackManager;

        this.engineEventListeners = {};
        this.trackManagerEventListeners = {};

        this.registerEventListeners();
    }

    /** @private */
    registerEventListeners() {
        engineEvents.forEach(event => {
            this.listen(this.engine, this.engineEventListeners, event, e => {
                this.communicationManager.sendUnifiedMessage({
                    type: 'Event.ValidationEvent',
                    data: {
                        type: event,
                        ...e
                    }
                });
            });
        });

        trackManagerEvents.forEach(event => {
            this.listen(this.trackManager, this.trackManagerEventListeners, event, e => {
                this.communicationManager.sendUnifiedMessage({
                    type: 'Event.ValidationEvent',
                    data: {
                        type: event,
                        ...e
                    }
                });
            });
        });
    }

    /** @private */
    unregisterEventListeners() {
        Object.keys(this.engineEventListeners)
          .forEach(e => this.unlisten(this.engine, this.engineEventListeners, e));
        this.engineEventListeners = {};

        Object.keys(this.trackManagerEventListeners)
          .forEach(e => this.unlisten(this.trackManager, this.trackManagerEventListeners, e));
        this.trackManagerEventListeners = {};
    }

    reset() {
    }

    destroy() {
        this.unregisterEventListeners();

        this.communicationManager = null;
        this.engine = null;
        this.trackManager = null;
    }
};