import { StandardErrorArgs, StandardError } from '@tv4/one-playback-sdk-shared';

export enum ServiceErrorCodes {
    MissingConfiguration = 'MISSING_CONFIGURATION',
    MissingAuthentication = 'MISSING_AUTHENTICATION',

    BadResponseError = 'BAD_RESPONSE_ERROR',
    MissingCredentials = 'MISSING_CREDENTIALS',
    MappingError = 'MappingError',
    UnknownError = 'UNKNOWN_ERROR',
    InternalError = 'INTERNAL_ERROR',
}

export type TServiceErrorArgs = StandardErrorArgs & {

};

export class ServiceError extends StandardError {
    constructor(args: TServiceErrorArgs) {
        super(args)
    }
}
