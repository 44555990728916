import { en } from './en';
import { sv } from './sv';
import { fi } from './fi';

export const Translations: Record<string, object> = {
  TV4: {
    en: { ...en },
    sv: { ...sv },
  },
  MTV: {
    en: { ...en },
    fi: { ...fi },
  },
  FK: {
    en: { ...en },
    sv: { ...sv },
  },
};
