//
//  EmsgParser.ts
//
//  Created by Lars Rothaus on 14/06/2021.

/*
aligned(8) class DASHEventMessageBox extends FullBox('emsg', version, flags = 0) {
    if (version==0) {
        string              scheme_id_uri;
        string              value;
        unsigned int(32)    timescale;
        unsigned int(32)    presentation_time_delta;
        unsigned int(32)    event_duration;
        unsigned int(32)    id;
    } else if (version==1) {
        unsigned int(32)    timescale;
        unsigned int(64)    presentation_time;
        unsigned int(32)    event_duration;
        unsigned int(32)    id;
        string              scheme_id_uri;
        string              value;
    }
    unsigned int(8) message_data[];
}
 */

import ISOBoxer from 'codem-isoboxer';

export type EMSGBox = {
    id: number;
    flags: number;
    message_data: Uint8Array;
    presentation_time_delta?: number;
    presentation_time?: number;
    event_duration: number;
    scheme_id_uri: string;
    size: number;
    timescale: number;
    type: string;
    value: string;
    version: number;
};

export class EmsgParser {
    static Parse(segment: Uint8Array): Array<EMSGBox> {
        const parsedFile = ISOBoxer.parseBuffer(segment.buffer);
        let emsgBoxes;
        try {
            emsgBoxes = parsedFile.fetchAll('emsg') as Array<EMSGBox>;
        }
        catch (e) {
            throw new Error(`Error class:EmsgParser[Parse] : ISOBoxer error -> fetch('emsg')! \n ${e}`);
        }

        const parsedBoxes: Array<EMSGBox> = [];
        for (let i in emsgBoxes) {
            if (!emsgBoxes.hasOwnProperty(i)) continue;

            const emsg = emsgBoxes[i];
            try {
                parsedBoxes.push({
                    event_duration: emsg.event_duration,
                    flags: emsg.flags,
                    id: emsg.id,
                    message_data: emsg.message_data,
                    presentation_time: emsg.presentation_time,
                    presentation_time_delta: emsg.presentation_time_delta,
                    scheme_id_uri: emsg.scheme_id_uri,
                    size: emsg.size,
                    timescale: emsg.timescale,
                    type: emsg.type,
                    value: emsg.value,
                    version: emsg.version
                });
            }
            catch (e) {
                throw new Error(`Error class:EmsgParser[Parse] : Could not parse emsg box into type 'EMSGBox'! \n ${e}`);
            }
        }

        return parsedBoxes;
    }
};