//
//  ShakaEmsgFilter.js
//
//  Created by Lars Rothaus on 23/06/2021.
//  Copyright © 23/06/2021 Lars Rothaus. All rights reserved.
//
const { EmsgParser } = require('../../utils/EmsgParser');
var RequestType;
(function (RequestType) {
    RequestType[RequestType['APP'] = 3] = 'APP';
    RequestType[RequestType['LICENSE'] = 2] = 'LICENSE';
    RequestType[RequestType['MANIFEST'] = 0] = 'MANIFEST';
    RequestType[RequestType['SEGMENT'] = 1] = 'SEGMENT';
    RequestType[RequestType['SERVER_CERTIFICATE'] = 5] = 'SERVER_CERTIFICATE';
    RequestType[RequestType['TIMING'] = 4] = 'TIMING';
})(RequestType || (RequestType = {}));

export class ShakaEmsgFilter {
    constructor(emsgHandler, mediaElement) {
        this.emsgHandler = emsgHandler;
        this.mediaElement = mediaElement;
    }

    get responseFilter() {
        return (requestType, response) => {
            var _a;
            const resolvedResponse = Promise.resolve(response);
            if (requestType === RequestType.SEGMENT) {
                try {
                    if (response.data instanceof ArrayBuffer) {
                        const segment = new Uint8Array(response.data);
                        const emsgs = EmsgParser.Parse(segment);
                        if (emsgs && emsgs.length) {
                            const firstPresentationTime = emsgs[0].presentation_time || 0;
                            const startTime = this.mediaElement && this.mediaElement.buffered.length
                              ? this.mediaElement.buffered.end(this.mediaElement.buffered.length - 1)
                              : 0;
                            for (let emsg of emsgs) {
                                const segmentRelativePresentationTime = ((_a = emsg.presentation_time) !== null && _a !== void 0 ? _a : 0) - firstPresentationTime;
                                const scaledStartTime = (startTime * emsg.timescale) + segmentRelativePresentationTime;
                                this.emsgHandler({
                                    endTime: scaledStartTime + emsg.event_duration,
                                    eventDuration: emsg.event_duration,
                                    id: emsg.id,
                                    messageData: emsg.message_data,
                                    presentationTimeDelta: emsg.presentation_time ? emsg.presentation_time - scaledStartTime : emsg.presentation_time_delta,
                                    schemeIdUri: emsg.scheme_id_uri,
                                    startTime: scaledStartTime,
                                    timescale: emsg.timescale,
                                    value: emsg.value,
                                    version: emsg.version
                                });
                            }
                        }
                    } else {
                        console.warn('ShakaEmsgFilter::responseFilter: ShakaNetworkFilter response is not ArrayBuffer!');
                    }
                } catch (e) {
                    console.trace('ShakaEmsgFilter::responseFilter: ShakaNetworkFilter response parsing failed!', e);
                }
            }
            return resolvedResponse;
        };
    }
}