const Utils = require('../utils/Utils');
const Advertisement = require('./Advertisement');

/**
 * @typedef {object} TAdvertisementBreakArgs
 * @property {string} id
 * @property {Array<Advertisement>} advertisements
 * @property {number} completion
 * @property {number} duration
 * @property {boolean} embedded
 * @property {string} name
 * @property {number} position
 * @property {boolean} watched
 */
/**
 * @type {TAdvertisementBreakArgs}
 */
class Props {
    constructor() {
        this.id = this.advertisements = this.completion = this.duration = this.embedded = this.name = this.position =
          this.watched = undefined;
    }
}

class AdvertisementBreak extends Props {
    /**
     * @param {TAdvertisementBreakArgs} args
     */
    constructor(args = {}) {
        super();

        Utils.extend(this, {
            active: false,
            completion: 0,
            watched: false
        }, args);
    }
}

module.exports = AdvertisementBreak;