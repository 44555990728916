const EventEmitter = require('eventemitter3');

const {
    EngineEvents,
    TrackManagerEvents
} = require('../../Events');

const AudioTrack = require('./AudioTrack');
const I18n = require('../../i18n/I18n');
const Language = require('../../dtos/Language');
const LanguageMapper = require('../../mappers/LanguageMapper');
const TrackMapper = require('../../mappers/TrackMapper');
const TextTrack = require('./TextTrack');
const Utils = require('../../utils/Utils');

const disabledLanguage = 'off';

module.exports = class TracksManager extends EventEmitter {
    constructor({ engine, addOffTextTrack, accessibilitySupported = false }) {
        super();
        this.addOffTextTrack = addOffTextTrack;
        this.engine = engine;
        this.engineEventListeners = {};
        this.isEnabled = true;
        this.initialized = false;
        this.language = new Language();
        this.playerManager = this.engine.playerManager;
        this.playerManagerEventListeners = {};

        //@TextTrackRecoveryScript
        //remove when image based TTML are duplicated
        this.TTMLReEngagementTimer = null;

        //tracks
        this.textTrack = new TextTrack(this.engine);
        this.textTrack.addOffTextTrack = this.addOffTextTrack;
        this.audioTrack = new AudioTrack(this.engine);
        //set supported
        this.audioTrack.accessibilitySupported = this.textTrack.accessibilitySupported = accessibilitySupported;

        this.registerEventListeners();
        this.setLanguageLabelMapper(this.mapLabel.bind(this));
    }

    // |--------------------------|
    // | Public
    // |--------------------------|

    getTracks() {
        return [...this.textTrack.getTracks(), ...this.audioTrack.getTracks()];
    }

    getTracksDto() {
        const activeTracks = this.activeTracks();
        const audioTracks = this.audioTrack.getTracks() || [];
        const textTracks = this.textTrack.getTracks() || [];

        return {
            active: {
                audio: TrackMapper.map(activeTracks.audio),
                text: TrackMapper.map(activeTracks.text)
            },
            audio: audioTracks.map(track => TrackMapper.map(track)),
            text: textTracks.map(track => TrackMapper.map(track))
        };
    }

    activeTracks() {
        return {
            audio: this.audioTrack.getActiveTrack(),
            text: this.textTrack.getActiveTrack()
        };
    }

    activeTrackLanguages() {
        return {
            audio: this.audioTrack.getActiveTrackLanguage(),
            text: this.textTrack.getActiveTrackLanguage()
        };
    }

    activeTrackNames() {
        return {
            audio: this.audioTrack.getActiveTrackName(),
            text: this.textTrack.getActiveTrackName()
        };
    }

    audioTracks() {
        return this.audioTrack.getTracks();
    }

    audioTrackLanguages() {
        return this.audioTrack.getTrackLanguages();
    }

    audioTrackNames() {
        return this.audioTrack.getTrackNames();
    }

    textTracks() {
        return this.textTrack.getTracks();
    }

    textTrackLanguages() {
        return this.textTrack.getTrackLanguages();
    }

    textTrackNames() {
        return this.textTrack.getTrackNames();
    }

    /**
     * @param {Language} language
     */
    setLanguage(language) {

        if (language.preferred && typeof language.preferred.preferAccessibility === 'boolean') {
            this.audioTrack.preferAccessibility = this.textTrack.preferAccessibility = this.language.preferred.preferAccessibility = language.preferred.preferAccessibility;
        }

        if (this.language.preferred.audio.length === 0 && Array.isArray(language.preferred.audio)) {
            this.language.preferred.audio = language.preferred.audio;
        }

        if (this.language.preferred.text.length === 0 && Array.isArray(language.preferred.text)) {
            this.language.preferred.text = language.preferred.text;
        }

        if (language.selected) {
            if (language.selected.audio) {
                this.language.selected.audio = language.selected.audio;
            }

            if (language.selected.text) {
                this.language.selected.text = language.selected.text;
            }
        }

        if (this.language.selected.audio === null && typeof (this.language.preferred.audio[0]) === 'string') {
            this.language.selected.audio = this.language.preferred.audio[0];
        }

        if (this.language.selected.text === null && typeof (this.language.preferred.text[0]) === 'string') {
            this.language.selected.text = this.language.preferred.text[0];
        }

        this.updateEnginePreferred();
    }


    setLanguageLabelMapper(mapLanguageLabel) {
        if (mapLanguageLabel) {
            this.mapLanguageLabel = mapLanguageLabel;
            this.textTrack.setLanguageLabelMapper(this.mapLanguageLabel);
            this.audioTrack.setLanguageLabelMapper(this.mapLanguageLabel)
        }
    }

    // |--------------------------|
    // | Getter - Setter
    // |--------------------------|

    set audioTrackLanguage(language) {
        if (this.audioTrack.initialized) {
            if (/*in-line*/ this.audioTrack.setActiveTrack(language) === false) {
                console.warn(`TracksManager::set audioTrackLanguage: was not able to set track with language '${language}' !`);
            }
        } else {
            this.language.selected.text = language;
            this.updateEnginePreferred();
        }
    }

    get enabled() {
        return this.isEnabled;
    }

    set enabled(value) {
        if (!Utils.isBoolean(value) || this.isEnabled === value) {
            return;
        }

        this.textTrack.enabled = this.isEnabled = value;
    }

    set textTrackLanguage(language) {
        if (this.textTrack.initialized) {
            if (/*in-line*/ this.textTrack.setActiveTrack(language) === false) {
                console.warn(`TracksManager::set textTrackLanguage: was not able to set track with language '${language}' !`);
            }
        } else {
            this.language.selected.text = language;
            this.updateEnginePreferred();
        }
    }

    // |--------------------------|
    // | Internal
    // |--------------------------|

    /**
     * @internal
     */
    handleSetActiveTracksRequest(request) {
        let activeTracks = [...this.audioTrack.getActiveIds(), ...this.textTrack.getActiveIds()];
        let audioTracks = this.audioTrack.getTracks();
        let textTracks = this.textTrack.getTracks();
        let tracks = [...textTracks, ...audioTracks];

        /** HACK ->
         *  this is a hack to fix the need for a mock subtitle track with off
         *  This is a really bad solution, and must be fix by the senders as soon as possible
         */

        for (let it in textTracks) {
            if (!textTracks.hasOwnProperty(it)) continue;
            if (request.activeTrackIds.includes(textTracks[it].trackId) === true && textTracks[it].language === disabledLanguage) {
                request.activeTrackIds = request.activeTrackIds.filter((item) => item !== textTracks[it].trackId);
            }
        }

        let activeObj = {};
        let nextObj = {};
        for (let i in tracks) {
            if (activeTracks.includes(tracks[i].trackId) === true) {
                activeObj[tracks[i].type] = tracks[i];
            }
            if (request.activeTrackIds.includes(tracks[i].trackId) === true) {
                nextObj[tracks[i].type] = tracks[i];
            }
        }

        const TrackType = cast.framework.messages.TrackType;
        if (activeObj[TrackType.TEXT] && !nextObj[TrackType.TEXT]) {
            this.textTrack.setActiveTrack(disabledLanguage);
        } else if (
          (!activeObj[TrackType.TEXT] && nextObj[TrackType.TEXT]) ||
          (activeObj[TrackType.TEXT] && nextObj[TrackType.TEXT] && activeObj[TrackType.TEXT].trackId !== nextObj[TrackType.TEXT].trackId)
        ) {
            this.textTrack.setActiveTrack(nextObj[TrackType.TEXT].language);
        }

        if (activeObj[TrackType.AUDIO] && !nextObj[TrackType.AUDIO]) { // Not doing anything in this case, as audio track cannot be switched off in the same way as subtitle tracks
            // no action can't turn off audio.....
        } else if (
          (!activeObj[TrackType.AUDIO] && nextObj[TrackType.AUDIO]) ||
          (activeObj[TrackType.AUDIO] && nextObj[TrackType.AUDIO] && activeObj[TrackType.AUDIO].trackId !== nextObj[TrackType.AUDIO].trackId)
        ) {
            this.audioTrack.setActiveTrack(nextObj[TrackType.AUDIO].language);
        }
        return request;
    }

    /**
     * backwards compatibility
     * @internal
     */
    setPreferredTracks(tracks) {
        if (typeof (tracks) === 'object') {
            if (Array.isArray(tracks.audio)) {
                this.language.preferred.audio = tracks.audio;
            }
            if (Array.isArray(tracks.text)) {
                this.language.preferred.audio = tracks.text;
            }
        }
        this.setLanguage({});
    }

    // |--------------------------|
    // | Private
    // |--------------------------|

    /**
     * @private
     */
    updateEnginePreferred() {
        if (!this.engine.preferredTracks) {
            this.engine.preferredTracks = {};
        }
        Utils.extend(this.engine.preferredTracks, {
            audioTrackLanguage: this.language.selected.audio || 'off',
            textTrackLanguage: this.language.selected.text || 'off'
        });
    }

    /**
     * @private
     */
    registerEventListeners() {
        this.engine.on(
          EngineEvents.LoadedData,
          this.engineEventListeners[EngineEvents.LoadedData] = this.onStreamLoadedData.bind(this)
        );

        //@TextTrackRecoveryScript
        this.engine.on(
          EngineEvents.Seeked,
          this.engineEventListeners[EngineEvents.Seeked] = this.onStreamSeeked.bind(this)
        );
        //@TextTrackRecoveryScript
        this.engine.on(
          EngineEvents.TTMLImageSubtitleUpdated,
          this.engineEventListeners[EngineEvents.TTMLImageSubtitleUpdated] = this.onTTMLImageSubtitleUpdated.bind(this)
        );


    }

    //@TextTrackRecoveryScript
    onStreamSeeked() {
        /**
         * If we are using image based TTML and a textTrack is active we pre load a recovery script to
         * handle a case where the textEngine stop working after seeking
         */

        if (this.engine.useTtmlHandler && this.language.selected.text && this.language.selected.text !== 'off') {
            console.log(`class:TracksManager[onStreamSeeked] : Engaging textTrack recovery script!`);
            const activeLanguage = this.language.selected.text;
            this.TTMLReEngagementTimer = setTimeout(() => {
                console.log(`class:TracksManager[onStreamSeeked] : Running textTrack recovery script!`);
                this.textTrack.setActiveTrack('off');
                setTimeout(() => {
                    this.textTrack.setActiveTrack(activeLanguage);
                }, 500)
            }, 5000)
        }
    }

    //@TextTrackRecoveryScript
    onTTMLImageSubtitleUpdated() {
        if (this.TTMLReEngagementTimer) {
            clearTimeout(this.TTMLReEngagementTimer)
            this.TTMLReEngagementTimer = null;
            console.log(`class:TracksManager[onStreamSeeked] : Disengaging textTrack recovery script!`);
        }
    }

    /**
     * @private
     */
    onTrackChanged(data) {
        if (data.audio) {
            this.language.selected.audio = data.audio;
            this.emit(TrackManagerEvents.AudioTrackChanged, {
                language: data.audio
            });
        } else if (data.text) {
            this.language.selected.text = data.text;
            this.emit(TrackManagerEvents.TextTrackChanged, {
                language: data.text
            });
        }
    }

    /** @private */
    unregisterEventListeners() {
        Object.keys(this.engineEventListeners).forEach((e) => {
            this.engine.off(e, this.engineEventListeners[e]);
        });
        this.engineEventListeners = {};
    }

    /** @private */
    onTrackReady(track) {
        const activeTrack = track.getActiveTrack() || {};
        if (this.language.selected[track.type] !== activeTrack.language) {
            if (/*in-line*/ track.setActiveTrack(this.language.selected[track.type]) === false) { // Will result in handleTracksChange if successful, so no else statement needed
                if (/*in-line*/ track.applyPreferredTracks(this.language.preferred[track.type]) === false) {
                    console.log(`TracksManager::onTrackReady: Could not satisfy preferred tracks!`);

                    if (activeTrack && activeTrack.language) {
                        track.handleTracksChange(activeTrack.language);
                    } else {
                        // track.getActiveTrackLanguage() will in this case give disabledLanguage for text track and defaultLanguage ('en') for audio track
                        track.handleTracksChange(track.getActiveTrackLanguage());
                    }
                }
            }
        } else {
            track.handleTracksChange(this.language.selected[track.type]);
        }
    }

    /**
     * @private
     */
    mapLabel(language) {
        language = LanguageMapper.mapISOCodeFromAlpha2or3to2(language);

        let label = null;
        [
            language,
            I18n.language,
            LanguageMapper.mapISOCodeFromAlpha2or3to2(navigator.language.slice(0, 2)),
            'en'
        ].some(l => {
            if (LanguageMapper.hasLanguageGroup(l)) {
                label = LanguageMapper.mapLabel(l, language);
            }

            return !!label; // Will bail out once label is set
        });

        return language === 'off' ? 'Off' : (label || language);
    }

    /**
     * @private
     */
    onStreamLoadedData() {
        this.textTrack.trackReady = (track) => this.onTrackReady(track);
        this.textTrack.activeTracksChanged = (language) => this.onTrackChanged(language);
        this.textTrack.initialize();

        this.audioTrack.trackReady = (track) => this.onTrackReady(track);
        this.audioTrack.activeTracksChanged = (language) => this.onTrackChanged(language);
        this.audioTrack.initialize();
    }

    // |--------------------------|
    // | Life-cycle
    // |--------------------------|

    reset(hard) {
        this.audioTrack.reset();
        this.textTrack.reset();

        if (hard) {
            this.language = new Language();
        }
    }

    destroy() {
        this.reset(true);
        this.unregisterEventListeners();
        this.removeAllListeners();
    }
};