import { FetchRequestFactory, FetchRequestResponse, ServiceError, ServiceErrorCodes } from '@tv4/one-playback-sdk';
import { ErrorCategories, NetworkError } from '@tv4/one-playback-sdk-shared';
import { TDevice } from '@tv4/unified-receiver/types/dtos/ReceiverInformation';
import { PlaybackApiServiceConfiguration, PlaybackAsset, PlaybackMedia, TAssetResponse, TMediaResponse } from './Types';

export class PlaybackApiService {
    private configuration?: PlaybackApiServiceConfiguration;
    private jwtToken?: string;
    private name:string = 'PlaybackApiService';
    private requestFactory: FetchRequestFactory = new FetchRequestFactory();


    constructor(requestFactory:FetchRequestFactory) {
        this.requestFactory = requestFactory;

    }

    public initialize(configuration: PlaybackApiServiceConfiguration) {
        this.configuration = configuration;
    }

    public updateJwtToken(token: string) {
        this.jwtToken = token;
    }

    private async getAsset(metadataUrl: URL, headers: Record<string, string>) {
        const response = await this.requestFactory.fetch(metadataUrl.toString(), {
            method: 'GET',
            headers: headers,
            ignoreGlobalHeaders: true
        }).then((response) => {
            if (response instanceof NetworkError) {
                //throw ServiceLayerErrorFactory.createFromStandardError(response)
                throw new ServiceError({
                    code: response.responseBody?.errorCode || ServiceErrorCodes.BadResponseError,
                    category: ErrorCategories.API,
                    fatal: true,
                    details: {
                        response,
                        origin: this.name,
                        domain: 'getAssetAndMedia',
                        configuration: this.configuration,
                    }
                })
            } else if (response instanceof FetchRequestResponse && response.ok && response.responseBody) {
                return response.responseBody as TAssetResponse;
            }
            throw new ServiceError({
                code: ServiceErrorCodes.BadResponseError,
                category: ErrorCategories.API,
                fatal: true,
                details: {
                    response,
                    origin: this.name,
                    domain: 'getAssetAndMedia',
                    configuration: this.configuration,
                }
            })
        });

        return response;
    }

    private async getMedia(mediaUrl: URL, requestParams: any) {
        const response = await this.requestFactory.fetch(mediaUrl.toString(), requestParams).then((response) => {
            if (response instanceof NetworkError) {
                // TODO: add PlaybackApiError, an error response contains
                // errorCode, message & optionally details
                throw new ServiceError({
                    code: response.responseBody?.errorCode || ServiceErrorCodes.BadResponseError,
                    category: ErrorCategories.API,
                    fatal: true,
                    details: {
                        response,
                        origin: this.name,
                        domain: 'getAssetAndMedia',
                        configuration: this.configuration,
                    }
                })
            } else if (response instanceof FetchRequestResponse && response.ok && response.responseBody) {
                return response.responseBody as TMediaResponse;
            }
            throw new ServiceError({
                code: ServiceErrorCodes.BadResponseError,
                category: ErrorCategories.API,
                fatal: true,
                details: {
                    response,
                    origin: this.name,
                    domain: 'getAssetAndMedia',
                    configuration: this.configuration,
                }
            })
        });

        return response;
    }

    public async getMetadata(
        id: string,
        service: string,
        headers: Record<string, string>
      ) {
        const url = new URL(`/metadata/${id}`, this.configuration?.playbackApiUrl);
        url.searchParams.append("service", service);
        url.searchParams.append("device", "chromecast");

        const metadata = await this.requestFactory.fetch(url.toString(), {
          method: "GET",
          headers,
          ignoreGlobalHeaders: true
        }).then((response) => {
            if(response instanceof FetchRequestResponse && response.ok && response.responseBody) {
                return response.responseBody as TAssetResponse;
            } 
            throw new ServiceError({
                code: response.responseBody?.errorCode || ServiceErrorCodes.BadResponseError,
                    category: ErrorCategories.API,
                    fatal: true,
                    details: {
                        response,
                        origin: this.name,
                        domain: 'getMetadata',
                        configuration: this.configuration,
                    }
            });
        });

        return metadata;
      }
    

    public async getAssetAndMedia(id: string, capabilities?:string[], receiverInfo?: TDevice): Promise<{ asset: PlaybackAsset, media: PlaybackMedia }> {

        if (!this.configuration) {
            throw new ServiceError({
                code: ServiceErrorCodes.MissingConfiguration,
                category: ErrorCategories.DEFAULT,
                fatal: true,
                details: {
                    origin: this.name,
                    domain: 'getAssetAndMedia',
                    configuration: this.configuration,
                }
            });
        }

        const service = this.getPlaybackAPIServiceId(this.configuration.service);
        const headers:Record<string, string> = this.jwtToken ? {
            "x-jwt": `Bearer ${this.jwtToken}`,
        } : {};

        const metadataResponse = await this.getMetadata(id, service, headers);

        const playUrl = new URL(`/play/${id}`, this.configuration.playbackApiUrl);
        playUrl.searchParams.append('service', service);
        playUrl.searchParams.append('device', 'chromecast');
        playUrl.searchParams.append('device_model', receiverInfo?.deviceModel.toLowerCase() || 'unknown');
        playUrl.searchParams.append('browser', 'GoogleChrome');
        playUrl.searchParams.append('protocol', metadataResponse?.metadata.isLive && metadataResponse?.metadata.type !== 'sportvideo' ? this.configuration.protocol.join(',') : 'dash');
        if (this.configuration.drm) {
            playUrl.searchParams.append('drm', this.configuration.drm);
        }
        if (capabilities && capabilities.length) {
            playUrl.searchParams.append('capabilities', capabilities.join(','));
        }

        const assetResponse = await this.getAsset(playUrl, headers);
        
        const mediaUrl = new URL(assetResponse.mediaUri, this.configuration.playbackApiUrl);

        let requestParams = {
            method: 'GET',
            headers: undefined,
            ignoreGlobalHeaders: true
        }

        if(this.jwtToken && this.jwtToken.length > 0){
            //@ts-ignore
            requestParams.headers = {
                ['x-jwt']: this.jwtToken
            }
        }
        let mediaResponse = await this.getMedia(mediaUrl, requestParams);
        
        return {
            asset: this.getPlaybackAsset(assetResponse),
            media: this.getPlaybackMedia(mediaResponse)
        }
    }

    private getPlaybackAPIServiceId(service: string): string {
        if (service === "mtv" || service === "tv4play") {
            return service;
        } else if (service === "koket" || service === "fotbollskanalen" || service === "tv4") {
            return "tv4";
        }

        return "tv4play";
    }

    private getPlaybackAsset(asset: TAssetResponse): PlaybackAsset {
        const { mediaUri, ...playbackAsset } = asset;
        return playbackAsset;
    }

    private getPlaybackMedia(media: TMediaResponse): PlaybackMedia {
        return media.playbackItem;
    }
}
