import { StandardError, StandardErrorArgs } from './StandardError'
import { ErrorCategories } from '../Constants';
import { NetworkError } from './NetworkError';
import { getCodeFromNetworkError } from './utils';
import { StreamingGatewayErrorMap } from './constants';

type TStreamingGatewayErrorArgs = Omit<StandardErrorArgs, 'category' | 'code'> & {
    originalError: NetworkError;
};

export class StreamingGatewayError extends StandardError {
    constructor(args: TStreamingGatewayErrorArgs) {
        super({
            ...args,
            category: ErrorCategories.STREAMING_GATEWAY,
            code: getCodeFromNetworkError(
              {
                  error: args.originalError,
                  errorMap: StreamingGatewayErrorMap
              }),
        });
    }
};