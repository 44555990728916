import {
    PlaybackErrorEvent, StartingEvent,
    StoppedEvent,
    StoppedReasons, TClientName,
    TimeUpdateEvent, TrackingInfoEvent, TVideoTracking,
} from '@tv4/one-playback-sdk-shared';

import { guid } from '../../../utils/Guid';
import { getOSName } from '../../../utils/Device';
import { resolveIpAddress } from '../../../utils/IpAddress';
import { secondsToMs } from '../../../utils/Time';
import { getTopLocationHost } from '../../../utils/Url';
import { BaseTracker } from '../BaseTracker';
import { TTrackerConfiguration } from '../Types';

type TWireTrackerBaseData = {
    host: string;
    ip: string;
    os: string;
    platform: string;
    player: string;
    videoId: string;
};

export type TWireTrackerConfiguration = TTrackerConfiguration & {
    contentId?: string;
    endpoint: string;
};

type TPlatform = "bigscreen" | "computer" | "mobile";

const mapClientNameToPlatform = (clientName: TClientName): TPlatform => {
    switch (clientName) {
        case "ccr":
        case "sstv":
        case "lgtv":
            return "bigscreen";
        case "androidmob":
        case "ios":
            return "mobile";
        case "web":
        default:
            return "computer";
    }
}

export class WireTracker extends BaseTracker {
    readonly endpoint: string;
    private active: boolean;
    private baseData: TWireTrackerBaseData | null;
    private contentId?: string;
    private duration: number;
    private heartbeat: any;
    private live: boolean;
    private playbackSessionId?: string;
    private position: number;
    private sequenceNumber: number;
    private sessionEndFired: boolean;
    private sessionStartFired: boolean;
    private startPosition: number;
    private playerId: string;
    private trackingData?: TVideoTracking;
    private client: string;
    private clientName: TClientName;

    constructor({
        endpoint,
        player,
        contentId,
        service,
        device: {
            clientName
        }
    }: TWireTrackerConfiguration) {
        super('WireTracker');
        this.endpoint = endpoint;

        this.contentId = contentId;
        this.playerId = `${player.playerName}-${player.playerVersion}`;

        this.active = false;
        this.baseData = null;
        this.duration = 0;
        this.heartbeat = undefined;
        this.live = false;
        this.position = 0;
        this.sequenceNumber = 0;
        this.sessionEndFired = false;
        this.sessionStartFired = false;
        this.startPosition = 0;
        this.client = `${service.serviceId}-${player.playerName}`;
        this.clientName = clientName;
    }

    private getDuration(): number {
        if (this.live) {
            return 0;
        }

        return secondsToMs(this.duration);
    }

    private getPosition(): number {
        if (this.live) {
            return Date.now() - this.startPosition;
        }

        return secondsToMs(this.position);
    }

    private async setBaseData(): Promise<void> {
        if (!this.active) return;

        let ip = '';

        try {
            ip = await resolveIpAddress();
        }
        catch (error) {
            // couldn't fetch IP
        }

        if (!this.playbackSessionId) {
            this.playbackSessionId = guid();
        }

        this.baseData = {
            host: getTopLocationHost(), // Not Needed ?
            ip,
            os: getOSName(),
            platform: mapClientNameToPlatform(this.clientName),
            player: this.playerId,
            videoId: this.contentId || 'unknown',
        };
    }

    private async sessionStart(): Promise<void> {
        this.sequenceNumber = 0;
        this.startPosition = Date.now();

        await this.setBaseData();

        this.postData('sessionStart');

        this.heartbeat = setInterval(() => this.progress(), 15000);
    }

    private sessionEnd(): void {
        this.postData('sessionEnd');

        clearInterval(this.heartbeat);

        this.sessionEndFired = true;
    }

    private progress(): void {
        this.postData('progress');
    }

    private progressEnd(): void {
        this.postData('progress');
    }

    private postData(eventType: string): void {
        if (
            !this.active ||
            !this.endpoint ||
            !this.trackingData ||
            !this.baseData ||
            !this.baseData.videoId
        ) {
            return;
        }

        const data = {
            eventType,
            sequenceNumber: this.sequenceNumber++,
            position: this.getPosition(),
            duration: this.getDuration(),
            ...this.baseData,
            // spread the sgw provided data
            ...this.trackingData,
        };

        // console.debug(`Wire [${eventType}]`, data);

        void this.requestFactory?.fetch(this.endpoint, {
            method: 'POST',
            headers: {
                'client': this.client
            },
            body: JSON.stringify(data),
            useAuthentication: true
        }).catch(() => {

        });
    }

    // This tracker needs the SGW-provided tracking object to initialize.
    // The properties can be dynamically changed by SGW at any time.
    public initialize(initData: TVideoTracking): void {
        if (this.active) return;

        this.active = true;

        this.live = initData.live;
        this.trackingData = initData;
    };

    public starting({ payload: { playbackSessionId }}: StartingEvent): void {
        this.playbackSessionId = playbackSessionId;
    }

    public buffering(): void {
        this.progress();
    }

    public error({ payload }: PlaybackErrorEvent): void {
        //fixes error recursion on cc
        if(payload.error.fatal){
            this.progress();
        }
    }

    public paused(): void {
        this.progress();
    }

    public playing(): void {
        if (!this.sessionStartFired) {
            void this.sessionStart();
            this.sessionStartFired = true;
        }
    }

    public stopped({ payload }: StoppedEvent): void {
        if (payload.reason === StoppedReasons.END_OF_STREAM) {
            this.position = this.duration;
        }

        this.progressEnd();
    }

    public timeUpdate({ payload }: TimeUpdateEvent): void {
        this.duration = payload.duration;
        this.position = payload.currentTime;
    }

    public trackingInfo({ payload: { tracking: { VIDEOTRACKING } = {} }}: TrackingInfoEvent): void {
        if (
          !this.active
          && VIDEOTRACKING
        ) {
            this.initialize(VIDEOTRACKING)
        }
    }

    async reset(): Promise<void> {
        if (this.sessionStartFired && !this.sessionEndFired) {
            this.sessionEnd();
        }

        this.active = false;
        this.baseData = null;
        this.duration = 0;
        this.heartbeat = undefined;
        this.live = false;
        this.playbackSessionId = undefined;
        this.position = 0;
        this.sequenceNumber = 0;
        this.sessionEndFired = false;
        this.sessionStartFired = false;
        this.startPosition = 0;

        return Promise.resolve();
    }

    async destroy(): Promise<void> {
        if (this.active) {
            this.reset();
        }
    }
}
