const {
    EngineEvents,
    TrackManagerEvents
} = require('../../Events');

const Listener = require('../../event/Listener');
const Utils = require('../../utils/Utils');

module.exports = class TrackingManager extends Listener {
    constructor({ config, engine, tracksManager }) {
        super();

        this.destroyed = false;

        this.config = config;
        this.engine = engine;
        this.tracksManager = tracksManager;

        this.engineEventListeners = {};
        this.tracksManagerEventListeners = {};

        this.trackers = {};

        this.registerEventListeners();
    }

    /**
     * @private
     * @param {string} func
     * @param {object} data
     */
    callOnAllTrackers(func, data = {}) {
        for (let t in this.trackers) {
            if (!this.trackers.hasOwnProperty(t)) continue;

            try {
                this.trackers[t][func](data);
            } catch (e) {
                console.error(`TrackingManager: Error calling: '${func}' on tracker with name: '${t}'`, e);
            }
        }
    }

    /** @private */
    registerEventListeners() {
        this.listen(this.engine, this.engineEventListeners, EngineEvents.AdvertisementEnded,
          e => this.callOnAllTrackers('advertisementEnded', e));
        this.listen(this.engine, this.engineEventListeners, EngineEvents.AdvertisementStarted,
          e => this.callOnAllTrackers('advertisementStarted', e));
        this.listen(this.engine, this.engineEventListeners, EngineEvents.AdvertisementBreakEnded,
          e => this.callOnAllTrackers('advertisementBreakEnded', e));
        this.listen(this.engine, this.engineEventListeners, EngineEvents.AdvertisementBreakStarted,
          e => this.callOnAllTrackers('advertisementBreakStarted', e));
        this.listen(this.engine, this.engineEventListeners, EngineEvents.AdvertisementTimeUpdate,
          e => this.callOnAllTrackers('advertisementTimeUpdate', e));
        this.listen(this.engine, this.engineEventListeners, EngineEvents.BitrateChanged,
          e => this.callOnAllTrackers('bitrateChanged', e));
        this.listen(this.engine, this.engineEventListeners, EngineEvents.Buffered,
          e => this.callOnAllTrackers('buffered', e));
        this.listen(this.engine, this.engineEventListeners, EngineEvents.Buffering,
          e => this.callOnAllTrackers('buffering', e));
        this.listen(this.engine, this.engineEventListeners, EngineEvents.CdnChanged,
          e => this.callOnAllTrackers('cdnChanged', e));
        this.listen(this.engine, this.engineEventListeners, EngineEvents.DrmChanged,
          e => this.callOnAllTrackers('drmChanged', e));
        this.listen(this.engine, this.engineEventListeners, EngineEvents.DroppedFrames,
          e => this.callOnAllTrackers('droppedFrames', e));
        this.listen(this.engine, this.engineEventListeners, EngineEvents.LoadStart,
          e => this.callOnAllTrackers('loadStart', e));
        this.listen(this.engine, this.engineEventListeners, EngineEvents.LoadedData,
          e => this.callOnAllTrackers('loadedData', e));
        this.listen(this.engine, this.engineEventListeners, EngineEvents.LoadedMetadata,
          e => this.callOnAllTrackers('loadedMetadata', e));
        this.listen(this.engine, this.engineEventListeners, EngineEvents.Pause,
          e => this.callOnAllTrackers('pause', e));
        this.listen(this.engine, this.engineEventListeners, EngineEvents.Play,
          e => this.callOnAllTrackers('play', e));
        this.listen(this.engine, this.engineEventListeners, EngineEvents.SeekableRangeUpdated,
          e => this.callOnAllTrackers('seekableRangeUpdated', e));
        this.listen(this.engine, this.engineEventListeners, EngineEvents.Seeked,
          e => this.callOnAllTrackers('seeked', e));
        this.listen(this.engine, this.engineEventListeners, EngineEvents.Seeking,
          e => this.callOnAllTrackers('seeking', e));
        this.listen(this.engine, this.engineEventListeners, EngineEvents.StreamChanged,
          e => this.callOnAllTrackers('streamChanged', e));
        this.listen(this.engine, this.engineEventListeners, EngineEvents.StreamCue,
          e => this.callOnAllTrackers('streamCue', e));
        this.listen(this.engine, this.engineEventListeners, EngineEvents.StreamFinished,
          e => this.callOnAllTrackers('streamFinished', e));
        this.listen(this.engine, this.engineEventListeners, EngineEvents.SystemVolumeChanged,
          e => this.callOnAllTrackers('volumeChanged', e));
        this.listen(this.engine, this.engineEventListeners, EngineEvents.TimeUpdate,
          e => this.callOnAllTrackers('timeUpdate', e));

        this.listen(this.tracksManager, this.tracksManagerEventListeners, TrackManagerEvents.AudioTrackChanged,
          e => this.callOnAllTrackers('audioTrackChanged', e));
        this.listen(this.tracksManager, this.tracksManagerEventListeners, TrackManagerEvents.TextTrackChanged,
          e => this.callOnAllTrackers('textTrackChanged', e));
    }

    /** @private */
    unregisterEventListeners() {
        Object.keys(this.engineEventListeners)
          .forEach(e => this.unlisten(this.engine, this.engineEventListeners, e));
        this.engineEventListeners = {};

        Object.keys(this.tracksManagerEventListeners)
          .forEach(e => this.unlisten(this.tracksManager, this.tracksManagerEventListeners, e));
        this.tracksManagerEventListeners = {};
    }

    /** @private */
    error(error) {
        this.callOnAllTrackers('error', { error });
    }

    /** @private */
    playbackSessionStarted(receiverInformation) {
        this.callOnAllTrackers('playbackSessionStarted', {
            playbackSession: receiverInformation.playbackSession,
            session: receiverInformation.session
        });
    }

    /** @private */
    playbackSessionInitialized() {
        this.callOnAllTrackers('playbackSessionInitialized');
    }

    registerTracker(name, tracker) {
        if (this.trackers[name]) {
            return console.error(`TrackingManager::registerTracker: Tracker with name: ${name} is already registered!`);
        }

        return this.trackers[name] = tracker;
    }

    unregisterTracker(name) {
        delete this.trackers[name];
    }

    /** @private */
    reset(callTrackers = true) {
        callTrackers && this.callOnAllTrackers('reset');
    }

    /** @private */
    destroy() {
        this.destroyed = true;

        this.callOnAllTrackers('destroy');

        this.reset(false);

        this.unregisterEventListeners();

        this.trackers = {};
    }
};