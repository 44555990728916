import { TimeUpdateEvent, TrackingInfoEvent, WatchMode, VideoIdType } from '@tv4/one-playback-sdk-shared';
import { secondsToMs } from '../../../utils/Time';
import { BaseTracker } from '../BaseTracker';
import { TTrackerConfiguration } from '../Types';

export type TContinueWatchingGatewayTrackerConfiguration = TTrackerConfiguration & {
    endpoint: string;
};

export type TContinueWatchingGatewayTrackerData = {
    assetId: string,
    videoIdType: VideoIdType,
    watchMode: WatchMode,
};

export class ContinueWatchingGatewayTracker extends BaseTracker {
    readonly endpoint: string;
    readonly applicationSessionId: string;

    private active: boolean;
    private assetId?: string;
    private duration: number;
    private position: number;
    private stoppedFired: boolean;

    constructor({
        endpoint,
        session: { applicationSessionId }
    }: TContinueWatchingGatewayTrackerConfiguration) {
        super('ContinueWatchingGatewayTracker');
        this.endpoint = endpoint;
        this.applicationSessionId = applicationSessionId;
        this.active = false;
        this.duration = 0;
        this.position = 0;
        this.stoppedFired = false;
    }

    private getDuration(): number {
        return secondsToMs(this.duration);
    }

    private getPosition(): number {
        return secondsToMs(this.position);
    }

    private postData(): void {
        if (
            !this.active ||
            !this.endpoint ||
            !this.assetId
        ) {
            return;
        }

        const data = {
            assetId: this.assetId,
            position: this.getPosition(),
            duration: this.getDuration()
        };

        void this.requestFactory?.fetch(this.endpoint, {
            method: 'POST',
            headers: {
                'tv-client-boot-id': this.applicationSessionId
            },
            body: JSON.stringify(data),
            useAuthentication: true
        }).catch(() => {});
    }

    // ITracker
    public initialize({ assetId, watchMode, videoIdType }: TContinueWatchingGatewayTrackerData) {
        if (this.active) return;

        if (
          videoIdType === VideoIdType.MEDIA
          && ![WatchMode.LIVE, WatchMode.TRAILER].includes(watchMode)
        ) {
            this.active = true;
            this.assetId = assetId;
        }
    }

    public paused(): void {
        this.postData();
    }

    public stopped(): void {
        this.stoppedFired = true;
        this.postData();
    }

    public timeUpdate({ payload }: TimeUpdateEvent): void {
        this.duration = payload.duration;
        this.position = payload.currentTime;
    }

    public trackingInfo({ payload: { tracking: { YOUBORA } = {}, content }}: TrackingInfoEvent): void {
        // This tracker is temporary and the WIRE/VIDEO tracker will handle this use
        // case in the future. This is why it is okay for it to look at YOUBORA data.
        if (
            !this.active
            && YOUBORA
            && typeof YOUBORA.assetId === "string"
            && content?.playbackSpec
        ) {
            this.initialize({
                assetId: YOUBORA.assetId,
                videoIdType: content.playbackSpec.videoIdType,
                watchMode: content.playbackSpec.watchMode,
            });
        }
    }

    async reset(): Promise<void> {
        if (this.active && !this.stoppedFired) {
            this.postData();
        }

        this.active = false;
        this.duration = 0;
        this.position = 0;
        this.stoppedFired = false;

        return Promise.resolve();
    }

    async destroy(): Promise<void> {
        if (this.active) {
            this.reset();
        }
    }
};
