import { PlaybackEventBase } from './PlaybackEventBaseClass';
import { PlaybackEventTypes } from "../Constants";

type TCdnChangeEventPayload = {
    cdn: string;
}

export class CdnChangedEvent extends PlaybackEventBase<PlaybackEventTypes.CDN_CHANGED, TCdnChangeEventPayload> {
    constructor(payload: TCdnChangeEventPayload) {
        super(PlaybackEventTypes.CDN_CHANGED, payload);
    }
}
