module.exports = class ITracker {
    constructor() {
    }

    static get Name() {
        return 'ITracker';
    }

    initialize() {
    }

    advertisementEnded() {
    }

    advertisementStarted(data) {
    }

    advertisementBreakEnded(data) {
    }

    advertisementBreakStarted(data) {
    }

    advertisementTimeUpdate(data) {
    }

    audioTrackChanged(data) {
    }

    bitrateChanged(data) {
    }

    buffered() {
    }

    buffering() {
    }

    cdnChanged(data) {
    }

    drmChanged(data) {
    }

    droppedFrames(data) {
    }

    error(data) {
    }

    pause() {
    }

    play() {
    }

    playbackSessionStarted(data) {
    } // Start loading of content/new playback session starting
    playbackSessionInitialized() {
    }

    seekableRangeUpdated(data) {
    }

    seeked() {
    }

    seeking() {
    }

    streamChanged() {
    }

    streamCue(data) {
    }

    streamFinished(data) {
    }

    streamLoadStart() {
    } // Finished initial loading of content (e.g. calling backend apis), start loading of stream
    streamLoadedData() {
    }

    streamLoadedMetadata() {
    }

    timeUpdate(data) {
    }

    volumeChanged(data) {
    }

    textTrackChanged(data) {
    }

    reset() {
    }

    destroy() {
    }
};