const { Content } = require('../dtos/');
const { StreamTypes } = require('../Constants');
const AdvertisementBreakMapper = require('./AdvertisementBreakMapper');
const MetadataMapper = require('./MetadataMapper');
const Utils = require('../utils/Utils');

module.exports = class ContentMapper {
    constructor() {
    }

    static mapToLoadRequest(request, content, stream, logoUrl) {
        request.media.contentId = content.contentId;

        if (content.metadata) {
            request.media.metadata = MetadataMapper.mapToGoogleMetadata(content.metadata, logoUrl);
        }

        if (stream) {
            request.media.contentType = stream.contentType;
            request.media.contentUrl = stream.playbackSessionUrl || stream.url;
            request.media.streamType = [StreamTypes.Dvr, StreamTypes.Live, StreamTypes.StartOver].includes(stream.streamType) ? 'LIVE' : 'BUFFERED';

            if (stream.advertisementBreaks && stream.advertisementBreaks.length) {
                request = AdvertisementBreakMapper.mapToGoogleDtos(request, stream.advertisementBreaks);
            }
        }

        request.customData = {
            content
        };

        return request;
    }

    static mapFromLoadRequest(customData, request) {
        const filterReservedCustomDataProperties = c => {
            [
                'advertisement',
                'credentials',
                'language',
                'tracking'
            ].forEach(r => delete c[r]);
            return c;
        };

        let content;
        if (customData.content && Object.keys(customData.content).length) { // One case
            content = Utils.extend(new Content(), customData.content);
        } else if (customData.contentId) { // Telia case
            content = Utils.extend(new Content(), customData);
        } else { // Bonnier case
            content = Utils.extend(new Content({
                contentId: request.media.entity
            }), customData);
        }

        content.auxiliaryData = content.auxiliaryData || {};

        if (!content.metadata && request.media && request.media.metadata) {
            content.metadata = MetadataMapper.mapFromGoogleMetadata(request.media.metadata);
        }

        return filterReservedCustomDataProperties(content);
    }
};