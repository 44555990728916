export const AdvertisementTypes = {
    None: 'AdvertisementTypes.None',
    Yospace: 'AdvertisementTypes.Yospace'
};

export const CipherTypes = {
    Aes128: 'CipherTypes.Aes128'
};

export const CipherModeTypes = {
    Cbcs: 'CipherModeTypes.Cbcs',
    Ctr: 'CipherModeTypes.Ctr'
};

export const ContainerTypes = {
    Mp2Ts: 'ContainerTypes.Mp2Ts',
    Mp3: 'ContainerTypes.Mp3',
    Mp4: 'ContainerTypes.Mp4',
    Mp4Cmaf: 'ContainerTypes.Mp4Cmaf',
    Mp4IsoBmff: 'ContainerTypes.Mp4IsoBmff'
};

export const ContentTypes = {
    AudioMp4: 'audio/mp4',
    Dash: 'application/dash+xml',
    Hls: 'application/x-mpegurl',
    TextVtt: 'text/vtt',
    VideoMp4: 'video/mp4'
};

export const ErrorCodes = {
    Generic: 'ErrorCodes.Generic',
    HandleLoadNextContentError: 'ErrorCodes.HandleLoadNextContentError',
    TryingToRecover: 'ErrorCodes.TryingToRecover',
    UncaughtError: 'ErrorCodes.UncaughtError',
    UncaughtRejection: 'ErrorCodes.UncaughtRejection',
    UpdateRequired: 'ErrorCodes.UpdateRequired'
};

/**
 * Enum
 * @readonly
 * @enum {string} <Media | Network | Drm | Advertisement | Default>
 */
export const ErrorCategories = {
    Media: 'ErrorCategories.Media',
    Network: 'ErrorCategories.Network',
    Drm: 'ErrorCategories.Drm',
    Advertisement: 'ErrorCategories.Advertisement',
    Default: 'ErrorCategories.Default'
};

export const ErrorOrigins = {
    Caf: {
        Context: 'Caf.Context',
        PlayerManager: 'Caf.PlayerManager'
    },
    Receiver: {
        Dush: 'Receiver.Dush',
        Interceptor: {
            Load: 'Receiver.Interceptor.Load'
        },
        Interface: {
            EventBus: 'Receiver.Interface.EventBus'
        },
        MediaElement: 'Receiver.MediaElement',
        Start: 'Receiver.Start',
        NextContent: 'Receiver.NextContent',
        ShakaWacka: 'Receiver.ShakaWacka',
        Wacka: 'Receiver.Wacka'
    },
    ServiceLayer: {
        DispatchError: 'ServiceLayer.DispatchError',
        Generic: 'ServiceLayer.Generic'
    },
    Window: {
        Error: 'Window.Error',
        Rejection: 'Window.Rejection'
    }
};

export const MetadataTypes = {
    AudioBookChapter: 'MetadataTypes.AudioBookChapter',
    Generic: 'MetadataTypes.Generic',
    Movie: 'MetadataTypes.Movie',
    MusicTrack: 'MetadataTypes.MusicTrack',
    Photo: 'MetadataTypes.Photo',
    TvShow: 'MetadataTypes.TvShow',
    Unknown: 'MetadataTypes.Unknown',
    User: 'MetadataTypes.User'
};

export const NetworkFilterTypes = {
    Request: 'NetworkFilterTypes.Request',
    Response: 'NetworkFilterTypes.Response'
};

export const PaddingTypes = {
    None: 'PaddingTypes.None',
    Pkcs7: 'PaddingTypes.Pkcs7',
    ZeroLength: 'PaddingTypes.ZeroLength'
};

export const ProtectionSystemTypes = {
    PlayReady: 'com.chromecast.playready',
    Widevine: 'com.widevine.alpha'
};

export const PlaybackStates = {
    Buffered: 'PlaybackStates.Buffered',
    Buffering: 'PlaybackStates.Buffering',
    Paused: 'PlaybackStates.Paused',
    Playing: 'PlaybackStates.Playing',
    Seeked: 'PlaybackStates.Seeked',
    Seeking: 'PlaybackStates.Seeking'
};

export const RequestContentTypes = {
    Legacy: 'RequestContentTypes.Legacy',
    Standard: 'RequestContentTypes.Standard'
};

export const RequestTypes = {
    Fetch: 'RequestTypes.Fetch',
    Xhr: 'RequestTypes.Xhr'
};

export const ReceiverStates = {
    LoadComplete: 'ReceiverStates.LoadComplete',
    LoadFailed: 'ReceiverStates.LoadFailed',
    LoadStarted: 'ReceiverStates.LoadStarted',
    StreamFailed: 'ReceiverStates.StreamFailed',
    StreamInitialized: 'ReceiverStates.StreamInitialized',
    ServiceLayerInitialized: 'ReceiverStates.ServiceLayerInitialized'
};

export const SenderTypes = {
    Android: 'SenderTypes.Android',
    iOS: 'SenderTypes.iOS',
    Web: 'SenderTypes.Web',
    Unknown: 'SenderTypes.Unknown'
};

export const ShutdownReasons = {
    Caf: 'ShutdownReasons.Caf',
    Error: 'ShutdownReasons.Error',
    RequestedBySender: 'ShutdownReasons.RequestedBySender',
    UnifiedCommand: 'ShutdownReasons.UnifiedCommand'
};

export const StreamTypes = {
    CatchUp: 'StreamTypes.CatchUp',
    Dvr: 'StreamTypes.Dvr',
    Live: 'StreamTypes.Live',
    OnDemand: 'StreamTypes.OnDemand',
    StartOver: 'StreamTypes.StartOver'
};

export const TrackTypes = {
    Audio: 'TrackTypes.Audio',
    Text: 'TrackTypes.Text',
    Video: 'TrackTypes.Video',
    Unknown: 'TrackTypes.Unknown'
};

/**
 * Enum
 * @readonly
 * @enum {string} <AdvertisementSwitch | AdvertisementSkip | Error | EndOfStream | Inactivity | Interrupted | NextContentAutomatic | User | Unknown >
 */
export const StoppedReasons = {
    AdvertisementSkip: 'ADVERTISEMENT_SKIP',
    AdvertisementSwitch: 'ADVERTISEMENT_SWITCH',
    EndOfStream: 'END_OF_STREAM',
    Error: 'ERROR',
    Inactivity: 'INACTIVITY',
    Interrupted: 'INTERRUPTED',
    NextContentAutomatic: 'NEXT_CONTENT_AUTOMATIC',
    User: 'USER',
    Unknown: 'UNKNOWN'
};

/**
 * Enum
 * @readonly
 * @enum {string} <Emsg | Id3 | Id3InEmsg | Scte35 | Unknown>
 */
export const StreamCueTypes = {
    Emsg: 'StreamCueTypes.Emsg',
    Id3: 'StreamCueTypes.Id3',
    Id3InEmsg: 'StreamCueTypes.Id3InEmsg',
    Scte35: 'StreamCueTypes.Scte35',
    Unknown: 'StreamCueTypes.Unknown'
};