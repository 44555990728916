const { StoppedReasons } = require('../../Constants');

/**
 * // ToDo: emit events for both IdleTimeout & WatchedTime (every half hour?) (Prio 3)
 * // Note: WatchedTime event to be used for "Are you still watching?" feature
 */
module.exports = class SessionManager {
    constructor({ engine, uiManager }) {
        this.engine = engine;
        this.uiManager = uiManager;
        this.mediaElement = this.uiManager.domElements.mediaElement;

        this.debug = false;
        this.destroyed = false;
        this.lastCurrentTime = 0;
        this.lastUpdate = Date.now();
        this.mediaElementEventListeners = {};
        this.probeInterval = (30) * 1000;
        this.timeOutThreshold = (20.5) * 60;

        if (this.debug) {
            this.timeOutThreshold = 30;
            this.probeInterval = 2000;
        }

        this.interval = setInterval(this.probe.bind(this), this.probeInterval);

        this.registerEventListeners();
    }

    registerEventListeners() {
        this.mediaElement.addEventListener('pause',
          this.mediaElementEventListeners['pause'] = this.probe.bind(this, 'pause'));
        this.mediaElement.addEventListener('play',
          this.mediaElementEventListeners['play'] = this.probe.bind(this, 'play'));
        this.mediaElement.addEventListener('seeking',
          this.mediaElementEventListeners['seeking'] = this.probe.bind(this, 'seeking'));
    }

    unregisterEventListeners() {
        Object.keys(this.mediaElementEventListeners).forEach(e =>
          this.mediaElement.removeEventListener(e,
            this.mediaElementEventListeners[e]
          )
        );
        this.mediaElementEventListeners = {};
    }

    idleTimeout() {
        this.destroyed = true; // Prevent probe function from running again while engine is stopping

        // engine.stop() will lead to reset() being called by the receiver, hence re-setting the destroyed flag once stopped
        this.engine
          .stop(StoppedReasons.Inactivity)
          .then(() => {
              this.destroyed = true;
          });
    }

    probe(e) {
        if (this.destroyed) return;

        this.debug && console.log(`SessionManager::probe: activated by ${e || 'timer'}`);

        if (this.mediaElement && this.mediaElement.src !== '') {
            if (this.lastCurrentTime !== this.mediaElement.currentTime) {
                this.lastCurrentTime = this.mediaElement.currentTime;
                this.lastUpdate = Date.now();
            } else {
                let idleTime = Math.floor((Date.now() - this.lastUpdate) / 1000);
                if (idleTime >= this.timeOutThreshold) {
                    this.idleTimeout();
                }
                this.debug && console.log('IdleTime:', idleTime, 'Time to die:', this.timeOutThreshold - idleTime);
            }
        }
    }

    reset() {
        this.destroyed = false;
        this.lastCurrentTime = 0;
        this.lastUpdate = Date.now();

        if (!this.interval) {
            this.interval = setInterval(this.probe.bind(this), this.probeInterval);
        }
    }

    destroy() {
        this.destroyed = true;

        this.unregisterEventListeners();

        this.engine = null;
        this.uiManager = null;
        this.mediaElement = null;

        if (this.interval) {
            this.interval = clearInterval(this.interval);
        }
    }
};