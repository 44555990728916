import { PlaybackEventBase } from './PlaybackEventBaseClass';
import { TLanguageCode } from './Types';
import { PlaybackEventTypes } from "../Constants";

type TTextTrackChangedEventPayload = {
    language: keyof TLanguageCode;
    type: TTextTrackType;
}

export type TTextTrackType = "subtitle" | "caption";

export class TextTrackChangedEvent extends PlaybackEventBase<PlaybackEventTypes.TEXT_TRACK_CHANGED, TTextTrackChangedEventPayload> {
    constructor(payload: TTextTrackChangedEventPayload) {
        super(PlaybackEventTypes.TEXT_TRACK_CHANGED, payload);
    }
}
