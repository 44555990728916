module.exports = class IPlaybackFeature {
    constructor() {
    }

    static get Name() {
        return 'IPlaybackFeature';
    }

    /** @returns {Promise<any>} */
    initialize(...args) {
    }

    setEngine(engine) {
    }

    setEngineEventHandler(engineEventHandler = (event, data) => {
    }) {
    }

    reset() {
    }

    destroy() {
    }
};