const {
    AdvertisementTypes,
    CipherTypes,
    CipherModeTypes,
    ContainerTypes,
    ContentTypes,
    ErrorCategories,
    ErrorCodes,
    ErrorOrigins,
    MetadataTypes,
    NetworkFilterTypes,
    PaddingTypes,
    ProtectionSystemTypes,
    PlaybackStates,
    RequestTypes,
    ReceiverStates,
    SenderTypes,
    ShutdownReasons,
    StoppedReasons,
    StreamCueTypes,
    StreamTypes,
    TrackTypes
} = require('./Constants');

const {
    Advertisement,
    AdvertisementBreak,
    Content,
    Credentials,
    DeviceInfo,
    Drm,
    Encryption,
    HandledError,
    Image,
    Language,
    Load,
    LoadAdvertisement,
    LoadTracking,
    Metadata,
    NormalizedError,
    PlaybackSessionState,
    PreferredLanguage,
    ReceiverConfig,
    ReceiverInformation,
    ReceiverError,
    Sender,
    ServiceLayerError,
    SessionState,
    Shutdown,
    Stream,
    StreamRestrictions
} = require('./dtos/');

const {
    CommunicationManagerEvents,
    ContentManagerEvents,
    DushEvents,
    EngineEvents,
    FetchEvents,
    MediatorEvents,
    NetworkEvents,
    NotificationEvents,
    ReceiverEvents,
    ShakaWackaEvents,
    TimelineManagerEvents,
    TrackManagerEvents,
    WackaEvents,
    XhrEvents
} = require('./Events');

const EventPayloads = require('./event/EventPayloads');
const I18n = require('./i18n/I18n');
const ImageInterceptor = require('./utils/network/ImageInterceptor');
const IOverlay = require('./ui/IOverlay');
const IServiceLayer = require('./service/IServiceLayer');
const IYospacePlaybackFeature = require('./player/features/IYospacePlaybackFeature');
const IPlaybackFeature = require('./player/features/IPlaybackFeature');
const LanguageMapper = require('./mappers/LanguageMapper');
const NetworkInterceptor = require('./utils/network/NetworkInterceptor');
const Receiver = require('./receiver');
const ReceiverInterface = require('./service/ReceiverInterface');
const Utils = require('./utils/Utils');
const { DefaultServiceLayerConfiguration } = require('./service/Types');
const {
    AdvertisementEndedEvent,
    AdvertisementStartedEvent,
    AdvertisementBreakEndedEvent,
    AdvertisementBreakStartedEvent,
    AdvertisementTimeUpdateEvent,
    BitrateChangedEvent,
    BufferedEvent,
    BufferingEvent,
    CdnChangedEvent,
    DrmChangedEvent,
    DroppedFramesEvent,
    LoadStartEvent,
    LoadedDataEvent,
    LoadedMetadataEvent,
    PauseEvent,
    PlayEvent,
    SeekableRangeUpdatedEvent,
    SeekedEvent,
    SeekingEvent,
    StreamChangedEvent,
    StreamCueEvent,
    StreamFinishedEvent,
    SystemVolumeChangedEvent,
    TimeUpdateEvent
} = require('./event/EventPayloads')
module.exports = {
    // Constants
    AdvertisementTypes,
    CipherTypes,
    CipherModeTypes,
    ContainerTypes,
    ContentTypes,
    ErrorCategories,
    ErrorCodes,
    ErrorOrigins,
    MetadataTypes,
    NetworkFilterTypes,
    PaddingTypes,
    ProtectionSystemTypes,
    PlaybackStates,
    RequestTypes,
    ReceiverStates,
    SenderTypes,
    ShutdownReasons,
    StreamTypes,
    StreamCueTypes,
    StoppedReasons,
    TrackTypes,

    // Dtos
    Advertisement,
    AdvertisementBreak,
    Content,
    Credentials,
    DeviceInfo,
    Drm,
    Encryption,
    HandledError,
    Image,
    Language,
    Load,
    LoadAdvertisement,
    LoadTracking,
    Metadata,
    NormalizedError,
    PlaybackSessionState,
    PreferredLanguage,
    ReceiverConfig,
    ReceiverInformation,
    ReceiverError,
    Sender,
    ServiceLayerError,
    SessionState,
    Shutdown,
    Stream,
    StreamRestrictions,

    // Events
    CommunicationManagerEvents,
    ContentManagerEvents,
    DushEvents,
    EngineEvents,
    FetchEvents,
    MediatorEvents,
    NetworkEvents,
    NotificationEvents,
    ReceiverEvents,
    ShakaWackaEvents,
    TimelineManagerEvents,
    TrackManagerEvents,
    WackaEvents,
    XhrEvents,
    //EventPayloads
    AdvertisementEndedEvent,
    AdvertisementStartedEvent,
    AdvertisementBreakEndedEvent,
    AdvertisementBreakStartedEvent,
    AdvertisementTimeUpdateEvent,
    BitrateChangedEvent,
    BufferedEvent,
    BufferingEvent,
    CdnChangedEvent,
    DrmChangedEvent,
    DroppedFramesEvent,
    LoadStartEvent,
    LoadedDataEvent,
    LoadedMetadataEvent,
    PauseEvent,
    PlayEvent,
    SeekableRangeUpdatedEvent,
    SeekedEvent,
    SeekingEvent,
    StreamChangedEvent,
    StreamCueEvent,
    StreamFinishedEvent,
    SystemVolumeChangedEvent,
    TimeUpdateEvent,
    //Other
    DefaultServiceLayerConfiguration,
    I18n,
    ImageInterceptor,
    IOverlay,
    IServiceLayer,
    IYospacePlaybackFeature,
    IPlaybackFeature,
    LanguageMapper,
    NetworkInterceptor,
    Receiver,
    ReceiverInterface,
    Utils,
    Version: __VERSION__ // Will be injected at build time
};