import { PlaybackEventBase } from './PlaybackEventBaseClass';
import { PlaybackEventTypes } from '../Constants';

type TAdvertisementTimeUpdateEventPayload = {
    advertisementCurrentTime: number;
    advertisementDuration: number;
    currentTime: number;
};

export class AdvertisementTimeUpdateEvent extends PlaybackEventBase<PlaybackEventTypes.ADVERTISEMENT_TIME_UPDATE, TAdvertisementTimeUpdateEventPayload> {
    constructor(payload: TAdvertisementTimeUpdateEventPayload) {
        super(PlaybackEventTypes.ADVERTISEMENT_TIME_UPDATE, payload);
    }
};
