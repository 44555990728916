import { Receiver } from '@tv4/unified-receiver';
import { EnvTypes, ServiceTypes } from './Types';
import { AvodServiceLayer } from './AvodServiceLayer';

import './css/default.css';
import './css/services.css';

import { GetConfig } from './ConfigurationFactory';

const ENVIRONMENTS = ['development', 'production'];
const SERVICES = ['fotbollskanalen', 'tv4play', 'tv4se', 'koket', 'mtv', 'tv4'];

(() => {
  document.addEventListener('DOMContentLoaded', () => {
    const env = process.env.NODE_ENV as EnvTypes;
    if (!ENVIRONMENTS.includes(env)) {
      throw new Error(`Error class:index.ts : Target environment '${env}' is not supported!`);
    }

    const service = getService(env);

    document.body.classList.add(service);

    const config = GetConfig(env, service);

    Receiver.initialize(config);
    Receiver.start(AvodServiceLayer);
  });
})();

const getService = (env: EnvTypes): ServiceTypes => {
  let service: ServiceTypes;
  if (window.location && window.location.search) {
    const params = new URLSearchParams(window.location.search);
    service = params.get('service') as ServiceTypes;
  } else {
    if (env === 'development') {
      console.group('Warning');
      console.log('Using default service TV4Play');
      console.groupEnd();
      service = 'tv4play';
    } else {
      service = 'tv4se';
    }
  }

  if (!SERVICES.includes(service)) {
    throw new Error(`Error class:index.ts : Service '${service}' is not supported!`);
  }

  return service;
};
