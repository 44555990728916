const Utils = require('../utils/Utils');

/**
 * @typedef {object} TPlaybackSessionStateArgs
 * @property {number} currentTime
 * @property {number} duration
 */
/**
 * @type {TPlaybackSessionStateArgs}
 */
class Props {
    constructor() {
        this.currentTime = this.duration = undefined;
    }
}

class PlaybackSessionState extends Props {
    /**
     * @param {TPlaybackSessionStateArgs} args
     */
    constructor(args = {}) {
        super();

        Utils.extend(this, {}, args);
    }
}

module.exports = PlaybackSessionState;