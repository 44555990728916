export interface ILogger {
    error: (message?: any, ...optionalParams: any[]) => void;
    warn: (message?: any, ...optionalParams: any[]) => void;
    info: (message?: any, ...optionalParams: any[]) => void;
    debug: (message?: any, ...optionalParams: any[]) => void;
    log: (message?: any, ...optionalParams: any[]) => void;
}

export class Logger {
    private static imp: ILogger = {
        error: console.error,
        warn: console.warn,
        info: console.info,
        debug: console.debug,
        log: console.log
    }

    public static setLoggerImplementation(implementation: ILogger) {
        this.imp = implementation;
    }

    public static getLoggerImplementation():ILogger {
        return this.imp;
    }
    private static pLogLevel: number = 0;

    public static createLoggerContext(contextName: string): LoggerInstance {
        return new LoggerInstance(contextName);
    }

    public static setLogLevel(logLevel: number) {
        if (logLevel >= 0 && logLevel <= 5) {
            this.pLogLevel = logLevel;
        }
    }

    public static getLogLevel(): number {
        return this.pLogLevel;
    }

    public static error(message?: any, ...optionalParams: any[]) {
        if (Logger.pLogLevel >= 1) {
            Logger.imp.error(message, ...optionalParams);
        }
    }

    public static warn(message?: any, ...optionalParams: any[]) {
        if (Logger.pLogLevel >= 2) {
            Logger.imp.warn(message, ...optionalParams);
        }
    }

    public static debug(message?: any, ...optionalParams: any[]) {
        if (Logger.pLogLevel >= 3) {
            Logger.imp.debug(message, ...optionalParams);
        }
    }

    public static info(message?: any, ...optionalParams: any[]) {
        if (Logger.pLogLevel >= 4) {
            Logger.imp.info(message, ...optionalParams);
        }
    }

    public static log(message?: any, ...optionalParams: any[]) {
        if (Logger.pLogLevel >= 5) {
            Logger.imp.log(message, ...optionalParams);
        }
    }
}

export class LoggerInstance implements ILogger {
    public debugMode: boolean = false;
    public logLevel: number | null = null;
    private implementation: ILogger
    private contentName:string;

    public setLogLevel(logLevel: number) {
        if (logLevel >= 0 && logLevel <= 5) {
            this.logLevel = logLevel;
        }
    }

    public getLogLevel(): number {
        return this.logLevel ?? Logger.getLogLevel();
    }
    public getImplementation():ILogger {
        return this.implementation ?? Logger.getLoggerImplementation();
    }

    public setLoggerImplementation(implementation: ILogger) {
        this.implementation = implementation;
    }

    constructor(contentName: string) {
        this.contentName = contentName;
    }

    error(message?: any, ...optionalParams: any[]) {
        if (this.getLogLevel() >= 1 || this.debugMode) {
            this.getImplementation().error(`[error][${this.contentName}] | `,message, ...optionalParams);
        }
    }

    warn(message?: any, ...optionalParams: any[]) {
        if (this.getLogLevel() >= 2 || this.debugMode) {
            this.getImplementation().warn(`[warn][${this.contentName}] | `,message, ...optionalParams);
        }
    }

    debug(message?: any, ...optionalParams: any[]) {
        if (this.getLogLevel() >= 3 || this.debugMode) {
            this.getImplementation().debug(`[debug][${this.contentName}] | `,message, ...optionalParams);
        }
    }

    info(message?: any, ...optionalParams: any[]) {
        if (this.getLogLevel() >= 4 || this.debugMode) {
            this.getImplementation().info(`[info][${this.contentName}] | `,message, ...optionalParams);
        }
    }

    log(message?: any, ...optionalParams: any[]) {
        if (this.getLogLevel() >= 5 || this.debugMode) {
            this.getImplementation().log(`[log][${this.contentName}] | `,message, ...optionalParams);
        }
    }
}
