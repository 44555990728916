import { ErrorCodes } from '@tv4/unified-receiver';
import { ServiceLayerErrorCodes, Translations } from '../Constants';

export const fi = {
  [ErrorCodes.Generic]: 'Jokin meni pieleen - yritä myöhemmin uudelleen',
  [ErrorCodes.UpdateRequired]: 'Laitteesi täytyy päivittää',
  [ErrorCodes.TryingToRecover]: 'Virhetilanne<br /><br />Yritetään luoda uusi yhteys',

  [ServiceLayerErrorCodes.Api.MissingAssetOrMedia]: 'Sisältöä ei löydy - yritä myöhemmin uudelleen',
  [ServiceLayerErrorCodes.Configuration.MissingSdkService]: 'Käynnistys epäonnistui - yritä myöhemmin uudelleen',
  [ServiceLayerErrorCodes.Device.Gen1NotSupported]:
    'Tuki käyttämällesi Chromecast -mallille on päättynyt. Vaihda Chromecast uudempaan versioon tai tutustu muihin katselutapoihimme. Lisätietoja katselutavoista löydät osoitteesta mtv.fi/ohjeet.',
  [ServiceLayerErrorCodes.GeoRestricted.Generic]:
    'Pahoittelut! Tämä ohjelma on ohjelmaoikeuksista johtuen katsottavissa vain Suomessa.',
  [ServiceLayerErrorCodes.GeoRestricted.Portability]:
    'Pahoittelut! Tämä ohjelma on ohjelmaoikeuksista johtuen katsottavissa vain Suomessa.',
  [ServiceLayerErrorCodes.Load.Cancelled]: 'Lataus keskeytetty - yritä myöhemmin uudelleen',
  [ServiceLayerErrorCodes.StreamLimit.Generic]:
    'Vaikuttaa siltä, että käyttäjätunnuksellasi on juuri nyt auki liian monta lähetystä.',
  [ServiceLayerErrorCodes.Credentials.MissingOrInvalidRefreshToken]: 'Autentikointi epäonnistui',
  [Translations.Overlay.CustomPauseAd.TopBar]: 'Mainos: Klikkaa play-ikonia jatkaaksesi katselua',
};
