export class PlayerInfo {
    public playerName: string;
    public playerVersion: string;
    public playerEngine?: string;
    public supportedDRMSystems?: Array<string>;
    public supportedStreamingProtocols?: Array<string>;

    constructor(args?: PlayerInfo) {
        if (args) {
            this.playerEngine = args.playerEngine;
            this.playerName = args.playerName;
            this.playerVersion = args.playerVersion;
            this.supportedDRMSystems = args.supportedDRMSystems || [];
            this.supportedStreamingProtocols = args.supportedStreamingProtocols || [];
        }
    }
}
