import {
    FetchRequestOptions,
    FetchRequestResponse
} from '../../network';
import { BaseService } from '../BaseService';

import { AuthenticationServiceConfiguration, IAuthenticationComponent, ServiceConfig } from '../Types';
import { ServiceError } from '../errors/';
import { AuthenticationInfo, Credentials, UserInfo } from './Types';
import { parseUserInfoFromAccessToken, parseUserInfoFromToken } from './utils/Token';
import {
    ErrorCategories,
    NetworkError,
    LoginGatewayError,
    JSONStringifyWithErrors
} from '@tv4/one-playback-sdk-shared';
import {ServiceErrorCodes} from "../errors";

export type AuthenticationServiceData = {
    accessToken: string;
    refreshToken: string;
    validTo: string;
    geoblock: boolean;
};


const ErrorCodesNamesMap: Record<number, string> = {
    9020: 'TryAgain',
    50002: 'UsernameOrPasswordIncorrect',
    50003: 'AccountBlocked',
    50004: 'AccountClosed',
    50005: 'IllegalRefreshToken',
    50007: 'EmptyRefreshToken',
    50008: 'TokenNotFound',
    50011: 'UserNotFound'
};

export class AuthenticationService extends BaseService implements IAuthenticationComponent {
    readonly isAuthenticationDelegated: boolean = false;

    private accessToken?: string;
    protected configuration?: AuthenticationServiceConfiguration | ServiceConfig;
    private credentials?: Credentials;
    private refreshToken?: string;
    private userInfo:UserInfo | null = null;
    private validTo?: Date;

    protected headers: any = {};

    constructor() {
        super('AuthenticationService');
    }

    public initialize(configuration: AuthenticationServiceConfiguration | ServiceConfig) {
        this.configuration = configuration;

        this.headers = {
            'client-name': this.configuration.clientName,
            'X-Country': this.configuration.serviceCountry.toLowerCase()
        };
    }

    private createRequestOptions(args: any): FetchRequestOptions {
        let body = ''
        if (this.configuration) {
            body = JSON.stringify({
                deviceId: this.configuration.deviceId,
                whiteLabelBrand: this.configuration.serviceBrand,
                deviceType: "WEB",
                ...args
            })
        }
        return {
            body: body,
            headers: this.headers,
            useAuthentication: false
        };
    }

    /**
     * it's seems that the profileHeader can be undefined for the default profile in some cases
     */
    public updateProfileHeader(profileHeader:string | undefined){
        if(this.credentials && this.credentials.profileHeader !== profileHeader){
            this.credentials.profileHeader = profileHeader;
        }
    }

    public getProfileHeader(): string | null {
        return this.credentials?.profileHeader || null;
    }

    public async getAccessToken(): Promise<string> {
        const authenticationInfo = await this.getAuthenticationInfo();
        if (!authenticationInfo || !authenticationInfo.isAuthenticated || !authenticationInfo.accessToken) {
            throw new ServiceError({
                code: ServiceErrorCodes.MissingAuthentication,
                category: ErrorCategories.LOGIN_GATEWAY,
                fatal: true,
                details: {
                    origin: this.name,
                    domain: 'login',
                    configuration: this.configuration,
                    isAuthenticated: !!authenticationInfo!.isAuthenticated,
                    accessToken: authenticationInfo!.accessToken || 'missing',
                    credentials: this.credentials || 'missing'
                }
            });
        }
        else {
            return authenticationInfo.accessToken;
        }
    }

    public async getAuthenticationInfo(): Promise<AuthenticationInfo | null> {
        if (this.accessToken == null || this.refreshToken == null) {
            return null
        }
        let authenticationInfo: AuthenticationInfo | null = {
            isAuthenticated: false,
            accessToken: this.accessToken,
            refreshToken: this.refreshToken,
            validTo: this.validTo
        };
        if (this.accessToken && this.isValid()) {
            authenticationInfo.isAuthenticated = true;
        }
        else if (this.refreshToken) {
            try {
                authenticationInfo = await this.login({
                    token: this.refreshToken
                });
            } catch (e) {} // Todo: Throw new FailedToRefreshError ...
        }
        else {
            authenticationInfo.isAuthenticated = false;
        }

        return authenticationInfo;
    }

    public async isLoginRequired(refreshToken:string):Promise<boolean> {
        const currentUserId = this.userInfo?.userId || null;
        const incomingUserId = parseUserInfoFromToken(refreshToken);

        if(currentUserId != null && incomingUserId != null && currentUserId !== incomingUserId){
            return true;
        }

        if (!this.accessToken || !this.isValid()) {
            return true;
        }

        return false;
    }

    public async isAuthenticated(): Promise<boolean> {
        return (this.accessToken != null && this.isValid());
    }

    private isValid(): boolean {
        if (this.validTo) {
            // @ts-ignore
            let timeReminding: number = (this.validTo - new Date()) / 1000;
            let threshold = 0;
            return (timeReminding - threshold) > 0;

        }
        return false;
    }

    public async getUserInfo(): Promise<UserInfo | null> {
        const accessToken = await this.getAccessToken();
        if (!accessToken) return null;

        return parseUserInfoFromAccessToken(accessToken);
    }

    /**
     * @param {Credentials} credentials
     * @throws {ServiceError} - MissingCredentials, BadResponseError
     * @throws {LoginGatewayError}
     */
    public async login(credentials: Credentials): Promise<AuthenticationInfo> {
        if (!this.configuration) {
            throw new ServiceError({
                code: ServiceErrorCodes.MissingConfiguration,
                category: ErrorCategories.DEFAULT,
                fatal: true,
                details: {
                    origin: this.name,
                    domain: 'login',
                    configuration: this.configuration,
                }
            });
        }

        this.credentials = credentials;
        let response: FetchRequestResponse | NetworkError | null = null;

        if (credentials.username && credentials.password) {
            response = await this.requestFactory.fetch(`${this.configuration.loginGatewayUrl}/logingateway/rest/v1/login`, this.createRequestOptions({
                username: credentials.username,
                password: credentials.password
            }));
        }
        else if (credentials.token) {
            const token = credentials.token.replace('Bearer ', '');
            if ((credentials.token as string).includes('refresh')) {
                response = await this.requestFactory.fetch(`${this.configuration.loginGatewayUrl}/logingateway/rest/v1/login/refresh`, this.createRequestOptions({
                    refreshToken: token
                }));
            }
        }
        else {
            throw new ServiceError({
                code: ServiceErrorCodes.MissingCredentials,
                category: ErrorCategories.DEFAULT,
                fatal: true,
                details: {
                    origin: this.name,
                    domain: 'login',
                    credentials,
                    configuration: this.configuration,
                }
            });
        }

        if (response instanceof NetworkError) {
            if (response.responseBody && response.responseBody.errorCode) {
                throw new LoginGatewayError({
                    fatal: true,
                    originalError: response,
                    details: {
                        origin: this.name,
                        domain: 'login',
                        configuration: this.configuration,
                    }
                });
            }

            throw response;
        }
        else if (response instanceof FetchRequestResponse) {
            const data = response.responseBody as AuthenticationServiceData;
            if (!data) {
                throw new ServiceError({
                    code: ServiceErrorCodes.BadResponseError,
                    category: ErrorCategories.LOGIN_GATEWAY,
                    details: {
                        response,
                        origin: this.name,
                        domain: 'login',
                        configuration: this.configuration,
                    },
                    fatal: true,
                });
            }

            this.accessToken = data.accessToken;
            this.refreshToken = data.refreshToken;
            this.validTo = new Date(data.validTo);
            this.userInfo = parseUserInfoFromAccessToken(this.accessToken)

            return {
                isAuthenticated: true,
                accessToken: this.accessToken,
                refreshToken: this.refreshToken,
                validTo: this.validTo
            };
        }
        else {
            let responseString = 'Could not read response'

            if(response) {
                if (typeof response === 'object') {
                    try {
                        responseString = JSONStringifyWithErrors(response);
                    } catch (e) {}
                } else {
                    responseString = ''+response;
                }
            }

            throw new ServiceError({
                code: ServiceErrorCodes.UnknownError,
                category: ErrorCategories.LOGIN_GATEWAY,
                message: responseString,
                fatal: true,
                details: {
                    origin: this.name,
                    domain: 'login',
                    configuration: this.configuration,
                },
                originalError: 'unknown'
            });
        }
    }

    async reset(): Promise<void> {
        return Promise.resolve();
    }

    async destroy(): Promise<void> {
        return Promise.resolve();
    }
};
