const Interceptor = require('./Interceptor');

module.exports = class InterceptorFactory {
    constructor() {
        this.playerManager = null;
    }

    createInterceptor(config) {
        if (!this.playerManager) {
            throw new Error('Error class:InterceptorFactory[createInterceptor] : Missing playerManager!');
        }

        const interceptor = new Interceptor(config);

        this.playerManager.setMessageInterceptor(interceptor.key, interceptor.interceptHandler.bind(interceptor));

        if (interceptor.event) {
            this.playerManager.addEventListener(interceptor.event, interceptor.preRequestHandler.bind(interceptor));
        }

        return interceptor;
    }

    registerPlayerManager(playerManager) {
        this.playerManager = playerManager;
    }
};