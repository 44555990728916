const ImageExtension = require('./ImageExtension');

let instance;
module.exports = class ImageInterceptor {
    static getInstance() {
        if (!instance) {
            instance = new ImageInterceptor();
        }

        return instance;
    }

    constructor() {
        this.interceptors = [];

        this.Image = window.Image;
        this.ImageExtension = ImageExtension;
        this.ImageExtension.prototype.imageInterceptor = this;
    }

    getNetworkHandlers(url) {
        let handlers = [];
        for (let i in this.interceptors) {
            if (!this.interceptors.hasOwnProperty(i)) continue;

            if (this.interceptors[i].pattern) {
                if (
                  ('string' === typeof (this.interceptors[i].pattern) && url === this.interceptors[i].pattern) ||
                  ((this.interceptors[i].pattern instanceof RegExp) && url.match(this.interceptors[i].pattern))
                ) {
                    handlers.push(this.interceptors[i].networkHandler);
                }
            }
        }

        return handlers;
    }

    intercept() {
        window.Image = this.ImageExtension;

        return this;
    }

    registerInterceptor(pattern, networkHandler) {
        if (pattern && networkHandler && (networkHandler.requestHandler || networkHandler.responseHandler)) {
            this.interceptors.push({
                pattern,
                networkHandler
            });
        }

        return this;
    }

    reset() {
        this.interceptors = [];

        return this;
    }

    destroy() {
        this.reset();

        window.Image = this.Image;

        instance = null;
        this.Image = null;
        this.ImageExtension = null;
    }
};