const IPlaybackFeature = require('./IPlaybackFeature');

module.exports = class IYospacePlaybackFeature extends IPlaybackFeature {
    constructor(...args) {
        super(...args);
    }

    static get Name() {
        return 'IYospacePlaybackFeature';
    }

    /** @returns {Promise<any>} */
    initialize(...args) {
    }

    /** @returns {number} */
    get currentTime() {
    }

    /** @returns {number} */
    get duration() {
    }

    interceptEngineEvent(event, data) {
    }

    handlePause() {
    }

    handlePlay() {
    }

    handleSeek() {
    }

    reset() {
    }

    destroy() {
    }
};