export const DEFAULT_SERVICE_LANGUAGE = 'sv';

export enum ErrorCategories {
  ADS = 'Ads',
  API = 'API',
  DEFAULT = 'Default',
  DRM = 'DRM',
  GRAPH = 'Graph',
  LOGIN_GATEWAY = 'LoginGateway',
  MEDIA = 'Media',
  NETWORK = 'Network',
  STREAMING_GATEWAY = 'StreamingGateway',
  TV_CLIENT_GATEWAY = 'TvClientGateway',
  PLAYBACK_API = 'PlaybackApi',
  USER = 'User',
}

export const ServiceLayerErrorCodes = {
  Api: {
    MissingAssetOrMedia: 'ServiceLayer:Api:MissingAssetOrMedia',
  },
  Configuration: {
    MissingSdkService: 'ServiceLayer:Configuration:MissingSdkService',
  },
  Device: {
    Gen1NotSupported: 'ServiceLayer:Device:Gen1NotSupported',
  },
  GeoRestricted: {
    Generic: 'ServiceLayer:GeoRestricted:Generic',
    Portability: 'ServiceLayer:GeoRestricted:Portability',
  },
  Load: {
    Cancelled: 'ServiceLayer:Load:Cancelled',
  },
  StreamLimit: {
    Generic: 'ServiceLayer:StreamLimit:Generic',
  },
  Credentials: {
    MissingOrInvalidRefreshToken: 'ServiceLayer:Credentials:MissingOrInvalidRefreshToken',
  },
};

export const Translations = {
  Overlay: {
    CustomPauseAd: {
      TopBar: 'Translations.Overlay.CustomPauseAd.TopBar',
    },
  },
};
