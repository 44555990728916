import {IQuery} from '../Types'
import {ExtendedSeries,SimpleSeries, Playback, Images} from './Generics'


type MediaMetadataQueryVariables ={
    id: string;
    useExtended?: boolean
}
export class MediaMetadataQuery implements IQuery {

    generateBody(queryVariables: MediaMetadataQueryVariables): string {
        const series = queryVariables.useExtended ? ExtendedSeries : SimpleSeries;
        const operationName = 'media'
        const query = `
          query media($id: String!) {
            media(id: $id) {
              __typename
              ... on SportEvent {
                id
                title
                descriptionLong
                images {
                    showcard2x3 {
                      sourceNonEncoded
                    }
                    showcard16x9 {
                      sourceNonEncoded
                    }
                    backdrop16x9 {
                      sourceNonEncoded
                    }
                }
                playback {
                  play {
                    ${Playback}
                    npvr {
                      npvrInfo {
                        originalAirDate {
                          startDate {
                            timestamp
                          }
                          endDate {
                            timestamp
                          }
                        }
                      }
                    }
                  }
                  buy {
                    ${Playback}
                  }
                }
              }
              ... on Movie {
                id
                title
                descriptionLong
                images {
                  ${Images}
                }
                playback {
                  play {
                    ${Playback}
                    npvr {
                      npvrInfo {
                        originalAirDate {
                          startDate {
                            timestamp
                          }
                          endDate {
                            timestamp
                          }
                        }
                      }
                    }
                  }
                  buy {
                    ${Playback}
                  }
                }
                
              }
              ... on Episode {
                id
                title
                descriptionLong
                images {
                  ${Images}
                }
                nextEpisode {
                  id
                  title
                  descriptionLong,
                  episodeNumber {
                    number
                  }
                  seasonNumber {
                    number
                  }
                  series {
                    title
                  }
                  images {
                    ${Images}
                  }
                  playback {
                    play {
                      ${Playback}
                    }
                  }
                }
                episodeNumber {
                  number
                }
                seasonNumber {
                  number
                }
                ${series}
                playback {
                  play {
                    ${Playback}
                    npvr {
                      npvrInfo {
                        originalAirDate {
                          startDate {
                            timestamp
                          }
                          endDate {
                            timestamp
                          }
                        }
                      }
                    }
                  }
                  buy {
                    ${Playback}
                  }
                }
             
              }
            }
          }
        `

        // no need to send 'useExtended'
        //delete queryVariables.useExtended;
        return  JSON.stringify({
            operationName,
            query,
            variables: queryVariables
        });
    }
}
