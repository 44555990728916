const FadingOverlay = require('./FadingOverlay');

module.exports = class NotificationsOverlay extends FadingOverlay {
    constructor({ container }) {
        super({ container });
        this.notificationTimeOut = null;
    }

    static get Name() {
        return 'Notifications';
    }

    initialize() {
        super.initialize(NotificationsOverlay.Name.toLowerCase());
    }

    showNotification(notification, duration) {
        if (this.content) {
            this.content.innerHTML = notification;
            this.show();
            if (this.notificationTimeOut) {
                clearTimeout(this.notificationTimeOut)
            }
            this.notificationTimeOut = setTimeout(() => this.fadeOut(), duration || 5000);
        }
    }

    clearNotification(notification, duration) {
        if (this.content) {
            if (this.notificationTimeOut) {
                this.fadeOut();
                clearTimeout(this.notificationTimeOut);
            }
        }
    }
};