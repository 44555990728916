const Credentials = require('../dtos/Credentials');
const Language = require('../dtos/Language');
const Load = require('../dtos/Load');
const LoadAdvertisement = require('../dtos/LoadAdvertisement');
const LoadTracking = require('../dtos/LoadTracking');

module.exports = class CustomDataMapper {
    constructor() {
    }

    static mapToLoadDto(customData) {
        const load = new Load();

        const supportedProperties = {
            'advertisement': LoadAdvertisement,
            'credentials': Credentials,
            'language': Language,
            'tracking': LoadTracking
        };

        Object.keys(supportedProperties).forEach(key => {
            if (customData[key]) {
                load[key] = new supportedProperties[key](customData[key]);
                delete customData[key];
            }
        });

        return load;
    }
};