const Utils = require('../utils/Utils');
const Content = require('./Content');
const PlaybackSessionState = require('./PlaybackSessionState');

/**
 * @typedef {object} TSessionStateArgs
 * @property {Content} [content]
 * @property {PlaybackSessionState} [playbackSessionState]
 */
/**
 * @type {TSessionStateArgs}
 */
class Props {
    constructor() {
        this.content = this.playbackSessionState = undefined;
    }
}

class SessionState extends Props {
    /**
     * @param {TSessionStateArgs} args
     */
    constructor(args = {}) {
        super();

        Utils.extend(this, {
            content: {},
            playbackSessionState: {}
        }, args);
    }
}

module.exports = SessionState;