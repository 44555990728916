export type TClientName =
    |  'web'
    |  'iptvstb'
    |  'aistb'
    |  'tvos'
    |  'androidtv'
    |  'androidstb'
    |  'androidmob'
    |  'ios'
    |  'ccr'
    |  'sstv'
    |  'lgtv';

export class DeviceInfo {
    public clientName: TClientName;
    public deviceId: string;
    public deviceModel: string;
    public deviceVendor?: string;

    constructor(args: DeviceInfo) {
        this.clientName = 'web';

        if (args) {
            this.clientName = args.clientName;
            this.deviceId = args.deviceId;
            this.deviceModel = args.deviceModel;
            this.deviceVendor = args.deviceVendor;
        }
    }
};
