import { ChannelMetadata, Icons, ProgramMedia, TimeRange} from '../Types';
import { GraphChannel } from './GraphTypes';

export const ChannelMetadataMapper = (response: { data: { channel: GraphChannel } }, timeRange?: TimeRange): ChannelMetadata => {
    if (!(response && response.data && response.data.channel)) {
        throw new Error('MetadataService::ChannelMetadataMapper: Missing data or channel!');
    }

    const graphChannel = response.data.channel;
    const icons: Icons = {};
    if (graphChannel.icons) {
        if (graphChannel.icons.light) {
            if (graphChannel.icons.light.sourceNonEncoded) {
                icons.light = graphChannel.icons.light.sourceNonEncoded;
            }
            else if (graphChannel.icons.light.source) {
                icons.light = decodeURIComponent(graphChannel.icons.light.source);
            }
        }
        if (graphChannel.icons.dark) {
            if (graphChannel.icons.dark.sourceNonEncoded) {
                icons.dark = graphChannel.icons.dark.sourceNonEncoded;
            }
            else if (graphChannel.icons.dark.source) {
                icons.dark = decodeURIComponent(graphChannel.icons.dark.source);
            }
        }
    }

    const channelMetadata: ChannelMetadata = {
        icons,
        id: graphChannel.id,
        name: graphChannel.name,
        playbackSpec: graphChannel.playbackSpec,
        programs: []
    };

    if (graphChannel.programs && graphChannel.programs.programItems) {
        for (let i in graphChannel.programs.programItems) {
            if (!graphChannel.programs.programItems.hasOwnProperty(i)) continue;

            const programItem = graphChannel.programs.programItems[i];

            const programMedia: ProgramMedia = {
                id: programItem.media.id,
                title: programItem.media.title,
                description: programItem.media.descriptionLong,
                images: {}
            };

            if (programItem.media.series && programItem.media.series.title) {
                programMedia.seriesTitle = programItem.media.series.title;
            }

            if (programItem.media.seasonNumber) {
                programMedia.season = programItem.media.seasonNumber.number;
            }

            if (programItem.media.episodeNumber) {
                programMedia.episode = programItem.media.episodeNumber.number;
            }

            const graphImages: any = programItem.media.images;
            for (let key in graphImages) {
                if (!graphImages.hasOwnProperty(key) || !graphImages[key]) continue;

                if (graphImages[key].source) {
                    //@ts-ignore
                    programMedia.images[key] = decodeURIComponent(graphImages[key].source);
                }
                else if (graphImages[key].sourceNonEncoded) {
                    //@ts-ignore
                    programMedia.images[key] = graphImages[key].sourceNonEncoded;
                }
            }

            //trimming programItems...
            if (timeRange && programItem.startTime.timestamp > timeRange.end) continue;

            channelMetadata.programs.push({
                id: programItem.id,
                startTime: programItem.startTime.timestamp,
                endTime: programItem.endTime.timestamp,
                live: programItem.live,
                rerun: programItem.rerun,
                startoverPlaybackSpec: programItem.media?.playback?.play?.linear?.item?.startover?.playbackSpec,
                media: programMedia
            });
        }
    }

    return channelMetadata;
};
