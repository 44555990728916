const EventEmitter = require('eventemitter3');
const InterceptorFactory = require('./InterceptorFactory');
const Engine = require('../player/Engine');
const Utils = require('../utils/Utils');

const { ErrorOrigins } = require('../Constants');
const { MediatorEvents } = require('../Events');

const { CommunicationManager } = require('../managers/');
const { ReceiverConfig } = require('../dtos/');

module.exports = class Mediator extends EventEmitter {
    /**
     * @typedef {object} MediatorArgs
     * @param {ReceiverConfig} config
     * @param {Engine} engine
     */
    /**
     * @param {MediatorArgs} args
     */
    constructor(args) {
        super();
        const { communicationManager, config, engine } = args;

        /** @type {CommunicationManager} */
        this.communicationManager = communicationManager;
        /** @type {ReceiverConfig} */
        this.config = config;
        /** @type {Engine} */
        this.engine = engine;

        this.context = cast.framework.CastReceiverContext.getInstance();
        this.playerManager = this.context.getPlayerManager();

        this.eventHandlers = {};
        Object.values(MediatorEvents).forEach(e => this.eventHandlers[e] = []);

        this.intercepts = {};
        this.interceptorFactory = new InterceptorFactory();
        this.interceptorFactory.registerPlayerManager(this.playerManager);

        this.ready = false;
        this.resolveStartPromise = null;

        this.context.setLoggerLevel(cast.framework.LoggerLevel.ERROR);

        if (this.config.features.senderDisconnectedDestroy) {
            this.context.setLastSenderDisconnectedHandler(() => {
            });
        }

        this.registerEventHandlers();
        this.registerInterceptors();

        this.interceptShaka();
    }

    /** @private */
    registerEventHandlers() {
        this.context.addEventListener(cast.framework.system.EventType.READY, () => {
            if (!this.ready) {
                this.ready = true;
                this.communicationManager.ready = this.ready;
                this.resolveStartPromise();
            }
        });
        this.context.addEventListener(cast.framework.system.EventType.ALLOW_GROUP_CHANGE, () => {
        });
        this.context.addEventListener(cast.framework.system.EventType.ERROR, e => {
            e = Utils.extend(e || {}, {
                fatal: false,
                origin: ErrorOrigins.Caf.Context
            });

            this.eventHandlers[MediatorEvents.Error].forEach(handler => {
                try {
                    handler(e);
                } catch (e) {
                }
            });
        });
        this.context.addEventListener(cast.framework.system.EventType.FEEDBACK_STARTED, () => {
        });
        this.context.addEventListener(cast.framework.system.EventType.GROUP_CAPABILITIES, () => {
        });
        this.context.addEventListener(cast.framework.system.EventType.MAX_VIDEO_RESOLUTION_CHANGED, () => {
        });
        this.context.addEventListener(cast.framework.system.EventType.PROXIMITY_CHANGED, () => {
        });
        this.context.addEventListener(cast.framework.system.EventType.SENDER_CONNECTED, e => {
            this.eventHandlers[MediatorEvents.SenderConnected].forEach(handler => {
                try {
                    handler(e || {});
                } catch (e) {
                }
            });
        });
        this.context.addEventListener(cast.framework.system.EventType.SENDER_DISCONNECTED, e => {
            this.eventHandlers[MediatorEvents.SenderDisconnected].forEach(handler => {
                try {
                    handler(e || {});
                } catch (e) {
                }
            });
        });
        this.context.addEventListener(cast.framework.system.EventType.SHUTDOWN, () => {
            this.eventHandlers[MediatorEvents.Shutdown].forEach(handler => {
                try {
                    handler();
                } catch (e) {
                }
            });
        });
        this.context.addEventListener(cast.framework.system.EventType.STANDBY_CHANGED, () => {
        });
        this.context.addEventListener(cast.framework.system.EventType.SYSTEM_VOLUME_CHANGED,
          e => this.engine.onSystemVolumeChanged(e.data.level, e.data.muted));
        this.context.addEventListener(cast.framework.system.EventType.VISIBILITY_CHANGED, () => {
        });
    }

    /** @private */
    registerInterceptors() {
        const interceptable = [
            {
                key: cast.framework.messages.MessageType.PRELOAD
            },
            {
                event: cast.framework.events.EventType.REQUEST_LOAD_BY_ENTITY,
                key: cast.framework.messages.MessageType.LOAD_BY_ENTITY
            },
            {
                event: cast.framework.events.EventType.REQUEST_LOAD,
                key: cast.framework.messages.MessageType.LOAD
            },
            {
                key: cast.framework.messages.MessageType.MEDIA_STATUS
            },
            {
                event: cast.framework.events.EventType.REQUEST_EDIT_AUDIO_TRACKS,
                key: cast.framework.messages.MessageType.EDIT_AUDIO_TRACKS
            },
            {
                event: cast.framework.events.EventType.REQUEST_EDIT_TRACKS_INFO,
                key: cast.framework.messages.MessageType.EDIT_TRACKS_INFO
            },
            {
                event: cast.framework.events.EventType.REQUEST_PLAY,
                key: cast.framework.messages.MessageType.PLAY
            },
            {
                event: cast.framework.events.EventType.REQUEST_PAUSE,
                key: cast.framework.messages.MessageType.PAUSE
            },
            {
                event: cast.framework.events.EventType.REQUEST_SEEK,
                key: cast.framework.messages.MessageType.SEEK
            },
            {
                event: cast.framework.events.EventType.REQUEST_STOP,
                key: cast.framework.messages.MessageType.STOP
            }
        ];

        for (let i in interceptable) {
            if (!interceptable.hasOwnProperty(i)) continue;
            const interceptor = this.interceptorFactory.createInterceptor(interceptable[i]);
            this.intercepts[interceptor.key] = interceptor;
        }
    }

    /** @private */
    interceptShaka() {
        this.shakaInterceptPromise = new Promise((resolve, reject) => {
            const interceptStart = Date.now();
            let interval = setInterval(() => {
                const timeSinceInterceptStart = Date.now() - interceptStart;
                if (60000 <= timeSinceInterceptStart) { // Failed to intercept shaka
                    interval = clearInterval(interval);
                    return reject();
                }

                if (!('shaka' in window)) return; // Try again next time

                if (interval) {
                    interval = clearInterval(interval);
                }

                const self = this;
                const originalShakaConstructor = shaka.Player;
                shaka.Player = function (a, b, c) {
                    return self.engine
                      .getPlayerFactory(originalShakaConstructor)
                      .create(a, b, c);
                };
                Object.keys(originalShakaConstructor)
                  .forEach(k => {
                      if (Utils.isFunction(originalShakaConstructor[k])) { // Add call-through functions for static shaka functions
                          return shaka.Player[k] = (...args) => originalShakaConstructor[k](...args);
                      }

                      shaka.Player[k] = originalShakaConstructor[k];
                  });

                resolve();
            }, 100);
        });
    }

    /** @private */
    manifestHandler(manifest) {
        let modifiedManifest = null;
        try {
            modifiedManifest = this.engine.onManifest(manifest);
        } catch (e) {
        }

        if (modifiedManifest && modifiedManifest.length) {
            return modifiedManifest;
        }

        return manifest;
    }

    /** @private */
    segmentHandler(segment) {
        let modifiedSegment = null;
        try {
            modifiedSegment = this.engine.onSegment(segment);
        } catch (e) {
        }

        if (modifiedSegment && modifiedSegment.byteLength) {
            return modifiedSegment;
        }

        return segment;
    }

    awaitShakaIntercept() {
        return this.shakaInterceptPromise;
    }

    /**
     * @param {string} interceptorKey
     * @param {function} action
     * @param {boolean} interrupt
     * @returns {void | Number}
     */
    use(interceptorKey, action, interrupt = true) {
        if (Object.values(MediatorEvents).includes(interceptorKey)) {
            return this.eventHandlers[interceptorKey].push(action);
        }

        if (!this.intercepts[interceptorKey]) {
            throw new Error(`Mediator::use: No interceptor with a name of ${interceptorKey}!`);
        }
        if (!Utils.isFunction(action)) {
            throw new Error('Mediator::use: action must be a function!');
        }
        if (!Utils.isBoolean(interrupt)) {
            throw new Error(`Mediator::use: interrupt must be a boolean!`);
        }

        this.intercepts[interceptorKey].addMiddleware(action, interrupt);
    }

    start() {
        return new Promise(resolve => {
            this.resolveStartPromise = () => resolve();

            const customNamespaces = {};
            this.communicationManager
              .getCommunicationChannels()
              .forEach(channel => customNamespaces[channel] = cast.framework.system.MessageType.JSON);

            this.context.start(
              Object.assign(
                new cast.framework.CastReceiverOptions(), {
                    //touchScreenOptimizedApp: true,
                    //skipPlayersLoad: false,
                    //mplConfig: {},
                    //mplUrl: null,
                    //shakaUrl: null,
                    //shakaUrl: 'https://ajax.googleapis.com/ajax/libs/shaka-player/3.0.11/shaka-player.compiled.js',
                    //shakaUrl: 'https://ajax.googleapis.com/ajax/libs/shaka-player/3.0.11/shaka-player.compiled.debug.js',
                    //disableSourceBufferTimeAdjust: false,
                    //loadMux: false,
                    customNamespaces,
                    disableIdleTimeout: this.config.debug,
                    playbackConfig: Object.assign(new cast.framework.PlaybackConfig(), this.playerManager.getPlaybackConfig(), {
                        manifestHandler: this.manifestHandler.bind(this),
                        segmentHandler: this.segmentHandler.bind(this)
                        //shakaConfig: {}
                    }),
                    shakaUrl: this.config.shakaUrl
                }
              )
            );

            this.communicationManager.ready = this.ready;

            if (this.ready) return this.resolveStartPromise();
            setTimeout(() => {
                if (!this.ready) {
                    this.ready = true;
                    this.communicationManager.ready = this.ready;
                    this.resolveStartPromise();
                }
            }, 3000);
        });
    }

    destroy() {
        this.eventHandlers = {};
        Object.values(MediatorEvents).forEach(e => this.eventHandlers[e] = []);
        this.context.stop();
    }
};