const Utils = require('../utils/Utils');

/**
 * @typedef {object} TAdvertisementArgs
 * @property {string} id
 * @property {number} duration
 * @property {string} name
 * @property {string} [title]
 */
/**
 * @type {TAdvertisementArgs}
 */
class Props {
    constructor() {
        this.id = this.duration = this.name = this.title = undefined;
    }
}

class Advertisement extends Props {
    /**
     * @param {TAdvertisementArgs} args
     */
    constructor(args = {}) {
        super();

        Utils.extend(this, {}, args);
    }
}

module.exports = Advertisement;