const {
    EngineEvents
} = require('../../Events');

const Utils = require('../../utils/Utils');
const IOverlay = require('../IOverlay');

module.exports = class DebugOverlay extends IOverlay {
    constructor({ config, container, engine }) {
        super();

        this.config = config;
        this.container = container;
        this.engine = engine;

        this.applicationData = {
            application: {
                applicationVersion: 'Unknown'
            }, receiver: {
                receiverVersion: 'Unknown'
            }
        };

        this.sourceBufferLimitBytes = -1;
        Utils.getSourceBufferLimitBytes().then(sourceBufferLimitBytes => {
            this.sourceBufferLimitBytes = sourceBufferLimitBytes;
        });

        this.updateTimer = null;
    }

    static get Name() {
        return 'Debug';
    }

    initialize() {
        const debugWrapper = document.createElement('div');
        debugWrapper.className = 'debug-wrapper';

        this.debugContent = document.createElement('div');
        this.debugContent.className = 'debug-content';
        debugWrapper.appendChild(this.debugContent);

        this.container.appendChild(debugWrapper);

    }

    hide() {
        Utils.removeClass(this.container, 'visible');
        this.killUpdateTimer();
    }

    pause() {
    }

    play() {
    }

    setApplicationData(applicationData) {
        if (applicationData) {
            this.applicationData = applicationData;
        }
    }

    setDebugContent() {
        const {
            features: {
                addOffTextTrack, dush, notifications, playbackManager: {
                    dynamicBufferAdjustment
                }, senderDisconnectedDestroy, shakaWacka, timelineManager: {
                    useEPGRestrictions
                }, ui: {
                    adCounter
                }, validationEvents, wacka
            }
        } = this.config;

        this.debugContent.innerHTML = `
          applicationVersion: ${this.applicationData.application.applicationVersion} <br />
          receiverVersion: ${this.applicationData.receiver.receiverVersion} <br />
          features: { <br />
            &nbsp;&nbsp;addOffTextTrack: ${addOffTextTrack} <br />
            &nbsp;&nbsp;dush: ${dush} <br />
            &nbsp;&nbsp;notifications: ${notifications} <br />
            &nbsp;&nbsp;playbackManager.dynamicBufferAdjustment: ${dynamicBufferAdjustment} <br />
            &nbsp;&nbsp;senderDisconnectedDestroy: ${senderDisconnectedDestroy} <br />
            &nbsp;&nbsp;shakaWacka: ${shakaWacka} <br />
            &nbsp;&nbsp;timelineManager.useEPGRestrictions: ${useEPGRestrictions} <br />
            &nbsp;&nbsp;ui.adCounter: ${adCounter} <br />
            &nbsp;&nbsp;validationEvents: ${validationEvents} <br />
            &nbsp;&nbsp;wacka: ${wacka} <br />
          } <br />
          screen: { <br />
            &nbsp;&nbsp;height: ${window.screen.availHeight} <br />
            &nbsp;&nbsp;width: ${window.screen.availWidth} <br />
          } <br />
          sourceBufferSize: ${(this.sourceBufferLimitBytes / 1024 / 1024).toFixed(2)} MB`;

        if (this.engine.stream) {
            const stream = this.engine.stream
            const urlParts = (stream.playbackSessionUrl || stream.url).split('?')
            this.debugContent.innerHTML += ` <br />
          stream: { <br />
            &nbsp;&nbsp;url: ${urlParts[0]} <br />
            &nbsp;&nbsp;audioCodec: ${this.engine.getVariantTracks().map(track => `${track.audioCodec}, channels:${track.channelsCount}`)[0] || 'unknown'} <br />
            &nbsp;&nbsp;contentType: ${stream.contentType} <br />
            &nbsp;&nbsp;streamType: ${stream.streamType} <br />
            &nbsp;&nbsp;encrypted: ${!!(this.engine.license && this.engine.license.server)} <br />
            &nbsp;&nbsp;urlParameter: ${urlParts[1] || 'no-parameters'} <br />
          } <br />
        `;
        }
    }

    show() {
        this.updateTimer = setInterval(e => {
            this.setDebugContent()
        }, 2000);
        Utils.addClass(this.container, 'visible');
    }

    killUpdateTimer() {
        if (this.updateTimer) {
            clearInterval(this.updateTimer);
            this.updateTimer = null;
        }
    }

    reset() {
        this.stream = null;
        this.setDebugContent(true);
    }

    destroy() {
        this.killUpdateTimer();

        if (this.debugContent) {
            this.debugContent.innerHTML = '';
        }

        if (this.container) {
            Utils.removeClass(this.container, 'visible');
            this.container.innerHTML = '';
        }
    }
};