import { Content, Metadata, Image } from '@tv4/unified-receiver';

export class NextContent {
  public static async GetFK(contentId: string, graphqlEndpoint: string, accessToken?: string): Promise<Content | undefined> {
    const query = `{
    "query": "{
        videoAsset(id:\\"${contentId}\\") {
          nextContent {
            relatedClips(limit:5) {
              id
              image
              title
              description
              tags
              duration
            }
          }
        }
      }"
  }`
      .replace(/\s{2,}/g, ' ')
      .trim();

    const headers: any = {
      'Content-Type': 'application/json',
      'client-name': 'tv4play-chromecast',
    }

    if(accessToken) {
      headers['Authorization'] = `Bearer ${accessToken}`
    }

    let response: any = await fetch(graphqlEndpoint, {
      method: 'POST',
      headers,
      body: query,
    });
    response = await response.json();

    if (response?.data?.videoAsset?.nextContent?.relatedClips?.length) {
      let relatedClips = response.data.videoAsset.nextContent.relatedClips;
      if (relatedClips.length > 0) {
        for (let i = 0; i < relatedClips.length; i++) {
          if (relatedClips[i].tags.indexOf('fotboll') > -1) {
            const newItem = response.data.videoAsset.nextContent.relatedClips[i];

            const metadata: Metadata = new Metadata();
            metadata.title = newItem.title || '';
            metadata.description = newItem.description || '';

            if (newItem.image) {
              metadata.images.push(new Image({ url: newItem.image }));
            }

            return new Content({
              contentId: newItem.id,
              metadata: metadata,
              streams: [],
            });
          }
        }
      }
    }
  }

  public static async GetTv4Play(
    contentId: string,
    isClip: boolean,
    graphqlEndpoint: string,
    accessToken?: string
  ): Promise<Content | undefined> {
    const query = {
      query: `
      query nextContent($episodeId: ID!) {
        episode(id: $episodeId) {
          nextContent {
            autoplay {
              playableMedia {
                ... on Episode {
                  id
                  title
                  images {
                    main16x9 {
                      sourceEncoded
                    }
                  }
                  seriesLink {
                    title
                  }
                  upsell {
                    tierId
                  }
                }
              }
            }
          }
        }
      }`,
      variables: {
        episodeId: contentId,
      },
    };

    const headers: any = {
      'Content-Type': 'application/json',
      'client-name': 'avod-chromecast',
      //@ts-ignore
      'client-version': __VERSION__,
    }

    if (accessToken) {
      headers['Authorization'] = `Bearer ${accessToken}`
    }

    const response = await fetch(graphqlEndpoint, {
      method: 'POST',
      headers,
      body: JSON.stringify(query),
    }).then((res) => res.json());
    
    const {
      data: {
        episode: {
          nextContent: {
            autoplay: { playableMedia: nextEpisode },
          },
        },
      },
    } = response;

    if (nextEpisode && !nextEpisode.upsell) {
      const {
        seriesLink: { title: seriesTitle },
      } = nextEpisode;

      const metadata: Metadata = new Metadata();
      metadata.title = nextEpisode.title || '';
      if (nextEpisode.season || nextEpisode.episode || seriesTitle) {
        metadata.series = {
          title: seriesTitle,
          episode: nextEpisode.episode,
          season: nextEpisode.season,
        };
      }

      const {
        main16x9: { sourceEncoded: nextEpisodeImageSource },
      } = nextEpisode.images;

      if (nextEpisodeImageSource) {
        metadata.images.push(new Image({ url: nextEpisodeImageSource }));
      }

      return new Content({
        accessControl: 'SUBSCRIPTION',
        auxiliaryData: undefined,
        contentId: nextEpisode.id,
        contentType: 'MEDIA',
        metadata: metadata,
        regionalChannels: undefined,
        serviceCountry: 'se',
        streamRestrictions: undefined,
        streams: [],
        watchMode: 'ONDEMAND',
      });
    }
  }
}
