const Utils = require('../utils/Utils');

/**
 * @typedef {object} TStreamRestrictionsArgs
 * @property {boolean} [pause=false] if true, pausing will be prohibited
 * @property {boolean} [seek=false] if true, seeking will be prohibited
 */
/**
 * @type {TStreamRestrictionsArgs}
 */
class Props {
    constructor() {
        this.pause = this.seek = undefined;
    }
}

class StreamRestrictions extends Props {
    /**
     * @param {TStreamRestrictionsArgs} args
     */
    constructor(args = {}) {
        super();

        Utils.extend(this, { pause: false, seek: false }, args);
    }
}

module.exports = StreamRestrictions;