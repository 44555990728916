import { ClientServices, EnvTypes, ServiceTypes } from './Types';
import merge from 'deepmerge';
import { ReceiverConfig } from '@tv4/unified-receiver';
// |--------------------------|
// | Default
// |--------------------------|
const DefaultConfig = {
  dependencies: ['./lib/hls-1.4.10.min.js'],
  receiver: {
    debug: false,
    features: {
      addOffTextTrack: true,
      engine: {
        preferShakaHlsTs: false,
        preferShakaHlsCmaf: false,
      },
      loadCastFramework: true,
      playbackManager: {
        dynamicBufferAdjustment: false,
      },
      shakaWacka: true,
      ui: {
        adCounter: false,
        injectDefaultConfigStyling: false,
      },
      wacka: true,
    },
    messageNamespace: 'urn:x-cast:application.messaging',
    shakaUrl: 'https://ajax.googleapis.com/ajax/libs/shaka-player/3.2.11/shaka-player.compiled.js',
  },
  serviceLayer: {
    debug: true,
    backend: {
      imageResizeUrlPattern: 'https://imageproxy.a2d.tv/?source={urlEncodedImageUrl}&width={width}',
      playbackApiEndpoint: 'https://playback2.a2d.tv/',
      graphqlEndpoint: 'https://client-gateway.tv4.a2d.tv/graphql',
    },
    features: {
      freewheelEnabled: true,
      freewheelTestEnv: false,
      requireAuthentication: true,
      yospaceLive: true,
      yospaceVod: true,
    },
    service: {},
    tracking: {
      segmentsUrl: 'https://tv4-segments.a2d.tv/segments',
      wireUrl: 'https://tv4.video-tracking.a2d.tv/events',
      wireAnonymousUrl: 'https://tv4.video-tracking.a2d.tv/anonymous/event',
      youboraAccount: 'mtvoy',
    },
  },
};
// |--------------------------|
// | Environment
// |--------------------------|
const EnvironmentConfig = {
  development: {
    receiver: {
      debug: false,
      features: {
        debugTracking: false,
        debugConsole: {
          debug: false,
          log: false,
          info: false,
          warn: false,
          error: false,
        },
      },
    },
    serviceLayer: {
      tracking: {
        //youboraAccount: 'mtvoydev', /* this should be used, but I have no access to the dev account and can therefore not test anything*/
      },
    },
  },
  production: {},
};

// |--------------------------|
// |  Service
// |--------------------------|
const ServiceConfigs = {
  fotbollskanalen: {
    serviceLayer: {
      service: {
        serviceBrand: 'Fotbollskanalen',
        serviceCountry: 'se',
        serviceId: ClientServices.FK,
        serviceName: 'Fotbollskanalen',
      },
    },
  },
  tv4play: {
    serviceLayer: {
      service: {
        serviceBrand: 'TV4',
        serviceCountry: 'se',
        serviceId: ClientServices.TV4PLAY,
        serviceName: 'TV4 Play',
      },
    },
  },
  tv4se: {
    serviceLayer: {
      service: {
        serviceBrand: 'TV4',
        serviceCountry: 'se',
        serviceId: ClientServices.TV4SE,
        serviceName: 'TV4 Nyheterna',
      },
      features: {
        requireAuthentication: false,
      },
    },
  },
  tv4: {
    serviceLayer: {
      service: {
        serviceBrand: 'TV4',
        serviceCountry: 'se',
        serviceId: ClientServices.TV4,
        serviceName: 'TV4',
      },
      features: {
        requireAuthentication: false,
      },
    },
  },
  koket: {
    serviceLayer: {
      service: {
        serviceBrand: 'Köket',
        serviceCountry: 'se',
        serviceId: ClientServices.KOKET,
        serviceName: 'Köket',
      },
    },
  },
  mtv: {
    serviceLayer: {
      backend: {
        graphqlEndpoint: 'https://client-gateway.mtv.a2d.tv/graphql',
      },
      service: {
        serviceBrand: 'MTV',
        serviceCountry: 'fi',
        serviceId: ClientServices.MTV,
        serviceName: 'MTV',
      },
      tracking: {
        wireUrl: 'https://mtv.video-tracking.a2d.tv/events',
        wireAnonymousUrl: 'https://mtv.video-tracking.a2d.tv/anonymous/event',
      },
    },
  },
};

export const GetConfig = (env: EnvTypes, service: ServiceTypes): ReceiverConfig => {
  return merge(merge(DefaultConfig, EnvironmentConfig[env]), ServiceConfigs[service]);
};
