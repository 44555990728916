const Utils = require('../../utils/Utils');

module.exports = class QueueManager {
    constructor() {
        this.castPlayerManager = cast.framework.CastReceiverContext
          .getInstance()
          .getPlayerManager();
        this.castQueueManager = this.castPlayerManager.getQueueManager();

        // To ensure backwards compatibility
        this.contentManager = null;
    }

    addToQueue(items) {
        if (!Array.isArray(items)) {
            items = [items];
        }

        for (let i in items) {
            if (!items.hasOwnProperty(i)) continue;

            if (!(items[i] instanceof cast.framework.messages.QueueItem)) {
                console.warn('Error QueueManager::addToQueue: items added to the QueueManager must be of type cast.framework.messages.QueueItem!');
                continue;
            }

            const allItems = this.castQueueManager.getItems();
            const currentItemIndex = this.castQueueManager.getCurrentItemIndex();
            const nextItemIndex = currentItemIndex + 1;
            let insertBefore = undefined;
            if (currentItemIndex >= 0 && currentItemIndex < allItems.length - 1) {
                insertBefore = allItems[nextItemIndex].itemId;
            }

            this.castQueueManager.insertItems([items[i]], insertBefore);
        }
    }

    clearNextContent() {
        if (this.hasNextContentInQueue()) {
            const items = this.castQueueManager.getItems();
            const itemsIdsToRemove = [];
            for (let i in items) {
                if (!items.hasOwnProperty(i) || !items[i] || !items[i].customData || !items[i].customData.content) continue;

                if (items[i].customData.content.nextContent) {
                    itemsIdsToRemove.push(items[i].itemId);
                }
            }

            if (itemsIdsToRemove.length) {
                this.castQueueManager.removeItems(itemsIdsToRemove);
            }
        }
    }

    getNextContent() {
        if (this.hasNextContentInQueue()) {
            const items = this.castQueueManager.getItems();
            const currentIndex = this.castQueueManager.getCurrentItemIndex();

            return items[currentIndex + 1] || null;
        }

        return null;
    }

    hasNextContentInQueue() {
        const items = this.castQueueManager.getItems();
        const currentIndex = this.castQueueManager.getCurrentItemIndex();

        return (currentIndex + 1) < items.length;
    }

    playNextContent() {
        const nextContent = this.getNextContent();
        if (nextContent) {
            nextContent.media.contentId = nextContent.media.entity; // To avoid Caf legacy validation bug
            nextContent.type = 'LOAD';
            nextContent.requestId = Utils.randomInteger();
            receiver.loadingByEntity = true;
            this.castPlayerManager.load(nextContent);
        }
    }

    removeNextContent() {
        if (this.hasNextContentInQueue()) {
            const items = this.castQueueManager.getItems();
            const currentIndex = this.castQueueManager.getCurrentItemIndex();
            const itemId = (items[currentIndex + 1] || {}).itemId;
            if (itemId) {
                this.castQueueManager.removeItems([itemId]);
            }
        }
    }

    // All metadata related calls have been moved to ContentManager
    getContainerMetadata() {
        console.warn('QueueManager::getContainerMetadata: Method is deprecated please use ContentManager::getContainerMetadata instead!');
        return this.contentManager.getContainerMetadata();
    }

    updateContainerMetadata(type, title, metadata) {
        console.warn('QueueManager::updateContainerMetadata: Method is deprecated please use ContentManager::updateContainerMetadata instead!');
        this.contentManager.updateContainerMetadata(type, title, metadata);
    }

    resetContainerMetadata() {
        console.warn('QueueManager::resetContainerMetadata: Method is deprecated please use ContentManager::resetContainerMetadata instead!');
        this.contentManager.resetContainerMetadata();
    }

    updateCurrentItem() {
        console.warn('QueueManager::updateCurrentItem: Method is deprecated please use ContentManager::updateCurrentItem instead!');
        this.contentManager.updateCurrentItem();
    }

    reset() {
    }

    destroy() {
        this.castPlayerManager = null;
        this.castQueueManager = null;
        this.contentManager = null;
    }
};