//

//
//  ComscoreEventParser.js
//
//  Created by Lars Rothaus on 30/03/2022.
//  Copyright © 30/03/2022 Lars Rothaus. All rights reserved.
//

class ComscoreEventParser {
    static Parse(url, all = false) {
        const labels = {
            'ns_st_po': 'playheadPosition',
            'ns_st_ev': 'event',
            'ns_ts': 'timeStamp',
            'ns_st_cl': 'ns_st_cl',
            'ns_st_el': 'ns_st_el'
        }
        const params = url.split('?')[1];
        const keyValues = params.split('&');
        let output = {}
        const objects = keyValues.map(value => {
            let kv = value.split('=');
            if (all) {
                output[kv[0]] = `${kv[1]};`
            } else if (labels[kv[0]]) {
                output[labels[kv[0]]] = `${kv[1]};`
            }

        })
        return output;
    }

}

module.exports = ComscoreEventParser;