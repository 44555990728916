export const CommunicationManagerEvents = {
    ApplicationMessage: 'CommunicationManager.ApplicationMessage',
    UnifiedMessage: 'CommunicationManager.UnifiedMessage'
};

export const ContentManagerEvents = {
    ContentMetadataUpdated: 'ContentManagerEvents.ContentMetadataUpdated'
};

export const DushEvents = {
    BitratesReady: 'DushEvents.BitratesReady',
    Error: 'DushEvents.Error',
    Scte35: 'DushEvents.Scte35'
};

export const EngineEvents = {
    AdvertisementEnded: 'EngineEvents.AdvertisementEnded',
    AdvertisementStarted: 'EngineEvents.AdvertisementStarted',
    AdvertisementBreakEnded: 'EngineEvents.AdvertisementBreakEnded',
    AdvertisementBreakStarted: 'EngineEvents.AdvertisementBreakStarted',
    AdvertisementTimeUpdate: 'EngineEvents.AdvertisementTimeUpdate',
    BitrateChanged: 'EngineEvents.BitrateChanged',
    Buffered: 'EngineEvents.Buffered',
    Buffering: 'EngineEvents.Buffering',
    CdnChanged: 'EngineEvents.CdnChanged',
    DrmChanged: 'EngineEvents.DrmChanged',
    DroppedFrames: 'EngineEvents.DroppedFrames',
    LoadStart: 'EngineEvents.LoadStart',
    LoadedData: 'EngineEvents.LoadedData',
    LoadedMetadata: 'EngineEvents.LoadedMetadata',
    Pause: 'EngineEvents.Pause',
    Play: 'EngineEvents.Play',
    SeekableRangeUpdated: 'EngineEvents.SeekableRangeUpdated',
    Seeked: 'EngineEvents.Seeked',
    Seeking: 'EngineEvents.Seeking',
    StreamChanged: 'EngineEvents.StreamChanged',
    StreamCue: 'EngineEvents.StreamCue',
    StreamFinished: 'EngineEvents.StreamFinished',
    SystemVolumeChanged: 'EngineEvents.SystemVolumeChanged',
    TimeUpdate: 'EngineEvents.TimeUpdate',
    // The TTMLImageSubtitleUpdated is only used to re-engage the TTML player which has a tendency to stop working after seeking!
    // This should be removed the the second the image based subs are duplicated
    TTMLImageSubtitleUpdated: 'EngineEvents.TTMLImageSubtitleUpdated'
};

export const FetchEvents = {
    CdnDiscovered: 'FetchEvents.CdnDiscovered'
};

export const MediatorEvents = {
    Error: 'Mediator.Error',
    SenderConnected: 'Mediator.SenderConnected',
    SenderDisconnected: 'Mediator.SenderDisconnected',
    Shutdown: 'Mediator.Shutdown'
};

export const NetworkEvents = {
    CdnDiscovered: 'NetworkEvents.CdnDiscovered'
};

export const NotificationEvents = {
    ShowNotification: 'Notifications.ShowNotification',
    ClearNotification: 'Notifications.ClearNotification'
};

export const ReceiverEvents = {
    Error: 'ReceiverEvents.Error',
    PauseRequest: 'ReceiverEvents.PauseRequest',
    PlayRequest: 'ReceiverEvents.PlayRequest',
    SeekRequest: 'ReceiverEvents.SeekRequest',
    StopRequest: 'ReceiverEvents.StopRequest'
};

export const ShakaWackaEvents = {
    Error: 'ShakaWackaEvents.Error'
};

export const TimelineManagerEvents = {
    SeekableRangeUpdated: 'TimelineManagerEvents.SeekableRangeUpdated',
    TimeUpdate: 'TimelineManagerEvents.TimeUpdate'
};

export const TrackManagerEvents = {
    AudioTrackChanged: 'TrackManagerEvents.AudioTrackChanged',
    TextTrackChanged: 'TrackManagerEvents.TextTrackChanged'
};

export const WackaEvents = {
    BitratesReady: 'WackaEvents.BitratesReady',
    Error: 'WackaEvents.Error',
    Id3: 'WackaEvents.Id3'
};

export const XhrEvents = {
    CdnDiscovered: 'XhrEvents.CdnDiscovered'
};