import { StandardError, StandardErrorArgs } from './StandardError';
import { ErrorCategories } from '../Constants';
import { NetworkError } from './NetworkError';
import { TvClientGatewayErrorMap } from './constants';
import { getCodeFromNetworkError } from './utils';

export type TTvClientGatewayErrorArgs = Omit<StandardErrorArgs, 'category' | 'code'> & {
    originalError: NetworkError;
};

export class TvClientGatewayError extends StandardError {
    constructor(args: TTvClientGatewayErrorArgs) {
        super({
            ...args,
            category: ErrorCategories.TV_CLIENT_GATEWAY,
            code: getCodeFromNetworkError({
                error: args.originalError,
                errorMap: TvClientGatewayErrorMap,
            }),
        });
    }
};