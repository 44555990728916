const LanguageCodesMap = {
    'ar': ['ar', 'ara'],
    'bg': ['bg', 'bul'],
    'cs': ['cs', 'ces', 'cze'],
    'da': ['da', 'dk', 'dan', 'den'],
    'de': ['de', 'deu', 'ger'],
    'el': ['el', 'ell', 'gre'],
    'en': ['en', 'eng'],
    'es': ['es', 'spa'],
    'et': ['et', 'est'],
    'fa': ['fa', 'fas', 'per'],
    'fi': ['fi', 'fin'],
    'fr': ['fr', 'fre', 'fra'],
    'he': ['he', 'heb'],
    'hi': ['hi', 'hin'],
    'hr': ['hr', 'hrv', 'scr'],
    'hu': ['hu', 'hun', 'ohu'],
    'is': ['is', 'ice', 'isl'],
    'it': ['it', 'ita'],
    'ja': ['ja', 'jpn'],
    'lt': ['lt', 'lit'],
    'lv': ['lv', 'lav', 'lvs', 'ltg'],
    'mk': ['mk', 'mac', 'mkd'],
    'nl': ['nl', 'dut', 'nld'],
    'nb': ['nb', 'nob'],
    'no': ['no', 'nn', 'nor', 'nno'],
    'off': ['off'],
    'pl': ['pl', 'pol'],
    'pt': ['pt', 'por', 'prt'],
    'qad': ['qad'],
    'qag': ['qag'],
    'qaf': ['qaf'],
    'qaa': ['qaa'],
    'qai': ['qai'],
    'qc': ['qc', 'nol'],
    'qd': ['qd', 'qbb'],
    'ro': ['ro', 'ron', 'rum'],
    'ru': ['ru', 'rus'],
    'sk': ['sk', 'slo'],
    'sl': ['sl', 'slv'],
    'sq': ['sq', 'alb', 'sqi'],
    'sr': ['sr', 'scc', 'srp'],
    'sv': ['sv', 'se', 'sve', 'swe'],
    'tr': ['tr', 'tur'],
    'uk': ['uk', 'ukr'],
    'zh': ['zh', 'chi', 'cmn', 'zho'],
    'zu': ['zu', 'zul']
};

const LanguageCodeToLabelMap = {
    lt: {
        off: 'Be subtitrų',
        nb: 'Norvegų',
        no: 'Norvegų',
        sv: 'Švedų',
        da: 'Danų',
        fi: 'Suomių',
        en: 'Anglų',
        et: 'Estų',
        lt: 'Lietuvių',
        lv: 'Latvių',
        is: 'Islandų',
        hu: 'Vengrų',
        fr: 'Prancūzų',
        de: 'Vokiečių',
        cs: 'Čekų',
        ru: 'Rusų',
        pt: 'Portugalų',
        es: 'Ispanų',
        nl: 'Olandų',
        qd: 'Garsinis aprašas',
        qc: 'Originalo',
        zu: 'Garsinis aprašas',
        sq: 'Albanų',
        ar: 'Arabų',
        bg: 'Bulgarų',
        zh: 'Kinų',
        el: 'Graikų',
        fa: 'Persų',
        he: 'Hebrajų',
        hi: 'Hindi',
        hr: 'Kroatų',
        it: 'Italų',
        ja: 'Japonų',
        mk: 'Makedoniečių',
        pl: 'Lenkų',
        ro: 'Rumunų',
        sr: 'Serbų',
        sk: 'Slovakų',
        sl: 'Slovėnų',
        tr: 'Turkų',
        uk: 'Ukrainiečių',
        qad: 'Garsinis aprašas',
        qag: '---',
        qaf: '---',
        qaa: '---',
        qai: '---'
    },
    sv: {
        off: 'Inga undertexter',
        nb: 'Norska',
        no: 'Norska',
        sv: 'Svenska',
        da: 'Danska',
        fi: 'Finska',
        en: 'Engelska',
        et: 'Estniska',
        lt: 'Litauiska',
        lv: 'Lettiska',
        is: 'Isländska',
        hu: 'Ungerska',
        fr: 'Franska',
        de: 'Tyska',
        cs: 'Tjeckiska',
        ru: 'Ryska',
        pt: 'Portugisiska',
        es: 'Spanska',
        nl: 'Holländska',
        qd: 'Arenaljud',
        sq: 'Albanska',
        ar: 'Arabiska',
        bg: 'Bulgariska',
        zh: 'Kinesiska',
        el: 'Grekiska',
        fa: 'Persiska',
        he: 'Hebreiska',
        hi: 'Hindi',
        hr: 'Kroatiska',
        it: 'Italienska',
        ja: 'Japanska',
        mk: 'Makedonska',
        pl: 'Polska',
        ro: 'Rumänska',
        sr: 'Serbiska',
        sk: 'Slovakiska',
        sl: 'Slovenska',
        tr: 'Turkiska',
        uk: 'Ukrainska',
        qad: 'Arenaljud',
        qag: 'Svenska [CC]',
        qaf: 'Uppläst undertext',
        qaa: 'Utan kommentator',
        qai: 'Svenska (AI-genererad)'
    },
    da: {
        off: 'Ingen undertekster',
        nb: 'Norsk',
        no: 'Norsk',
        sv: 'Svensk',
        da: 'Dansk',
        fi: 'Finsk',
        en: 'Engelsk',
        lt: 'Litauisk',
        lv: 'Lettisk',
        is: 'Islandsk',
        hu: 'Ungarsk',
        fr: 'Fransk',
        de: 'Tysk',
        cs: 'Tjekkisk',
        ru: 'Russisk',
        pt: 'Portugisisk',
        es: 'Spansk',
        nl: 'Hollandske',
        qd: 'Arena lyd',
        qad: 'Arena lyd',
        qag: '---',
        qaf: '---',
        qaa: '---',
        qai: '---'
    },
    fi: {
        off: 'Ei tekstitystä',
        nb: 'Norja',
        no: 'Norja',
        sv: 'Ruotsi',
        da: 'Tanska',
        fi: 'Suomi',
        en: 'Englanti',
        lt: 'Liettua',
        lv: 'Latvia',
        is: 'Islanti',
        hu: 'Unkari',
        fr: 'Ranska',
        de: 'Saksa',
        cs: 'Tšekki',
        ru: 'Venäjä',
        pt: 'Portugali',
        es: 'Espanja',
        nl: 'Hollanti',
        qd: 'Vain halliäänet',
        qad: 'Vain halliäänet',
        qag: 'Ohjelmatekstitys',
        qaf: 'Äänitekstitys',
        qaa: 'Ilman selostusta',
        qai: '---'
    },
    en: {
        off: 'No subtitles',
        nb: 'Norwegian',
        no: 'Norwegian',
        sv: 'Swedish',
        da: 'Danish',
        fi: 'Finnish',
        en: 'English',
        et: 'Estonian',
        lt: 'Lithuanian',
        lv: 'Latvian',
        is: 'Icelandic',
        hu: 'Hungarian',
        fr: 'French',
        de: 'German',
        cs: 'Czech',
        ru: 'Russian',
        pt: 'Portuguese',
        es: 'Spanish',
        nl: 'Dutch',
        qad: 'Arena sound',
        qc: 'Original',
        qd: 'Arena sound',
        zu: 'Descriptive audio',
        qag: '---',
        qaf: '---',
        qaa: '---',
        qai: '---'
    },
    no: {
        off: 'Ingen undertekster',
        nb: 'Norsk',
        no: 'Norsk',
        sv: 'Svensk',
        da: 'Dansk',
        fi: 'Finsk',
        en: 'Engelsk',
        lt: 'Litauisk',
        lv: 'Latvisk',
        is: 'Islandsk',
        hu: 'Ungarsk',
        fr: 'Fransk',
        de: 'Tysk',
        cs: 'Czech',
        ru: 'Russisk',
        pt: 'Portugisisk',
        es: 'Spansk',
        nl: 'Nederlandsk',
        qd: 'Arena lyd',
        qad: 'Arena lyd',
        qag: '---',
        qaf: '---',
        qaa: '---',
        qai: '---'
    }
};

module.exports = new class LanguageMapper {
    constructor() {
        this.codeKeys = Object.keys(LanguageCodesMap);
        this.labelKeys = Object.keys(LanguageCodeToLabelMap);
    }

    hasLanguageGroup(market) {
        return this.labelKeys.includes(market);
    }

    /**
     *
     * @param {string} languageGroup
     * @param {string} language
     * @returns {*|undefined}
     */
    mapLabel(languageGroup, language) {
        const languageNames = LanguageCodeToLabelMap[languageGroup];
        if (!languageNames || !language) {
            return;
        }
        const sanitizedLangCode = this.codeKeys.find(key => (key === language) || LanguageCodesMap[key].includes(language));
        return languageNames[sanitizedLangCode] || undefined;
    }

    /**
     * Map 3 letter code to 2 letter code, example dan -> da. Returns false if not found
     * @param {String} language language code to be mapped
     * @return {String|Boolean} language code
     */
    mapISOCodeFromAlpha3to2(language) {
        let alpha2Code = false;
        Object.entries(LanguageCodesMap).forEach(([key, alpha3Codes]) => {
            if (alpha3Codes.indexOf && alpha3Codes.indexOf(language) !== -1) {
                alpha2Code = key;
            }
        });

        return alpha2Code;
    }

    /**
     * Map an array of ISO 639-1 codes to defined codeMapping (ISO 639-1/2) codes
     * @param {Array} languageArray Array of ISO 623-1 codes
     */
    mapISOCodesFromAlpha2to3(languageArray) {
        return languageArray.reduce((result, language) => {
            try {
                const langsIso3 = LanguageCodesMap[language].filter(language => language.length === 3);
                return result.concat(langsIso3);
            } catch (err) {
                return result;
            }
        }, []);
    }

    mapISOCodeFromAlpha2or3to2(language) {
        const res = this.mapISOCodeFromAlpha3to2(language);
        return (res || language);
    }
};