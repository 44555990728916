import { PlaybackEventBase } from './PlaybackEventBaseClass';
import { PlaybackEventTypes } from "../Constants";

type TVolumeChangedEventPayload = {
    muted: boolean;
    volume: number;
}

export class VolumeChangedEvent extends PlaybackEventBase<PlaybackEventTypes.VOLUME_CHANGED, TVolumeChangedEventPayload> {
    constructor(payload: TVolumeChangedEventPayload) {
        super(PlaybackEventTypes.VOLUME_CHANGED, payload);
    }
}

