//
//  WackaRestrictionHelper.js
//
//  Created by Lars Rothaus on 01/06/2021.
//  Copyright © 01/06/2021 Lars Rothaus. All rights reserved.
//

const { RestrictionHelper, Level } = require('./RestrictionHelper');

class WackaRestrictionHelper extends RestrictionHelper {

    constructor() {
        super();

    }

    _setMaxLevel(level, restriction) {
        if (level && typeof level.index === 'number') {
            this.player.autoLevelCapping = level.index;
        } else {
            this.player.autoLevelCapping = -1;
        }

        console.log(`-------= setting max restriction =-------`);
        console.log(`capping level:`, this.player.autoLevelCapping);
    }

    getLevels() {
        let convertedLevels = [];
        if (this.player && this.player.levels) {
            let levels = this.player.levels;

            for (let i in levels) {
                convertedLevels.push(new Level({
                    index: parseInt(i),
                    width: parseInt(levels[i].width),
                    height: parseInt(levels[i].height),
                    bitrate: parseInt(levels[i].bitrate),
                    bandwidth: Math.floor((parseInt(levels[i].bitrate) / 8) / 1024),
                    fps: parseInt(levels[i].attrs['FRAME-RATE'] || -1)
                }));
            }
        }
        return convertedLevels;
    }
}

module.exports = WackaRestrictionHelper;