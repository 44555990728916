

export enum AdvertisementBreakTypes {
    MIDROLL = 'MIDROLL',
    POSTROLL = 'POSTROLL',
    PREROLL = 'PREROLL'
};

export type TYospaceSeekPositionData = {
    seekPosition: number;
    seekPositionAfterBreakEnd?: number;
};


export type TYospaceTimedDataObject = {
    time: number;
    tag: Record<string, string>;
};

type Id3Tag = {
    key: string;
    data: Uint8Array;
};

export type Id3Cue = {
    startTime: number;
    tags: Array<Id3Tag>;
};
export enum StreamCueTypes {
    EMSG = "EMSG",
    ID3 = "ID3",
    ID3_IN_EMSG = "ID3_IN_EMSG",
    SCTE35 = "SCTE35",
    UNKNOWN = "UNKNOWN"
}
export type StreamCue = {
    type: StreamCueTypes;
    parsed: any;
    raw: Uint8Array;
    endTime?: number;
    eventDuration?: number;
    id?: number;
    presentationTimeDelta?: number;
    schemeIdUri?: string;
    startTime?: number;
    timescale?: number;
    timestamp?: number;
    value?: any;
};
