import Bowser from 'bowser';

export class BrowserInfo {
    public browserName: string;
    public browserVersion: string;

    constructor(args?: BrowserInfo) {
        if (!args) {
            args = {
                browserName: undefined,
                browserVersion: undefined
            };
        }

        const browser = Bowser.getParser(window.navigator.userAgent).getBrowser();
        this.browserName = args.browserName || browser.name;
        this.browserVersion = args.browserVersion || browser.version;
    }
};