import { AdvertisementBreakTypes } from './Types'
import { guid } from './YospaceUtils'

export class Advertisement {
    public readonly type = 'Advertisement';
    public readonly id: string;
    public readonly name: string;
    public readonly durationInSeconds: number;
    public readonly breakType: AdvertisementBreakTypes;
    public readonly adNumberInBreak: number;
    public readonly sponsor: boolean;
    public campaignId?: string;
    public customId?: string;
    public goalId?: string;
    public positionInAdBreak?: number;
    public totalAdsInAdBreak?: number;

    constructor(args: Partial<Advertisement>) {
        this.id = args.id ?? guid();
        this.name = args.name ?? 'Unknown';
        this.durationInSeconds = args.durationInSeconds ?? 0;
        this.breakType = args.breakType ?? AdvertisementBreakTypes.MIDROLL;
        this.adNumberInBreak = args.adNumberInBreak ?? 1;
        this.sponsor = args.sponsor ?? false;
        this.campaignId = args.campaignId;
        this.customId = args.customId;
        this.goalId = args.goalId;
        this.positionInAdBreak = args.positionInAdBreak;
        this.totalAdsInAdBreak = args.totalAdsInAdBreak;
    }
}

export class AdvertisementBreak {
    public readonly type = 'AdvertisementBreak';
    public id: string;
    public name: string;
    public active: boolean;
    public advertisements: Array<Advertisement>;
    public breakType: AdvertisementBreakTypes;
    public durationInSeconds: number;
    public duration: number
    public embedded: boolean;
    public position: number;
    public watched: boolean;


    constructor(advertisementBreak: Partial<AdvertisementBreak>) {
        this.id = advertisementBreak?.id ?? guid();
        this.name = advertisementBreak?.name ?? 'Unknown';
        this.active = advertisementBreak?.active ?? false;
        this.advertisements = advertisementBreak?.advertisements ?? [];
        this.breakType = advertisementBreak?.breakType ?? AdvertisementBreakTypes.MIDROLL; // Defaults to MidRoll as that is the most common type of break
        this.durationInSeconds = advertisementBreak?.durationInSeconds ?? 0;
        this.duration = advertisementBreak?.durationInSeconds ?? 0;
        this.embedded = advertisementBreak?.embedded ?? false;
        this.position = advertisementBreak?.position ?? 0;
        this.watched = advertisementBreak?.watched ?? false;
    }

    public getEndPosition(): number {
        return this.position + this.durationInSeconds + END_MARGIN;
    }

    public isPositionInAdvertisementBreak(position: number): boolean {
        return (
            position >= this.position &&
            position + END_MARGIN < this.position + this.durationInSeconds
        );
    }

    public isPositionPastAdvertisementBreak(position: number): boolean {
        return position + END_MARGIN >= this.position + this.durationInSeconds;
    }
};

/*
  Sometimes the Yospace SDK position values and video position values differ by
  a few ms, eg. video 28.485 vs SDK 28.48500003, this can cause
  invalid behavior in `isPositionInAdvertisementBreak()` and `isPositionPastAdvertisementBreak()`
  To avoid this we add a margin to the position when comparing position to AdvertisementBreak end.

  We also add the margin to `getEndPosition()` as the Yospace SDK values can be inaccurate causing the end position
  to be a bit smaller than the actual end position which can cause problems when converting between timestamps
  with and without ads.
*/
const END_MARGIN = 0.5;






