export const Playback = `            
  linear {
    item {
      startTime {
        timestamp
      }
      endTime {
        timestamp
      }
      playbackSpec {
        videoId
        videoIdType
        watchMode
        accessControl
      }
      startover {
        playbackSpec {
          videoId
          videoIdType
          watchMode
          accessControl
        }
      }
      channel {
        playbackSpec {
          videoId
          videoIdType
          watchMode
          accessControl
        }
      }
    }
  }
  subscription {
    item {
      validFrom {
        timestamp
      }
      playbackSpec {
        videoId
        videoIdType
        watchMode
        accessControl
      }
    }
  }
  rental {
    item {
      validFrom {
        timestamp
      }
      playbackSpec {
        videoId
        videoIdType
        watchMode
        accessControl
      }
    }
  }
`
export const Images = `
  showcard2x3 { sourceNonEncoded }
  showcard16x9 { sourceNonEncoded }
  backdrop16x9 { sourceNonEncoded }
  screenshot16x9 { sourceNonEncoded }
`

export const SimpleSeries = `  
  series {
    id
    numberOfEpisodes {
      number
    }
    numberOfSeasons {
      number
    }
    title
  }
`
export const ExtendedSeries = `
  series {
    id
    title
    numberOfSeasons {number}
    numberOfEpisodes {number}
    seasons(sort: { order: ASC}) {
      seasonItems {
        id
        seasonNumber {
          number
          readable
        }
        episodes(sort: { order: ASC}) {
          episodeItems {
            id
            episodeNumber {
              number,
              readable
            }
            userData {
              progress {
                percent
                position
              }
            }
            duration {
              readableShort
            }
            images {
              screenshot16x9 {
                source
                sourceNonEncoded
              }
              backdrop16x9 {
                source
                sourceNonEncoded
              }
            }
            playback {
              play {
                ${Playback}
              }
            }
          }
        }
      }
    }
  }
`
