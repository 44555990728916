import { ErrorCategories as UnifiedErrorCategories } from '@tv4/unified-receiver';
import { ErrorCategories } from '../Constants';

export const ErrorCategoriesMapper = (errorCategory?: UnifiedErrorCategories | ErrorCategories): ErrorCategories => {
    if (!errorCategory) return ErrorCategories.DEFAULT;

    switch (errorCategory) {
        case UnifiedErrorCategories.Media: {
            return ErrorCategories.MEDIA;
        }
        case UnifiedErrorCategories.Network: {
            return ErrorCategories.NETWORK;
        }
        case UnifiedErrorCategories.Drm: {
            return ErrorCategories.DRM;
        }
        case UnifiedErrorCategories.Advertisement: {
            return ErrorCategories.ADS;
        }
        case UnifiedErrorCategories.Default: {
            return ErrorCategories.DEFAULT;
        }
    }

    return errorCategory as ErrorCategories;
};
