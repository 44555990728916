import {BaseComponent} from './BaseComponent'
import {StandardError} from "../errors/StandardError";
import {ErrorCategories, ErrorSeverities} from "../Constants";
import {Logger as LoggerSingleton} from '../logger/Logger'

const Logger = LoggerSingleton.createLoggerContext('BaseManager');

export interface IBaseManager {
    initialize(configuration?: any): void;

    reset(): Promise<void>;

    destroy(): Promise<void>;
};

export class BaseManager implements IBaseManager {
    private components: Record<string, BaseComponent>;
    readonly name: string;

    constructor(managerName: string) {
        this.name = managerName;
        this.components = {};
    }

    // |--------------------------|
    // | Handlers
    // |--------------------------|

    public async executeOnComponents(functionName: string, data?: any, fireAndForget: boolean = false): Promise<void> {
        let pendingResolution = [];
        for (let t in this.components) {
            if (!this.components.hasOwnProperty(t)) continue;
            try {
                if (typeof this.components[t][functionName] === 'function') {
                    const promise = this.components[t][functionName](data);
                    if (promise && promise instanceof Promise) {
                        //pendingResolution.push(TimeoutPromise<void | Error>(promise, 5));
                        pendingResolution.push(promise);
                    }
                }
            } catch (e) {
                Logger.error(`ManagerError in ${this.name}: Error calling: '${functionName}' on component with name: '${t}'`, e);
            }
        }

        if (fireAndForget) {
            return;
        }

        return new Promise<void>((resolve, reject) => {
            Promise.all(pendingResolution).then(valuesOrErrors => {
                let errors = valuesOrErrors.map(value => {
                    if (value instanceof Error) {
                        return value;
                    }
                });

                resolve();
            })
        });
    }

    public getComponentWithName(name: string): BaseComponent | null {
        return this.components[name] || null;
    }

    public registerComponent(component: BaseComponent) {
        if (this.components[component.name]) {
            return Logger.error(`BaseManager::registerComponent: component with name: ${component.name} is already registered!`);
        }

        component.throwError = this.throwError;

        this.components[component.name] = component;
    }

    public async unregisterComponent(name, destroyBeforeUnregister: boolean = true): Promise<void> {
        let final: Promise<void> = Promise.resolve();
        if (!this.components[name]) {
            Logger.warn(`Warning class:${this.name}[unregisterComponent] : No component with name: ${name}!`);
            return final;
        }

        if (destroyBeforeUnregister) {
            final = this.components[name].destroy();
        }

        delete this.components[name];

        return final;
    }

    public async unregisterAllComponents(destroyBeforeUnregister: boolean = true): Promise<void[]> {
        let final: Promise<void[]> = Promise.resolve([]);

        if (destroyBeforeUnregister) {
            final = Promise.all(Object.values(this.components).map(c => c.destroy()));
        }

        this.components = {};

        return final;
    }

    protected throwError(error: Error | StandardError): StandardError {
        /**
         * Some form of minimal normalisation
         */

        //todo re-enable this
        // if ((error instanceof StandardError) === false) {
        //     error = new StandardError({
        //         category: ErrorCategories.DEFAULT,
        //         code: `${this.name}:Generic:UnknownError`,
        //         severity: ErrorSeverities.CRITICAL,
        //         details: error
        //     });
        // }

        throw error;
    }

    // |--------------------------|
    // |  IBaseManager
    // |--------------------------|

    public initialize(configuration?: any) {

    }

    public async reset(): Promise<void> {
        return this.executeOnComponents('reset');
    }

    public async destroy(): Promise<void> {
        return this.executeOnComponents('destroy');
    }
};

// const TimeoutPromise = <T>(origPromise: Promise<T>, delayInSeconds: number): Promise<T> => {
//     // let timeout = new Promise<T>((resolve, reject) => {
//     //     let id = setTimeout(() => {
//     //         clearTimeout(id);
//     //         reject('Promise rejected due to timeout')
//     //     }, delayInSeconds * 1000);
//     // })
//     return new Promise<T>((resolve) => {
//         let timeout = setTimeout(()=>{
//             resolve(null);
//         }, delayInSeconds * 1000)
//         origPromise.then(e => {
//             clearTimeout(timeout);
//             resolve(e)
//
//         }).catch(error => {
//             resolve(error)
//         })
//     });
//
//     //return Promise.race<T>([origPromise, timeout])
// };
