module.exports = class FetchExtension {
    constructor(fetchInterceptor) {
        this.fetchInterceptor = fetchInterceptor;

        this.fetch = window.fetch;
    }

    interceptor(request) {
        const fetchPromise = this.fetch.apply(window, arguments);

        fetchPromise.then(response => {
            if (response.headers && response.headers.has('x-cdn-forward')) {
                const cdn = response.headers.get('x-cdn-forward');
                if (cdn) {
                    this.fetchInterceptor.cdnDiscovered(cdn);
                }
            }
        }).catch(e => {
        });

        return fetchPromise;
    }
};