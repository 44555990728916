const Utils = require('../utils/Utils');

/**
 * @typedef {object} TLoadAdvertisementArgs
 * @property {string} [advertisingId]
 */
/**
 * @type {TLoadAdvertisementArgs}
 */
class Props {
    constructor() {
        this.advertisingId = undefined;
    }
}

class LoadAdvertisement extends Props {
    /**
     * @param {TLoadAdvertisementArgs} args
     */
    constructor(args = {}) {
        super();

        Utils.extend(this, {}, args);
    }
}

module.exports = LoadAdvertisement;