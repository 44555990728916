(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@tv4/one-playback-sdk-shared"));
	else if(typeof define === 'function' && define.amd)
		define("OnePlaybackSdk", ["@tv4/one-playback-sdk-shared"], factory);
	else if(typeof exports === 'object')
		exports["OnePlaybackSdk"] = factory(require("@tv4/one-playback-sdk-shared"));
	else
		root["OnePlaybackSdk"] = factory(root["@tv4/one-playback-sdk-shared"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__9323__) {
return 