module.exports = class TtmlParser {
    constructor(preserveBrBreaksInTtmlTextContent = false) {
        this.preserveBrBreaksInTtmlTextContent = preserveBrBreaksInTtmlTextContent;
        this.textParser = document.createElement('div');
        this.xmlParser = new DOMParser();
    }

    timestampToSeconds(timestamp) {
        const parts = timestamp.split(':');
        const h = parseInt(parts[0]) * 60 * 60;
        const m = parseInt(parts[1]) * 60;
        const s = parseFloat(parts[2]);

        return h + m + s;
    };

    parse(ttml, offset) {
        const ttmlDocument = this.xmlParser.parseFromString(ttml, 'application/xml');
        const cues = [];
        const divs = ttmlDocument.querySelectorAll('div');
        const images = ttmlDocument.querySelectorAll('image');
        const paragraphs = ttmlDocument.querySelectorAll('p');

        let isImageSubtitles = false;
        if (divs.length && images.length) {
            const mappedImages = [];
            for (let i = 0; i < images.length; i++) {
                const imageId = images[i].getAttribute('xml:id');

                if (!imageId) continue;

                images[i].id = imageId;
                mappedImages.push(images[i]);
            }

            for (let d = 0; d < divs.length; d++) {
                const imageId = divs[d].getAttribute('smpte:backgroundImage');
                const beginText = divs[d].getAttribute('begin');
                const endText = divs[d].getAttribute('end');

                if (!imageId || !beginText || !mappedImages.length) continue;

                isImageSubtitles = true;
                const image = mappedImages.find(i => imageId === `#${i.id}`);

                if (!image || !image.textContent) continue;

                const begin = this.timestampToSeconds(beginText) + offset;
                const end = (endText ? this.timestampToSeconds(endText) : begin + 3) + offset;
                const imageType = (image.getAttribute('imagetype') || 'png').toLowerCase();
                const encoding = (image.getAttribute('encoding') || 'base64').toLowerCase();

                cues.push(new VTTCue(begin, end, `data:image/${imageType};${encoding},` + image.textContent.trim()));
            }
        }

        if (paragraphs.length) {
            for (let p = 0; p < paragraphs.length; p++) {
                const beginText = paragraphs[p].getAttribute('begin');
                const endText = paragraphs[p].getAttribute('end');
                let text = paragraphs[p].textContent;

                if (this.preserveBrBreaksInTtmlTextContent) { // Try parsing to preserve line breaks but fall back to just use textContent if that fails
                    try {
                        this.textParser.innerHTML = paragraphs[p].outerHTML;
                        text = this.textParser.firstChild.innerHTML.replace(/<br>/g, '\n');
                    } catch (e) {
                    }

                    this.textParser.innerHTML = '';
                }

                if (!beginText || !text) continue;

                const begin = this.timestampToSeconds(beginText) + offset;
                const end = (endText ? this.timestampToSeconds(endText) : begin + 3) + offset;

                // remove extra whitespace seen on for example MTV, History Channel
                text = text.replace(/  +/g, ' ');
                cues.push(new VTTCue(begin, end, text.trim()));
            }
        }

        return cues;
    }
};