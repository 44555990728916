import { AdvertisementBreakTypes } from "../Constants";

export class AdvertisementInfo {
  readonly id: string;
  readonly name: string;
  readonly durationInSeconds: number;
  readonly breakType: AdvertisementBreakTypes;
  readonly campaignId?: string;
  readonly customId?: string;
  readonly goalId?: string;
  readonly sponsor: boolean;
  readonly positionInAdBreak?: number;
  readonly totalAdsInAdBreak?: number;

  constructor(args: AdvertisementInfo) {
    Object.entries(args).forEach((arg) => {
      this[arg[0]] = arg[1];
    })
  }
}
