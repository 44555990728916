module.exports = class IOverlay {
    constructor() {
    }

    static get Name() {
        return 'IOverlay';
    }

    initialize() {
    }

    hide() {
    }

    pause() {
    }

    play() {
    }

    show() {
    }

    reset() {
    }

    destroy() {
    }
};