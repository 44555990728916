import { PlaybackSpec } from "@tv4/one-playback-sdk-shared";

export interface IQuery {
    generateBody(queryVariables:any):string;
};

export type ChannelsMetadata = {
    id: string;
    name: string;
    icons: Icons;
    playback: any;
    programs: Array<Program>;
}

export type ChannelMetadata = {
    id: string;
    name: string;
    icons: Icons;
    playbackSpec: PlaybackSpec;
    startoverPlaybackSpec?: PlaybackSpec;
    programs: Array<Program>;
};

export type MediaMetadata = {
    type: MediaTypes;
    id: string;
    description: string;
    images: Images;
    title: string;
    series?: Series,
    nextContent?: NextContent;
    startoverPlaybackSpec?: PlaybackSpec;
    channelPlaybackSpec?: PlaybackSpec;
    linearPlaybackSpec?: PlaybackSpec;
    startTime?: number;
    endTime?: number;
};

export type Images = {
    showcard2x3?: string;
    showcard16x9?: string;
    backdrop16x9?: string;
    backdrop4x3?: string;
    screenshot16x9?: string;
};

export type NextContent = {
    validFrom: number;
    playbackSpec: PlaybackSpec;
    metadata?: MediaMetadata;
    nextContent?: PlaybackSpec;
    seriesTitle?: string;
    episode: number;
    season: number;
};

export type EpisodeDetails = {
    episode: number;
    season: number;
};

export enum MediaTypes {
    Episode = 'Episode',
    Movie = 'Movie',
    Channel = 'Channel',
    SportEvent = 'SportEvent'
};

export type Seasons = {};

export type Series = {
    id: string;
    title: string;
    episode: number;
    season: number;
    seasons?: any;
};

export type TimeRange = {
    start: number | Date,
    end: number | Date
}

export type Icons = {
    light?: string;
    dark?: string;
};

export type Program = {
    id: string;
    startTime: number;
    endTime: number;
    live: boolean;
    rerun: boolean;
    startoverPlaybackSpec?: PlaybackSpec;
    media: ProgramMedia;
};

export type ProgramMedia = {
    id: string;
    title: string;
    description: string;
    seriesTitle?: string;
    season?: number;
    episode?: number;
    images: Images;
};
