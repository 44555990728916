import { UserInfo } from "../../dtos/UserInfo";

export class BonnierSdkSimpleUser {

    private token:string
    private user:UserInfo;

    constructor(token:string, userInfo:UserInfo) {
        this.token = token;
        this.user = userInfo;
    }

    public getUser():UserInfo{
        return this.user;
    }

    public getBonnierAccountToken(): string{
        return this.token;
    }

    public getId(): string{
        return this.user.userId
    }

    public isLoggedIn(): boolean {
        return this.user.userIsLoggedIn
    }
}
