const Utils = require('../utils/Utils');

module.exports = class AdvertisementBreakMapper {
    constructor() {
    }

    static mapToGoogleDtos(request, advertisementBreaks) {
        request.media.breakClips = request.media.breakClips || [];
        request.media.breaks = request.media.breaks || [];

        advertisementBreaks.forEach(advertisementBreak => {
            const mappedBreakClips = (advertisementBreak.advertisements || [])
              .map(advertisement => Utils.extend(new cast.framework.messages.BreakClip(advertisement.id), {
                  duration: advertisement.duration,
                  title: advertisement.title
              }));
            request.media.breakClips.push(...mappedBreakClips);

            request.media.breaks.push(Utils.extend(
              new cast.framework.messages.Break(advertisementBreak.id, mappedBreakClips.map(bc => bc.id), advertisementBreak.position), {
                  duration: advertisementBreak.duration,
                  isEmbedded: advertisementBreak.embedded,
                  isWatched: advertisementBreak.watched
              }
            ));
        });

        return request;
    }
};