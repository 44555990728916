const Utils = require('../utils/Utils');
const { SenderTypes } = require('../Constants');

const guessSenderType = (id = '', userAgent = '') => {
    const lowerCaseUserAgent = userAgent.toLowerCase();
    if (['android'].every(part => lowerCaseUserAgent.includes(part))) return SenderTypes.Android;
    if (['ios'].every(part => lowerCaseUserAgent.includes(part))) return SenderTypes.iOS;
    if (['mozilla/', 'chrome/'].every(part => lowerCaseUserAgent.includes(part))) return SenderTypes.Web;

    return SenderTypes.Unknown;
};

// Util for parsing sender id. Though unused, kept for future reference
const parseSenderId = (id = '') => {
    const idParts = id.split(':');
    if (idParts.length !== 2) return null; // Unable to parse

    const receiverParts = idParts[0].split('.');
    if (receiverParts.length !== 2) return null; // Unable to parse

    const receiverDeviceId = receiverParts[0];
    const maybeSocketId = receiverParts[1];
    const senderId = idParts[1];

    console.log('Sender::parseSenderId:', { receiverDeviceId, maybeSocketId, senderId });
};

/**
 * @typedef {object} TSenderClientInformation
 * @property {string} appVersion
 * @property {string} playerVersion
 */
/**
 * @typedef {object} TSenderReceiverInformation
 * @property {string} receiverVersion
 */

/**
 * @typedef {object} TSenderArgs
 * @property {TSenderClientInformation} [client]
 * @property {number} [connectedAt=+new Date()]
 * @property {string} id
 * @property {TSenderReceiverInformation} receiver
 * @property {string} senderVersion
 * @property {object} services
 * @property {string} type
 * @property {string} [userAgent=null]
 */
/**
 * @type {TSenderArgs}
 */
class Props {
    constructor() {
        this.client = this.id = this.receiver = this.senderVersion = this.services = this.type = undefined;
    }
}

class Sender extends Props {
    /**
     * @param {TSenderArgs} args
     */
    constructor(args = {}) {
        super();

        Utils.extend(this, {
            client: {},
            connectedAt: +new Date(),
            id: null,
            receiver: {},
            senderVersion: null,
            services: {},
            type: null,
            userAgent: null
        }, args);

        if (!this.id) {
            console.warn('Sender::constructor: Sender created without an id!');
        }
    }
}

module.exports = Sender;