const defaultLocalStorageNamespace = 'OnePlaybackSdk';

const namespacedGetDataStorage = (localStorageNamespace: string, storageKey: string): Record<string, any> | null => {
    const localStorageKey = `${localStorageNamespace}.${storageKey}`;
    const localStorageData = localStorage.getItem(localStorageKey);

    if (localStorageData) {
        try {
            return JSON.parse(localStorageData);
        } catch (e) {}
    }

    return null;
};

const namespacedGetData = (localStorageNamespace: string, storageKey: string, key: string): Record<string, any> | null => {
    const dataStorage = namespacedGetDataStorage(localStorageNamespace, storageKey);

    return (dataStorage && dataStorage[key]) ?? null;
};

const namespacedStoreData = (localStorageNamespace: string, storageKey: string, key: string, data: Record<string, any> | null): void => {
    const localStorageKey = `${localStorageNamespace}.${storageKey}`;
    const dataStorage = namespacedGetDataStorage(localStorageNamespace, storageKey) ?? {};
    dataStorage[key] = data;
    try {
        localStorage.setItem(localStorageKey, JSON.stringify(dataStorage));
    } catch (e) {}
};

const namespacedGetBrandData = (localStorageNamespace: string, storageKey: string, brand: string, key: string): Record<string, any> | null => {
    const brandDataStorage = namespacedGetData(localStorageNamespace, storageKey, brand);

    return (brandDataStorage && brandDataStorage[key]) ?? null;
};

const namespacedStoreBrandData = (localStorageNamespace: string, storageKey: string, brand: string, key: string, data: Record<string, any> | null): void => {
    const localStorageKey = `${localStorageNamespace}.${storageKey}`;
    const dataStorage = namespacedGetDataStorage(localStorageNamespace, storageKey) ?? {};
    dataStorage[brand] = dataStorage[brand] ?? {};
    dataStorage[brand][key] = data;
    try {
        localStorage.setItem(localStorageKey, JSON.stringify(dataStorage));
    } catch (e) {}
};

const namespacedClearDeviceData = (localStorageNamespace: string, deviceId: string, key: string): void => namespacedStoreDeviceData(localStorageNamespace, deviceId, key, null);
const namespacedGetDeviceData = (localStorageNamespace: string, deviceId: string, key: string): Record<string, any> | null => namespacedGetData(localStorageNamespace, deviceId, key);
const namespacedStoreDeviceData = (localStorageNamespace: string, deviceId: string, key: string, data: Record<string, any> | null): void => namespacedStoreData(localStorageNamespace, deviceId, key, data);

const namespacedClearDeviceBrandData = (localStorageNamespace: string, deviceId: string, brand: string, key: string): void => namespacedStoreDeviceBrandData(localStorageNamespace, deviceId, brand, key, null);
const namespacedGetDeviceBrandData = (localStorageNamespace: string, deviceId: string, brand: string, key: string): Record<string, any> | null => namespacedGetBrandData(localStorageNamespace, deviceId, brand, key);
const namespacedStoreDeviceBrandData = (localStorageNamespace: string, deviceId: string, brand: string, key: string, data: Record<string, any> | null): void => namespacedStoreBrandData(localStorageNamespace, deviceId, brand, key, data);


const namespacedClearUserData = (localStorageNamespace: string, userId: string, key: string): void => namespacedStoreUserData(localStorageNamespace, userId, key, null);
const namespacedGetUserData = (localStorageNamespace: string, userId: string, key: string): Record<string, any> | null => namespacedGetData(localStorageNamespace, userId, key);
const namespacedStoreUserData = (localStorageNamespace: string, userId: string, key: string, data: Record<string, any> | null): void => namespacedStoreData(localStorageNamespace, userId, key, data);

const namespacedClearUserBrandData = (localStorageNamespace: string, userId: string, brand: string, key: string): void => namespacedStoreUserBrandData(localStorageNamespace, userId, brand, key, null);
const namespacedGetUserBrandData = (localStorageNamespace: string, userId: string, brand: string, key: string): Record<string, any> | null => namespacedGetBrandData(localStorageNamespace, userId, brand, key);
const namespacedStoreUserBrandData = (localStorageNamespace: string, userId: string, brand: string, key: string, data: Record<string, any> | null): void => namespacedStoreBrandData(localStorageNamespace, userId, brand, key, data);


export const clearDeviceData = (deviceId: string, key: string): void => namespacedClearDeviceData(defaultLocalStorageNamespace, deviceId, key);
export const getDeviceData = (deviceId: string, key: string): Record<string, any> | null => namespacedGetDeviceData(defaultLocalStorageNamespace, deviceId, key);
export const storeDeviceData = (deviceId: string, key: string, data: Record<string, any> | null): void => namespacedStoreDeviceData(defaultLocalStorageNamespace, deviceId, key, data);

export const clearDeviceBrandData = (deviceId: string, brand: string, key: string): void => namespacedClearDeviceBrandData(defaultLocalStorageNamespace, deviceId, brand, key);
export const getDeviceBrandData = (deviceId: string, brand: string, key: string): Record<string, any> | null => namespacedGetDeviceBrandData(defaultLocalStorageNamespace, deviceId, brand, key);
export const storeDeviceBrandData = (deviceId: string, brand: string, key: string, data: Record<string, any> | null): void => namespacedStoreDeviceBrandData(defaultLocalStorageNamespace, deviceId, brand, key, data);


export const clearUserData = (userId: string, key: string): void => namespacedClearUserData(defaultLocalStorageNamespace, userId, key);
export const getUserData = (userId: string, key: string): Record<string, any> | null => namespacedGetUserData(defaultLocalStorageNamespace, userId, key);
export const storeUserData = (userId: string, key: string, data: Record<string, any> | null): void => namespacedStoreUserData(defaultLocalStorageNamespace, userId, key, data);

export const clearUserBrandData = (userId: string, brand: string, key: string): void => namespacedClearUserBrandData(defaultLocalStorageNamespace, userId, brand, key);
export const getUserBrandData = (userId: string, brand: string, key: string): Record<string, any> | null => namespacedGetUserBrandData(defaultLocalStorageNamespace, userId, brand, key);
export const storeUserBrandData = (userId: string, brand: string, key: string, data: Record<string, any> | null): void => namespacedStoreUserBrandData(defaultLocalStorageNamespace, userId, brand, key, data);


export const createNamespacedLocalStorage = (localStorageNamespace: string) => ({
    clearDeviceData: (deviceId: string, key: string): void => namespacedClearDeviceData(localStorageNamespace, deviceId, key),
    getDeviceData: (deviceId: string, key: string): Record<string, any> | null => namespacedGetDeviceData(localStorageNamespace, deviceId, key),
    storeDeviceData: (deviceId: string, key: string, data: Record<string, any> | null): void => namespacedStoreDeviceData(localStorageNamespace, deviceId, key, data),
    
    clearDeviceBrandData: (deviceId: string, brand: string, key: string): void => namespacedClearDeviceBrandData(localStorageNamespace, deviceId, brand, key),
    getDeviceBrandData: (deviceId: string, brand: string, key: string): Record<string, any> | null => namespacedGetDeviceBrandData(localStorageNamespace, deviceId, brand, key),
    storeDeviceBrandData: (deviceId: string, brand: string, key: string, data: Record<string, any> | null): void => namespacedStoreDeviceBrandData(localStorageNamespace, deviceId, brand, key, data),
    
    
    clearUserData: (userId: string, key: string): void => namespacedClearUserData(localStorageNamespace, userId, key),
    getUserData: (userId: string, key: string): Record<string, any> | null => namespacedGetUserData(localStorageNamespace, userId, key),
    storeUserData: (userId: string, key: string, data: Record<string, any> | null): void => namespacedStoreUserData(localStorageNamespace, userId, key, data),
    
    clearUserBrandData: (userId: string, brand: string, key: string): void => namespacedClearUserBrandData(localStorageNamespace, userId, brand, key),
    getUserBrandData: (userId: string, brand: string, key: string): Record<string, any> | null => namespacedGetUserBrandData(localStorageNamespace, userId, brand, key),
    storeUserBrandData: (userId: string, brand: string, key: string, data: Record<string, any> | null): void => namespacedStoreUserBrandData(localStorageNamespace, userId, brand, key, data),
});