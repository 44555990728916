(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("bowser"));
	else if(typeof define === 'function' && define.amd)
		define("OnePlaybackSdkShared", ["bowser"], factory);
	else if(typeof exports === 'object')
		exports["OnePlaybackSdkShared"] = factory(require("bowser"));
	else
		root["OnePlaybackSdkShared"] = factory(root["bowser"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__7300__) {
return 