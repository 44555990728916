const Utils = require('../utils/Utils');
const PreferredLanguage = require('./PreferredLanguage');
const SelectedLanguage = require('./SelectedLanguage');

/**
 * @typedef {object} TLanguageArgs
 * @property {PreferredLanguage} [preferred]
 * @property {SelectedLanguage} [selected]
 */
/**
 * @type {TLanguageArgs}
 */
class Props {
    constructor() {
        this.preferred = this.selected = undefined;
    }
}

class Language extends Props {
    /**
     * @param {TLanguageArgs} args
     */
    constructor(args = {}) {
        super();

        Utils.extend(this, {
            preferred: {
                audio: [], preferAccessibility: false, text: []
            },
            selected: { audio: null, text: null }
        }, args);
    }
}

module.exports = Language;