const Utils = require('../utils/Utils');
const { Content, Credentials, Language, LoadAdvertisement, LoadTracking, Drm, Stream, Load } = require('../dtos');
const MetadataMapper = require('./MetadataMapper');
const { RequestContentTypes } = require('../Constants');

module.exports = class LegacyLoadRequestMapper {

    static mapToLoadDto(legacyRequest) {
        //sanatizing
        if (legacyRequest.textTrackStyle) {
            delete legacyRequest.textTrackStyle;
        }

        const loadDto = new Load();

        loadDto.autoplay = legacyRequest.autoplay || true
        loadDto.currentTime = legacyRequest.currentTime || 0

        const media = legacyRequest.media;

        if (media) {
            const isLive = (media.streamType || '').toLowerCase() === 'live'

            loadDto.content = new Content({
                accessControl: 'SUBSCRIPTION',
                auxiliaryData: undefined,
                contentId: media.contentId,
                contentType: isLive ? 'CHANNEL' : 'MEDIA',
                metadata: undefined,
                nextContent: null,//|todo loadMapperTest.js [IMP][Major]: Fix this;//Utils.booleanOrDefault(args.nextContent, false);
                regionalChannels: undefined,
                serviceCountry: 'se',
                streamRestrictions: undefined,
                streams: [],
                type: undefined,
                watchMode: isLive ? 'LIVE' : 'ONDEMAND'
            })

            const metadata = media.metadata;
            if (metadata) {
                loadDto.content.metadata = MetadataMapper.mapFromGoogleMetadata(metadata);
            }

        } else {
            throw new Error(`Error class:loadMapperTest[mapToLoadDto] :  media is requered!`);
        }

        let customData = legacyRequest.customData;
        if (customData) {
            if (customData.subtitleLanguage || customData.audioLanguage) {
                loadDto.language = new Language({
                    preferred: {
                        text: [
                            customData.subtitleLanguage || undefined
                        ],
                        audio: [
                            customData.audioLanguage || undefined
                        ]
                    }
                })
                delete customData.subtitleLanguage;
                delete customData.audioLanguage;
            } else if (customData.language && customData.language.preferredTracks && Object.keys(customData.language.preferredTracks) > 0) {
                loadDto.language = new Language({
                    preferred: {
                        text: [
                            customData.language.preferredTracks.textTrackLanguage || undefined
                        ],
                        audio: [
                            customData.language.preferredTracks.audioTrackLanguage || undefined
                        ]
                    }
                })
                delete customData.language
            }
            //Credentials
            if (customData.jwt || customData.jwtToken) {
                loadDto.credentials = new Credentials({
                    type: 'TOKEN',
                    token: customData.jwt || customData.jwtToken
                });
                delete customData.jwt;
                delete customData.jwtToken;
            }

            if (customData.tracking && customData.tracking.consentString) {
                loadDto.tracking = new LoadTracking({
                    consentString: customData.tracking.consentString
                })
                delete customData.tracking;
            }

            if (customData.advertisement && customData.advertisement.advertisingId) {
                loadDto.advertisement = new LoadAdvertisement({
                    advertisingId: customData.advertisement.advertisingId
                })
                delete customData.advertisement;
            }
            let auxKeys = Object.keys(customData);
            if (auxKeys.length > 0) {
                console.warn(`[INFO] class:LegacyLoadRequestMapper[mapToLoadDto] : These keys were not parsed [${auxKeys.join(', ')}] And will therefore be arbitrarily added to axillaryData!`);
                if (!loadDto.content.auxiliaryData) {
                    loadDto.content.auxiliaryData = {};
                }
                Utils.extend(loadDto.content.auxiliaryData, customData)
                //Remove before release!
                console.log(`[LOG] class:LegacyLoadRequestMapper[mapToLoadDto] : On mapped axillary data:\n${JSON.stringify(loadDto.content.auxiliaryData, null, 3)}\n----------------------------------------`);
            }
        }

        legacyRequest.customData = loadDto
        return legacyRequest;
    }
};