const Utils = require('../../utils/Utils');
const IOverlay = require('../IOverlay');

module.exports = class FadingOverlay extends IOverlay {
    constructor({ container }) {
        super();

        this.container = container;

        this.content = null;
    }

    static get Name() {
        return 'Fading';
    }

    initialize(name = 'fading') {
        const wrapper = document.createElement('div');
        wrapper.className = `${name}-wrapper`;

        this.content = document.createElement('div');
        this.content.className = `${name}-content`;
        wrapper.appendChild(this.content);

        this.container.appendChild(wrapper);
    }

    fadeOut() {
        Utils.removeClass(this.container, 'fadeIn');
        Utils.addClass(this.container, 'fadeOut');

        setTimeout(() => this.reset(), 1000);
    }

    hide() {
        Utils.removeClass(this.container, 'fadeOut');
        Utils.removeClass(this.container, 'fadeIn');
    }

    pause() {
    }

    play() {
    }

    show() {
        Utils.addClass(this.container, 'fadeIn');
    }

    reset() {
        this.hide();

        if (this.content) {
            this.content.innerHTML = '';
        }
    }

    destroy() {
        this.reset();

        if (this.container) {
            Utils.removeClass(this.container, 'visible');
            this.container.innerHTML = '';
        }
    }
};