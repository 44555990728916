import { PlaybackEventTypes } from '../Constants';

export class PlaybackEventBase<t extends PlaybackEventTypes, p> {
    readonly type: t;
    readonly payload: p;

    constructor(type: t, payload: p) {
        this.type = type;
        this.payload = payload;
    }
};