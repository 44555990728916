const Utils = require('../utils/Utils');

/**
 * @typedef {object} TPreferredLanguageArgs
 * @property {string[]} [audio]
 * @property {boolean} [preferAccessibility]
 * @property {string[]} [text]
 */
/**
 * @type {TPreferredLanguageArgs}
 */
class Props {
    constructor() {
        this.audio = this.text = undefined;
    }
}

class PreferredLanguage extends Props {
    /**
     * @param {TPreferredLanguageArgs} args
     */
    constructor(args = {}) {
        super();

        Utils.extend(this, {
              audio: [],
              preferAccessibility: false,
              text: []
          }
          , args);
    }
}

module.exports = PreferredLanguage;