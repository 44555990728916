const {
    ErrorManager,
    UiManager,
    PlaybackManager,
    TracksManager,
    ContentManager,
    CommunicationManager
} = require('../managers');

const { Content, Metadata, ServiceLayerError, Stream } = require('../dtos');
const { ErrorOrigins, StoppedReasons } = require('../Constants');
const EventBus = require('../event/EventBus');
const I18n = require('../i18n/I18n');
const Engine = require('../player/Engine');
const Utils = require('../utils/Utils');
const IOverlay = require('../ui/IOverlay');
const LanguageMapper = require('../mappers/LanguageMapper');
const ReceiverError = require('../dtos/ReceiverError');


class ReceiverInterface extends EventBus {
    constructor({
                    communicationManager,
                    contentManager,
                    engine,
                    errorManager,
                    playbackManager,
                    tracksManager,
                    uiManager
                }) {
        super({ engine, tracksManager, errorManager });

        /**
         * @private
         * @type {CommunicationManager}
         */
        this.communicationManager = communicationManager;

        /**
         * @private
         * @type {ContentManager}
         */
        this.contentManager = contentManager;

        /**
         * @private
         * @type {Engine}
         */
        this.engine = engine;

        /**
         * @private
         * @type {ErrorManager}
         */
        this.errorManager = errorManager;

        /**
         * @private
         * @type {PlaybackManager}
         */
        this.playbackManager = playbackManager;

        /**
         * @private
         * @type {TracksManager}
         */
        this.tracksManager = tracksManager;

        /**
         * @private
         * @type {UiManager}
         */
        this.uiManager = uiManager;


        //default default on the default
        this.setLanguage('en');

        /**
         * @typedef {object} TApplicationInformation
         * @param {string} applicationName
         * @param {string} applicationVersion
         */

        /**
         * @type {TApplicationInformation}
         */
        this.applicationInfo = {
            applicationName: 'No_ApplicationInfo',
            applicationVersion: 'No_ApplicationInfo'
        }
    }

    /**
     * @typedef {object} TEventBusSetting
     * @property {boolean} [debugMode]
     * @property {boolean} [emitOriginal]
     */
    /**
     *
     * @param {TEventBusSetting} args
     */
    updateEventBusSetting(args) {
        ['debugMode', 'emitOriginal'].forEach(value => {
            if (typeof args[value] === 'boolean') {
                this.settings[value] = args[value]
            }
        })
        if (this.settings.wrapPayload) {
            console.warn(`Warning class:ReceiverInterface[updateEventBusSetting] : wrapPayload is deprecated since 3.5.0!\n All events will from here on out not be wrapped in a payload property!`);
        }
        console.log(`[LOG] class:ReceiverInterface[updateEventBusSetting] : EventBusSetting was updated: \n ${JSON.stringify(this.settings, null, 4)}!`);
    }

    /**
     * @return {{playbackManager: PlaybackManager, contentManager: ContentManager, engine: Engine, tracksManager: TracksManager, uiManager: UiManager, communicationManager: CommunicationManager}}
     */
    get components() {
        return {
            communicationManager: this.communicationManager,
            contentManager: this.contentManager,
            engine: this.engine,
            playbackManager: this.playbackManager,
            tracksManager: this.tracksManager,
            uiManager: this.uiManager
        }
    }

    /**
     * @param {ServiceLayerError} error
     */
    dispatchError(error) {
        error = Utils.extend(error || {}, {
            origin: ErrorOrigins.ServiceLayer.DispatchError
        });
        return this.errorManager.dispatchError(error);
    }

    /**
     *
     * @param {object} payload
     */
    dispatchSenderEvent(payload) {
        if (payload) {
            this.communicationManager.sendMessage(payload)
        }
    }

    // |--------------------------|
    // | CONTENT MANAGER
    // |--------------------------|
    /**
     * @param {string} title=''
     * @param {Array<Metadata>} metadataItems
     */
    updateContainerMetadata(title, metadataItems) {
        this.contentManager.updateContainerMetadata(title, metadataItems);
    }

    /**
     * @param {Metadata} metadata
     */
    updateCurrentMetadata(metadata) {
        this.contentManager.updateCurrentMetadata(metadata);
    }

    /**
     * @param {Content} content
     * @param {Boolean} playDirect
     */
    addToQueue(content, playDirect = false) {
        this.contentManager.addToQueue(content, playDirect);
    }

    /**
     *
     * @param {ReceiverError} error
     */
    logFinalError(error) {
        /** override **/
    }

    // |--------------------------|
    // | ENGINE
    // |--------------------------|

    pause() {
        this.engine.pause()
    }

    play() {
        this.engine.play()
    }

    /**
     * @param {number} position
     */
    seek(position) {
        this.engine.seek(position)
    }

    /**
     * @param {StoppedReasons} reason
     */
    stop(reason = StoppedReasons.Unknown) {
        this.engine.stop(reason);
    }

    registerPlayerConfiguration(playerConfiguration) {
        this.engine.registerPlayerConfiguration(playerConfiguration);
    }

    registerPlaybackFeature(name, playbackFeature) {
        this.engine.registerPlaybackFeature(name, playbackFeature);
    }

    setPlayerConfigurationProfile(playerConfigurationProfile) {
        this.engine.setPlayerConfigurationProfile(playerConfigurationProfile);
    }

    /**
     * @param {TApplicationInformation} applicationInfo
     */
    setApplicationInfo(applicationInfo) {
        this.applicationInfo = applicationInfo;
    }

    /**
     * @returns {(Stream | undefind)}
     */
    getCurrentStream() {
        return this.components.engine.stream;
    }

    //enableTtmlHandler
    //enableDashStaticTransitionHandler
    //enableSeekableRangeEndAsDuration
    //

    // |--------------------------|
    // | uiManager
    // |--------------------------|

    /**
     * @param {string}name
     * @param {function} factory
     * @return {any}
     */
    registerOverlay(name, factory) {
        return this.uiManager.registerOverlay(name, factory);
    }

    // |--------------------------|
    // | LANGUAGE
    // |--------------------------|
    /**
     * @param {string} languageCodeOrCountryCode
     */
    setLanguage(languageCodeOrCountryCode) {
        const language = LanguageMapper.mapISOCodeFromAlpha2or3to2(languageCodeOrCountryCode);
        I18n.setLanguage(language === 'nb' ? 'no' : language);
    }

    registerTranslation(translation) {
        I18n.setTranslations(translation);
    }
}

module.exports = ReceiverInterface;