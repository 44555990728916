const Utils = require('../Utils');
const NativeImage = Image;

class ImageExtension {
    constructor(w, h) {
        const nativeImage = new NativeImage(w, h);
        const handler = {
            set: (obj, prop, value) => {
                if ('src' === prop) {
                    value = Utils.resolveUrl(value);
                    const networkHandlers = this.imageInterceptor.getNetworkHandlers(value);
                    if (networkHandlers && networkHandlers.length) {
                        value = this.processRequest(networkHandlers, value);
                    }
                }

                return nativeImage[prop] = value;
            },
            get: (target, prop) => {
                let result = target[prop];
                if ('function' === typeof (result)) {
                    result = result.bind(target);
                }

                return result;
            }
        };

        const proxy = new Proxy(nativeImage, handler);
        try {
            proxy[Symbol.toStringTag] = 'HTMLImageElement';
        } catch (e) {
        }

        return proxy;
    }

    processRequest(networkHandlers, url) {
        let modifiedUrl = url;
        for (let n in networkHandlers) {
            if (!networkHandlers.hasOwnProperty(n)) continue;

            if (networkHandlers[n].requestHandler) {
                modifiedUrl = networkHandlers[n].requestHandler(modifiedUrl);
            }
        }

        return modifiedUrl;
    }
}

ImageExtension.prototype[Symbol.toStringTag] = NativeImage.prototype.toString();

Object.defineProperty(ImageExtension, 'name', {
    enumerable: false,
    configurable: false,
    writable: false,
    value: 'Image'
});

Object.defineProperty(ImageExtension, 'toString', {
    enumerable: true,
    configurable: false,
    writable: true,
    value: function () {
        return NativeImage.toString();
    }
});

module.exports = ImageExtension;