//
//  ReceiverInformation.js
//
//  Created by Lars Rothaus on 04/10/2021.
//  Copyright © 04/10/2021 Lars Rothaus. All rights reserved.
//

const Utils = require('../utils/Utils');

/**
 * @typedef {object} TBrowser
 * @property {string} browserName
 * @property {string} browserVersion
 */

/**
 * @typedef {object} TDevice
 * @property {string} deviceId
 * @property {string} deviceName
 * @property {string} deviceModel
 * @property {string} deviceVendor
 * @property {string} deviceVersion
 */

/**
 * @typedef {object} TOperatingSystem
 * @property {string} operatingSystemFirmwareVersion
 * @property {string} operatingSystemName
 * @property {string} operatingSystemVersion
 */

/**
 * @typedef {object} TPlayer
 * @property {string} playerName
 * @property {string} playerVersion
 */

/**
 * @typedef {object} TScreen
 * @property {number} screenHeight
 * @property {number} screenWidth
 */

/**
 * @typedef {object} TSession
 * @property {string} sessionId
 */

/**
 * @typedef {object} TPlaybackSession
 * @property {string} playbackSessionId
 */

/**
 * @typedef {object} TReceiverInformationArgs
 * @property {TBrowser} browser
 * @property {TDevice} device
 * @property {TOperatingSystem} operatingSystem
 * @property {TPlayer} player
 * @property {TPlaybackSession} playbackSession
 * @property {TScreen} screen
 * @property {TSession} session
 */

/**
 * @type {TReceiverInformationArgs}
 */
class Props {
    constructor() {
        this.browser = this.device = this.operatingSystem = this.player = this.playbackSession = this.screen = this.session = undefined
    }
}

class ReceiverInformation extends Props {
    /**
     * @param {TReceiverInformationArgs} args
     */
    constructor(args = {}) {
        super();

        Utils.extend(this, {}, args);
    }
}

module.exports = ReceiverInformation;