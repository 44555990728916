const Utils = require('../utils/Utils');

/**
 * @typedef {object} TShutdownArgs
 * @property {string} reason
 */
/**
 * @type {TShutdownArgs}
 */
class Props {
    constructor() {
        this.reason = undefined;
    }
}

class Shutdown extends Props {
    /**
     * @param {TShutdownArgs} args
     */
    constructor(args = {}) {
        super();

        Utils.extend(this, {
            reason: 'ShutdownReasons.Error'
        }, args);
    }
}

module.exports = Shutdown;