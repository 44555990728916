//
//  YospacePlaybackFeature.ts
//
//  Created by Lars Rothaus on 06/04/2022.
//
import { StandardError, ErrorCategories } from '@tv4/one-playback-sdk-shared';
import {
  AdvertisementBreak as UnifiedAdvertisementBreak,
  Advertisement as UnifiedAdvertisement,
} from '@tv4/unified-receiver';
import {
  TYospaceAdvert,
  TYospaceAdvertWrapper,
  YSPlayerEvents,
  YSSessionManager,
  YSTimelineAdvertElement,
  YSTimelineElement,
} from './lib/yospace-1.8.12.min';

import {
  addOrAppendQueryParameter,
  getPersistentAdvertisementId,
  ID3Parser,
  parseYospaceTimedDataObjectFromId3Cues,
} from './YospaceUtils';
import { Advertisement, AdvertisementBreak } from './AdvertisementData';
import { AdvertisementBreakTypes, Id3Cue, StreamCue, StreamCueTypes, TYospaceSeekPositionData } from './Types';
import Engine from '@tv4/unified-receiver/types/player/Engine';
import {
  AdvertisementTypes,
  EngineEvents,
  IYospacePlaybackFeature,
  Load,
  Stream,
  StreamTypes,
} from '@tv4/unified-receiver';
import { Videoplaza } from '../../playbackApiService/Types';
import { ClientServices, FeaturesConfiguration, ServiceLayerInformation } from '../../../Types';

export class Tv4YospacePlaybackFeature extends IYospacePlaybackFeature {
  public advertisementBreaks: Array<AdvertisementBreak>;
  public contentDuration: number;
  public inAdBreak: boolean;

  private clear: boolean = true;
  private debug: boolean = false;
  private engine?: Engine;
  private engineEventHandler: EngineEventHandler;
  private featureCurrentTime: number;
  private features: FeaturesConfiguration;
  private isBuffering: boolean;
  private live: boolean;
  private sessionManager?: YSSessionManager;
  private stitchedUrl?: string;
  private startTime: number = 0;
  private yospaceTimedDataObjects: Array<any>;

  constructor(features: FeaturesConfiguration, debug: boolean = false) {
    super();
    this.engineEventHandler = () => {};
    this.features = features;
    this.live = false;
    this.advertisementBreaks = [];
    this.contentDuration = 0;
    this.featureCurrentTime = 0;
    this.isBuffering = false;
    this.yospaceTimedDataObjects = [];
    this.inAdBreak = false;
    this.debug = debug;
    if (this.debug) {
      YSSessionManager.DEFAULTS.DEBUGGING = true; // enable yospace debugging
    }
  }

  public async initialize({
    configuration,
    load,
    serviceLayerInfo,
    videoPlaza,
    viewedAdvertisementBreaks,
    segmentTags,
  }: InitializeArgs): Promise<Stream[]> {
    // The PlaybackApi well always only produce one stream

    if (!load.content.streams[0]) {
      throw new StandardError({
        category: ErrorCategories.API,
        code: 'AD_STITCHING_CONTENT_BROKEN',
        message: 'Content contains no streams',
        fatal: true,
        details: {
          origin: 'Yospace',
          domain: 'initialize',
          message: 'No streams in content',
        },
      });
    }
    const stdStream = load.content.streams[0];
    const accessUrl = stdStream.playbackSessionUrl;
    stdStream.playbackSessionUrl = ''; // this should be nullable!!
    if (!accessUrl || stdStream.advertisementType !== AdvertisementTypes.Yospace) {
      return [stdStream];
    }
    //This is use the loaded method
    this.startTime = load.currentTime;

    const yospaceStream = new Stream(stdStream);

    // Resetting the playback-feature if it's not clear
    if (!this.clear) {
      await this.reset();
    }
    this.clear = false;

    this.live = configuration.isLive || false;

    let tags: string[] = [];
    tags.push(`${serviceLayerInfo.serviceId}.${serviceLayerInfo.serviceCountry}`);
    tags.push(`ns_st_mv-${serviceLayerInfo.applicationInfo?.applicationVersion || 'cc-unknown'}`);
    let isStartOver = yospaceStream.streamType === StreamTypes.StartOver;
    if (isStartOver) {
      tags.push('startover');
    }

    if (configuration.skipBumpers) {
      tags.push('NOBUMPERS');
    }

    const yospaceUrl = new URL(accessUrl);
    if (videoPlaza.tags) {
      if (Array.isArray(videoPlaza.tags)) {
        tags = [...tags, ...videoPlaza.tags];
      } else if (typeof videoPlaza.tags === 'string') {
        let vpTags: string[] = videoPlaza.tags.split(',');
        /**
         * remove ns_st_mv as we need to override it...
         */
        vpTags.forEach((value, index) => {
          if (value.includes('ns_st_mv')) {
            vpTags.splice(index, 1);
          }
        });
        tags = [...tags, ...vpTags];
      }
    }
    if (videoPlaza.contentForm && serviceLayerInfo.serviceId === ClientServices.MTV) {
      tags.push(videoPlaza.contentForm);
    }
    if (serviceLayerInfo.serviceId === ClientServices.MTV) {
      tags.push('NordicPlayer');
    }

    tags = [...tags, ...segmentTags];
    const tagsString = tags.join(',');

    addOrAppendQueryParameter(yospaceUrl.searchParams, 't', tagsString);
    if (this.features.freewheelEnabled) {
      const project = this.features.freewheelTestEnv ? 'freewheeltest' : 'freewheel';
      addOrAppendQueryParameter(yospaceUrl.searchParams, 'project', project);
    }
    if (videoPlaza.shares) {
      yospaceUrl.searchParams.set('s', videoPlaza.shares);
    }

    yospaceUrl.searchParams.set(
      'dcid',
      serviceLayerInfo.serviceId === ClientServices.MTV ? 'mtv_googlecast' : DeviceAdvertisementId
    );
    if (serviceLayerInfo.serviceId === ClientServices.MTV) {
      yospaceUrl.searchParams.set('cp.appname', 'Katsomo');
      yospaceUrl.searchParams.set('cp.appid', 'fi.mtvkatsomo');
      yospaceUrl.searchParams.set('cp.appver', serviceLayerInfo.applicationInfo?.applicationVersion || 'cc-unknown');
    }
    if (!this.debug) {
      yospaceUrl.searchParams.set('pid', getPersistentAdvertisementId(serviceLayerInfo.user?.userId));
    } else {
      /**
             Generating a random pid will bypass the ad-proxys one minute grace-period
             ensuring that one allways gets ads while testing
             **/
      yospaceUrl.searchParams.set('pid', Date.now().toString().slice(-6));
    }

    if (load.advertisement && load.advertisement.advertisingId) {
      yospaceUrl.searchParams.set('cp.ifa', '' + load.advertisement.advertisingId);
    }
    if (load.tracking && load.tracking.consentString) {
      if (serviceLayerInfo.serviceId === ClientServices.MTV) {
        yospaceUrl.searchParams.set('cp.gdpr_consent', '' + load.tracking.consentString);
        yospaceUrl.searchParams.set('cp.gdpr', '1');
        yospaceUrl.searchParams.set('cp.gdpr_pd', '0');
      } else {
        yospaceUrl.searchParams.set('gdpr_consent', '' + load.tracking.consentString);
        yospaceUrl.searchParams.set('gdpr', '1');
      }
    }
    if (configuration.skipPrerolls) {
      yospaceUrl.searchParams.set('f', 'noprerolls');
    }
    if (isStartOver && load.currentTime) {
      yospaceUrl.searchParams.set('yo.so', load.currentTime.toString());
    }

    const result = await this.setupYospace(yospaceUrl.href);

    if (result !== YospaceSuccessfulResult || !this.sessionManager) {
      throw new StandardError({
        category: ErrorCategories.API,
        code: 'AD_LOAD_FAILED',
        fatal: true,
        details: {
          origin: 'Yospace',
          domain: 'initialize',
          configuration: {},
          result,
        },
      });
    }

    let yospaceStramUrl = getStitchedUrl(this.sessionManager.masterPlaylist());
    if (!yospaceStramUrl) {
      //|todo Tv4YospacePlaybackFeature.ts [INFO][Major]: Should this be fatal? or should it just fallback to the none-yospace stream;
      return [stdStream];
    }
    if (!yospaceStramUrl) {
      throw new StandardError({
        category: ErrorCategories.API,
        code: 'AD_STITCHING_FAILED',
        fatal: true,
        details: {
          origin: 'Yospace',
          domain: 'initialize',
          configuration: {},
        },
      });
    }

    /**
     * register player stuff...
     */

    this.sessionManager.registerPlayer({
      AdBreakStart: () => {
        let advertisementInfo = this.handleAdvertisementBreakStart();
        /**
         * If session is live we create a dummy AdvertisementBreak to satisfy the BonnierTrackingLib
         */
        if (!advertisementInfo) {
          advertisementInfo = new AdvertisementBreak({
            id: 'live',
            advertisements: [],
            breakType: AdvertisementBreakTypes.MIDROLL,
            active: true,
          });
        }
        this.engineEventHandler(EngineEvents.AdvertisementBreakStarted, advertisementInfo);
      },
      AdBreakEnd: () => {
        let advertisementInfo = this.handleAdvertisementBreakEnd();
        if (!advertisementInfo) {
          advertisementInfo = new AdvertisementBreak({
            id: 'live',
            advertisements: [],
            breakType: AdvertisementBreakTypes.MIDROLL,
            active: true,
          });
        }
        this.engineEventHandler(EngineEvents.AdvertisementBreakEnded, advertisementInfo);
      },
      AdvertStart: () => {
        const advertisementInfo = this.getAdvertisementStartData();
        this.engineEventHandler(EngineEvents.AdvertisementStarted, advertisementInfo);
      },
      AdvertEnd: () => {
        this.engineEventHandler(EngineEvents.AdvertisementEnded);
      },
    });

    this.setupAdvertisementBreaks(load.currentTime, load.content.contentId, viewedAdvertisementBreaks);

    if (this.advertisementBreaks && this.advertisementBreaks.length) {
      yospaceStream.advertisementBreaks = this.advertisementBreaks.map((adBreak) => {
        return new UnifiedAdvertisementBreak({
          id: adBreak.id,
          name: adBreak.name,
          duration: adBreak.duration,
          embedded: adBreak.embedded,
          watched: false,
          position: this.getPositionWithoutAds(adBreak.position),
          completion: adBreak.watched ? 100 : 0,
          advertisements: adBreak.advertisements.map((ad) => {
            return new UnifiedAdvertisement({
              id: ad.id,
              name: ad.name,
              duration: ad.durationInSeconds,
              title: ad.name,
            });
          }),
        });
      });
    }

    yospaceStream.playbackSessionUrl = yospaceStramUrl;
    return [yospaceStream];
  }

  private getAdvertisementStartData(): Advertisement | undefined {
    if (
      !this.sessionManager ||
      !this.sessionManager.session ||
      !this.sessionManager.session.currentAdvert ||
      !this.sessionManager.session.currentAdvert.advert
    ) {
      return;
    }

    const advert = this.sessionManager.session.currentAdvert.advert;
    const advertWrapper = this.sessionManager.session.currentAdvert;
    const durationInSeconds: number = this.sessionManager.session.currentAdvert.duration;

    let positionInAdBreak = 0;
    let totalAdsInAdBreak = 0;
    const adBreak = this.sessionManager.session.getCurrentBreak();
    if (adBreak?.adverts?.length) {
      const index = adBreak.adverts.findIndex((wrapper) => wrapper.startPosition === advertWrapper.startPosition);
      if (index !== -1) {
        positionInAdBreak = index + 1;
        totalAdsInAdBreak = adBreak.adverts.length;
      }
    }

    const id: string = advert.id;
    const name: string = advert.AdTitle;
    const breakType = this.getAdvertisementBreakType();
    const { campaignId, customId, goalId, sponsor } = getVastAdExtensionData(advert, this.features.freewheelEnabled);

    const ad = new Advertisement({
      id,
      name,
      durationInSeconds,
      breakType,
      //adNumberInBreak,
      sponsor,
      campaignId,
      customId,
      goalId,
      positionInAdBreak,
      totalAdsInAdBreak,
    });

    return ad;
  }

  private handleAdvertisementBreakEnd(): AdvertisementBreak | undefined {
    this.inAdBreak = false;
    let lastActiveBreak: AdvertisementBreak | undefined;
    this.advertisementBreaks.forEach((advertisementBreak) => {
      if (advertisementBreak.active) {
        advertisementBreak.active = false;
        advertisementBreak.watched = true;
        lastActiveBreak = advertisementBreak;
      }
    });

    return lastActiveBreak;
  }

  private handleAdvertisementBreakStart(): AdvertisementBreak | undefined {
    if (!this.sessionManager || !this.sessionManager.session) return;

    this.inAdBreak = true;

    const currentBreak = this.sessionManager.session.getCurrentBreak();

    let lastActiveBreak: AdvertisementBreak | undefined;

    this.advertisementBreaks.forEach((advertisementBreak) => {
      if (advertisementBreak.position === currentBreak.startPosition) {
        advertisementBreak.active = true;
        lastActiveBreak = advertisementBreak;
      }
    });

    return lastActiveBreak;
  }

  private setupAdvertisementBreaks(startTime: number, contentId: string, viewedAdvertisementBreaks: Array<number>) {
    if (!this.sessionManager) return;

    const timeline = this.sessionManager.getTimeline();
    if (!timeline || !timeline.elements) return;

    let nextAdvertisementId = -1;
    let nextAdvertisementBreakId = -1;
    timeline.elements.forEach((element: YSTimelineAdvertElement | YSTimelineElement) => {
      if (element instanceof YSTimelineAdvertElement) {
        const adStart = this.getPositionWithoutAds(element.offset);
        const watched =
          viewedAdvertisementBreaks.some(
            (viewedStart) => Math.abs(viewedStart - adStart) < AdvertisementViewedMarginSeconds
          ) ||
          (startTime != null && adStart < startTime);

        const breakType = element.offset === 0 ? AdvertisementBreakTypes.PREROLL : AdvertisementBreakTypes.MIDROLL;
        const advertisements = element.adBreak.adverts.map(
          (advertWrapper: TYospaceAdvertWrapper, index: number) =>
            new Advertisement({
              id: `Advertisement-${contentId}-${++nextAdvertisementId}`,
              name: advertWrapper.advert?.AdTitle,
              durationInSeconds: advertWrapper.duration,
              breakType,
              adNumberInBreak: index + 1,
              sponsor: advertWrapper.advert?.Extensions
                ? getVastAdExtensionData(advertWrapper.advert, this.features.freewheelEnabled).sponsor
                : false,
            })
        );

        this.advertisementBreaks.push(
          new AdvertisementBreak({
            id: `AdvertisementBreak-${contentId}-${++nextAdvertisementBreakId}`,
            name: '',
            active: false,
            advertisements,
            breakType,
            durationInSeconds: element.duration,
            embedded: true,
            position: element.offset,
            watched,
          })
        );
      } else {
        this.contentDuration += element.duration;
      }
    });
  }

  private setupYospace(yospaceUrl: string): Promise<any> {
    return new Promise((resolve) => {
      const yospaceFunction = this.live ? YSSessionManager.createForLive : YSSessionManager.createForVoD;
      const yospaceParameters = this.live ? { IS_REDIRECT: false } : null;
      /**
       * Create Yospace SessionManager
       */
      this.sessionManager = yospaceFunction(yospaceUrl, yospaceParameters, resolve);
    });
  }

  private getPastAdvertisementBreak(positionWithAds: number): AdvertisementBreak | undefined {
    let pastAdvertisementBreak: AdvertisementBreak | undefined;

    this.advertisementBreaks.forEach((advertisementBreak) => {
      if (advertisementBreak.isPositionPastAdvertisementBreak(positionWithAds)) {
        pastAdvertisementBreak = advertisementBreak;
      }
    });

    return pastAdvertisementBreak;
  }

  private getPositionWithAdsBeforeOrAfterBreak(positionWithoutAds: number): number {
    let newPosition: number = positionWithoutAds;

    this.advertisementBreaks.forEach((advertisementBreak) => {
      if (
        advertisementBreak.isPositionPastAdvertisementBreak(newPosition) ||
        advertisementBreak.isPositionInAdvertisementBreak(newPosition)
      ) {
        newPosition += advertisementBreak.durationInSeconds;
      }
    });

    return newPosition;
  }

  public getAdvertisementBreaks(): Array<AdvertisementBreak> {
    return this.advertisementBreaks;
  }

  public getAdvertisementBreakForPosition(currentTime: number): AdvertisementBreak | undefined {
    return this.advertisementBreaks.find((advertisementBreak) =>
      advertisementBreak.isPositionInAdvertisementBreak(currentTime)
    );
  }

  public getAdvertisementBreakType(): AdvertisementBreakTypes {
    const lastActiveBreak = this.advertisementBreaks.filter((advertisementBreak) => advertisementBreak.active).pop();
    if (!this.live && lastActiveBreak && lastActiveBreak.position === 0) {
      return AdvertisementBreakTypes.PREROLL;
    }
    return AdvertisementBreakTypes.MIDROLL;
  }

  public getCurrentAdvertisementClickThroughUrl(): string | undefined {
    if (
      !this.sessionManager ||
      !this.sessionManager.session ||
      !this.sessionManager.session.currentAdvert ||
      !this.sessionManager.session.currentAdvert.advert ||
      !this.sessionManager.session.currentAdvert.advert.linear ||
      !this.sessionManager.session.currentAdvert.advert.linear.clickThrough
    )
      return;

    return this.sessionManager.session.currentAdvert.advert.linear.clickThrough;
  }

  public getCurrentAdvertisementDuration(): number {
    if (!this.sessionManager || !this.sessionManager.session || !this.sessionManager.session.currentAdvert) return 0;
    return this.sessionManager.session.currentAdvert.duration;
  }

  public getCurrentAdvertisementTime(): number {
    if (!this.sessionManager || !this.sessionManager.session || !this.sessionManager.session.currentAdvert) return 0;
    return this.sessionManager.session.currentAdvert.timeElapsed();
  }

  public getPositionWithAds(positionWithoutAds: number): number {
    const pos = this.getPositionWithAdsBeforeOrAfterBreak(positionWithoutAds);

    const pastBreak = this.getPastAdvertisementBreak(pos);

    if (pastBreak && !pastBreak.watched) {
      return pastBreak.position;
    }

    return pos;
  }

  public getPositionWithoutAds(positionWithAds: number): number {
    let adTime = 0;

    this.advertisementBreaks.forEach((advertisementBreak) => {
      if (advertisementBreak.isPositionPastAdvertisementBreak(positionWithAds)) {
        adTime += advertisementBreak.durationInSeconds;
      } else if (advertisementBreak.isPositionInAdvertisementBreak(positionWithAds)) {
        adTime += positionWithAds - advertisementBreak.position;
      }
    });

    return positionWithAds - adTime;
  }

  public getSeekPosition(positionWithoutAds: number): TYospaceSeekPositionData {
    const seekPosition = this.getPositionWithAds(positionWithoutAds);
    const seekPositionAfterBreakEnd = this.getPositionWithAdsBeforeOrAfterBreak(positionWithoutAds);

    return {
      seekPosition,
      seekPositionAfterBreakEnd: seekPositionAfterBreakEnd === seekPosition ? undefined : seekPositionAfterBreakEnd,
    };
  }

  public loaded(): void {
    if (!this.sessionManager) return;

    this.sessionManager.reportPlayerEvent(YSPlayerEvents.START);

    if (!this.live) {
      this.sessionManager.reportPlayerEvent(YSPlayerEvents.POSITION, this.startTime || 0);
    }

    this.sessionManager.reportPlayerEvent(YSPlayerEvents.PAUSE);
  }

  public advertisementClicked(): void {
    if (!this.sessionManager) return;
    this.sessionManager.reportPlayerEvent(YSPlayerEvents.CLICK);
  }

  public buffered(): void {
    if (!this.sessionManager || !this.isBuffering) return;

    this.isBuffering = false;
    this.sessionManager.reportPlayerEvent(YSPlayerEvents.CONTINUE);
  }

  public buffering(): void {
    if (!this.sessionManager || this.currentTime <= 3) return;

    this.isBuffering = true;
    this.sessionManager.reportPlayerEvent(YSPlayerEvents.STALL);
  }

  public paused(): void {
    if (!this.sessionManager) return;
    this.sessionManager.reportPlayerEvent(YSPlayerEvents.PAUSE);
  }

  public playing(): void {
    if (!this.sessionManager) return;

    this.sessionManager.reportPlayerEvent(YSPlayerEvents.RESUME);
  }

  public id3Cue(id3Cue: Id3Cue) {
    const yospaceTimedDataObject = parseYospaceTimedDataObjectFromId3Cues(id3Cue);
    this.yospaceTimedDataObjects.push(yospaceTimedDataObject);
  }

  public streamCue(streamCue: StreamCue) {
    // ToDo: Remove ?
    if (streamCue.type === StreamCueTypes.ID3) {
      if (!streamCue.startTime) return;
      const id3Cue: Id3Cue = streamCue.parsed || ID3Parser.Parse(streamCue.raw, streamCue.startTime);
      this.id3Cue(id3Cue);
    } else if (streamCue.type === StreamCueTypes.ID3_IN_EMSG) {
      // ToDo: Parse Id3 in emsg messageData
      // this.id3Cue(streamCue.parsed, streamCue.startTime);
    } else if (streamCue.type === StreamCueTypes.EMSG) {
      // this.emsgCue(streamCue.parsed, streamCue.startTime);
    }
  }

  public stopped(): void {
    if (!this.sessionManager) return;
    this.sessionManager.reportPlayerEvent(YSPlayerEvents.END);
  }

  public timeUpdate(positionWithAds: number): void {
    if (!this.sessionManager || !this.sessionManager.session) return;

    this.featureCurrentTime = positionWithAds;

    if (!isNaN(this.featureCurrentTime)) {
      const currentAdBreak = this.advertisementBreaks.find((advertisementBreak) =>
        advertisementBreak.isPositionInAdvertisementBreak(this.featureCurrentTime)
      );
      if (!currentAdBreak || !currentAdBreak.watched) {
        // ToDo: Should it really not report progress for watched breaks? Granted, they will be seeked over but still seems odd...
        this.sessionManager.reportPlayerEvent(YSPlayerEvents.POSITION, this.featureCurrentTime);
        this.yospaceTimedDataObjects.forEach((id3, index) => {
          if (!this.sessionManager) return;
          if (id3.time <= this.featureCurrentTime) {
            this.sessionManager.reportPlayerEvent(YSPlayerEvents.METADATA, id3.tag);
            this.yospaceTimedDataObjects.splice(index, 1);
          }
        });
      }
    }
  }

  public setEngine(engine: Engine) {
    this.engine = engine;
  }

  get currentTime() {
    if (!this.engine) {
      return 0;
    }

    if (this.inAdBreak) {
      return this.getCurrentAdvertisementTime();
    }

    if (!this.live) {
      return this.getPositionWithoutAds(this.engine.currentMediaElementTime);
    }

    return this.engine.currentTime;
  }

  get duration() {
    if (!this.engine) {
      return -1;
    }
    if (this.inAdBreak) {
      return this.getCurrentAdvertisementDuration();
    }

    if (!this.live) {
      return this.contentDuration;
    }

    return this.engine.duration;
  }

  interceptEngineEvent(event: string, data: Record<string, any>): boolean {
    if (!this.engine) return false;

    switch (event) {
      case EngineEvents.Buffered:
        this.buffered();
        break;
      case EngineEvents.Buffering:
        this.buffering();
        break;
      case EngineEvents.LoadedData:
        this.loaded();
        break;
      case EngineEvents.Pause:
        this.paused();
        break;
      case EngineEvents.Play:
        this.playing();
        break;
      case EngineEvents.Seeked:
        this.buffered();
        break;
      case EngineEvents.Seeking:
        this.buffering();
        break;
      case EngineEvents.StreamCue:
        if (['StreamCueTypes.Id3'].includes(data.type)) {
          this.id3Cue(data.parsed);
        } else {
          console.error(`Unable to map map CueType: ${data.type} [EMSG, ID3, ID3_IN_EMSG, SCTE35, UNKNOWN]`);
        }
        break;
      case EngineEvents.StreamFinished:
        this.stopped();
        break;
      case EngineEvents.TimeUpdate:
        this.timeUpdate(this.engine.currentMediaElementTime);
        if (this.inAdBreak) {
          this.engineEventHandler(EngineEvents.AdvertisementTimeUpdate, {
            ...data,
            advertisementCurrentTime: this.currentTime,
            advertisementDuration: this.duration,
          });
          return true;
        }
        break;
    }
    return false;
  }

  setEngineEventHandler(engineEventHandler: EngineEventHandler) {
    this.engineEventHandler = engineEventHandler;
  }

  async reset(): Promise<void> {
    // console.warn('|-----------------------------------------------------|');
    // console.warn('| CHECK THAT ALL PROPERTIES ARE RESET BEFORE RELEASE');
    // console.warn('|-----------------------------------------------------|');
    if (this.sessionManager) {
      this.sessionManager.shutdown();
    }
    this.advertisementBreaks;
    this.contentDuration = 0;
    this.inAdBreak = false;
    this.isBuffering = false;
    this.startTime = 0;
    this.yospaceTimedDataObjects = [];
    this.sessionManager = undefined;
    this.live = false;
    this.isBuffering = false;
    this.advertisementBreaks = [];
    this.isBuffering = false;
    this.contentDuration = 0;
    this.featureCurrentTime = 0;
    this.yospaceTimedDataObjects = [];
    this.inAdBreak = false;
    this.stitchedUrl = undefined;

    this.clear = true;
  }

  async destroy(): Promise<void> {
    await this.reset();
    this.engineEventHandler = () => {};
    this.engine = undefined;
  }
}

type TVastAdExtensionData = {
  campaignId: string;
  customId: string;
  goalId: string;
  sponsor: boolean;
};

type InitializeArgs = {
  load: Load;
  videoPlaza: Videoplaza;
  serviceLayerInfo: ServiceLayerInformation;
  configuration: YospaceFeatureConfiguration;
  viewedAdvertisementBreaks: Array<number>;
  segmentTags: string[];
};

interface AdInfoElement extends Element {
  attributes: Element['attributes'] & {
    cid?: { value: string };
    customaid?: { value: string };
    gid?: { value: string };
    variant?: { value: string };
  };
}

const getDataFromAdInfo = (adInfo?: AdInfoElement) => ({
  campaignId: adInfo?.attributes?.cid?.value || '',
  customId: adInfo?.attributes?.customaid?.value || '',
  goalId: adInfo?.attributes?.gid?.value || '',
  sponsor: adInfo?.attributes?.variant?.value === 'BUMPER',
});

const getDataFromFreeWheelAd = (advert: TYospaceAdvert) => ({
  campaignId: '',
  customId:
    //@ts-ignore
    advert.vastXML?.getElementsByTagName('UniversalAdId')[0]?.innerHTML || '',
  goalId: '',
  //@ts-ignore
  sponsor: advert.vastXML?.getElementsByTagName('CreativeParameter')[0]?.innerHTML === 'bumper',
});

const getVastAdExtensionData = (advert: TYospaceAdvert, freewheelEnabled: boolean): TVastAdExtensionData => {
  const adExtensions: Array<Element> = advert.Extensions;
  // Look explicitly for a videoplaza extension with AdInfo
  const videoplazaExtension: Element | undefined = adExtensions?.find(
    (extension) => extension?.getAttribute('name') === 'Videoplaza'
  );

  // Extract videoplaza AdInfo
  const videoplazaAdInfo: AdInfoElement | undefined = videoplazaExtension?.getElementsByTagName('AdInfo')[0];

  if (videoplazaAdInfo) {
    return getDataFromAdInfo(videoplazaAdInfo);
  }
  // Look for any extension with AdInfo
  const extensionWithAdInfo: Element | undefined = adExtensions?.find(
    (extension) => extension.getElementsByTagName('AdInfo')[0]
  );
  // Extract AdInfo
  const adInfo: AdInfoElement | undefined = extensionWithAdInfo?.getElementsByTagName('AdInfo')[0];

  if (freewheelEnabled) {
    return getDataFromFreeWheelAd(advert);
  }

  return getDataFromAdInfo(adInfo);
};

const getStitchedUrl = (masterPlaylist: string): string | undefined => {
  return masterPlaylist && masterPlaylist.indexOf('https://null/') === 0 ? undefined : masterPlaylist;
};

const DeviceAdvertisementId = 'b5b11b43-b2e2-42fe-96c3-f6cd322c6383';
const YospaceSuccessfulResult = 'ready';
const AdvertisementViewedMarginSeconds = 2;

type YospaceFeatureConfiguration = {
  skipPrerolls?: boolean;
  skipBumpers?: boolean;
  isLive?: boolean;
};

type EngineEventHandler = (event: string, data?: Record<string, any>) => void;
