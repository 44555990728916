import { AdvertisementBreakTypes } from '@tv4/one-playback-sdk-shared';
import { guid } from '../../../../../utils';
import { TAdvertisement, TAdvertisementBreak } from '../Types';

/*
  Sometimes the Yospace SDK position values and video position values differ by
  a few ms, eg. video 28.485 vs SDK 28.48500003, this can cause
  invalid behavior in `isPositionInAdvertisementBreak()` and `isPositionPastAdvertisementBreak()`
  To avoid this we add a margin to the position when comparing position to AdvertisementBreak end.

  We also add the margin to `getEndPosition()` as the Yospace SDK values can be inaccurate causing the end position
  to be a bit smaller than the actual end position which can cause problems when converting between timestamps
  with and without ads.
*/
const END_MARGIN = 0.5;

export type TAdvertisementBreakArgs = {
    id?: string;
    name?: string;
    active: boolean;
    advertisements: Array<TAdvertisement>;
    breakType: AdvertisementBreakTypes;
    durationInSeconds: number;
    embedded: boolean;
    position: number;
    watched: boolean;
};

export class AdvertisementBreak implements TAdvertisementBreak {
    public readonly type = 'AdvertisementBreak';

    public id: string;
    public name: string;
    public active: boolean;
    public advertisements: Array<TAdvertisement>;
    public breakType: AdvertisementBreakTypes;
    public durationInSeconds: number;
    public embedded: boolean;
    public position: number;
    public watched: boolean;

    constructor(advertisementBreak?: TAdvertisementBreakArgs) {
        this.id = advertisementBreak?.id ?? guid();
        this.name = advertisementBreak?.name ?? 'Unknown';
        this.active = advertisementBreak?.active ?? false;
        this.advertisements = advertisementBreak?.advertisements ?? [];
        this.breakType = advertisementBreak?.breakType ?? AdvertisementBreakTypes.MIDROLL; // Defaults to MidRoll as that is the most common type of break
        this.durationInSeconds = advertisementBreak?.durationInSeconds ?? 0;
        this.embedded = advertisementBreak?.embedded ?? false;
        this.position = advertisementBreak?.position ?? 0;
        this.watched = advertisementBreak?.watched ?? false;
    }

    public getEndPosition(): number {
        return this.position + this.durationInSeconds + END_MARGIN;
    }


    public isPositionInAdvertisementBreak(position: number): boolean {
        return (
            position >= this.position &&
            position + END_MARGIN < this.position + this.durationInSeconds
        );
    }

    public isPositionPastAdvertisementBreak(position: number): boolean {
        return position + END_MARGIN >= this.position + this.durationInSeconds;
    }
};
