import { ErrorCodes } from '@tv4/unified-receiver';
import { ServiceLayerErrorCodes, Translations } from '../Constants';

export const sv = {
  [ErrorCodes.Generic]: 'Något gick fel - försök igen senare',
  [ErrorCodes.UpdateRequired]: 'Du behöver uppdatera din enhet',
  [ErrorCodes.TryingToRecover]: 'Ett problem har uppstått<br /><br />Försöker att återansluta',

  [ServiceLayerErrorCodes.Api.MissingAssetOrMedia]: 'Kunde inte hitta innehållet - försök igen senare',
  [ServiceLayerErrorCodes.Configuration.MissingSdkService]: 'Kunde inte starta - försök igen senare',
  [ServiceLayerErrorCodes.Device.Gen1NotSupported]:
    'Det går inte längre att använda TV4 Play på din Chromecast-enhet. Du behöver en nyare Chromecast för att fortsätta casta framöver. Läs mer på tv4play.se/faq',
  [ServiceLayerErrorCodes.GeoRestricted.Generic]:
    'Det går inte att spela innehållet där du är. Det kan bero på att du befinner dig utomlands',
  [ServiceLayerErrorCodes.GeoRestricted.Portability]:
    'Vill du titta på våra program utomlands?<br /><br />Nu kan du som är inloggad använda TV4 Play inom hela EU och EES! ' +
    'Surfa in på webbadressen nedan på din mobil, surfplatta eller dator för att komma igång.<br /><br /> ' +
    'www.tv4play.se/utomlands',
  [ServiceLayerErrorCodes.Load.Cancelled]: 'Start avbruten - försök igen senare',
  [ServiceLayerErrorCodes.StreamLimit.Generic]: 'Du använder för många enheter på ditt konto',
  [ServiceLayerErrorCodes.Credentials.MissingOrInvalidRefreshToken]: 'Autentisering misslyckades',

  [Translations.Overlay.CustomPauseAd.TopBar]: 'Annons: Klicka på play för att fortsätta titta',
};
