const Utils = require('../utils/Utils');

/**
 * @typedef {object} TSelectedLanguageArgs
 * @property {string} [audio]
 * @property {string} [text]
 */
/**
 * @type {TSelectedLanguageArgs}
 */
class Props {
    constructor() {
        this.audio = this.text = undefined;
    }
}

class SelectedLanguage extends Props {
    /**
     * @param {TSelectedLanguageArgs} args
     */
    constructor(args = {}) {
        super();

        Utils.extend(this, {}, args);
    }
}

module.exports = SelectedLanguage;