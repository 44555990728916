import { TClientName } from "@tv4/one-playback-sdk-shared";

export const getDeviceAdvertisementId = (clientName: TClientName): string => {
    if (clientName === 'ccr') return 'b5b11b43-b2e2-42fe-96c3-f6cd322c6383';
    if (clientName === 'androidmob') return '740af957-6a1f-4b40-bfe8-f9b23749d88b';
    if (clientName === 'ios') return 'bfbc3de4-2c4a-406d-9a36-2a5f4da91a32';
    if (clientName === 'sstv') return 'cf8e2aa5-da5c-4f76-82eb-3ef563d8d64e';
    if (clientName === 'lgtv') return '36f1c438-2aef-4033-ad6b-233c29873c7c';
    return 'd918a1f3-0bcc-438b-a205-b2a87ad0daf6'; // default to 'web'
};
