const Utils = require('../utils/Utils');
const { CipherTypes, CipherModeTypes, PaddingTypes } = require('../Constants');

/**
 * @typedef {object} TEncryptionArgs
 * @property {string} cipher
 * @property {string} cipherMode
 * @property {Array<number>} [initializationVector=null]
 * @property {Array<number>} [key=null]
 * @property {Array<number>} [keyId=null]
 * @property {string} [padding]
 */
/**
 * @type {TEncryptionArgs}
 */
class Props {
    constructor() {
        this.cipher = this.cipherMode = this.initializationVector = this.key = this.keyId = this.padding = undefined;
    }
}

class Encryption extends Props {
    /**
     * @param {TEncryptionArgs} args
     */
    constructor(args = {}) {
        super();

        Utils.extend(this, {
            cipher: CipherTypes.Aes128,
            cipherMode: CipherModeTypes.Ctr
        }, args);

        if (!this.padding) {
            this.padding = this.cipherMode === CipherModeTypes.Ctr ? PaddingTypes.None : PaddingTypes.Pkcs7;
        }
    }
}

module.exports = Encryption;