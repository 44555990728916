const ContentMapper = require('./ContentMapper');
const CustomDataMapper = require('./CustomDataMapper');
const LanguageMapper = require('./LanguageMapper');
const MetadataMapper = require('./MetadataMapper');
const Utils = require('../utils/Utils');

module.exports = class RequestMapper {
    constructor() {
    }

    static mapEditTracksRequest(request) {
        if (request.textTrackStyle) {
            delete request.textTrackStyle;
        }

        if (request.language === 'none') {
            request.language = 'off';
        }

        if (request.language) {
            request.language = LanguageMapper.mapISOCodeFromAlpha2or3to2(request.language);
        }

        return request;
    }

    static mapFinalLoadRequest(request) {
        if (!Utils.isNumber(request.currentTime)) {
            request.currentTime = undefined;
        }

        return request;
    }

    static mapFromLoadDto(request, loadDto, stream, logoUrl) {
        request = ContentMapper.mapToLoadRequest(request, loadDto.content, stream, logoUrl);

        request.autoplay = loadDto.autoplay;
        if (Utils.isNumber(loadDto.currentTime)) {
            request.currentTime = loadDto.currentTime;
        }

        return request;
    }

    static mapLabel(language) {
        language = LanguageMapper.mapISOCodeFromAlpha2or3to2(language);

        let label = null;
        [
            language,
            LanguageMapper.mapISOCodeFromAlpha2or3to2(navigator.language.slice(0, 2)),
            'en'
        ].some(l => {
            if (LanguageMapper.hasLanguageGroup(l)) {
                label = LanguageMapper.mapLabel(l, language);
            }

            return !!label; // Will bail out once label is set
        });

        return language === 'off' ? 'Off' : (label || language);
    }

    static mapLoadRequest(request) {
        request.media = request.media || {};

        if (request.media.contentId && !request.media.entity) {
            request.media.entity = request.media.contentId;
        }

        if (request.media.textTrackStyle) {
            delete request.media.textTrackStyle;
        }

        if (
          request.customData &&
          request.media.customData &&
          Object.keys(request.customData).length &&
          Object.keys(request.media.customData).length
        ) {
            console.error('RequestMapper::normalizeLoadRequest: CustomData found in both the load request and the media of the load request!', request);
        }

        request.customData = Utils.extend({}, request.customData || {}, request.media.customData || {});
        delete request.media.customData;

        // Prefer retrieving contentId from new request structure, if present
        request.media.entity = ((request.customData.content && request.customData.content.contentId)) ||
          request.customData.contentId ||
          request.media.entity;

        request.media.contentId = request.media.entity; // Needed for Google to consider the request to be valid

        if (
          request.customData.content &&
          request.customData.content.metadata &&
          request.customData.content.metadata.posterImage &&
          request.customData.content.metadata.posterImage.url &&
          request.media.metadata
        ) {
            request.media.metadata.posterImage = request.customData.content.metadata.posterImage.url;
        }

        return request;
    }

    static mapMediaStatusRequest(request, hasStartedPlaying) {
        if (request.media) {
            delete request.media.customData;

            // Remove potential adsResponse from media status since web sender can't handle such large responses (>64kB)
            // https://issuetracker.google.com/issues/132323230
            if (Array.isArray(request.media.breakClips)) { // ToDo: Grab better version of this from old client-side-ads branch (Prio 4)
                for (let i = 0; i < request.media.breakClips.length; i++) {
                    if (request.media.breakClips[i].vastAdsRequest) {
                        request.media.breakClips[i].vastAdsRequest.adsResponse = '';
                    }
                }
            }
        }

        if (request.activeTrackIds && !hasStartedPlaying && request.playerState === 'PLAYING') {
            request = Utils.extend({}, request);
            request.playerState = 'BUFFERING';
        }

        delete request.customData;

        return request;
    }

    static mapPreloadRequest(request) {
        if (request && request.media) {
            if (!request.media.contentId && request.media.entity) {
                request.media.contentId = request.media.entity;
            }

            request.media.contentUrl = 'dummy'; // Needs to be something 'truthy' for CAF to consider the PRELOAD request to be valid
        }

        return request;
    }

    static mapToLoadDto(request, sender) {
        const content = ContentMapper.mapFromLoadRequest(request.customData, request);
        delete request.customData.content;

        const loadDto = CustomDataMapper.mapToLoadDto(request.customData);
        loadDto.content = content;

        if (!loadDto.credentials && request.credentials) {
            loadDto.credentials = {
                token: request.credentials
            };
        }

        loadDto.autoplay = request.autoplay;
        if (Utils.isNumber(request.currentTime)) {
            loadDto.currentTime = request.currentTime;
        }

        if (loadDto.language && loadDto.language.assetLanguage && !content.auxiliaryData.assetLanguage) {
            console.warn('RequestMapper::mapToLoadDto: assetLanguage should be specified in content.auxiliaryData, not language!', request);
            content.auxiliaryData.assetLanguage = loadDto.language.assetLanguage;
        }

        loadDto.sender = sender;

        return loadDto;
    }

    static removeCustomData(request) {
        if (request.media) {
            delete request.media.customData;
        }

        delete request.customData;

        return request;
    }
};