import { PlaybackEventTypes } from "../Constants";
import { PlaybackEventBase } from "./PlaybackEventBaseClass";

type TStartingEventPayload = {
  playbackSessionId: string;
}

export class StartingEvent extends PlaybackEventBase<PlaybackEventTypes.STARTING, TStartingEventPayload> {
  constructor(payload: TStartingEventPayload) {
    super(PlaybackEventTypes.STARTING, payload);
  }
}
