export class ScreenInfo {
    public screenHeight: number;
    public screenWidth: number;

    constructor(args?: ScreenInfo) {
        this.screenHeight = window.screen.availHeight;
        this.screenWidth = window.screen.availWidth;

        if (args) {
            this.screenHeight = args.screenHeight;
            this.screenWidth = args.screenWidth;
        }
    }
};