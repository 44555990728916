import {IQuery, TimeRange} from "../Types";
export type ChannelQueryArgs = {
    id: string
    timeRange?: TimeRange
    limit?: number
}

export class ChannelsMetadataQuery implements IQuery {
    generateBody(): string {
        let start = Date.now();
        let limit = 3;

        const operationName = 'channels'
        const query = `
        query channels {
            channels {
                    channelItems {
                        id
                        name
                        icons {
                            dark {
                                sourceNonEncoded
                            }
                            light {
                                sourceNonEncoded
                            }
                        }
                        playback {
                          play {
                            playbackSpec {
                                videoId
                                videoIdType
                                accessControl
                                watchMode
                            }
                          }
                        }
                        programs (limit: ${limit}, timestamp: ${start}) {
                            programItems {
                                startTime {
                                    timestamp
                                }
                                endTime {
                                    timestamp
                                }
                                media {
                                    ... on Movie {
                                        title
                                    }
                                    ... on Episode {
                                        title
                                    }
                                    ... on SportEvent {
                                        title
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `;
        return JSON.stringify({
            operationName,
            query,
        });
    }
};

export class ChannelMetadataQuery implements IQuery {
    generateBody(queryVariables: ChannelQueryArgs): string {
        let start = null;
        let limit = queryVariables.limit || 2;
        //let duration = null;
        if (queryVariables.timeRange) {
            if (queryVariables.timeRange.start instanceof Date) {
                queryVariables.timeRange.start = queryVariables.timeRange.start.valueOf();
            }
            if (queryVariables.timeRange.end instanceof Date) {
                queryVariables.timeRange.end = queryVariables.timeRange.end.valueOf();
            }

            start = queryVariables.timeRange.start;
            limit = 20;// Math.round(duration  //duration = Math.round(queryVariables.timeRange.end - queryVariables.timeRange.start);
        }

        const operationName = 'channel'
        const query = `
            query channel($id: String!) {
             channel(id: $id) {
              id
              name
              icons {
               dark {
                sourceNonEncoded
               }
               light {
                sourceNonEncoded
               }
              }
              playbackSpec {
               videoId
               videoIdType
               watchMode
               accessControl
              }
              programs(limit: ${(limit >= 1) ? limit : 1}${(start) ? `, timestamp: ${start}` : ''}) {
               programItems {
                id
                startTime {
                 timestamp
                }
                endTime {
                 timestamp
                }
                live
                rerun
                media {
                 __typename
                 ... on Movie {
                  id
                  descriptionLong
                  images {
                   screenshot16x9 {
                    sourceNonEncoded
                   }
                   showcard2x3 {
                    sourceNonEncoded
                   }
                  }
                  title
                  playback {
                    play {
                    linear {
                      item {
                        startover {
                          playbackSpec {
                            videoId
                            videoIdType
                            watchMode
                            accessControl
                          }
                        }
                      }
                    }
                    }
                  }
                 }
                 ... on Episode {
                  id
                  descriptionLong
                  seasonNumber {
                   number
                  }
                  episodeNumber {
                   number
                  }
                  series {
                   title
                  }
                  images {
                   screenshot16x9 {
                    sourceNonEncoded
                   }
                   showcard2x3 {
                    sourceNonEncoded
                   }
                  }
                  title
                  playback {
                    play {
                    linear {
                      item {
                        startover {
                          playbackSpec {
                            videoId
                            videoIdType
                            watchMode
                            accessControl
                          }
                        }
                      }
                    }
                    }
                  }
                 }
                 ... on SportEvent {
                    id
                    descriptionLong
                    images {
                     showcard16x9 {
                      sourceNonEncoded
                     }
                     showcard2x3 {
                      sourceNonEncoded
                     }
                    }
                    title
                    playback {
                      play {
                      linear {
                        item {
                          startover {
                            playbackSpec {
                              videoId
                              videoIdType
                              watchMode
                              accessControl
                            }
                          }
                        }
                      }
                      }
                    }
                 }
                }
               }
              }
             }
            }
        `
        return JSON.stringify({
            operationName,
            query,
            variables: queryVariables
        });
    }
}
