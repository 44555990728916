//
//  Dush.js
//  Built with contempt by Daniel and Lars
//  Copyspite © 09/10/2019 All rights revoked.
//

const {
    DushEvents
} = require('../Events');

const IPlayer = require('./IPlayer');
const LanguageMapper = require('../mappers/LanguageMapper');
const Utils = require('../utils/Utils');

module.exports = class Dush extends IPlayer {
    static get Utils() {
        return {
            CreateVariantTrack: (levelIndex, level, audioIndex, audio = { lang: 'en' }) => {
                return {
                    active: false,
                    audioBandwidth: 0,
                    videoBandwidth: level.bitrate,
                    audioCodec: audio.audioCodec || level.audioCodec,
                    audioId: audioIndex,
                    audioRoles: ['main'],
                    bandwidth: level.bitrate,
                    channelsCount: 2,
                    codecs: `${level.videoCodec}, ${level.audioCodec}`,
                    frameRate: 25,
                    width: level.width,
                    height: level.height,
                    id: 30 + levelIndex,
                    kind: null,
                    label: null,
                    language: audio.lang,
                    mimeType: 'video/mp4',
                    originalTextId: null,
                    primary: true,
                    roles: ['main'],
                    type: 'variant',
                    videoCodec: level.videoCodec,
                    videoId: levelIndex
                }
            },
            TextTrackToVariantTrack: (index, language) => {
                return {
                    active: false,
                    audioBandwidth: null,
                    videoBandwidth: null,
                    audioCodec: null,
                    audioId: null,
                    audioRoles: null,
                    bandwidth: 0,
                    channelsCount: null,
                    codecs: null,
                    frameRate: null,
                    width: null,
                    height: null,
                    id: 1 + index,
                    kind: 'subtitle',
                    label: null,
                    language: language || 'sv',
                    mimeType: 'text/vtt',
                    originalTextId: '' + index,
                    primary: true,
                    roles: [],
                    type: 'text',
                    videoCodec: null,
                    videoId: null
                }
            }
        }
    }

    static isSupported() {
        return 'dashjs' in window;
    }

    constructor({ getNewLicense, license, mediaElement, textContainer }) {
        super({ getNewLicense, license, mediaElement, textContainer });

        this.destroyed = false;

        this.audioLanguageReverseMap = {};
        this.audioTracks = {};
        this.buffering = false;
        this.currentLanguage = 'en';
        this.initialized = false;
        this.live = false;
        this.mediaElementEventListeners = {};
        this.playerEventListeners = {};
        this.shakaConfiguration = {};
        this.selectedSubtitleLanguage = null;
        this.subtitleLanguageReverseMap = {};
        this.trackingBitrateValues = {};
        this.variantTracks = {};
        this.videoTracks = null;

        console.log(`*******************************************************************`);
        console.log(`*** Dush Initialized!!!`);
        console.log(`*******************************************************************`);
    }

    registerEventListeners(resolveLoad) {
        this.listenOnce(this.player, this.playerEventListeners, dashjs.MediaPlayer.events.STREAM_INITIALIZED, () => {
            let audioTracks = this.player.getTracksFor('audio');
            let videoTracks = this.player.getTracksFor('video');

            let counter = 1;
            let audioTracksCounters = {};
            let hasSetInitialLanguage = false;
            for (let a in audioTracks) {
                if (!audioTracks.hasOwnProperty(a)) continue;

                const audioTrack = Utils.extend({}, audioTracks[a]);

                let language = LanguageMapper.mapISOCodeFromAlpha2or3to2(audioTrack.lang);
                audioTracksCounters[language] = 1 + (audioTracksCounters[language] || 0);
                if (audioTracksCounters[language] > 1) {
                    language += `-${audioTracksCounters[language]}`;
                }

                this.audioLanguageReverseMap[language] = audioTrack.lang;

                audioTrack.lang = language;

                if (!hasSetInitialLanguage) {
                    this.currentLanguage = language;
                    hasSetInitialLanguage = true;
                }

                for (let i in videoTracks[0].bitrateList) {
                    if (!videoTracks[0].bitrateList.hasOwnProperty(i)) continue;
                    let level = {
                        videoCodec: 'avc1.4d400d',
                        audioCodec: 'mp4a.40.2',
                        width: videoTracks[0].bitrateList[i].width,
                        height: videoTracks[0].bitrateList[i].height,
                        bitrate: videoTracks[0].bitrateList[i].bandwidth
                    };

                    let vTrack = Dush.Utils.CreateVariantTrack(parseInt(i), level, parseInt(a), audioTrack);
                    this.trackingBitrateValues[parseInt(vTrack.videoBandwidth)] = {
                        bitrate: parseInt(vTrack.videoBandwidth),
                        width: vTrack.width,
                        height: vTrack.height
                    };
                    this.variantTracks[counter] = vTrack;
                    counter++;
                }

                resolveLoad();
            }

            this.videoTracks = videoTracks[0];

            this.emit(DushEvents.BitratesReady, this.trackingBitrateValues);

            this.setInitSubsAndAudio();

            this.setActiveVariantTrack();
        });

        this.listen(this.player, this.playerEventListeners, dashjs.MediaPlayer.events.BUFFER_EMPTY, () => this.buffering = true);
        this.listen(this.player, this.playerEventListeners, dashjs.MediaPlayer.events.BUFFER_LOADED, () => this.buffering = false);

        this.listen(this.player, this.playerEventListeners, dashjs.MediaPlayer.events.ERROR, e => {
            if (!e.event && e.error && e.error.code) {
                switch (e.error.code) {
                    case dashjs.MediaPlayer.errors.DOWNLOAD_ERROR_ID_MANIFEST:
                    case dashjs.MediaPlayer.errors.MANIFEST_LOADER_PARSING_FAILURE_ERROR_CODE:
                    case dashjs.MediaPlayer.errors.MANIFEST_LOADER_LOADING_FAILURE_ERROR_CODE:
                    case dashjs.MediaPlayer.errors.XLINK_LOADER_LOADING_FAILURE_ERROR_CODE:
                    case dashjs.MediaPlayer.errors.SEGMENTS_UPDATE_FAILED_ERROR_CODE:
                    case dashjs.MediaPlayer.errors.SEGMENTS_UNAVAILABLE_ERROR_CODE:
                    case dashjs.MediaPlayer.errors.SEGMENT_BASE_LOADER_ERROR_CODE:
                    case dashjs.MediaPlayer.errors.TIME_SYNC_FAILED_ERROR_CODE:
                    case dashjs.MediaPlayer.errors.FRAGMENT_LOADER_LOADING_FAILURE_ERROR_CODE:
                    case dashjs.MediaPlayer.errors.FRAGMENT_LOADER_NULL_REQUEST_ERROR_CODE:
                    case dashjs.MediaPlayer.errors.URL_RESOLUTION_FAILED_GENERIC_ERROR_CODE:
                    case dashjs.MediaPlayer.errors.APPEND_ERROR_CODE:
                    case dashjs.MediaPlayer.errors.REMOVE_ERROR_CODE:
                    case dashjs.MediaPlayer.errors.DATA_UPDATE_FAILED_ERROR_CODE:
                    case dashjs.MediaPlayer.errors.CAPABILITY_MEDIASOURCE_ERROR_CODE:
                    case dashjs.MediaPlayer.errors.CAPABILITY_MEDIAKEYS_ERROR_CODE:
                    case dashjs.MediaPlayer.errors.DOWNLOAD_ERROR_ID_SIDX:
                    case dashjs.MediaPlayer.errors.DOWNLOAD_ERROR_ID_CONTENT:
                    case dashjs.MediaPlayer.errors.DOWNLOAD_ERROR_ID_INITIALIZATION:
                    case dashjs.MediaPlayer.errors.DOWNLOAD_ERROR_ID_XLINK:
                    case dashjs.MediaPlayer.errors.MANIFEST_ERROR_ID_CODEC:
                    case dashjs.MediaPlayer.errors.MANIFEST_ERROR_ID_PARSE:
                    case dashjs.MediaPlayer.errors.MANIFEST_ERROR_ID_NOSTREAMS:
                    case dashjs.MediaPlayer.errors.TIMED_TEXT_ERROR_ID_PARSE:
                    case dashjs.MediaPlayer.errors.MSS_NO_TFRF_CODE:
                    case dashjs.MediaPlayer.errors.MEDIA_KEYERR_CODE:
                    case dashjs.MediaPlayer.errors.MEDIA_KEYERR_UNKNOWN_CODE:
                    case dashjs.MediaPlayer.errors.MEDIA_KEYERR_CLIENT_CODE:
                    case dashjs.MediaPlayer.errors.MEDIA_KEYERR_SERVICE_CODE:
                    case dashjs.MediaPlayer.errors.MEDIA_KEYERR_OUTPUT_CODE:
                    case dashjs.MediaPlayer.errors.MEDIA_KEYERR_HARDWARECHANGE_CODE:
                    case dashjs.MediaPlayer.errors.MEDIA_KEYERR_DOMAIN_CODE:
                        break;
                }
            }
        });

        this.listen(this.player, this.playerEventListeners, dashjs.MediaPlayer.events.QUALITY_CHANGE_REQUESTED, () => {
            let variantTrack = this.setActiveVariantTrack();
            if (variantTrack && variantTrack.videoBandwidth) {
                this.emit('adaptation', variantTrack.videoBandwidth);
            }
        });
    }

    unregisterEventListeners() {
        Object.keys(this.mediaElementEventListeners).forEach(e =>
          this.mediaElement.removeEventListener(e,
            this.mediaElementEventListeners[e]
          )
        );
        this.mediaElementEventListeners = {};

        Object.keys(this.playerEventListeners).forEach(e =>
          this.player.off(e,
            this.playerEventListeners[e]
          )
        );
        this.playerEventListeners = {};
    };

    setInitSubsAndAudio() {
        if (this.mediaElement && this.mediaElement.textTracks && this.mediaElement.textTracks.length) {
            for (let i = 0; i < this.mediaElement.textTracks.length; i++) {
                if (this.mediaElement.textTracks[i].mode === 'disabled') continue;

                const language = LanguageMapper.mapISOCodeFromAlpha2or3to2(this.mediaElement.textTracks[i].language);
                this.subtitleLanguageReverseMap[language] = this.mediaElement.textTracks[i].language;

                if (!this.shakaConfiguration.preferredTextLanguage || !this.player.isTextEnabled()) continue;

                this.mediaElement.textTracks[i].mode = 'hidden';
                if (language === this.shakaConfiguration.preferredTextLanguage) {
                    this.mediaElement.textTracks[i].mode = 'showing';
                    this.player.setTextTrack(i);
                }
            }
        }

        this.selectAudioLanguage(this.shakaConfiguration.preferredAudioLanguage);
    }

    load(source, startTime) {
        return new Promise(resolve => {
            if (typeof (startTime) === 'number') {
                source += '#s=' + startTime;
            }

            this.player = dashjs.MediaPlayer().create();
            this.player.updateSettings({
                debug: {
                    logLevel: dashjs.Debug.LOG_LEVEL_ERROR
                },
                streaming: {
                    fastSwitchEnabled: true,
                    bufferToKeep: 5,
                    bufferAheadToKeep: 20,
                    bufferTimeAtTopQuality: 20,
                    bufferTimeAtTopQualityLongForm: 20,
                    stableBufferTime: 5,
                    metricsMaxListDepth: 100,
                    retryAttempts: {
                        MPD: 10
                    }
                }
            });
            this.registerEventListeners(resolve);
            this.player.initialize(this.mediaElement, source, true);
            if (this.textContainer) {
                this.player.attachTTMLRenderingDiv(this.textContainer);
            }
            this.player.displayCaptionsOnTop(true);

            if (this.license && this.license.server && this.license.protectionSystem) {
                this.player.setProtectionData({
                    [this.license.protectionSystem]: {
                        serverURL: this.license.server,
                        httpRequestHeaders: this.license.headers || {}
                    }
                });
            }
        });
    }

    configure(configOrKey, value) {
        if (Utils.isString(configOrKey)) {
            configOrKey = Utils.pathToObject(configOrKey, value);
        }

        if (!this.isShakaConfiguration(configOrKey)) {
            // ToDo: Update dash.js configuration
        } else {
            // The 'unsetting' of shaka restrictions requires the ability to set values to undefined
            this.shakaConfiguration = Utils.extend(/* allowUndefinedValues */ true, {}, this.shakaConfiguration || {}, configOrKey);
        }
    }

    getConfiguration() {
        return this.shakaConfiguration;
    }

    getManifest() {
        return {
            //presentationTimeline: null,
            //periods: null,
            //offlineSessionIds: [],
            //minBufferTime: 0
        };
    }

    getNetworkingEngine() {
        return this.networkingEngine;
    }

    getTextTracks() {
        let textTracks = [];
        if (this.mediaElement && this.mediaElement.textTracks && this.mediaElement.textTracks.length) {
            for (let i = 0; i < this.mediaElement.textTracks.length; i++) {
                if (this.mediaElement.textTracks[i].mode === 'disabled') continue;

                if (this.mediaElement.textTracks[i].kind === 'subtitles' || this.mediaElement.textTracks[i].kind === 'captions') {
                    const language = LanguageMapper.mapISOCodeFromAlpha2or3to2(this.mediaElement.textTracks[i].language);
                    textTracks.push(Dush.Utils.TextTrackToVariantTrack(i, language));
                }
            }
        }
        return textTracks;
    }

    getVariantTracks() {
        return Object.values(this.variantTracks);
    }

    isBuffering() {
        return this.buffering;
    }

    isInProgress() { // Not applicable for HLS, always false
        return false;
    }

    isLive() {
        return this.live;
    }

    seekRange() {
        return {
            start: 0,
            end: (isNaN(this.mediaElement.duration)) ? 0 : this.mediaElement.duration
        };
    }

    selectAudioLanguage(language) {
        let audioTracks = this.player.getTracksFor('audio');
        for (let i in audioTracks) {
            if (!audioTracks.hasOwnProperty(i)) continue;

            if (audioTracks[i].lang === this.audioLanguageReverseMap[language]) {
                console.log(`Dush::selectAudioLanguage: Language: ${this.currentLanguage}(${this.audioLanguageReverseMap[this.currentLanguage]}) -> ${language}(${this.audioLanguageReverseMap[language]})`);
                this.player.setCurrentTrack(audioTracks[i]);
                this.currentLanguage = language;
            }
        }
    }

    selectTextTrack(track) {
        if (this.mediaElement && this.mediaElement.textTracks && this.mediaElement.textTracks.length) {
            for (let i = 0; i < this.mediaElement.textTracks.length; i++) {
                if (this.mediaElement.textTracks[i].mode === 'disabled') continue;

                this.mediaElement.textTracks[i].mode = 'hidden';
                const language = LanguageMapper.mapISOCodeFromAlpha2or3to2(this.mediaElement.textTracks[i].language);
                if (language === track.language) {
                    this.selectedSubtitleLanguage = track.language;
                    this.mediaElement.textTracks[i].mode = 'showing';
                    this.player.setTextTrack(i);
                }
            }
        }
    }

    setActiveVariantTrack() {
        let active = null;
        let currentLevel = this.player.getQualityFor('video');
        if (Object.keys(this.variantTracks).length > 0 && typeof (currentLevel) === 'number') {
            let currentBitrate = this.videoTracks.bitrateList['' + currentLevel].bandwidth;
            for (let i in this.variantTracks) {
                if (!this.variantTracks.hasOwnProperty(i)) continue;

                if (
                  this.variantTracks[i].videoBandwidth === currentBitrate &&
                  this.variantTracks[i].language === this.currentLanguage
                ) {
                    this.variantTracks[i].active = true;
                    active = this.variantTracks[i];
                } else {
                    this.variantTracks[i].active = false
                }
            }
        }

        return active;
    }

    setTextTrackVisibility(visible) {
        this.player.enableText(false);
        this.player.enableText(visible);

        if (this.mediaElement && this.mediaElement.textTracks && this.mediaElement.textTracks.length) {
            for (let i = 0; i < this.mediaElement.textTracks.length; i++) {
                if (this.mediaElement.textTracks[i].mode === 'disabled') continue;

                this.mediaElement.textTracks[i].mode = 'hidden';
                const language = LanguageMapper.mapISOCodeFromAlpha2or3to2(this.mediaElement.textTracks[i].language);
                if (visible && language === this.selectedSubtitleLanguage) {
                    this.mediaElement.textTracks[i].mode = 'showing';
                    this.player.setTextTrack(i);
                }
            }
        }

        return Promise.resolve();
    }

    reset() {
    }

    destroy() {
        this.destroyed = true;

        return new Promise((resolve) => {
            this.removeAllListeners();

            this.unregisterEventListeners();

            if (this.player) {
                this.player.reset();
            }

            for (let i = 0; i < this.mediaElement.textTracks.length; i++) {
                const track = this.mediaElement.textTracks[i];
                track.mode = 'disabled';
            }

            this.audioLanguageReverseMap = {};
            this.audioTracks = {};
            this.buffering = false;
            this.currentLanguage = 'en';
            this.initialized = false;
            this.license = null;
            this.live = false;
            this.player = null;
            this.shakaConfiguration = {};
            this.selectedSubtitleLanguage = null;
            this.subtitleLanguageReverseMap = {};
            this.textContainer = null;
            this.trackingBitrateValues = {};
            this.variantTracks = {};
            this.videoTracks = null;

            resolve();
        });
    }
};