import { PlaybackEventBase } from './PlaybackEventBaseClass';
import { PlaybackEventTypes } from "../Constants";

type TStreamInfoEventPayload = {
    contentUrl: string;
}

export class StreamInfoEvent extends PlaybackEventBase<PlaybackEventTypes.STREAM_INFO, TStreamInfoEventPayload> {
    constructor(payload: TStreamInfoEventPayload) {
        super(PlaybackEventTypes.STREAM_INFO, payload);
    }
}
