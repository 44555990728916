const { ErrorCategories, ErrorCodes, ErrorOrigins } = require('../../Constants');
const { NormalizedError, ReceiverError } = require('../../dtos/');
const {
    generateErrorCode,
    mapFinalError,
    mapToEnumerableError,
    mapToNormalizedError
} = require('../../mappers/ErrorMapper');

const Utils = require('../../utils/Utils');
const UiManager = require('../ui/UiManager');

module.exports = class ErrorManager {
    /**
     * @param {object} config
     * @param {Function} handleError
     * @param {Function} handleFinalError
     * @param {UiManager} uiManager
     */
    constructor({
                    config, handleError = e => e, handleFinalError = e => e, terminate = () => {
        }, uiManager
                }) {
        this.config = config;
        this.handleError = handleError;
        this.handleFinalError = handleFinalError;
        this.terminate = terminate;
        this.uiManager = uiManager;

        this.content = null;
        this.reloadAbortable = false;
        this.reloadPending = false;
        this.reloadTimeout = null;
        this.terminatePending = false;
        this.terminateTimeout = null;
        this.stack = [];
        this.windowEventListeners = {};

        this.registerUncaughtErrorHandlers();
    }

    /** @private */
    matchesNormalizedError(error) {
        return error &&
          ![ // Errors from these origins should always proceed to the normalize mapper
              ErrorOrigins.Caf.Context,
              ErrorOrigins.Caf.PlayerManager,
              ErrorOrigins.Receiver.Dush,
              ErrorOrigins.Receiver.MediaElement,
              ErrorOrigins.Receiver.ShakaWacka,
              ErrorOrigins.Receiver.Wacka,
              ErrorOrigins.Window.Error,
              ErrorOrigins.Window.Rejection
          ].includes(error.origin) &&
          !!(error.category && error.code && error.hasOwnProperty('fatal'));
    }

    clearError() {
        if (!this.uiManager || !this.uiManager.ready || this.terminatePending) {
            return;
        }

        if (this.reloadTimeout) {
            clearTimeout(this.reloadTimeout);
        }

        this.reloadAbortable = false;
        this.reloadPending = false;
        this.reloadTimeout = null;
    }

    dispatchError(error = {}) {
        if (this.reloadPending || this.terminatePending) return; // Ignoring errors after having received a fatal one

        // Make error properties enumerable to not lose information when further extending/stringifying
        error = mapToEnumerableError(error);

        // Gather ReceiverError properties
        const receiverErrorProperties = {};

        receiverErrorProperties.originalError = error;

        receiverErrorProperties.stackedErrors = Array.prototype.slice.call(this.stack);
        this.stack = [];

        if (this.matchesNormalizedError(error)) {
            receiverErrorProperties.normalizedError = new NormalizedError(error);
            receiverErrorProperties.fatal = error.fatal;
        } else if (this.matchesNormalizedError(error.normalizedError)) {

            receiverErrorProperties.normalizedError = new NormalizedError(error.normalizedError);
            receiverErrorProperties.fatal = Utils.booleanOrDefault(
              error.normalizedError.fatal,
              Utils.booleanOrDefault(error.fatal, false)
            );
        } else {
            receiverErrorProperties.fatal = Utils.booleanOrDefault(error.fatal, false);

            try {
                receiverErrorProperties.normalizedError = mapToNormalizedError(error);
                receiverErrorProperties.fatal = Utils.booleanOrDefault(
                  receiverErrorProperties.normalizedError && receiverErrorProperties.normalizedError.fatal,
                  Utils.booleanOrDefault(error.fatal, false)
                );
            } catch (e) {
                receiverErrorProperties.stackedErrors.unshift(e);
            }
        }

        receiverErrorProperties.content = error.content || this.content || {};
        receiverErrorProperties.code = Utils.stringOrDefault(error.code);
        receiverErrorProperties.message = Utils.stringOrDefault(error.message);

        if (receiverErrorProperties.normalizedError && receiverErrorProperties.normalizedError.code) {
            if (!receiverErrorProperties.code) {
                receiverErrorProperties.code = receiverErrorProperties.normalizedError.code;
            }

            if (!receiverErrorProperties.message) {
                receiverErrorProperties.message = receiverErrorProperties.normalizedError.code;
            }
        }

        receiverErrorProperties.stack = Utils.stringOrDefault(error.stack);
        if (!receiverErrorProperties.stack) {
            try {
                throw new Error();
            } catch (e) {
                receiverErrorProperties.stack = e.stack;
            }
        }

        const receiverError = new ReceiverError({
            code: receiverErrorProperties.code,
            content: receiverErrorProperties.content,
            displayMessage: Utils.stringOrDefault(error.displayMessage),
            fatal: receiverErrorProperties.fatal,
            handled: Utils.booleanOrDefault(error.handled, false),
            message: receiverErrorProperties.message,
            notify: Utils.booleanOrDefault(error.notify, false),
            notificationDuration: Utils.numberOrDefault(error.notificationDuration, 10000),
            normalizedError: receiverErrorProperties.normalizedError,
            origin: Utils.stringOrDefault(error.origin),
            originalError: receiverErrorProperties.originalError,
            reload: Utils.booleanOrDefault(error.reload, true),
            reloadAbortable: Utils.booleanOrDefault(error.reloadAbortable, false),
            reloadDelay: Utils.numberOrDefault(error.reloadDelay, this.config.receiver.reloadDelay),
            stack: receiverErrorProperties.stack,
            stackedErrors: receiverErrorProperties.stackedErrors,
            terminate: Utils.booleanOrDefault(error.terminate, false),
            terminateDelay: Utils.numberOrDefault(error.terminateDelay, this.config.receiver.terminateDelay),
        });

        const handleFinalError = finalError => {
            finalError = mapFinalError(finalError);

            /**
             * Await handling of final error/shutdown?
             * handleFinalError returns a promise - if that promise is still pending when it is time
             * to reload then maybe wait some additional time before reloading? Alternatively, ask service layer if it
             * wants to extend the time until reload for a while (not too long though).
             *
             * At the moment we are purposefully not awaiting this to ensure that we don't end up with the application
             * in an error state that it will never get out of.
             */
            this.handleFinalError(finalError);

            this.displayErrorAndReloadOrTerminate(finalError);

            return finalError;
        };

        return this.handleError(receiverError)
          .then(handledError => {
              if (handledError) {
                  Utils.extend(receiverError, handledError);
              }
              return handleFinalError(receiverError);
          })
          .catch(e => {
              receiverError.handled = false;
              receiverError.stackedErrors.unshift({
                  category: ErrorCategories.Default,
                  code: generateErrorCode('ErrorManager', ErrorCategories.Default, 'FailedToHandleError'),
                  details: mapToEnumerableError(e),
                  fatal: receiverError.fatal
              });

              return handleFinalError(receiverError);
          });
    }

    displayErrorAndReloadOrTerminate(error) {
        if (this.config.receiver.debug && error.code) {
            error.displayMessage = error.displayMessage || '';
            error.displayMessage += `<br /><br /><div class="code">${error.code}</div>`;
        }

        if (!error.fatal) {
            if (error.notify && error.displayMessage) {
                this.uiManager.showNotification(error.displayMessage, error.notificationDuration);
            }

            return;
        }

        const shouldDisplay = !Utils.booleanOrDefault(error.handled, false);
        if (shouldDisplay) {
            this.uiManager.showError(error.displayMessage);
        }

        const shouldReload = Utils.booleanOrDefault(error.reload, true);
        const shouldTerminate = Utils.booleanOrDefault(error.terminate, false);
        if ((shouldReload || shouldTerminate) && this.config.receiver.debug) {
            console.debug(error);
            debugger;
        }

        if (shouldTerminate) {
            if (this.config.receiver.debug) return;

            const terminateDelay = Utils.numberOrDefault(error.terminateDelay, this.config.receiver.terminateDelay);
            this.terminatePending = true;
            this.terminateTimeout = Utils.defer(() => this.terminate(), terminateDelay);
        } else if (shouldReload) {
            this.reloadAbortable = Utils.booleanOrDefault(error.reloadAbortable, false);
            this.reloadPending = true;
            const reloadDelay = Utils.numberOrDefault(error.reloadDelay, this.config.receiver.reloadDelay);
            this.reloadTimeout = Utils.defer(() => window.location.reload(true), reloadDelay);
        }
    }

    registerUncaughtErrorHandlers() {
        const handleUnhandled = (e, code, origin) => {
            e = mapToEnumerableError(e);
            Utils.extend(e, {
                code,
                fatal: false,
                origin
            });

            this.dispatchError(e);
            return false;
        };

        const unhandledErrorHandler = e => handleUnhandled(e, ErrorCodes.UncaughtError, ErrorOrigins.Window.Error);
        window.addEventListener(
          'error',
          this.windowEventListeners['error'] = unhandledErrorHandler
        );

        const unhandledRejectionHandler = e => handleUnhandled(e, ErrorCodes.UncaughtRejection, ErrorOrigins.Window.Rejection);
        window.addEventListener(
          'unhandledrejection',
          this.windowEventListeners['unhandledrejection'] = unhandledRejectionHandler
        );
    }

    unregisterUncaughtErrorHandlers() {
        Object.keys(this.windowEventListeners).forEach(e =>
          window.removeEventListener(e,
            this.windowEventListeners[e]
          )
        );
        this.windowEventListeners = {};
    }

    setContent(content) {
        if (content) {
            this.content = {
                accessControl: content.accessControl,
                auxiliaryData: content.auxiliaryData,
                contentId: content.contentId,
                contentType: content.contentType,
                serviceCountry: content.serviceCountry,
                watchMode: content.watchMode
            };
        }
    }

    stackError(error) {
        error = mapToEnumerableError(error);

        this.stack.unshift(error);
    }

    reset() {
    }

    destroy() {
        this.reset();

        this.unregisterUncaughtErrorHandlers();

        this.config = null;
        this.handleError = e => e;
        this.handleFinalError = e => e;
        this.terminate = () => {
        };
        this.uiManager = null;

        this.content = null;
        this.reloadAbortable = false;
        this.reloadPending = false;
        this.reloadTimeout = null;
        this.terminatePending = false;
        this.terminateTimeout = null;
        this.stack = [];
    }
};