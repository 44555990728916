const EventEmitter = require('eventemitter3');
const { FetchEvents, NetworkEvents, XhrEvents } = require('../../Events');
const FetchInterceptor = require('./FetchInterceptor');
const XhrInterceptor = require('./XhrInterceptor');

let instance;
module.exports = class NetworkInterceptor extends EventEmitter {
    static getInstance() {
        if (!instance) {
            instance = new NetworkInterceptor();
        }

        return instance;
    }

    constructor() {
        super();

        this.fetchInterceptor = FetchInterceptor.getInstance();
        this.xhrInterceptor = XhrInterceptor.getInstance();
    }

    intercept() {
        this.fetchInterceptor.on(FetchEvents.CdnDiscovered, (...args) => this.emit(NetworkEvents.CdnDiscovered, ...args));
        this.xhrInterceptor.on(XhrEvents.CdnDiscovered, (...args) => this.emit(NetworkEvents.CdnDiscovered, ...args));

        this.fetchInterceptor.intercept();
        this.xhrInterceptor.intercept();

        return this;
    }

    reset() {
        this.removeAllListeners();
    }

    destroy() {
        this.reset();

        this.fetchInterceptor.destroy();
        this.xhrInterceptor.destroy();

        instance = null;
        this.fetchInterceptor = null;
        this.xhrInterceptor = null;
    }
};