//
//  ShakaWackaRestrictionHelper.js
//
//  Created by Lars Rothaus on 01/06/2021.
//  Copyright © 01/06/2021 Lars Rothaus. All rights reserved.
//
const { RestrictionHelper, Level } = require('./RestrictionHelper');

class ShakaWackaRestrictionHelper extends RestrictionHelper {

    constructor() {
        super();
        this.useSoftRestrictions = true;

    }

    _setMaxLevel(level, restriction) {
        let restrictionShakaConfig = {
            maxWidth: undefined,
            maxHeight: undefined,
            maxFrameRate: undefined,
            maxBandwidth: undefined
        }
        if (level && typeof level.index === 'number') {
            if (restriction.width.max) {
                restrictionShakaConfig['maxWidth'] = level.width;
            }
            if (restriction.height.max) {
                restrictionShakaConfig['maxHeight'] = level.height;
            }
            if (restriction.fps.max) {
                restrictionShakaConfig['maxFrameRate'] = level.fps;
            }
            if (restriction.bandwidth.max) {
                restrictionShakaConfig['maxBandwidth'] = level.bandwidth;
            }
        }
        if (this.useSoftRestrictions) {
            restrictionShakaConfig = {
                abr: {
                    restrictions: restrictionShakaConfig
                }
            }
        } else {
            restrictionShakaConfig = {
                restrictions: restrictionShakaConfig
            }
        }

        console.log(`-------= setting max restriction =-------`);
        console.log(restrictionShakaConfig);
        this.player.configure(restrictionShakaConfig)
    }

    /*
    minWidth
    maxWidth
    minHeight
    maxHeight
    minPixels
    maxPixels
    minFrameRate
    maxFrameRate
    minBandwidth
    maxBandwidth
    */

    getLevels() {
        let convertedLevels = [];
        if (this.player && this.player.getVariantTracks()) {
            let levels = this.player.getVariantTracks();
            for (let i in levels) {
                convertedLevels.push(new Level({
                    index: levels[i].id,
                    width: levels[i].width,
                    height: levels[i].height,
                    bitrate: levels[i].bandwidth,
                    bandwidth: Math.floor((levels[i].bandwidth / 8) / 1024),
                    fps: levels[i].frameRate || -1
                }));
            }
        }
        return convertedLevels;
    }
}

module.exports = ShakaWackaRestrictionHelper;
/*
{
  "id": 0,
  "active": false,
  "type": "variant",
  "bandwidth": 308843,
  "language": "und",
  "label": null,
  "kind": null,
  "width": 384,
  "height": 216,
  "frameRate": 25,
  "pixelAspectRatio": "1:1",
  "mimeType": "video/mp4",
  "codecs": "avc1.4D400D, mp4a.40.2",
  "audioCodec": "mp4a.40.2",
  "videoCodec": "avc1.4D400D",
  "primary": true,
  "roles": [
  "main"
],
  "audioRoles": [
  "main"
],
  "videoId": 6,
  "audioId": 1,
  "channelsCount": 2,
  "audioSamplingRate": 48000,
  "audioBandwidth": 128000,
  "videoBandwidth": 180843,
  "originalVideoId": "video=180843",
  "originalAudioId": "audio=128000",
  "originalTextId": null
}
*/