module.exports = class ShakaManifestInfoHandler {
    constructor({
                    manifestInfoHandler = () => {
                    }
                } = {}) {
        this.canHandle = true;
        this.manifestInfoHandler = manifestInfoHandler;
        this.textDecoder = null;
        this.textEncoder = null;

        try {
            this.textDecoder = new TextDecoder();
            this.textEncoder = new TextEncoder();
        } catch (e) {
            this.canHandle = false;
        }
    }

    responseFilter() {
        return (type, response) => {
            // type: 0 === shaka.net.NetworkingEngine.html.RequestType.Manifest
            if (!this.canHandle || type !== 0) return response;

            try {
                const manifestStr = this.textDecoder.decode(response.data);
                const parser = new DOMParser();
                const manifestDoc = parser.parseFromString(manifestStr, 'application/xml');
                const mpd = manifestDoc.querySelector('MPD');

                const getAttribute = (attributeName, src, asType) => {
                    let value = src.getAttribute(attributeName);
                    if (!value) return null;

                    if (asType === 'int') {
                        if (value.includes('/') && value.length < 5) {
                            value = eval(value);
                        } else {
                            value = parseInt(value, 10);
                        }
                    }

                    return value;
                };

                const manifestInfo = {
                    minBufferTime: getAttribute('minBufferTime', mpd),
                    type: getAttribute('type', mpd),
                    representations: []
                };
                const representations = mpd.querySelectorAll('Representation');
                for (let r in representations) {
                    if (!representations.hasOwnProperty(r) || !getAttribute('width', representations[r], 'int')) continue;

                    const representation = representations[r];
                    manifestInfo.representations.push({
                        id: getAttribute('id', representation),
                        codecs: getAttribute('codecs', representation),
                        bandwidth: getAttribute('bandwidth', representation, 'int'),
                        width: getAttribute('width', representation, 'int'),
                        height: getAttribute('height', representation, 'int'),
                        frameRate: getAttribute('frameRate', representation, 'int')
                    });
                }

                this.manifestInfoHandler(manifestInfo);
            } catch (e) {
            } // Ignore errors and return unmodified manifest if modifications fail

            return response;
        };
    }
};