const { ErrorCategories, ErrorCodes, ErrorOrigins } = require('../Constants');

const { NormalizedError, ReceiverError, ServiceLayerError } = require('../dtos/');

const I18n = require('../i18n/I18n');
const Utils = require('../utils/Utils');

const mapDomain = domain => Object.values(ErrorCategories).includes(domain) ? domain.replace('ErrorCategories.', '') : domain;

const generateErrorCode = (origin, domain, code) => `${origin}:${mapDomain(domain)}:${code}`;

const generateCafPlayerManagerErrorCode = (domain, code) => generateErrorCode('CafPlayerManager', domain, code);

const getCafPlayerManagerErrorCode = (code) => {
    const CafPlayerManagerErrors = cast.framework.events.DetailedErrorCode;
    const CafPlayerManagerErrorMap = {
        [CafPlayerManagerErrors.MEDIA_UNKNOWN]: generateCafPlayerManagerErrorCode(ErrorCategories.Media, 'MediaUnknown'),
        [CafPlayerManagerErrors.MEDIA_ABORTED]: generateCafPlayerManagerErrorCode(ErrorCategories.Media, 'MediaAborted'),
        [CafPlayerManagerErrors.MEDIA_DECODE]: generateCafPlayerManagerErrorCode(ErrorCategories.Media, 'MediaDecode'),
        [CafPlayerManagerErrors.MEDIA_NETWORK]: generateCafPlayerManagerErrorCode(ErrorCategories.Network, 'MediaNetwork'),
        [CafPlayerManagerErrors.MEDIA_SRC_NOT_SUPPORTED]: generateCafPlayerManagerErrorCode(ErrorCategories.Media, 'MediaSrcNotSupported'),
        [CafPlayerManagerErrors.SOURCE_BUFFER_FAILURE]: generateCafPlayerManagerErrorCode(ErrorCategories.Media, 'SourceBufferError'),
        [CafPlayerManagerErrors.MEDIAKEYS_UNKNOWN]: generateCafPlayerManagerErrorCode(ErrorCategories.Drm, 'MediaKeysUnknown'),
        [CafPlayerManagerErrors.MEDIAKEYS_NETWORK]: generateCafPlayerManagerErrorCode(ErrorCategories.Drm, 'MediaKeysNetwork'),
        [CafPlayerManagerErrors.MEDIAKEYS_UNSUPPORTED]: generateCafPlayerManagerErrorCode(ErrorCategories.Drm, 'MediaKeysNotSupported'),
        [CafPlayerManagerErrors.MEDIAKEYS_WEBCRYPTO]: generateCafPlayerManagerErrorCode(ErrorCategories.Drm, 'MediaKeysWebCrypto'),
        [CafPlayerManagerErrors.NETWORK_UNKNOWN]: generateCafPlayerManagerErrorCode(ErrorCategories.Network, 'Unknown'),
        [CafPlayerManagerErrors.SEGMENT_NETWORK]: generateCafPlayerManagerErrorCode(ErrorCategories.Network, 'SegmentError'),
        [CafPlayerManagerErrors.HLS_NETWORK_MASTER_PLAYLIST]: generateCafPlayerManagerErrorCode(ErrorCategories.Network, 'HlsMasterPlaylistError'),
        [CafPlayerManagerErrors.HLS_NETWORK_PLAYLIST]: generateCafPlayerManagerErrorCode(ErrorCategories.Network, 'HlsVariantPlaylistError'),
        [CafPlayerManagerErrors.HLS_NETWORK_NO_KEY_RESPONSE]: generateCafPlayerManagerErrorCode(ErrorCategories.Network, 'HlsNoKeyResponse'),
        [CafPlayerManagerErrors.HLS_NETWORK_KEY_LOAD]: generateCafPlayerManagerErrorCode(ErrorCategories.Network, 'HlsKeyLoadError'),
        [CafPlayerManagerErrors.HLS_NETWORK_INVALID_SEGMENT]: generateCafPlayerManagerErrorCode(ErrorCategories.Network, 'HlsInvalidSegment'),
        [CafPlayerManagerErrors.HLS_SEGMENT_PARSING]: generateCafPlayerManagerErrorCode(ErrorCategories.Media, 'HlsSegmentParsingError'),
        [CafPlayerManagerErrors.DASH_NETWORK]: generateCafPlayerManagerErrorCode(ErrorCategories.Network, 'DashUnknown'),
        [CafPlayerManagerErrors.DASH_NO_INIT]: generateCafPlayerManagerErrorCode(ErrorCategories.Media, 'DashNoInitSegment'),
        [CafPlayerManagerErrors.SMOOTH_NETWORK]: generateCafPlayerManagerErrorCode(ErrorCategories.Network, 'SmoothUnknown'),
        [CafPlayerManagerErrors.SMOOTH_NO_MEDIA_DATA]: generateCafPlayerManagerErrorCode(ErrorCategories.Media, 'SmoothNoMediaData'),
        [CafPlayerManagerErrors.MANIFEST_UNKNOWN]: generateCafPlayerManagerErrorCode(ErrorCategories.Media, 'ManifestUnknown'),
        [CafPlayerManagerErrors.HLS_MANIFEST_MASTER]: generateCafPlayerManagerErrorCode(ErrorCategories.Media, 'HlsMasterPlaylistError'),
        [CafPlayerManagerErrors.HLS_MANIFEST_PLAYLIST]: generateCafPlayerManagerErrorCode(ErrorCategories.Media, 'HlsVariantPlaylistError'),
        [CafPlayerManagerErrors.DASH_MANIFEST_UNKNOWN]: generateCafPlayerManagerErrorCode(ErrorCategories.Media, 'DashManifestUnknown'),
        [CafPlayerManagerErrors.DASH_MANIFEST_NO_PERIODS]: generateCafPlayerManagerErrorCode(ErrorCategories.Media, 'DashManifestNoPeriods'),
        [CafPlayerManagerErrors.DASH_MANIFEST_NO_MIMETYPE]: generateCafPlayerManagerErrorCode(ErrorCategories.Media, 'DashManifestNoMimeType'),
        [CafPlayerManagerErrors.DASH_INVALID_SEGMENT_INFO]: generateCafPlayerManagerErrorCode(ErrorCategories.Media, 'DashInvalidSegment'),
        [CafPlayerManagerErrors.SMOOTH_MANIFEST]: generateCafPlayerManagerErrorCode(ErrorCategories.Media, 'SmoothManifestError'),
        [CafPlayerManagerErrors.SEGMENT_UNKNOWN]: generateCafPlayerManagerErrorCode(ErrorCategories.Media, 'SegmentUnknown'),
        [CafPlayerManagerErrors.TEXT_UNKNOWN]: generateCafPlayerManagerErrorCode(ErrorCategories.Media, 'TextUnknown'),
        [CafPlayerManagerErrors.APP]: generateCafPlayerManagerErrorCode(ErrorCategories.Media, 'AppError'),
        [CafPlayerManagerErrors.BREAK_CLIP_LOADING_ERROR]: generateCafPlayerManagerErrorCode(ErrorCategories.Advertisement, 'LoadError'),
        [CafPlayerManagerErrors.BREAK_SEEK_INTERCEPTOR_ERROR]: generateCafPlayerManagerErrorCode(ErrorCategories.Advertisement, 'SeekError'),
        [CafPlayerManagerErrors.IMAGE_ERROR]: generateCafPlayerManagerErrorCode(ErrorCategories.Media, 'ImageError'),
        [CafPlayerManagerErrors.LOAD_INTERRUPTED]: generateCafPlayerManagerErrorCode(ErrorCategories.Media, 'LoadInterrupted'),
        [CafPlayerManagerErrors.LOAD_FAILED]: generateCafPlayerManagerErrorCode(ErrorCategories.Media, 'LoadError'),
        [CafPlayerManagerErrors.MEDIA_ERROR_MESSAGE]: generateCafPlayerManagerErrorCode(ErrorCategories.Media, 'MediaErrorMessage'),
        [CafPlayerManagerErrors.GENERIC]: generateCafPlayerManagerErrorCode(ErrorCategories.Media, 'Generic')
    };

    return CafPlayerManagerErrorMap[code] || generateCafPlayerManagerErrorCode(ErrorCategories.Media, 'Unidentified');
};

const NativeErrorMap = {
    0: 'NATIVE_UNKNOWN',
    1: 'MEDIA_ERR_ABORTED',
    2: 'MEDIA_ERR_NETWORK',
    3: 'MEDIA_ERR_DECODE',
    4: 'MEDIA_ERR_SRC_NOT_SUPPORTED',
    5: 'MEDIA_ERR_ENCRYPTED'
};

const NonFatalCafPlayerManagerErrorCodes = [
    901,
    902
];

const NonFatalShakaErrorCodeKeys = [
    'INVALID_TEXT_HEADER',
    'INVALID_TEXT_CUE',
    'UNABLE_TO_DETECT_ENCODING',
    'BAD_ENCODING',
    'INVALID_XML',
    'INVALID_TTML',
    'INVALID_MP4_TTML',
    'INVALID_MP4_VTT'
];

const mapDushError = e => { // ToDo: Map Dash.Js errors (Prio 5)
    const category = ErrorCategories.Media;
    const code = generateErrorCode('Dush', ErrorCategories.Media, 'Unidentified');
    const details = Utils.extend({}, e);

    return {
        category,
        code,
        details
    };
};

const mapCafContextError = e => {
    let error = e.reason || e.detailedErrorCode;
    if (!error && Utils.isObject(e.error)) {
        error = e.error.reason || e.error.message;
    }
    return {
        category: ErrorCategories.Default,
        code: generateErrorCode('CafContext', ErrorCategories.Default, error || 'Unknown'),
        details: e
    };
};

const mapCafPlayerManagerError = e => {
    if (!e || !e.detailedErrorCode || NonFatalCafPlayerManagerErrorCodes.includes(e.detailedErrorCode)) {
        return e;
    }

    const details = {
        cafCode: e.detailedErrorCode,
        cafCodeKey: undefined,
        shakaCode: undefined,
        shakaCodeKey: undefined,
        shakaErrorData: undefined
    };

    const CafPlayerManagerErrors = cast.framework.events.DetailedErrorCode;
    const cafCodeEntry = Object.entries(CafPlayerManagerErrors).find(entry => entry[1] === details.cafCode);
    if (cafCodeEntry && cafCodeEntry[0]) {
        details.cafCodeKey = cafCodeEntry[0];
    }

    let code = getCafPlayerManagerErrorCode(details.cafCode);

    let category = ErrorCategories.Default;
    if (e.error) {
        if (e.error.shakaErrorCode) {
            details.shakaCode = e.error.shakaErrorCode;

            Object.keys(shaka.util.Error.Code).forEach(key => {
                if (shaka.util.Error.Code[key] === details.shakaCode) {
                    details.shakaCodeKey = key;
                }
            });

            if (NonFatalShakaErrorCodeKeys.includes(details.shakaCodeKey)) {
                return e;
            }

            if (details.shakaCode >= 10000) {
                category = ErrorCategories.Advertisement;
            } else {
                category = ErrorCategories.Media;
                const errorCodeFirstDigit = ('' + details.shakaCode)[0];
                switch (errorCodeFirstDigit) {
                    case '1':
                        category = ErrorCategories.Network;
                        break;
                    case '6':
                        category = ErrorCategories.Drm;
                        break;
                }
            }
        }
        if (e.error.shakaErrorData) {
            if (Array.isArray(e.error.shakaErrorData)) {
                details.shakaErrorData = `[${e.error.shakaErrorData.join('][')}]`;
            } else {
                details.shakaErrorData = e.error.shakaErrorData;
            }
        }
    }

    if (details.shakaCodeKey) {
        code += ':' + details.shakaCodeKey;
    }

    return {
        category,
        code,
        details
    };
};

const mapMediaElementError = e => {
    e = e || {};
    e.target = e.target || {};
    const error = e.target.error || {};

    const code = error.code;
    let category = ErrorCategories.Media;
    switch (code) {
        case 2:
            category = ErrorCategories.Network;
            break;
        case 5:
            category = ErrorCategories.Drm;
            break;
    }

    const details = {
        message: error.message,
        description: NativeErrorMap[code] || NativeErrorMap[0]
    };

    return {
        category,
        code,
        details
    };
};

const mapShakaWackaError = e => {
    const category = e.category || ErrorCategories.Default;
    const code = generateErrorCode('ShakaWacka', category, e.code);

    if (e.details && e.details.originalError) {
        e.details.originalError = mapToEnumerableError(e.details.originalError);
    }

    const details = Utils.extend({}, e.details);

    return {
        category,
        code,
        details
    };
};

const mapWackaError = e => {
    const code = generateErrorCode('Wacka', e.type, e.details);

    const details = Utils.extend({}, e);
    delete details.context;
    delete details.loader;

    let category = ErrorCategories.Default;
    if ('Hls' in window) {
        switch (e.type) {
            case Hls.ErrorTypes.MEDIA_ERROR:
            case Hls.ErrorTypes.MUX_ERROR:
            case Hls.ErrorTypes.OTHER_ERROR:
                category = ErrorCategories.Media;
                break;
            case Hls.ErrorTypes.KEY_SYSTEM_ERROR:
                category = ErrorCategories.Drm;
                break;
            case Hls.ErrorTypes.NETWORK_ERROR:
                category = ErrorCategories.Network;
                break;
        }
    }

    return {
        category,
        code,
        details
    };
};

const mapWindowError = e => {
    const error = e.origin === ErrorOrigins.Window.Rejection ? 'UnhandledRejection' : 'UnhandledError';
    return {
        category: ErrorCategories.Default,
        code: generateErrorCode('ErrorManager', ErrorCategories.Default, error),
        details: e
    };
};

const mapFinalError = finalError => {
    const fallbackMessage = I18n.getText(ErrorCodes.Generic);
    const translatedMessage = I18n.getText(finalError.code);
    finalError.displayMessage = finalError.displayMessage || translatedMessage || fallbackMessage;

    if (!finalError.normalizedError) {
        finalError.normalizedError = {
            category: ErrorCategories.Default,
            code: generateErrorCode('ErrorManager', ErrorCategories.Default, 'UnknownError'),
            details: Utils.extend({}, mapToEnumerableError(finalError)),
            fatal: finalError.fatal
        };
    }

    finalError.normalizedError.details = finalError.normalizedError.details || {};

    if (!Utils.isObject(finalError.normalizedError.details)) {
        const originalDetails = finalError.normalizedError.details;
        finalError.normalizedError.details = {
            originalDetails
        };
    }

    finalError.normalizedError.details.stackedErrors = finalError.stackedErrors;

    return finalError;
};

const mapLoadError = (e = {}) => {
    if (e instanceof ServiceLayerError) {
        e = new ReceiverError(e);
    } else if (e.code && e.category && Utils.isBoolean(e.fatal)) {
        e = mapStandardError(e);
    }

    return Utils.extend(e || {}, {
        fatal: true,
        origin: ErrorOrigins.Receiver.Interceptor.Load
    });
};

const mapNormalizedErrorToStandardError = (normalizedError, originalError) => new StandardError(Utils.extend({
    originalError
}, normalizedError));

const mapNonEnumerableErrorProperties = e => {
    const mapped = {};
    if (!e) return mapped;

    mapped.category = e.category;
    mapped.code = e.code;
    mapped.details = e.details;
    mapped.fatal = e.fatal;

    const innerError = e.error || {};
    const innerReason = e.reason || {};
    const message = e.message || innerError.message || (Utils.isString(innerReason) ? innerReason : innerReason.message);
    const stack = e.stack || innerError.stack || innerReason.stack;

    if (message) {
        mapped.message = message;
    }
    if (stack) {
        mapped.stack = stack;
    }
    if (e.reason && !Utils.isString(e.reason)) {
        mapped.reason = e.reason;
    }

    return mapped;
};

const mapReceiverStartError = (e = {}) => {
    e = mapToEnumerableError(e);

    if (e instanceof ServiceLayerError) {
        return Utils.extend(new ReceiverError(e), {
            code: e.code || ErrorCodes.Generic,
            fatal: true,
            origin: e.origin || ErrorOrigins.Receiver.Start,
            reload: false,
            terminate: true
        });
    }

    return new ReceiverError({
        code: ErrorCodes.Generic,
        fatal: true,
        normalizedError: new NormalizedError({
            category: ErrorCategories.Default,
            code: generateErrorCode('Receiver', 'Start', 'Unknown'),
            details: e,
            fatal: true
        }),
        origin: ErrorOrigins.Receiver.Start,
        reload: false,
        terminate: true
    });
};

const mapStandardError = (e = {}) => {
    return new ReceiverError({
        code: e.code || ErrorCodes.Generic, // Code for translation - not necessarily the actual error code
        fatal: Utils.booleanOrDefault(e.fatal, false),
        normalizedError: new NormalizedError(e),
        origin: e.origin || ErrorOrigins.ServiceLayer.Generic // StandardErrors are only thrown/dispatched from ServiceLayer
    });
};

const mapToEnumerableError = e => {
    Object
      .entries(mapNonEnumerableErrorProperties(e))
      .filter(entry => !!entry[1])
      .forEach(entry => Object.defineProperty(e, entry[0], {
          configurable: true,
          enumerable: true,
          value: entry[1],
          writable: true
      }));
    return e;

};

const mapToNormalizedError = (e = {}) => {
    let normalizedError = null;

    let fatal = e.fatal;
    try {
        switch (e.origin) {
            case ErrorOrigins.Caf.Context:
                normalizedError = mapCafContextError(e);
                break;
            case ErrorOrigins.Caf.PlayerManager:
                normalizedError = mapCafPlayerManagerError(e);
                break;
            case ErrorOrigins.Receiver.Dush:
                normalizedError = mapDushError(e);
                break;
            case ErrorOrigins.Receiver.MediaElement:
                normalizedError = mapMediaElementError(e);
                break;
            case ErrorOrigins.Receiver.ShakaWacka:
                normalizedError = mapShakaWackaError(e);
                break;
            case ErrorOrigins.Receiver.Wacka:
                normalizedError = mapWackaError(e);

                if (normalizedError.code === generateErrorCode('Wacka', 'mediaError', 'Wacka.SourceBufferFull')) {
                    fatal = false;
                }
                break;
            case ErrorOrigins.Window.Error:
            case ErrorOrigins.Window.Rejection:
                normalizedError = mapWindowError(e);
                break;

            case ErrorOrigins.Receiver.Interceptor.Load:
            case ErrorOrigins.Receiver.Start:
            case ErrorOrigins.Receiver.NextContent:
            case ErrorOrigins.ServiceLayer.DispatchError:
                break;
            default:
                break;
        }
    } catch (mappingError) {
        mappingError = mapToEnumerableError(mappingError);

        //|todo ErrorMapper.js [INFO][Major]: This should be checked and double checked;
        throw {
            category: ErrorCategories.Default,
            code: generateErrorCode('ErrorManager', ErrorCategories.Default, 'FailedToNormalizeError'),
            details: mappingError,
            fatal
        };
    }

    if (normalizedError) {
        normalizedError.fatal = fatal;
    }

    return normalizedError;
};

module.exports = {
    generateErrorCode,
    mapFinalError,
    mapLoadError,
    mapNormalizedErrorToStandardError,
    mapReceiverStartError,
    mapStandardError,
    mapToEnumerableError,
    mapToNormalizedError
};