const Utils = require('../utils/Utils');
const NormalizedError = require('./NormalizedError');

/**
 * @typedef {object} THandledErrorArgs
 * @property {string} [code]
 * @property {string} [displayMessage]
 * @property {boolean} [fatal]
 * @property {boolean} [handled]
 * @property {string} [message]
 * @property {NormalizedError} [normalizedError]
 * @property {boolean} [reload]
 * @property {boolean} [reloadAbortable]
 * @property {number} [reloadDelay]
 * @property {boolean} [terminate]
 * @property {number} [terminateDelay]
 */
/**
 * @type {THandledErrorArgs}
 */
class Props {
    constructor() {
        this.code = this.displayMessage = this.fatal = this.handled = this.message = this.normalizedError =
          this.reload = this.reloadAbortable = this.reloadDelay = this.terminate = this.terminateDelay = undefined;
    }
}

class HandledError extends Props {
    /**
     * @param {THandledErrorArgs} args
     */
    constructor(args = {}) {
        super();

        Utils.extend(this, {}, args);
    }
}

module.exports = HandledError;