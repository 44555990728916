const EventEmitter = require('eventemitter3');
const { XhrEvents } = require('../../Events');
const XMLHttpRequestExtension = require('./XMLHttpRequestExtension');

let instance;
module.exports = class XhrInterceptor extends EventEmitter {
    static getInstance() {
        if (!instance) {
            instance = new XhrInterceptor();
        }

        return instance;
    }

    constructor() {
        super();

        this.XMLHttpRequest = XMLHttpRequest;
        this.XMLHttpRequestExtension = XMLHttpRequestExtension;
        this.XMLHttpRequestExtension.prototype.xhrInterceptor = this;
    }

    intercept() {
        window.XMLHttpRequest = this.XMLHttpRequestExtension;
    }

    reset() {
        this.removeAllListeners();
    }

    destroy() {
        this.reset();

        window.XMLHttpRequest = this.XMLHttpRequest;

        instance = null;
        this.XMLHttpRequest = null;
        this.XMLHttpRequestExtension = null;
    }

    cdnDiscovered(cdn) {
        this.emit(XhrEvents.CdnDiscovered, {
            cdn
        });
    }
};