module.exports = class XMLHttpRequestExtension extends XMLHttpRequest {
    constructor() {
        super();
    }

    open(method, url) {
        this.addEventListener('loadend', () => {
            const headers = this.getAllResponseHeaders();
            if (headers && headers.indexOf('x-cdn-forward') > -1) {
                const cdn = this.getResponseHeader('x-cdn-forward');
                if (cdn) {
                    this.xhrInterceptor.cdnDiscovered(cdn);
                }
            }
        });

        super.open(method, url);
    }
};