const Translations = {
    en: require('./en')
};

module.exports = new class I18n {
    constructor() {
        this.language = 'en';
    }

    /**
     * Get the translated text for the provided token and language
     * @param {String} token The text token
     * @param {String} lang The language code
     * @param {String} [defaultText] A fallback text to return if no text is found
     * @returns {String} The resulting text
     */
    getTextForLanguage(token, lang, defaultText) {
        return this.getSomething((Translations[lang] || {})[token], Translations.en[token], defaultText);
    }

    /**
     * Get the translated text for the provided token and currentLanguage
     * @param {String} token The text token
     * @param {String} [defaultText] A fallback text to return if no text is found
     * @returns {String} The resulting text
     */
    getText(token, defaultText) {
        return this.getTextForLanguage(token, this.language, defaultText);
    }

    /**
     * Return the first provided value that is something, or undefined if no value is something.
     * undefined, null and NaN are not something
     * All truthy values + false, 0 and '' are something
     * @param {*} values Values in order of priority
     * @returns {*} The first value that is something, undefined if no value is something
     */
    getSomething(...values) {
        return values.find(val => val !== null && val !== undefined && !Number.isNaN(val));
    }

    /**
     * Set a language as currentLanguage, the first provided language that actually exists, if any, is applied
     * @param {String...} languages The language code(s) in order of priority
     */
    setLanguage(...languages) {
        languages.push(navigator.language.slice(0, 2)); // Potential fallback to device language

        for (const language of languages) {
            if (language in Translations) {
                this.language = language;
                break;
            }
        }

        return this;
    }

    setTranslations(translations) {
        for (let t in translations) {
            if (!translations.hasOwnProperty(t)) continue;

            Translations[t] = translations[t];
        }

        return this;
    }
};