//
//  ShakaWacka.js
//  Built with contempt by Daniel and Lars
//  Copyspite © 24/08/2020 All rights revoked.
//


const {
    ErrorOrigins,
    ErrorCategories
} = require('../Constants');
const {
    ShakaWackaEvents
} = require('../Events');
const IPlayer = require('./IPlayer');
const ShakaWackaRestrictionHelper = require('./helpers/ShakaWackaRestrictionHelper');
const Utils = require('../utils/Utils');
const { NormalizedError, ReceiverError } = require('../dtos/');
module.exports = class ShakaWacka extends IPlayer {
    static isSupported() {
        return 'shaka' in window;
    }

    constructor({ getNewLicense, license, mediaElement, textContainer, shakaConstructor, shakaRoot }) {
        super({ getNewLicense, license, mediaElement, textContainer });

        this.mediaElement = mediaElement;
        this.license = license;
        this.shakaConstructor = shakaConstructor;
        this.shakaRoot = shakaRoot;

        this.destroyed = false;
        this.mediaElementEventListeners = {};
        this.playerEventListeners = {};
        this.recoveringMode = false;
        this.requestFilters = [];
        this.responseFilters = [];
        this.retryConfiguration = {
            default: { maxAttempts: 0, retryDelay: 100 },
            playbackSession: { maxAttemptsPerRetry: 3, maxAttemptsPerSession: 5 }
        };
        this.retryState = {
            default: { attempts: 0, maxAttempts: 0, retryDelay: 100 },
            playbackSession: {
                attemptsPerRetry: 0,
                attemptsPerSession: 0,
                maxAttemptsPerRetry: 3,
                maxAttemptsPerSession: 5
            }
        };
        this.retryStack = null;
        this.retryTimeout = null;
        this.sessionInfo = null;
        this.shakaConfiguration = {};

        this.addEventListener = this.on;
        this.removeEventListener = this.off;

        this.restrictionHelper = new ShakaWackaRestrictionHelper();

        this.selectedTracks = {
            audio: null,
            text: null
        };

        this.networkingEngine = {
            registerRequestFilter: (a, b, c) => {
                this.requestFilters.push({ a, b, c });
            },
            registerResponseFilter: (a, b, c) => {
                this.responseFilters.push({ a, b, c });
            }
        };

        this.resetRetryConfiguration();
        this.resetRetryState();

        console.log(`*******************************************************************`);
        console.log(`*** ShakaWacka INITIALIZED!!!`);
        console.log(`*******************************************************************`);
    }

    /** @private */
    registerEventListeners() {
        const handleError = error => {
            this.addToErrorData(error.detail, {
                destroyed: this.destroyed
            });

            this.retryLoad('ShakaWacka::Event::Error', error.detail)
              .catch(e => this.emit('error', { type: 'error', detail: e }));
        };

        const createErrorSimulator = code => () => handleError({ detail: { code } });
        this.simulateError = code => createErrorSimulator(code)();
        this.simulateUnknownError = createErrorSimulator('unknown');
        this.simulateHttpError = createErrorSimulator(this.shakaRoot.util.Error.Code.HTTP_ERROR);
        this.simulateVideoError = createErrorSimulator(this.shakaRoot.util.Error.Code.VIDEO_ERROR);

        this.listen(this.mediaElement, this.mediaElementEventListeners, 'playing', () => {
            if (this.recoveringMode) {
                this.recoveringMode = false;
                Utils.defer(() => this.handleRetrySuccess(), 30000);
            }
            this.updateActiveTracks();
        });

        this.listen(this.player, this.playerEventListeners, 'textchanged', e => {
            this.updateActiveTracks();
            this.emit('textchanged', e);
        });

        this.listen(this.player, this.playerEventListeners, 'trackschanged', e => {
            this.updateActiveTracks();
            this.emit('trackschanged', e);
        });

        this.listen(this.player, this.playerEventListeners, 'variantchanged', e => {
            this.updateActiveTracks();
            this.emit('variantchanged', e);
        });

        this.listen(this.player, this.playerEventListeners, 'error', handleError);

        const shakaEvents = [
            /** Handled above */
            // 'error',
            // 'textchanged',
            // 'trackschanged',
            // 'variantchanged',

            /** ReEmitted */
            'abrstatuschanged',
            'adaptation',
            'buffering',
            'drmsessionupdate',
            'emsg',
            'expirationupdated',
            'largegap',
            'loaded',
            'loading',
            'manifestparsed',
            'metadata',
            'onstatechange',
            'onstateidle',
            'ratechange',
            'sessiondata',
            'streaming',
            'texttrackvisibility',
            'timelineregionadded',
            'timelineregionenter',
            'timelineregionexit',
            'unloading'
        ];
        const createReEmitter = event => data => this.emit(event, data);
        shakaEvents.forEach(event => this.listen(this.player, this.playerEventListeners, event, createReEmitter(event)));
    }

    /** @private */
    updateActiveTracks() {
        if (this.recoveringMode) return;

        const activeAudioTracks = this.player.getVariantTracks().find((track) => track.type === 'variant' && track.audioId && track.active);
        const activeTextTracks = this.player.getTextTracks().find((track) => track.type === 'text' && track.active);
        if (activeAudioTracks && activeAudioTracks.language !== 'und') {
            this.selectedTracks.audio = activeAudioTracks.language
        }
        if (activeTextTracks && activeTextTracks.language) {
            this.selectedTracks.text = activeTextTracks.language
        }
    }

    /** @private */
    unregisterEventListeners() {
        Object.keys(this.mediaElementEventListeners).forEach(e =>
          this.mediaElement.removeEventListener(e,
            this.mediaElementEventListeners[e]
          )
        );
        this.mediaElementEventListeners = {};

        Object.keys(this.playerEventListeners).forEach(e =>
          this.player.removeEventListener(e,
            this.playerEventListeners[e]
          )
        );
        this.playerEventListeners = {};
    };

    /** @private */
    addToErrorData(error, data) {
        if (!error.data) {
            error.data = [];
        }

        if (Array.isArray(error.data)) {
            try {
                error.data.push(JSON.stringify(data));
            } catch (e) {
            }
        }
    }

    /** @private */
    augmentRetryState(reset = false) {
        this.retryState.default = Utils.extend({}, this.retryConfiguration.default, {
            attempts: reset ? 0 : (this.retryState.default.attempts || 0)
        });

        this.retryState.playbackSession = Utils.extend({}, this.retryConfiguration.playbackSession, {
            attemptsPerRetry: reset ? 0 : (this.retryState.playbackSession.attemptsPerRetry || 0),
            attemptsPerSession: reset ? 0 : (this.retryState.playbackSession.attemptsPerSession || 0)
        });

        Object.entries(this.retryConfiguration)
          .forEach(([key, value]) => {
              if (['default', 'playbackSession'].includes(key)) return;

              this.retryState[key] = Utils.extend({}, value, { attempts: reset ? 0 : (value.attempts || 0) });
          });
    }

    /** @private */
    canRetryLoad(error) {
        const retryConfiguration = this.retryState[error.code] || this.retryState.default;
        return retryConfiguration.attempts < retryConfiguration.maxAttempts &&
          this.retryState.playbackSession.attemptsPerRetry < this.retryState.playbackSession.maxAttemptsPerRetry &&
          this.retryState.playbackSession.attemptsPerSession < this.retryState.playbackSession.maxAttemptsPerSession;
    }

    /** @private */
    handleRetrySuccess() {
        this.emit(ShakaWackaEvents.Error, {
            category: ErrorCategories.Media,
            code: 'ShakaWacka.Recovered',
            details: {
                retryState: this.retryState,
                retryStack: this.retryStack
            },
            fatal: false
        });

        this.resetRetries();
    }

    /** @private */
    incrementRetryState(error) {
        const retryConfiguration = this.retryState[error.code] || this.retryState.default;
        ++retryConfiguration.attempts;
        ++this.retryState.playbackSession.attemptsPerRetry;
        ++this.retryState.playbackSession.attemptsPerSession;
    }

    /** @private */
    loadShaka(source, startTime) {
        return new Promise((resolve, reject) => {
            let destroyed = Promise.resolve();
            if (this.player) {
                destroyed = this.player.destroy();
            }

            destroyed
              .finally(() => {
                  this.player = new this.shakaConstructor(this.mediaElement);
                  //this.restrictionHelper.setPlayer(this.player);

                  if (this.shakaConfiguration) {
                      this.player.configure(this.shakaConfiguration);
                  }

                  if (this.recoveringMode) {
                      // Setting preferred audio & text language on recoveringMode to not change selected language on session
                      if (this.selectedTracks.audio) {
                          this.player.configure({
                              preferredAudioLanguage: this.selectedTracks.audio
                          });
                      }

                      if (this.selectedTracks.text) {
                          this.player.configure({
                              preferredTextLanguage: this.selectedTracks.text
                          });
                      }
                  }

                  if (this.requestFilters.length) {
                      this.requestFilters.forEach(({ a, b, c }) => {
                          this.player.getNetworkingEngine().registerRequestFilter(a, b, c);
                      });
                  }

                  if (this.responseFilters.length) {
                      this.responseFilters.forEach(({ a, b, c }) => {
                          this.player.getNetworkingEngine().registerResponseFilter(a, b, c);
                      });
                  }

                  this.player.load(source, startTime)
                    .then(result => {
                        this.registerEventListeners();

                        // return reject({ code: 'unknown' }); // Test retry load shaka (0 retry then failure)
                        // if (this.retryState[this.shakaRoot.util.Error.Code.HTTP_ERROR].attempts < 2) return reject({ code: this.shakaRoot.util.Error.Code.HTTP_ERROR }); // Test retry load shaka (1 retry then failure)
                        // if (this.retryState[this.shakaRoot.util.Error.Code.VIDEO_ERROR].attempts < 2) return reject({ code: this.shakaRoot.util.Error.Code.VIDEO_ERROR }); // Test retry load shaka (2 retries then success)

                        resolve(result);
                    })
                    .catch(reject);
              });
        });
    }

    /** @private */
    resetRetries() {
        // clearing the retryStack
        this.retryStack = null;
        this.retryState.playbackSession.attemptsPerRetry = 0;
        this.retryState.playbackSession.retryStart = null;

        [
            this.shakaRoot.util.Error.Code.VIDEO_ERROR
        ].forEach(code => this.retryState[code].attempts = 0);
    }

    /** @private */
    resetRetryConfiguration() {
        this.retryConfiguration = {
            // Reserved retry configurations
            default: { maxAttempts: 0, retryDelay: 100 }, // Used for unspecified errors
            playbackSession: { maxAttemptsPerRetry: 3, maxAttemptsPerSession: 5, onDemandRetrySeekOffsetSeconds: 10 },

            // PerError retry configurations
            [this.shakaRoot.util.Error.Code.BAD_HTTP_STATUS]: { maxAttempts: 1, retryDelay: 0 },
            [this.shakaRoot.util.Error.Code.HTTP_ERROR]: { maxAttempts: 1, retryDelay: 0 },
            [this.shakaRoot.util.Error.Code.FAILED_TO_GENERATE_LICENSE_REQUEST]: { maxAttempts: 1, retryDelay: 3000 },
            [this.shakaRoot.util.Error.Code.VIDEO_ERROR]: { maxAttempts: 1, retryDelay: 0 }
        };
    }

    /** @private */
    resetRetryState() {
        this.augmentRetryState(true);
    }

    /** @private */
    retryLoad(from, error) {
        this.recoveringMode = true;
        this.retryState.playbackSession.retryStart = this.retryState.playbackSession.retryStart || Date.now();

        this.unregisterEventListeners(); // Unregister event listeners here to avoid getting a seconds error on a shaka instance that is about to be destroyed...
        let normalizedError = new NormalizedError(
          {
              category: ErrorCategories.Media,
              code: `ShakaWacka.${ShakaErrorCodes[error.code]}`,
              details: {
                  ...error,
                  retryState: this.retryState
              },
              fatal: true
          }
        )
        let standardError = new ReceiverError({
            code: normalizedError.code,
            fatal: normalizedError.fatal,
            origin: ErrorOrigins.Receiver.ShakaWacka,
            normalizedError: normalizedError
        })

        if (!this.retryStack) {
            this.retryStack = {
                originalError: standardError,
                retryAttempts: [],
            }
        } else {
            this.retryStack.retryAttempts.push(standardError);
        }

        return new Promise((resolve, reject) => {
            this.setSessionInfo();

            if (!this.canRetryLoad(error)) {
                console.error('ShakaWacka::retryLoad: Can\'t retry any more, final error:', error, ', retry state:', this.retryState);

                const originalError = this.retryStack.originalError;
                originalError.normalizedError = originalError.normalizedError || {};
                originalError.normalizedError.details = originalError.normalizedError.details || {};
                originalError.normalizedError.details.retryAttempts = this.retryStack.retryAttempts;
                originalError.normalizedError.details.retryDuration = Date.now() - this.retryState.playbackSession.retryStart;

                this.emit(ShakaWackaEvents.Error, originalError);
                return reject(null); // ToDo: Should reject with error ?!
            }
            this.incrementRetryState(error);

            console.error(`${from}: Retrying load, retry state:`, this.retryState);

            // this.emit(ShakaWackaEvents.Error, {
            //   category: ErrorCategories.Media,
            //   code: ErrorCodes.TryingToRecover,
            //   details: {
            //     originalError: error,
            //     retryState: this.retryState
            //   },
            //   fatal: false,
            //   notify: true,
            //   notificationDuration: 60000
            // });

            if (!this.isLive()) { // Try to skip potentially broken segment
                this.startTime = this.mediaElement.currentTime + this.retryState.playbackSession.onDemandRetrySeekOffsetSeconds;
            }

            const retryDelay = (this.retryState[error.code] || this.retryState.default).retryDelay;
            this.retryTimeout = Utils.defer(
              () => this.getNewLicense()
                .catch(e => { // Catch & emit error but let it go through to attempt retry even if this fails
                    this.emit(ShakaWackaEvents.Error, {
                        category: ErrorCategories.Media,
                        code: 'ShakaWacka.FailedToUpdateDrmInfo',
                        details: {
                            originalError: e
                        },
                        fatal: false
                    });
                })
                .then(license => this.license && license && Utils.extend(this.license, license))
                .then(() => this.load(this.source, this.startTime))
                .then(() => {
                    this.previousError = null;
                    resolve();
                })
                .catch(reject),
              retryDelay
            );
        });
    }

    /** @private */
    setSessionInfo() {
        if (this.player && this.player.manifest_ && this.player.manifest_.periods && this.player.manifest_.periods[0] && this.player.manifest_.periods[0].variants) {
            let variants = [];
            for (let i in this.player.manifest_.periods[0].variants) {
                if (!this.player.manifest_.periods[0].variants.hasOwnProperty(i)) continue;

                const variant = this.player.manifest_.periods[0].variants[i];
                const {
                    id,
                    allowedByApplication,
                    allowedByKeySystem,
                    drmInfos,
                    audio,
                    video
                } = variant;

                try {
                    variants.push({
                        id,
                        allowedByApplication,
                        allowedByKeySystem,
                        audioMime: audio.mimeType + '; codecs="' + audio.codecs + '"',
                        videoMime: video.mimeType + '; codecs="' + video.codecs + '"',
                        isTypeSupported: MediaSource.isTypeSupported(video.mimeType + '; codecs="' + video.codecs + ', ' + audio.codecs + '"'),
                        drmInfos: drmInfos.map((d) => {
                            return {
                                keyId: d.keyIds[0],
                                keySystem: d.keySystem
                            };
                        })
                    });
                } catch (e) {
                }
            }

            const sessionInfo = {
                variants
            };
            try {
                sessionInfo.activeSessions = this.player.drmEngine_.activeSessions_.size;
                sessionInfo.currentDrmInfo = this.player.drmEngine_.currentDrmInfo_;
                sessionInfo.supportedTypes = this.player.drmEngine_.supportedTypes_;
            } catch (e) {
            }

            this.sessionInfo = sessionInfo;
        }
    }

    load(source, startTime) {
        this.source = source;
        this.startTime = startTime;

        return new Promise((resolve, reject) => {
            const createAbortedError = () => ({
                category: this.shakaRoot.util.Error.Category.PLAYER,
                code: this.shakaRoot.util.Error.Code.LOAD_INTERRUPTED,
                severity: this.shakaRoot.util.Error.Severity.CRITICAL
            });

            const rejectDestroyed = () => reject(createAbortedError());

            const resolveOrRejectIfDestroyed = () => {
                if (this.destroyed) return rejectDestroyed();

                resolve();
            };

            if (this.destroyed) return rejectDestroyed();

            this.loadShaka(source, startTime)
              .then(resolveOrRejectIfDestroyed)
              .catch(error => {
                  this.retryLoad('ShakaWacka::load', error)
                    .then(resolveOrRejectIfDestroyed)
                    .catch(reject);
              });
        });
    }

    configure(configOrKey, value) {
        if (Utils.isString(configOrKey)) {
            configOrKey = Utils.pathToObject(configOrKey, value);
        }

        if (configOrKey.retryConfiguration) {
            Utils.extend(this.retryConfiguration, configOrKey.retryConfiguration);
            delete configOrKey.retryConfiguration;
            this.augmentRetryState();
        }

        // The 'unsetting' of shaka restrictions requires the ability to set values to undefined
        this.shakaConfiguration = Utils.extend(/* allowUndefinedValues */ true, {}, this.shakaConfiguration || {}, configOrKey);

        if (!this.player) return;

        this.player.configure(this.shakaConfiguration);
    }

    getConfiguration() {
        if (!this.player) return this.shakaConfiguration;

        return this.player.getConfiguration();
    }

    getManifest() {
        if (!this.player) return {};

        return this.player.getManifest();
    }

    getNetworkingEngine() {
        if (!this.player) return this.networkingEngine;

        return this.player.getNetworkingEngine();
    }

    getStats() {
        if (!this.player) return {};

        return this.player.getStats();
    }

    getTextTracks() {
        if (!this.player) return [];

        return this.player.getTextTracks();
    }

    getVariantTracks() {
        if (!this.player) return [];

        return this.player.getVariantTracks();
    }

    isBuffering() {
        if (!this.player) return false;

        return this.player.isBuffering();
    }

    isInProgress() {
        if (!this.player) return false;

        return this.player.isInProgress();
    }

    isLive() {
        if (!this.player) return false;

        return this.player.isLive();
    }

    seekRange() {
        if (!this.player) return {
            start: 0,
            end: (isNaN(this.mediaElement.duration)) ? 0 : this.mediaElement.duration
        };

        return this.player.seekRange();
    }

    selectAudioLanguage(language) {
        if (!this.player) return Promise.resolve();

        return this.player.selectAudioLanguage(language);
    }

    selectTextTrack(track) {
        if (!this.player) return Promise.resolve();

        return this.player.selectTextTrack(track);
    }

    setTextTrackVisibility(visible) {
        if (!this.player) return Promise.resolve();

        return this.player.setTextTrackVisibility(visible);
    }

    reset() {
        if (this.retryTimeout) {
            this.retryTimeout = clearTimeout(this.retryTimeout);
        }
        this.retryStack = null;
        this.recoveringMode = false;

        this.resetRetryConfiguration();
        this.resetRetryState();
    }

    destroy() {
        this.destroyed = true;

        return new Promise((resolve) => {
            this.removeAllListeners();

            this.unregisterEventListeners();

            this.reset();

            this.requestFilters = [];
            this.responseFilters = [];
            this.sessionInfo = null;
            this.shakaConfiguration = {};

            if (this.player) {
                this.player.destroy()
                  .finally(resolve);
                this.player = null;
            } else {
                this.player = null;

                resolve();
            }
        });
    }
};

const ShakaErrorCodes = {
    1000: 'UNSUPPORTED_SCHEME',
    1001: 'BAD_HTTP_STATUS',
    1002: 'HTTP_ERROR',
    1003: 'TIMEOUT',
    1004: 'MALFORMED_DATA_URI',
    1006: 'REQUEST_FILTER_ERROR',
    1007: 'RESPONSE_FILTER_ERROR',
    1008: 'MALFORMED_TEST_URI',
    1009: 'UNEXPECTED_TEST_REQUEST',
    1010: 'ATTEMPTS_EXHAUSTED',
    2000: 'INVALID_TEXT_HEADER',
    2001: 'INVALID_TEXT_CUE',
    2003: 'UNABLE_TO_DETECT_ENCODING',
    2004: 'BAD_ENCODING',
    2005: 'INVALID_XML',
    2007: 'INVALID_MP4_TTML',
    2008: 'INVALID_MP4_VTT',
    2009: 'UNABLE_TO_EXTRACT_CUE_START_TIME',
    2010: 'INVALID_MP4_CEA',
    2011: 'TEXT_COULD_NOT_GUESS_MIME_TYPE',
    2012: 'CANNOT_ADD_EXTERNAL_TEXT_TO_SRC_EQUALS',
    2013: 'TEXT_ONLY_WEBVTT_SRC_EQUALS',
    2014: 'MISSING_TEXT_PLUGIN',
    3000: 'BUFFER_READ_OUT_OF_BOUNDS',
    3001: 'JS_INTEGER_OVERFLOW',
    3002: 'EBML_OVERFLOW',
    3003: 'EBML_BAD_FLOATING_POINT_SIZE',
    3004: 'MP4_SIDX_WRONG_BOX_TYPE',
    3005: 'MP4_SIDX_INVALID_TIMESCALE',
    3006: 'MP4_SIDX_TYPE_NOT_SUPPORTED',
    3007: 'WEBM_CUES_ELEMENT_MISSING',
    3008: 'WEBM_EBML_HEADER_ELEMENT_MISSING',
    3009: 'WEBM_SEGMENT_ELEMENT_MISSING',
    3010: 'WEBM_INFO_ELEMENT_MISSING',
    3011: 'WEBM_DURATION_ELEMENT_MISSING',
    3012: 'WEBM_CUE_TRACK_POSITIONS_ELEMENT_MISSING',
    3013: 'WEBM_CUE_TIME_ELEMENT_MISSING',
    3014: 'MEDIA_SOURCE_OPERATION_FAILED',
    3015: 'MEDIA_SOURCE_OPERATION_THREW',
    3016: 'VIDEO_ERROR',
    3017: 'QUOTA_EXCEEDED_ERROR',
    3018: 'TRANSMUXING_FAILED',
    3019: 'CONTENT_TRANSFORMATION_FAILED',
    4000: 'UNABLE_TO_GUESS_MANIFEST_TYPE',
    4001: 'DASH_INVALID_XML',
    4002: 'DASH_NO_SEGMENT_INFO',
    4003: 'DASH_EMPTY_ADAPTATION_SET',
    4004: 'DASH_EMPTY_PERIOD',
    4005: 'DASH_WEBM_MISSING_INIT',
    4006: 'DASH_UNSUPPORTED_CONTAINER',
    4007: 'DASH_PSSH_BAD_ENCODING',
    4008: 'DASH_NO_COMMON_KEY_SYSTEM',
    4009: 'DASH_MULTIPLE_KEY_IDS_NOT_SUPPORTED',
    4010: 'DASH_CONFLICTING_KEY_IDS',
    4012: 'RESTRICTIONS_CANNOT_BE_MET',
    4015: 'HLS_PLAYLIST_HEADER_MISSING',
    4016: 'INVALID_HLS_TAG',
    4017: 'HLS_INVALID_PLAYLIST_HIERARCHY',
    4018: 'DASH_DUPLICATE_REPRESENTATION_ID',
    4020: 'HLS_MULTIPLE_MEDIA_INIT_SECTIONS_FOUND',
    4022: 'HLS_MASTER_PLAYLIST_NOT_PROVIDED',
    4023: 'HLS_REQUIRED_ATTRIBUTE_MISSING',
    4024: 'HLS_REQUIRED_TAG_MISSING',
    4025: 'HLS_COULD_NOT_GUESS_CODECS',
    4026: 'HLS_KEYFORMATS_NOT_SUPPORTED',
    4027: 'DASH_UNSUPPORTED_XLINK_ACTUATE',
    4028: 'DASH_XLINK_DEPTH_LIMIT',
    4030: 'HLS_COULD_NOT_PARSE_SEGMENT_START_TIME',
    4032: 'CONTENT_UNSUPPORTED_BY_BROWSER',
    4033: 'CANNOT_ADD_EXTERNAL_TEXT_TO_LIVE_STREAM',
    4034: 'HLS_AES_128_ENCRYPTION_NOT_SUPPORTED',
    4035: 'HLS_INTERNAL_SKIP_STREAM',
    4036: 'NO_VARIANTS',
    4037: 'PERIOD_FLATTENING_FAILED',
    4038: 'INCONSISTENT_DRM_ACROSS_PERIODS',
    4039: 'HLS_VARIABLE_NOT_FOUND',
    5006: 'STREAMING_ENGINE_STARTUP_INVALID_STATE',
    6000: 'NO_RECOGNIZED_KEY_SYSTEMS',
    6001: 'REQUESTED_KEY_SYSTEM_CONFIG_UNAVAILABLE',
    6002: 'FAILED_TO_CREATE_CDM',
    6003: 'FAILED_TO_ATTACH_TO_VIDEO',
    6004: 'INVALID_SERVER_CERTIFICATE',
    6005: 'FAILED_TO_CREATE_SESSION',
    6006: 'FAILED_TO_GENERATE_LICENSE_REQUEST',
    6007: 'LICENSE_REQUEST_FAILED',
    6008: 'LICENSE_RESPONSE_REJECTED',
    6010: 'ENCRYPTED_CONTENT_WITHOUT_DRM_INFO',
    6012: 'NO_LICENSE_SERVER_GIVEN',
    6013: 'OFFLINE_SESSION_REMOVED',
    6014: 'EXPIRED',
    6015: 'SERVER_CERTIFICATE_REQUIRED',
    6016: 'INIT_DATA_TRANSFORM_ERROR',
    6017: 'SERVER_CERTIFICATE_REQUEST_FAILED',
    7000: 'LOAD_INTERRUPTED',
    7001: 'OPERATION_ABORTED',
    7002: 'NO_VIDEO_ELEMENT',
    7003: 'OBJECT_DESTROYED',
    7004: 'CONTENT_NOT_LOADED',
    8000: 'CAST_API_UNAVAILABLE',
    8001: 'NO_CAST_RECEIVERS',
    8002: 'ALREADY_CASTING',
    8003: 'UNEXPECTED_CAST_ERROR',
    8004: 'CAST_CANCELED_BY_USER',
    8005: 'CAST_CONNECTION_TIMED_OUT',
    8006: 'CAST_RECEIVER_APP_UNAVAILABLE',
    9000: 'STORAGE_NOT_SUPPORTED',
    9001: 'INDEXED_DB_ERROR',
    9002: 'DEPRECATED_OPERATION_ABORTED',
    9003: 'REQUESTED_ITEM_NOT_FOUND',
    9004: 'MALFORMED_OFFLINE_URI',
    9005: 'CANNOT_STORE_LIVE_OFFLINE',
    9007: 'NO_INIT_DATA_FOR_OFFLINE',
    9008: 'LOCAL_PLAYER_INSTANCE_REQUIRED',
    9011: 'NEW_KEY_OPERATION_NOT_SUPPORTED',
    9012: 'KEY_NOT_FOUND',
    9013: 'MISSING_STORAGE_CELL',
    9014: 'STORAGE_LIMIT_REACHED',
    9015: 'DOWNLOAD_SIZE_CALLBACK_ERROR',
    9016: 'MODIFY_OPERATION_NOT_SUPPORTED',
    10000: 'CS_IMA_SDK_MISSING',
    10001: 'CS_AD_MANAGER_NOT_INITIALIZED',
    10002: 'SS_IMA_SDK_MISSING',
    10003: 'SS_AD_MANAGER_NOT_INITIALIZED',
    10004: 'CURRENT_DAI_REQUEST_NOT_FINISHED',
}