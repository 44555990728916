const IServiceLayer = require('./IServiceLayer');
const { HandledError } = require('../dtos/');

module.exports = class ServiceLayer extends IServiceLayer {
    constructor(...args) {
        super(...args);

        this.receiver.setApplicationInfo({
            applicationName: 'UnifiedReceiver::ServiceLayer',
            applicationVersion: __VERSION__ // Will be injected at build time
        })
    }

    canHandleContent(content) {
        return !!content.contentId &&
          !!(content.stream && content.stream.url);
    }

    initialize() {
        return this.resolve();
    }

    handleError(error) {
        return this.resolve(new HandledError());
    }

    handleLoad(dto, receiverInformation) {
        return this.resolve(dto);
    }

    handleReset() {
        return this.resolve();
    }

    handleShutdown() {
        return this.resolve();
    }
};