export class ApplicationInfo {
    public applicationName: string;
    public applicationVersion: string;

    constructor(args?: ApplicationInfo) {
        if (args) {
            this.applicationName = args.applicationName;
            this.applicationVersion = args.applicationVersion;
        }
    }
}
