const Track = require('./Track');

const defaultLanguage = 'en';
const disabledLanguage = 'off';

class AudioTrack extends Track {
    constructor(engine) {
        super('audio', engine);
        this.disableable = false;
        this.accessibilityIndicates = {
            language: '-hi',
            name: '[HEARING_INTERPRETED]',
            role: 'commentary'
        }
    }

    getActiveTrack() {
        try {
            return this.gTracksManager.getTrackById(this.gTracksManager.getActiveId());
        } catch (e) {
            return null;
        }
    }

    getActiveTrackLanguage() {
        let language = super.getActiveTrackLanguage();
        if (language === disabledLanguage) {
            return defaultLanguage;
        }
        return language;
    }

    setInitialActiveTrack() {
        if (this.gTracksManager && !this.gTracksManager.getActiveTrack()) {
            const audioTracks = this.getTracks();
            if (audioTracks.length === 1) {
                if (audioTracks[0] && audioTracks[0].trackId) {
                    this.gTracksManager.setActiveById(audioTracks[0].trackId);
                }
            }
            // Find active variant track and set on CAF
            else {
                const variantTracks = this.engine.getVariantTracks();
                if (variantTracks && variantTracks.length) {
                    const activeVariantTrack = variantTracks.find(v => v.active);
                    if (activeVariantTrack && activeVariantTrack.language) {
                        this.gTracksManager.setActiveByLanguage(activeVariantTrack.language);
                    }
                }
            }
        }
        super.setInitialActiveTrack();
    }
}

module.exports = AudioTrack;