export enum VideoType {
  LIVE = "live",
  VOD = "vod",
  CLIP = "clip",
  ARCHIVE = "archive",
}

export enum CoreTrackingEvent {
  SET_USER_PROPERTIES = "set_user_properties",
  STREAM_START = "stream_start",
  STREAM_RESUME = "stream_resume",
  STREAM_START_INIT = "stream_start_init",
  STREAM_AD_BREAK_START = "ad_break_start",
  STREAM_AD_BREAK_END = "ad_break_end",
  STREAM_AD_START = "stream_ad_start",
  STREAM_SPONSOR_START = "stream_sponsor_start",
  STREAM_TRAILER_START = "stream_trailer_start",
  STREAM_JINGLE_START = "stream_jingle_start", // aka bumper
  STREAM_FILLER_START = "stream_filler_start",
  EXPERIMENT = "experiment",

  // Can be sent as custom events using the CustomTrackingEvent
  PLAYER_SKIP_INTRO = "player_skip_intro",
  PLAYER_SKIP_INTRO_IMPRESSION = "player_skip_intro_impression",
  PLAYER_SKIP_RECAP = "player_skip_recap",
  PLAYER_SKIP_RECAP_IMPRESSION = "player_skip_recap_impression",
  PLAYER_NEXT_EPISODE = "player_next_episode",
  PLAYER_NEXT_EPISODE_IMPRESSION = "player_next_episode_impression",
  PLAYER_MORE_CONTENT = "player_more_content",
  PLAYER_MORE_CONTENT_ASSET_CLICK = "player_more_content_asset_click",
  PLAYER_STARTOVER_BUTTON_PRESSED = "player_startover_button_pressed",
  PLAYER_JUMP_TO_LIVE_BUTTON_PRESSED = "player_jump_to_live_button_pressed"
}

export type TAutoplayType = "sequence" | "none";

export type TPlayerData = {
  // If the media was initiated by play next
  player_is_autoplay: boolean;
  // "sequence" is for series play next.
  player_autoplay_type: TAutoplayType;
};

export type TAssetData = {
  content_media_id: string;
  content_title: string;
};

export type TDataLayerEvent = {
  event: CoreTrackingEvent;
  player_session_id: string;
};

export type TDataLayerAdBreakEvent = {
  event:
    | CoreTrackingEvent.STREAM_AD_BREAK_START
    | CoreTrackingEvent.STREAM_AD_BREAK_END;
  player_session_id: string;
};

export type TDataLayerAdEvent = {
  event: CoreTrackingEvent.STREAM_AD_START;
  ad_id: string;
  custom_ad_id: string;
  player_session_id: string;
};

export type TDataLayerTrailerEvent = {
  event: CoreTrackingEvent.STREAM_TRAILER_START;
  ad_id: string;
  custom_ad_id: string;
  player_session_id: string;
};

export type TDataLayerUserEvent = {
  event: CoreTrackingEvent.SET_USER_PROPERTIES;
  user_id: string;
  player_session_id: string;
};

export type TDataLayerExperimentEvent = {
  event: string;
  experiment_name: string;
  experiment_group: string;
  player_session_id: string;
};

export type TDataLayerCustomEvent = TPlayerData & TAssetData & TDataLayerEvent;

export type TDataLayerEventTypes =
  | TDataLayerEvent
  | TDataLayerUserEvent
  | TDataLayerCustomEvent
  | TDataLayerAdBreakEvent
  | TDataLayerAdEvent
  | TDataLayerTrailerEvent
  | TDataLayerExperimentEvent;
