
const defaultImageSizes = {
  width: 1280,
  height: 720,
};

const createResizedImageUrlV1 = (
  imageUrl: string,
  width: number = defaultImageSizes.width,
  height: number = defaultImageSizes.height,
  imageResizeUrlPattern: string
) => {
  if (!imageUrl || !imageResizeUrlPattern || width <= 0 || height <= 0) return imageUrl;

  try {
    return imageResizeUrlPattern
      .replace('{widthXHeight}', `${width}x${height}`)
      .replace('{urlEncodedImageUrl}', encodeURIComponent(imageUrl));
  } catch (e) {}

  return imageUrl;
}


const createResizedImageUrlV2 = (
  imageUrl: string,
  width: number = defaultImageSizes.width,
  height: number = defaultImageSizes.height,
  imageResizeUrlPattern: string
) => {
  if (!imageUrl || !imageResizeUrlPattern || width <= 0 || height <= 0) return imageUrl;

  try {
    return imageResizeUrlPattern
      .replace('{urlEncodedImageUrl}', encodeURIComponent(imageUrl))
      .replace('{width}', `${width}`)
  .replace('{height}', `${height}`);

  } catch (e) {}

  return imageUrl;
}

export const createResizedImageUrl =  createResizedImageUrlV2;
