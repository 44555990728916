const ReceiverInterface = require('./ReceiverInterface');
/**
 * @typedef {object} DefaultServiceLayerConfiguration
 * @property {boolean} debug - it's a debug mode come on..
 */

/**
 *
 * @type {DefaultServiceLayerConfiguration}
 */
export const DefaultServiceLayerConfiguration = {}
