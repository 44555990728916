const EventEmitter = require('eventemitter3');
const { FetchEvents } = require('../../Events');
const FetchExtension = require('./FetchExtension');

let instance;
module.exports = class FetchInterceptor extends EventEmitter {
    static getInstance() {
        if (!instance) {
            instance = new FetchInterceptor();
        }

        return instance;
    }

    constructor() {
        super();

        this.fetch = window.fetch;
        this.fetchExtension = new FetchExtension(this);
    }

    intercept() {
        window.fetch = this.fetchExtension.interceptor.bind(this.fetchExtension);
    }

    reset() {
        this.removeAllListeners();
    }

    destroy() {
        this.reset();

        window.fetch = this.fetch;

        instance = null;
        this.fetch = null;
        this.fetchExtension = null;
    }

    cdnDiscovered(cdn) {
        this.emit(FetchEvents.CdnDiscovered, {
            cdn
        });
    }
};