const Utils = require('../utils/Utils');

/**
 * @typedef {object} TNormalizedErrorArgs
 * @property {string} category
 * @property {string} code
 * @property {*} [details]
 * @property {boolean} fatal
 */
/**
 * @type {TNormalizedErrorArgs}
 */
class Props {
    constructor() {
        this.category = this.code = this.details = this.fatal = undefined;
    }
}

class NormalizedError extends Props {
    /**
     * @param {TNormalizedErrorArgs} args
     */
    constructor(args = {}) {
        super();

        Utils.extend(this, {
            category: args.category,
            code: args.code,
            details: args.details,
            fatal: Utils.booleanOrDefault(args.fatal, false)
        }, args);
    }
}

module.exports = NormalizedError;