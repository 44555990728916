import {
    AdvertisementBreakStartedEvent,
    AdvertisementBreakTypes,
    AdvertisementEndedEvent,
    AdvertisementStartedEvent,
    AdvertisementTimeUpdateEvent,
    BufferingEvent,
    Logger,
    PausedEvent,
    PlayerInfo,
    SeekedEvent,
    SeekingEvent,
    ServiceCountry,
    StoppedEvent,
    TimeUpdateEvent,
    TNielsenContentMetadataDenmark,
    TNielsenContentMetadataSweden,
    TrackingInfoEvent,
    WatchMode
} from "@tv4/one-playback-sdk-shared";
import { BaseTracker } from "../BaseTracker";
import { TTrackerConfiguration } from "../Types";
import { getSDK, NielsenAdMetadata, NielsenBreakType, NielsenContentMetadata, NielsenEvent, NielsenInstance, NOLBUNDLE } from "./lib/nielsen";

const BreakTypeMap = {
    [AdvertisementBreakTypes.PREROLL]: NielsenBreakType.PREROLL,
    [AdvertisementBreakTypes.MIDROLL]: NielsenBreakType.MIDROLL,
    [AdvertisementBreakTypes.POSTROLL]: NielsenBreakType.POSTROLL,
}

function getAdType(breakType: AdvertisementBreakTypes) {
    return BreakTypeMap[breakType] || NielsenBreakType.UNKNOWN;
}


function isSwedenMetadata(metadata: Record<string, any>): metadata is TNielsenContentMetadataSweden {
    return !!metadata.playerv;
}

export class NielsenTracker extends BaseTracker {
    private disabled = false;
    private initialized = false;
    private sdk: NOLBUNDLE;
    private player: PlayerInfo;
    private serviceCountry: ServiceCountry;

    private nlsn: NielsenInstance | null = null;
    private isLive: boolean = false;
    private contentMetadata?: NielsenContentMetadata;
    private onWindowUnload?: () => void;

    private utcStartTime: number | null = null;
    private lastPosition = -1;
    private currentMetadata?: NielsenContentMetadata | NielsenAdMetadata | null;
    private sessionEnded = false;
    private isStopped = false;
    private isSeeking = false;

    constructor({ player, service }: TTrackerConfiguration) {
        super("NielsenTracker");

        this.player = player;
        this.serviceCountry = service.serviceCountry as ServiceCountry;

        this.sdk = getSDK(this.serviceCountry);
        if (!this.sdk) {
            Logger.log(`[NielsenTracker] serviceCountry: ${this.serviceCountry} not supported`);
            this.disabled = true;
        }
    }

    initialize({ appId, nol_sdkDebug, contentMetadata, isLive }: { appId: string, nol_sdkDebug: string | undefined, contentMetadata: TNielsenContentMetadataDenmark | TNielsenContentMetadataSweden, isLive: boolean }) {
        if (this.initialized || this.disabled) {
            return;
        }
        this.initialized = true;

        this.nlsn = this.sdk.nlsQ(appId, "nlsnInstance", { nol_sdkDebug: nol_sdkDebug?.toLowerCase() })
        this.isLive = isLive;

        if (isSwedenMetadata(contentMetadata)) {
            this.contentMetadata = {
                ...contentMetadata,
                plugv: this.player.playerVersion
            };
        } else {
            this.contentMetadata = {
                ...contentMetadata
            }
        }

        this.loadMetadata(this.contentMetadata);

        this.onWindowUnload = () => this.track(NielsenEvent.END, this.lastPosition);
        window.addEventListener('beforeunload', this.onWindowUnload);
    }

    private track(
        event: NielsenEvent,
        payload: NielsenContentMetadata | NielsenAdMetadata | number,
    ): void {
        // Do not track if not ready
        if (!this.initialized || !this.currentMetadata) return;

        // Do not track if session has ended
        if (this.sessionEnded) return

        // Prevent multiple stopped events from triggering (e.g. pausing
        // during a buffer)
        if (this.isStopped && event === NielsenEvent.STOP) {
            return;
        }

        if (event === NielsenEvent.STOP) {
            this.isStopped = true;
        }

        this.nlsn?.ggPM(event, payload);
    }

    private loadMetadata(metadata: NielsenContentMetadata | NielsenAdMetadata): void {
        if (metadata === this.currentMetadata) {
            return;
        }
        this.currentMetadata = metadata;

        this.track(NielsenEvent.LOAD_METADATA, metadata);
    }

    private setPlayheadPosition(currentTime: number): void {
        if (this.isLive && this.utcStartTime === null) {
            Logger.warn("[NielsenTracker] tried to track playhead position of live content without seekRangeEnd set")
            // live content _must_ have a utcStartTime set to track time
            return;
        }

        const position = this.convertToPlayheadPosition(currentTime);

        if (position !== this.lastPosition) {
            this.isStopped = false;
            this.lastPosition = position;
            this.track(NielsenEvent.SET_PLAYHEAD_POSITION, position);
        }
    }

    private convertToPlayheadPosition(currentTime: number): number {
        if (this.isLive && this.utcStartTime) {
            return Math.round(this.utcStartTime + currentTime);
        }
        return Math.round(currentTime);
    }

    setSeekRangeEnd(seekRangeEndInSeconds: number): void {
        if (!this.utcStartTime) {
            this.utcStartTime = (Date.now() - seekRangeEndInSeconds * 1000) / 1000;
        }
    }

    advertisementStarted({ payload }: AdvertisementStartedEvent): void {
        const adMetadata: NielsenAdMetadata = {
            type: getAdType(payload.breakType),
            adidx: `${payload.positionInAdBreak ?? 0}/${payload.totalAdsInAdBreak ?? 0}`,
            assetid: payload.customId || payload.id,
            length: Math.round(payload.durationInSeconds).toString(),
            isprogrammatic: payload.name.includes("programmatic") ? "yes" : "no"
        };

        this.loadMetadata(adMetadata);
    }

    advertisementBreakStarted(event: AdvertisementBreakStartedEvent) {
        if (event.payload.breakType === AdvertisementBreakTypes.MIDROLL) {
            this.track(NielsenEvent.STOP, this.lastPosition);
        }
    }

    advertisementBreakEnded() {
        if (this.contentMetadata) {
            this.loadMetadata(this.contentMetadata)
        }
    }

    advertisementTimeUpdate({ payload }: AdvertisementTimeUpdateEvent): void {
        if (this.isLive) {
            this.setPlayheadPosition(payload.currentTime);
        } else {
            this.setPlayheadPosition(payload.advertisementCurrentTime);
        }
    }

    advertisementEnded(event: AdvertisementEndedEvent): void {
        this.track(NielsenEvent.STOP, this.lastPosition);
    }

    seeking(event: SeekingEvent): void {
        this.isSeeking = true;
        this.track(NielsenEvent.STOP, this.lastPosition);
    }

    seeked(event: SeekedEvent): void {
        this.isSeeking = false;
    }

    buffering(event: BufferingEvent): void {
        this.track(NielsenEvent.STOP, this.lastPosition);
    }

    paused(event: PausedEvent): void {
        this.track(NielsenEvent.STOP, this.lastPosition);
    }

    stopped({ payload }: StoppedEvent): void {
        this.track(NielsenEvent.END, this.lastPosition);
        this.sessionEnded = true;
    }

    timeUpdate({ payload }: TimeUpdateEvent): void {
        if (this.isSeeking) {
            return;
        }
        this.setPlayheadPosition(payload.currentTime);
    }

    trackingInfo({ payload }: TrackingInfoEvent): void {
        const { tracking, content } = payload;

        if (tracking?.NIELSEN && content?.playbackSpec?.watchMode) {
            const { NIELSEN } = tracking;
            const watchMode = content?.playbackSpec?.watchMode;

            this.initialize({
                appId: NIELSEN.appInformation.appid,
                nol_sdkDebug: NIELSEN.appInformation.nol_devDebug,
                contentMetadata: NIELSEN.contentMetadata,
                isLive: watchMode === WatchMode.LIVE,
            });
        }
    };

    reset() {
        this.initialized = false;

        this.nlsn = null;
        this.isLive = false;
        this.contentMetadata = undefined;

        this.utcStartTime = null;
        this.lastPosition = -1;
        this.currentMetadata = null;
        this.sessionEnded = false;
        this.isStopped = false;

        if (this.onWindowUnload) {
            window.removeEventListener("beforeunload", this.onWindowUnload);
        }

        return super.reset();
    }

    destroy() {
        this.reset();
        return super.destroy();
    }
}
