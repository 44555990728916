const { Content, HandledError, Load, ReceiverInformation, Shutdown } = require('../dtos/');
const ReceiverInterface = require('./ReceiverInterface');

module.exports = class IServiceLayer {
    /**
     * @param {DefaultServiceLayerConfiguration} config
     * @param {ReceiverInterface} receiver
     */
    constructor(config, receiver) {
        this.config = config;
        /** @type {ReceiverInterface} */
        this.receiver = receiver;
    }

    initialize() {
        return this.resolve();
    }

    /**
     * @param {Content} content
     * @return {boolean}
     */
    canHandleContent(content) {
        return true;
    }

    /**
     * @param {ReceiverError} error
     * @returns {Promise<HandledError>}
     */
    handleError(error) {
        return this.resolve(new HandledError(error));
    }

    /**
     * @param {Load} load
     * @param {ReceiverInformation} receiverInformation
     * @return {Promise<Load>}
     */
    handleLoad(load, receiverInformation) {
        return this.resolve(load);
    }

    /**
     * @return {Promise<void>}
     */
    handleLoadNextContent() {
        return this.resolve();
    }

    handleReset() {
        return this.resolve();
    }

    /**
     * @param {Shutdown} shutdown
     * @return {Promise<void>}
     */
    handleShutdown(shutdown) {
        return this.resolve();
    }

    /** @protected */
    handlePause() {
        return this.resolve();
    }

    /** @protected */
    handlePlay() {
        return this.resolve();
    }

    /** @private */
    destroy() {
        return this.resolve();
    }

    /** @private */
    handleSeek(dto) {
        return this.resolve(dto);
    }

    /** @private */
    handleSetActiveTracks(dto) {
        return this.resolve(dto);
    }

    /** @private */
    handleSetAudioTrack(language) {
        return this.resolve(language);
    }

    /** @private */
    handleSetTextTrack(language) {
        return this.resolve(language);
    }

    /** @private */
    mapCustomDataToLoadDto(customData) {
        return customData;
    }

    /** @private */
    mapEntity(entity) {
        return entity;
    }

    /** @private */
    mapMediaStatusRequest(dto) {
        return dto;
    }

    /** @private */
    reject(value) {
        return Promise.reject(value);
    }

    /** @private */
    resolve(value) {
        return Promise.resolve(value);
    }
};