import { Id3Cue } from '../../../../Types';
import { TYospaceTimedDataObject } from '../Types';

//export const parseYospaceTimedDataObjectFromEmsgData = (emsgData: Uint8Array, startTime: number): TYospaceTimedDataObject => {}; // ToDo:

export const parseYospaceTimedDataObjectFromId3Cues = (id3Cue: Id3Cue): TYospaceTimedDataObject => ({
    time: id3Cue.startTime,
    tag: id3Cue.tags.reduce((acc, curr) => {
        return {
            ...acc,
            [curr.key]: String.fromCharCode(...Array.from(curr.data))
        }
    }, {})
});
