import { PlaybackEventBase } from './PlaybackEventBaseClass';
import { PlaybackEventTypes } from "../Constants";

type TBitrateChangedEventPayload = {
    bitrate: number;
    height?: number;
    width?: number;
}

export class BitrateChangedEvent extends PlaybackEventBase<PlaybackEventTypes.BITRATE_CHANGED, TBitrateChangedEventPayload> {
    constructor(payload: TBitrateChangedEventPayload) {
        super(PlaybackEventTypes.BITRATE_CHANGED, payload);
    }
}
