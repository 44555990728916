//
//  Wacka.js
//  Built with contempt by Daniel and Lars
//  Copyspite © 09/10/2019 All rights revoked.
//

const {
    WackaEvents
} = require('../Events');

const IPlayer = require('./IPlayer');
const LanguageMapper = require('../mappers/LanguageMapper');
const Utils = require('../utils/Utils');
const WackaRestrictionHelper = require('./helpers/WackaRestrictionHelper');
module.exports = class Wacka extends IPlayer {
    static get Utils() {
        return {
            CreateVariantTrack: (levelIndex, level, audioIndex, audio = { lang: 'en' }) => {
                return {
                    active: false,
                    audioBandwidth: 0,
                    videoBandwidth: level.bitrate,
                    audioCodec: audio.audioCodec || level.audioCodec,
                    audioId: audioIndex,
                    audioRoles: ['main'],
                    bandwidth: level.bitrate,
                    channelsCount: 2,
                    codecs: `${level.videoCodec}, ${level.audioCodec}`,
                    frameRate: 25,
                    width: level.width,
                    height: level.height,
                    id: 10 + levelIndex,
                    kind: null,
                    label: null,
                    language: audio.lang,
                    mimeType: 'video/mp4',
                    originalTextId: null,
                    primary: true,
                    roles: ['main'],
                    type: 'variant',
                    videoCodec: level.videoCodec,
                    videoId: levelIndex
                }
            },
            TextTrackToVariantTrack: (index, language) => {
                return {
                    active: false,
                    audioBandwidth: null,
                    videoBandwidth: null,
                    audioCodec: null,
                    audioId: null,
                    audioRoles: null,
                    bandwidth: 0,
                    channelsCount: null,
                    codecs: null,
                    frameRate: null,
                    width: null,
                    height: null,
                    id: 1 + index,
                    kind: 'subtitle',
                    label: null,
                    language: language || 'sv',
                    mimeType: 'text/vtt',
                    originalTextId: '' + index,
                    primary: true,
                    roles: [],
                    type: 'text',
                    videoCodec: null,
                    videoId: null
                }
            }
        }
    }

    static isSupported() {
        return 'Hls' in window;
    }

    constructor({ getNewLicense, license, mediaElement, textContainer }) {
        super({ getNewLicense, license, mediaElement, textContainer });

        this.destroyed = false;

        this.audioLanguageReverseMap = {};
        this.audioTracks = {};
        this.buffering = false;
        this.bufferingTimeout = null;
        this.currentLanguage = 'en';
        this.initialized = false;
        this.live = false;
        this.maxBufferingTime = 60e3;
        this.mediaElementEventListeners = {};
        this.playerEventListeners = {};
        this.recoverDecodeErrorTime = null;
        this.recoverNetworkErrorTime = null;
        this.recoverSwapAudioCodecTime = null;
        this.recoverStart = null;
        this.recoverTimeout = null;
        this.restrictionHelper = new WackaRestrictionHelper();
        this.seekableRange = {
            end: 0,
            start: Infinity
        };
        this.selectedSubtitleLanguage = null;
        this.shakaConfiguration = {};
        this.subtitleLanguageReverseMap = {};
        this.targetDuration = null;
        this.trackingBitrateValues = {};
        this.variantTracks = {};

        this.hlsOptions = {};

        console.log(`*******************************************************************`);
        console.log(`*** Wacka Initialized!!!`);
        console.log(`*******************************************************************`);

        const createErrorSimulator = code => () => {
            // this.emit(WackaEvents.Error, {
            //   type: Hls.ErrorTypes.MEDIA_ERROR,
            //   details: 'Wacka.FailedToRecover',
            //   fatal: true,
            //   originalError: data
            // });
        }

        // this.simulateUnknownError = ()=>{
        //     this.player.emit(Hls.Events.ERROR, {
        //   type:Hls.ErrorTypes.MEDIA_ERROR,
        //   fatal: true
        // })
        // }
        // this.simulateHttpError = ()=>{
        //     this.player.emit(Hls.Events.ERROR, {
        //   type:Hls.ErrorTypes.MEDIA_ERROR,
        //   fatal: true
        // })
        // }
        this.simulateVideoError = () => {
            setTimeout(() => {
                //Deferring pipe call
                this.emit(WackaEvents.Error, {
                    type: Hls.ErrorTypes.MEDIA_ERROR,
                    details: 'Wacka.FailedToRecover',
                    fatal: true,
                    originalError: {
                        type: 'SimulatedVideoError'
                    }
                });
            }, 1);
            return Promise.resolve('Done');
        }
    }

    /** @private */
    getDrmStreamHlsJsOptionsOverrides() {
        const castlabsToken = this.license.headers["x-dt-auth-token"];
        const castlabsServer = this.license.server;

        return {
            emeEnabled: true,
            debug: false,
            liveMaxLatencyDuration: Infinity,
            useUselessMux: false,
            widevineLicenseUrl: this.license.server,
            liveBackBufferLength: 20,
            startPosition: -1,
            drmSystems: {
                "com.widevine.alpha": {
                    licenseUrl: castlabsServer,
                },
                "com.microsoft.playready": {
                    licenseUrl: castlabsServer,
                },
            },
            drmSystemOptions: {
                audioEncryptionScheme: 'cbcs',
                videoEncryptionScheme: 'cbcs',
            },
            licenseXhrSetup: (xhr, _url, keyContext, licenseChallenge) => {
                const { keySystem } = keyContext;
                if (!castlabsToken) return licenseChallenge;

                if (
                    keySystem === "com.widevine.alpha" ||
                    keySystem === "com.microsoft.playready"
                ) {
                    xhr.setRequestHeader("x-dt-auth-token", castlabsToken);
                }

                return licenseChallenge;
            },
            licenseResponseCallback: (xhr, _url) => {
                const { response } = xhr;
                return response;
            },
            requestMediaKeySystemAccessFunc: (
                keySystem,
                supportedConfigurations
            ) => {
                const essentialConfigurations = supportedConfigurations.map(config => ({
                    audioCapabilities: config.audioCapabilities,
                    videoCapabilities: config.videoCapabilities,
                }));
                return navigator.requestMediaKeySystemAccess(keySystem, essentialConfigurations);
            },
            liveSyncDuration: 15,
        };
    }

    /** @private */
    recoverMediaError() {
        const position = this.mediaElement.currentTime - 1;
        this.player.recoverMediaError();
        this.listenOnce(this.mediaElement, this.mediaElementEventListeners, 'play', () => {
            this.mediaElement.currentTime = position;
        });

        this.recoverStart = Date.now();
        this.recoverTimeout = setTimeout(() => {
            this.emit(WackaEvents.Error, {
                type: Hls.ErrorTypes.MEDIA_ERROR,
                details: 'Wacka.FailedToRecover',
                fatal: true
            });
        }, 10000);
    }

    registerEventListeners() {
        // const clearBufferingTimeout = () => {
        //   if (this.bufferingTimeout) {
        //     this.bufferingTimeout = clearTimeout(this.bufferingTimeout);
        //   }
        // };
        //
        // this.listen(this.mediaElement, this.mediaElementEventListeners, 'waiting', () => {
        //   if (this.bufferingTimeout) return;
        //
        //   this.bufferingTimeout = setTimeout(() => {
        //     this.emit(WackaEvents.Error, {
        //       type: Hls.ErrorTypes.NETWORK_ERROR,
        //       details: 'Wacka.BufferingTimeout',
        //       currentTime: this.mediaElement.currentTime,
        //       fatal: true
        //     });
        //
        //     this.bufferingTimeout = null;
        //   }, this.maxBufferingTime);
        // });
        //
        // this.listen(this.mediaElement, this.mediaElementEventListeners, 'playing', clearBufferingTimeout);
        //
        // this.listen(this.mediaElement, this.mediaElementEventListeners, 'seeked', clearBufferingTimeout);

        this.listen(this.mediaElement, this.mediaElementEventListeners, 'timeupdate', () => {
            if (this.recoverTimeout && this.recoverStart) {
                const timeSinceRecoverStart = Date.now() - this.recoverStart;
                if (1000 <= timeSinceRecoverStart) {
                    this.recoverStart = null;
                    this.recoverTimeout = clearTimeout(this.recoverTimeout);
                }
            }
        });

        this.listen(this.player, this.playerEventListeners, Hls.Events.AUDIO_TRACK_SWITCHED, (event, data) => {
            let newAudioTrack = (this.player.audioTracks || []).find(a => a.id === (data || {}).id);
            if (newAudioTrack && newAudioTrack.lang) {
                this.currentLanguage = LanguageMapper.mapISOCodeFromAlpha2or3to2(newAudioTrack.lang);
                if (this.player.currentLevel && this.player.currentLevel !== -1) {
                    this.setActiveVariantTrack();
                }
            }
        });


        this.listen(this.player, this.playerEventListeners, Hls.Events.ERROR, (event, data) => {
            /**
             * Workaround for a bug in Hls.Js (0.14.6) where this scenario never leads to a fatal error even though it is not
             * recoverable. This happens when the source buffer gets full and breaks the video decoding pipeline.
             */
            if (
                data.type === Hls.ErrorTypes.MEDIA_ERROR &&
                data.details === Hls.ErrorDetails.BUFFER_APPENDING_ERROR &&
                this.mediaElement.error &&
                this.mediaElement.error.code === 3
            ) {
                this.recoverMediaError();
                return this.emit(WackaEvents.Error, {
                    type: Hls.ErrorTypes.MEDIA_ERROR,
                    details: 'Wacka.SourceBufferFull',
                    fatal: false
                });
            }

            if (data.details === Hls.ErrorDetails.BUFFER_STALLED_ERROR) {
                this.buffering = true;
            }

            if (data.fatal) {
                let tryRecover = true;
                let now = Date.now();
                switch (data.type) {
                    case Hls.ErrorTypes.MEDIA_ERROR:
                        if (!this.recoverDecodeErrorTime || 3000 < (now - this.recoverDecodeErrorTime)) {
                            this.recoverDecodeErrorTime = now;
                            this.recoverMediaError();
                        } else if (!this.recoverSwapAudioCodecTime || 3000 < (now - this.recoverSwapAudioCodecTime)) {
                            this.recoverDecodeErrorTime = this.recoverSwapAudioCodecTime = now;
                            this.player.swapAudioCodec();
                            this.recoverMediaError();
                        } else {
                            tryRecover = false;
                        }
                        break;
                    case Hls.ErrorTypes.NETWORK_ERROR:
                        if (!this.recoverNetworkErrorTime || 60000 < (now - this.recoverNetworkErrorTime)) {
                            this.recoverNetworkErrorTime = now;
                            const isManifestError = [
                                Hls.ErrorDetails.MANIFEST_LOAD_ERROR,
                                Hls.ErrorDetails.MANIFEST_LOAD_TIMEOUT,
                                Hls.ErrorDetails.MANIFEST_PARSING_ERROR
                            ].includes(data.details);
                            if (isManifestError) {
                                this.player.loadSource(this.player.url);
                            } else {
                                this.player.startLoad();
                            }
                        } else {
                            tryRecover = false;
                        }
                        break;
                    default:
                        console.error('Wacka::onError: An unrecoverable error occurred');
                        tryRecover = false;
                        break;
                }

                console.error(`Wacka::onError: Fatal error, type: ${data.type}, details: ${data.details}, trying to recover: ${tryRecover}`);

                if (!tryRecover) {
                    if (this.recoverTimeout) {
                        this.recoverStart = null;
                        this.recoverTimeout = clearTimeout(this.recoverTimeout);

                        if (data.frag && data.frag.loader) { // data.frag.loader has a circular reference
                            delete data.frag.loader;
                        }

                        this.emit(WackaEvents.Error, {
                            type: Hls.ErrorTypes.MEDIA_ERROR,
                            details: 'Wacka.FailedToRecover',
                            fatal: true,
                            originalError: data
                        });
                    } else {
                        this.emit(WackaEvents.Error, data);
                    }

                    this.destroy();
                }
            }
        });

        /**
         * unused due to cool shit done in FetchExtension.js :)
         */
        // this.listen(this.player, this.playerEventListeners, Hls.Events.FRAG_LOADED, (event, data) => {
        //   if (data.networkDetails) {
        //     const headers = data.networkDetails.getAllResponseHeaders();
        //     if (headers.includes('x-cdn-forward')) {
        //       const newCdn = data.networkDetails.getResponseHeader('x-cdn-forward');
        //       if (this.currentCdn !== newCdn) {
        //         this.currentCdn = newCdn;
        //         this.emit(WackaEvents.CdnDiscovered, {
        //           cdn: this.currentCdn
        //         });
        //       }
        //     }
        //   }
        // });

        this.listen(this.player, this.playerEventListeners, Hls.Events.FRAG_BUFFERED, (event, data) => {
            this.buffering = false;
        });

        this.listen(this.player, this.playerEventListeners, Hls.Events.FRAG_PARSING_METADATA, (event, data) => {
            if (data.samples) {
                for (let i in data.samples) {
                    if (!data.samples.hasOwnProperty(i)) continue;

                    this.emit(WackaEvents.Id3, {
                        segmentData: data.samples[i].data,
                        timestamp: data.samples[i].pts
                    });
                }
            }
            if (!this.initialized) {
                this.initialized = true;
                this.setInitSubsAndAudio();
            }
        });

        this.listen(this.player, this.playerEventListeners, Hls.Events.LEVEL_SWITCHED, (event, data) => {
            let active = this.setActiveVariantTrack();
            if (active) {
                return this.emit('adaptation', active.videoBandwidth);
            }

            console.warn('Wacka::onLevelSwitched: Missing active track!');
        });

        this.listen(this.player, this.playerEventListeners, Hls.Events.LEVEL_UPDATED, (event, level) => {
            const getFragmentsRange = fragments => {
                const range = {};

                if (fragments.length) {
                    const firstFragment = fragments[0];
                    range.start = firstFragment.start;

                    const lastFragment = fragments[fragments.length - 1];
                    if (lastFragment && lastFragment.start && lastFragment.duration) {
                        range.end = lastFragment.start + lastFragment.duration;
                    }
                }

                return ('start' in range && 'end' in range)
                    ? range
                    : {
                        end: 0,
                        start: Infinity
                    };
            };

            const details = (level && level.details) || {};
            this.live = details.live;
            this.seekableRange = getFragmentsRange(details.fragments || []);

            if (details.targetduration && details.targetduration >= 0 && !this.targetDuration) {
                this.targetDuration = details.targetduration;
                this.hlsOptions.backBufferLength = this.targetDuration;
            }
        });

        this.listen(this.player, this.playerEventListeners, Hls.Events.MANIFEST_PARSED, (event, data) => {
            if (!data.audioTracks.length) {
                data.audioTracks.push({
                    id: 0,
                    lang: 'en'
                });
            }

            if ((Object.keys(this.player.levels).length * data.audioTracks.length) !== Object.keys(this.variantTracks).length) {
                let counter = 1;
                let audioTracksCounters = {};
                for (let a in data.audioTracks) {
                    if (!data.audioTracks.hasOwnProperty(a)) continue;

                    data.audioTracks[a].lang = data.audioTracks[a].lang || 'en';
                    let language = LanguageMapper.mapISOCodeFromAlpha2or3to2(data.audioTracks[a].lang);
                    audioTracksCounters[language] = 1 + (audioTracksCounters[language] || 0);
                    if (audioTracksCounters[language] > 1) {
                        language += `-${audioTracksCounters[language]}`;
                    }

                    this.audioLanguageReverseMap[language] = data.audioTracks[a].lang;

                    data.audioTracks[a].lang = language;
                    this.audioTracks[language] = data.audioTracks[a].id;

                    for (let i in this.player.levels) {
                        if (!this.player.levels.hasOwnProperty(i)) continue;

                        let vTrack = Wacka.Utils.CreateVariantTrack(i, this.player.levels[i], a, data.audioTracks[a]);
                        this.trackingBitrateValues[vTrack.videoBandwidth] = {
                            bitrate: vTrack.videoBandwidth,
                            width: vTrack.width,
                            height: vTrack.height
                        };
                        this.variantTracks[counter] = vTrack;
                        counter++;
                    }
                }

                this.emit(WackaEvents.BitratesReady, this.trackingBitrateValues);
            }

            if (data && data.levels && data.levels.length > 0) {
                const topQualityBitrates = data.levels.filter(level => level.width === 1920);
                if (topQualityBitrates.length > 1) { // Duplicate top quality bitrates - cap to lowest one
                    this.player.autoLevelCapping = data.levels.length - 2;
                }
            }

            this.hlsOptions.startPosition = -1;
        });
    }

    unregisterEventListeners() {
        Object.keys(this.mediaElementEventListeners).forEach(e =>
            this.mediaElement.removeEventListener(e,
                this.mediaElementEventListeners[e]
            )
        );
        this.mediaElementEventListeners = {};

        Object.keys(this.playerEventListeners).forEach(e =>
            this.player.off(e,
                this.playerEventListeners[e]
            )
        );
        this.playerEventListeners = {};
    };

    setInitSubsAndAudio() {
        if (this.mediaElement && this.mediaElement.textTracks && this.mediaElement.textTracks.length) {
            for (let i = 0; i < this.mediaElement.textTracks.length; i++) {
                const language = LanguageMapper.mapISOCodeFromAlpha2or3to2(this.mediaElement.textTracks[i].language);
                this.subtitleLanguageReverseMap[language] = this.mediaElement.textTracks[i].language;

                if (!this.shakaConfiguration.preferredTextLanguage) continue;

                this.mediaElement.textTracks[i].mode = 'hidden';
                if (language === this.shakaConfiguration.preferredTextLanguage) {
                    this.mediaElement.textTracks[i].mode = 'showing';
                }
            }
        }

        this.selectAudioLanguage(this.shakaConfiguration.preferredAudioLanguage);
    }

    load(source, startTime) {
        return new Promise(resolve => {
            try {
                if (this.player) { // To handle cases of retries when Caf has not destroyed the player yet
                    this.reset();
                    this.player.destroy();
                    this.player = null;
                }

                this.player = new Hls(Utils.extend(
                    this.hlsOptions,
                    startTime ? { startPosition: startTime } : {}
                ));

                //this.restrictionHelper.setPlayer(this.player);
                this.registerEventListeners();

                this.player.once(Hls.Events.LEVEL_UPDATED, resolve);

                this.player.attachMedia(this.mediaElement);
                this.player.loadSource(source);
            } catch (e) {
                console.error('Wacka::load: Failed to load:', e);
                this.emit(WackaEvents.Error, {
                    type: Hls.ErrorTypes.OTHER_ERROR,
                    details: 'Wacka.LoadError',
                    error: e,
                    fatal: true
                });
            }
        });
    }

    configure(configOrKey, value) {
        if (Utils.isString(configOrKey)) {
            configOrKey = Utils.pathToObject(configOrKey, value);
        }

        if (!this.isShakaConfiguration(configOrKey)) {
            this.hlsOptions = Utils.extend({}, this.hlsOptions || {}, configOrKey);

            if (this.license) {
                this.hlsOptions = Utils.extend(this.hlsOptions, this.getDrmStreamHlsJsOptionsOverrides());
            }
        } else {
            // The 'unsetting' of shaka restrictions requires the ability to set values to undefined
            this.shakaConfiguration = Utils.extend(/* allowUndefinedValues */ true, {}, this.shakaConfiguration || {}, configOrKey);
        }
    }

    // applyRestriction(){
    //   parseInt(attrs['FRAME-RATE'])
    //   width
    //   height
    //   bitrate
    // }

    getConfiguration() {
        return this.shakaConfiguration;
    }

    getManifest() {
        return {
            //presentationTimeline: null,
            //periods: null,
            //offlineSessionIds: [],
            //minBufferTime: 0
        };
    }

    getNetworkingEngine() {
        return this.networkingEngine;
    }

    getStats() {
        return {
            bufferingTime: 0,
            completionPercent: 0 < (this.mediaElement.duration || 0) ? Math.round((this.mediaElement.currentTime || 0) / (this.mediaElement.duration || 1)) : 0,
            corruptedFrames: 0,
            decodedFrames: (this.mediaElement.currentTime || 0) * 25,
            drmTimeSeconds: 0,
            droppedFrames: 0,
            estimatedBandwidth: 1E7,
            gapsJumped: 0,
            height: window.screen.availHeight,
            licenseTime: 0,
            liveLatency: this.isLive() ? 1 : NaN,
            loadLatency: 0,
            manifestTimeSeconds: 1,
            maxSegmentDuration: 10,
            pauseTime: 0,
            playTime: this.mediaElement.currentTime || 0,
            stallsDetected: 0,
            stateHistory: [],
            streamBandwidth: 8E6,
            switchHistory: [],
            width: window.screen.availWidth
        };
    }

    getTextTracks() {
        let textTracks = [];
        const supportedKinds = ['subtitles', 'caption', 'captions'];
        if (this.mediaElement && this.mediaElement.textTracks && this.mediaElement.textTracks.length) {
            for (let i = 0; i < this.mediaElement.textTracks.length; i++) {
                if (supportedKinds.includes(this.mediaElement.textTracks[i].kind)) {
                    const language = LanguageMapper.mapISOCodeFromAlpha2or3to2(this.mediaElement.textTracks[i].language);
                    textTracks.push(Wacka.Utils.TextTrackToVariantTrack(i, language));
                }
            }
        }

        return textTracks;
    }

    getVariantTracks() {
        return Object.values(this.variantTracks);
    }

    isBuffering() {
        return this.buffering;
    }

    isInProgress() { // Not applicable for HLS, always false
        return false;
    }

    isLive() {
        return this.live;
    }

    seekRange() {
        return this.seekableRange;
    }

    selectAudioLanguage(language) {
        if (language in this.audioTracks) {
            this.player.audioTrack = this.audioTracks[language];
        }
    }

    selectTextTrack(track) {
        if (this.mediaElement && this.mediaElement.textTracks && this.mediaElement.textTracks.length) {
            for (let i = 0; i < this.mediaElement.textTracks.length; i++) {
                this.mediaElement.textTracks[i].mode = 'hidden';
                const language = LanguageMapper.mapISOCodeFromAlpha2or3to2(this.mediaElement.textTracks[i].language);
                if (language === track.language) {
                    this.selectedSubtitleLanguage = track.language;
                    this.mediaElement.textTracks[i].mode = 'showing';
                }
            }
        }
    }

    setActiveVariantTrack() {
        let active = null;
        if (
            Object.keys(this.variantTracks).length > 0 &&
            this.player.levels &&
            this.player.currentLevel >= 0 &&
            this.player.levels[this.player.currentLevel] &&
            this.player.levels[this.player.currentLevel].bitrate
        ) {
            let currentBitrate = this.player.levels[this.player.currentLevel].bitrate;
            for (let i in this.variantTracks) {
                if (!this.variantTracks.hasOwnProperty(i)) continue;

                if (
                    this.variantTracks[i].videoBandwidth === currentBitrate &&
                    this.variantTracks[i].language === this.currentLanguage
                ) {
                    this.variantTracks[i].active = true;
                    active = this.variantTracks[i];
                } else {
                    this.variantTracks[i].active = false
                }
            }
        }

        return active;
    }

    setTextTrackVisibility(visible) {
        if (this.mediaElement && this.mediaElement.textTracks && this.mediaElement.textTracks.length) {
            for (let i = 0; i < this.mediaElement.textTracks.length; i++) {
                this.mediaElement.textTracks[i].mode = 'hidden';
                const language = LanguageMapper.mapISOCodeFromAlpha2or3to2(this.mediaElement.textTracks[i].language);
                if (visible && language === this.selectedSubtitleLanguage) {
                    this.mediaElement.textTracks[i].mode = 'showing';
                }
            }
        }

        return Promise.resolve();
    }

    reset() {
        if (this.bufferingTimeout) {
            this.bufferingTimeout = clearTimeout(this.bufferingTimeout);
        }

        if (this.recoverTimeout) {
            this.recoverTimeout = clearTimeout(this.recoverTimeout);
        }

        this.buffering = false;
        this.recoverDecodeErrorTime = null;
        this.recoverNetworkErrorTime = null;
        this.recoverSwapAudioCodecTime = null;
        this.recoverStart = null;
        this.seekableRange = {
            end: 0,
            start: Infinity
        };
        this.targetDuration = null;
    }

    destroy() {
        this.destroyed = true;

        return new Promise((resolve) => {
            this.removeAllListeners();

            this.unregisterEventListeners();

            this.reset();

            this.audioLanguageReverseMap = {};
            this.audioTracks = {};
            this.currentLanguage = 'en';
            this.hlsOptions = {};
            this.initialized = false;
            this.license = null;
            this.live = false;
            this.selectedSubtitleLanguage = null;
            this.shakaConfiguration = {};
            this.subtitleLanguageReverseMap = {};
            this.textContainer = null;
            this.trackingBitrateValues = {};
            this.variantTracks = {};

            if (this.player) {
                this.player.destroy();
            }
            this.player = null;

            resolve();
        });
    }
};