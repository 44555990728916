const CommunicationManager = require('./communication/CommunicationManager');
const ContentManager = require('./content/ContentManager');
const ErrorManager = require('./error/ErrorManager');
const PlaybackManager = require('./playback/PlaybackManager');
const QueueManager = require('./queue/QueueManager');
const SessionManager = require('./session/SessionManager');
const TimelineManager = require('./timeline/TimelineManager');
const TrackingManager = require('./tracking/TrackingManager');
const TracksManager = require('./tracks/TracksManager');
const UiManager = require('./ui/UiManager');

module.exports = {
    CommunicationManager,
    ContentManager,
    ErrorManager,
    PlaybackManager,
    QueueManager,
    SessionManager,
    TimelineManager,
    TrackingManager,
    TracksManager,
    UiManager
};