//
//  ShakaEmsgFilter.js
//
//  Created by Lars Rothaus on 23/06/2021.
//  Copyright © 23/06/2021 Lars Rothaus. All rights reserved.
//

import { EmsgParser } from './EmsgParser';


type Emsg = {
    endTime: number;
    eventDuration: number;
    id: number;
    messageData: Uint8Array;
    presentationTimeDelta?: number;
    schemeIdUri: string;
    startTime: number;
    timescale: number;
    value: string;
    version: number;
};
type EmsgHandler = (emsg: Emsg) => void;

enum RequestType {
    APP = 3,
    LICENSE = 2,
    MANIFEST = 0,
    SEGMENT = 1,
    SERVER_CERTIFICATE = 5,
    TIMING = 4
};
type Response = {
    data: ArrayBuffer | ArrayBufferView;
    fromCache?: boolean;
    headers: { [key: string]: string };
    timeMs?: number;
    uri: string
};
type ResponseFilter = (a: RequestType, b: Response) => Promise<any> | void;

export class ShakaEmsgFilter {
    private emsgHandler: EmsgHandler;
    private mediaElement: HTMLMediaElement;

    constructor(emsgHandler: EmsgHandler, mediaElement: HTMLMediaElement) {
        this.emsgHandler = emsgHandler;
        this.mediaElement = mediaElement;
    }

    get responseFilter(): ResponseFilter {
        return (requestType: RequestType, response: Response): Promise<any> | void => {
            const resolvedResponse = Promise.resolve(response);
            if (requestType === RequestType.SEGMENT) {
                try {
                    if (response.data instanceof ArrayBuffer) {
                        const segment = new Uint8Array(response.data);
                        const emsgs = EmsgParser.Parse(segment);
                        if (emsgs && emsgs.length) {
                            const firstPresentationTime: number = emsgs[0].presentation_time || 0;

                            const startTime = this.mediaElement && this.mediaElement.buffered.length
                                ? this.mediaElement.buffered.end(this.mediaElement.buffered.length - 1)
                                : 0;
                            for (let emsg of emsgs) {
                                const segmentRelativePresentationTime = (emsg.presentation_time ?? 0) - firstPresentationTime;
                                const scaledStartTime = (startTime * emsg.timescale) + segmentRelativePresentationTime;

                                this.emsgHandler({
                                    endTime: scaledStartTime + emsg.event_duration,
                                    eventDuration: emsg.event_duration,
                                    id: emsg.id,
                                    messageData: emsg.message_data,
                                    presentationTimeDelta: emsg.presentation_time ? emsg.presentation_time - scaledStartTime : emsg.presentation_time_delta,
                                    schemeIdUri: emsg.scheme_id_uri,
                                    startTime: scaledStartTime,
                                    timescale: emsg.timescale,
                                    value: emsg.value,
                                    version: emsg.version
                                });
                            }
                        }
                    }
                    else {
                        console.warn('ShakaEmsgFilter::responseFilter: ShakaNetworkFilter response is not ArrayBuffer!');
                    }
                }
                catch (e) {
                    console.trace('ShakaEmsgFilter::responseFilter: ShakaNetworkFilter response parsing failed!', e);
                }
            }

            return resolvedResponse;
        }
    }
};
