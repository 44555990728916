import {
  AdvertisementStartedEvent, CustomTrackingEvent,
  StartingEvent,
  StoppedEvent,
  TClientName,
  TimeUpdateEvent,
  TrackingInfoEvent,
} from "@tv4/one-playback-sdk-shared";

import { TTrackerConfiguration } from "../Types";
import { CoreTrackingEvent, TAutoplayType, TDataLayerEventTypes } from "./types";
import { BaseTracker } from "../BaseTracker";
import { getAdEventType } from "./util";

const getUuid = (): string => {
  return "xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const rnd = (Math.random() * 16) % 16 | 0;
    return (c === "x" ? rnd : (rnd & 0x7) | 0x8).toString(16);
  });
};

type TCoreTrackerData = {
    content_media_id: string;
    content_title: string;
    player_session_id: string;
    // If the media was initiated by play next
    player_is_autoplay: boolean;
    // "sequence" is for series play next.
    player_autoplay_type: TAutoplayType;
}

type TRegisterUserProperties = {
  user_id: string;
  player_session_id: string;
}

export { CoreTrackingEvent };

export class CoreTracker extends BaseTracker {
  readonly clientName: TClientName;

  private userId: string | undefined;
  private trackingData: TCoreTrackerData | undefined;
  private initialized = false;
  private initialPlay = true;
  private player_session_id: string | undefined;
  private userPropertiesRegistered = true;
  private isPaused = false;

  private isInAdBreak = false;
  private sessionEnded = false;

  constructor({ device: { clientName }, user }: TTrackerConfiguration) {
    super("CoreTrackingTracker");

    this.clientName = clientName;
    this.userId = user?.userId;

    // This is handled by the client for web
    if (clientName === "ccr") {
      this.userPropertiesRegistered = false;
    }

    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
  }

  private contentPlaying(): void {
    if (!this.trackingData || this.sessionEnded) return;

    if (this.initialPlay) {
      this.initialPlay = false;

      this.pushToDataLayer({
        event: CoreTrackingEvent.STREAM_START,
        ...this.trackingData,
      });
    } else {
      this.pushToDataLayer({
        event: CoreTrackingEvent.STREAM_RESUME,
        ...this.trackingData,
      });
    }
  }

  public playing() {
    if (!this.trackingData) return;

    if (this.isPaused) {
      this.pushToDataLayer({
        event: CoreTrackingEvent.STREAM_RESUME,
        ...this.trackingData,
      });

      this.isPaused = false;
    }
  }

  public paused() {
    if (this.isInAdBreak) return;

    this.isPaused = true;
  }

  public registerUserProperties({
    user_id,
    player_session_id,
  }: TRegisterUserProperties) {
    if (this.userPropertiesRegistered) return;

    this.userPropertiesRegistered = true;

    this.pushToDataLayer({
      event: CoreTrackingEvent.SET_USER_PROPERTIES,
      user_id,
      player_session_id,
    });
  }

  public initialize({ trackingData, userId }: { trackingData: TCoreTrackerData, userId?: string }) {
    if (this.initialized) return;

    this.initialized = true;

    // Register user properties if user id was provided.
    // Otherwise it has to be done by a manual call to registerUserProperties.
    // This should happen before any other event is tracked.
    const user_id = this.userId || userId;

    if (user_id) {
      this.registerUserProperties({
        user_id,
        player_session_id: trackingData.player_session_id,
      });
    }

    this.trackingData = trackingData;
  }

  pushToDataLayer(payload: TDataLayerEventTypes): void{
    // @ts-ignore
    window.dataLayer.push(payload);
  };

  advertisementStarted({ payload: { name, id, customId, sponsor } }: AdvertisementStartedEvent): void {
    if (!this.trackingData || this.sessionEnded) return;

    this.pushToDataLayer({
      event: getAdEventType({
        name,
        sponsor,
      }),
      ad_id: getUuid(),
      custom_ad_id: id,
      ...this.trackingData
    })
  }

  custom({ payload: { name } }: CustomTrackingEvent) {
    if (!this.trackingData || this.sessionEnded) return;

    if (Object.values(CoreTrackingEvent).indexOf(name as CoreTrackingEvent) > -1) {
      this.pushToDataLayer({
        event: name as CoreTrackingEvent,
        ...this.trackingData,
      })
    }
  }

  advertisementBreakEnded(): void {
    if (!this.trackingData || this.sessionEnded) return;

    this.isInAdBreak = false;

    this.pushToDataLayer({
      event: CoreTrackingEvent.STREAM_AD_BREAK_END,
      ...this.trackingData
    });

    this.contentPlaying();
  }

  advertisementBreakStarted(): void {
    if (!this.trackingData || this.sessionEnded) return;
    this.isInAdBreak = true;

    this.pushToDataLayer({
      event: CoreTrackingEvent.STREAM_AD_BREAK_START,
      ...this.trackingData
    });
  }

  loading(): void {
    if (!this.trackingData || this.sessionEnded) return;

    this.pushToDataLayer({
      event: CoreTrackingEvent.STREAM_START_INIT,
      ...this.trackingData,
    });
  }

  starting({ payload: { playbackSessionId } }: StartingEvent): void {
    this.player_session_id = playbackSessionId;
  }

  stopped(event: StoppedEvent): void {
    this.sessionEnded = true;
  }

  timeUpdate(event: TimeUpdateEvent): void {
    if (!this.isInAdBreak && this.initialPlay) {
      this.contentPlaying();
    }
  }

  trackingInfo({
    payload: { tracking: { YOUBORA } = {}, content, user },
  }: TrackingInfoEvent): void {
    if (this.initialized) return;
    if (!content?.playbackSpec) return;
    if (!this.player_session_id) return;

    // TODO switch to tracking.CORE once it is implemented
    if (!YOUBORA) return;

    // Check if there is a user id, which is required for Chromecast devices
    if (!this.userPropertiesRegistered && this.clientName === "ccr" && (!this.userId && !user?.userId)) {
      // Exit if we have no id and no user object yet
      if (!user) return;

      // Exit if we do not have a logged in state yet
      if (typeof user.userIsLoggedIn !== "boolean") return;

      // Exit if the user is logged in, to wait for user id to show up before initializing
      if (user.userIsLoggedIn === true) return;

      // Allow users who are explicitly logged out to continue without user id
    }


    this.initialize({
      trackingData: {
        content_media_id: content.playbackSpec.videoId,
        content_title: YOUBORA.title,
        player_session_id: this.player_session_id,
        player_is_autoplay: content.playNext ?? false,
        player_autoplay_type: content.playNext ? "sequence" : "none",
      },
      userId: user?.userId || this.userId
    });
  }

  async reset(): Promise<void> {
    this.userId = undefined;
    this.trackingData = undefined;
    this.initialized = false;
    this.initialPlay = true;
    this.isPaused = false;
    this.player_session_id = undefined;

    if (this.clientName === "ccr") {
      this.userPropertiesRegistered = false;
    }

    this.isInAdBreak = false;
    this.sessionEnded = false;

    return Promise.resolve();
  }

  async destroy(): Promise<void> {
    return Promise.resolve();
  }
}
