const ListenableListener = require('../event/ListenableListener');

module.exports = class IShakaPlayer extends ListenableListener {
    constructor(mediaElement) {
        super();

        this.mediaElement = mediaElement;

        this.addEventListener = this.on.bind(this);
        this.removeEventListener = this.off.bind(this);

        this.networkingEngine = {
            registerRequestFilter: (a, b, c) => {
            },
            registerResponseFilter: (a, b, c) => {
            }
        };
    }

    configure(configOrKey, value) {
    }

    destroy() {
    }

    getConfiguration() {
    }

    getManifest() {
    }

    getNetworkingEngine() {
    }

    getTextTracks() {
    }

    getVariantTracks() {
    }

    isBuffering() {
    }

    isInProgress() {
    }

    isLive() {
    }

    load(src, startTime) {
    }

    seekRange() {
    }

    selectAudioLanguage(language) {
    }

    selectTextTrack(track) {
    }

    setTextTrackVisibility(visible) {
    }
};