const Metadata = require('./Metadata');
const Stream = require('./Stream');
const Utils = require('../utils/Utils');
const { RequestContentTypes } = require('../Constants');

/**
 * @typedef {object} TContentArgs
 * @property {string} [type]
 * @property {string} [accessControl]
 * @property {object} [auxiliaryData={}]
 * @property {string} contentId
 * @property {string} [contentType]
 * @property {Metadata} [metadata]
 * @property {boolean} [nextContent=false]
 * @property {Object<string, string>} [regionalChannels]
 * @property {string} [serviceCountry]
 * @property {StreamRestrictions} [streamRestrictions]
 * @property {Array<Stream>} [streams]
 * @property {string} [watchMode]
 */
/**
 * @type {TContentArgs}
 */

class Props {
    constructor() {
        this.type = this.accessControl = this.auxiliaryData = this.contentId = this.contentType = this.metadata = this.nextContent =
          this.regionalChannels = this.serviceCountry = this.streamRestrictions = this.streams = this.watchMode = undefined;
    }
}

class Content extends Props {
    /**
     * @param {TContentArgs} args
     */
    constructor(args = {}) {
        super();

        args.nextContent = Utils.booleanOrDefault(args.nextContent, false);

        Utils.extend(this, {
            type: RequestContentTypes.Standard,
            auxiliaryData: {},
            streams: []
        }, args);
    }
}

module.exports = Content;