import { AccessControl, WatchMode } from '@tv4/one-playback-sdk-shared';
import {AdType, DrmType, Packaging, WhiteLabelBrand } from '../Types'

export enum Accessibility {
    SIGNED = 'SIGNED',
    HARDOFHEARING = 'HARD_OF_HEARING',
    HEARINGINTERPRETED = 'HEARING_INTERPRETED'
}

export enum Capability {
    VC_H265 = 'VC_H265',
    YOSPACE_VOD = 'YOSPACE_VOD'
}

interface Device {
    deviceId?: string;
    category?: string;
    packagings?: Array<Packaging>;
    drmType?: DrmType;
    capabilities?: Array<Capability>;
    screen?: Screen;
    os?: string;
    vendor?: string;
    Data?: string;
}

export interface Drm {
    type?: DrmType;
}

export interface FairplayDrm extends VendorHeadersDrm {
    licenseUrl?: string;
    certificateUrl?: string;
    assetId?: string;
    type?: DrmType;
}

export enum LanguageCode {
    Undefined = 'undefined',
    Aa = 'aa',
    Ab = 'ab',
    Ae = 'ae',
    Af = 'af',
    Ak = 'ak',
    Am = 'am',
    An = 'an',
    Ar = 'ar',
    As = 'as',
    Av = 'av',
    Ay = 'ay',
    Az = 'az',
    Ba = 'ba',
    Be = 'be',
    Bg = 'bg',
    Bh = 'bh',
    Bi = 'bi',
    Bm = 'bm',
    Bn = 'bn',
    Bo = 'bo',
    Br = 'br',
    Bs = 'bs',
    Ca = 'ca',
    Ce = 'ce',
    Ch = 'ch',
    Co = 'co',
    Cr = 'cr',
    Cs = 'cs',
    Cu = 'cu',
    Cv = 'cv',
    Cy = 'cy',
    Da = 'da',
    De = 'de',
    Dv = 'dv',
    Dz = 'dz',
    Ee = 'ee',
    El = 'el',
    En = 'en',
    Eo = 'eo',
    Es = 'es',
    Et = 'et',
    Eu = 'eu',
    Fa = 'fa',
    Ff = 'ff',
    Fi = 'fi',
    Fj = 'fj',
    Fo = 'fo',
    Fr = 'fr',
    Fy = 'fy',
    Ga = 'ga',
    Gd = 'gd',
    Gl = 'gl',
    Gn = 'gn',
    Gu = 'gu',
    Gv = 'gv',
    Ha = 'ha',
    He = 'he',
    Hi = 'hi',
    Ho = 'ho',
    Hr = 'hr',
    Ht = 'ht',
    Hu = 'hu',
    Hy = 'hy',
    Hz = 'hz',
    Ia = 'ia',
    Id = 'id',
    Ie = 'ie',
    Ig = 'ig',
    Ii = 'ii',
    Ik = 'ik',
    Io = 'io',
    Is = 'is',
    It = 'it',
    Iu = 'iu',
    Ja = 'ja',
    Jv = 'jv',
    Ka = 'ka',
    Kg = 'kg',
    Ki = 'ki',
    Kj = 'kj',
    Kk = 'kk',
    Kl = 'kl',
    Km = 'km',
    Kn = 'kn',
    Ko = 'ko',
    Kr = 'kr',
    Ks = 'ks',
    Ku = 'ku',
    Kv = 'kv',
    Kw = 'kw',
    Ky = 'ky',
    La = 'la',
    Lb = 'lb',
    Lg = 'lg',
    Li = 'li',
    Ln = 'ln',
    Lo = 'lo',
    Lt = 'lt',
    Lu = 'lu',
    Lv = 'lv',
    Mg = 'mg',
    Mh = 'mh',
    Mi = 'mi',
    Mk = 'mk',
    Ml = 'ml',
    Mn = 'mn',
    Mr = 'mr',
    Ms = 'ms',
    Mt = 'mt',
    My = 'my',
    Na = 'na',
    Nb = 'nb',
    Nd = 'nd',
    Ne = 'ne',
    Ng = 'ng',
    Nl = 'nl',
    Nn = 'nn',
    No = 'no',
    Nr = 'nr',
    Nv = 'nv',
    Ny = 'ny',
    Oc = 'oc',
    Oj = 'oj',
    Om = 'om',
    Or = 'or',
    Os = 'os',
    Pa = 'pa',
    Pi = 'pi',
    Pl = 'pl',
    Ps = 'ps',
    Pt = 'pt',
    Qu = 'qu',
    Rm = 'rm',
    Rn = 'rn',
    Ro = 'ro',
    Ru = 'ru',
    Rw = 'rw',
    Sa = 'sa',
    Sc = 'sc',
    Sd = 'sd',
    Se = 'se',
    Sg = 'sg',
    Si = 'si',
    Sk = 'sk',
    Sl = 'sl',
    Sm = 'sm',
    Sn = 'sn',
    So = 'so',
    Sq = 'sq',
    Sr = 'sr',
    Ss = 'ss',
    St = 'st',
    Su = 'su',
    Sv = 'sv',
    Sw = 'sw',
    Ta = 'ta',
    Te = 'te',
    Tg = 'tg',
    Th = 'th',
    Ti = 'ti',
    Tk = 'tk',
    Tl = 'tl',
    Tn = 'tn',
    To = 'to',
    Tr = 'tr',
    Ts = 'ts',
    Tt = 'tt',
    Tw = 'tw',
    Ty = 'ty',
    Ug = 'ug',
    Uk = 'uk',
    Ur = 'ur',
    Uz = 'uz',
    Ve = 've',
    Vi = 'vi',
    Vo = 'vo',
    Wa = 'wa',
    Wo = 'wo',
    Xh = 'xh',
    Yi = 'yi',
    Yo = 'yo',
    Za = 'za',
    Zh = 'zh',
    Zu = 'zu'
}

export interface LogstashTracking extends Tracking {
    videoId?: string;
    videoIdType?: string;
    streamingProtocol?: string;
    sessionId?: string;
    assetId?: string;
}

export interface NoneDrm extends Drm {
    type?: DrmType;
}

export interface PlaybackRestrictions {
    noFastForward?: boolean;
    noPause?: boolean;
    noRewind?: boolean;
}
export interface PlayreadyDrm extends VendorHeadersDrm {
    licenseUrl?: string;
    type?: DrmType;
}
export interface Preferences {
    audioLanguage?: Array<LanguageCode>;
    accessibility?: Array<Accessibility>;
}
export interface Screen {
    width: number;
    height: number;
}
export interface Stream {
    url?: string;
    drm?: Drm;
    adType?: AdType;
    packaging?: Packaging;
}
export interface StreamingTicket {
    streamingUrl?: string;
    altStreamingUrl?: string;
    streamingUrlType?: StreamingUrlType;
    adServerUrl?: string;
    adManifestUrl?: string;
    token?: string;
    expires: number;
    ipAddress?: string;
    currentTime: number;
    noFastforward?: boolean;
    noPause?: boolean;
    noRewind?: boolean;
    validTo?: number;
    playbackId?: string;
    timeline?: Array<TimelineBlock>;
}
export interface StreamingTicketData {
    streams?: Array<Stream>;
    tracking?: { [key: string]: Tracking; };
    playbackRestrictions?: PlaybackRestrictions;
    timeLine?: Array<TimelineBlockData>;
}
export interface StreamingTicketRequest {
    sessionId?: string;
    whiteLabelBrand?: WhiteLabelBrand;
    watchMode?: WatchMode;
    accessControl?: AccessControl;
    device?: Device;
    preferences?: Preferences;
}
export enum StreamingUrlType {
    STREAM = 'STREAM',
    REQUESTROUTER = 'REQUEST_ROUTER'
}
export interface TimelineBlock {
    type?: TimelineBlockTypeData;
    offset: number;
    duration: number;
}
export interface TimelineBlockData {
    type?: TimelineBlockType;
    offset: number;
    duration: number;
}
export enum TimelineBlockType {
    OPENINGCREDITS = 'OPENING_CREDITS',
    CLOSINGCREDITS = 'CLOSING_CREDITS',
    RECAP = 'RECAP'
}
export enum TimelineBlockTypeData {
    OPENINGCREDITS = 'OPENING_CREDITS',
    CLOSINGCREDITS = 'CLOSING_CREDITS',
    RECAP = 'RECAP'
}
export interface Tracking {
}
export enum TrackingType {
    YOUBORA = 'YOUBORA',
    VIDEOTRACKING = 'VIDEOTRACKING',
    LOGSTASH = 'LOGSTASH'
}
export interface VendorHeadersDrm extends Drm {
    headers?: { [key: string]: string; };
}
export interface VerimatrixDrm extends Drm {
    address?: string;
    type?: DrmType;
}

export interface VideoTracking extends Tracking {
    videoId?: string;
    videoIdType?: string;
    assetId?: string;
}

export interface WidevineDrm extends VendorHeadersDrm {
    licenseUrl?: string;
    type?: DrmType;
}
export enum XmlNs0VideoIdType {
    CHANNEL = 'CHANNEL',
    MEDIA = 'MEDIA'
}
export interface YouboraTracking extends Tracking {
    videoIdType?: string;
    videoId?: string;
    title?: string;
    live?: boolean;
    streamingProtocol?: string;
    transactionCode?: string;
    assetId?: string;
}
