//
//  pauseAds.ts
//
//  Created by Lars Rothaus on 28/02/2022.

import { ServiceError } from '@tv4/one-playback-sdk';
import { EngineEvents, ReceiverEvents, ReceiverInterface } from '@tv4/unified-receiver';
import { XMLParser } from 'fast-xml-parser';
import { ClientServices, ServiceLayerInformation } from '../../Types';
import { getPersistentAdvertisementId } from '../playbackFeatures/yospace/YospaceUtils';
import { ErrorCategories } from '@tv4/one-playback-sdk-shared';

//|todo pauseAds.ts [BUG][Low]: (Erik) unsure what version should be put here;
const __VERSION__ = '1.0.0';

export type PauseAdsConfiguration = {
  adsPersistence: boolean;
};

/**
 * PauseAds
 * Implicit dependencies:
 * The PauseAds component is relying on finding an image-tag with an id of pauseImage Somewhere in the DOM
 *
 */
export class PauseAds {
  private adsProxyUrl?: URL;
  private currentAd?: AdsData;
  private config: PauseAdsConfiguration;
  private receiverEvents: Record<string, (e: any) => void>;
  private receiver: ReceiverInterface;
  private xmlParser = new XMLParser();
  private pauseImageTag: HTMLImageElement;
  private pauseImageContainer: HTMLElement;

  constructor(
    receiver: ReceiverInterface,
    serviceLayerInfo: ServiceLayerInformation,
    config: PauseAdsConfiguration = { adsPersistence: true }
  ) {
    this.config = config;
    this.receiver = receiver;
    this.receiverEvents = {};
    this.registerListeners();
    if (document.getElementById('pauseImage') && document.getElementById('pauseImageContainer')) {
      this.pauseImageTag = document.getElementById('pauseImage') as HTMLImageElement;
      this.pauseImageContainer = document.getElementById('pauseImageContainer') as HTMLElement;
      this.pauseImageContainer.style.position = 'absolute';
      this.pauseImageContainer.style.top = '0px';
      this.pauseImageContainer.style.right = '0px';
      this.pauseImageContainer.style.width = `${window.innerWidth}px`;
      this.pauseImageContainer.style.height = `${window.innerHeight}px`;
      this.pauseImageContainer.hidden = true;

      if (document.getElementById('pauseImageText')) {
        const textContainer = document.getElementById('pauseImageText') as HTMLElement;
        const finnishText = 'Mainos: Paina Play jatkaaksesi katselua';
        const swedishText = 'Annons: Tryck på Play för att fortsätta titta';
        textContainer.innerHTML = serviceLayerInfo.serviceId === ClientServices.MTV ? finnishText : swedishText;
      }
    } else {
      throw new ServiceError({
        code: 'PAUSE_AD_NO_IMAGE_TAG_FOUND',
        category: ErrorCategories.DEFAULT,
        fatal: true,
        details: {
          origin: 'PauseAd',
          domain: 'Initialization',
        },
      });
    }
  }

  private registerListeners() {
    this.receiver.addListener(
      ReceiverEvents.PauseRequest,
      (this.receiverEvents[ReceiverEvents.PauseRequest] = (e) => {
        this.changeVisualState(true);
      })
    );
    this.receiver.addListener(
      EngineEvents.Play,
      (this.receiverEvents[EngineEvents.Play] = (e) => {
        this.changeVisualState(false);
      })
    );
  }

  private unregisterListeners() {
    for (let event in this.receiverEvents) {
      this.receiver.removeListener(event, this.receiverEvents[event]);
    }
  }

  //|todo pauseAds.ts [BUG][Critical]: (Erik) A lot of these URLs seem to fail. Is that normal or what's going on?;
  private track(urls: string[]) {
    for (let i in urls) {
      try {
        fetch(urls[i], { mode: 'no-cors' });
      } catch (e) {
        console.warn(`Warning class:pauseAds[track] : PauseAds tracking url failed!\n${urls[i]}`);
      }
    }
  }

  public changeVisualState(show: boolean) {
    if (show) {
      if (this.currentAd) {
        this.pauseImageContainer.hidden = false;
        this.track(this.currentAd.impressionUrls);
      }
      this.currentAd = undefined;
    } else {
      this.pauseImageContainer.hidden = true;
      this.fetchPauseAd();
      if (!this.config.adsPersistence) {
        this.pauseImageTag.src = '';
      }
    }
  }

  public initialize(
    adsConfig: VideoPlaza,
    segmentTags: string[] = [],
    serviceLayerInfo: ServiceLayerInformation,
    freewheelEnabled: boolean,
    freewheelTestEnv: boolean
  ) {
    if (freewheelEnabled) {
      this.adsProxyUrl = new URL(this.getFreeWheelPauseAdUrl(serviceLayerInfo, adsConfig, freewheelTestEnv));
      return;
    }
    const url =
      serviceLayerInfo.serviceId === ClientServices.MTV
        ? new URL('https://fi-mtv3.videoplaza.tv/proxy/distributor/v2')
        : new URL('https://se-tv4.videoplaza.tv/proxy/distributor/v2');
    const cf = adsConfig.contentForm.includes('short') ? 'short_form' : 'long_form';

    if (adsConfig.tags) {
      adsConfig.tags = adsConfig.tags + ',ns_st_mv-1.0.0';
    }

    let tags: string[] = [];

    if (adsConfig.tags) {
      if (Array.isArray(adsConfig.tags)) {
        tags = [...tags, ...adsConfig.tags];
      } else if (typeof adsConfig.tags === 'string') {
        let vpTags: string[] = adsConfig.tags.split(',');
        /**
         * remove ns_st_mv as we need to override it...
         */
        vpTags.forEach((value, index) => {
          if (value.includes('ns_st_mv')) {
            vpTags.splice(index, 1);
          }
        });
        tags = [...tags, ...vpTags];
      }
    }

    if (serviceLayerInfo.serviceId === ClientServices.MTV) {
      tags.push('NordicPlayer');
    }

    tags = [...tags, ...segmentTags];
    tags.push(`${serviceLayerInfo.serviceId}.${serviceLayerInfo.serviceCountry}`);
    tags.push(`ns_st_mv-${serviceLayerInfo.applicationInfo?.applicationVersion || 'cc-unknown'}`);
    const tagsString = tags.join(',');

    url.searchParams.set('t', tagsString);
    url.searchParams.set('s', adsConfig.shares);
    url.searchParams.set('f', adsConfig.flags);

    url.searchParams.set('pid', getPersistentAdvertisementId(serviceLayerInfo.user?.userId));
    url.searchParams.set('cf', cf);
    url.searchParams.set('rt', 'vast_2.0');
    url.searchParams.set('vbw', '8000');
    url.searchParams.set('vwt', '1920');
    url.searchParams.set('vht', '1080');
    url.searchParams.set('afr', '0');
    url.searchParams.set('trs', 'https');
    url.searchParams.set(
      'dcid',
      serviceLayerInfo.serviceId === ClientServices.MTV ? 'mtv_googlecast' : 'b5b11b43-b2e2-42fe-96c3-f6cd322c6383'
    );
    url.searchParams.set('tt', 'pa');
    url.searchParams.set('obp', '0');
    url.searchParams.set('st', '0:0');

    if (serviceLayerInfo.user?.consentString) {
      if (serviceLayerInfo.serviceId === ClientServices.MTV) {
        url.searchParams.set('cp.gdpr_consent', '' + serviceLayerInfo.user.consentString);
        url.searchParams.set('cp.gdpr', '1');
        url.searchParams.set('cp.gdpr_pd', '0');
      } else {
        url.searchParams.set('gdpr_consent', '' + serviceLayerInfo.user.consentString);
        url.searchParams.set('gdpr', '1');
      }
    }

    this.adsProxyUrl = url;
  }

  private getFWKeyValues(serviceLayerInfo: ServiceLayerInformation, adsConfig: VideoPlaza) {
    let keyValues = `_fw_vcid2=${getPersistentAdvertisementId(serviceLayerInfo.user?.userId)}`;
    if (serviceLayerInfo.user?.consentString) {
      keyValues += '&_fw_gdpr=1';
      keyValues += `&_fw_gdpr_consent=${encodeURIComponent(serviceLayerInfo.user.consentString)}`;
    }
    keyValues += '&_fw_player_width=1920';
    keyValues += '&_fw_player_height=1080';

    const subscriptionTag = adsConfig?.tags.split(',').find((value) => value.indexOf('sub_') === 0);
    if (subscriptionTag) {
      keyValues += `&sub=${subscriptionTag}`;
    }

    return keyValues;
  }

  private getSiteSectionId(serviceId: string): string {
    if (serviceId === ClientServices.FK) return 'fotbollskanalense';
    if (serviceId === ClientServices.MTV) return 'mtvkatsomo';
    if (serviceId === ClientServices.KOKET) return 'koketse';
    if (serviceId === ClientServices.TV4SE) return 'tv4se';
    return 'tv4playse';
  }

  private getMRMProfileBasedOnNetworkId(networkId: string) {
    return `${networkId}:ctv_googlecast`;
  }

  private getMRMSiteSectionTag(tags: string, serviceId: string) {
    const liveOrVod = tags.includes('live') ? 'live' : 'vod';
    const sectionId = this.getSiteSectionId(serviceId);

    return `ondomain_${sectionId}_chromecast_app_${liveOrVod}`;
  }

  private getFWGlobalParams(serviceLayerInfo: ServiceLayerInformation, adsConfig: VideoPlaza, networkId: string) {
    const fwPVRandom = Math.floor(Math.random() * 10000000000).toString();
    const fwVPRandom = Math.floor(Math.random() * 10000000000).toString();
    let globalParams = `?prof=${encodeURIComponent(this.getMRMProfileBasedOnNetworkId(networkId))}`;
    globalParams += `&nw=${encodeURIComponent(networkId)}`;
    globalParams += `&flag=${encodeURIComponent('+slcb+sync')}`;
    globalParams += `&resp=vast4`;
    globalParams += `&caid=${adsConfig.contentId}`;
    globalParams += `&csid=${encodeURIComponent(
      this.getMRMSiteSectionTag(adsConfig.tags, serviceLayerInfo.serviceId)
    )}`;
    globalParams += `&mode=${adsConfig.tags.includes('live') ? 'live' : 'on-demand'}`;
    globalParams += `&vprn=${fwVPRandom}`;
    globalParams += `&pvrn=${fwPVRandom}`;

    return globalParams;
  }

  private getFreeWheelConfig(serviceLayerInfo: ServiceLayerInformation, useTest: boolean) {
    if (serviceLayerInfo.serviceId === ClientServices.MTV) {
      return {
        baseUrl: useTest ? 'https://805B8.v.fwmrm.net/ad/g/1' : 'https://805BA.v.fwmrm.net/ad/g/1',
        networkId: useTest ? '525752' : '525754',
        pauseAdSlot: useTest ? 'MTV%20FI_Pause2' : 'MTV%20FI_Pause_ad',
      };
    }
    return {
      baseUrl: useTest ? 'https://80265.v.fwmrm.net/ad/g/1' : 'https://80276.v.fwmrm.net/ad/g/1',
      networkId: useTest ? '524901' : '524918',
      pauseAdSlot: 'TV4_Pausead',
    };
  }

  private getFreeWheelPauseAdUrl(
    serviceLayerInfo: ServiceLayerInformation,
    adsConfig: VideoPlaza,
    useFreewheelTestEnv: boolean
  ) {
    const fwConfig = this.getFreeWheelConfig(serviceLayerInfo, useFreewheelTestEnv);
    const baseUrl = fwConfig.baseUrl;
    const globalParams = this.getFWGlobalParams(serviceLayerInfo, adsConfig, fwConfig.networkId);
    const keyValues = this.getFWKeyValues(serviceLayerInfo, adsConfig);
    const customAdSlotName = fwConfig.pauseAdSlot;

    return `${baseUrl}${globalParams};${keyValues};ptgt=a&slau=${customAdSlotName}&maxa=1`;
  }

  public parseResponse(vast: string): AdsData | undefined {
    const root = this.xmlParser.parse(vast);
    const inlineAd = root.VAST.Ad.InLine as InlineAds;
    if (inlineAd) {
      const ad: any = {};

      const nonLinear = inlineAd.Creatives?.Creative?.NonLinearAds?.NonLinear;
      const tracking = inlineAd.Creatives?.Creative?.NonLinearAds?.TrackingEvents?.Tracking;
      const linear = inlineAd.Creatives?.Creative?.Linear?.MediaFiles;
      const linearTracking = inlineAd.Creatives?.Creative?.Linear?.TrackingEvents?.Tracking;
      const linearClickThrough = inlineAd.Creatives?.Creative?.Linear?.VideoClicks?.ClickThrough;

      if (nonLinear && tracking) {
        ad.imageSource = nonLinear.StaticResource;
        ad.clickthroughTrackingUrls = Array.isArray(tracking) ? tracking : [tracking];
        ad.clickthroughUrl = nonLinear.NonLinearClickThrough;
      }
      if (linear && linearTracking && linearClickThrough) {
        ad.imageSource = linear.MediaFile;
        ad.clickthroughTrackingUrls = Array.isArray(linearTracking) ? linearTracking : [linearTracking];
        ad.clickthroughUrl = linearClickThrough;
      }
      const error = inlineAd.Error;
      if (error) {
        ad.errorTrackingUrls = Array.isArray(error) ? error : [error];
      }
      const impression = inlineAd.Impression;
      if (impression) {
        ad.impressionUrls = Array.isArray(impression) ? impression : [impression];
      }
      if (
        ad.imageSource &&
        ad.impressionUrls &&
        ad.clickthroughUrl &&
        ad.clickthroughTrackingUrls &&
        ad.errorTrackingUrls
      ) {
        return ad as AdsData;
      }
    }
  }

  public async fetchPauseAd(): Promise<void> {
    if (this.adsProxyUrl) {
      const response = await fetch(this.adsProxyUrl.toString(), { mode: 'cors' });
      const parsedResponse = this.parseResponse(await response.text());
      if (parsedResponse) {
        const response = await fetch(parsedResponse.imageSource);
        const imageData = await response.blob();
        const imageDataURL = URL.createObjectURL(imageData);
        parsedResponse.imageSource = imageDataURL;
        this.currentAd = parsedResponse;
        this.pauseImageTag.src = this.currentAd.imageSource;
      }
    } else {
      console.warn(`Warning class:pauseAds[fetchPauseAd] : No initialize call register!`);
    }
  }

  public reset() {
    this.pauseImageContainer.hidden = true;
    this.pauseImageTag.src = '';
    this.adsProxyUrl = undefined;
    this.currentAd = undefined;
  }

  public destroy() {
    this.unregisterListeners();
    this.reset();
  }
}

// |--------------------------|
// | TYPES
// |--------------------------|

type AdsData = {
  imageSource: string;
  impressionUrls: string[];
  clickthroughUrl: string;
  clickthroughTrackingUrls: string[];
  errorTrackingUrls: string[];
};

type VideoPlaza = {
  tags: string;
  shares: string;
  flags: string;
  contentForm: string;
  contentId: string;
};

export interface InlineAds {
  AdSystem?: string;
  AdTitle?: string;
  Error?: string;
  Impression?: string[];
  Creatives?: Creatives;
  Extensions?: Extensions;
}

export interface Creatives {
  Creative?: Creative;
}

export interface Creative {
  Linear?: LinearAds;
  NonLinearAds?: NonLinearAds;
}

export interface NonLinearAds {
  TrackingEvents?: TrackingEvents;
  NonLinear?: NonLinear;
}

export interface LinearAds {
  TrackingEvents?: TrackingEvents;
  MediaFiles?: MediaFiles;
  VideoClicks?: VideoClicks;
}

export interface VideoClicks {
  ClickThrough?: string;
}

export interface MediaFiles {
  MediaFile?: string;
}

export interface NonLinear {
  StaticResource?: string;
  NonLinearClickThrough?: string;
}

export interface TrackingEvents {
  Tracking?: string[];
}

export interface Extensions {
  Extension?: Extension;
}

export interface Extension {
  AdInfo?: string;
}
