const TtmlParser = require('../../utils/TtmlParser');

module.exports = class ShakaMp4TtmlParser {
    constructor(preserveBrBreaksInTtmlTextContent) {
        this.ttmlParser = new TtmlParser(preserveBrBreaksInTtmlTextContent);
    }

    parseInit(data) {
    }

    parseMedia(data, time) {
        const Mp4Parser = shaka.util.Mp4Parser;
        let sawMDAT = false;
        let payload = [];
        const parser = new Mp4Parser()
          .box('mdat', Mp4Parser.allData((data) => {
              sawMDAT = true;
              try {
                  const vttCues = this.ttmlParser.parse(new TextDecoder().decode(data), time.periodStart);
                  payload = payload.concat(vttCues.map(c => new shaka.text.Cue(c.startTime, c.endTime, c.text)));
              } catch (e) {
                  console.warn('ShakaMp4TtmlParser: Failed to parse ttml subtitle!');
              }
          }));

        parser.parse(data, /* partialOkay */ false);

        if (!sawMDAT) {
            console.warn('ShakaMp4TtmlParser: Failed to parse Mp4!');
            return [];
        }

        return payload;
    }
};