const {
    EngineEvents,
    TimelineManagerEvents
} = require('../../Events');

const Utils = require('../../utils/Utils');
const ListenableListener = require('../../event/ListenableListener');
const { StreamTypes } = require('../../Constants');
/**
 * EpochTimeIndicator is a number in seconds since 01/01-2010 @ 00:00:00
 * used to asses if a timestamp is in relative or absolute(decodeTime)
 **/
const EpochTimeIndicator = 1262350800;

module.exports = class TimelineManager extends ListenableListener {
    constructor({ engine, config }) {
        super();
        //fallback config
        this.config = {
            useEPGRestrictions: false,
            seekableRangeUpdateTolerance: 2
        };

        if (config && config.features && config.features.timelineManager) {
            this.config = Utils.extend(this.config, config.features.timelineManager);
        }

        this.currentSectionInfo = null;
        this.duration = null;
        this.engine = engine;
        this.initOffset = null;
        this.playerManager = this.engine.playerManager;
        this.currentTime = 0;
        this.seekableRangeInfo = {
            epochInformation: {},
            seekableRangeRestricted: false
        };
        this.seekableRange = null;
        this.startOffest = null;
        this.active = false;
        this.metaDataTimeout = null;
        this.engineEventListeners = {};
        this.lastSeekableRange = null;
        this.missingMetadataFailover = false;
        this.streamType = null;

        this.listen(this.engine, this.engineEventListeners, EngineEvents.TimeUpdate, this.onTimeUpdate.bind(this));
        this.listen(this.engine, this.engineEventListeners, EngineEvents.StreamChanged, this.onStreamChanged.bind(this));
        this.listen(this.engine, this.engineEventListeners, EngineEvents.AdvertisementTimeUpdate, this.onTimeUpdate.bind(this));
    }

    updateContent(content) {
        if (content) {
            if (!this.metaDataTimeout) {
                this.metaDataTimeout = setTimeout(() => {
                    this.missingMetadataFailover = true;
                }, 10000);
            }

            this.active = ['LIVE', 'STARTOVER'].includes(content.watchMode);

            if (content.metadata && content.metadata.sectionInfo) {
                this.startOffest = null;
                this.currentSectionInfo = content.metadata.sectionInfo;
            }
        } else {
            console.warn('TimelineManager::updateContent: No content!');
        }
    }

    /**
     *
     * @param {Stream}
     */
    onStreamChanged(event) {
        this.streamType = event.stream.streamType
    }

    onTimeUpdate() {
        const seekableRange = this.playerManager.getLiveSeekableRange();
        if (seekableRange && Utils.isNumber(seekableRange.end)) {
            if (!this.seekableRangeInfo.seekableRange || this.lastSeekableRange && Math.abs(this.lastSeekableRange.end - seekableRange.end) > this.config.seekableRangeUpdateTolerance) {
                this.updateCurrentSeekableRange(seekableRange);
            }
        }

        if (this.currentTime < Math.floor(this.engine.currentTime)) {
            this.currentTime = Math.floor(this.engine.currentTime);
        } else {
            return;
        }
        let timeUpdate = {
            currentTime: this.engine.currentTime,
            epochTime: null,
            seekableRange: null

        }
        if (this.streamType && this.streamType !== StreamTypes.OnDemand) {
            // Stream is some form of live...
            if (Utils.isNumber(this.engine.currentMediaElementTime) && this.engine.currentMediaElementTime > EpochTimeIndicator) {
                timeUpdate.epochTime = this.engine.currentMediaElementTime;
            } else if (this.initOffset) {
                timeUpdate.epochTime = this.initOffset + this.engine.currentTime;
            }
            if (this.engine.getSeekableRange()) {
                timeUpdate.seekableRange = this.engine.getSeekableRange();
            }
        }
        this.emit(TimelineManagerEvents.TimeUpdate, timeUpdate);

    }


    updateCurrentSeekableRange(seekableRange) {
        let halt = false;
        if (this.active && seekableRange) {
            // get "Real" seekableRange from engine...
            const playerSeekableRange = this.engine.getSeekableRange();

            this.seekableRangeInfo.seekableRangeRestricted = false;

            if (playerSeekableRange && playerSeekableRange.start > EpochTimeIndicator) { // if real seekableRange is in epoch time, // ToDo: This calculation should move to engine which should provide the seekRange in both epoch & zero-based format at all time
                this.seekableRangeInfo.epochInformation.start = playerSeekableRange.start;
                this.seekableRangeInfo.epochInformation.end = playerSeekableRange.end;
            } else { // if real seekableRange is not in epoch time use wall-clock time
                if (!this.initOffset) {
                    this.initOffset = (Date.now() / 1000) - seekableRange.end;
                }

                this.seekableRangeInfo.epochInformation.start = this.initOffset + playerSeekableRange.start;
                this.seekableRangeInfo.epochInformation.end = this.initOffset + playerSeekableRange.end;
            }

            if (this.config.useEPGRestrictions && this.missingMetadataFailover === false) {
                if (!this.startOffest && this.currentSectionInfo && Utils.isNumber(this.currentSectionInfo.startTime)) {
                    this.startOffest = {};
                    this.startOffest.absolute = this.currentSectionInfo.startTime / 1000;

                    const epochValueForStreamTime0 = this.seekableRangeInfo.epochInformation.start - seekableRange.start;
                    this.startOffest.relative = this.startOffest.absolute - epochValueForStreamTime0;
                }

                if (this.startOffest) {
                    if (this.startOffest.relative > 0) {
                        this.seekableRangeInfo.seekableRangeRestricted = true;

                        seekableRange.start = this.startOffest.relative;
                        seekableRange.isMovingWindow = false;

                        // Ensure we are not restricting the window so that playhead ends up outside the restricted window
                        if (this.startOffest.relative >= this.engine.currentTime) {
                            seekableRange.start = this.engine.currentTime - 1;
                            seekableRange.isMovingWindow = true;
                        }

                        const seekableRangeDuration = seekableRange.end - seekableRange.start;
                        this.seekableRangeInfo.epochInformation.start = this.seekableRangeInfo.epochInformation.end - seekableRangeDuration;
                    }
                } else {
                    halt = true;
                }
            }
        }

        this.lastSeekableRange = seekableRange;

        if (this.active && halt === false) {
            this.metaDataTimeoutReset();
            this.seekableRangeInfo.seekableRange = seekableRange;
            this.emit(TimelineManagerEvents.SeekableRangeUpdated, this.seekableRangeInfo);
        }
    }

    metaDataTimeoutReset() {
        if (this.metaDataTimeout) {
            this.metaDataTimeout = clearTimeout(this.metaDataTimeout);
        }
    }

    reset() {
        this.metaDataTimeoutReset();

        this.missingMetadataFailover = false;
        this.lastSeekableRange = null;
        this.currentSectionInfo = null;
        this.duration = null;
        this.initOffset = null;
        this.seekableRangeInfo = {
            epochInformation: {},
            seekableRangeRestricted: false
        };
        this.startOffest = null;
        this.active = false;
    }

    destroy() {
        this.unlisten(this.engine, this.engineEventListeners, EngineEvents.TimeUpdate);
        this.unlisten(this.engine, this.engineEventListeners, EngineEvents.AdvertisementTimeUpdate);
        this.unlisten(this.engine, this.engineEventListeners, EngineEvents.StreamChanged);
    }
};